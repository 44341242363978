import { UsageMap } from './UsageMap';

// Dimension class represent key value pair which uniquely identies tile/Assesment/Dashboard  
export class Dimension {
    id: string;
    key: string;
    value: string;
    isMultiselectionAllowed?: boolean;
    isMappedToComplianceEntities?: boolean;
    usageMap?: UsageMap[];
    isGlobalDimension: boolean;
}