import * as React from 'react';

export class ScreenReaderAnnouncer extends React.Component {
    render() {
        return (
            <div
                id="screenReaderAnnouncer"
                aria-live="polite"
                aria-relevant="additions text"
                aria-atomic="true"
            >
                {''}
            </div>
        );
    }
}