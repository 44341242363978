import { DocumentInfo } from './DocumentInfo';
import { ImplementationStatus } from './ImplementationStatus';
import { FrameworkControlAssignment } from './FrameworkControlAssignment';
import { FrameworkControlTestResult } from './FrameworkControlTestResult';
import { FrameworkStatus } from './FrameworkStatus';

export class FrameworkUserActions {
    assignedUserId: string;
    assignment: FrameworkControlAssignment;
    microsoftControlId: string;
    frameworkId: string;
    status: ImplementationStatus;
    testResult: FrameworkControlTestResult;
    documentInfos: DocumentInfo[];
    tileId: string;
    tileStatus: FrameworkStatus;
}