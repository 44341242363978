import { Reducer } from 'redux';
import { MicrosoftServiceLinks, ServiceLinks, AzureSubscription, AzureStorageAccount } from '../Models/PrivacyPortal';

type Status = 'Init' | 'Requesting' | 'Finished' | 'Success' | 'Error' | 'Loaded' | 'Loading';

export interface PrivacyPortalState {
    status: Status;
    azureSubscriptions: AzureSubscription[];
    azureStorageAccounts: AzureStorageAccount[];
    microsoftServiceLinks: MicrosoftServiceLinks;
    message?: string;
}
// 
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface MicrosoftServiceLinksRequesting {
    type: 'MS_SERVICE_LINKS_REQUESTING';
}

interface MicrosoftServiceLinksRequestSucceed {
    type: 'MS_SERVICE_LINKS_REQUEST_SUCCEED';
    message: string;
    microsoftServiceLinks: MicrosoftServiceLinks;

}

interface MicrosoftServiceLinksRequestError {
    type: 'MS_SERVICE_LINKS_REQUEST_ERROR';
    message: string;
}

interface AzureSubscriptionRequesting {
    type: 'AZURE_SUBSCRIPTION_REQUESTING';
}

interface AzureSubscriptionRequestSucceed {
    type: 'AZURE_SUBSCRIPTION_REQUEST_SUCCEED';
    message: string;
    azureSubscriptions: AzureSubscription[];
}

interface AzureSubscriptionRequestError {
    type: 'AZURE_SUBSCRIPTION_REQUEST_ERROR';
    message: string;
}

interface AzureStorageAccountRequesting {
    type: 'AZURE_STORAGE_ACCOUNT_REQUESTING';
}

interface AzureStorageAccountRequestSucceed {
    type: 'AZURE_STORAGE_ACCOUNT_REQUEST_SUCCEED';
    message: string;
    azureStorageAccounts: AzureStorageAccount[];
}

interface AzureStorageAccountRequestError {
    type: 'AZURE_STORAGE_ACCOUNT_REQUEST_ERROR';
    message: string;
}

export type MicrosoftServiceLinksActions =  MicrosoftServiceLinksRequesting | MicrosoftServiceLinksRequestSucceed | MicrosoftServiceLinksRequestError;
export type AzureSubscriptionsActions =  AzureSubscriptionRequesting | AzureSubscriptionRequestSucceed | AzureSubscriptionRequestError;
export type AzureStorageAccountsActions =  AzureStorageAccountRequesting | AzureStorageAccountRequestSucceed | AzureStorageAccountRequestError;
export type PrivacyPortalActions = MicrosoftServiceLinksActions | AzureSubscriptionsActions | AzureStorageAccountsActions;

export function getServiceLinksRequestingAction(): MicrosoftServiceLinksActions {
    return {
        type: 'MS_SERVICE_LINKS_REQUESTING',
    };
}

export function getServiceLinksRequestSucceedAction(message: string, data: MicrosoftServiceLinks): MicrosoftServiceLinksActions {
    return {
        type: 'MS_SERVICE_LINKS_REQUEST_SUCCEED',
        message: message,
        microsoftServiceLinks: data
    };
}

// tslint:disable-next-line:no-any
export function getServiceLinksRequestErrorAction(message: string): MicrosoftServiceLinksActions {
    return {
        type: 'MS_SERVICE_LINKS_REQUEST_ERROR',
        message: message,
    };
}

export function getAzureSubscriptionRequestingAction(): AzureSubscriptionsActions {
    return {
        type: 'AZURE_SUBSCRIPTION_REQUESTING',
    };
}

export function getAzureSubscriptionRequestSucceedAction(message: string, data: AzureSubscription[]): AzureSubscriptionsActions {
    return {
        type: 'AZURE_SUBSCRIPTION_REQUEST_SUCCEED',
        message: message,
        azureSubscriptions: data
    };
}

// tslint:disable-next-line:no-any
export function getAzureSubscriptionRequestErrorAction(message: string): AzureSubscriptionsActions {
    return {
        type: 'AZURE_SUBSCRIPTION_REQUEST_ERROR',
        message: message,
    };
}

export function getAzureStorageAccountRequestingAction(): AzureStorageAccountsActions {
    return {
        type: 'AZURE_STORAGE_ACCOUNT_REQUESTING',
    };
}

export function getAzureStorageAccountRequestSucceedAction(message: string, data: AzureStorageAccount[]): AzureStorageAccountsActions {
    return {
        type: 'AZURE_STORAGE_ACCOUNT_REQUEST_SUCCEED',
        message: message,
        azureStorageAccounts: data
    };
}

// tslint:disable-next-line:no-any
export function getAzureStorageAccountRequestErrorAction(message: string): AzureStorageAccountsActions {
    return {
        type: 'AZURE_STORAGE_ACCOUNT_REQUEST_ERROR',
        message: message,
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

var microsoftServiceLinks: MicrosoftServiceLinks = {
    id: '',
    serviceLinks: [] as ServiceLinks,
    publicationStatus: 0,
    whoPublished: ''
};

export const reducer: Reducer<PrivacyPortalState> = (state: PrivacyPortalState, action: PrivacyPortalActions) => {
    let newState: PrivacyPortalState;
    switch (action.type) {
        case 'MS_SERVICE_LINKS_REQUESTING':
            newState = { ...state, status: 'Requesting' };
            break;

        case 'MS_SERVICE_LINKS_REQUEST_SUCCEED':
            newState = {
                ...state,
                status: 'Success',
                message: action.message,
                microsoftServiceLinks: action.microsoftServiceLinks
            };
            break;

        case 'MS_SERVICE_LINKS_REQUEST_ERROR':
            newState = {
                ...state,
                status: 'Error',
                message: action.message,
            };
            break;

        case 'AZURE_SUBSCRIPTION_REQUESTING':
            newState = { ...state, status: 'Requesting' };
            break;

        case 'AZURE_SUBSCRIPTION_REQUEST_SUCCEED':
            newState = {
                ...state,
                status: 'Success',
                message: action.message,
                azureSubscriptions: action.azureSubscriptions,
                azureStorageAccounts: [] as AzureStorageAccount[]
            };
            break;

        case 'AZURE_SUBSCRIPTION_REQUEST_ERROR':
            newState = {
                ...state,
                status: 'Error',
                azureSubscriptions: [] as AzureSubscription[],
                message: action.message,
            };
            break;
            
        case 'AZURE_STORAGE_ACCOUNT_REQUESTING':
            newState = { ...state, azureStorageAccounts: [] as AzureStorageAccount[], status: 'Requesting' };
            break;

        case 'AZURE_STORAGE_ACCOUNT_REQUEST_SUCCEED':
            newState = {
                    ...state,
                    status: 'Success',
                    message: action.message,
                    azureStorageAccounts: action.azureStorageAccounts
                };
            break;

        case 'AZURE_STORAGE_ACCOUNT_REQUEST_ERROR':
            newState = {
                    ...state,
                    status: 'Error',
                    azureStorageAccounts: [] as AzureStorageAccount[],
                    message: action.message,
                };
            break;     

        default:
            newState = (state === undefined)
            ? {
                status: 'Init',
                azureSubscriptions: [] as AzureSubscription[],
                azureStorageAccounts: [] as AzureStorageAccount[],
                microsoftServiceLinks,
                message: '',
            }
            : state;
    }
    return newState;
};
