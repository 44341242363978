import * as React from 'react';
import { SearchBox, Persona, IPersonaProps, PersonaPresence } from 'office-ui-fabric-react';
import { DropdownMenuComponent } from '../../../Common/Components/DropdownMenu';
import './ExportData.css';
import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { ExportedFile, PrivacyOperation } from '../../Models/PrivacyPortal';
import { PrivacyPortalClient } from '../../Services/PrivacyPortalService';
import { store } from '../../../Store';
import * as ExportedFilesReducer from '../../Reducers/PrivacyPortal/ExportedFiles';
import { GetExportedFilesResponse } from '../../Services/Models/PrivacyPortal/index';
import { ErrorResponse } from '../../Services/Models/ErrorResponse';

let api = PrivacyPortalClient;

export interface ExportedFilesPageProps {
    exportId: string;
    files: ExportedFile[];
    status: string;
    message?: string;
    privacyOperation: PrivacyOperation;
    user: IPersonaProps;
}

export interface Pagination {
    itemsPerPage: string;
    currentPage: number;
}

export interface ExportedFilesPageState {
    pagination: Pagination;
    searchText?: string;
}

export function getReadableSize(bytesSize: number): string {
    if (bytesSize < 1024) {
        return bytesSize + ' B';
    }
    let units = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let idx = -1;
    do {
        bytesSize /= 1024;
        ++idx;
    } while (Math.abs(bytesSize) >= 1024 && idx < units.length - 1);

    return bytesSize.toFixed(1) + ' ' + units[idx];
}

export default class ExportedFilesPage extends React.Component<ExportedFilesPageProps, ExportedFilesPageState> {

    constructor(props: ExportedFilesPageProps) {
        super(props);
        this.state = {
            pagination: { itemsPerPage: '5', currentPage: 1 },
        };
    }

    getExportedFileItems() {
        return (
            <span>{this.getCurrentPageItems().map((file, idx) => <div key={idx} data-grid="col-12" className="privacy-portal-each-datarequest">
                <div data-grid="col-8">
                    <p className="c-subheading-6 privacy-portal-label">
                        <a className="c-hyperlink privacy-portal-label privacy-portal-link" href={file.uri} download={true} target="_blank">
                            {file.name}  <i style={{ fontSize: 10 }} className="ms-Icon ms-Icon--Download" aria-hidden="true" />
                        </a>
                    </p>
                </div>

                <div data-grid="col-2" style={{ paddingLeft: 10 }}>
                    <p className="c-subheading-6 privacy-portal-label">{getReadableSize(file.size)}</p>
                </div>
                <div data-grid="col-2" style={{ paddingLeft: 10 }}>
                    <p className="c-paragraph privacy-portal-label" />
                </div>

            </div>)}
            </span>
        );
    }

    getAllFilesSize(): string {
        let totalSize: number = 0;
        if (this.props.files) {
            for (let file of this.props.files) {
                totalSize += file.size;
            }
        }
        return getReadableSize(totalSize);
    }

    // tslint:disable-next-line:no-empty
    public componentDidMount(): void {
        api.GetExportedFiles(
            { operationId: this.props.exportId },
            () => {
                store.dispatch(ExportedFilesReducer.getExportedFilesRequestingAction());
            },
            (response: GetExportedFilesResponse) => {
                store.dispatch(ExportedFilesReducer.getExportedFilesRequestSucceededAction(response.message, response.data.files, response.data.privacyOperation));
            },
            (failed: ErrorResponse) => {
                let message: string = failed.code + ' ' + failed.status + ' - ' + failed.message;
                store.dispatch(ExportedFilesReducer.getExportedFilesRequestErrorAction(message));
            });
    }

    getPagination() {
        return (
            <div data-grid="col-12" style={{ paddingTop: 10 }}>
                <div data-grid="col-12">
                    <p className="c-paragraph-4 privacy-portal-label" data-grid="col-12">
                        {this._getShowingPagesText()}
                    </p>
                </div>
                <ul data-grid="col-8" className="m-pagination">
                    {this.state.pagination && this.state.pagination.currentPage > 1 ? <li>
                        <a className="c-glyph privacy-portal-a" aria-label="Previous Page" onClick={() => this.setCurrentPage(this.state.pagination.currentPage - 1)}>
                            <span>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPaginationPreviousLabel)}</span>
                        </a>
                    </li> : null}
                    {this.getTotalPages().map((pageNumber, idx) =>
                        pageNumber === this.state.pagination.currentPage ?
                            (<li key={idx} className={'f-active'} data-label="Page 1">
                                <span aria-label={'Currently on page ' + pageNumber}>{pageNumber}</span>
                            </li>) : (<li key={idx}>
                                <a className="privacy-portal-a" aria-label={'Page' + pageNumber} onClick={() => this.setCurrentPage(pageNumber)} >{pageNumber}</a>
                            </li>))
                    }
                    {this.state.pagination && this.state.pagination.currentPage < this.getTotalPages().length ?
                        <li>
                            <a className="c-glyph privacy-portal-a" aria-label="Next Page" onClick={() => this.setCurrentPage(this.state.pagination.currentPage + 1)}>
                                <span>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPaginationNextLabel)}</span>
                            </a>
                        </li>
                        : null}
                </ul>
                <div data-grid="col-4">
                    <div style={{ float: 'right' }}>
                        <span className="alignleft privacy-portal-label" style={{ padding: 0, margin: 0 }}>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerResultsPerPage)} </span>
                        <span className="alignleft privacy-portal-dropdownmenu-wrapper" style={{ paddingLeft: 5 }}>
                            <DropdownMenuComponent
                                items={[
                                    {
                                        key: '5',
                                        name: '5'
                                    },
                                    {
                                        key: '10',
                                        name: '10'
                                    },
                                    {
                                        key: '20',
                                        name: '20'
                                    },
                                ]}
                                selectedText={this.state.pagination.itemsPerPage}
                                onChange={(param: string) => { this.resultsPerPageChanged(param); }}
                            />
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    resultsPerPageChanged(resultsPerPage: string) {
        this.setState({ pagination: { itemsPerPage: resultsPerPage, currentPage: 1 } });
    }

    setCurrentPage(pageNumber: Number) {
        if (pageNumber < this.getTotalPages().length + 1) {
            this.setState({ pagination: { itemsPerPage: this.state.pagination.itemsPerPage, currentPage: Number(pageNumber) } });
        }
    }

    getItemsPerPage(): number {
        return Number(this.state.pagination.itemsPerPage);
    }

    getFilteredItems(): ExportedFile[] {
        let items = this.props.files;

        if (this.state.searchText) {
            let s: string = this.state.searchText.toLowerCase();
            items = items.filter(i => i.name.toLowerCase().indexOf(s) !== -1);
        }

        return items;
    }

    getTotalPages(): Number[] {
        let itemsPerPage = this.getItemsPerPage();
        let totalItems = this.getFilteredItems().length;
        let lastPage = Number(Math.floor(totalItems / itemsPerPage));
        if (totalItems > lastPage * itemsPerPage) {
            lastPage += 1;
        }
        let pages: Number[] = [];
        for (let i = 1; i <= lastPage; i++) {
            pages.push(i);
        }
        return pages;
    }

    getCurrentPageItems(): ExportedFile[] {
        let currentFiles: ExportedFile[] = [];
        let skip = (this.state.pagination.currentPage - 1) * this.getItemsPerPage();
        let filteredItems = this.getFilteredItems();
        if ((skip === 0 && filteredItems.length > 0) || skip <= filteredItems.length) {
            for (let i = skip; i < skip + this.getItemsPerPage(); i++) {
                if (filteredItems[i] !== undefined) {
                    currentFiles.push(filteredItems[i]);
                }
            }
        }
        return currentFiles;
    }

    render() {
        return (
            <div>
                <div style={{ marginTop: '10px' }}>
                    <div data-grid="col-12">
                        <div data-grid="col-8">
                            <h6 className="col-12 c-heading-6">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerTitle)}</h6>
                            <h3 className="col-12 c-heading-3 tile-Progress" style={{ padding: 0 }}>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerSubTitle)}</h3>
                        </div>
                    </div>
                    <div data-grid="col-8">
                        <div data-grid="col-12">
                            <p className="c-subheading-3 privacy-porta-label-bolder">{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportRequestLabel)} - {this.props.user.primaryText}</p>
                        </div>
                    </div>
                </div>
                <div data-grid="col-12">
                    <hr className="c-divider f-pad-top-3x f-pad-bottom-3x" />
                </div>
                <div data-grid="col-12">
                    <div data-grid="col-3">
                        <div className="c-age-rating">
                            <Persona
                                {...this.props.user}
                                presence={PersonaPresence.none}
                            />
                        </div>
                    </div>
                    <div data-grid="col-2">
                        <div className="c-age-rating">
                            <p className="c-label privacy-portal-label privacy-porta-label-bolder" style={{ marginLeft: 65 }}>{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportStatusLabel)}</p>
                            <p className="c-paragraph privacy-portal-label" style={{ marginLeft: 65 }}>{this.props.privacyOperation.StateText}</p>
                        </div>
                    </div>

                    {/* <div data-grid="col-3">
                        <div className="c-age-rating">
                            <p className="c-label privacy-portal-label privacy-porta-label-bolder" style={{ marginLeft: 65 }}>{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportRequestByLabel)}</p>
                            <p className="c-paragraph privacy-portal-label" style={{ marginLeft: 65 }}>admin@contso.com</p>
                        </div>
                    </div> */}

                    <div data-grid="col-2">
                        <div className="c-age-rating">
                            <p className="c-label privacy-portal-label privacy-porta-label-bolder" style={{ marginLeft: 65 }}>{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportDateRequestedLabel)}</p>
                            <p className="c-paragraph privacy-portal-label" style={{ marginLeft: 65 }}>{this.props.privacyOperation.SubmittedTime ? new Date(this.props.privacyOperation.SubmittedTime).toLocaleDateString() : ''}</p>
                        </div>
                    </div>

                    {/* <div data-grid="col-1">
                        <div className="c-age-rating">
                            <p className="c-label privacy-portal-label privacy-porta-label-bolder" style={{ marginLeft: 65 }}>{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportDateCompletedLabel)}</p>
                            <p className="c-paragraph privacy-portal-label" style={{ marginLeft: 65 }}>12/28/17</p>
                        </div>
                    </div> */}

                    {/* <div data-grid="col-1">
                        <div className="c-age-rating">
                            <p className="c-label privacy-portal-label privacy-porta-label-bolder" style={{ marginLeft: 65 }}>{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportLinkExpiresLabel)}</p>
                            <p className="c-paragraph privacy-portal-label" style={{ marginLeft: 65 }}>01/28/18</p>
                        </div>
                    </div> */}
                    <div data-grid="col-2">
                        <div className="c-age-rating">
                            <p className="c-label privacy-portal-label privacy-porta-label-bolder" style={{ marginLeft: 65 }}>{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportSizeLabel)}</p>
                            <p className="c-paragraph privacy-portal-label" style={{ marginLeft: 65 }}>{this.getAllFilesSize()}</p>
                        </div>
                    </div>
                </div>
                <div data-grid="col-12">
                    <hr className="c-divider f-pad-top-3x f-pad-bottom-3x" />
                </div>
                <div data-grid="col-12">
                    <h3 className="c-heading-5">
                        {Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportedGridTitle)}
                    </h3>

                </div>
                <div data-grid="col-12" style={{ paddingTop: 10 }}>
                    <div data-grid="col-3">
                        <SearchBox
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, param: string) => { this.searchFilterTextChanged(param); }}
                        />
                    </div>
                    {/* <div data-grid="col-2" style={{ paddingLeft: 10 }}>
                        <span className="alignleft privacy-portal-label" style={{ padding: 0, margin: 0 }}>{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportedGridServicesLabel)} </span>
                        <span className="alignleft privacy-portal-dropdownmenu-wrapper" style={{ paddingLeft: 5 }}>
                            <DropdownMenuComponent
                                items={[
                                    {
                                        key: 'All',
                                        name: 'All'
                                    },
                                    {
                                        key: 'All',
                                        name: 'All'
                                    },
                                ]}
                            />
                        </span>
                    </div> */}
                    <div data-grid="col-4" />
                    {/* <div data-grid="col-2">
                        <PrimaryButton style={{ float: 'right' }}>
                            {Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportedGridDownloadAllButton)}
                        </PrimaryButton>
                    </div> */}
                </div>
                <div data-grid="col-12">
                    <hr className="c-divider f-pad-top-3x f-pad-bottom-3x" />
                </div>
                <div data-grid="col-12">
                    <div data-grid="col-8">
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportedGridFileNameLabel)}</p>
                    </div>

                    <div data-grid="col-2" style={{ paddingLeft: 10 }}>
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportedGridSizeLabel)}</p>
                    </div>
                    <div data-grid="col-2" style={{ paddingLeft: 10 }}>
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.ExportedFilesDataExportedGridLinkExpiresLabel)}</p>
                    </div>
                </div>
                <div data-grid="col-12">
                    <hr className="c-divider f-pad-top-3x f-pad-bottom-3x" />
                </div>
                {this.getExportedFileItems()}
                {this.getPagination()}
            </div>
        );
    }

    private _getShowingPagesText(): string {
        let totalPages = this.getTotalPages().length;
        let currentPage = totalPages > 0 ? this.state.pagination.currentPage : 0;
        return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPaginationShowingLabel, { currentPage: currentPage, totalPages: totalPages });
    }

    private searchFilterTextChanged(param: string): void {
        this.setState({ searchText: param });
        this.setCurrentPage(1);
    }
}