import { AxiosResponse } from 'axios';
import {
    ErrorResponse,
    BaseRequest,
    BaseResponse,
    
} from './Models';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { SeriesDefinition } from '../Models/Series/SeriesDefinition';

export interface GetSeriesRequest extends BaseRequest {
    serieName: string;
}

export interface GetSeriesResponse extends BaseResponse {
    series: SeriesDefinition[];
}

class DocumentSeriesService {

    private api: string = '/api/v2/DocumentSeries/';
    
    getSeries(
        request: GetSeriesRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: GetSeriesResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {

        
        ServiceTrustClient.doRequest_New(
            'get', this.api, request, beforeRequest,
            (response: AxiosResponse) => {
                let res: GetSeriesResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    series: response.data
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );
    }

    listSeries( beforeRequest: () => void,
                      onRequestSuccess: (response: AxiosResponse) => void,
                      onRequestFailed: (response: ErrorResponse) => void): void {
        ServiceTrustClient.doRequest_New('GET', this.api, {}, beforeRequest, onRequestSuccess, onRequestFailed);
    }

}

export let SeriesServiceClient = new DocumentSeriesService();