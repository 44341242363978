import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { HeroItemPanelPropertiesData } from '../../Models/PageInfrastructure';
import { BasePanel } from './BasePanel';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { IComboBox, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { PrimaryButton, DefaultButton, ComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import Constants from '../../Utilities/Constants';
import { Intl } from '../../Services/GlobalizationService';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { getHeroItemLayoutOptions, getHeroItemThemeOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import './HeroItemPanel.css';

export class HeroItemPanel extends BasePanel<HeroItemPanelPropertiesData> {
    public themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    public layoutOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.LayoutLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<HeroItemPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getHeroItemThemeOptions());
        this.layoutOptions = this.layoutOptions.concat(getHeroItemLayoutOptions());
    }

    getActualPanel() {
        const heroItemTheme = typeof this.state.panelData.theme === 'string' ? this.state.panelData.theme.toLowerCase() : 'light';
        const heroItemLayout = typeof this.state.panelData.layout === 'string' ? this.state.panelData.layout.toLowerCase() : 'thumbnail';
        return (
            <div className="stp_hero-item-panel">
                {this.state.panelData.layout === 'Featured' ?
                    <section className={`mx-ng mx-lg-0 theme-${heroItemTheme} stp_hero-item-panel_layout-${heroItemLayout}`}>
                        <div>
                            <div className="col-6 stp_hero-item-panel_copy">
                                <h1>{getLocalizedString(this.state.panelData.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h1>
                                {this.state.panelData.subHeading && this.state.panelData.subHeading.lv &&
                                    <p className="c-paragraph-1">{getLocalizedString(this.state.panelData.subHeading)}</p>
                                }
                            </div>
                            <div className="col-6 stp_hero-item-panel_image stp_hero-item-panel_featured-image">
                                {this.state.panelData.imageId && !this.state.panelData.imageId.includes('Missing-Image') &&
                                    <div>
                                        <img
                                            aria-hidden={true}
                                            className="c-image lazyloaded"
                                            src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                            data-src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                            alt={getLocalizedString(this.state.panelData.imageTitle)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                    :
                    <section className={`mx-ng mx-lg-0 theme-${heroItemTheme} stp_hero-item-panel_layout-${heroItemLayout}`}>
                        {this.state.panelData.backgroundImageId && !this.state.panelData.backgroundImageId.includes('Missing-Image') &&
                            <div className="stp_hero-item-panel_background-image">
                                <img
                                    aria-hidden={true}
                                    className="lazyloaded"
                                    src={imageHelper.getImageUrl(this.state.panelData.backgroundImageId)}
                                    data-src={imageHelper.getImageUrl(this.state.panelData.backgroundImageId)}
                                    // background images are decorative, so best practice is to leave alt text set to "", so that screenreaders know to skip
                                    alt=""
                                />
                            </div>
                        }
                        <div>
                            {this.state.panelData.thumbnailImageId && !this.state.panelData.thumbnailImageId.includes('Missing-Image') &&
                                <div className="col-2 stp_hero-item-panel_image stp_hero-item-panel_thumbnail-image">
                                    <img
                                        aria-hidden={true}
                                        className="c-image lazyloaded"
                                        src={imageHelper.getImageUrl(this.state.panelData.thumbnailImageId)}
                                        data-src={imageHelper.getImageUrl(this.state.panelData.thumbnailImageId)}
                                        alt={getLocalizedString(this.state.panelData.thumbnailImageTitle)}
                                    />
                                </div>
                            }
                            <div className="col-8 stp_hero-item-panel_copy">
                                <h2 className="c-heading-1">{getLocalizedString(this.state.panelData.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h2>
                                {this.state.panelData.subHeading && this.state.panelData.subHeading.lv &&
                                    <p className="c-paragraph-2">{getLocalizedString(this.state.panelData.subHeading)}</p>
                                }
                            </div>
                        </div>
                    </section>
                }
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>

                        {this.state.showEditor ?
                            <div>
                                <div data-grid="col-12" className="editPanel">
                                    <div data-grid="col-5">
                                        <ComboBox
                                            defaultSelectedKey={this.state.panelData.theme || 'Light'}
                                            text={this.state.panelData.theme || 'Light'}
                                            label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                            ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                            allowFreeform={true}
                                            autoComplete={'on'}
                                            options={this.themeOptions}
                                            onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                this.state.panelData.theme = item.key.toString();
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                        />
                                        <ComboBox
                                            defaultSelectedKey={this.state.panelData.layout || 'Thumbnail'}
                                            text={this.state.panelData.layout || 'Thumbnail'}
                                            label={Intl.Get(LocIds.PageAndPanels.SelectLayoutLabel)}
                                            ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectLayoutLabel)}
                                            allowFreeform={true}
                                            autoComplete={'on'}
                                            options={this.layoutOptions}
                                            onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                this.state.panelData.layout = item.key.toString();
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                        />
                                        <TextFieldWrapper
                                            value={getLocalizedString(this.state.panelData.heading)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                    newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                }
                                                setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                            required={true}
                                            validator={this.panelValidator}
                                        />
                                        <TextFieldWrapper
                                            value={this.state.panelData.subHeading ? (getLocalizedString(this.state.panelData.subHeading || '')) : ''}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                if (newVal.length > Constants.PANEL_DESCRIPTION_MAX) {
                                                    newVal = newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX);
                                                }
                                                setDefaultLocalizedString(this.state.panelData, 'subHeading', newVal);
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            placeholder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                            required={false}
                                            validator={this.panelValidator}
                                        />
                                    </div>
                                    <div data-grid="col-12" style={{ marginTop: '24px' }}>
                                        <h3>{Intl.Get(LocIds.PageAndPanels.FeaturedImageLabel)}</h3>
                                        <p className="c-heading-6">{Intl.Get(LocIds.PageAndPanels.FeaturedImageSuggestionLabel)}</p>
                                        <p>{Intl.Get(LocIds.PageAndPanels.FeaturedImageDescriptionLabel)}</p>
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={false}
                                                disabled={true}
                                                value={getLocalizedString(this.state.panelData.imageTitle)}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton aria-label="Choose featured image" onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length) {
                                                        this.setState({
                                                            panelData: {
                                                                ...this.state.panelData,
                                                                imageId: images[0].url,
                                                                ...setDefaultLocalizedString({}, 'imageTitle', images[0].title),
                                                            },
                                                            showImagePicker: false
                                                        });
                                                    } else {
                                                        this.state.panelData.imageId = '/Images/Panel-Infrastructure/Missing-Image.png';
                                                        setDefaultLocalizedString(this.state.panelData, 'imageTitle', '');
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12" style={{ marginTop: '24px', marginBottom: '24px' }}>
                                        <h3>{Intl.Get(LocIds.PageAndPanels.ThumbnailImageLabel)}</h3>
                                        <p className="c-heading-6">{Intl.Get(LocIds.PageAndPanels.ThumbnailImageSuggestionLabel)}</p>
                                        <p>{Intl.Get(LocIds.PageAndPanels.ThumbnailImageDescriptionLabel)}</p>
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={false}
                                                disabled={true}
                                                value={getLocalizedString(this.state.panelData.thumbnailImageTitle)}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />                                        
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton aria-label="Choose thumbnail image" onClick={() => this.setState({ showThumbnailImagePicker: true })} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showThumbnailImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length) {
                                                        this.setState({
                                                            panelData: {
                                                                ...this.state.panelData,
                                                                thumbnailImageId: images[0].url,
                                                                ...setDefaultLocalizedString({}, 'thumbnailImageTitle', images[0].title),
                                                            },
                                                            showThumbnailImagePicker: false
                                                        });
                                                    } else {
                                                        this.state.panelData.thumbnailImageId = '/Images/Panel-Infrastructure/Missing-Image.png';
                                                        setDefaultLocalizedString(this.state.panelData, 'thumbnailImageTitle', '');
                                                        this.setState({ panelData: this.state.panelData, showThumbnailImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showThumbnailImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12" style={{ marginTop: '24px' }}>
                                        <h3>{Intl.Get(LocIds.PageAndPanels.BackgroundImageLabel)}</h3>
                                        <p className="c-heading-6">{Intl.Get(LocIds.PageAndPanels.BackgroundImageSuggestionLabel)}</p>
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={false}
                                                disabled={true}
                                                value={getLocalizedString(this.state.panelData.backgroundImageTitle)}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton aria-label="Choose background image" onClick={() => this.setState({ showBackgroundImagePicker: true })} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showBackgroundImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length) {
                                                        this.setState({
                                                            panelData: {
                                                                ...this.state.panelData,
                                                                backgroundImageId: images[0].url,
                                                                ...setDefaultLocalizedString({}, 'backgroundImageTitle', images[0].title),
                                                            },
                                                            showBackgroundImagePicker: false
                                                        });
                                                    } else {
                                                        this.state.panelData.backgroundImageId = '/Images/Panel-Infrastructure/Missing-Image.png';
                                                        setDefaultLocalizedString(this.state.panelData, 'backgroundImageTitle', '');
                                                        this.setState({ panelData: this.state.panelData, showBackgroundImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showBackgroundImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: '6px' }} />
                                <PrimaryButton onClick={() => { this.onSave(); }} text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)} />
                                <DefaultButton onClick={() => { this.onDiscardChange(); }} text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)} />
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }

}