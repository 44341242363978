import { DetailsList, IColumn } from '@fluentui/react';
import * as React from 'react';
import { Intl } from '../../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { SeriesDefinition } from 'src/App/Models/Series/SeriesDefinition';

interface SeriesTableProps {
  seriesList: SeriesDefinition[];
}

interface SeriesTableState {
}

class SeriesTable extends React.Component<SeriesTableProps, SeriesTableState> {

  render() {
    const { seriesList } = this.props;

    const columns: IColumn[] = [
      {
        key: 'seriesName',
        name: Intl.Get(LocIds.SeriesManagmentPage.SeriesName),
        fieldName: 'seriesName',
        minWidth: 150,
      },
      {
        key: 'doucumentsCount',
        name: Intl.Get(LocIds.SeriesManagmentPage.DocumentsCount),
        fieldName: 'doucumentsCount',
        minWidth: 150,
      },
    ];

    const formatedData = seriesList.map((serie) => {
      return {
        id: serie.id,
        seriesName: serie.seriesName,
        doucumentsCount: serie.seriesDocuments.length
      };
    });

    return (
        <DetailsList
          items={formatedData}
          columns={columns}
        />
    );
  }
}

export default SeriesTable;
