import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { QuotePanelPropertiesData } from '../../Models/PageInfrastructure';
import { BasePanel } from './BasePanel';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import Constants from '../../Utilities/Constants';
import { Intl } from '../../Services/GlobalizationService';
import './QuotePanel.css';

export class QuotePanel extends BasePanel<QuotePanelPropertiesData> {
    constructor(props: BasicPanelProperties<QuotePanelPropertiesData>) {
        super(props);
    }

    getActualPanel() {
        return (
            <div data-panel-type="Quote" className="m-quote">
                <div className="c-quote__liner">
                    {this.state.panelData.quote && getLocalizedString(this.state.panelData.quote) !== '' &&
                        <blockquote className="c-paragraph-2 f-lean">
                            <span aria-hidden="true">"</span>
                            {getLocalizedString(this.state.panelData.quote)}
                            <span aria-hidden="true">"</span>
                        </blockquote>
                    }
                    {this.state.panelData.author &&
                        <div className="c-quote__attribution">{this.state.panelData.author}</div>
                    }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div data-grid="col-12">
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}}>
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>

                        {this.state.showEditor &&
                            <div>
                                <div data-grid="col-12" className="editPanel">
                                    <div data-grid="col-5">
                                        <TextFieldWrapper
                                            value={getLocalizedString(this.state.panelData.quote)}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                if (newVal.length > Constants.PANEL_DESCRIPTION_MAX) {
                                                    newVal = newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX);
                                                }
                                                setDefaultLocalizedString(this.state.panelData, 'quote', newVal);
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            placeholder={Intl.Get(LocIds.PageAndPanels.QuotePlaceHolder)}
                                            label={Intl.Get(LocIds.PageAndPanels.QuoteLabel)}
                                            required={true}
                                            validator={this.panelValidator}
                                        />
                                        <TextFieldWrapper
                                            value={this.state.panelData.author ? this.state.panelData.author : ''}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                    newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                }
                                                this.state.panelData.author = newVal;
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            placeholder={Intl.Get(LocIds.PageAndPanels.QuoteSourcePlaceHolder)}
                                            label={Intl.Get(LocIds.PageAndPanels.QuoteSourceLabel)}
                                            required={false}
                                            validator={this.panelValidator}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginBottom: '6px' }} />
                                <PrimaryButton onClick={() => { this.onSave(); }} text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)} />
                                <DefaultButton onClick={() => { this.onDiscardChange(); }} text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)} />
                            </div>
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}