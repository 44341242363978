import { Reducer } from 'redux';
import { CustomerActionModel, CMv3ApiResult } from '../../Models/ComplianceManagerV3';

export const RECEIVE_CUSTOMERACTIONSV3 = 'RECEIVE_CUSTOMERACTIONSV3';

export interface CustomerActionStateV3 {
    customerActions: CMv3ApiResult<CustomerActionModel> | null;
}

const unloadedState: CustomerActionStateV3 = {
    customerActions: null
};

export interface ReceiveCustomerActions {
    type: typeof RECEIVE_CUSTOMERACTIONSV3;
    data: CMv3ApiResult<CustomerActionModel>;
}

export type KnownAction = ReceiveCustomerActions;

export function receiveDashboardDetails(data: CMv3ApiResult<CustomerActionModel>): ReceiveCustomerActions {
    return {
        type: RECEIVE_CUSTOMERACTIONSV3,
        data: data
    };
}

export const reducer: Reducer<CustomerActionStateV3> = (state: CustomerActionStateV3, action: KnownAction): CustomerActionStateV3 => {
    switch (action.type) {
        case RECEIVE_CUSTOMERACTIONSV3:
            return {
                customerActions: action.data
            };
        default:
            return state || unloadedState;
    }    
};