import * as Redux from 'redux';
import { ErrorResponse } from '../Services/Models';
import { DocumentVersionInfo } from '../Models';

export interface PanelDocuments {
    loaded: boolean;
    pending: boolean;
    documents?: DocumentVersionInfo[];
    error?: string;
}

export const initialState: PanelDocuments = {
    loaded: false,
    pending: false
};

// Define the action type strings
export const PANEL_DOCUMENTS_REQUEST = 'PANEL_DOCUMENTS_REQUEST';
export type PANEL_DOCUMENTS_REQUEST = typeof PANEL_DOCUMENTS_REQUEST;

export const PANEL_DOCUMENTS_RECEIVED = 'PANEL_DOCUMENTS_RECEIVED';
export type PANEL_DOCUMENTS_RECEIVED = typeof PANEL_DOCUMENTS_RECEIVED;

export const PANEL_DOCUMENTS_ERROR = 'PANEL_DOCUMENTS_ERROR';
export type PANEL_DOCUMENTS_ERROR = typeof PANEL_DOCUMENTS_ERROR;

// For each action, define a strongly-typed interface which includes the action-specific properties.
export interface PanelDocumentsRequestAction {
    type: PANEL_DOCUMENTS_REQUEST;
}

export interface PanelDocumentsReceivedAction {
    type: PANEL_DOCUMENTS_RECEIVED;
    documents?: DocumentVersionInfo[];
}

export interface PanelDocumentsErrorAction {
    type: PANEL_DOCUMENTS_ERROR;
    error: string;
}

// Define the merged set of all the strongly-typed actions that can affect this model.
export type PanelDocumentsAction =
    PanelDocumentsRequestAction | PanelDocumentsReceivedAction | PanelDocumentsErrorAction;

// Define helper functions that return strongly-typed routable action instances that inject the action-specific properties into the routable objects.
export function requestPanelDocuments(): PanelDocumentsAction {
    return {
        type: PANEL_DOCUMENTS_REQUEST
    };
}

export function receivedPanelDocuments(documents?: DocumentVersionInfo[]): PanelDocumentsAction {
    return {
        documents,
        type: PANEL_DOCUMENTS_RECEIVED
    };
}

export function errorPanelDocuments(error: ErrorResponse): PanelDocumentsAction {
    return {
        error: error.message,
        type: PANEL_DOCUMENTS_ERROR
    };
}

// Define the reducer that performs the model changes required for all of the actions that can affect this model.
export const reducer: Redux.Reducer<PanelDocuments> =
    (oldState: PanelDocuments, action: PanelDocumentsAction): PanelDocuments => {
        switch (action.type) {
            case PANEL_DOCUMENTS_REQUEST:
                return {
                    ...oldState,
                    loaded: false,
                    pending: true
                };

            case PANEL_DOCUMENTS_RECEIVED:
                return {
                    ...oldState,
                    loaded: true,
                    pending: false,
                    documents: action.documents,
                };

            case PANEL_DOCUMENTS_ERROR:
                return {
                    ...oldState,
                    documents: undefined,
                    error: action.error,
                    loaded: false,
                    pending: false
                };

            default:
                return {
                    ...initialState,
                    ...oldState
                };
        }
    };
