import * as React from 'react';
import * as SimpleMDE from 'simplemde';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { ValidatableField } from '../../App/Components/OfficeWrapperProps';
import ErrorLabel from '../../App/Components/ErrorLabel';
import { Validator } from '../../Common/Utilities/Validator';

const styles = 'https://cdn.jsdelivr.net/simplemde/latest/simplemde.min.css';

interface MarkdownEditorProps {
    handleSave?: Function;
    handleCloseWithoutSave?: Function;
    value: string;
    label?: string;
    onChange?: (newValue: string) => void;
    showCommandButton?: boolean;
    required?: boolean;
    validator?: Validator;
    customValidator?: (value: string) => string;
    selectedIndex?: number;
    maxCharacter?: number;
    placeHolder?: string;
}

interface MarkdownEditorState {
    isDialog: boolean;
    indexHasChange: boolean;
    error: string;
    selectedIndex?: number;
}

export class MarkDownEditor extends React.Component<MarkdownEditorProps, MarkdownEditorState> implements ValidatableField {
    isUnmount: boolean;

    constructor(props: MarkdownEditorProps) {
        super(props);
        this.state = {
            isDialog: false,
            indexHasChange: false,
            error: '',
            selectedIndex: props.selectedIndex
        };
    }

    public UNSAFE_componentWillReceiveProps(nextPro: MarkdownEditorProps) {
        if (nextPro.selectedIndex !== this.state.selectedIndex) {
            this.setState({
                selectedIndex: nextPro.selectedIndex,
                indexHasChange: true
            });
        }
    }

    componentDidMount() {
        if (this.props.validator) {
            this.props.validator.add(this);
        }
        this.isUnmount = false;
    }

    componentDidUpdate() {
        if (this.state.indexHasChange) {
            this.setState({indexHasChange: false});
        }
    }

    componentWillUnmount() {
        this.isUnmount = true;
    }

    handleFullScreen(data: string) {
        this.setState({
            isDialog: true
        });
    }

    handleExitFullScreen(data: string) {
        this.setState({
            isDialog: false
        });
    }

    handleCloseWithoutSave() {
        this.setState({ isDialog: false });
        if (this.props.handleCloseWithoutSave) {
            this.props.handleCloseWithoutSave();
        }
    }

    handleSave(data: string) {
        this.setState({ isDialog: false });
        if (this.props.handleSave) {
            this.props.handleSave(data);
        }
    }

    validate() {
        if (this.isUnmount) {
            return true;
        }
        if (this.props.required && this.props.value === '') {
            this.setState({ error: 'Required' });
            return false;
        }
        if (this.props.customValidator) {
            const customError = this.props.customValidator(this.props.value || '');
            if (customError !== '') {
                this.setState({ error: customError });
                return false;
            }
        }
        this.setState({ error: '' });
        return true;
    }

    focus() {
        if (this.isUnmount) {
            return;
        }
    }

    render() {
        return (
            <div>
                {!this.state.isDialog ?
                    <div>
                        <label className={'ms-Label ' + (this.props.required ? 'is-required' : '')}>{this.props.label}</label>
                        {!this.state.indexHasChange && <Editor
                            handleCloseWithoutSave={() => this.handleCloseWithoutSave()}
                            value={this.props.value}
                            onChange={this.props.onChange}
                            handleSave={(e: string) => this.handleSave(e)}
                            handleFullScreen={(e: string) => { this.handleFullScreen(e); }}
                            showCommandButton={this.props.showCommandButton}
                            maxCharacter={this.props.maxCharacter}
                            placeHolder={this.props.placeHolder}
                        />}
                        {this.state.error !== '' &&
                            <ErrorLabel error={this.state.error} />
                        }
                    </div>
                    :
                    <Dialog
                        isOpen={this.state.isDialog}
                        type={DialogType.normal}
                        isBlocking={true}
                        containerClassName="viewMoreDialog"
                    >
                        {!this.state.indexHasChange && <Editor
                            handleCloseWithoutSave={() => this.handleCloseWithoutSave()}
                            value={this.props.value}
                            onChange={this.props.onChange}
                            handleSave={(e: string) => this.handleSave(e)}
                            handleExitFullScreen={(e: string) => { this.handleExitFullScreen(e); }}
                            maxCharacter={this.props.maxCharacter}
                            placeHolder={this.props.placeHolder}
                        />}
                    </Dialog>
                }
            </div>
        );
    }
}

interface EditorProps {
    handleSave?: Function;
    handleCloseWithoutSave?: Function;
    value: string;
    handleFullScreen?: Function;
    handleExitFullScreen?: Function;
    onChange?: (newValue: string) => void;
    showCommandButton?: boolean;
    maxCharacter?: number;
    placeHolder?: string;
}

class Editor extends React.Component<EditorProps> {

    private simplemde: SimpleMDE;
    // tslint:disable-next-line:no-any
    private mdeText: HTMLTextAreaElement;

    componentDidMount() {
        this.createEditor();
    }

    createEditor() {
        const initialOptions = {
            element: this.mdeText,
            initialValue: this.props.value,
        };
        const allOptions = { ...initialOptions, ...this.getMarkdownOptions() };
        this.simplemde = new SimpleMDE(allOptions);
        this.handleChange = this.handleChange.bind(this);
        this.beforeChange = this.beforeChange.bind(this);
        this.simplemde.codemirror.on('keyup', this.handleChange);
        this.simplemde.codemirror.on('keydown', this.handleChange);
        this.simplemde.codemirror.on('change', this.handleChange);
        this.simplemde.codemirror.on('beforeChange', this.beforeChange);
    }
    
    // tslint:disable-next-line:no-any
    beforeChange(instance: any, change: any) {
        if (this.props.maxCharacter && change.from.ch >= this.props.maxCharacter) {
            change.cancel();
        }
    }

    handleChange() {
        if (this.props.onChange) {
            this.props.onChange(this.simplemde.value());
        }
    }

    getMarkdownOptions() {
        return {
            autofocus: false,
            spellChecker: true,
            toolbar: [
                'heading-1', 'heading-smaller', 'heading-bigger', '|',
                'unordered-list', 'ordered-list', '|',
                'bold', 'italic', '|',
                'link', '|',
                'preview',
            ],
            toolbarTips: true,
            status: false,
            renderingConfig: { singleLineBreaks: false, codeSyntaxHighlighting: false },
        };
    }

    handleSave() {
        if (this.props.handleSave) {
            this.props.handleSave(this.simplemde.value() as string);
        }
    }

    handleClear() {
        this.simplemde.value('');
    }

    handleCloseWithoutSave() {
        if (this.props.handleCloseWithoutSave) {
            this.props.handleCloseWithoutSave();
        }
    }

    handleFullScreen() {
        const handleFullScreen = this.props.handleFullScreen;
        if (handleFullScreen) {
            handleFullScreen(this.simplemde.value());
        }
    }

    handleExitFullScreen() {
        const handleExitFullScreen = this.props.handleExitFullScreen;
        if (handleExitFullScreen) {
            handleExitFullScreen(this.simplemde.value());
        }
    }

    render() {
        return (
            <div>
                <link rel="stylesheet" type="text/css" href={styles} />
                <textarea
                    ref={(input: HTMLTextAreaElement) => this.mdeText = input}
                    placeholder={this.props.placeHolder}
                />
                {
                    this.props.showCommandButton ?
                        <div style={{ marginTop: '5px' }}>
                            <DefaultButton style={{ marginRight: '5px' }} description="Clear MarkDownEditor Text" text="Clear" onClick={() => this.handleClear()} />
                            {this.props.handleFullScreen !== undefined ?
                                <DefaultButton style={{ marginRight: '5px' }} description="Show The Editor in Dialog" text="Expand" onClick={() => this.handleFullScreen()} />
                                : null}
                            {this.props.handleExitFullScreen !== undefined ?
                                <DefaultButton style={{ marginRight: '5px' }} description="Remove The Editor from Dialog" text="Exit FullScreen" onClick={() => this.handleExitFullScreen()} />
                                : null}
                        </div>
                        : null
                }
            </div>
        );
    }
}
