import './OfficeFeedback.css';

import * as React from 'react';

import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { loadFeedbackAndBootstrap } from './FeedbackUtility';

import { FeedbackOptions, GetDefaultFeedBackOptions } from "./FeedbackOptions"
import { FeedbackUiType, openFeedback, updateFeedbackOptions } from '@ms/inapp-feedback-loader';

export default class OfficeFeedback extends React.Component<React.Props<{}>> {

  private feedbackData = FeedbackOptions

  constructor(props: React.Props<{}>) {
    super(props);

    loadFeedbackAndBootstrap();
  }

  private openFeedbackModal()
  { 
    // resetting anything that may have been set by the chatbot thumbs up/down feedback, since both share the same div and feedback config object
    this.feedbackData.feedbackConfig = GetDefaultFeedBackOptions()
    this.feedbackData.feedbackConfig!.initialFeedbackType = undefined;
    this.feedbackData.feedbackConfig!.scenarioConfig = undefined;

    this.feedbackData.feedbackConfig!.feedbackUiType = FeedbackUiType.Modal;
    this.feedbackData.feedbackConfig!.featureAreas = ["STP Documents", "Other"];

    // updateFeedbackOptions and openFeedback both appear to be necessary to fix issues with the feedback UI if the user clicked thumbs up/down first.
    updateFeedbackOptions(this.feedbackData);
    openFeedback(this.feedbackData);
  }

  render() {
    return (
      <section className="feedbackLinkContainer c-uhff-nav">
        <div  className="c-heading-5" style={{ paddingTop: '2em' }}>
          <button
            onClick={e => this.openFeedbackModal()}
            type="button"
            className="btn"
            id="feedbackButton"
            style={{ backgroundColor: '#0066b4', color: 'white' }}>
                <span>{Intl.Get(LocIds.PageAndPanels.FeedbackCallToAction)}</span>
          </button>
        </div>
      </section>
    );
  }
}
