import { StoreState } from '../../Store';
import { connect } from 'react-redux';
import { ErrorDialog, ErrorDialogActions, ErrorDialogProps } from '../Pages/ErrorDialog';

import * as reducer from '../Reducers/StatusMessageReducer';

function mapDispatchToProps(dispatch: (action: reducer.StatusMessageActions) => void): ErrorDialogActions {
    return {
        hideDialog: () => { dispatch(reducer.getClearAllAction()); },
    };
}

function mapStateToProps(state: StoreState): ErrorDialogProps {
    return {
        errorInfo: state.statusMessageManagement.errorInfo,
        showDialog: state.statusMessageManagement.IsError,        
        errorMessage: state.statusMessageManagement.errorMessage,
        isExceptionDialogStyle: state.statusMessageManagement.isExceptionDialogStyle,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);
