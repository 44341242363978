import { Reducer } from 'redux';
import { ComplianceDashboard } from '../../Models/ComplianceManagerV3';
import { AssessmentFilterV3 } from '../../Models/ComplianceManagerV3/AssessmentFilterV3';

export const RECEIVE_DASHBOARDDETAILSV3 = 'RECEIVE_DASHBOARDDETAILSV3';
export const SET_DEFAULT_CM_V3_GROUPID = 'SET_DEFAULT_CM_V3_GROUPID'; // Action type for setting current CM V3 group Id

export interface ReceiveDashboardDetails {
    type: typeof RECEIVE_DASHBOARDDETAILSV3;   
    data: ComplianceDashboard; 
}

export interface SetDefaultCmV3GroupAction {
    type: typeof SET_DEFAULT_CM_V3_GROUPID;
    currentGroupId?: string;
}

export type KnownAction =  ReceiveDashboardDetails | SetDefaultCmV3GroupAction;

export interface ComplianceDashboardStateV3 {
    isLoading: boolean;
    dashboard?: ComplianceDashboard;
    filter: AssessmentFilterV3;    
    currentGroupId?: string;
}

const unloadedState: ComplianceDashboardStateV3 = {
    isLoading: false,    
    filter: {
        isShowAssessmentTileView: true,
        isShowArchivedRecord: false,
        selectedDimensions: [],
        selectedIndices: [],
    },
};

export function receiveDashboardDetails(data: ComplianceDashboard): ReceiveDashboardDetails {
    return {
        type: RECEIVE_DASHBOARDDETAILSV3,
        data: data,
    };
}

export function getSetDefaultCmV3Action(groupId: string | undefined): SetDefaultCmV3GroupAction {
    return {
        type: SET_DEFAULT_CM_V3_GROUPID,
        currentGroupId: groupId,
    };
}

export const reducer: Reducer<ComplianceDashboardStateV3> = (state: ComplianceDashboardStateV3, action: KnownAction): ComplianceDashboardStateV3 => {    
    switch (action.type) {
        case RECEIVE_DASHBOARDDETAILSV3:   
            return {
                    ...state,
                    isLoading: false,
                    dashboard: action.data,
                    filter: state.filter,                    
                };
        case SET_DEFAULT_CM_V3_GROUPID:
            return {
                ...state,
                currentGroupId: action.currentGroupId,
            };
        default:                
    }
    return state || unloadedState;
};