import { Dimension } from './Dimension';

export interface TemplateFilterV3 {
    ShowArchivedRecord: boolean;
    ShowApprovedRecord: boolean;
    ShowUnapprovedRecord: boolean;
    ShowLockedRecord: boolean;
    selectedDimensions: Dimension[];
}

export const getDefault = (): TemplateFilterV3 => {
    return {
        ShowArchivedRecord: false,
        ShowApprovedRecord: true,
        ShowUnapprovedRecord: true,
        ShowLockedRecord: true,
        selectedDimensions: [],
    };
};

export const IsFilterSet = (filter: TemplateFilterV3): boolean => {
    if (!filter || filter === null) {
        return false;
    }
    const def = getDefault();
    return filter.ShowApprovedRecord !== def.ShowApprovedRecord ||
        filter.ShowUnapprovedRecord !== def.ShowUnapprovedRecord ||
        filter.ShowLockedRecord !== def.ShowLockedRecord ||
        filter.selectedDimensions.length > 0;
    return false;
};