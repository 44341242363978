import { Reducer } from 'redux';
import { ActionItemsFilterOptions, CloudService, FrameworkControlViewModel, FrameworkFamilyViewModel, ImplementationStatus, CustomerImplementationPriority } from '../Models/ComplianceDashboard';
import { AssessControl, ASSESS_CONTROL, UpdateUploadState, UpdateDeleteState, UPDATE_DELETE, UPDATE_UPLOAD } from './FrameworkDetail';

export const UPDATE_ACTION_ITEM = 'UPDATE_ACTION_ITEM';
export const UPDATE_COMPLETE = 'UPDATE_COMPLETE';
export const RECEIVE_ALLFAMILIES = 'RECEIVE_ACTIONITEMS';
export const RECEIVE_FAMILYDETAILS = 'RECEIVE_FAMILYDETAILS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const ASSESS_IMPLEMENT_CONTROL = 'ASSESS_IMPLEMENT_CONTROL';

export interface ActionItemsState {
    isLoading: boolean;
    customerControled: CloudService;
    actionItemsViewModel: FrameworkFamilyViewModel[];
    filter: ActionItemsFilterOptions;
}

export interface ApplyFilter {
    type: typeof APPLY_FILTER;
    filter: ActionItemsFilterOptions;
}

interface Update {
    type: typeof UPDATE_ACTION_ITEM;
}

interface UpdateComplete {
    type: typeof UPDATE_COMPLETE;
}

interface ReceiveAllFamilies {
    type: typeof RECEIVE_ALLFAMILIES;
    data: FrameworkFamilyViewModel[];
}

interface ReceiveFamilyDetails {
    type: typeof RECEIVE_FAMILYDETAILS;
    data: FrameworkControlViewModel[];
    controlFamilyId: string;
}

export interface AssessImplementControl {
    type: typeof ASSESS_IMPLEMENT_CONTROL;
    data: FrameworkControlViewModel;
}

export type KnownAction =
    ReceiveAllFamilies |
    ReceiveFamilyDetails |
    Update |
    UpdateComplete |
    ApplyFilter |
    AssessImplementControl |
    AssessControl |
    UpdateUploadState |
    UpdateDeleteState;

export function receiveAllFamilies(data: FrameworkFamilyViewModel[]): KnownAction {
    return {
        type: RECEIVE_ALLFAMILIES,
        data: data,
    };
}

export function receiveFrameworkControlFamily(data: FrameworkControlViewModel[], controlFamilyId: string): KnownAction {
    return {
        type: RECEIVE_FAMILYDETAILS,
        data: data,
        controlFamilyId: controlFamilyId
    };
}

export function updateActionItem(): KnownAction {
    return { type: UPDATE_ACTION_ITEM };
}

export function filterAction(options: ActionItemsFilterOptions): KnownAction {
    return { type: APPLY_FILTER, filter: options };
}

export function assessControl(data: FrameworkControlViewModel): KnownAction {
    return {
        type: ASSESS_IMPLEMENT_CONTROL,
        data: data,
    };
}

const unloadedState: ActionItemsState = {
    isLoading: false,
    customerControled: {} as CloudService,
    actionItemsViewModel: [],
    filter: {} as ActionItemsFilterOptions,
};

export const reducer: Reducer<ActionItemsState> = (state: ActionItemsState, action: KnownAction): ActionItemsState => {
    // tslint:disable-next-line:switch-default
    switch (action.type) {
        case RECEIVE_ALLFAMILIES:
            return {
                customerControled: state.customerControled,
                isLoading: false,
                actionItemsViewModel: action.data,
                filter: state.filter,
            };
        case RECEIVE_FAMILYDETAILS:
            let stateCopy = { ...state, isLoading: false };
            if (action.data) {
                let index: number = stateCopy.actionItemsViewModel.findIndex(o => o.id === action.controlFamilyId);
                stateCopy.actionItemsViewModel[index].controlFamilyDetails = action.data;
                stateCopy.actionItemsViewModel = stateCopy.actionItemsViewModel.slice();
            }
            return stateCopy;
        case UPDATE_ACTION_ITEM:
            let sCopy = { ...state, isLoading: true };
            return sCopy;
        case UPDATE_COMPLETE:
            let cCopy = { ...state, isLoading: false };
            return cCopy;
        case APPLY_FILTER:
            let myFilter = { ...action.filter };
            let dCopy = { ...state, filter: myFilter };
            return dCopy;
        case ASSESS_IMPLEMENT_CONTROL:
            if (!action.data.userActions) {
                return state;
            }

            let familyIndex = state.actionItemsViewModel.findIndex(f => f.id === action.data.controlFamilyId);
            let family = state.actionItemsViewModel[familyIndex];
            if (!family) {
                return state;
            }

            family.actionItemsCompletedCount =
                family.assignedCount =
                family.highPriorityCount =
                family.lowPriorityCount =
                family.mediumPriorityCount =
                family.implementedCount =
                family.alternativeImplementationCount =
                family.notInScopeCount =
                family.plannedCount = 0;

            family.controlFamilyDetails.forEach(c => {
                if (!c.userActions) {
                    return;
                }
                switch (c.userActions.assignment.implementationStatus) {
                    case ImplementationStatus.AlternativeImplementation:
                        family.alternativeImplementationCount++;
                        family.actionItemsCompletedCount++;
                        break;
                    case ImplementationStatus.Implemented:
                        family.actionItemsCompletedCount++;
                        family.implementedCount++;
                        break;
                    case ImplementationStatus.NotInScope:
                        family.notInScopeCount++;
                        break;
                    case ImplementationStatus.Planned:
                        family.plannedCount++;
                        break;
                    default:
                        break;
                }
                switch (c.userActions.assignment.priority) {
                    case CustomerImplementationPriority.High:
                        family.highPriorityCount++;
                        break;
                    case CustomerImplementationPriority.Medium:
                        family.mediumPriorityCount++;
                        break;
                    case CustomerImplementationPriority.Low:
                        family.lowPriorityCount++;
                        break;
                    default:
                        break;
                }
            });

            state.actionItemsViewModel[familyIndex] = { ...family };
            return { ...state, actionItemsViewModel: state.actionItemsViewModel.slice() };
        case ASSESS_CONTROL:
            return state;
        case UPDATE_UPLOAD:
            let newUploadState = {
                ...state,
                actionItemsViewModel: state.actionItemsViewModel ? state.actionItemsViewModel.slice() : []
            };

            let fIndex = -1;
            if (newUploadState.actionItemsViewModel.length <= 0 || (fIndex = newUploadState.actionItemsViewModel.findIndex(f => f.id === action.familyId)) < 0) {
                return state;
            }

            let fData = newUploadState.actionItemsViewModel[fIndex];
            let cIndex = fData.controlFamilyDetails && fData.controlFamilyDetails.length > 0 ? fData.controlFamilyDetails.findIndex(o => o.microsoftControl.id === action.controlId) : -1;
            if (cIndex < 0) {
                return state;
            }

            newUploadState.actionItemsViewModel.splice(fIndex, 1, fData);
            
            let control = { ...fData.controlFamilyDetails[cIndex] } as FrameworkControlViewModel;
            if (!control.userActions) {
                return state;
            }

            if (!control.userActions.documentInfos) {
                control.userActions.documentInfos = [];
            } else {
                control.userActions.documentInfos = control.userActions.documentInfos.slice();
            }
            control.userActions.documentInfos.push(action.document);
            fData.controlFamilyDetails.splice(cIndex, 1, control);

            return newUploadState;
        case UPDATE_DELETE:
            let newDeleteState = {
                ...state,
                actionItemsViewModel: state.actionItemsViewModel ? state.actionItemsViewModel.slice() : []
            };
            let fdIndex = -1;
            if (newDeleteState.actionItemsViewModel.length <= 0 || (fdIndex = newDeleteState.actionItemsViewModel.findIndex(f => f.id === action.familyId)) < 0) {
                return state;
            }

            let fdData = newDeleteState.actionItemsViewModel[fdIndex];
            let cdIndex = fdData.controlFamilyDetails && fdData.controlFamilyDetails.length > 0 ? fdData.controlFamilyDetails.findIndex(o => o.microsoftControl.id === action.controlId) : -1;
            if (cdIndex < 0) {
                return state;
            }

            newDeleteState.actionItemsViewModel.splice(fdIndex, 1, fdData);

            let dcontrol = { ...fdData.controlFamilyDetails[cdIndex] } as FrameworkControlViewModel;
            let deleteIndex: number = -1;
            if (!dcontrol.userActions || (deleteIndex = dcontrol.userActions.documentInfos.findIndex(o => o.fileId === action.document.fileId)) < 0) {
                return state;
            }

            dcontrol.userActions.documentInfos.splice(deleteIndex, 1);
            dcontrol.userActions.documentInfos = dcontrol.userActions.documentInfos.slice();
            fdData.controlFamilyDetails.splice(cdIndex, 1, dcontrol);

            return newDeleteState;
    }

    return state || unloadedState;
};
