import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { StoreState } from '../../Store';
import {
    AdvancedDocumentTable,
    AdvancedDocumentTableActions,
    AdvancedDocumentTableModel,
    AdvancedDocumentTablePassthrough
} from '../Components/AdvancedDocumentTable';
import * as MyLibrary from '../Reducers/MyLibrary';
import { ErrorResponse } from '../Services/Models';

// Define how application-specific global state maps to the component's public properties.
export function mapStateToProps(state: StoreState, ownProps: AdvancedDocumentTablePassthrough ): AdvancedDocumentTableModel {
    const {
        myLibrary: { docList, loaded, pending },
        authentication: { isSignedIn, context },
        applicationContext: { userInfo: { hasAgreedToCurrentLicenseAgreement } } } = state;

    return {
        hasAgreedToCurrentLicenseAgreement,
        isSignedIn,
        authContext: context,
        myLibraryDocuments: docList || [],
        myLibraryLoaded: loaded,
        myLibraryPending: pending,
        ...ownProps // pass-through all the remaining props
    };
}

// Define how component events map to application-specific dispatachable actions.
export function mapDispatchToProps(dispatch: Dispatch<AnyAction>): AdvancedDocumentTableActions {
    return {
        receivedAddToMyLibrary: () => { dispatch(MyLibrary.receivedAddToMyLibrary()); },
        receivedMyLibraryList: (docList?: MyLibrary.MyLibraryDocument[]) => { dispatch(MyLibrary.receivedMyLibraryList(docList || [])); },
        errorMyLibraryList: (error: ErrorResponse) => { dispatch(MyLibrary.errorMyLibraryList(error)); },
        receivedRemoveFromMyLibrary: () => { dispatch(MyLibrary.receivedRemoveFromMyLibrary()); },
        requestAddToMyLibrary: (docs: MyLibrary.MyLibraryDocument[]) => { dispatch(MyLibrary.requestAddToMyLibrary(docs)); },
        errorAddToMyLibrary: (error: ErrorResponse) => { dispatch(MyLibrary.errorAddToMyLibrary(error)); },
        requestMyLibraryList: () => { dispatch(MyLibrary.requestMyLibraryList()); },
        requestRemoveFromMyLibrary: (doc: MyLibrary.MyLibraryDocument) => { dispatch(MyLibrary.requestRemoveFromMyLibrary(doc)); },
        errorRemoveFromMyLibrary: (error: ErrorResponse) => { dispatch(MyLibrary.errorRemoveFromMyLibrary(error)); },
    };
}

/**
 * This connected component allows you to pass through a lot of the document information and filtering
 * configuration but it will bootstrap itself with the 'My Library' actions, allowing it to perform
 * updates without any additional wiring for the pages that leverage it.
 */
export default connect(mapStateToProps, mapDispatchToProps)(AdvancedDocumentTable);
