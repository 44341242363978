import * as React from 'react';

export interface ErrorLabelProps {
    error: string;
}

export default class ErrorLabel extends React.Component<ErrorLabelProps> {
    render() {
        return (
            <p className="errorLabel">
                {this.props.error}
            </p>
        );
    }
}