import { StoreState } from '../../Store';
import { connect } from 'react-redux';
import { ExportDataRequestPageProps } from '../Pages/PrivacyPortal/ExportDataRequestPage';
import ExportDataRequestPage from '../Pages/PrivacyPortal/ExportDataRequestPage';

function mapStateToProps(state: StoreState): ExportDataRequestPageProps {
    return {
        azureSubscriptions: state.privacyPortal.azureSubscriptions,
        azureStorageAccounts: state.privacyPortal.azureStorageAccounts,
    };
}

export default connect(mapStateToProps)(ExportDataRequestPage);
