export enum UserActionStatus {
    Select,
    NotAssessed,
    Passed,
    FailedLowRisk,
    FailedMediumRisk,
    FailedHighRisk,
    NotInScope,
    ToBeDetermined,
    CouldNotBeDetermined,
    PartiallyTested
}