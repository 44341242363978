import { AxiosResponse } from 'axios';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { ErrorResponse } from './Models/ErrorResponse';
import { BaseRequest } from './Models/BaseRequest';
import { BaseResponse } from './Models/BaseResponse';
import { ImageInfo } from '../Models/Image';

export interface ImageResponse extends BaseResponse {
    imageInfos: ImageInfo[] | undefined;
}

export interface AddImageRequest extends BaseRequest {
    fileUploadId: string;
    title?: string;
    fileSize?: number;
}

class ImageManagementService {
    addImage(
        requestData: AddImageRequest,
        beforeRequest: () => void,
        onRequestSuccess: () => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
            let api: string = '/api/Images/' + requestData.fileUploadId;
            ServiceTrustClient.doRequest_New(
                'POST', api, requestData, beforeRequest,
                (response: AxiosResponse) => {
                    onRequestSuccess();
                },
                onRequestFailed, 'uploadImageDialogMessage');
        }

    deleteImages(
        imageInfos: ImageInfo[],
        beforeRequest: () => void,
        onRequestSuccess: (response: ImageResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
            let imageIds: string[] = [];
            for (let index = 0; index < imageInfos.length; index++) {
                imageIds[index] = imageInfos[index].id;
            }
            ServiceTrustClient.doRequest_New(
                'DELETE', '/api/Images/Delete', imageIds, beforeRequest,
                (response: AxiosResponse) => {
                    let res: ImageResponse = {
                        code: response.status,
                        status: response.statusText,
                        message: response.statusText,
                        imageInfos: undefined
                    };
                    onRequestSuccess(res);
                },
                onRequestFailed);
        }

    getAllImages(
        beforeRequest: () => void,
        onRequestSuccess: (response: ImageResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
            ServiceTrustClient.doRequest_New(
                'GET', '/api/Images/All', /*requestData*/null, beforeRequest,
                (response: AxiosResponse) => {
                    let imageResponse: ImageResponse = {
                        code: response.status,
                        status: response.statusText,
                        message: response.statusText,
                        imageInfos: response.data
                    };
                    onRequestSuccess(imageResponse);
                },
                onRequestFailed);
        }
}

export let ImageManagementServiceClient = new ImageManagementService();
