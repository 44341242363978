import { AuditEvent } from '../../App/Models/ComplianceManagerV3/AuditEvent';

const groupsLookup: AuditEvent[] = [
    { id: 'a6c11990-197e-4174-86cf-e72ff33ec575', name: 'HIPPA Certification 2018' },
    { id: '76d3bb8d-0ead-4dec-85fa-b785e379d3a2', name: 'ISO 27001 Certification 2017' },
    { id: '89d3bb8d-0ead-4dec-85fa-b785e379d3a2', name: 'FedRamp Test Audit' }];

export class MockGroupService {
    getGroupLookup(): AuditEvent[] {
        return groupsLookup;
    }
}

export const MockClientGroupService = new MockGroupService();