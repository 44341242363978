import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { LeftRightViewsCollection } from './LeftRightViewsCollection';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { getDefaultView } from '../../Utilities/PageInfrastructure/ViewsHelper';
import { ViewData, PanelData, ViewPanelPropertiesData } from '../../Models/PageInfrastructure';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { LeftRightViewsPanelPropertiesData } from '../../Models/PageInfrastructure/PanelPropertiesData/LeftRightViewsPanelPropertiesData';

interface LeftRightPanelState {
    showEditor: boolean;
    panelData: PanelData<LeftRightViewsPanelPropertiesData>;
    selectedView: ViewData;
    modifiedViewWidths: number[];
    totalViewWidth: number;
}

export class LeftRightPanel extends React.Component<BasicPanelProperties<LeftRightViewsPanelPropertiesData>, LeftRightPanelState> {
    private backup: PanelData<LeftRightViewsPanelPropertiesData>;

    constructor(props: BasicPanelProperties<LeftRightViewsPanelPropertiesData>) {
        super(props);
        this.backup = getDeepCopy(this.props.panel);
        this.state = {
            showEditor: false,
            panelData: this.props.panel,
            selectedView: this.props.panel.views[this.props.panel.views.findIndex(x => x.isSelected)],
            modifiedViewWidths: [],
            totalViewWidth: 12
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: BasicPanelProperties<LeftRightViewsPanelPropertiesData>) {
        this.setDefaultSelected(nextProps.panel);
        this.setState({
            showEditor: false,
            panelData: nextProps.panel,
            selectedView: nextProps.panel.views[nextProps.panel.views.findIndex(x => x.isSelected)]
        });
        this.backup = getDeepCopy(nextProps.panel);
    }

    setDefaultSelected(panel: PanelData<LeftRightViewsPanelPropertiesData>) {
        if (panel.views.length > 0 && panel.views.findIndex(x => x.isSelected) === -1) {
            panel.views.forEach((v) => v.isSelected = false);
            panel.views[0].isSelected = true;
        }
    }

    saveModifiedViewData() {
        this.props.panel.views.forEach((view, index) => {
            view.widthInGridUnits = this.state.modifiedViewWidths[index];
        });
        if (this.props.panel.views.length < this.state.modifiedViewWidths.length) {
            let newView: ViewData = getDefaultView();
            newView.widthInGridUnits = 2;
            newView.isSelected = true;
            this.state.panelData.views.forEach(x => x.isSelected = false);
            this.state.panelData.views.push(newView);
            this.setState({ selectedView: newView });
        }
        this.setState({ showEditor: false, panelData: this.props.panel });
        this.props.makeDirty();
    }

    showViewDataModal() {
        var modifiedViewWidths: number[] = [];
        this.state.panelData.views.forEach(view => {
            modifiedViewWidths.push(view.widthInGridUnits || 0);
        });
        this.setState({ showEditor: true, modifiedViewWidths: modifiedViewWidths, totalViewWidth: this.sum(modifiedViewWidths) });
    }

    dismissModal() {
        this.setState({ showEditor: false });
    }

    sum(arr: number[]): number {
        var s: number = 0;
        arr.forEach(item => s += item);
        return s;
    }

    onAddNewView() {
        var modifiedViewWidths: number[] = [];
        this.state.panelData.views.forEach(view => {
            modifiedViewWidths.push(view.widthInGridUnits || 0);
        });
        modifiedViewWidths.push(2);
        this.setState({ showEditor: true, modifiedViewWidths: modifiedViewWidths, totalViewWidth: this.sum(modifiedViewWidths) });
    }

    onModifyView(index: number) {
        this.showViewDataModal();
    }

    onMoveViewLeft(index: number) {
        if (index > 0) {
            this.swapViews(index, index - 1);
            this.props.makeDirty();
        }
    }

    onMoveViewRight(index: number) {
        if (index < this.state.panelData.views.length - 1) {
            this.swapViews(index, index + 1);
            this.props.makeDirty();
        }
    }

    swapViews(index1: number, index2: number) {
        let temp = this.state.panelData.views[index1];
        this.state.panelData.views[index1] = this.state.panelData.views[index2];
        this.state.panelData.views[index2] = temp;
        this.setState({panelData: this.state.panelData});
    }

    onDeleteView(index: number) {
        let views = this.state.panelData.views;
        if (index >= views.length) {
            return;
        }
        var deleteView = views[index];
        var deleteViewWidth = deleteView && deleteView.widthInGridUnits ? deleteView.widthInGridUnits : 0;
        this.state.panelData.views = [];
        views.forEach((x, i) => {
            x.widthInGridUnits = x.widthInGridUnits || 0;
            if (i === (index - 1)) {
                x.widthInGridUnits += deleteViewWidth;
            }
            if (i !== index) {
                this.state.panelData.views.push(x);
            }
        });
        this.setState({panelData: this.state.panelData});
        this.props.makeDirty();
    }

    render(): JSX.Element {
        return (
            <div>
                <LeftRightViewsCollection
                    views={this.state.panelData.views}
                    panel={this.state.panelData}
                    isInEditMode={this.props.isInEditMode}
                    addNewView={() => this.onAddNewView()}
                    modifySelectedView={(index: number) => this.onModifyView(index)}
                    moveSelectedViewLeft={(index: number) => this.onMoveViewLeft(index)}
                    moveSelectedViewRight={(index: number) => this.onMoveViewRight(index)}
                    deleteSelectedView={(index: number) => this.onDeleteView(index)}
                    makeDirty={() => this.props.makeDirty()}
                    moveUp={() => this.props.moveUp(this.state.panelData)}
                    moveDown={() => this.props.moveDown(this.state.panelData)}
                    delete={() => this.props.delete(this.state.panelData)}
                    idOfPage={this.props.idOfPage}
                    parentId={this.props.panel.id}
                />
                <Dialog
                    hidden={!this.state.showEditor}
                    onDismiss={() => this.dismissModal()}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: Intl.Get(LocIds.PageAndPanels.LeftRightModifyTitle),
                        subText: Intl.Get(LocIds.PageAndPanels.LeftRightModifyDescription)
                    }}
                    modalProps={{
                        titleAriaId: Intl.Get(LocIds.PageAndPanels.LeftRightModifyTitle),
                        subtitleAriaId: Intl.Get(LocIds.PageAndPanels.LeftRightModifyDescription),
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <div>
                        {this.state.modifiedViewWidths.map((widthInView, index) => 
                            <TextField 
                                key={index}
                                label={`${Intl.Get(LocIds.PageAndPanels.PanelLabel)} ${index + 1}`} 
                                type="number" 
                                value={(widthInView).toString()} 
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                    this.state.modifiedViewWidths[index] = parseInt(newVal, 0);
                                    this.setState({totalViewWidth: this.sum(this.state.modifiedViewWidths)});
                                }} 
                            />
                        )}
                                           
                    </div>
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.saveModifiedViewData()} disabled={this.state.totalViewWidth !== 12}>{Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}</PrimaryButton>
                        <DefaultButton onClick={() => this.dismissModal()}>{Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}</DefaultButton>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}