import { AxiosResponse } from 'axios';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { ErrorResponse } from './Models/ErrorResponse';
import { BaseResponse } from './Models/BaseResponse';
import { LicenseAgreementDetails } from './Models/LicenseAgreementDetails';

export interface LicenseAgreementResponse extends BaseResponse {
    licenseDetails: LicenseAgreementDetails;
}

class LicenseAgreementService {
    getAgreementStatus(
        beforeRequest: () => void,
        onRequestSuccess: (response: boolean) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        ServiceTrustClient.doRequest_New(
            'GET', '/api/LicenseAgreement', /*requestData*/null, beforeRequest,
            (response: AxiosResponse) => {
                var status: boolean = response.data === 'true';
                onRequestSuccess(status);
            },
            onRequestFailed);
    }

    getAgreement(
        beforeRequest: () => void,
        onRequestSuccess: (response: LicenseAgreementResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        ServiceTrustClient.doRequest_New(
            'GET', '/api/LicenseAgreement/content', /*requestData*/null, beforeRequest,
            (response: AxiosResponse) => {
                var licenseDetails = response.data as LicenseAgreementDetails;
                let licenseResponse: LicenseAgreementResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    licenseDetails: licenseDetails
                };
                onRequestSuccess(licenseResponse);
            },
            onRequestFailed);
    }

    postLicenseAgreement(
        id: string,
        beforeRequest: () => void,
        onRequestSuccess: () => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        let api: string = '/api/LicenseAgreement?licenseAgreementId=' + id;
        ServiceTrustClient.doRequest_New(
            'PUT', api, null, beforeRequest,
            (response: AxiosResponse) => {
                onRequestSuccess();
            },
            onRequestFailed);
    }
}

export let LicenseAgreementServiceClient = new LicenseAgreementService();
