import { connect } from 'react-redux';
import { StoreState } from '../../Store';
import { AnyAction, Dispatch } from 'redux';
import * as PanelDocumentsActions from '../Reducers/PanelDocuments';
import * as PanelPagesActions from '../Reducers/PanelPages';
import * as VocabulariesManagementActions from '../Reducers/VocabulariesManagement';
import { DocumentListPanelPropertiesData, PageMetadata } from '../Models/PageInfrastructure';
import { DocumentListPanelRedux, DocumentListPanelActions } from '../Components/PageInfrastructure/DocumentListPanel';
import { DocumentVersionInfo } from '../Models';
import { VocabularyDefinition } from '../Models/Vocabulary';
import { BasicPanelProperties } from '../Utilities/PageInfrastructure/EditablePanelHelper';
import { DocumentListPanel } from '../Components/PageInfrastructure/DocumentListPanel';

// Define how application-specific global state maps to the component's public properties.
export function mapStateToProps(state: StoreState, ownProps: BasicPanelProperties<DocumentListPanelPropertiesData>): DocumentListPanelRedux {
    const {
        panelDocuments,
        panelPages,
        vocabulariesManagement,
        authentication: { isSignedIn },
        applicationContext: { userInfo: { hasAgreedToCurrentLicenseAgreement } }
    } = state;

    return {
        documentsPending: panelDocuments.pending,
        documentsLoaded: panelDocuments.loaded,
        documents: panelDocuments.documents,
        pagesPending: panelPages.pending,
        pagesLoaded: panelPages.loaded,
        pages: panelPages.pages,
        vocabulariesPending: vocabulariesManagement.status === 'Requesting',
        vocabularies: vocabulariesManagement.vocabularyDefinitions,
        vocabulariesLoaded: vocabulariesManagement.status === 'Finished',
        isSignedIn,
        hasAgreedToCurrentLicenseAgreement
    };
}

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DocumentListPanelActions {
    return {
        requestPanelDocuments: () => { dispatch(PanelDocumentsActions.requestPanelDocuments()); },
        receivedPanelDocuments: (documents?: DocumentVersionInfo[]) => { dispatch(PanelDocumentsActions.receivedPanelDocuments(documents)); },
        requestPanelPages: () => { dispatch(PanelPagesActions.requestPanelPages()); },
        receivedPanelPages: (pages?: PageMetadata[]) => { dispatch(PanelPagesActions.receivedPanelPages(pages)); },
        fetchVocabularies: () => { dispatch(VocabulariesManagementActions.fetchVocabularies()); },
        saveVocabularies: (vocabularies?: VocabularyDefinition[]) => { dispatch(VocabulariesManagementActions.saveVocabulary(vocabularies || [])); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListPanel);
