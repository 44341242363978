import { TenantSettings } from '../../App/Models/Settings/TenantSettings';
import { SecureScoreIntegrationLevel } from '../../../src/App/Models/ComplianceManagerV3/SecureScoreIntegrationLevel';

const tenantSettings = {
    SecureScoreIntegrationLevel:SecureScoreIntegrationLevel.EnableForAllActions
}  as TenantSettings;
export class MockTenantSettingsService {
    getTenantSettings(): TenantSettings {
        return tenantSettings;
    }
}

export const MockTenantSettings = new MockTenantSettingsService();