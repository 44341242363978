import * as React from 'react';
import { CommandButton } from 'office-ui-fabric-react/lib/Button';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';

interface PanelEditorProps {
    edit: () => void;
    delete: () => void;
    moveUp: () => void;
    moveDown: () => void;
}

export class PanelEditor extends React.Component<PanelEditorProps> {
    render() {
        return (
            <div className="col" style={{background: '#f2f2f2'}}>
                <CommandButton className="row" iconProps={{ iconName: 'Edit' }} ariaDescription={Intl.Get(LocIds.PageAndPanels.EditPanelButtonDescription)} text={Intl.Get(LocIds.PageAndPanels.EditButtonLabel)} onClick={() => this.props.edit()} />
                <CommandButton className="row" iconProps={{ iconName: 'RecycleBin'}} ariaDescription={Intl.Get(LocIds.PageAndPanels.DeletePanelButtonDescription)} text={Intl.Get(LocIds.PageAndPanels.DeleteButtonLabel)} onClick={() => this.props.delete()} />
                <CommandButton className="row" iconProps={{ iconName: 'ChevronUp' }} ariaDescription={Intl.Get(LocIds.PageAndPanels.MoveUpPanelButtonDescription)} text={Intl.Get(LocIds.PageAndPanels.MoveUpButtonLabel)} onClick={() => this.props.moveUp()} />
                <CommandButton className="row" iconProps={{ iconName: 'ChevronDown'}} ariaDescription={Intl.Get(LocIds.PageAndPanels.MoveDownPanelButtonDescription)} text={Intl.Get(LocIds.PageAndPanels.MoveDownButtonLabel)} onClick={() => this.props.moveDown()} />
            </div>
        );
    }
}