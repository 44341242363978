import { ChatBotStepEnum } from "./types";

export const getChatBotStepValueOrUndefined = (steps: Record<ChatBotStepEnum, { value: string }> | undefined, step: ChatBotStepEnum): string | undefined => {
    return steps && steps[step] && steps[step].value || undefined;

}

export const getExtensionFromFileName = (
    fileName: string,
  ): string | undefined => {
    if (typeof fileName !== 'string') {
      return undefined;
    }
  
    const name = fileName.trim();
  
    if (name === '') {
      return undefined;
    }
  
    return name.split('.').pop();
  };

