import * as Redux from 'redux';
import { SKAiSource } from 'src/App/Services/Models/SKAi/SKAiSource';

interface QuestionAndAnswer {
    question: string,
    answer: string,
    sources: Array<SKAiSource>
}

export interface ConversationHistory {
    conversationId : string
    conversationData : QuestionAndAnswer[]
}

export const initialState: ConversationHistory = {
    conversationId: '',
    conversationData: []
};

// Define the strings that will be used when dispatching the actions that affect this model.
export const CONVERSATIONID_CHANGED_ACTION = 'CONVERSATIONID_CHANGED_ACTION';
export type CONVERSATIONID_CHANGED_ACTION = typeof CONVERSATIONID_CHANGED_ACTION;
export const CONVERSATIONDATA_CHANGED_ACTION = 'CONVERSATIONDATA_CHANGED_ACTION';
export type CONVERSATIONDATA_CHANGED_ACTION = typeof CONVERSATIONDATA_CHANGED_ACTION;
export const CONVERSATIONDATA_CLEARED_ACTION = 'CONVERSATIONDATA_CLEARED_ACTION';
export type CONVERSATIONDATA_CLEARED_ACTION = typeof CONVERSATIONDATA_CLEARED_ACTION;

// For each action, define a strongly-typed interface which includes the action-specific properties.
export interface ConversationIdChangedAction {
    type: CONVERSATIONID_CHANGED_ACTION;
    conversationId: string
}

export interface ConversationDataChangedAction {
    type: CONVERSATIONDATA_CHANGED_ACTION;
    conversationData: QuestionAndAnswer[]
}

export interface ConversationDataClearedAction {
    type: CONVERSATIONDATA_CLEARED_ACTION;
}

// Define the merged set of all the strongly-typed actions that can affect this model.
export type ConversationAction = ConversationIdChangedAction | ConversationDataChangedAction | ConversationDataClearedAction

// Define helper functions that return strongly-typed routable action instances that inject the action-specific properties into the routable objects.
export function addConversationHistoryEntry(conversationHistory: QuestionAndAnswer): ConversationAction {
    return {
        conversationData: [conversationHistory],
        type: CONVERSATIONDATA_CHANGED_ACTION
    };
}

export function setConverstationId(newConversationId: string): ConversationAction {
    return {
        conversationId: newConversationId,
        type: CONVERSATIONID_CHANGED_ACTION
    };
}

export function clearConversationHistory(): ConversationAction {
    return {
        type: CONVERSATIONDATA_CLEARED_ACTION
    };
}

// Define the reducer that performs the model changes required for all of the actions that can affect this model.
export const reducer: Redux.Reducer<ConversationHistory> =
    (oldState: ConversationHistory, action: ConversationAction): ConversationHistory => {
        switch (action.type) {
            case CONVERSATIONID_CHANGED_ACTION:
                return {
                    ...oldState,
                    conversationId: action.conversationId,
                };

            case CONVERSATIONDATA_CHANGED_ACTION:
                return {
                    ...oldState,
                    conversationData: [...oldState.conversationData, ...action.conversationData]
                };
            
            // in the case where we are clearing the conversation history, we just return the object in the store to the initial blank state. This will also clear the conversation id.
            case CONVERSATIONDATA_CLEARED_ACTION:
                return initialState;

            default:
                return {
                    ...initialState,
                    ...oldState
                };
        }
    };