import * as React from 'react';

export interface PageHeadingProps {
    title: string;
}

export default class PageHeading extends React.Component<PageHeadingProps> {
    render() {
        return (
            <section data-grid="col-12 pad-12x">
                <div data-grid="col-1" />
                <div data-grid="col-10">
                    <h2 className="c-heading-2">{this.props.title}</h2>
                </div>
                <div data-grid="col-1"/>
            </section>
        );
    }
}
