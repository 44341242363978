import { Reducer } from 'redux';
import {
    ComplianceDashboardTile, UserActions
} from '../../Models/ComplianceManagerV2';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';

const RECEIVE_CERTIFICATION_TILE = 'RECEIVE_CERTIFICATION_TILE';
const UPDATE_TESTRESULT = 'UPDATE_TESTRESULT';

export interface CertificationDetailState {
    framework: string;
    certificationId: string;
    complianceDashboardTile: ComplianceDashboardTile | null;
    inScopeServiceToFrameworkMapping: string[] | null;
}

interface ReceiveCertificationTile {
    type: typeof RECEIVE_CERTIFICATION_TILE;
    data: ComplianceDashboardTile;
}

interface UpdateTestResult {
    type: typeof UPDATE_TESTRESULT;
    data: UserActions;
    complianceDashboardTile: ComplianceDashboardTile;
}

export type KnownAction = ReceiveCertificationTile | UpdateTestResult;

export function receiveDashboardTile(data: ComplianceDashboardTile): KnownAction {
    return {
        type: RECEIVE_CERTIFICATION_TILE,
        data: data
    };
}

export function updateTestResult(data: UserActions, complianceDashboardTile: ComplianceDashboardTile): KnownAction {
    return {
        type: UPDATE_TESTRESULT,
        data: data,
        complianceDashboardTile: complianceDashboardTile
    };
}

const unloadedState: CertificationDetailState = {
    framework: '',
    certificationId: '',
    inScopeServiceToFrameworkMapping: [],
    complianceDashboardTile: null,
};

export const reducer: Reducer<CertificationDetailState> = (state: CertificationDetailState, action: KnownAction) => {
    switch (action.type) {
        case RECEIVE_CERTIFICATION_TILE:
            return {
                framework: state.framework,
                certificationId: state.certificationId,
                complianceDashboardTile: action.data,
                inScopeServiceToFrameworkMapping: state.inScopeServiceToFrameworkMapping,
            };

        case UPDATE_TESTRESULT:
            let stateResultCopy = { ...state };
            if (stateResultCopy.complianceDashboardTile && stateResultCopy.complianceDashboardTile.certificationControlFamilies) {
                stateResultCopy.complianceDashboardTile = action.complianceDashboardTile;
            }
            return {
                ...state,
                complianceDashboardTile: getDeepCopy(stateResultCopy.complianceDashboardTile)
            };
      
        default:
            return state || unloadedState;
    }
};