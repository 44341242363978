// Please do not change the order of this enum which will break lot of UI logic,if you need to
// add new enumerators at the end after the backup to ensure proper functioning
// This could potentiallly break the code if you add any new enumerator at the beginning or middle and the begining.
export enum ApprovalStatus {
    'Imported' = 'Imported',
    'PendingApproval' = 'Pending Approval',
    'Approved' = 'Approved',
    'Rejected' = 'Rejected',
    'Archived' = 'Archived',
    'BackUp' = 'BackUp'
}