import { FrameworkControl } from './FrameworkControl';
import { LocalizedValue } from '../../../Common/Models';

export class FrameworkControlFamily {
    public id: string;
    public name: LocalizedValue;
    public cloudServiceId: string;
    public description: LocalizedValue;
    public microsoftControls: FrameworkControl[];
}
