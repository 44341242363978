import { combineReducers, createStore, Store } from 'redux';
import { filterActions } from 'redux-ignore';
import { EnvironmentSettingsState, reducer as environmentSettingsReducer } from './App/Reducers/EnvironmentSettings';
import { ApplicationContextState, reducer as applicationContextReducer } from './App/Reducers/ApplicationContext';
import { LocalizedStringsState, reducer as localizedStringsReducer } from './App/Reducers/LocalizedStrings';
import { AuthenticationStatusState, reducer as authenticationReducer } from './App/Reducers/Authentication';
import { TenantSettings, reducer as tenantSettingsReducer } from './App/Reducers/TenantSettings';
import { MyLibrary, reducer as myLibraryReducer } from './App/Reducers/MyLibrary';
import { PanelDocuments, reducer as panelDocumentsReducer } from './App/Reducers/PanelDocuments';
import { PanelPages, reducer as panelPagesReducer } from './App/Reducers/PanelPages';
import { DashboardState, reducer as dashboardReducer } from './App/Reducers/Dashboard';
import { ComplianceDashboardState, reducer as complianceDashboardReducer } from './App/Reducers/ComplianceManagerV2/Dashboard';
import { ComplianceDashboardStateV3, reducer as complianceDashboardReducerV3 } from './App/Reducers/ComplianceManagerV3/Dashboard';
import { ComplianceDashboardTemplateStateV3, reducer as complianceDashboardTemplateReducerV3 } from './App/Reducers/ComplianceManagerV3/Templates';
import { ActionItemsState, reducer as actionItemsReducer } from './App/Reducers/ActionItems';
import { FrameworkDetailState, reducer as frameworkDetailsReducer } from './App/Reducers/FrameworkDetail';
import { VocabulariesManagementState, reducer as vocabulariesManagementReducer } from './App/Reducers/VocabulariesManagement';
import { StatusAlertState, reducer as statusAlertReducer } from './App/Reducers/StatusMessageReducer';
import { PagesManagementState, reducer as PagesReducer } from './App/Reducers/PagesManagement';
import { TopNavigationManagementState, reducer as TopNavigationManagementReducer } from './App/Reducers/TopNavigationManagement';
import { ImageManagmentState, reducer as imageManagementReducer } from './App/Reducers/ImageManagement';
import { BreachNotificationState, BreachNotificationActionList, reducer as breachNotificationReducer } from './App/Reducers/GdprBreachNotification';
import { BreachNotificationHistoryState, reducer as breachNotificationHistoryReducer } from './App/Reducers/GdprBreachNotificationHistory';
import { LicenseManagementState, reducer as licenseManagementReducer } from './App/Reducers/LicenseManagement';
import { CertificationDetailState, reducer as certificationDetailsReducer } from './App/Reducers/ComplianceManagerV2/CertificationDetails';
import { PrivacyPortalState, reducer as privacyPortalReducer } from './App/Reducers/PrivacyPortal';
import { ExportedFilesState, reducer as privacyPortalExportedFilesReducer } from './App/Reducers/PrivacyPortal/ExportedFiles';
import { PrivacyManagerState, reducer as privacyPortalPrivacyManagerReducer } from './App/Reducers/PrivacyPortal/PrivacyManager';
import { DimensionLookupState, reducer as DimensionLookupReducer } from './App/Reducers/ComplianceManagerV3/TenantManagement';
import { ResponsiblePartiesState, reducer as ResponsiblePartiesReducer } from './App/Reducers/ComplianceManagerV3/ResponsibleParties';
import { CustomerActionStateV3, reducer as CustomerActionReducerV3 } from './App/Reducers/ComplianceManagerV3/CustomerAction';
import { reducer as AssessmentFilterReducerV3 } from './App/Reducers/ComplianceManagerV3/AssessmentFilterReducer';
import { reducer as TemplateFilterReducerV3 } from './App/Reducers/ComplianceManagerV3/TemplateFilterReducer';
import { AssessmentFilterV3 } from './App/Models/ComplianceManagerV3/AssessmentFilterV3';
import { NotificationSummary, reducer as notificationSummaryReducer } from './App/Reducers/NotificationSummary';
import { AnyAction } from 'redux';
import { createMockContext } from './Common/Utilities/ADAuthenticate';
import { getDefaultApplicationContext } from './App/Reducers/ApplicationContext';
import { Status } from './App/Reducers/Status';
import { Status as GDPRStatus } from './App/Reducers/GdprBreachNotification';
import { Status as ImgStatus } from './App/Reducers/ImageManagement';
import { Status as EnvStatus } from './App/Reducers/EnvironmentSettings';
import { CloudService } from './App/Models/ComplianceDashboard';
import { FrameworkDetailsFilter } from './App/Models/ComplianceDashboard/FrameworkDetailsFilter';
import { ErrorInfo } from './App/Models/ErrorInfo';
import { TemplateFilterV3, getDefault as getDefaultTemplateFilter } from './App/Models/ComplianceManagerV3/TemplateFilterV3';
import { ScopePermissionsState, reducer as ScopePermissionsReducer } from './App/Reducers/ScopePermissions';
import { ActionItemFilter } from './App/Models/ComplianceManagerV3';
import { getUnloadedState, reducer as ActionItemFilterReducer } from './App/Reducers/ComplianceManagerV3/ActionItemFilterReducer';
import { ControlFilter } from './App/Models/ComplianceManagerV3/ControlFilter';
import { DashBoardType } from './App/Models/ComplianceManagerV3/DashBoardType';
import { getDefaultState as getDefaultControlFilter } from './App/Reducers/ComplianceManagerV3/ControlInfoFilterFlyoutReducer';
import { TemplateFilter, AssessmentFilter } from './App/Reducers/ComplianceManagerV3/ControlInfoFilterFlyoutReducer';
import { CookieConsentState, defaultCookieConsentState, reducer as CookieConsentReducer } from './App/Reducers/CookieConsentReducer';
import { SeriesManagementState, reducer as seriesManagementReducer } from './App/Reducers/SeriesManagement';
import { ConversationHistory, reducer as conversationHistoryReducer, initialState as conversationHistoryInitialState } from './App/Models/ChatBot/ConversationHistory';

export interface StoreState {
    environmentSettings: EnvironmentSettingsState;
    applicationContext: ApplicationContextState;
    localizedStrings: LocalizedStringsState;
    authentication: AuthenticationStatusState;
    tenantSettings: TenantSettings;
    myLibrary: MyLibrary;
    panelDocuments: PanelDocuments;
    panelPages: PanelPages;
    notificationSummary: NotificationSummary;
    dashboard: DashboardState;
    actionItems: ActionItemsState;
    frameworkDetails: FrameworkDetailState;
    vocabulariesManagement: VocabulariesManagementState;
    statusMessageManagement: StatusAlertState;
    pagesManagement: PagesManagementState;
    topNavigationManagement: TopNavigationManagementState;
    seriesManagement: SeriesManagementState;
    imageManagement: ImageManagmentState;
    breachNotification: BreachNotificationState;
    breachNotificationHistory: BreachNotificationHistoryState;
    licenseManagement: LicenseManagementState;
    complianceDashboard: ComplianceDashboardState;
    complianceDashboardV3: ComplianceDashboardStateV3;
    complianceDashboardTemplateV3: ComplianceDashboardTemplateStateV3;
    certificationDetails: CertificationDetailState;
    privacyPortal: PrivacyPortalState;
    privacyPortalExportedFiles: ExportedFilesState;
    privacyPortalPrivacyManager: PrivacyManagerState;
    dimensionLookup: DimensionLookupState;
    responsiblePartiesLookupModel: ResponsiblePartiesState;
    customerActionV3: CustomerActionStateV3;
    assessmentFilterData: AssessmentFilterV3;
    templateFilterData: TemplateFilterV3;
    ScopePermissions: ScopePermissionsState;
    actionItemFilterData: ActionItemFilter;
    assessmentsControlsFilter: ControlFilter;
    templatesControlsFilter: ControlFilter;
    cookieConsent: CookieConsentState;
    conversationHistory : ConversationHistory;
}

const initStatus: Status = 'Init';
const testCloudSvc: CloudService = {
    id: '',
    name: {
        cultureName: '',
        displayValue: '',
    },
    frameworkControlFamily: [],
};

const statusAlert: StatusAlertState = {
    StatusAlerts: {},
    IsBanner: false,
    IsError: false,
    errorInfo: new ErrorInfo(),
    errorMessage: '',
    isExceptionDialogStyle: true,
};

const testFilter: FrameworkDetailsFilter = {
    statusFilter: [],
    testResultFilter: [],
    assessmentUserFilter: [],
    controlFamilyIds: [],
    cloudServiceId: '',
    framework: [],
    tileId: '',
};

const testFrameworkDetailState: FrameworkDetailState = {
    isLoading: false,
    framework: '',
    certificationId: '',
    controlFamilyData: null,
    reviewDashboardTile: null,
    inScopeServiceToFrameworkMapping: null,
    filter: testFilter,
};

const testActItem: ActionItemsState = {
    isLoading: false,
    customerControled: testCloudSvc,
    actionItemsViewModel: [],
    filter: {
        assigned: false,
        unassigned: false,
        priority: [],
        status: [],
        filtering: false,
    },
};

const unloadedState = {
    isLoading: false,
    dashboard2: null,
    filter: { ProductFilter: [], StatusFilter: [], GroupFilter: [], ShowArchived: false },
    products: [],
    groups: [],
    certifications: [],
    cloudServiceProduct: null
};

const unloadedCMV3State = {
    isLoading: false,
    filter: {
        isShowAssessmentTileView: true,
        isShowArchivedRecord: false,
        selectedDimensions: [],
        selectedIndices: [],
    },
};

const unloadedCMV3TemplateState = {
    isLoading: false,
    dashboard: null,
    viewMode: { ShowTemplateTileView: true },
};

const unloadedCertDetailState = {
    framework: '',
    certificationId: '',
    inScopeServiceToFrameworkMapping: [],
    complianceDashboardTile: null,
};

const unloadedPrivacyPortalState = {
    status: initStatus,
    azureSubscriptions: [],
    azureStorageAccounts: [],
    microsoftServiceLinks: {
        id: '',
        serviceLinks: [],
    },
};

const emptyPrivacyOperationSubject = {
    type: '',
    tenantId: '',
    orgIdPuid: '',
    objectId: '',
};

const emptyPrivacyOperation = {
    id: '',
    State: 0,
    StateText: '',
    DestinationUri: '',
    Context: '',
    SubmittedTime: '',
    Subject: emptyPrivacyOperationSubject,
    User: {
        image: '',
        displayName: '',
        userPrincipalName: '',
        id: '',
        mail: '',
    },
    exportFileContainer: '',
};

export function createMockStore(): StoreState {
    return {
        assessmentFilterData: {
            isShowAssessmentTileView: true,
            isShowArchivedRecord: false,
            selectedDimensions: [],
            selectedIndices: []
        },
        templateFilterData: getDefaultTemplateFilter(),
        authentication: {
            isSignedIn: true,
            context: createMockContext(),
            idToken: '',
            blockMsaUser: false
        },
        applicationContext: getDefaultApplicationContext(),
        myLibrary: {
            docList: [],
            loaded: true,
            pending: false,
        },
        panelDocuments: {
            loaded: true,
            pending: false,
            documents: []
        },
        panelPages: {
            loaded: true,
            pending: false,
            pages: []
        },
        vocabulariesManagement: {
            status: 'Finished',
            vocabularyDefinitions: [],
        },
        environmentSettings: {
            environmentName: '',
            webApiEndpointUrl: '',
            imageLibraryCdnUrl: '',
            useMockServices: false,
            status: initStatus as EnvStatus,
            axiosRequestTimeoutInMilliSeconds: 10
        },
        localizedStrings: {
            cultureName: 'en-US',
            localizedStrings: {},
            status: 'Init'
        },
        tenantSettings: {
            region: 'eastus',
            industry: 'insurance'
        },
        notificationSummary: {
            loaded: true,
            pending: false
        },
        dashboard: {
            isLoading: false,
            dashboard: null,
            filter: {
                StatusFilter: [],
                ProductFilter: []
            },
            products: [],
            frameworks: []
        },
        actionItems: testActItem,
        frameworkDetails: testFrameworkDetailState,
        statusMessageManagement: statusAlert,
        pagesManagement: {
            status: initStatus,
            pagesMetadata: []
        },
        topNavigationManagement: {
            status: 'Init',
            topNavigationSettingsVersions: [],
        },
        seriesManagement: {
            status: 'Init',
            series: [],
            onlyNoDocuments: false
        },
        imageManagement: {
            status: initStatus as ImgStatus,
            imageInfos: [],
            selectedItems: [],
            uploadDialogState: {
                uploadInProgress: false,
                uploadCompleted: false,
                title: '',
                hidden: true
            },
            pickerDialogState: {
                hidden: true,
                viewStyle: '',
            },
        },
        breachNotification: {
            status: initStatus as GDPRStatus,
            page: 'IncidentPanel',
            searchBoxText: null,
            incidents: [],
            marker: '',
            message: null,
            incidentPanel: null,
        },
        breachNotificationHistory: {
            status: initStatus as GDPRStatus,
            marker: null,
            incidents: [],
            view: {
                index: 0,
            },
            isExit: false,
        },
        licenseManagement: {
            cancelled: false,
            showLicense: false,
            licenseRequesting: false,
            agreedStatus: true,
            licensePosting: false,
            signInCancelled: false,
            signInProgress: false,
            licenseDetails: undefined,
        },
        complianceDashboard: unloadedState,
        complianceDashboardV3: unloadedCMV3State,
        complianceDashboardTemplateV3: unloadedCMV3TemplateState,
        certificationDetails: unloadedCertDetailState,
        privacyPortal: unloadedPrivacyPortalState,
        privacyPortalPrivacyManager: {
            status: initStatus,
            privacyOperations: [],
        },
        privacyPortalExportedFiles: {
            status: initStatus,
            exportedFiles: [],
            privacyOperation: emptyPrivacyOperation,
        },
        dimensionLookup: {
            dimensions: []
        },
        customerActionV3: {
            customerActions: null
        },
        responsiblePartiesLookupModel: {
            responsiblePartiesLookup: null,
        },
        ScopePermissions: {
            canModifyComplianceManagerV3Master: false,
            canViewComplianceManagerV3Master: false,
            canModifyComplianceManagerV3Customer: false,
            canModifyEvidenceData: false,
            canModifyTenantData: false,
            canModifyTestData: false,
            canViewComplianceManagerV3Customer: false,
            canViewEvidenceData: false,
            canViewTenantData: false,
            canViewTestData: false,
            isGlobalAdmin: false
        },
        actionItemFilterData: getUnloadedState(),
        assessmentsControlsFilter: getDefaultControlFilter(),
        templatesControlsFilter: getDefaultControlFilter(DashBoardType.Template),
        cookieConsent: defaultCookieConsentState,
        conversationHistory: conversationHistoryInitialState,
    };
}

const combinedReducers = combineReducers<StoreState>({
    environmentSettings: environmentSettingsReducer,
    applicationContext: applicationContextReducer,
    localizedStrings: localizedStringsReducer,
    authentication: authenticationReducer,
    tenantSettings: tenantSettingsReducer,
    myLibrary: myLibraryReducer,
    panelDocuments: panelDocumentsReducer,
    panelPages: panelPagesReducer,
    notificationSummary: notificationSummaryReducer,
    dashboard: dashboardReducer,
    actionItems: actionItemsReducer,
    frameworkDetails: frameworkDetailsReducer,
    vocabulariesManagement: vocabulariesManagementReducer,
    statusMessageManagement: statusAlertReducer,
    pagesManagement: PagesReducer,
    topNavigationManagement: TopNavigationManagementReducer,
    seriesManagement: seriesManagementReducer,
    imageManagement: imageManagementReducer,
    breachNotification: filterActions<BreachNotificationState>(breachNotificationReducer, BreachNotificationActionList),
    breachNotificationHistory: breachNotificationHistoryReducer,
    licenseManagement: licenseManagementReducer,
    complianceDashboard: complianceDashboardReducer,
    complianceDashboardV3: complianceDashboardReducerV3,
    complianceDashboardTemplateV3: complianceDashboardTemplateReducerV3,
    certificationDetails: certificationDetailsReducer,
    privacyPortal: privacyPortalReducer,
    privacyPortalExportedFiles: privacyPortalExportedFilesReducer,
    privacyPortalPrivacyManager: privacyPortalPrivacyManagerReducer,
    dimensionLookup: DimensionLookupReducer,
    responsiblePartiesLookupModel: ResponsiblePartiesReducer,
    customerActionV3: CustomerActionReducerV3,
    assessmentFilterData: AssessmentFilterReducerV3,
    templateFilterData: TemplateFilterReducerV3,
    ScopePermissions: ScopePermissionsReducer,
    actionItemFilterData: ActionItemFilterReducer,
    assessmentsControlsFilter: AssessmentFilter,
    templatesControlsFilter: TemplateFilter,
    cookieConsent: CookieConsentReducer, 
    conversationHistory: conversationHistoryReducer
});

// tslint:disable-next-line:no-any
export const store: Store<StoreState, AnyAction> = createStore<StoreState, AnyAction, any, any>(combinedReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// tslint:disable-next-line:no-any
declare const window: any;