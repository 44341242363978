import { ValidatableField } from '../../App/Components/OfficeWrapperProps';

export class Validator {
    private fields: ValidatableField[];

    constructor() {
        this.fields = [];
    }

    add(field: ValidatableField) {
        this.fields.push(field);
    }

    validate(): boolean {
        let isValid = true;
        let setFocus = false;
        this.fields.forEach(field => {
            if (!field.validate()) {
                if (field.focus && !setFocus) {
                    field.focus();
                    setFocus = true;
                }
                isValid = false;
            }
        });
        return isValid;
    }
}