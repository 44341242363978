import './CopilotStudioChatBotContainer.css';

import { Icon, Panel, PanelType } from 'office-ui-fabric-react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import ReactWebChat, { createDirectLine } from 'botframework-webchat';
import { chatBotAuthenticationServiceClient } from 'src/App/Services/ChatBotAuthenticationService';
import { ChatBotAuthenticationTokenResponse, ErrorResponse } from 'src/App/Services/Models';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../../Services/GlobalizationService';
import { store } from 'src/Store'
import { TelemetryClient } from 'src/Common/Utilities/Telemetry';

export const CopilotStudioChatBotContainer: React.FC = () => {

    // For triggering if the chat window is open or not.
    const [isCopilotChatBotOpen, setIsCopilotChatBotOpen] = useState(false);

    // For setting errors and controlling how the component is rendered.
    const [error, setError] = useState(false);

    // DirectLine for the BotFramework bot. This is what starts a new conversation. (Each DirectLine that gets passed to the component creates a net-new conversationId)
    const [directLine, setDirectLine] = useState(null);

    let telemetry = TelemetryClient;

    TelemetryClient.Initialize(store.getState().applicationContext.appInsightsId, false);

    /* 
    
    Each time the Direct Line changes (which should only be on the button click to open the chat), we send a message to start the conversation. 
    There is a topic defined in CoPilot Studio that triggers the chat to start based on this exact phrase "startConversation", which initiates the welcome message topic.
    We use an activityMiddleware function in the ReactWebChat component to filter out the "startConversation" text from the chat to make it a better intuitive user experience.

    */
    useEffect(() => {

        if (directLine !== null)
        {
            let applicationContext = store.getState().applicationContext;
            let sessionId = applicationContext.userInfo.sessionId


            // if the user is signed in, then we send along the STP JWT token which we set as a global variable in the bot
            directLine.postActivity({
                from: {id: 'user'},
                type: 'event',
                name: 'setStpUserSessionId',
                // if session Id is blank that's the anonymous user case (someone who is not logged in)
                text: sessionId
            }).subscribe( 
                // for success, we don't need to log anything. But for errors we might want the activity id at least to debug later if needed.
                id => null,
                error => telemetry.trackException(new Error("Failed to send session ID to Copilot Chatbot. Bot activity id: ".concat(error)), "ChatbotContainer-directLine.postActivity") 
            );

            directLine.postActivity({
                from: { id: 'user', name: 'User' },
                type: 'message',
                text: 'startConversation'
            }).subscribe(
                id => null,
                error => telemetry.trackException(new Error("Failed to start conversation with Copilot Chatbot through DirectLine. Bot activity id: ".concat(error)), "ChatbotContainer-directLine.postActivity") 
            );
        }

    }, [directLine]);

    // Activity middleware to filter out trigger text. Note "event" activity types don't show up in the chat window, so we don't need to filter them.
    const activityMiddleware = () => next => card => {
        
        if (card.activity.type === 'message' && card.activity.text === 'startConversation') {
            // Return null for activities with trigger text to prevent them from being displayed
            return null;
        }

        // Call the next middleware in the chain for other activities
        return next(card);
    };
    
    const invokeChatAuthenticationClientAndSetDirectLine = async() => {
        try {
            chatBotAuthenticationServiceClient.GetCopilotChatBotToken(
                (response: ChatBotAuthenticationTokenResponse) => {

                    // call out to the direct line API to create a direct line to establish a connection to the bot when we create return the ReactWebChat component.
                    setDirectLine(createDirectLine({ secret: response.token}));
                },
                (response: ErrorResponse) => {
                    telemetry.trackException(new Error("Calling token API from STP failed. Error was: ".concat(response.message)), "ChatbotContainer-invokeChatAuthenticationClientAndSetDirectLine");
                }
            )
            setError(false);
        } catch (error) {
            setError(true);
            console.error(error)
        }
    }

    const toggleCopilotChatBot = async () => {
        
        if (!isCopilotChatBotOpen)
        {
            await invokeChatAuthenticationClientAndSetDirectLine();
        }
       
        setIsCopilotChatBotOpen(!isCopilotChatBotOpen);
        
    }

    if (error)
    {
        return (
            <>
        <div className='chatBot__wrapper'>
            <Icon className="chatBot__icon" iconName="ChatBot" onClick={toggleCopilotChatBot} />
        </div>

        <Panel
            isOpen={isCopilotChatBotOpen}
            type={PanelType.custom}
            customWidth="600px"
            isLightDismiss
            onLightDismissClick={toggleCopilotChatBot}
            onDismiss={toggleCopilotChatBot}
            closeButtonAriaLabel="Close"
        > 

            <div className="error">{Intl.Get(LocIds.CopilotChatBot.ErrorLabel)}</div>

        </Panel>
        </>
        )
    }

    return (
        
        <>
        <div className='cs_chatBot__wrapper'>
            <Icon className="chatBot__icon" iconName="ChatBot" onClick={toggleCopilotChatBot} />
        </div>

        <Panel
            isOpen={isCopilotChatBotOpen}
            type={PanelType.custom}
            customWidth="600px"
            isLightDismiss
            onLightDismissClick={toggleCopilotChatBot}
            onDismiss={toggleCopilotChatBot}
            closeButtonAriaLabel="Close"
        > 

        {directLine !== null ? 
             (
            <ReactWebChat directLine={directLine} className='copilotChatBot' activityMiddleware={[activityMiddleware]} />
        ) : (
            <div className='info'>{Intl.Get(LocIds.CopilotChatBot.LoadingLabel)}</div>
        )
    
        }
        
        </Panel>
        </>
    )
}