import { UserPersona } from '../ComplianceManagerV2/UserPersona';
import { DocumentInfo } from '../ComplianceManagerV2/DocumentInfo';
import { CustomerImplementationPriority } from '../ComplianceManagerV2/CustomerImplementationPriority';
import { ImplementationStatus } from './ImplementationStatus';
import { UserActionStatus } from './UserActionStatus';

export class UserActions {
    public id: string;
    public customerActionId: string;
    public anonymousTenantId: string;
    public groupId: string;
    public cloudServiceId: string;
    public whenAssigned: Date;
    public whoAssigned: UserPersona;
    public whoAssignedTo: UserPersona;
    public priority: CustomerImplementationPriority;
    public generalNotes: string; 
    public implementationNotes: string;
    public implementationStatus: ImplementationStatus;
    public whenImplemented: Date | null;
    public whoImplemented: UserPersona;
    public managementResponse: string;
    public testPlanDetails: string;
    public whenTested: Date | null;
    public whoTested: UserPersona;
    public testResultStatus: UserActionStatus;
    public documentInfos: DocumentInfo[];

    public isEmailRequested: boolean;
}
