import { AxiosResponse } from 'axios';
import {
    GetVocabularyRequest,
    GetVocabularyResponse,
    DeleteVocabularyResponse,
    ErrorResponse,
    CreateVocabularyResponse,
    CreateVocabularyRequest,
    DeleteVocabularyRequest
} from './Models';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';

class VocabularyService {

    getVocabulary(
        request: GetVocabularyRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: GetVocabularyResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {

        var api: string = '/api/v2/Vocabularies/' + request.vocabularyName;
        ServiceTrustClient.doRequest_New(
            'get', api, request, beforeRequest,
            (response: AxiosResponse) => {
                let res: GetVocabularyResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    vocabularyDefinition: response.data
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );
    }

    listVocabularies( beforeRequest: () => void,
                      onRequestSuccess: (response: AxiosResponse) => void,
                      onRequestFailed: (response: ErrorResponse) => void): void {
        ServiceTrustClient.doRequest_New('GET', '/api/v2/Vocabularies/', {}, beforeRequest, onRequestSuccess, onRequestFailed);
    }

    updateVocabulary(
        request: CreateVocabularyRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: CreateVocabularyResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/Vocabularies';
        ServiceTrustClient.doRequest_New(
            'post', api, request.vocabularyDefinition, beforeRequest,
            (response: AxiosResponse) => {
                let res: CreateVocabularyResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );

    }

    deleteVocabulary(
        request: DeleteVocabularyRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: CreateVocabularyResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/Vocabularies/' + request.id;
        ServiceTrustClient.doRequest_New(
            'delete', api, request, beforeRequest,
            (response: AxiosResponse) => {
                let res: DeleteVocabularyResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );

    }
}

export let VocabularyServiceClient = new VocabularyService();