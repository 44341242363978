import { Reducer } from 'redux';
import { Dimension } from '../../Models/ComplianceManagerV3';
import * as _ from 'lodash';
import { getDeepCopy } from '../../../App/Utilities/DeepCopyHelper';
export const RECIVE_DIMENSIONLOOKUPDETAILS = 'RECIEVE_DIMENSIONLOOKUP';
export const DELETE_DIMENSION = 'DELETE_DIMENSION';

export interface DimensionLookupState {
    dimensions: Dimension[];
}

const unloadedState: DimensionLookupState = {
    dimensions: []
};

export interface RecieveDimensionLookupDetails {
    type: typeof RECIVE_DIMENSIONLOOKUPDETAILS;
    data: Dimension[];
}

export interface DeleteDimension {
    type: typeof DELETE_DIMENSION;
    key: string;
}

export type KnownAction = RecieveDimensionLookupDetails | DeleteDimension;

export function recieveDimensionLookupDetails(dimensions: Dimension[]): RecieveDimensionLookupDetails {
    return {
        type: RECIVE_DIMENSIONLOOKUPDETAILS,
        data: dimensions
    };
}

export function deleteDimension(key: string): DeleteDimension {
    return {
        type: DELETE_DIMENSION,
        key: key
    };
}

export const reducer: Reducer<DimensionLookupState> = (state: DimensionLookupState, action: KnownAction): DimensionLookupState => {
    switch (action.type) {
        case RECIVE_DIMENSIONLOOKUPDETAILS:
        return {...state, dimensions: action.data };
        // let dimLookup: DimensionLookup = <DimensionLookup> {
        //     dimensions: action.data.dimensions
        // }
        // newState ={...state, dimensionLookup:dimLookup}
        // return newState
        case DELETE_DIMENSION:
        let newDimensionState = { ...state };
        if (newDimensionState.dimensions) {
            let mappedDimensions: Dimension[] = newDimensionState.dimensions;
            _.remove(mappedDimensions,{
                key: action.key
            })
            newDimensionState.dimensions = getDeepCopy(mappedDimensions);
        }
         return newDimensionState;
    }
    return state || unloadedState;
};