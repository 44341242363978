import { Reducer } from 'redux';
import { ADAuthenticate, authContext } from '../../Common/Utilities/ADAuthenticate';

// Define the action state that the reducer expects to receive/update while processing events.
export interface AuthenticationStatusState {
    context: ADAuthenticate;
    isSignedIn: boolean;
    idToken: string;
    blockMsaUser: boolean;
}

// Define the actions that this component can perform and the reducer that performs them.
export const UPDATE_AUTHORIZATION_CONTEXT_ACTION = 'UPDATE_AUTHORIZATION_CONTEXT_ACTION';
export type UPDATE_AUTHORIZATION_CONTEXT_ACTION = typeof UPDATE_AUTHORIZATION_CONTEXT_ACTION;

export interface UpdateAuthorizationContextAction {
    type: UPDATE_AUTHORIZATION_CONTEXT_ACTION;
    authContext: ADAuthenticate;
    idToken: string;
    blockMsaUser: boolean;
}

export function updateAuthorizationContextAction(context: ADAuthenticate, idToken: string): UpdateAuthorizationContextAction {
    return {
        type: UPDATE_AUTHORIZATION_CONTEXT_ACTION,
        authContext: context,
        idToken: idToken && idToken.length > 0 ? 'Bearer ' + idToken : '',
        blockMsaUser: context.blockMsaUser,
    };
}

export type AuthenticationAction = UpdateAuthorizationContextAction;

export const reducer: Reducer<AuthenticationStatusState> =
    (oldState: AuthenticationStatusState, action: AuthenticationAction): AuthenticationStatusState => {
        let newState: AuthenticationStatusState;
        switch (action.type) {

            case UPDATE_AUTHORIZATION_CONTEXT_ACTION:
                newState = {
                    ...oldState,
                    context: action.authContext,
                    isSignedIn: action.authContext.isSignedIn(),
                    idToken: action.idToken,
                    blockMsaUser: action.blockMsaUser
                };
                break;

            default:
                newState = (oldState === undefined)
                    ? newState = {
                        context: authContext,
                        isSignedIn: false,
                        idToken: '',
                        blockMsaUser: false
                    }
                    : oldState;
        }
        return newState;
    };