import * as React from 'react';
import { DocumentVersionInfo } from '../Models';
import { SelectedVocabulary, VocabularyDefinition, VocabularyValueDefinition } from '../Models/Vocabulary';
import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { getLocalizedValue } from '../../Common/Utilities/LocalizationHelper';
import { PrimaryButton, ActionButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { Icon } from '../../Common/Components/Icon';
import { PageMetadata } from '../Models/PageInfrastructure';
import { TableItem } from '../Models/TableItem';
import { VocabularyFilterDropdown } from '../../App/Components/VocabularyFilterDropdown';
import { FilterChoiceSummary } from './Table';
import { downloadFile, getDocumentDetailLink, getDownloadDocumentLink } from '../Utilities/DocumentHelper';

import './DocumentList.css';
import LicenseAgreementPage from './Licensing/LicenseAgreementPage';
import { store } from 'src/Store';

const HIDDEN_MOBILE_CLASSES = 'x-hidden-vp1 x-hidden-vp2';
const VISIBLE_MOBILE_CLASSES = 'x-hidden-vp3 x-hidden-vp4 x-hidden-vp5';

export interface DocumentListProps {
    documents: DocumentVersionInfo[];
    vocabularies: VocabularyDefinition[];
    clientFilters?: SelectedVocabulary[];
    filteringInfo?: SelectedVocabulary[];
    pages: PageMetadata[];
    parentId?: string;
    isSignedIn?: boolean;
    hasAgreedToCurrentLicenseAgreement?: boolean;
}

export interface DocumentListState {
    index: number;
    selectedFilters: SelectedVocabulary[];
    showMobileFilters: boolean;
    documentsToBeDownloaded: string[];
    showWarningDialog: boolean;
    showAgreement: boolean;
}

/**
 * This connected is responsible for displaying a sort-able, filter-able document table.  It is also connected to the
 * store and actions to allow it to add and remove documents from My Library and (eventually) adjust the users'
 * notification settings.
 */
export class DocumentList extends React.Component<DocumentListProps, DocumentListState> {
    private itemToBeDownloaded?: TableItem;

    constructor(props: DocumentListProps) {
        super(props);

        this.state = {
            index: 0,
            selectedFilters: [] as SelectedVocabulary[],
            showMobileFilters: false,
            documentsToBeDownloaded: [] as string[],
            showWarningDialog: false,
            showAgreement: false,
        };
    }

    public render() {
        // load the current documents, filter them by saved filters, then user-selected filters, then sort
        const items = this.getItemsToRender();
        const { selectedFilters, showMobileFilters } = this.state;

        return (
            <div className="documentList m-document-list" data-grid="col-12 stack-3">
                <div className={HIDDEN_MOBILE_CLASSES} data-grid="col-12">
                    {this.renderFilterList()}
                </div>

                <div className={VISIBLE_MOBILE_CLASSES} data-grid="col-12">
                    <div className="documentList__mobile-filters">
                        <div>
                            <ActionButton
                                className="documentList__mobile-filter-button"
                                onClick={this.showMobileFilters}
                                iconProps={{ iconName: 'Equalizer', className: 'documentList__mobile-filter-button-icon' }}
                            >
                                {Intl.Get(LocIds.PageAndPanels.FilterButton)}
                            </ActionButton>
                        </div>
                    </div>
                </div>

                {showMobileFilters && (
                    <div className="documentList__mobile-filter-modal">
                        <div className="documentList__mobile-filter-header">
                            <IconButton iconProps={{ iconName: 'ChromeClose' }} onClick={this.hideMobileFilters} />
                            {Intl.Get(LocIds.PageAndPanels.FilterButton)}
                        </div>
                        <div className="documentList__mobile-filter-body">
                            {this.renderFilterList()}
                        </div>
                        <div className="documentList__mobile-filter-footer">
                            {(items.length > 0) ? (
                                <PrimaryButton text={`See ${items.length} Documents`} onClick={this.hideMobileFilters} />
                            ) : (
                                this.renderNoResults()
                            )}
                        </div>
                    </div>
                )}

                <div data-grid="col-12">
                    {this.renderChoiceSummary()}

                    {(selectedFilters.length > 0) && (
                        <button className="documentList__clear-filters c-hyperlink" onClick={this._clearSelectedFilters}>Clear Filters</button>
                    )}

                    <hr />
                </div>

                <div data-grid="col-12">
                    <div className="documentList__items">
                        {(items.length > 0) ? (
                            items.map(this.getTableRow)
                        ) : (
                            this.renderNoResults()
                        )}
                    </div>
                </div>

                {this.state.showAgreement && (
                    <LicenseAgreementPage
                        onAgree={() => {
                            this.setState({ showAgreement: false });
                            const document = this.itemToBeDownloaded;
                            if (document) {
                                this.itemToBeDownloaded = undefined;
                                this.downloadDocument(document);
                            }
                        }}
                        onDismiss={() => {
                            this.itemToBeDownloaded = undefined;
                            this.setState({ showAgreement: false });
                        }}
                        callBack={() => {
                            this.itemToBeDownloaded = undefined;
                            this.setState({ showAgreement: false });
                        }}
                    />
                )}
            </div>
        );
    }

    private showMobileFilters = () => {
        this.setState({ showMobileFilters: true });
    }

    private hideMobileFilters = () => {
        this.setState({ showMobileFilters: false });
    }

    private renderNoResults = () => {
        return (
            <div className="documentList__no-results">
                <h5>{Intl.Get(LocIds.PageAndPanels.NoFilterResults)}</h5>
                <p>
                    {this.createHintMarkup()}
                </p>
            </div>
        );
    }

    private createHintMarkup = (): JSX.Element[] => {
        const value = Intl.Get(LocIds.PageAndPanels.NoFilterResultsHint);
        const tokens = value.match(/__[^_]+__/ig);

        if (tokens === null) { return [ value ]; }

        const token = tokens[0];

        const strings = value.split(token);
        const linkText = token.replace(/_/g, ''); // remove all the underscores

        let nodes = [];

        for (let i = 0; i < strings.length; i++) {
            nodes.push(strings[i]);

            if (i < (strings.length - 1)) {
                nodes.push(<a href="#" className="c-hyperlink" onClick={this._clearSelectedFilters}>{linkText}</a>);
            }
        }

        return nodes;
    }

    private renderFilterList = () => {
        const { selectedFilters } = this.state;
        const { vocabularies, clientFilters } = this.props;
        const clientFilterNames: string[] = clientFilters ? clientFilters.map((filter) => filter.vocabulary) : [];

        return (
            <div className="documentList__filter-list">
                {vocabularies.map((vocab) =>
                    (clientFilterNames.indexOf(vocab.name) >= 0) && (
                        <div key={`filter${vocab.name}`} className="documentList__filter-list-item">
                            <VocabularyFilterDropdown
                                key={vocab.id}
                                vocabulary={vocab}
                                selectedFilters={selectedFilters}
                                dropdownWidth={180}
                                onSelectionChanged={this.onFilterChanged}
                            />
                        </div>
                    )
                )}
            </div>
        );
    }

    private renderChoiceSummary = () => {
        const { selectedFilters } = this.state;

        return (
            <ul className="c-group f-wrap-items documentList__filters" style={{display: 'inline-flex'}}>
                {selectedFilters.map((filter) => {
                    const joinedArray = filter.choice.map((item, index) => ({ choice: item, text: filter.choiceDisplay[index] }));
                    return joinedArray.map((choice) =>
                        <FilterChoiceSummary
                            key={choice.choice}
                            choice={choice.choice}
                            text={choice.text}
                            filter={filter}
                            onRemove={this._onRemoveSelectedFilter}
                        />
                    );
                })}
            </ul>
        );
    }

    private onFilterChanged = (vocabulary: VocabularyDefinition | undefined, selectedItems: VocabularyValueDefinition[]) => {
        const { selectedFilters } = this.state;
        const newFilters = [ ...selectedFilters ];

        if (!vocabulary) { return; }

        const newSelectedVocabulary: SelectedVocabulary = {
            choice: selectedItems.map((item) => item.id),
            choiceDisplay: selectedItems.map((item) => getLocalizedValue(item.displayValues, false)),
            shouldExcludeValueOfAll: false,
            vocabulary: vocabulary.name,
        };

        if (0 === selectedItems.length) {
            // we need to remove the selected vocabulary from the current filter list entirely
            const index = selectedFilters.findIndex((filter) => filter.vocabulary === vocabulary.name);
            if (index >= 0) {
                newFilters.splice(index, 1);
            }
        } else {
            const index = selectedFilters.findIndex((filter) => filter.vocabulary === vocabulary.name);
            if (index === -1) {
                newFilters.push(newSelectedVocabulary);
            } else {
                newFilters[index] = newSelectedVocabulary;
            }
        }

        this.setState({
            selectedFilters: newFilters
        });
    }

    private _clearSelectedFilters = (ev?: React.MouseEvent<HTMLElement>) => {
        if (ev) { ev.preventDefault(); }

        this.setState({
            selectedFilters: []
        });
    }

    private _onRemoveSelectedFilter = (choice: string, text: string, filter: SelectedVocabulary) => {
        const { vocabularies } = this.props;
        const { selectedFilters } = this.state;
        const newSelectedFilters = [ ...selectedFilters ];

        if (!vocabularies || !selectedFilters) { return; }

        const index = newSelectedFilters.findIndex((f) => f.vocabulary === filter.vocabulary);
        if (index < 0) { return; } // we don't have that vocabulary in the current selectedFilters

        const choiceIndex = newSelectedFilters[index].choice.indexOf(choice);
        if (choiceIndex >= 0) {
            // remove this 'choice' value
            newSelectedFilters[index].choice.splice(choiceIndex, 1);
        }

        const textIndex = newSelectedFilters[index].choiceDisplay.indexOf(text);
        if (textIndex >= 0) {
            // remove this 'choiceDisplay' value
            newSelectedFilters[index].choiceDisplay.splice(textIndex, 1);
        }

        // if there are no active choices in this selectedvocabulary then remove it entirely
        if (newSelectedFilters[index].choice.length === 0) {
            newSelectedFilters.splice(index, 1);
        }

        this.setState({ selectedFilters: newSelectedFilters });
    }

    /**
     * This provides a collection of items based on the saved panel props
     * for this table (aka the 'base filter')
     */
    private getBaseItems = (): TableItem[] => {
        let items = this.props.documents.map((doc) => TableItem.fromDocument(doc))
            .concat(this.props.pages.map((page) => TableItem.fromPage(page)));

        // pull in the full list of items, filtered by the filteringInfo props (which is the "pre-filter" saved in the editor)
        return this.filteredItems(items, this.props.filteringInfo, true);
    }

    /**
     * Get the current list of documents to render based on the stored filters,
     * the client-side filters, and the current sorting.
     *
     * @return TableItem[] list of docs to render in the table
     */
    private getItemsToRender = (): TableItem[] => {
        let items = this.getBaseItems();

        // now fiter that list by the user's selected filters
        items = this.filteredItems(items, this.state.selectedFilters);
        items = items.filter((item) => (true !== item.isStarterPackZip())); // everything except starter pack zips

        return items;
    }

    private filteredItems = (items: TableItem[], filteringInformation: SelectedVocabulary[] | undefined, skipDates?: boolean): TableItem[] => {
        let ret: TableItem[] = [ ...items ];

        let filtersInEffect = (filteringInformation || []).length || 0;

        if (filtersInEffect === 0) { return ret; }

        (filteringInformation || []).forEach((filter) => {
            if (!filter.choice) {
                return;
            }

            const temp: TableItem[] = [];
            filter.choice.forEach((choice) => {
                this.getItemsBySelectedVocabulary(
                    ret, // use the currently filtered collection as the basis, if a filter has eliminated all possibilities then we won't have any to go off of
                    { vocabulary: filter.vocabulary, choice: [choice], choiceDisplay: [], shouldExcludeValueOfAll: filter.shouldExcludeValueOfAll }).forEach((t: TableItem) => {
                    if (temp.indexOf(t) < 0) {
                        temp.push(t);
                    }
                });
            });

            ret = temp;
        });

        return ret;
    }

    /**
     * This will take the passed list of items and return the items that match
     * the SelectedVocabulary passed in the second arg.
     *
     * @param items - the list of items to filter
     * @param filter - the SelectedVocabulary to filter the document list with
     *
     * @return list of items after filtering by `filter`
     */
    private getItemsBySelectedVocabulary = (items: TableItem[], filter: SelectedVocabulary): TableItem[] => {
        const ret: TableItem[] = [];

        items.forEach((item) => {
            if (filter.vocabulary && filter.choice) {
                const vocabulary = this.props.vocabularies.find((v) => v.name === filter.vocabulary);

                if (vocabulary) {
                    const choices = item.getChoicesByVocabulary(vocabulary);
                    if (choices && choices.vocabularyValueIds.indexOf(filter.choice[0]) >= 0) {
                        ret.push(item);
                    }
                }
            }
        });

        return ret;
    }

    /**
     * Render a row of the document table
     *
     * @param {TableItem} item a document model with versioning info
     * @param {number} i index of the current row
     */
    private getTableRow = (item: TableItem, i: number): JSX.Element | undefined => {
        const isNewUI = store.getState().applicationContext.userInfo.flights.indexOf('NewUIDesign') > -1;
        return (
            <div key={item.documentId.concat(i.toString())} className="documentList__item">
                {
                    item.isLink ?
                        <a
                            href={item.getLink()}
                            className="c-hyperlink"
                            target="_blank"
                        >
                            {item.title}
                            {this.getHyperlinkIcon(item)}
                        </a>
                        :
                        <a
                            href={isNewUI ? getDocumentDetailLink(item.documentId) : getDownloadDocumentLink(item.documentId, this.props.parentId)}
                            onClick={(e) => {
                                if (!isNewUI) {
                                    e.preventDefault();
                                    this.downloadDocument(item);
                                }
                            }}
                            className="c-hyperlink"
                        >
                            {item.title}
                            {this.getHyperlinkIcon(item)}
                        </a>
                }
            </div>
        );
    }

    private getHyperlinkIcon(linkitem: TableItem) {
        if (linkitem.isLink) {
            if (linkitem.isDocument) {
                return <Icon iconName={'NavigateExternal'} style={{width: '12px', stroke: '#005da6'}} />;
            } else {
                return undefined;
            }
        } else {
            return <Icon iconName={'Download'} />;
        }
    }

    private downloadDocument = (item: TableItem) => {

        const { isSignedIn, hasAgreedToCurrentLicenseAgreement } = this.props;

        const hasPermissionToDownload =
            item.isPublic ||
            (isSignedIn && hasAgreedToCurrentLicenseAgreement);

        // user doesn't have permission so we'll have them login and/or do the agreement
        if (!hasPermissionToDownload) {
            window.history.pushState(null, '', getDownloadDocumentLink(item.documentId, this.props.parentId));
            this.itemToBeDownloaded = item;
            this.setState({ showAgreement: true });
            return;
        }

        const fileName = item.title + item.fileExtension;
        const index = this.state.documentsToBeDownloaded.indexOf(item.documentId);
        if (index !== -1) { return; }

        this.setState({
            documentsToBeDownloaded: [ ...this.state.documentsToBeDownloaded, item.documentId ],
            showWarningDialog: item.isSpecialDocument
        });

        downloadFile(item.documentId, fileName, () => {
            const indexToBeDeleted = this.state.documentsToBeDownloaded.indexOf(item.documentId);
            if (indexToBeDeleted > -1) {
                this.state.documentsToBeDownloaded.splice(indexToBeDeleted, 1);
                this.setState({ documentsToBeDownloaded: this.state.documentsToBeDownloaded });
            }
        });

    }

}

export default DocumentList;
