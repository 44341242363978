export module LocalizationIds {
    export enum ComplianceManagerV3 {
        ImportTemplateErrorsInFileWhenApprove = 'ComplianceManagerV3_ImportTemplateErrorsInFileWhenApprove',
        ErrorDetails = 'ComplianceManagerV3_ErrorDetails',
    }
    export enum StandardButtons {
        Save = 'StandardButtons_Save',
        Close = 'StandardButtons_Close',
        Add = 'StandardButtons_Add',
        Remove = 'StandardButtons_Remove',
        ToggleSwitchYes = 'StandardButtons_ToggleSwitchYes',
        ToggleSwitchNo = 'StandardButtons_ToggleSwitchNo',
        BackToTop = 'StandardButtons_BackToTop',
    }
    export enum UserVisibleException {
        OperationFailedTitle = 'UserVisibleException_OperationFailedTitle',
    }
    export enum StandardButtons {
        Clear = 'StandardButtons_Clear',
        Copy = 'StandardButtons_Copy',
    }
    export enum ContextMenus {
        TableContextMenuTitle = 'ContextMenus_TableContextMenuTitle',
    }
    export enum ErrorPage {
        NotAuthorizedHeader = 'ErrorPage_NotAuthorizedHeader',
        NotExistOrNoPermission = 'ErrorPage_NotExistOrNoPermission',
        NotExistOrNotSignedIn = 'ErrorPage_NotExistOrNotSignedIn',
    }
    export enum HomePage {
        BrowserSupport = 'HomePage_BrowserSupport',
        FallbackLangMsg = 'HomePage_FallbackLangMsg',
        TitleColumnLabel = 'HomePage_TitleColumnLabel',
        TitlePlaceHolder = 'HomePage_TitlePlaceHolder',
        DescriptionColumnLabel = 'HomePage_DescriptionColumnLabel',
        DescriptionPlaceHolder = 'HomePage_DescriptionPlaceHolder',
        OwnerColumnLabel = 'HomePage_OwnerColumnLabel',
        OwnerPlaceHolder = 'HomePage_OwnerPlaceHolder',
    }
    export enum PageLayout {
        SkipToMainContent = 'PageLayout_SkipToMainContent',
        MainContentInfo = 'PageLayout_MainContentInfo',
    }
    export enum DocumentDownloadHistoryPage {
        TitleColumnLabel = 'DocumentDownloadHistoryPage_TitleColumnLabel',
        MainTitle = 'DocumentDownloadHistoryPage_MainTitle',
        ExportDownloadHistoryLable = 'DocumentDownloadHistoryPage_ExportDownloadHistoryLable',
        UnsignInError = 'DocumentDownloadHistoryPage_UnsignInError',
        DocumentDownloadTimeLabel = 'DocumentDownloadHistoryPage_DocumentDownloadTimeLabel',
        DocumentStatusLabel = 'DocumentDownloadHistoryPage_DocumentStatusLabel',
        DocumentDeletedStatus = 'DocumentDownloadHistoryPage_DocumentDeletedStatus',
        DocumentLiveStatus = 'DocumentDownloadHistoryPage_DocumentLiveStatus',
        DocumentHasNewerVersionStatus = 'DocumentDownloadHistoryPage_DocumentHasNewerVersionStatus',
    }
    export enum DocumentManagmentPage {
        MainTitle = 'DocumentManagmentPage_MainTitle',
        NewPageTitle = 'DocumentManagmentPage_NewPageTitle',
        TitleColumnLabel = 'DocumentManagmentPage_TitleColumnLabel',
    }
    export enum DocumentManagementPage {
        SeriesColumnLabel = 'DocumentManagementPage_SeriesColumnLabel',
        SeriesColumnDescription = 'DocumentManagementPage_SeriesColumnDescription',
        MoreOptionsColumnLabel = 'DocumentManagementPage_MoreOptionsColumnLabel',
        SubscribeToDocumentConfirmation = 'DocumentManagementPage_SubscribeToDocumentConfirmation',
        SubscribeToSeriesConfirmation = 'DocumentManagementPage_SubscribeToSeriesConfirmation',
        SubscribeToMultipleDocumentsConfirmation = 'DocumentManagementPage_SubscribeToMultipleDocumentsConfirmation',
        SubscribeToMultipleDocumentsAndSeriesConfirmation = 'DocumentManagementPage_SubscribeToMultipleDocumentsAndSeriesConfirmation',
        StandaloneDocumentsHeading = 'DocumentManagementPage_StandaloneDocumentsHeading',
        UnsubscribeToDocumentConfirmation = 'DocumentManagementPage_UnsubscribeToDocumentConfirmation',
        UnsubscribeToSeriesConfirmation = 'DocumentManagementPage_UnsubscribeToSeriesConfirmation',
        UnsubscribeToMultipleDocumentsConfirmation = 'DocumentManagementPage_UnsubscribeToMultipleDocumentsConfirmation',
        UnsubscribeToMultipleDocumentsAndSeriesConfirmation = 'DocumentManagementPage_UnsubscribeToMultipleDocumentsAndSeriesConfirmation',
        SeriesDocumentsHeading = 'DocumentManagementPage_SeriesDocumentsHeading',
        CloseButtonLabelText = 'DocumentManagementPage_CloseButtonLabelText',
        NoResults = 'DocumentManagementPage_NoResults',
    }
    export enum DocumentManagmentPage {
        PublicColumnLabel = 'DocumentManagmentPage_PublicColumnLabel',
        SizeColumnLabel = 'DocumentManagmentPage_SizeColumnLabel',
        DateModifiedColumnLabel = 'DocumentManagmentPage_DateModifiedColumnLabel',
        SeriesColumnLabel = 'DocumentManagmentPage_SeriesColumnLabel',
        OwnerColumnLabel = 'DocumentManagmentPage_OwnerColumnLabel',
        ModifiedByColumnLabel = 'DocumentManagmentPage_ModifiedByColumnLabel',
        StatusColumnLabel = 'DocumentManagmentPage_StatusColumnLabel',
        DownloadColumnLabel = 'DocumentManagmentPage_DownloadColumnLabel',
        UploadButtonLabel = 'DocumentManagmentPage_UploadButtonLabel',
        AddButtonLabel = 'DocumentManagmentPage_AddButtonLabel',
        EditButtonLabel = 'DocumentManagmentPage_EditButtonLabel',
        DeleteButtonLabel = 'DocumentManagmentPage_DeleteButtonLabel',
        CancelButtonLabel = 'DocumentManagmentPage_CancelButtonLabel',
        SaveButtonLabel = 'DocumentManagmentPage_SaveButtonLabel',
        SendForApprovalButtonLabel = 'DocumentManagmentPage_SendForApprovalButtonLabel',
        ViewAllVersionButtonLabel = 'DocumentManagmentPage_ViewAllVersionButtonLabel',
    }
    export enum DocumentManagementPage {
        ConfirmButtonLabel = 'DocumentManagementPage_ConfirmButtonLabel',
        LibraryYesButtonLabel = 'DocumentManagementPage_LibraryYesButtonLabel',
        LibraryNoButtonLabel = 'DocumentManagementPage_LibraryNoButtonLabel',
    }
    export enum DocumentManagmentPage {
        DeletePopuptitle = 'DocumentManagmentPage_DeletePopuptitle',
        DeletePopupTitle = 'DocumentManagmentPage_DeletePopupTitle',
        DeletePopupConfirmText = 'DocumentManagmentPage_DeletePopupConfirmText',
        ExternalUrlTitle = 'DocumentManagmentPage_ExternalUrlTitle',
        ExternalUrlPlaceHolder = 'DocumentManagmentPage_ExternalUrlPlaceHolder',
        NewDocumentLabel = 'DocumentManagmentPage_NewDocumentLabel',
        EditDocumentLabel = 'DocumentManagmentPage_EditDocumentLabel',
        UploadDocumentTitle = 'DocumentManagmentPage_UploadDocumentTitle',
        UploadURLTitle = 'DocumentManagmentPage_UploadURLTitle',
    }
    export enum DocumentManagementPage {
        UploadFailedTitle = 'DocumentManagementPage_UploadFailedTitle',
    }
    export enum DocumentManagmentPage {
        DocumentTitlePlaceHolder = 'DocumentManagmentPage_DocumentTitlePlaceHolder',
        DocumentTitleURLPlaceHolder = 'DocumentManagmentPage_DocumentTitleURLPlaceHolder',
        DocumentDescriptionLabel = 'DocumentManagmentPage_DocumentDescriptionLabel',
        DocumentUpdateNotesLabel = 'DocumentManagmentPage_DocumentUpdateNotesLabel',
        DocumentDescriptionPlaceHolder = 'DocumentManagmentPage_DocumentDescriptionPlaceHolder',
        DocumentDescriptionURLPlaceHolder = 'DocumentManagmentPage_DocumentDescriptionURLPlaceHolder',
        DocumentUpdateNotesPlaceHolder = 'DocumentManagmentPage_DocumentUpdateNotesPlaceHolder',
        DocumentUpdateNotesURLPlaceHolder = 'DocumentManagmentPage_DocumentUpdateNotesURLPlaceHolder',
        PublicDocumentLabel = 'DocumentManagmentPage_PublicDocumentLabel',
        PublicURLLabel = 'DocumentManagmentPage_PublicURLLabel',
        PressToCheckLabel = 'DocumentManagmentPage_PressToCheckLabel',
        PressToUncheckLabel = 'DocumentManagmentPage_PressToUncheckLabel',
        DocumentSourceLabel = 'DocumentManagmentPage_DocumentSourceLabel',
        DocumentSourceUpload = 'DocumentManagmentPage_DocumentSourceUpload',
        DocumentSourceExternalLink = 'DocumentManagmentPage_DocumentSourceExternalLink',
        DocumentFileExtensionLabel = 'DocumentManagmentPage_DocumentFileExtensionLabel',
        DocumentFileExtensionOtherLabel = 'DocumentManagmentPage_DocumentFileExtensionOtherLabel',
        DocumentOwnerLabel = 'DocumentManagmentPage_DocumentOwnerLabel',
        URLOwnerLabel = 'DocumentManagmentPage_URLOwnerLabel',
        IsSpecialDocumentLabel = 'DocumentManagmentPage_IsSpecialDocumentLabel',
        DocumentReportDateLabel = 'DocumentManagmentPage_DocumentReportDateLabel',
    }
    export enum DocumentManagementPage {
        DocumentDownloadWarningHeader = 'DocumentManagementPage_DocumentDownloadWarningHeader',
        DocumentDownloadWarningDescription = 'DocumentManagementPage_DocumentDownloadWarningDescription',
        AddSeriesDropdownOption = 'DocumentManagementPage_AddSeriesDropdownOption',
        AddNewSeriesDialogHeader = 'DocumentManagementPage_AddNewSeriesDialogHeader',
        DuplicateDocumentSeries = 'DocumentManagementPage_DuplicateDocumentSeries',
        SavingSeries = 'DocumentManagementPage_SavingSeries',
        DistributionListLabel = 'DocumentManagementPage_DistributionListLabel',
        DocumentSelectSeriesLabel = 'DocumentManagementPage_DocumentSelectSeriesLabel',
        DocumentSelectFrequencyPlaceholder = 'DocumentManagementPage_DocumentSelectFrequencyPlaceholder',
        DocumentSelectFrequencyLabel = 'DocumentManagementPage_DocumentSelectFrequencyLabel',
        DocumentSeriesAddInfo = 'DocumentManagementPage_DocumentSeriesAddInfo',
        DocumentSeriesEditInfo = 'DocumentManagementPage_DocumentSeriesEditInfo',
        DocumentSeriesWarning = 'DocumentManagementPage_DocumentSeriesWarning',
        DocumentTitle = 'DocumentManagementPage_DocumentTitle',
        DocumentDescription = 'DocumentManagementPage_DocumentDescription',
        ReportDate = 'DocumentManagementPage_ReportDate',
    }
    export enum SeriesManagmentPage {
        Title = 'SeriesManagmentPage_Title',
        NoResult = 'SeriesManagmentPage_NoResult',
        FaildToLoad = 'SeriesManagmentPage_FaildToLoad',
        DocumentsCount = 'SeriesManagmentPage_DocumentsCount',
        HasNoDocuments = 'SeriesManagmentPage_HasNoDocuments',
        SeriesName = 'SeriesManagmentPage_SeriesName',
    }
    export enum PageAndPanels {
        TLSDeprecated = 'PageAndPanels_TLSDeprecated',
        AdminPageRemoved = 'PageAndPanels_AdminPageRemoved',
        MSAUserWillBeBlocked = 'PageAndPanels_MSAUserWillBeBlocked',
        StopSupportMsa = 'PageAndPanels_StopSupportMsa',
        WaysAccessingSTP = 'PageAndPanels_WaysAccessingSTP',
        MSAUserWillBeBlockedLearnMore = 'PageAndPanels_MSAUserWillBeBlockedLearnMore',
        AdminPageRemovedLearnMore = 'PageAndPanels_AdminPageRemovedLearnMore',
        ServiceTrustPortalTitle = 'PageAndPanels_ServiceTrustPortalTitle',
        ComplianceManagerTitle = 'PageAndPanels_ComplianceManagerTitle',
        AddToLibrary = 'PageAndPanels_AddToLibrary',
        RemoveFromLibrary = 'PageAndPanels_RemoveFromLibrary',
        FiltersAppliedText = 'PageAndPanels_FiltersAppliedText',
        DownloadDocument = 'PageAndPanels_DownloadDocument',
        InformationButton = 'PageAndPanels_InformationButton',
        DownloadDocumentAriaLabel = 'PageAndPanels_DownloadDocumentAriaLabel',
        DownloadAll = 'PageAndPanels_DownloadAll',
        DocumentTypeLabel = 'PageAndPanels_DocumentTypeLabel',
        DocumentDatesLabel = 'PageAndPanels_DocumentDatesLabel',
        HideDocumentTypeFilter = 'PageAndPanels_HideDocumentTypeFilter',
        HideDescriptionColumn = 'PageAndPanels_HideDescriptionColumn',
        HideDateColumn = 'PageAndPanels_HideDateColumn',
        HideDateFilter = 'PageAndPanels_HideDateFilter',
        SelectTableRow = 'PageAndPanels_SelectTableRow',
        MaxDownloadsLabel = 'PageAndPanels_MaxDownloadsLabel',
        MaxDownloadsTooltip = 'PageAndPanels_MaxDownloadsTooltip',
        StartDateLabel = 'PageAndPanels_StartDateLabel',
        EndDateLabel = 'PageAndPanels_EndDateLabel',
        ShowMore = 'PageAndPanels_ShowMore',
        ShowLess = 'PageAndPanels_ShowLess',
        ShowMoreAriaLabel = 'PageAndPanels_ShowMoreAriaLabel',
        ShowLessAriaLabel = 'PageAndPanels_ShowLessAriaLabel',
        None = 'PageAndPanels_None',
        NeverFrequency = 'PageAndPanels_NeverFrequency',
        DailyFrequency = 'PageAndPanels_DailyFrequency',
        WeeklyFrequency = 'PageAndPanels_WeeklyFrequency',
        MonthlyFrequency = 'PageAndPanels_MonthlyFrequency',
        QuarterlyFrequency = 'PageAndPanels_QuarterlyFrequency',
        DateFilterLabel = 'PageAndPanels_DateFilterLabel',
        YearlyFrequency = 'PageAndPanels_YearlyFrequency',
        AsNeededFrequency = 'PageAndPanels_AsNeededFrequency',
        DocumentIsStoredUntillDateLabel = 'PageAndPanels_DocumentIsStoredUntillDateLabel',
        Last = 'PageAndPanels_Last',
        SignInCallToActionText = 'PageAndPanels_SignInCallToActionText',
        NoDocuments = 'PageAndPanels_NoDocuments',
        NoDocumentsVNext = 'PageAndPanels_NoDocumentsVNext',
        NoDocumentsVNextSignIn = 'PageAndPanels_NoDocumentsVNextSignIn',
        NoFilterResults = 'PageAndPanels_NoFilterResults',
        NoFilterResultsHint = 'PageAndPanels_NoFilterResultsHint',
        DocumentNotFound = 'PageAndPanels_DocumentNotFound',
        RestrictedDocument = 'PageAndPanels_RestrictedDocument',
        NoDocumentSubscription = 'PageAndPanels_NoDocumentSubscription',
        NoDocumentSubscriptionFilterResults = 'PageAndPanels_NoDocumentSubscriptionFilterResults',
        NoDocumentSeriesSubscription = 'PageAndPanels_NoDocumentSeriesSubscription',
        NoDocumentSeriesSubscriptionFilterResults = 'PageAndPanels_NoDocumentSeriesSubscriptionFilterResults',
        UpdateLabel = 'PageAndPanels_UpdateLabel',
        LibraryAlertText = 'PageAndPanels_LibraryAlertText',
        FilterButton = 'PageAndPanels_FilterButton',
        ImagePriorityLabel = 'PageAndPanels_ImagePriorityLabel',
        NotificationSettingsButtonText = 'PageAndPanels_NotificationSettingsButtonText',
        NotificationSettingsConfirmationText = 'PageAndPanels_NotificationSettingsConfirmationText',
        NotificationSettingsConfirmationTitle = 'PageAndPanels_NotificationSettingsConfirmationTitle',
        PageDuplicationConfirmationTitle = 'PageAndPanels_PageDuplicationConfirmationTitle',
        NotificationSettingsConfirmationSubheader = 'PageAndPanels_NotificationSettingsConfirmationSubheader',
        NotificationSettingsConfirmationSubtext = 'PageAndPanels_NotificationSettingsConfirmationSubtext',
        NotificationSettingsDialogText = 'PageAndPanels_NotificationSettingsDialogText',
        NotificationSettingsDialogTitle = 'PageAndPanels_NotificationSettingsDialogTitle',
        NotificationSettingsEverything = 'PageAndPanels_NotificationSettingsEverything',
        NotificationSettingsOff = 'PageAndPanels_NotificationSettingsOff',
        NotificationSettingsLabel = 'PageAndPanels_NotificationSettingsLabel',
        NotificationSettingsOnArialLabel = 'PageAndPanels_NotificationSettingsOnArialLabel',
        NotificationSettingsOffArialLabel = 'PageAndPanels_NotificationSettingsOffArialLabel',
        NotificationSettingsEmailLabel = 'PageAndPanels_NotificationSettingsEmailLabel',
        DuplicatePageTitleLabel = 'PageAndPanels_DuplicatePageTitleLabel',
        DuplicatePageDescriptionLabel = 'PageAndPanels_DuplicatePageDescriptionLabel',
        NotificationSettingsFrequencyLabel = 'PageAndPanels_NotificationSettingsFrequencyLabel',
        PageSignInRequired = 'PageAndPanels_PageSignInRequired',
        BrowseAuditReports = 'PageAndPanels_BrowseAuditReports',
        LoadingLibrary = 'PageAndPanels_LoadingLibrary',
        MyLibraryHeader = 'PageAndPanels_MyLibraryHeader',
        LibraryEmptyHeader = 'PageAndPanels_LibraryEmptyHeader',
        LibraryEmptySubheader = 'PageAndPanels_LibraryEmptySubheader',
        AuditReportsLabel = 'PageAndPanels_AuditReportsLabel',
        BrowseDocumentsAndResources = 'PageAndPanels_BrowseDocumentsAndResources',
        FeedbackCallToAction = 'PageAndPanels_FeedbackCallToAction',
        SkipTable = 'PageAndPanels_SkipTable',
    }
    export enum UHFFooter {
        AdditionalResourcesLabel = 'UHFFooter_AdditionalResourcesLabel',
        AdditionalResourcesCallToAction = 'UHFFooter_AdditionalResourcesCallToAction',
    }
    export enum PageAndPanels {
        GetStartedLabel = 'PageAndPanels_GetStartedLabel',
        GetStartedDescription = 'PageAndPanels_GetStartedDescription',
        TechnicalSupportLabel = 'PageAndPanels_TechnicalSupportLabel',
        TechnicalSupportDescription = 'PageAndPanels_TechnicalSupportDescription',
        TechnicalSupportCallToAction = 'PageAndPanels_TechnicalSupportCallToAction',
    }
    export enum UHFFooter {
        FrequentlyAskedQuestionsLabel = 'UHFFooter_FrequentlyAskedQuestionsLabel',
        FrequentlyAskedQuestionsCallToAction = 'UHFFooter_FrequentlyAskedQuestionsCallToAction',
    }
    export enum PageAndPanels {
        PageDropdownlistboxTitle = 'PageAndPanels_PageDropdownlistboxTitle',
        ViewAllVersionLabel = 'PageAndPanels_ViewAllVersionLabel',
        RestoreVersionLabel = 'PageAndPanels_RestoreVersionLabel',
        SendForApprovalLabel = 'PageAndPanels_SendForApprovalLabel',
        ExitEditMode = 'PageAndPanels_ExitEditMode',
        UndoChangesLabel = 'PageAndPanels_UndoChangesLabel',
        UrlNameLabel = 'PageAndPanels_UrlNameLabel',
        UrlLabel = 'PageAndPanels_UrlLabel',
        CloudServiceLabel = 'PageAndPanels_CloudServiceLabel',
        IndustryLabel = 'PageAndPanels_IndustryLabel',
        RoleTaskLabel = 'PageAndPanels_RoleTaskLabel',
        RequiredFlightsLabel = 'PageAndPanels_RequiredFlightsLabel',
        ExitTitle = 'PageAndPanels_ExitTitle',
        ExitSubtext = 'PageAndPanels_ExitSubtext',
        UrlNameErrorTitle = 'PageAndPanels_UrlNameErrorTitle',
        UrlNameErrorDescription = 'PageAndPanels_UrlNameErrorDescription',
        PublicButtonLabel = 'PageAndPanels_PublicButtonLabel',
        ConfirmRestoreTitle = 'PageAndPanels_ConfirmRestoreTitle',
        ConfirmRestoreDescription = 'PageAndPanels_ConfirmRestoreDescription',
        ConfirmDeleteTitle = 'PageAndPanels_ConfirmDeleteTitle',
        ConfirmDeleteDescription = 'PageAndPanels_ConfirmDeleteDescription',
        EditButtonLabel = 'PageAndPanels_EditButtonLabel',
        ApproveButtonLabel = 'PageAndPanels_ApproveButtonLabel',
        ApprovePublishButtonLabel = 'PageAndPanels_ApprovePublishButtonLabel',
        ApproveDeletionButtonLabel = 'PageAndPanels_ApproveDeletionButtonLabel',
        RejectButtonLabel = 'PageAndPanels_RejectButtonLabel',
        RejectPublishButtonLabel = 'PageAndPanels_RejectPublishButtonLabel',
        RejectDeletionButtonLabel = 'PageAndPanels_RejectDeletionButtonLabel',
        AddButtonLabel = 'PageAndPanels_AddButtonLabel',
        DuplicateButtonLabel = 'PageAndPanels_DuplicateButtonLabel',
        AddViewButtonLabel = 'PageAndPanels_AddViewButtonLabel',
        SelectViewTitle = 'PageAndPanels_SelectViewTitle',
        SelectViewDescription = 'PageAndPanels_SelectViewDescription',
        PanelSelectTitle = 'PageAndPanels_PanelSelectTitle',
        DeleteButtonLabel = 'PageAndPanels_DeleteButtonLabel',
        ExitButtonLabel = 'PageAndPanels_ExitButtonLabel',
        MoveLeftButtonLabel = 'PageAndPanels_MoveLeftButtonLabel',
        MoveRightButtonLabel = 'PageAndPanels_MoveRightButtonLabel',
        MoveUpButtonLabel = 'PageAndPanels_MoveUpButtonLabel',
        MoveDownButtonLabel = 'PageAndPanels_MoveDownButtonLabel',
        SaveButtonLabel = 'PageAndPanels_SaveButtonLabel',
        RollBackButtonLabel = 'PageAndPanels_RollBackButtonLabel',
        UndoButtonLabel = 'PageAndPanels_UndoButtonLabel',
        AddNewPageButtonLabel = 'PageAndPanels_AddNewPageButtonLabel',
        UrlNameColumnTitle = 'PageAndPanels_UrlNameColumnTitle',
        StatusColumnTitle = 'PageAndPanels_StatusColumnTitle',
        DateModifiedColumnTitle = 'PageAndPanels_DateModifiedColumnTitle',
        DateColumnTitle = 'PageAndPanels_DateColumnTitle',
        TitleColumnTitle = 'PageAndPanels_TitleColumnTitle',
        OwnerColumnTitle = 'PageAndPanels_OwnerColumnTitle',
        FiltersClearedMessage = 'PageAndPanels_FiltersClearedMessage',
        RemovedFromLibraryMessage = 'PageAndPanels_RemovedFromLibraryMessage',
        DescriptionColumnTitle = 'PageAndPanels_DescriptionColumnTitle',
        SortByAriaLabel = 'PageAndPanels_SortByAriaLabel',
        AscendingSortStatus = 'PageAndPanels_AscendingSortStatus',
        DescendingSortStatus = 'PageAndPanels_DescendingSortStatus',
        NotSortedSortStatus = 'PageAndPanels_NotSortedSortStatus',
        TableFilterItemCount = 'PageAndPanels_TableFilterItemCount',
        RemoveFilterChoice = 'PageAndPanels_RemoveFilterChoice',
        ModifiedByColumnTitle = 'PageAndPanels_ModifiedByColumnTitle',
        HasPublicColumnTitle = 'PageAndPanels_HasPublicColumnTitle',
        UseFooterSeparatorLabel = 'PageAndPanels_UseFooterSeparatorLabel',
        HideLargeContentLabel = 'PageAndPanels_HideLargeContentLabel',
        MaxCharacterLengthLabel = 'PageAndPanels_MaxCharacterLengthLabel',
        UseCardPaddingLabel = 'PageAndPanels_UseCardPaddingLabel',
        ShowBorderLabel = 'PageAndPanels_ShowBorderLabel',
        PanelBorderBottomLabel = 'PageAndPanels_PanelBorderBottomLabel',
        PanelBorderTopLabel = 'PageAndPanels_PanelBorderTopLabel',
        EditPanelButtonDescription = 'PageAndPanels_EditPanelButtonDescription',
        DeletePanelButtonDescription = 'PageAndPanels_DeletePanelButtonDescription',
        MoveUpPanelButtonDescription = 'PageAndPanels_MoveUpPanelButtonDescription',
        MoveDownPanelButtonDescription = 'PageAndPanels_MoveDownPanelButtonDescription',
        DiscardChangesButtonLabel = 'PageAndPanels_DiscardChangesButtonLabel',
        SelectImageWidthLabel = 'PageAndPanels_SelectImageWidthLabel',
        SelectThemeLabel = 'PageAndPanels_SelectThemeLabel',
        FeaturedImageLabel = 'PageAndPanels_FeaturedImageLabel',
        FeaturedImageSuggestionLabel = 'PageAndPanels_FeaturedImageSuggestionLabel',
        FeaturedImageDescriptionLabel = 'PageAndPanels_FeaturedImageDescriptionLabel',
        ThumbnailImageLabel = 'PageAndPanels_ThumbnailImageLabel',
        ThumbnailImageSuggestionLabel = 'PageAndPanels_ThumbnailImageSuggestionLabel',
        ThumbnailImageDescriptionLabel = 'PageAndPanels_ThumbnailImageDescriptionLabel',
        BackgroundImageLabel = 'PageAndPanels_BackgroundImageLabel',
        BackgroundImageSuggestionLabel = 'PageAndPanels_BackgroundImageSuggestionLabel',
        ThemeLabel = 'PageAndPanels_ThemeLabel',
        MainPanelLabel = 'PageAndPanels_MainPanelLabel',
        MosaicPatternLabel = 'PageAndPanels_MosaicPatternLabel',
        TextAlignLabel = 'PageAndPanels_TextAlignLabel',
        AdditionalTopMarginLabel = 'PageAndPanels_AdditionalTopMarginLabel',
        AlignLabel = 'PageAndPanels_AlignLabel',
        TileSizeLabel = 'PageAndPanels_TileSizeLabel',
        SmallTileOption = 'PageAndPanels_SmallTileOption',
        ExtraSmallTileOption = 'PageAndPanels_ExtraSmallTileOption',
        TextOnlyTallTileOption = 'PageAndPanels_TextOnlyTallTileOption',
        DocumentLabel = 'PageAndPanels_DocumentLabel',
        ReportDateLabel = 'PageAndPanels_ReportDateLabel',
        HeadingLabel = 'PageAndPanels_HeadingLabel',
        HeadingUrlLabel = 'PageAndPanels_HeadingUrlLabel',
        BadgeLabel = 'PageAndPanels_BadgeLabel',
        SelectLayoutLabel = 'PageAndPanels_SelectLayoutLabel',
        LayoutLabel = 'PageAndPanels_LayoutLabel',
        SelectInsetLabel = 'PageAndPanels_SelectInsetLabel',
        InsetLabel = 'PageAndPanels_InsetLabel',
        VerticalLabel = 'PageAndPanels_VerticalLabel',
        HorizontalLabel = 'PageAndPanels_HorizontalLabel',
        PanelHeadingLabel = 'PageAndPanels_PanelHeadingLabel',
        PanelSubHeadingLabel = 'PageAndPanels_PanelSubHeadingLabel',
        DescriptionLabel = 'PageAndPanels_DescriptionLabel',
        TermLabel = 'PageAndPanels_TermLabel',
        IconLabel = 'PageAndPanels_IconLabel',
        IconSizeLabel = 'PageAndPanels_IconSizeLabel',
        TitleLabel = 'PageAndPanels_TitleLabel',
        NameLabel = 'PageAndPanels_NameLabel',
        ImageLabel = 'PageAndPanels_ImageLabel',
        ModifySelectViewTitle = 'PageAndPanels_ModifySelectViewTitle',
        ChooseImageLabel = 'PageAndPanels_ChooseImageLabel',
        AddPanelLabel = 'PageAndPanels_AddPanelLabel',
        DeletePanelLabel = 'PageAndPanels_DeletePanelLabel',
        AddSessionLabel = 'PageAndPanels_AddSessionLabel',
        DeleteSessionLabel = 'PageAndPanels_DeleteSessionLabel',
        CallToActionLabel = 'PageAndPanels_CallToActionLabel',
        CallToActionUrlLabel = 'PageAndPanels_CallToActionUrlLabel',
        PanelCallToActionLabel = 'PageAndPanels_PanelCallToActionLabel',
        PanelCallToActionUrlLabel = 'PageAndPanels_PanelCallToActionUrlLabel',
        PrimaryCallToActionLabel = 'PageAndPanels_PrimaryCallToActionLabel',
        PrimaryCallToActionUrlLabel = 'PageAndPanels_PrimaryCallToActionUrlLabel',
        PrimaryCallToActionThemeLabel = 'PageAndPanels_PrimaryCallToActionThemeLabel',
        SecondaryCallToActionLabel = 'PageAndPanels_SecondaryCallToActionLabel',
        SecondaryCallToActionUrlLabel = 'PageAndPanels_SecondaryCallToActionUrlLabel',
        SecondaryCallToActionThemeLabel = 'PageAndPanels_SecondaryCallToActionThemeLabel',
        AddColumnLabel = 'PageAndPanels_AddColumnLabel',
        DeleteColumnLabel = 'PageAndPanels_DeleteColumnLabel',
        TextPositionLabel = 'PageAndPanels_TextPositionLabel',
        BackgroundMaskLabel = 'PageAndPanels_BackgroundMaskLabel',
        BackgroundColorLabel = 'PageAndPanels_BackgroundColorLabel',
        UseImageLabel = 'PageAndPanels_UseImageLabel',
        CallToActionStyleLabel = 'PageAndPanels_CallToActionStyleLabel',
        AutoPlayLabel = 'PageAndPanels_AutoPlayLabel',
        RequiredError = 'PageAndPanels_RequiredError',
        PanelLabel = 'PageAndPanels_PanelLabel',
        LinkTypesLabel = 'PageAndPanels_LinkTypesLabel',
        SelectPageLabel = 'PageAndPanels_SelectPageLabel',
        LeftRightModifyTitle = 'PageAndPanels_LeftRightModifyTitle',
        LeftRightModifyDescription = 'PageAndPanels_LeftRightModifyDescription',
        SlideChangeIntervalLabel = 'PageAndPanels_SlideChangeIntervalLabel',
        UsePrimaryGroupasPivotViewToggle = 'PageAndPanels_UsePrimaryGroupasPivotViewToggle',
        UsePrimaryGroupasPivotViewToggleOn = 'PageAndPanels_UsePrimaryGroupasPivotViewToggleOn',
        UsePrimaryGroupasPivotViewToggleOff = 'PageAndPanels_UsePrimaryGroupasPivotViewToggleOff',
        GroupByPrimarySecondaryLabel = 'PageAndPanels_GroupByPrimarySecondaryLabel',
        SelectAGroupLabel = 'PageAndPanels_SelectAGroupLabel',
        IfNoDocumentsAreReturnedToggle = 'PageAndPanels_IfNoDocumentsAreReturnedToggle',
        IfNoDocumentsAreReturnedToggleOn = 'PageAndPanels_IfNoDocumentsAreReturnedToggleOn',
        IfNoDocumentsAreReturnedToggleOff = 'PageAndPanels_IfNoDocumentsAreReturnedToggleOff',
        HeaderCompactModeToggle = 'PageAndPanels_HeaderCompactModeToggle',
        HeaderCompactModeToggleOn = 'PageAndPanels_HeaderCompactModeToggleOn',
        HeaderCompactModeToggleOff = 'PageAndPanels_HeaderCompactModeToggleOff',
        ErrorDownloadMessage = 'PageAndPanels_ErrorDownloadMessage',
        ErrorDownloadMessageForMSA = 'PageAndPanels_ErrorDownloadMessageForMSA',
        TitlePlaceHolder = 'PageAndPanels_TitlePlaceHolder',
        HeadingPlaceHolder = 'PageAndPanels_HeadingPlaceHolder',
        DescriptionPlaceHolder = 'PageAndPanels_DescriptionPlaceHolder',
        ForcePublish = 'PageAndPanels_ForcePublish',
        DrawerCountLabel = 'PageAndPanels_DrawerCountLabel',
        FAQExpand = 'PageAndPanels_FAQExpand',
        FAQCollapse = 'PageAndPanels_FAQCollapse',
        LeftCurlyBraceAriaLabel = 'PageAndPanels_LeftCurlyBraceAriaLabel',
        RightCurlyBraceAriaLabel = 'PageAndPanels_RightCurlyBraceAriaLabel',
        QuotePlaceHolder = 'PageAndPanels_QuotePlaceHolder',
        QuoteLabel = 'PageAndPanels_QuoteLabel',
        QuoteSourcePlaceHolder = 'PageAndPanels_QuoteSourcePlaceHolder',
        QuoteSourceLabel = 'PageAndPanels_QuoteSourceLabel',
        ClearFilters = 'PageAndPanels_ClearFilters',
        ContentPlacementLinkAriaLabel = 'PageAndPanels_ContentPlacementLinkAriaLabel',
        NavigateExternalIcon = 'PageAndPanels_NavigateExternalIcon',
        SavedToLibraryAnnouncement = 'PageAndPanels_SavedToLibraryAnnouncement',
        NotificationSettingsAnnouncement = 'PageAndPanels_NotificationSettingsAnnouncement',
        CloseFilters = 'PageAndPanels_CloseFilters',
        FiltersModal = 'PageAndPanels_FiltersModal',
        ViewAllVersions = 'PageAndPanels_ViewAllVersions',
        HeroItemPanelTitle = 'PageAndPanels_HeroItemPanelTitle',
        SidebarGridPanelTitle = 'PageAndPanels_SidebarGridPanelTitle',
        RichHeadingPanelTitle = 'PageAndPanels_RichHeadingPanelTitle',
        HeaderPanelTitle = 'PageAndPanels_HeaderPanelTitle',
        DocumentFilterableListPanelTitle = 'PageAndPanels_DocumentFilterableListPanelTitle',
        ContentPlacementPanelTitle = 'PageAndPanels_ContentPlacementPanelTitle',
        AreaHeadingPanelTitle = 'PageAndPanels_AreaHeadingPanelTitle',
        AreaHeadingWithIconPanelTitle = 'PageAndPanels_AreaHeadingWithIconPanelTitle',
        PivotPanelTitle = 'PageAndPanels_PivotPanelTitle',
        ContentRichBlockPanelTitle = 'PageAndPanels_ContentRichBlockPanelTitle',
        DocumentPanelTitle = 'PageAndPanels_DocumentPanelTitle',
        AdvancedDocumentPanelTitle = 'PageAndPanels_AdvancedDocumentPanelTitle',
        ContentPlacementWithIconPanelTitle = 'PageAndPanels_ContentPlacementWithIconPanelTitle',
        ContentPlacementWithImagePanelTitle = 'PageAndPanels_ContentPlacementWithImagePanelTitle',
        HeroPanelTitle = 'PageAndPanels_HeroPanelTitle',
        FeaturePanelTitle = 'PageAndPanels_FeaturePanelTitle',
        GuideHelpPanelTitle = 'PageAndPanels_GuideHelpPanelTitle',
        LibraryNotificationPanelTitle = 'PageAndPanels_LibraryNotificationPanelTitle',
        ContentPivotPanelTitle = 'PageAndPanels_ContentPivotPanelTitle',
        LinkListPanelTitle = 'PageAndPanels_LinkListPanelTitle',
        TopResourcePanelTitle = 'PageAndPanels_TopResourcePanelTitle',
        StructuredListPanelTitle = 'PageAndPanels_StructuredListPanelTitle',
        ColumnsPanelTitle = 'PageAndPanels_ColumnsPanelTitle',
        DrawerPanelTitle = 'PageAndPanels_DrawerPanelTitle',
        FAQPanelTitle = 'PageAndPanels_FAQPanelTitle',
        CardsPanelTitle = 'PageAndPanels_CardsPanelTitle',
        QuotePanelTitle = 'PageAndPanels_QuotePanelTitle',
        ImageRichTextPanelTitle = 'PageAndPanels_ImageRichTextPanelTitle',
        CTAPanelTitle = 'PageAndPanels_CTAPanelTitle',
        MosaicPanelTitle = 'PageAndPanels_MosaicPanelTitle',
    }
    export enum ImageManagmentPage {
        Title = 'ImageManagmentPage_Title',
        PreviewUrlLabel = 'ImageManagmentPage_PreviewUrlLabel',
        PreviewUrlText = 'ImageManagmentPage_PreviewUrlText',
        TitleLabel = 'ImageManagmentPage_TitleLabel',
        SizeLabel = 'ImageManagmentPage_SizeLabel',
        LastModifiedLabel = 'ImageManagmentPage_LastModifiedLabel',
        AddButtonLabel = 'ImageManagmentPage_AddButtonLabel',
        DeleteButtonLabel = 'ImageManagmentPage_DeleteButtonLabel',
        ConfirmationDlgTitle = 'ImageManagmentPage_ConfirmationDlgTitle',
        ConfirmationDlgSubText = 'ImageManagmentPage_ConfirmationDlgSubText',
        ConfirmationDlgButtonLabel = 'ImageManagmentPage_ConfirmationDlgButtonLabel',
        ConfirmationDlgCancelButtonLabel = 'ImageManagmentPage_ConfirmationDlgCancelButtonLabel',
        ImageUploaderDialogTitle = 'ImageManagmentPage_ImageUploaderDialogTitle',
        ImageUploaderDialogImageSelectionLabel = 'ImageManagmentPage_ImageUploaderDialogImageSelectionLabel',
        ImageUploaderDialogImageUploadButtonLabel = 'ImageManagmentPage_ImageUploaderDialogImageUploadButtonLabel',
        ImageUploaderDialogImageTitleLabel = 'ImageManagmentPage_ImageUploaderDialogImageTitleLabel',
        ImageUploaderDialogTitlePlaceHolderText = 'ImageManagmentPage_ImageUploaderDialogTitlePlaceHolderText',
        ImageUploaderDialogSaveButtonLabel = 'ImageManagmentPage_ImageUploaderDialogSaveButtonLabel',
        ImageUploaderDialogCancelButtonLabel = 'ImageManagmentPage_ImageUploaderDialogCancelButtonLabel',
        ImageUploaderDialogUploadCompleteMessage = 'ImageManagmentPage_ImageUploaderDialogUploadCompleteMessage',
        ImageUploaderDialogTitleEmptyErrorMessage = 'ImageManagmentPage_ImageUploaderDialogTitleEmptyErrorMessage',
        ImageUploaderDialogTitleTooLongErrorMessage = 'ImageManagmentPage_ImageUploaderDialogTitleTooLongErrorMessage',
        ImagePickerDialogTitleLabel = 'ImageManagmentPage_ImagePickerDialogTitleLabel',
        ImagePickerDialogPreviewUrlLabel = 'ImageManagmentPage_ImagePickerDialogPreviewUrlLabel',
        ImagePickerDialogPreviewUrlText = 'ImageManagmentPage_ImagePickerDialogPreviewUrlText',
        ImagePickerDialogSizeLabel = 'ImageManagmentPage_ImagePickerDialogSizeLabel',
        ImagePickerDialogTitle = 'ImageManagmentPage_ImagePickerDialogTitle',
        ImagePickerDialogChoiceGroupLabel = 'ImageManagmentPage_ImagePickerDialogChoiceGroupLabel',
        ImagePickerDialogChoiceGroupOption1Text = 'ImageManagmentPage_ImagePickerDialogChoiceGroupOption1Text',
        ImagePickerDialogChoiceGroupOption2Text = 'ImageManagmentPage_ImagePickerDialogChoiceGroupOption2Text',
    }
    export enum SettingsPage {
        SettingsTitle = 'SettingsPage_SettingsTitle',
        ComplianceManagerReaderRole = 'SettingsPage_ComplianceManagerReaderRole',
        ComplianceManagerReaderRoleDescription = 'SettingsPage_ComplianceManagerReaderRoleDescription',
        ComplianceManagerContributorRole = 'SettingsPage_ComplianceManagerContributorRole',
        ComplianceManagerContributorRoleDescription = 'SettingsPage_ComplianceManagerContributorRoleDescription',
        ComplianceManagerAssessorRole = 'SettingsPage_ComplianceManagerAssessorRole',
        ComplianceManagerAssessorRoleDescription = 'SettingsPage_ComplianceManagerAssessorRoleDescription',
        ComplianceManagerAdministratorRole = 'SettingsPage_ComplianceManagerAdministratorRole',
        ComplianceManagerAdministratorRoleDescription = 'SettingsPage_ComplianceManagerAdministratorRoleDescription',
        EveryoneRoleLabel = 'SettingsPage_EveryoneRoleLabel',
        SelectedRoleTableText = 'SettingsPage_SelectedRoleTableText',
        SelectedRoleTableHeaderAriaText = 'SettingsPage_SelectedRoleTableHeaderAriaText',
        SelectedRoleTableSelectAllCheckboxAriaText = 'SettingsPage_SelectedRoleTableSelectAllCheckboxAriaText',
        PortalAdminRole = 'SettingsPage_PortalAdminRole',
        PortalAdminRoleDescription = 'SettingsPage_PortalAdminRoleDescription',
        PortalPrivacyManagerRole = 'SettingsPage_PortalPrivacyManagerRole',
        PortalPrivacyManagerRoleDescription = 'SettingsPage_PortalPrivacyManagerRoleDescription',
        AccessToSensitiveDocumentsRole = 'SettingsPage_AccessToSensitiveDocumentsRole',
        AccessToSensitiveDocumentsRoleDescription = 'SettingsPage_AccessToSensitiveDocumentsRoleDescription',
        NameColumn = 'SettingsPage_NameColumn',
        EmailColumn = 'SettingsPage_EmailColumn',
        SelectRoleText = 'SettingsPage_SelectRoleText',
        SelectedRoleText = 'SettingsPage_SelectedRoleText',
        SelectedDomainText = 'SettingsPage_SelectedDomainText',
        AddUserText = 'SettingsPage_AddUserText',
        DeleteUsersText = 'SettingsPage_DeleteUsersText',
        DeleteUsersDescription = 'SettingsPage_DeleteUsersDescription',
        UserRoleText = 'SettingsPage_UserRoleText',
        SecureScoreText = 'SettingsPage_SecureScoreText',
        SecureScoreSaveDialogText = 'SettingsPage_SecureScoreSaveDialogText',
    }
    export enum BreachNotificationPage {
        HeadingTitle = 'BreachNotificationPage_HeadingTitle',
    }
    export enum TopNavigationManagementPage {
        Title = 'TopNavigationManagementPage_Title',
    }
    export enum VocabulariesManagementPage {
        Title = 'VocabulariesManagementPage_Title',
    }
    export enum PagesManagementPage {
        Title = 'PagesManagementPage_Title',
        UrlNameColumnLabel = 'PagesManagementPage_UrlNameColumnLabel',
        StatusColumnLabel = 'PagesManagementPage_StatusColumnLabel',
        DateModifiedColumnLabel = 'PagesManagementPage_DateModifiedColumnLabel',
        OwnerColumnLabel = 'PagesManagementPage_OwnerColumnLabel',
        WhoModifiedColumnLabel = 'PagesManagementPage_WhoModifiedColumnLabel',
        HasPublicVersionColumnLabel = 'PagesManagementPage_HasPublicVersionColumnLabel',
        ViewPublicPageLabel = 'PagesManagementPage_ViewPublicPageLabel',
    }
    export enum GDPRCompliance {
        NoPermissionErrorMessage = 'GDPRCompliance_NoPermissionErrorMessage',
        ExportServiceIndividualDataExportTitle = 'GDPRCompliance_ExportServiceIndividualDataExportTitle',
        ExportServiceIndividualDataExportDescription = 'GDPRCompliance_ExportServiceIndividualDataExportDescription',
        ExportServiceIndividualDataExportMostRequestedServicesHeader = 'GDPRCompliance_ExportServiceIndividualDataExportMostRequestedServicesHeader',
        ExportServiceIndividualDataExportAllServicesHeader = 'GDPRCompliance_ExportServiceIndividualDataExportAllServicesHeader',
        ExportServiceIndividualDataExportServiceColumn = 'GDPRCompliance_ExportServiceIndividualDataExportServiceColumn',
        ExportServiceIndividualDataExportProductColumn = 'GDPRCompliance_ExportServiceIndividualDataExportProductColumn',
        ExportServiceIndividualDataExportAccessColumn = 'GDPRCompliance_ExportServiceIndividualDataExportAccessColumn',
        ExportServiceIndividualDataExportDocumentationColumn = 'GDPRCompliance_ExportServiceIndividualDataExportDocumentationColumn',
        ExportServiceHeaderTitle = 'GDPRCompliance_ExportServiceHeaderTitle',
        ExportServiceHeaderSubTitle = 'GDPRCompliance_ExportServiceHeaderSubTitle',
        PrivacyManagerPaginationNextLabel = 'GDPRCompliance_PrivacyManagerPaginationNextLabel',
        PrivacyManagerPaginationPreviousLabel = 'GDPRCompliance_PrivacyManagerPaginationPreviousLabel',
        PrivacyManagerPaginationShowingLabel = 'GDPRCompliance_PrivacyManagerPaginationShowingLabel',
        PrivacyManagerResultsPerPage = 'GDPRCompliance_PrivacyManagerResultsPerPage',
        PrivacyManagerTitle = 'GDPRCompliance_PrivacyManagerTitle',
        PrivacyManagerSubTitle = 'GDPRCompliance_PrivacyManagerSubTitle',
        PrivacyManagerTitleSummary = 'GDPRCompliance_PrivacyManagerTitleSummary',
        PrivacyManagerPrivacyOptionsListError = 'GDPRCompliance_PrivacyManagerPrivacyOptionsListError',
        PrivacyManagerPreviewReleaseNotesLabel = 'GDPRCompliance_PrivacyManagerPreviewReleaseNotesLabel',
        PrivacyManagerPreviewReleaseNotesLinkLabel = 'GDPRCompliance_PrivacyManagerPreviewReleaseNotesLinkLabel',
        PrivacyManagerStatusLabel = 'GDPRCompliance_PrivacyManagerStatusLabel',
        PrivacyManagerDataRequestedLabel = 'GDPRCompliance_PrivacyManagerDataRequestedLabel',
        PrivacyManagerDataCompletedLabel = 'GDPRCompliance_PrivacyManagerDataCompletedLabel',
        PrivacyManagerDataRequestsLabel = 'GDPRCompliance_PrivacyManagerDataRequestsLabel',
        PrivacyManagerRequestSummary = 'GDPRCompliance_PrivacyManagerRequestSummary',
        PrivacyManagerGridPendingLabel = 'GDPRCompliance_PrivacyManagerGridPendingLabel',
        PrivacyManagerGridCompletedLabel = 'GDPRCompliance_PrivacyManagerGridCompletedLabel',
        PrivacyManagerGridFailedLabel = 'GDPRCompliance_PrivacyManagerGridFailedLabel',
        PrivacyManagerGridExpiredLabel = 'GDPRCompliance_PrivacyManagerGridExpiredLabel',
        PrivacyManagerGridCreateRequestButton = 'GDPRCompliance_PrivacyManagerGridCreateRequestButton',
        PrivacyManagerGridHelpLink = 'GDPRCompliance_PrivacyManagerGridHelpLink',
        PrivacyManagerGridNameLabel = 'GDPRCompliance_PrivacyManagerGridNameLabel',
        PrivacyManagerGridStatusLabel = 'GDPRCompliance_PrivacyManagerGridStatusLabel',
        PrivacyManagerGridProgressLabel = 'GDPRCompliance_PrivacyManagerGridProgressLabel',
        PrivacyManagerGridDateRequestedLabel = 'GDPRCompliance_PrivacyManagerGridDateRequestedLabel',
        PrivacyManagerGridContainerLabel = 'GDPRCompliance_PrivacyManagerGridContainerLabel',
        PrivacyManagerGridRequestIdLabel = 'GDPRCompliance_PrivacyManagerGridRequestIdLabel',
        PrivacyManagerGridLinkExpiresLabel = 'GDPRCompliance_PrivacyManagerGridLinkExpiresLabel',
        PrivacyManagerGridSizeLabel = 'GDPRCompliance_PrivacyManagerGridSizeLabel',
        PrivacyManagerGridLinksLabel = 'GDPRCompliance_PrivacyManagerGridLinksLabel',
        PrivacyManagerGridViewFilesAndLinksLabel = 'GDPRCompliance_PrivacyManagerGridViewFilesAndLinksLabel',
        PrivacyManagerGridDetailsLabel = 'GDPRCompliance_PrivacyManagerGridDetailsLabel',
        PrivacyManagerDetailsPanelHeader = 'GDPRCompliance_PrivacyManagerDetailsPanelHeader',
        PrivacyManagerDetailsPanelUserLabel = 'GDPRCompliance_PrivacyManagerDetailsPanelUserLabel',
        PrivacyManagerDetailsPanelRequestedByLabel = 'GDPRCompliance_PrivacyManagerDetailsPanelRequestedByLabel',
        PrivacyManagerDetailsPanelDateCompletedLabel = 'GDPRCompliance_PrivacyManagerDetailsPanelDateCompletedLabel',
        PrivacyManagerDateRequestedFilterLast30Days = 'GDPRCompliance_PrivacyManagerDateRequestedFilterLast30Days',
        PrivacyManagerDateRequestedFilterLast90Days = 'GDPRCompliance_PrivacyManagerDateRequestedFilterLast90Days',
        PrivacyManagerDateRequestedFilterLast365Days = 'GDPRCompliance_PrivacyManagerDateRequestedFilterLast365Days',
        PrivacyManagerDetailsPanelFilesLabel = 'GDPRCompliance_PrivacyManagerDetailsPanelFilesLabel',
        PrivacyManagerPrivacyOperationStatusCompletedLabel = 'GDPRCompliance_PrivacyManagerPrivacyOperationStatusCompletedLabel',
        PrivacyManagerPrivacyOperationStatusFailedLabel = 'GDPRCompliance_PrivacyManagerPrivacyOperationStatusFailedLabel',
        PrivacyManagerPrivacyOperationStatusPendingLabel = 'GDPRCompliance_PrivacyManagerPrivacyOperationStatusPendingLabel',
        PrivacyManagerPrivacyOperationFiltersAllOptionLabel = 'GDPRCompliance_PrivacyManagerPrivacyOperationFiltersAllOptionLabel',
        StorageUriContainsListAccess = 'GDPRCompliance_StorageUriContainsListAccess',
        StorageUriContainsReadAccess = 'GDPRCompliance_StorageUriContainsReadAccess',
        StorageUriContainsNoWriteAccess = 'GDPRCompliance_StorageUriContainsNoWriteAccess',
        UsedStorageContainer = 'GDPRCompliance_UsedStorageContainer',
        StorageUriContainsNoSASCredentials = 'GDPRCompliance_StorageUriContainsNoSASCredentials',
        CreateExportDataRequestNotificationLabel = 'GDPRCompliance_CreateExportDataRequestNotificationLabel',
        ExportDataNotEnabledLabel = 'GDPRCompliance_ExportDataNotEnabledLabel',
        CreateExportDataRequestNotificationLinkLabel = 'GDPRCompliance_CreateExportDataRequestNotificationLinkLabel',
        CreateExportDataRequestBackToDashboard = 'GDPRCompliance_CreateExportDataRequestBackToDashboard',
        CreateExportDataRequestTitle = 'GDPRCompliance_CreateExportDataRequestTitle',
        CreateExportDataInvalidDates = 'GDPRCompliance_CreateExportDataInvalidDates',
        CreateExportDataInvalidStartDate = 'GDPRCompliance_CreateExportDataInvalidStartDate',
        CreateExportDataInvalidEndDate = 'GDPRCompliance_CreateExportDataInvalidEndDate',
        CreateExportDataUnknownErrorOccured = 'GDPRCompliance_CreateExportDataUnknownErrorOccured',
        CreateExportDataCreateDataRequestSuccess = 'GDPRCompliance_CreateExportDataCreateDataRequestSuccess',
        CreateExportDataInvalidSASUri = 'GDPRCompliance_CreateExportDataInvalidSASUri',
        CreateExportDataRequestSubTitle = 'GDPRCompliance_CreateExportDataRequestSubTitle',
        CreateExportDataRequestSubTitleForBlackforestCloudInstance = 'GDPRCompliance_CreateExportDataRequestSubTitleForBlackforestCloudInstance',
        CreateExportDataRequestTitleDescription = 'GDPRCompliance_CreateExportDataRequestTitleDescription',
        CreateExportDataRequestCTAPortalLinkText = 'GDPRCompliance_CreateExportDataRequestCTAPortalLinkText',
        CreateExportDataRequestExportForHeading = 'GDPRCompliance_CreateExportDataRequestExportForHeading',
        CreateExportDataRequestExportForLabel = 'GDPRCompliance_CreateExportDataRequestExportForLabel',
        CreateExportDataRequestExportForSubTitle = 'GDPRCompliance_CreateExportDataRequestExportForSubTitle',
        CreateExportDataRequestAzureBlobUrlLabel = 'GDPRCompliance_CreateExportDataRequestAzureBlobUrlLabel',
        CreateExportDataRequestAzureSubscriptionLabel = 'GDPRCompliance_CreateExportDataRequestAzureSubscriptionLabel',
        CreateExportDataRequestSelectSubscriptionLabel = 'GDPRCompliance_CreateExportDataRequestSelectSubscriptionLabel',
        CreateExportDataRequestNoSubscriptionErrorLabel = 'GDPRCompliance_CreateExportDataRequestNoSubscriptionErrorLabel',
        CreateExportDataRequestAzureStorageAccountLabel = 'GDPRCompliance_CreateExportDataRequestAzureStorageAccountLabel',
        CreateExportDataRequestSelectStorageAccountLabel = 'GDPRCompliance_CreateExportDataRequestSelectStorageAccountLabel',
        CreateExportDataRequestAzureStorageContainerLabel = 'GDPRCompliance_CreateExportDataRequestAzureStorageContainerLabel',
        CreateExportDataRequestExportButton = 'GDPRCompliance_CreateExportDataRequestExportButton',
        CreateExportDataRequestExportButtonDescription = 'GDPRCompliance_CreateExportDataRequestExportButtonDescription',
        CreateExportDataRequestExportFAQLabel = 'GDPRCompliance_CreateExportDataRequestExportFAQLabel',
        CreateExportDataRequestAllServicesHeading = 'GDPRCompliance_CreateExportDataRequestAllServicesHeading',
        CreateExportDataRequestAllServicesSummary = 'GDPRCompliance_CreateExportDataRequestAllServicesSummary',
        CreateExportDataRequestAllServicesLinkText = 'GDPRCompliance_CreateExportDataRequestAllServicesLinkText',
        ExportedFilesDataExportRequestLabel = 'GDPRCompliance_ExportedFilesDataExportRequestLabel',
        ExportedFilesDataExportStatusLabel = 'GDPRCompliance_ExportedFilesDataExportStatusLabel',
        ExportedFilesDataExportRequestByLabel = 'GDPRCompliance_ExportedFilesDataExportRequestByLabel',
        ExportedFilesDataExportDateRequestedLabel = 'GDPRCompliance_ExportedFilesDataExportDateRequestedLabel',
        ExportedFilesDataExportDateCompletedLabel = 'GDPRCompliance_ExportedFilesDataExportDateCompletedLabel',
        ExportedFilesDataExportLinkExpiresLabel = 'GDPRCompliance_ExportedFilesDataExportLinkExpiresLabel',
        ExportedFilesDataExportSizeLabel = 'GDPRCompliance_ExportedFilesDataExportSizeLabel',
        ExportedFilesDataExportedGridTitle = 'GDPRCompliance_ExportedFilesDataExportedGridTitle',
        ExportedFilesDataExportedGridServicesLabel = 'GDPRCompliance_ExportedFilesDataExportedGridServicesLabel',
        ExportedFilesDataExportedGridDownloadAllButton = 'GDPRCompliance_ExportedFilesDataExportedGridDownloadAllButton',
        ExportedFilesDataExportedGridFileNameLabel = 'GDPRCompliance_ExportedFilesDataExportedGridFileNameLabel',
        ExportedFilesDataExportedGridSizeLabel = 'GDPRCompliance_ExportedFilesDataExportedGridSizeLabel',
        ExportedFilesDataExportedGridLinkExpiresLabel = 'GDPRCompliance_ExportedFilesDataExportedGridLinkExpiresLabel',
        FeatureNotificationLabel = 'GDPRCompliance_FeatureNotificationLabel',
    }
    export enum ComplianceManager {
        ActionsLabel = 'ComplianceManager_ActionsLabel',
        ArchivedFrameworksLabel = 'ComplianceManager_ArchivedFrameworksLabel',
        ArchiveFrameworksLabel = 'ComplianceManager_ArchiveFrameworksLabel',
        ExportToExcelLabel = 'ComplianceManager_ExportToExcelLabel',
        CopyTileLinkLabel = 'ComplianceManager_CopyTileLinkLabel',
        RenameFrameworkLabel = 'ComplianceManager_RenameFrameworkLabel',
        DeleteFrameworkLabel = 'ComplianceManager_DeleteFrameworkLabel',
        RenameTileLabel = 'ComplianceManager_RenameTileLabel',
        RenameGroupLabel = 'ComplianceManager_RenameGroupLabel',
        ReviewActionLabel = 'ComplianceManager_ReviewActionLabel',
        RenderRenameDialogTextExistedErrorMessage = 'ComplianceManager_RenderRenameDialogTextExistedErrorMessage',
        RenderRenameGroupDialogTextEmptyErrorMessage = 'ComplianceManager_RenderRenameGroupDialogTextEmptyErrorMessage',
        ArchiveLabel = 'ComplianceManager_ArchiveLabel',
        ArchiveTileLabel = 'ComplianceManager_ArchiveTileLabel',
        MoreActionsLabel = 'ComplianceManager_MoreActionsLabel',
        DeleteTileLabel = 'ComplianceManager_DeleteTileLabel',
        DeleteTileTitleLabel = 'ComplianceManager_DeleteTileTitleLabel',
        NewTileName = 'ComplianceManager_NewTileName',
        NewGroupName = 'ComplianceManager_NewGroupName',
        ArchiveTileWarning = 'ComplianceManager_ArchiveTileWarning',
        DeleteTileWarning = 'ComplianceManager_DeleteTileWarning',
        UnArchiveFrameworksLabel = 'ComplianceManager_UnArchiveFrameworksLabel',
        RiskComplianceDashboard = 'ComplianceManager_RiskComplianceDashboard',
        AssessmentTab = 'ComplianceManager_AssessmentTab',
        ActionItems = 'ComplianceManager_ActionItems',
        CheckServiceCompliance = 'ComplianceManager_CheckServiceCompliance',
        ShowArchivedLabel = 'ComplianceManager_ShowArchivedLabel',
    }
    export enum ComplianceManagerV3 {
        ShowHiddenLabel = 'ComplianceManagerV3_ShowHiddenLabel',
    }
    export enum ComplianceManager {
        ShowApprovedLabel = 'ComplianceManager_ShowApprovedLabel',
        ShowUnapprovedLabel = 'ComplianceManager_ShowUnapprovedLabel',
        AddFrameworkLabel = 'ComplianceManager_AddFrameworkLabel',
        AddFrameworkStep1Notice = 'ComplianceManager_AddFrameworkStep1Notice',
        AddFrameworkCertificateValidationError = 'ComplianceManager_AddFrameworkCertificateValidationError',
        AddFrameworkNewGroupValidationError = 'ComplianceManager_AddFrameworkNewGroupValidationError',
        AddFrameworkSelectedGroupToCopyData = 'ComplianceManager_AddFrameworkSelectedGroupToCopyData',
        TestPlanCheckBoxLabel = 'ComplianceManager_TestPlanCheckBoxLabel',
        ImplementationDetailsCheckBoxLabel = 'ComplianceManager_ImplementationDetailsCheckBoxLabel',
        DocumentCheckBoxLabel = 'ComplianceManager_DocumentCheckBoxLabel',
        AddFrameworkStep2Notice = 'ComplianceManager_AddFrameworkStep2Notice',
        FilterProductsLabel = 'ComplianceManager_FilterProductsLabel',
        FilterGroupsLabel = 'ComplianceManager_FilterGroupsLabel',
        FilterItemsLabel = 'ComplianceManager_FilterItemsLabel',
        CompliantLabel = 'ComplianceManager_CompliantLabel',
        NonCompliantLabel = 'ComplianceManager_NonCompliantLabel',
    }
    export enum ComplianceManagerV3 {
        NonCompliantLabel = 'ComplianceManagerV3_NonCompliantLabel',
    }
    export enum ComplianceManager {
        InProgressLabel = 'ComplianceManager_InProgressLabel',
        ProductsLabel = 'ComplianceManager_ProductsLabel',
        CreatedLabel = 'ComplianceManager_CreatedLabel',
        ModifiedLabel = 'ComplianceManager_ModifiedLabel',
        TestResult = 'ComplianceManager_TestResult',
        Help = 'ComplianceManager_Help',
        HelpHeading = 'ComplianceManager_HelpHeading',
        HelpIntroHeading = 'ComplianceManager_HelpIntroHeading',
        HelpIntroStep1 = 'ComplianceManager_HelpIntroStep1',
        HelpIntroStep2 = 'ComplianceManager_HelpIntroStep2',
        HelpIntroHeading3 = 'ComplianceManager_HelpIntroHeading3',
        HelpStep1Heading = 'ComplianceManager_HelpStep1Heading',
        HelpStep1Body = 'ComplianceManager_HelpStep1Body',
        HelpStep2Heading = 'ComplianceManager_HelpStep2Heading',
        HelpStep2Body = 'ComplianceManager_HelpStep2Body',
        HelpStep3Heading = 'ComplianceManager_HelpStep3Heading',
        HelpStep3Body = 'ComplianceManager_HelpStep3Body',
        HelpStep4Heading = 'ComplianceManager_HelpStep4Heading',
        HelpStep4Body = 'ComplianceManager_HelpStep4Body',
        HelpStep5Heading = 'ComplianceManager_HelpStep5Heading',
        HelpStep5Body = 'ComplianceManager_HelpStep5Body',
        HelpStep6Heading = 'ComplianceManager_HelpStep6Heading',
        HelpStep6Body = 'ComplianceManager_HelpStep6Body',
        HelpStepNextLabel = 'ComplianceManager_HelpStepNextLabel',
        HelpStepAddFrameworkLabel = 'ComplianceManager_HelpStepAddFrameworkLabel',
        HelpStepDoneLabel = 'ComplianceManager_HelpStepDoneLabel',
        NotStartedLabel = 'ComplianceManager_NotStartedLabel',
        MicrosoftImplementationDetails = 'ComplianceManager_MicrosoftImplementationDetails',
        TestPlanDetails = 'ComplianceManager_TestPlanDetails',
        ManagementResponse = 'ComplianceManager_ManagementResponse',
        FrameworkDetailNotice = 'ComplianceManager_FrameworkDetailNotice',
        FrameworkDetailCustomerManagedControl = 'ComplianceManager_FrameworkDetailCustomerManagedControl',
        FrameworkDetailMicrosoftManagedControl = 'ComplianceManager_FrameworkDetailMicrosoftManagedControl',
        PreReaderNoticePart1 = 'ComplianceManager_PreReaderNoticePart1',
        PreReaderNoticePart2 = 'ComplianceManager_PreReaderNoticePart2',
        PreReaderNoticePart3 = 'ComplianceManager_PreReaderNoticePart3',
        PreReaderNoticePart4 = 'ComplianceManager_PreReaderNoticePart4',
        PreReaderNoticePart5 = 'ComplianceManager_PreReaderNoticePart5',
        WrongArticleNameErrorMessage = 'ComplianceManager_WrongArticleNameErrorMessage',
        FrameworkDetailCustomerControlsLabel = 'ComplianceManager_FrameworkDetailCustomerControlsLabel',
        FrameworkDetailMicrosoftControlsLabel = 'ComplianceManager_FrameworkDetailMicrosoftControlsLabel',
        FrameworkDetailMicrosoftScore = 'ComplianceManager_FrameworkDetailMicrosoftScore',
        FrameworkDetailCustomerScore = 'ComplianceManager_FrameworkDetailCustomerScore',
        GroupNameLabel = 'ComplianceManager_GroupNameLabel',
        FrameworkDetailProductLabel = 'ComplianceManager_FrameworkDetailProductLabel',
        FrameworkDetailFrameworkLabel = 'ComplianceManager_FrameworkDetailFrameworkLabel',
        FrameworkDetailCompliantControlsLabel = 'ComplianceManager_FrameworkDetailCompliantControlsLabel',
        FrameworkDetailStatusLabel = 'ComplianceManager_FrameworkDetailStatusLabel',
        FrameworkDetailNotAssesseddLabel = 'ComplianceManager_FrameworkDetailNotAssesseddLabel',
        FrameworkDetailsNotInScopeLabel = 'ComplianceManager_FrameworkDetailsNotInScopeLabel',
        FrameworkDetailPassedLabel = 'ComplianceManager_FrameworkDetailPassedLabel',
        FrameworkDetailFailedLowRiskLabel = 'ComplianceManager_FrameworkDetailFailedLowRiskLabel',
        FrameworkDetailFailedMediumRiskLabel = 'ComplianceManager_FrameworkDetailFailedMediumRiskLabel',
        FrameworkDetailFailedHighRiskLabel = 'ComplianceManager_FrameworkDetailFailedHighRiskLabel',
        FrameworkDetailLastModifiedLabel = 'ComplianceManager_FrameworkDetailLastModifiedLabel',
        FrameworkDetailNotApplicableLabel = 'ComplianceManager_FrameworkDetailNotApplicableLabel',
        RelatedArticleNotFound = 'ComplianceManager_RelatedArticleNotFound',
        FrameworkDetailAssessedLabel = 'ComplianceManager_FrameworkDetailAssessedLabel',
        FilterArticleName = 'ComplianceManager_FilterArticleName',
        FilterNoArticleFound = 'ComplianceManager_FilterNoArticleFound',
        FilterControls = 'ComplianceManager_FilterControls',
        FilterNoControlsFound = 'ComplianceManager_FilterNoControlsFound',
        FilterAssignedUsers = 'ComplianceManager_FilterAssignedUsers',
    }
    export enum ComplianceManagerV3 {
        FilterAssignedTo = 'ComplianceManagerV3_FilterAssignedTo',
    }
    export enum ComplianceManager {
        FilterSelectOptions = 'ComplianceManager_FilterSelectOptions',
        FilterNoUserAssigned = 'ComplianceManager_FilterNoUserAssigned',
        FilterStatus = 'ComplianceManager_FilterStatus',
        FilterTestResult = 'ComplianceManager_FilterTestResult',
        FilterToolTip = 'ComplianceManager_FilterToolTip',
        Loading = 'ComplianceManager_Loading',
        SavingChanges = 'ComplianceManager_SavingChanges',
        TestDate = 'ComplianceManager_TestDate',
        TestedBy = 'ComplianceManager_TestedBy',
        ThirdPartyIndependentAuditor = 'ComplianceManager_ThirdPartyIndependentAuditor',
        ActionItemsIntro = 'ComplianceManager_ActionItemsIntro',
        ActionItemsAddNotesLabel = 'ComplianceManager_ActionItemsAddNotesLabel',
        ActionItemsImplementationDetails = 'ComplianceManager_ActionItemsImplementationDetails',
    }
    export enum ComplianceManagerV3 {
        ActionItemsTestPlanDetails = 'ComplianceManagerV3_ActionItemsTestPlanDetails',
        ActionItemsManagementResponse = 'ComplianceManagerV3_ActionItemsManagementResponse',
    }
    export enum ComplianceManager {
        ActionItemsReadOnlyImplementationDetails = 'ComplianceManager_ActionItemsReadOnlyImplementationDetails',
        ActionItemsTestPlanAndManagementResponse = 'ComplianceManager_ActionItemsTestPlanAndManagementResponse',
        ActionItemsByAssignmentLabel = 'ComplianceManager_ActionItemsByAssignmentLabel',
        ActionItemsUnAssigedItemsLabel = 'ComplianceManager_ActionItemsUnAssigedItemsLabel',
        ActionItemsAssigedItemsLabel = 'ComplianceManager_ActionItemsAssigedItemsLabel',
        ActionItemsNotImplementedLabel = 'ComplianceManager_ActionItemsNotImplementedLabel',
        ActionItemsImplementedLabel = 'ComplianceManager_ActionItemsImplementedLabel',
        ActionItemsAlternativeImplementationLabel = 'ComplianceManager_ActionItemsAlternativeImplementationLabel',
        ActionItemsPlannedLabel = 'ComplianceManager_ActionItemsPlannedLabel',
        ActionItemsNotInScopeLabel = 'ComplianceManager_ActionItemsNotInScopeLabel',
        ActionItemsPriorityLabel = 'ComplianceManager_ActionItemsPriorityLabel',
        ActionItemsPriorityHighLabel = 'ComplianceManager_ActionItemsPriorityHighLabel',
        ActionItemsPriorityMediumLabel = 'ComplianceManager_ActionItemsPriorityMediumLabel',
        ActionItemsPriorityLowLabel = 'ComplianceManager_ActionItemsPriorityLowLabel',
        ActionItemsMSControlLabel = 'ComplianceManager_ActionItemsMSControlLabel',
        ActionItemsCertificationControlLabel = 'ComplianceManager_ActionItemsCertificationControlLabel',
        ActionItemsAssignedToColumnHeaderLabel = 'ComplianceManager_ActionItemsAssignedToColumnHeaderLabel',
        ActionItemsPriorityColumnHeaderLabel = 'ComplianceManager_ActionItemsPriorityColumnHeaderLabel',
        ActionItemsImplementationNotesColumnHeaderLabel = 'ComplianceManager_ActionItemsImplementationNotesColumnHeaderLabel',
        ActionItemsDateImplementedColumnHeaderLabel = 'ComplianceManager_ActionItemsDateImplementedColumnHeaderLabel',
        ActionItemsStatusColumnHeaderLabel = 'ComplianceManager_ActionItemsStatusColumnHeaderLabel',
        ActionItemsDescriptionColumnHeaderLabel = 'ComplianceManager_ActionItemsDescriptionColumnHeaderLabel',
        ActionItemsCustomerActionsColumnHeaderLabel = 'ComplianceManager_ActionItemsCustomerActionsColumnHeaderLabel',
        ActionItemsColumnSelect = 'ComplianceManager_ActionItemsColumnSelect',
        ConfirmDeleteDescription = 'ComplianceManager_ConfirmDeleteDescription',
        ConfirmModifyImplementationDate = 'ComplianceManager_ConfirmModifyImplementationDate',
        ConfirmUpdate = 'ComplianceManager_ConfirmUpdate',
        ActionItemsTestPlanColumnHeaderLabel = 'ComplianceManager_ActionItemsTestPlanColumnHeaderLabel',
        ActionItemsEditLabel = 'ComplianceManager_ActionItemsEditLabel',
        ActionItemsManageDocuments = 'ComplianceManager_ActionItemsManageDocuments',
        ActionItemsUpload = 'ComplianceManager_ActionItemsUpload',
        ActionItemsDownload = 'ComplianceManager_ActionItemsDownload',
        ActionItemsDelete = 'ComplianceManager_ActionItemsDelete',
        ActionItemsUploadingDocument = 'ComplianceManager_ActionItemsUploadingDocument',
        ActionItemsUploadingLargeFile = 'ComplianceManager_ActionItemsUploadingLargeFile',
        ActionItemsUploadingNonSupportedFormatFile = 'ComplianceManager_ActionItemsUploadingNonSupportedFormatFile',
        ActionItemsUploadingEmptyFile = 'ComplianceManager_ActionItemsUploadingEmptyFile',
        ActionItemsDocumentUploadFilterText = 'ComplianceManager_ActionItemsDocumentUploadFilterText',
        ActionItemsUploadedDocName = 'ComplianceManager_ActionItemsUploadedDocName',
        ActionItemsDateUploaded = 'ComplianceManager_ActionItemsDateUploaded',
        ActionItemsWhoUploaded = 'ComplianceManager_ActionItemsWhoUploaded',
        ActionItemsFileSize = 'ComplianceManager_ActionItemsFileSize',
        ActionItemsEmpty = 'ComplianceManager_ActionItemsEmpty',
        UserRemoved = 'ComplianceManager_UserRemoved',
    }
    export enum ComplianceManagerDashboard {
        AddFrameworkLabel = 'ComplianceManagerDashboard_AddFrameworkLabel',
        ProductEvaluationLabel = 'ComplianceManagerDashboard_ProductEvaluationLabel',
        SelectGroupLabel = 'ComplianceManagerDashboard_SelectGroupLabel',
        NewGroupLabel = 'ComplianceManagerDashboard_NewGroupLabel',
        ExistingGroupLabel = 'ComplianceManagerDashboard_ExistingGroupLabel',
        SelectProductLabel = 'ComplianceManagerDashboard_SelectProductLabel',
        NextButtonLabel = 'ComplianceManagerDashboard_NextButtonLabel',
        PreviousButtonLabel = 'ComplianceManagerDashboard_PreviousButtonLabel',
        FrameworkEvaluationLabel = 'ComplianceManagerDashboard_FrameworkEvaluationLabel',
        BackButtonLabel = 'ComplianceManagerDashboard_BackButtonLabel',
        AddToDashboardButtonLabel = 'ComplianceManagerDashboard_AddToDashboardButtonLabel',
        NameTheFrameworkLabel = 'ComplianceManagerDashboard_NameTheFrameworkLabel',
        PleaseEnterFrameowrkNameLabel = 'ComplianceManagerDashboard_PleaseEnterFrameowrkNameLabel',
    }
    export enum ComplianceManagerAssigntask {
        ReAssignLabel = 'ComplianceManagerAssigntask_ReAssignLabel',
        UnAssignLabel = 'ComplianceManagerAssigntask_UnAssignLabel',
        AssignBtnLabel = 'ComplianceManagerAssigntask_AssignBtnLabel',
        AssignTaskLabel = 'ComplianceManagerAssigntask_AssignTaskLabel',
        AssignToLabel = 'ComplianceManagerAssigntask_AssignToLabel',
        SelectPriorityLabel = 'ComplianceManagerAssigntask_SelectPriorityLabel',
        SelectAnOptionLabel = 'ComplianceManagerAssigntask_SelectAnOptionLabel',
        MSControlLabel = 'ComplianceManagerAssigntask_MSControlLabel',
        CertificationControlLabel = 'ComplianceManagerAssigntask_CertificationControlLabel',
        SendEmailNotificationsLabel = 'ComplianceManagerAssigntask_SendEmailNotificationsLabel',
        AssignTaskNotesLabel = 'ComplianceManagerAssigntask_AssignTaskNotesLabel',
        ErrorDetailsLabel = 'ComplianceManagerAssigntask_ErrorDetailsLabel',
        FailedToAssignTask = 'ComplianceManagerAssigntask_FailedToAssignTask',
        FailedToSendEmail = 'ComplianceManagerAssigntask_FailedToSendEmail',
        ErrorDialogTitle = 'ComplianceManagerAssigntask_ErrorDialogTitle',
        CloseDialog = 'ComplianceManagerAssigntask_CloseDialog',
        TryAgainLabel = 'ComplianceManagerAssigntask_TryAgainLabel',
    }
    export enum ComplianceManagerDashboard {
        Disclaimer = 'ComplianceManagerDashboard_Disclaimer',
        CloudServiceScopeDescription = 'ComplianceManagerDashboard_CloudServiceScopeDescription',
    }
    export enum ComplianceManagerContent {
        Save = 'ComplianceManagerContent_Save',
        Close = 'ComplianceManagerContent_Close',
        Cancel = 'ComplianceManagerContent_Cancel',
        Publish = 'ComplianceManagerContent_Publish',
        Approve = 'ComplianceManagerContent_Approve',
        Reject = 'ComplianceManagerContent_Reject',
        ApproveContent = 'ComplianceManagerContent_ApproveContent',
        RejectContent = 'ComplianceManagerContent_RejectContent',
        RejectConfirmation = 'ComplianceManagerContent_RejectConfirmation',
        ApproveConfirmation = 'ComplianceManagerContent_ApproveConfirmation',
    }
    export enum ComplianceManagerHelp {
        TakeTheTourLabel = 'ComplianceManagerHelp_TakeTheTourLabel',
    }
    export enum ComplianceManagerValidation {
        DateGreaterThanToday = 'ComplianceManagerValidation_DateGreaterThanToday',
    }
    export enum ComplianceManager {
        FrameworkDetailMore = 'ComplianceManager_FrameworkDetailMore',
        BackToDashboard = 'ComplianceManager_BackToDashboard',
        AriaBackToDashboard = 'ComplianceManager_AriaBackToDashboard',
    }
    export enum ComplianceManagerHelp {
        DashboardImageAltText = 'ComplianceManagerHelp_DashboardImageAltText',
        CloseDialog = 'ComplianceManagerHelp_CloseDialog',
    }
    export enum ComplianceManagerDashboard {
        FacePileAriaDescription = 'ComplianceManagerDashboard_FacePileAriaDescription',
    }
    export enum ComplianceManager {
        AssignedPeople = 'ComplianceManager_AssignedPeople',
        FacePileAriaDescrptiontoMove = 'ComplianceManager_FacePileAriaDescrptiontoMove',
    }
    export enum ComplianceManagerAssigntask {
        ReAssignOrAssignLabel = 'ComplianceManagerAssigntask_ReAssignOrAssignLabel',
    }
    export enum ComplianceManager {
        AriaDateLabel = 'ComplianceManager_AriaDateLabel',
        FilterLabel = 'ComplianceManager_FilterLabel',
        AriaClearLabel = 'ComplianceManager_AriaClearLabel',
        ManageDocumentsSelectAllDocuemnts = 'ComplianceManager_ManageDocumentsSelectAllDocuemnts',
        ManageDocumentsSelectDocuemnt = 'ComplianceManager_ManageDocumentsSelectDocuemnt',
        ComplianceFrameworkForIndustryStandards = 'ComplianceManager_ComplianceFrameworkForIndustryStandards',
        HelpArticle = 'ComplianceManager_HelpArticle',
        ReadMore = 'ComplianceManager_ReadMore',
        ReadLess = 'ComplianceManager_ReadLess',
        PrereaderBannerRole = 'ComplianceManager_PrereaderBannerRole',
        PrereaderBannerGuest = 'ComplianceManager_PrereaderBannerGuest',
        PrereaderBannerIntro = 'ComplianceManager_PrereaderBannerIntro',
        PrereaderBannerPermission = 'ComplianceManager_PrereaderBannerPermission',
        Here = 'ComplianceManager_Here',
        GroupNameTooltip = 'ComplianceManager_GroupNameTooltip',
        ProductTooltip = 'ComplianceManager_ProductTooltip',
        AssessmentTooltip = 'ComplianceManager_AssessmentTooltip',
        AssessedControlsTooltip = 'ComplianceManager_AssessedControlsTooltip',
        AssessedPercentTooltip = 'ComplianceManager_AssessedPercentTooltip',
        StatusTooltip = 'ComplianceManager_StatusTooltip',
        LastModifiedTooltip = 'ComplianceManager_LastModifiedTooltip',
        OverallScoreTooltip = 'ComplianceManager_OverallScoreTooltip',
        ControlArticleTooltip = 'ComplianceManager_ControlArticleTooltip',
        ComplianceScoreTooltip = 'ComplianceManager_ComplianceScoreTooltip',
        RelatedControlsTooltip = 'ComplianceManager_RelatedControlsTooltip',
        AssignedUserTooltip = 'ComplianceManager_AssignedUserTooltip',
        ManageDocumentsTooltip = 'ComplianceManager_ManageDocumentsTooltip',
        ImplementationStatusTooltip = 'ComplianceManager_ImplementationStatusTooltip',
        ImplementationDateTooltip = 'ComplianceManager_ImplementationDateTooltip',
        TestDateTooltip = 'ComplianceManager_TestDateTooltip',
        TestResultTooltip = 'ComplianceManager_TestResultTooltip',
        MoreLessTooltip = 'ComplianceManager_MoreLessTooltip',
    }
    export enum ComplianceManagerV2 {
        CustomerControlCertificate = 'ComplianceManagerV2_CustomerControlCertificate',
        CustomerControlRiskScore = 'ComplianceManagerV2_CustomerControlRiskScore',
        CustomerControlRelatedArticles = 'ComplianceManagerV2_CustomerControlRelatedArticles',
        CustomerControlRelatedArticlesTooltip = 'ComplianceManagerV2_CustomerControlRelatedArticlesTooltip',
        CustomerControlAssessmentUsers = 'ComplianceManagerV2_CustomerControlAssessmentUsers',
        CustomerControlImplementation = 'ComplianceManagerV2_CustomerControlImplementation',
        CustomerControlStatus = 'ComplianceManagerV2_CustomerControlStatus',
        CustomerControlDate = 'ComplianceManagerV2_CustomerControlDate',
        CustomerControlTestDate = 'ComplianceManagerV2_CustomerControlTestDate',
        CustomerControlResult = 'ComplianceManagerV2_CustomerControlResult',
        MSControlTitle = 'ComplianceManagerV2_MSControlTitle',
        MSControlStandardAndRegulation = 'ComplianceManagerV2_MSControlStandardAndRegulation',
        MSControlDescription = 'ComplianceManagerV2_MSControlDescription',
        MSControlStatus = 'ComplianceManagerV2_MSControlStatus',
        MSControlTestDate = 'ComplianceManagerV2_MSControlTestDate',
        MSControlTestResult = 'ComplianceManagerV2_MSControlTestResult',
        ControlDetailControlIDLabel = 'ComplianceManagerV2_ControlDetailControlIDLabel',
        ControlDetailControlTitleLabel = 'ComplianceManagerV2_ControlDetailControlTitleLabel',
        ControlDetailArticleIDLabel = 'ComplianceManagerV2_ControlDetailArticleIDLabel',
        ControlDetailDescriptionLabel = 'ComplianceManagerV2_ControlDetailDescriptionLabel',
        AddFrameworkEnterNewGroup = 'ComplianceManagerV2_AddFrameworkEnterNewGroup',
        AddFrameworkSelectGroup = 'ComplianceManagerV2_AddFrameworkSelectGroup',
        AddFrameworkCopyGroup = 'ComplianceManagerV2_AddFrameworkCopyGroup',
        AddFrameworkToggleYes = 'ComplianceManagerV2_AddFrameworkToggleYes',
        AddFrameworkToggleNo = 'ComplianceManagerV2_AddFrameworkToggleNo',
        AddFrameworkSelectProduct = 'ComplianceManagerV2_AddFrameworkSelectProduct',
        AddFrameworkSelectProductPlaceholder = 'ComplianceManagerV2_AddFrameworkSelectProductPlaceholder',
        AddFrameworkSelectCertification = 'ComplianceManagerV2_AddFrameworkSelectCertification',
        HelpHeading = 'ComplianceManagerV2_HelpHeading',
        HelpStepNextLabel = 'ComplianceManagerV2_HelpStepNextLabel',
        HelpStepPreviousLabel = 'ComplianceManagerV2_HelpStepPreviousLabel',
        HelpStepAddFrameworkLabel = 'ComplianceManagerV2_HelpStepAddFrameworkLabel',
        HelpStepDoneLabel = 'ComplianceManagerV2_HelpStepDoneLabel',
        FrameworkDetailCustomerControlsLabel = 'ComplianceManagerV2_FrameworkDetailCustomerControlsLabel',
        FrameworkDetailMicrosoftControlsLabel = 'ComplianceManagerV2_FrameworkDetailMicrosoftControlsLabel',
        ActionItemsCustomerActionsColumnHeaderLabel = 'ComplianceManagerV2_ActionItemsCustomerActionsColumnHeaderLabel',
        ImplementationDetails = 'ComplianceManagerV2_ImplementationDetails',
        ManagementResponse = 'ComplianceManagerV2_ManagementResponse',
        ComplianceScoreRingLabel = 'ComplianceManagerV2_ComplianceScoreRingLabel',
        ComplianceScoreRingText = 'ComplianceManagerV2_ComplianceScoreRingText',
    }
    export enum ComplianceManagerV3 {
        AssessmentScoreRingText = 'ComplianceManagerV3_AssessmentScoreRingText',
    }
    export enum ComplianceManagerV2 {
        FrameworkDetailAssessedLabel = 'ComplianceManagerV2_FrameworkDetailAssessedLabel',
        NotApplicable = 'ComplianceManagerV2_NotApplicable',
        ReturnToNewVersion = 'ComplianceManagerV2_ReturnToNewVersion',
        IsDataRefreshed = 'ComplianceManagerV2_IsDataRefreshed',
        ComplianceManagerFAQPage = 'ComplianceManagerV2_ComplianceManagerFAQPage',
        MicrosoftNoData = 'ComplianceManagerV2_MicrosoftNoData',
        CustomerNoData = 'ComplianceManagerV2_CustomerNoData',
        FrameworkDetailNotice = 'ComplianceManagerV2_FrameworkDetailNotice',
        AssessmentStatus = 'ComplianceManagerV2_AssessmentStatus',
        RedirectMessage = 'ComplianceManagerV2_RedirectMessage',
        HelpTitle1 = 'ComplianceManagerV2_HelpTitle1',
        HelpContent1Part1 = 'ComplianceManagerV2_HelpContent1Part1',
        HelpContent1Part2 = 'ComplianceManagerV2_HelpContent1Part2',
        HelpContent1Part3 = 'ComplianceManagerV2_HelpContent1Part3',
        HelpContent1Part4 = 'ComplianceManagerV2_HelpContent1Part4',
        HelpContent1Part5 = 'ComplianceManagerV2_HelpContent1Part5',
        HelpContent1Part6 = 'ComplianceManagerV2_HelpContent1Part6',
        HelpContent1Part7 = 'ComplianceManagerV2_HelpContent1Part7',
        HelpTitle2 = 'ComplianceManagerV2_HelpTitle2',
        HelpContent2 = 'ComplianceManagerV2_HelpContent2',
        HelpTitle3 = 'ComplianceManagerV2_HelpTitle3',
        HelpContent3Part1 = 'ComplianceManagerV2_HelpContent3Part1',
        HelpTitle3C = 'ComplianceManagerV2_HelpTitle3C',
        HelpContent3Part2 = 'ComplianceManagerV2_HelpContent3Part2',
        HelpContent3Part3 = 'ComplianceManagerV2_HelpContent3Part3',
        HelpContent3Part4 = 'ComplianceManagerV2_HelpContent3Part4',
        HelpTitle4 = 'ComplianceManagerV2_HelpTitle4',
        HelpContent4 = 'ComplianceManagerV2_HelpContent4',
        HelpTitle5 = 'ComplianceManagerV2_HelpTitle5',
        HelpContent5Part1 = 'ComplianceManagerV2_HelpContent5Part1',
        HelpContent5Part2 = 'ComplianceManagerV2_HelpContent5Part2',
        HelpContent5Part3 = 'ComplianceManagerV2_HelpContent5Part3',
        HelpContent5Part4 = 'ComplianceManagerV2_HelpContent5Part4',
        HelpContent5Part5 = 'ComplianceManagerV2_HelpContent5Part5',
        HelpTitle6 = 'ComplianceManagerV2_HelpTitle6',
        HelpContent6 = 'ComplianceManagerV2_HelpContent6',
        HelpTitle7 = 'ComplianceManagerV2_HelpTitle7',
        HelpContent7 = 'ComplianceManagerV2_HelpContent7',
        NIST800171Message = 'ComplianceManagerV2_NIST800171Message',
    }
    export enum LicenseAgreementPage {
        AgreeButtonLabel = 'LicenseAgreementPage_AgreeButtonLabel',
        CancelButtonLabel = 'LicenseAgreementPage_CancelButtonLabel',
        SignInSignUpDialogTitle = 'LicenseAgreementPage_SignInSignUpDialogTitle',
        SignInSignUpDialogSignInHeader = 'LicenseAgreementPage_SignInSignUpDialogSignInHeader',
        SignInSignUpDialogSignInParagraph = 'LicenseAgreementPage_SignInSignUpDialogSignInParagraph',
        SignInButtonAriaLabel = 'LicenseAgreementPage_SignInButtonAriaLabel',
        SignInButtonLabel = 'LicenseAgreementPage_SignInButtonLabel',
        SignInItarButtonAriaLabel = 'LicenseAgreementPage_SignInItarButtonAriaLabel',
        SignInItarButtonLabel = 'LicenseAgreementPage_SignInItarButtonLabel',
        SignInSignUpDialogSignUpHeader = 'LicenseAgreementPage_SignInSignUpDialogSignUpHeader',
        SignInSignUpDialogSignUpParagraph1 = 'LicenseAgreementPage_SignInSignUpDialogSignUpParagraph1',
        SignInSignUpDialogSignUpParagraph2 = 'LicenseAgreementPage_SignInSignUpDialogSignUpParagraph2',
        SignUpButtonAriaLabel = 'LicenseAgreementPage_SignUpButtonAriaLabel',
        SignUpButtonLabel = 'LicenseAgreementPage_SignUpButtonLabel',
    }
    export enum ServiceTrustClient {
        ApiErrorMessage = 'ServiceTrustClient_ApiErrorMessage',
        ServiceUnReachableMessage = 'ServiceTrustClient_ServiceUnReachableMessage',
        ResourceNotFound = 'ServiceTrustClient_ResourceNotFound',
    }
    export enum ComplianceManager {
        restrictedAccessTitle = 'ComplianceManager_restrictedAccessTitle',
        restrictedAccess = 'ComplianceManager_restrictedAccess',
    }
    export enum UHFHeader {
        GovUserReminder = 'UHFHeader_GovUserReminder',
    }
    export enum UHFFooter {
        PrivacyandcookiesLabel = 'UHFFooter_PrivacyandcookiesLabel',
        TermsofuseLabel = 'UHFFooter_TermsofuseLabel',
        TechnicalsupportLabel = 'UHFFooter_TechnicalsupportLabel',
        TrademarksLabel = 'UHFFooter_TrademarksLabel',
    }
    export enum PageFooter {
        ManageCookieConsent = 'PageFooter_ManageCookieConsent',
        FooterAriaLabel = 'PageFooter_FooterAriaLabel',
        AboutLabel = 'PageFooter_AboutLabel',
    }
    export enum AuthenticationSessionExpiring {
        Title = 'AuthenticationSessionExpiring_Title',
        ExpirationMessage = 'AuthenticationSessionExpiring_ExpirationMessage',
        InstructionMessage = 'AuthenticationSessionExpiring_InstructionMessage',
        ContunueButtonLabel = 'AuthenticationSessionExpiring_ContunueButtonLabel',
        LogOutButtonLabel = 'AuthenticationSessionExpiring_LogOutButtonLabel',
    }
    export enum AuthenticationSessionExpired {
        Title = 'AuthenticationSessionExpired_Title',
        ExpirationMessage = 'AuthenticationSessionExpired_ExpirationMessage',
        InstructionMessage = 'AuthenticationSessionExpired_InstructionMessage',
        CloseButtonLabel = 'AuthenticationSessionExpired_CloseButtonLabel',
    }
    export enum AuthenticationSessionEndOfLifeWarning {
        Title = 'AuthenticationSessionEndOfLifeWarning_Title',
        ExpirationMessage = 'AuthenticationSessionEndOfLifeWarning_ExpirationMessage',
        InstructionMessage = 'AuthenticationSessionEndOfLifeWarning_InstructionMessage',
        CloseButtonLabel = 'AuthenticationSessionEndOfLifeWarning_CloseButtonLabel',
        LogOutButtonLabel = 'AuthenticationSessionEndOfLifeWarning_LogOutButtonLabel',
    }
    export enum AuthenticationSessionEndOfLife {
        Title = 'AuthenticationSessionEndOfLife_Title',
        ExpirationMessage = 'AuthenticationSessionEndOfLife_ExpirationMessage',
        InstructionMessage = 'AuthenticationSessionEndOfLife_InstructionMessage',
        CloseButtonLabel = 'AuthenticationSessionEndOfLife_CloseButtonLabel',
    }
    export enum Spinner {
        LoadingLabel = 'Spinner_LoadingLabel',
        UpdatingLabel = 'Spinner_UpdatingLabel',
        InitializingLabel = 'Spinner_InitializingLabel',
        LoadingDocumentsLabel = 'Spinner_LoadingDocumentsLabel',
        LoadingDocumentsDownloadHistoryLabel = 'Spinner_LoadingDocumentsDownloadHistoryLabel',
        ValidatingSpreadSheet = 'Spinner_ValidatingSpreadSheet',
    }
    export enum Search {
        topButtonAriaLabel = 'Search_topButtonAriaLabel',
        topButtonTooltip = 'Search_topButtonTooltip',
        DropdownCollapsedText = 'Search_DropdownCollapsedText',
        ResultsSummaryMultiple = 'Search_ResultsSummaryMultiple',
        ResultsSummaryFilters = 'Search_ResultsSummaryFilters',
        ResultsSummaryFilterSelected = 'Search_ResultsSummaryFilterSelected',
        ResultsAllResult = 'Search_ResultsAllResult',
        ResultsSummaryOne = 'Search_ResultsSummaryOne',
        ResultsSummaryNone = 'Search_ResultsSummaryNone',
        ResultsPerPage = 'Search_ResultsPerPage',
        CurrentOnPageAriaLabel = 'Search_CurrentOnPageAriaLabel',
        PageNumberAriaLabel = 'Search_PageNumberAriaLabel',
        PreviousPage = 'Search_PreviousPage',
        NextPage = 'Search_NextPage',
        PreviousPageAriaLabel = 'Search_PreviousPageAriaLabel',
        NextPageAriaLabel = 'Search_NextPageAriaLabel',
        Placeholder = 'Search_Placeholder',
        EnterYourSearchAriaLabel = 'Search_EnterYourSearchAriaLabel',
        ClearFilter = 'Search_ClearFilter',
        FilterBy = 'Search_FilterBy',
        ControlID = 'Search_ControlID',
        ControlTitle = 'Search_ControlTitle',
        ControlDescription = 'Search_ControlDescription',
    }
    export enum LanguageSelectionPage {
        Title = 'LanguageSelectionPage_Title',
    }
    export enum ApplicationConfigurationPage {
        UndoChangesConfirmationTitle = 'ApplicationConfigurationPage_UndoChangesConfirmationTitle',
        UndoChangesConfirmationMessage = 'ApplicationConfigurationPage_UndoChangesConfirmationMessage',
        DeletePendingChangesConfirmationTitle = 'ApplicationConfigurationPage_DeletePendingChangesConfirmationTitle',
        DeletePendingChangesConfirmationMessage = 'ApplicationConfigurationPage_DeletePendingChangesConfirmationMessage',
        AddFlightButtonLabel = 'ApplicationConfigurationPage_AddFlightButtonLabel',
        FlightSettingsPanelTitle = 'ApplicationConfigurationPage_FlightSettingsPanelTitle',
    }
    export enum UserPrivacySettings {
        PageLoading = 'UserPrivacySettings_PageLoading',
        PageLoaded = 'UserPrivacySettings_PageLoaded',
        ResultLoaded = 'UserPrivacySettings_ResultLoaded',
        PageTitle = 'UserPrivacySettings_PageTitle',
        EnterEmailLabel = 'UserPrivacySettings_EnterEmailLabel',
        UserNotExist = 'UserPrivacySettings_UserNotExist',
        ExportReportLabel = 'UserPrivacySettings_ExportReportLabel',
        ExportReportDescription = 'UserPrivacySettings_ExportReportDescription',
        ReassignActionLabel = 'UserPrivacySettings_ReassignActionLabel',
        ReassignActionDescription = 'UserPrivacySettings_ReassignActionDescription',
        DeleteUserLabel = 'UserPrivacySettings_DeleteUserLabel',
        DeleteUserDescription = 'UserPrivacySettings_DeleteUserDescription',
        ReassignConfirmation = 'UserPrivacySettings_ReassignConfirmation',
        DeletionConfirmation = 'UserPrivacySettings_DeletionConfirmation',
        ActionSuccess = 'UserPrivacySettings_ActionSuccess',
        ActionFail = 'UserPrivacySettings_ActionFail',
        EnterUserEmailPlaceHolder = 'UserPrivacySettings_EnterUserEmailPlaceHolder',
        DomainPlaceHolder = 'UserPrivacySettings_DomainPlaceHolder',
        ClearButtonLabel = 'UserPrivacySettings_ClearButtonLabel',
        SearchButtonLabel = 'UserPrivacySettings_SearchButtonLabel',
        ExportButtonLabel = 'UserPrivacySettings_ExportButtonLabel',
        ReassginButtonLabel = 'UserPrivacySettings_ReassginButtonLabel',
        DeleteButtonLabel = 'UserPrivacySettings_DeleteButtonLabel',
        ComfirmActionTitle = 'UserPrivacySettings_ComfirmActionTitle',
        ConfirmButtonLabel = 'UserPrivacySettings_ConfirmButtonLabel',
        CancelButtonLabel = 'UserPrivacySettings_CancelButtonLabel',
        SupportPageBanner = 'UserPrivacySettings_SupportPageBanner',
        UserPrivacySettingsSupportPage = 'UserPrivacySettings_UserPrivacySettingsSupportPage',
        ComplianceManagerV2Label = 'UserPrivacySettings_ComplianceManagerV2Label',
        ComplianceManagerV3Label = 'UserPrivacySettings_ComplianceManagerV3Label',
        SuccessMessageTitle = 'UserPrivacySettings_SuccessMessageTitle',
        FailedMessageTitle = 'UserPrivacySettings_FailedMessageTitle',
    }
    export enum Utility {
        Date = 'Utility_Date',
    }
    export enum ComplianceManagerV2 {
        PleaseConsiderUsingV3Text = 'ComplianceManagerV2_PleaseConsiderUsingV3Text',
    }
    export enum ComplianceManagerV3 {
        ActionItemsDeleteFileError = 'ComplianceManagerV3_ActionItemsDeleteFileError',
        CustomerManagedControlsLabel = 'ComplianceManagerV3_CustomerManagedControlsLabel',
        MicrosoftManagedControlsLabel = 'ComplianceManagerV3_MicrosoftManagedControlsLabel',
        MaxComplianceScoreLabel = 'ComplianceManagerV3_MaxComplianceScoreLabel',
        TemplatesTabLabel = 'ComplianceManagerV3_TemplatesTabLabel',
        TenantManagementTabLabel = 'ComplianceManagerV3_TenantManagementTabLabel',
        AddTemplateLabel = 'ComplianceManagerV3_AddTemplateLabel',
        ExportAllActions = 'ComplianceManagerV3_ExportAllActions',
        ActionsBulkUpdateSuccess = 'ComplianceManagerV3_ActionsBulkUpdateSuccess',
        ArchivedTemplatesLabel = 'ComplianceManagerV3_ArchivedTemplatesLabel',
        HiddenTemplatesLabel = 'ComplianceManagerV3_HiddenTemplatesLabel',
        HiddenAssessmentsLabel = 'ComplianceManagerV3_HiddenAssessmentsLabel',
        ArchiveTemplatesLabel = 'ComplianceManagerV3_ArchiveTemplatesLabel',
        UnHiddenAssessmentsLabel = 'ComplianceManagerV3_UnHiddenAssessmentsLabel',
        NoHiddenAssessmentsLabel = 'ComplianceManagerV3_NoHiddenAssessmentsLabel',
        ArchiveAssessmentLabel = 'ComplianceManagerV3_ArchiveAssessmentLabel',
        HideTemplatesLabel = 'ComplianceManagerV3_HideTemplatesLabel',
        EditLabel = 'ComplianceManagerV3_EditLabel',
        DeleteLabel = 'ComplianceManagerV3_DeleteLabel',
        PublishLabel = 'ComplianceManagerV3_PublishLabel',
        RejectLabel = 'ComplianceManagerV3_RejectLabel',
        ApproveLabel = 'ComplianceManagerV3_ApproveLabel',
        ExportLabel = 'ComplianceManagerV3_ExportLabel',
        PublishTemplateLabel = 'ComplianceManagerV3_PublishTemplateLabel',
        RejectTemplateLabel = 'ComplianceManagerV3_RejectTemplateLabel',
        TemplateAlreadyApproved = 'ComplianceManagerV3_TemplateAlreadyApproved',
        TemplateAlreadyApprovedSubText = 'ComplianceManagerV3_TemplateAlreadyApprovedSubText',
        ApproveTemplateLabel = 'ComplianceManagerV3_ApproveTemplateLabel',
        PublishTemplateConfirmationLabel = 'ComplianceManagerV3_PublishTemplateConfirmationLabel',
        RejectTemplateConfirmationLabel = 'ComplianceManagerV3_RejectTemplateConfirmationLabel',
        ApproveTemplateConfirmationLabel = 'ComplianceManagerV3_ApproveTemplateConfirmationLabel',
        ApproveTemplateErrorMessage = 'ComplianceManagerV3_ApproveTemplateErrorMessage',
        AddTemplatePanelHeader = 'ComplianceManagerV3_AddTemplatePanelHeader',
        ImportTemplateInfo = 'ComplianceManagerV3_ImportTemplateInfo',
        ImportControlsInfo = 'ComplianceManagerV3_ImportControlsInfo',
        DimensionsLabel = 'ComplianceManagerV3_DimensionsLabel',
        BrowseButton = 'ComplianceManagerV3_BrowseButton',
        SampleTemplateText = 'ComplianceManagerV3_SampleTemplateText',
        HereLink = 'ComplianceManagerV3_HereLink',
        AddTemplateEmptyTextValidationError = 'ComplianceManagerV3_AddTemplateEmptyTextValidationError',
        AddTemplateLockedTextValidationError = 'ComplianceManagerV3_AddTemplateLockedTextValidationError',
        AddTemplateAlreadyExistsValidationError = 'ComplianceManagerV3_AddTemplateAlreadyExistsValidationError',
        AddAssessmentEmptyTextValidationError = 'ComplianceManagerV3_AddAssessmentEmptyTextValidationError',
        AddAssessmentSpecialCharsOnlyTextValidationError = 'ComplianceManagerV3_AddAssessmentSpecialCharsOnlyTextValidationError',
        AddAssessmentAlreadyExistsValidationError = 'ComplianceManagerV3_AddAssessmentAlreadyExistsValidationError',
        TemplateYetToComeMessage = 'ComplianceManagerV3_TemplateYetToComeMessage',
        AddGroupEmptyTextValidationError = 'ComplianceManagerV3_AddGroupEmptyTextValidationError',
        AddGroupAlreadyExistsValidationError = 'ComplianceManagerV3_AddGroupAlreadyExistsValidationError',
        DimensionSelectedOnce = 'ComplianceManagerV3_DimensionSelectedOnce',
        DimensionValueSelectedOnce = 'ComplianceManagerV3_DimensionValueSelectedOnce',
        SelectTemplateLabel = 'ComplianceManagerV3_SelectTemplateLabel',
        NewTemplateLabel = 'ComplianceManagerV3_NewTemplateLabel',
        AssessmentLabel = 'ComplianceManagerV3_AssessmentLabel',
        ExistingTemplateLabel = 'ComplianceManagerV3_ExistingTemplateLabel',
        ExtendTemplateExistingLabel = 'ComplianceManagerV3_ExtendTemplateExistingLabel',
        IsThisExistingTemplateLabel = 'ComplianceManagerV3_IsThisExistingTemplateLabel',
        FinishButtonLabel = 'ComplianceManagerV3_FinishButtonLabel',
        AddTemplateEnterNewTemplate = 'ComplianceManagerV3_AddTemplateEnterNewTemplate',
        AddTemplateSelectTemplate = 'ComplianceManagerV3_AddTemplateSelectTemplate',
        ImportTemplateValidControlsFound = 'ComplianceManagerV3_ImportTemplateValidControlsFound',
        ImportTemplateControlFamiliesFound = 'ComplianceManagerV3_ImportTemplateControlFamiliesFound',
        ImportTemplateActionsFound = 'ComplianceManagerV3_ImportTemplateActionsFound',
        ImportTemplateErrorsInFile = 'ComplianceManagerV3_ImportTemplateErrorsInFile',
        ImportInvalidTempleteFile = 'ComplianceManagerV3_ImportInvalidTempleteFile',
        MicrosoftActionDetailsMissing = 'ComplianceManagerV3_MicrosoftActionDetailsMissing',
        CancelButtonLabel = 'ComplianceManagerV3_CancelButtonLabel',
        AddToDashboardButtonLabel = 'ComplianceManagerV3_AddToDashboardButtonLabel',
        ControlsInfo = 'ComplianceManagerV3_ControlsInfo',
        AddCustomControl = 'ComplianceManagerV3_AddCustomControl',
        CustomControlImport = 'ComplianceManagerV3_CustomControlImport',
        ImportControls = 'ComplianceManagerV3_ImportControls',
        ImportControlFileRequiredError = 'ComplianceManagerV3_ImportControlFileRequiredError',
        CustomControlExport = 'ComplianceManagerV3_CustomControlExport',
        CustomControlFilter = 'ComplianceManagerV3_CustomControlFilter',
        ControlInfoPanelHeader = 'ComplianceManagerV3_ControlInfoPanelHeader',
        ControlInfoTitle = 'ComplianceManagerV3_ControlInfoTitle',
        ControlInfoTitlePlaceholder = 'ComplianceManagerV3_ControlInfoTitlePlaceholder',
        ControlID = 'ComplianceManagerV3_ControlID',
        ControlIDPlaceholder = 'ComplianceManagerV3_ControlIDPlaceholder',
        ControlInfoCategory = 'ComplianceManagerV3_ControlInfoCategory',
        ControlInfoCategoryValue = 'ComplianceManagerV3_ControlInfoCategoryValue',
        ControlFamily = 'ComplianceManagerV3_ControlFamily',
        ControlFamilyPlaceholder = 'ComplianceManagerV3_ControlFamilyPlaceholder',
        ControlInfoDescription = 'ComplianceManagerV3_ControlInfoDescription',
        ControlInfoDescriptionPlaceholder = 'ComplianceManagerV3_ControlInfoDescriptionPlaceholder',
        AssignCustomerAction = 'ComplianceManagerV3_AssignCustomerAction',
        AssignCustomerActionNotClicked = 'ComplianceManagerV3_AssignCustomerActionNotClicked',
        ControlInfoFilterArticleName = 'ComplianceManagerV3_ControlInfoFilterArticleName',
        ControlInfoFilterArticleNamePlaceholder = 'ComplianceManagerV3_ControlInfoFilterArticleNamePlaceholder',
        ControlInfoFilterControls = 'ComplianceManagerV3_ControlInfoFilterControls',
        ControlInfoFilterControlsPlaceholder = 'ComplianceManagerV3_ControlInfoFilterControlsPlaceholder',
        ControlInfoControlAdded = 'ComplianceManagerV3_ControlInfoControlAdded',
        ControlInfoControlUpdated = 'ComplianceManagerV3_ControlInfoControlUpdated',
        ControlInfoControlsAdded = 'ComplianceManagerV3_ControlInfoControlsAdded',
        ControlInfoControlsUpdated = 'ComplianceManagerV3_ControlInfoControlsUpdated',
        ControlInfoFilterAssignedUsers = 'ComplianceManagerV3_ControlInfoFilterAssignedUsers',
        ControlInfoFilterAssignedUsersPlaceholder = 'ComplianceManagerV3_ControlInfoFilterAssignedUsersPlaceholder',
        ControlInfoFilterStatus = 'ComplianceManagerV3_ControlInfoFilterStatus',
        ControlInfoFilterTestResult = 'ComplianceManagerV3_ControlInfoFilterTestResult',
        ControlInfoFlyoutCancelButton = 'ComplianceManagerV3_ControlInfoFlyoutCancelButton',
        ControlInfoFlyoutSaveButton = 'ComplianceManagerV3_ControlInfoFlyoutSaveButton',
        ControlInfoFlyoutDoneButton = 'ComplianceManagerV3_ControlInfoFlyoutDoneButton',
        AssignCustomerActionsFlyoutHeader = 'ComplianceManagerV3_AssignCustomerActionsFlyoutHeader',
        AffectedCustomerActionsFlyoutHeader = 'ComplianceManagerV3_AffectedCustomerActionsFlyoutHeader',
        AssignCustomerActionsFlyoutFilterDimension = 'ComplianceManagerV3_AssignCustomerActionsFlyoutFilterDimension',
        AssignCustomerActionsFlyoutFilterOwner = 'ComplianceManagerV3_AssignCustomerActionsFlyoutFilterOwner',
        OwnerSelectedOnce = 'ComplianceManagerV3_OwnerSelectedOnce',
        AssignCustomerActionsFlyoutActionType = 'ComplianceManagerV3_AssignCustomerActionsFlyoutActionType',
        AssignCustomerActionsFlyoutCustomerAction = 'ComplianceManagerV3_AssignCustomerActionsFlyoutCustomerAction',
        AssignCustomerActionsFlyoutMatchingActions = 'ComplianceManagerV3_AssignCustomerActionsFlyoutMatchingActions',
        AssignCustomerActionsFlyoutCustomerActionPlaceholder = 'ComplianceManagerV3_AssignCustomerActionsFlyoutCustomerActionPlaceholder',
        AssignCustomerActionsFlyoutAssignButton = 'ComplianceManagerV3_AssignCustomerActionsFlyoutAssignButton',
        AssignCustomerActionsMapButton = 'ComplianceManagerV3_AssignCustomerActionsMapButton',
        AssignCustomerActionsFlyoutDidNotFindText = 'ComplianceManagerV3_AssignCustomerActionsFlyoutDidNotFindText',
        AssignCustomerActionsFlyoutAddNewCustomerActionText = 'ComplianceManagerV3_AssignCustomerActionsFlyoutAddNewCustomerActionText',
        AssignCustomerActionsFlyoutOwnersLabel = 'ComplianceManagerV3_AssignCustomerActionsFlyoutOwnersLabel',
        AssignCustomerActionsFlyoutDescriptionsLabel = 'ComplianceManagerV3_AssignCustomerActionsFlyoutDescriptionsLabel',
        AssignCustomerActionsFlyoutPreview = 'ComplianceManagerV3_AssignCustomerActionsFlyoutPreview',
        MoreButtonLabel = 'ComplianceManagerV3_MoreButtonLabel',
        AssessmentHeading = 'ComplianceManagerV3_AssessmentHeading',
        TemplateHeading = 'ComplianceManagerV3_TemplateHeading',
        NewGroupHeading = 'ComplianceManagerV3_NewGroupHeading',
        SelectGroupName = 'ComplianceManagerV3_SelectGroupName',
        AssementTitle = 'ComplianceManagerV3_AssementTitle',
        AssementTitlePlaceHolder = 'ComplianceManagerV3_AssementTitlePlaceHolder',
        AssessmentFilterTitle = 'ComplianceManagerV3_AssessmentFilterTitle',
        SelectTemplate = 'ComplianceManagerV3_SelectTemplate',
        TemplateStatus = 'ComplianceManagerV3_TemplateStatus',
        AddFrameworkExistingGroup = 'ComplianceManagerV3_AddFrameworkExistingGroup',
        Cancel = 'ComplianceManagerV3_Cancel',
        Done = 'ComplianceManagerV3_Done',
        Save = 'ComplianceManagerV3_Save',
        Status = 'ComplianceManagerV3_Status',
        AddNewGropup = 'ComplianceManagerV3_AddNewGropup',
        AddFrameworkEnterNewGroup = 'ComplianceManagerV3_AddFrameworkEnterNewGroup',
        AddFrameworkSelectGroup = 'ComplianceManagerV3_AddFrameworkSelectGroup',
        AddFrameworkCopyGroup = 'ComplianceManagerV3_AddFrameworkCopyGroup',
        AddFrameworkSelectProductPlaceholder = 'ComplianceManagerV3_AddFrameworkSelectProductPlaceholder',
        AddFrameworkSelectCertification = 'ComplianceManagerV3_AddFrameworkSelectCertification',
        ShowMyCustomerActionLabel = 'ComplianceManagerV3_ShowMyCustomerActionLabel',
        ActionTitleLabel = 'ComplianceManagerV3_ActionTitleLabel',
        ActionTypeLabel = 'ComplianceManagerV3_ActionTypeLabel',
        ComplianceScoreLabel = 'ComplianceManagerV3_ComplianceScoreLabel',
        ActionOwnerLabel = 'ComplianceManagerV3_ActionOwnerLabel',
        ActionTestDateLabel = 'ComplianceManagerV3_ActionTestDateLabel',
        ActionTestResultLabel = 'ComplianceManagerV3_ActionTestResultLabel',
        ActionImplementationDateLabel = 'ComplianceManagerV3_ActionImplementationDateLabel',
        ActionImplementationStatusLabel = 'ComplianceManagerV3_ActionImplementationStatusLabel',
        AssignedToLabel = 'ComplianceManagerV3_AssignedToLabel',
        AssignUserLabel = 'ComplianceManagerV3_AssignUserLabel',
        ActionImpelementationNotesLabel = 'ComplianceManagerV3_ActionImpelementationNotesLabel',
        ActionTestPlanLabel = 'ComplianceManagerV3_ActionTestPlanLabel',
        ActionManagementResponseLabel = 'ComplianceManagerV3_ActionManagementResponseLabel',
        SelectGroupLabel = 'ComplianceManagerV3_SelectGroupLabel',
        SelectAssessmentLabel = 'ComplianceManagerV3_SelectAssessmentLabel',
        DimensionDetailsLabel = 'ComplianceManagerV3_DimensionDetailsLabel',
        ActionItemStatusLabel = 'ComplianceManagerV3_ActionItemStatusLabel',
        ActionItemModifiedByLabel = 'ComplianceManagerV3_ActionItemModifiedByLabel',
        ComplianceScoreRingText = 'ComplianceManagerV3_ComplianceScoreRingText',
        ShowLockedLabel = 'ComplianceManagerV3_ShowLockedLabel',
        ImplementationLabel = 'ComplianceManagerV3_ImplementationLabel',
        ActionResponseLabel = 'ComplianceManagerV3_ActionResponseLabel',
        ActionStatusLabel = 'ComplianceManagerV3_ActionStatusLabel',
        ActionTestDate = 'ComplianceManagerV3_ActionTestDate',
        ActionImplementationDate = 'ComplianceManagerV3_ActionImplementationDate',
        ActionSetLabel = 'ComplianceManagerV3_ActionSetLabel',
        NewLabel = 'ComplianceManagerV3_NewLabel',
        ExistingLabel = 'ComplianceManagerV3_ExistingLabel',
        validationGroupMessage = 'ComplianceManagerV3_validationGroupMessage',
        validationAssessmentMessage = 'ComplianceManagerV3_validationAssessmentMessage',
        validationTemplateMessage = 'ComplianceManagerV3_validationTemplateMessage',
        validationControlInfoTitleMessage = 'ComplianceManagerV3_validationControlInfoTitleMessage',
        validationControlInfoFamilyEmptyMessage = 'ComplianceManagerV3_validationControlInfoFamilyEmptyMessage',
        validationControlInfoFamilyExistsMessage = 'ComplianceManagerV3_validationControlInfoFamilyExistsMessage',
        validationControlInfoNameMessage = 'ComplianceManagerV3_validationControlInfoNameMessage',
        validationControlInfoDescMessage = 'ComplianceManagerV3_validationControlInfoDescMessage',
        validationCustomerActionsMessage = 'ComplianceManagerV3_validationCustomerActionsMessage',
        SelectOneDimension = 'ComplianceManagerV3_SelectOneDimension',
        BrowseTemplateValidation = 'ComplianceManagerV3_BrowseTemplateValidation',
        NoDimensionValueSelected = 'ComplianceManagerV3_NoDimensionValueSelected',
        RequiredDimensionsNotSelected = 'ComplianceManagerV3_RequiredDimensionsNotSelected',
        DimensionsValuesExists = 'ComplianceManagerV3_DimensionsValuesExists',
        ExistingTemplateNotSelected = 'ComplianceManagerV3_ExistingTemplateNotSelected',
        CustomerManagedActionsLabel = 'ComplianceManagerV3_CustomerManagedActionsLabel',
        defaultControlFamilyLabel = 'ComplianceManagerV3_defaultControlFamilyLabel',
        defaultControlLabel = 'ComplianceManagerV3_defaultControlLabel',
        YourAccessedControlsLabel = 'ComplianceManagerV3_YourAccessedControlsLabel',
        YourAccessedActionsLabel = 'ComplianceManagerV3_YourAccessedActionsLabel',
        MicrosoftAccessedControlsLabel = 'ComplianceManagerV3_MicrosoftAccessedControlsLabel',
        MicrosoftAccessedActionsLabel = 'ComplianceManagerV3_MicrosoftAccessedActionsLabel',
        InScopeServicesMessageLabel = 'ComplianceManagerV3_InScopeServicesMessageLabel',
        DimensionLookupLabel = 'ComplianceManagerV3_DimensionLookupLabel',
        ModifiedLabel = 'ComplianceManagerV3_ModifiedLabel',
        TemplateLabel = 'ComplianceManagerV3_TemplateLabel',
        AssessedActionsLabel = 'ComplianceManagerV3_AssessedActionsLabel',
        AssessedControlsLabel = 'ComplianceManagerV3_AssessedControlsLabel',
        YourActionsLabel = 'ComplianceManagerV3_YourActionsLabel',
        MicrosoftActionsLabel = 'ComplianceManagerV3_MicrosoftActionsLabel',
        AttachActionsLabel = 'ComplianceManagerV3_AttachActionsLabel',
        CustomerControlCertificateLabel = 'ComplianceManagerV3_CustomerControlCertificateLabel',
        ActionScoreLabel = 'ComplianceManagerV3_ActionScoreLabel',
        RelatedControlsOrArticlesLabel = 'ComplianceManagerV3_RelatedControlsOrArticlesLabel',
        NameLabel = 'ComplianceManagerV3_NameLabel',
        DescriptionLabel = 'ComplianceManagerV3_DescriptionLabel',
        EllipsisLabel = 'ComplianceManagerV3_EllipsisLabel',
        customerActionsOwnerLabel = 'ComplianceManagerV3_customerActionsOwnerLabel',
        customerActionsContinuousUpdateScoreLabel = 'ComplianceManagerV3_customerActionsContinuousUpdateScoreLabel',
        ArchiveLabel = 'ComplianceManagerV3_ArchiveLabel',
        CheckForUpdatesLabel = 'ComplianceManagerV3_CheckForUpdatesLabel',
        LockLabel = 'ComplianceManagerV3_LockLabel',
        UnlockLabel = 'ComplianceManagerV3_UnlockLabel',
        SortLabel = 'ComplianceManagerV3_SortLabel',
        ValueLabel = 'ComplianceManagerV3_ValueLabel',
        CommandLabel = 'ComplianceManagerV3_CommandLabel',
        DateFormat = 'ComplianceManagerV3_DateFormat',
        titleLabel = 'ComplianceManagerV3_titleLabel',
        CreatedDateHeaderLabel = 'ComplianceManagerV3_CreatedDateHeaderLabel',
        ModifiedDateHeaderLabel = 'ComplianceManagerV3_ModifiedDateHeaderLabel',
        AscendingLabel = 'ComplianceManagerV3_AscendingLabel',
        DescendingLabel = 'ComplianceManagerV3_DescendingLabel',
        KeyLabel = 'ComplianceManagerV3_KeyLabel',
        IsMultiSelectLabel = 'ComplianceManagerV3_IsMultiSelectLabel',
        MultiSelectYesLabel = 'ComplianceManagerV3_MultiSelectYesLabel',
        MultiSelectNoLabel = 'ComplianceManagerV3_MultiSelectNoLabel',
        DashboardFilterResultsNotFoundMessage = 'ComplianceManagerV3_DashboardFilterResultsNotFoundMessage',
        UnHideTemplatesMessage = 'ComplianceManagerV3_UnHideTemplatesMessage',
        NoHiddenTemplatesMessage = 'ComplianceManagerV3_NoHiddenTemplatesMessage',
        Accept = 'ComplianceManagerV3_Accept',
        Ignore = 'ComplianceManagerV3_Ignore',
        Reject = 'ComplianceManagerV3_Reject',
        SkipForNow = 'ComplianceManagerV3_SkipForNow',
        MSDPAssessmentDailogConfirmationContent = 'ComplianceManagerV3_MSDPAssessmentDailogConfirmationContent',
        AcceptMergeFromAssessmentNotice = 'ComplianceManagerV3_AcceptMergeFromAssessmentNotice',
        AcceptMergeFromTemplateNotice = 'ComplianceManagerV3_AcceptMergeFromTemplateNotice',
        RejectMergeNotice = 'ComplianceManagerV3_RejectMergeNotice',
        Rollback = 'ComplianceManagerV3_Rollback',
        CustomerActionTitleEmptyLabel = 'ComplianceManagerV3_CustomerActionTitleEmptyLabel',
        CustomerActionDescriptionEmptyLabel = 'ComplianceManagerV3_CustomerActionDescriptionEmptyLabel',
        AllLabel = 'ComplianceManagerV3_AllLabel',
        ImplementationDetailsForSecureScoreAction = 'ComplianceManagerV3_ImplementationDetailsForSecureScoreAction',
        TestDetailsForSecureScoreAction = 'ComplianceManagerV3_TestDetailsForSecureScoreAction',
        SelectImplementationStatusPlaceHolder = 'ComplianceManagerV3_SelectImplementationStatusPlaceHolder',
        RelatedArticleNotFound = 'ComplianceManagerV3_RelatedArticleNotFound',
        SecureScoreMappedActionMessage = 'ComplianceManagerV3_SecureScoreMappedActionMessage',
        ActionItemsPartiallyImplementedLabel = 'ComplianceManagerV3_ActionItemsPartiallyImplementedLabel',
        ActionItemsToBeDeterminedLabel = 'ComplianceManagerV3_ActionItemsToBeDeterminedLabel',
        ActionItemsCouldNotBeDeterminedLabel = 'ComplianceManagerV3_ActionItemsCouldNotBeDeterminedLabel',
        ActionItemsPartiallyTestedLabel = 'ComplianceManagerV3_ActionItemsPartiallyTestedLabel',
        FrameworkDetailToBeDeterminedLabel = 'ComplianceManagerV3_FrameworkDetailToBeDeterminedLabel',
        FrameworkDetailCouldNotBeDeterminedLabel = 'ComplianceManagerV3_FrameworkDetailCouldNotBeDeterminedLabel',
        ImportedApprovalStatusLabel = 'ComplianceManagerV3_ImportedApprovalStatusLabel',
        PendingApprovalStatusLabel = 'ComplianceManagerV3_PendingApprovalStatusLabel',
        ApprovedApprovalStatusLabel = 'ComplianceManagerV3_ApprovedApprovalStatusLabel',
        RejectedApprovalStatusLabel = 'ComplianceManagerV3_RejectedApprovalStatusLabel',
        ArchivedApprovalStatusLabel = 'ComplianceManagerV3_ArchivedApprovalStatusLabel',
        BackUpApprovalStatusLabel = 'ComplianceManagerV3_BackUpApprovalStatusLabel',
        RBACNoticeLabel = 'ComplianceManagerV3_RBACNoticeLabel',
        DimensionValueThresholdReached = 'ComplianceManagerV3_DimensionValueThresholdReached',
        AttachActionHelpfulMessage = 'ComplianceManagerV3_AttachActionHelpfulMessage',
        SelectActionDescriptionFlyoutHeader = 'ComplianceManagerV3_SelectActionDescriptionFlyoutHeader',
        AttachActionDescriptionHelpfulMessage = 'ComplianceManagerV3_AttachActionDescriptionHelpfulMessage',
        AssignCustomerActionsFlyoutSelectButton = 'ComplianceManagerV3_AssignCustomerActionsFlyoutSelectButton',
        AssignCustomerActionsFlyoutSelectedActions = 'ComplianceManagerV3_AssignCustomerActionsFlyoutSelectedActions',
        AssignCustomerActionsFlyoutShortDescriptionsLabel = 'ComplianceManagerV3_AssignCustomerActionsFlyoutShortDescriptionsLabel',
        NoAssessmentsFoundLabel = 'ComplianceManagerV3_NoAssessmentsFoundLabel',
        NoActionitemsFoundLabel = 'ComplianceManagerV3_NoActionitemsFoundLabel',
        NoTemplatesFoundLabel = 'ComplianceManagerV3_NoTemplatesFoundLabel',
        NoDimensionsFoundLabel = 'ComplianceManagerV3_NoDimensionsFoundLabel',
        NoCustomerActionsFoundLabel = 'ComplianceManagerV3_NoCustomerActionsFoundLabel',
        NoOwnersFoundLabel = 'ComplianceManagerV3_NoOwnersFoundLabel',
        NoControlFamiliesFoundLabel = 'ComplianceManagerV3_NoControlFamiliesFoundLabel',
    }
    export enum TenantManagement {
        RestrictedAccess = 'TenantManagement_RestrictedAccess',
        Header = 'TenantManagement_Header',
        DimensionLookup = 'TenantManagement_DimensionLookup',
        NoSearchResultsFound = 'TenantManagement_NoSearchResultsFound',
        DeleteDimensionDialog = 'TenantManagement_DeleteDimensionDialog',
        DeleteDimensionSubText = 'TenantManagement_DeleteDimensionSubText',
        DeleteDialogAria = 'TenantManagement_DeleteDialogAria',
        ResponsiblePartiesLookup = 'TenantManagement_ResponsiblePartiesLookup',
        CustomerActionOwnerUpdateError = 'TenantManagement_CustomerActionOwnerUpdateError',
        CustomerActionOwnerFieldEmpty = 'TenantManagement_CustomerActionOwnerFieldEmpty',
        CustomerActionsLookup = 'TenantManagement_CustomerActionsLookup',
        AddDimensionLabel = 'TenantManagement_AddDimensionLabel',
        AffectedActionLabel = 'TenantManagement_AffectedActionLabel',
        AddOwnerLabel = 'TenantManagement_AddOwnerLabel',
        AddCustomerActionLabel = 'TenantManagement_AddCustomerActionLabel',
        CustomerActionTitleLabel = 'TenantManagement_CustomerActionTitleLabel',
        CustomerActionTitlePlaceHolder = 'TenantManagement_CustomerActionTitlePlaceHolder',
        CustomerActionMaxComplianceScoreLabel = 'TenantManagement_CustomerActionMaxComplianceScoreLabel',
        CustomerActionMaxComplianceScorePlaceHolder = 'TenantManagement_CustomerActionMaxComplianceScorePlaceHolder',
        CustomerActionTypeLabel = 'TenantManagement_CustomerActionTypeLabel',
        CustomerActionDescriptionLabel = 'TenantManagement_CustomerActionDescriptionLabel',
        CustomerActionContinuousUpdateLabel = 'TenantManagement_CustomerActionContinuousUpdateLabel',
        CustomerActionTypeSelect = 'TenantManagement_CustomerActionTypeSelect',
        CustomerActionDescription = 'TenantManagement_CustomerActionDescription',
        CustomerActionEnterDescription = 'TenantManagement_CustomerActionEnterDescription',
        CustomerActionDescriptionTitle = 'TenantManagement_CustomerActionDescriptionTitle',
        CustomerActionEnterDescriptionTitle = 'TenantManagement_CustomerActionEnterDescriptionTitle',
        CustomerActionShowMaxCountReached = 'TenantManagement_CustomerActionShowMaxCountReached',
        CustomerActionshowSelectDifferentValueMessage = 'TenantManagement_CustomerActionshowSelectDifferentValueMessage',
        CustomerActionContinuosUpdateSecureScoreLabel = 'TenantManagement_CustomerActionContinuosUpdateSecureScoreLabel',
        CustomerActionTitleEmptyLabel = 'TenantManagement_CustomerActionTitleEmptyLabel',
        CustomerActionScoreEmptyLabel = 'TenantManagement_CustomerActionScoreEmptyLabel',
        OwnerNameEmpty = 'TenantManagement_OwnerNameEmpty',
        OwnerDescriptionEmpty = 'TenantManagement_OwnerDescriptionEmpty',
        CustomerActionTitleDescAlreadyAvailable = 'TenantManagement_CustomerActionTitleDescAlreadyAvailable',
        KeyLabel = 'TenantManagement_KeyLabel',
        ValueLabel = 'TenantManagement_ValueLabel',
        SaveLabel = 'TenantManagement_SaveLabel',
        CancelLabel = 'TenantManagement_CancelLabel',
        CloseLabel = 'TenantManagement_CloseLabel',
        MultiSelectionLabel = 'TenantManagement_MultiSelectionLabel',
        AllowMultiSelectionLabel = 'TenantManagement_AllowMultiSelectionLabel',
        NotAllowMultiSelectionLabel = 'TenantManagement_NotAllowMultiSelectionLabel',
        NameLabel = 'TenantManagement_NameLabel',
        DescriptionLabel = 'TenantManagement_DescriptionLabel',
        DeleteLabel = 'TenantManagement_DeleteLabel',
        DeleteOwnerDialog = 'TenantManagement_DeleteOwnerDialog',
        DeleteOwnerSubText = 'TenantManagement_DeleteOwnerSubText',
        MultiSelectToggleView = 'TenantManagement_MultiSelectToggleView',
        CustomerActionOwner = 'TenantManagement_CustomerActionOwner',
        CustomerActionMaxComplianceScore = 'TenantManagement_CustomerActionMaxComplianceScore',
        UniqueOwnerName = 'TenantManagement_UniqueOwnerName',
        KeyEmpty = 'TenantManagement_KeyEmpty',
        CustomerActionTiltle = 'TenantManagement_CustomerActionTiltle',
        KeyExists = 'TenantManagement_KeyExists',
        OwnerHeader = 'TenantManagement_OwnerHeader',
        DeleteDimensionDialogMessage = 'TenantManagement_DeleteDimensionDialogMessage',
        DeleteDimensionSubtext = 'TenantManagement_DeleteDimensionSubtext',
        DeleteCustomerActionSubtext = 'TenantManagement_DeleteCustomerActionSubtext',
        DimensionValueExists = 'TenantManagement_DimensionValueExists',
        DimensionValueEmpty = 'TenantManagement_DimensionValueEmpty',
        DimensionValueMapped = 'TenantManagement_DimensionValueMapped',
        DeleteOwnerDialogMessage = 'TenantManagement_DeleteOwnerDialogMessage',
        DeleteCustomerActionDialogMessage = 'TenantManagement_DeleteCustomerActionDialogMessage',
        CustomerActionDescriptionHeader = 'TenantManagement_CustomerActionDescriptionHeader',
        SecureScoreToggleOn = 'TenantManagement_SecureScoreToggleOn',
        SecureScoreToggleOff = 'TenantManagement_SecureScoreToggleOff',
        SecureScoreToggleUnavailable = 'TenantManagement_SecureScoreToggleUnavailable',
        SameCustomerActionLinkingValidationMessage = 'TenantManagement_SameCustomerActionLinkingValidationMessage',
        SelectAtleastOneActionValidationMessage = 'TenantManagement_SelectAtleastOneActionValidationMessage',
        SelectActionNotPartOfExistingActionsValidationMessage = 'TenantManagement_SelectActionNotPartOfExistingActionsValidationMessage',
        SearchBoxPlaceholder = 'TenantManagement_SearchBoxPlaceholder',
        SecureScoreHeaderLabel = 'TenantManagement_SecureScoreHeaderLabel',
    }
    export enum VocabularyManagement {
        FilteringTitleLabel = 'VocabularyManagement_FilteringTitleLabel',
        FilteringTitleLabelV2022 = 'VocabularyManagement_FilteringTitleLabelV2022',
        RestrictingTitleLabel = 'VocabularyManagement_RestrictingTitleLabel',
        NorestrictMessage = 'VocabularyManagement_NorestrictMessage',
        RestrictMessage = 'VocabularyManagement_RestrictMessage',
        NoneLabel = 'VocabularyManagement_NoneLabel',
        AllLabel = 'VocabularyManagement_AllLabel',
        AnyLabel = 'VocabularyManagement_AnyLabel',
    }
    export enum ComplianceManager {
        ArchiveTileConfirmationLabel = 'ComplianceManager_ArchiveTileConfirmationLabel',
        ArchiveTemplateConfirmationLabel = 'ComplianceManager_ArchiveTemplateConfirmationLabel',
        LockTemplateConfirmationLabel = 'ComplianceManager_LockTemplateConfirmationLabel',
        LockTemplatFrameworksLabel = 'ComplianceManager_LockTemplatFrameworksLabel',
        UnlockTemplateConfirmationLabel = 'ComplianceManager_UnlockTemplateConfirmationLabel',
        UnlockTemplatFrameworksLabel = 'ComplianceManager_UnlockTemplatFrameworksLabel',
        LockLabel = 'ComplianceManager_LockLabel',
        UnlockLabel = 'ComplianceManager_UnlockLabel',
        UnarchiveTileLabel = 'ComplianceManager_UnarchiveTileLabel',
        UnarchiveTemplateLabel = 'ComplianceManager_UnarchiveTemplateLabel',
        UnarchiveAssessmentConfirmationLabel = 'ComplianceManager_UnarchiveAssessmentConfirmationLabel',
        UnarchiveTemplateConfirmationLabel = 'ComplianceManager_UnarchiveTemplateConfirmationLabel',
        UnarchiveLabel = 'ComplianceManager_UnarchiveLabel',
    }
    export enum ComplianceManagerV3 {
        UnHideLabel = 'ComplianceManagerV3_UnHideLabel',
    }
    export enum TenantManagement {
        DeleteCustomerActionMappedDialog = 'TenantManagement_DeleteCustomerActionMappedDialog',
        DeleteCustomerActionMappedSubText = 'TenantManagement_DeleteCustomerActionMappedSubText',
    }
    export enum ComplianceManagerV3 {
        AssignedUserMandatoryLabel = 'ComplianceManagerV3_AssignedUserMandatoryLabel',
        AssignCustomeractionOwnerPlaceholder = 'ComplianceManagerV3_AssignCustomeractionOwnerPlaceholder',
        AddTemplateInvalidFileType = 'ComplianceManagerV3_AddTemplateInvalidFileType',
        AssignedUserErrorMessage = 'ComplianceManagerV3_AssignedUserErrorMessage',
        AssignUserBanner = 'ComplianceManagerV3_AssignUserBanner',
        BrowseSpreadSheetsLabel = 'ComplianceManagerV3_BrowseSpreadSheetsLabel',
        ComplainceManagerV3ToV2 = 'ComplianceManagerV3_ComplainceManagerV3ToV2',
        ComplianceDashboardPreview = 'ComplianceManagerV3_ComplianceDashboardPreview',
        CustomerActionAlreadyExist = 'ComplianceManagerV3_CustomerActionAlreadyExist',
        CustomerActionDescriptionRequired = 'ComplianceManagerV3_CustomerActionDescriptionRequired',
        CustomerActionDimensionsRequired = 'ComplianceManagerV3_CustomerActionDimensionsRequired',
        CustomerActionTypeRequired = 'ComplianceManagerV3_CustomerActionTypeRequired',
        CustomerActionDuplicate = 'ComplianceManagerV3_CustomerActionDuplicate',
        MicrosoftActionDuplicate = 'ComplianceManagerV3_MicrosoftActionDuplicate',
        CustomerActionDelete = 'ComplianceManagerV3_CustomerActionDelete',
        CustomerActionEdit = 'ComplianceManagerV3_CustomerActionEdit',
        CustomerActionAddDescription = 'ComplianceManagerV3_CustomerActionAddDescription',
        CustomerManagedControls = 'ComplianceManagerV3_CustomerManagedControls',
        ControlIDExists = 'ComplianceManagerV3_ControlIDExists',
        ControlIDErrorMessage = 'ComplianceManagerV3_ControlIDErrorMessage',
        ControlIDAlreadyExist = 'ComplianceManagerV3_ControlIDAlreadyExist',
        CloseErrorMessageLabel = 'ComplianceManagerV3_CloseErrorMessageLabel',
        DashboardAssessmentLabel = 'ComplianceManagerV3_DashboardAssessmentLabel',
        DashboardTemplateLabel = 'ComplianceManagerV3_DashboardTemplateLabel',
        DimensionName = 'ComplianceManagerV3_DimensionName',
        DoYouWantToContinue = 'ComplianceManagerV3_DoYouWantToContinue',
        DimensionHeaderDroupDown = 'ComplianceManagerV3_DimensionHeaderDroupDown',
        DimensionOptionAddedAnnouncement = 'ComplianceManagerV3_DimensionOptionAddedAnnouncement',
        DimensionOptionRemovedAnnouncement = 'ComplianceManagerV3_DimensionOptionRemovedAnnouncement',
        FilterNoChangeAnnouncement = 'ComplianceManagerV3_FilterNoChangeAnnouncement',
        FilterResultsAnnouncement = 'ComplianceManagerV3_FilterResultsAnnouncement',
        FilterOneResultAnnouncement = 'ComplianceManagerV3_FilterOneResultAnnouncement',
        FilterNoResultAnnouncement = 'ComplianceManagerV3_FilterNoResultAnnouncement',
        FlyoutCloseButton = 'ComplianceManagerV3_FlyoutCloseButton',
        FlyoutYesButton = 'ComplianceManagerV3_FlyoutYesButton',
        FlyoutNoButton = 'ComplianceManagerV3_FlyoutNoButton',
        FilterRemoveButton = 'ComplianceManagerV3_FilterRemoveButton',
        GroupLabel = 'ComplianceManagerV3_GroupLabel',
        GroupPreferredGroup = 'ComplianceManagerV3_GroupPreferredGroup',
        ImplementationDateGreaterThanTestDate = 'ComplianceManagerV3_ImplementationDateGreaterThanTestDate',
        ImplementationDateMandatoryText = 'ComplianceManagerV3_ImplementationDateMandatoryText',
        IsDataRefreshed = 'ComplianceManagerV3_IsDataRefreshed',
        MicrosoftManagedControls = 'ComplianceManagerV3_MicrosoftManagedControls',
        MicrosoftManagedActions = 'ComplianceManagerV3_MicrosoftManagedActions',
        CustomerManagedActions = 'ComplianceManagerV3_CustomerManagedActions',
        MicrosoftManagedActionsLabel = 'ComplianceManagerV3_MicrosoftManagedActionsLabel',
        NotInScopeNotice = 'ComplianceManagerV3_NotInScopeNotice',
        NoDataFoundForSelectedFilter = 'ComplianceManagerV3_NoDataFoundForSelectedFilter',
        NoPermissionOnSave = 'ComplianceManagerV3_NoPermissionOnSave',
        OwnerOptionAddedAnnouncement = 'ComplianceManagerV3_OwnerOptionAddedAnnouncement',
        OwnerOptionRemovedAnnouncement = 'ComplianceManagerV3_OwnerOptionRemovedAnnouncement',
        ReadMoreActionDescriptionLabel = 'ComplianceManagerV3_ReadMoreActionDescriptionLabel',
        RemoveDimension = 'ComplianceManagerV3_RemoveDimension',
        RedirectToCustomerActionsWarningText = 'ComplianceManagerV3_RedirectToCustomerActionsWarningText',
        RemoveNamedDimension = 'ComplianceManagerV3_RemoveNamedDimension',
        SecureScorePanelHeader = 'ComplianceManagerV3_SecureScorePanelHeader',
        SecureScoreNoDataFound = 'ComplianceManagerV3_SecureScoreNoDataFound',
        SelectAtleastAControl = 'ComplianceManagerV3_SelectAtleastAControl',
        SelectTestStatusPlaceHolder = 'ComplianceManagerV3_SelectTestStatusPlaceHolder',
        SecureScoreCustomerActionLabel = 'ComplianceManagerV3_SecureScoreCustomerActionLabel',
        SecureScoreCustomerActionDisabledMessage = 'ComplianceManagerV3_SecureScoreCustomerActionDisabledMessage',
        SecureScoreContinuousUpdateLabel = 'ComplianceManagerV3_SecureScoreContinuousUpdateLabel',
        SecureScoreIntegrationFAQLinkMessage = 'ComplianceManagerV3_SecureScoreIntegrationFAQLinkMessage',
        TemplateVersionChangesMessage = 'ComplianceManagerV3_TemplateVersionChangesMessage',
        ActionTemplateVersionChangesMessage = 'ComplianceManagerV3_ActionTemplateVersionChangesMessage',
        SecureScoreDeltaMessage = 'ComplianceManagerV3_SecureScoreDeltaMessage',
        SecureScoreExploreLinkMessage = 'ComplianceManagerV3_SecureScoreExploreLinkMessage',
        SecureScoreSynopsisMessage = 'ComplianceManagerV3_SecureScoreSynopsisMessage',
        SecureScoreEnabledGloballyMessage = 'ComplianceManagerV3_SecureScoreEnabledGloballyMessage',
        SecureScoreDisabledGloballyMessage = 'ComplianceManagerV3_SecureScoreDisabledGloballyMessage',
        AutomaticSSUpdateText = 'ComplianceManagerV3_AutomaticSSUpdateText',
        AutomaticSSUpdateIntroText = 'ComplianceManagerV3_AutomaticSSUpdateIntroText',
        EnabledForAllText = 'ComplianceManagerV3_EnabledForAllText',
        DisabledForAllText = 'ComplianceManagerV3_DisabledForAllText',
        SetAtActionLevelText = 'ComplianceManagerV3_SetAtActionLevelText',
        SecureScoreNoExplicitRbacMessage = 'ComplianceManagerV3_SecureScoreNoExplicitRbacMessage',
        SelectAssessmentFilterMessage = 'ComplianceManagerV3_SelectAssessmentFilterMessage',
        SortByAriaLabel = 'ComplianceManagerV3_SortByAriaLabel',
        SearchResult = 'ComplianceManagerV3_SearchResult',
        SearchResultsMultiple = 'ComplianceManagerV3_SearchResultsMultiple',
        SaveDimension = 'ComplianceManagerV3_SaveDimension',
        SuccessfulSaveAnnouncement = 'ComplianceManagerV3_SuccessfulSaveAnnouncement',
        ShowDimensionsLabel = 'ComplianceManagerV3_ShowDimensionsLabel',
        ProductDimensionsLabel = 'ComplianceManagerV3_ProductDimensionsLabel',
        CertificationDimensionsLabel = 'ComplianceManagerV3_CertificationDimensionsLabel',
        TestDateMandatoryText = 'ComplianceManagerV3_TestDateMandatoryText',
        ToggleListView = 'ComplianceManagerV3_ToggleListView',
        ToggleGridView = 'ComplianceManagerV3_ToggleGridView',
        TemplateLocked = 'ComplianceManagerV3_TemplateLocked',
        TemplateUnlocked = 'ComplianceManagerV3_TemplateUnlocked',
        TotalCustomerManagedControls = 'ComplianceManagerV3_TotalCustomerManagedControls',
        TotalMicrosoftManagedControls = 'ComplianceManagerV3_TotalMicrosoftManagedControls',
        TotalCustomerManagedActions = 'ComplianceManagerV3_TotalCustomerManagedActions',
        TotalMicrosoftManagedActions = 'ComplianceManagerV3_TotalMicrosoftManagedActions',
        ViewHistoryLabel = 'ComplianceManagerV3_ViewHistoryLabel',
        ValidFileExtensionUpload = 'ComplianceManagerV3_ValidFileExtensionUpload',
        UpdatesAvailableTempalteLabel = 'ComplianceManagerV3_UpdatesAvailableTempalteLabel',
        UpdatesAvailableAssessmentLabel = 'ComplianceManagerV3_UpdatesAvailableAssessmentLabel',
        ConfirmationContentTempalteLabel = 'ComplianceManagerV3_ConfirmationContentTempalteLabel',
        ConfirmationContentAssessmentLabel = 'ComplianceManagerV3_ConfirmationContentAssessmentLabel',
        VersioningDialogTitle = 'ComplianceManagerV3_VersioningDialogTitle',
        TemplateUpdates = 'ComplianceManagerV3_TemplateUpdates',
        AssessmentUpdates = 'ComplianceManagerV3_AssessmentUpdates',
        ActionItemsWhenUploaded = 'ComplianceManagerV3_ActionItemsWhenUploaded',
        ImportCannotBeDone = 'ComplianceManagerV3_ImportCannotBeDone',
        ImportCannotBeDoneSubText = 'ComplianceManagerV3_ImportCannotBeDoneSubText',
        FrameworkDetailCouldNotBeDeterminedDescription = 'ComplianceManagerV3_FrameworkDetailCouldNotBeDeterminedDescription',
        ActionTypesColumnSelect = 'ComplianceManagerV3_ActionTypesColumnSelect',
        ActionTypesNoneLabel = 'ComplianceManagerV3_ActionTypesNoneLabel',
        ActionTypesDocumentationLabel = 'ComplianceManagerV3_ActionTypesDocumentationLabel',
        ActionTypesOperationalLabel = 'ComplianceManagerV3_ActionTypesOperationalLabel',
        ActionTypesProceduralLabel = 'ComplianceManagerV3_ActionTypesProceduralLabel',
        ActionTypesTechnicalLabel = 'ComplianceManagerV3_ActionTypesTechnicalLabel',
        ActionTypesContractualLabel = 'ComplianceManagerV3_ActionTypesContractualLabel',
        ActionTypesManagementLabel = 'ComplianceManagerV3_ActionTypesManagementLabel',
        ActionTypesPrivacyLabel = 'ComplianceManagerV3_ActionTypesPrivacyLabel',
        ActionImplementationDateAndStatusLabel = 'ComplianceManagerV3_ActionImplementationDateAndStatusLabel',
        ActionTestDateAndResultLabel = 'ComplianceManagerV3_ActionTestDateAndResultLabel',
        UpdateAvailablePopupTitle = 'ComplianceManagerV3_UpdateAvailablePopupTitle',
        UpdateAvailablePopupSubTitle = 'ComplianceManagerV3_UpdateAvailablePopupSubTitle',
    }
    export enum ComplianceManager {
        SaveVocabularyPopupSubTitle = 'ComplianceManager_SaveVocabularyPopupSubTitle',
        V4NewFeatures = 'ComplianceManager_V4NewFeatures',
        ComplianceScoreFeatures = 'ComplianceManager_ComplianceScoreFeatures',
        VisitDocumentationPage = 'ComplianceManager_VisitDocumentationPage',
        ComplianceCenterPage = 'ComplianceManager_ComplianceCenterPage',
        LearnHowItWorksPage = 'ComplianceManager_LearnHowItWorksPage',
        SetPermissionsPage = 'ComplianceManager_SetPermissionsPage',
        SelectAdminRoleBanner = 'ComplianceManager_SelectAdminRoleBanner',
        ComplianceCenterPermissionsPage = 'ComplianceManager_ComplianceCenterPermissionsPage',
        ComplianceCenterPermissionLearnMore = 'ComplianceManager_ComplianceCenterPermissionLearnMore',
    }
    export enum SettingsUserRoles {
        AddUserSuccess = 'SettingsUserRoles_AddUserSuccess',
        RemoveUserSuccess = 'SettingsUserRoles_RemoveUserSuccess',
    }
    export enum SKAiChatBot {
        Prompt = 'SKAiChatBot_Prompt',
        Sources = 'SKAiChatBot_Sources',
    }
    export enum CopilotChatBot {
        ErrorLabel = 'CopilotChatBot_ErrorLabel',
        LoadingLabel = 'CopilotChatBot_LoadingLabel',
        ProvideFeedback = 'CopilotChatBot_ProvideFeedback',
        FeedbackSubmitted = 'CopilotChatBot_FeedbackSubmitted',
        AIDisclosure = 'CopilotChatBot_AIDisclosure',
    }
}