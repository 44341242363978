import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import TrialSubscriptionsPage from '../Pages/TrialSubscriptionsPage';
import ExportServiceLinksDirectory from '../Containers/ExportServiceLinksDirectory';
import ComplianceManagerRedirectPage from '../Pages/ComplianceManagerRedirectPage';
import { ErrorPage } from '../Pages/ErrorPage';
import { Authorize, TargetRedirection } from '../../Common/Components/Authorize';
import DynamicPage from '../Containers/DynamicPage';
import LanguageSelectionPage from '../Pages/LanguageSelectionPage';
import SearchResultPage from '../Pages/SearchResultPage';
import { AuthorizationScope, AuthorizationActions, AuthorizationInfo, FlightMembership } from '../../Common/Utilities/AuthorizationInfo';
import ExportDataRequest from '../Containers/ExportDataRequest';
import ExportedFiles from '../Containers/PrivacyPortal/ExportedDataRequestFiles';
import PrivacyManagerContainer from '../Containers/PrivacyPortal/PrivacyManagerContainer';
import * as Loadable from 'react-loadable';
import { default as LoadingMessage } from '../Pages/LoadingMessage';
import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { ScopeAuthorizationClientService } from '../../Common/Services/ScopeAuthorizationService';
import { AuthorizationScopes, ComplianceManagerConstants } from '../../Common/Models/ComplianceManagerConstants';
import { TenantSettings } from '../Models/Settings/TenantSettings';
import { store } from '../../Store';
import * as ScopePermissionsReducer from '../../App/Reducers/ScopePermissions';
import * as _ from 'lodash';
import { DocumentDetailPage } from '../Pages/Admin/PageInfrastructure/DocumentDetailPage';
import DocumentDownloadHistoryPage from '../Pages/DocumentDownloadHistoryPage';
import { ADTenantManagementPage } from '../Pages/Admin/ADTenantManagement/ADTenantManagementPage';
import SeriesManagementPage from '../Pages/Admin/SeriesManager/SeriesManagementPage';

interface RouteState {
    tenantSettings: TenantSettings
}

interface RouteProps {
    previousPaths: string[];
    searchKeyword: string;
    post_logout_redirect_uri: string;
}

// Compliance Manager dynamic imports:
const RiskAndCompliancePageV2 = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cm"   */ '../Pages/RiskAndCompliancePageV2'),
    loading: LoadingMessage
}
);

const ActionItemsPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cm"  */ '../Pages/ActionItemsPage'),
    loading: LoadingMessage
}
);

const ActionItemsPageV2 = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cm"  */ '../Pages/ActionItemsPageV2'),
    loading: LoadingMessage
}
);

const FrameworkDetailsPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cm"  */ '../Pages/FrameworkDetailPage'),
    loading: LoadingMessage
}
);

const FrameworkDetailPageV2 = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cm"  */ '../Pages/FrameworkDetailPageV2'),
    loading: LoadingMessage
}
);

// Admin pages dynamic imports
const ComplianceManagerDataRefreshPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cm"  */ '../Pages/Admin/ComplianceManagerDataRefresh/ComplianceManagerDataRefreshPage'),
    loading: LoadingMessage
}
);

const VocabulariesManagementPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/Vocabularies/VocabulariesManagementPage'),
    loading: LoadingMessage
}
);

const CustomerSettingsPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/CustomerSettings/CustomerSettingsPage'),
    loading: LoadingMessage
}
);

const TopNavigationManagementPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Containers/TopNavigationManagementPage'),
    loading: LoadingMessage
}
);

const BreachNotificationPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/Gdpr/BreachNotificationPage'),
    loading: LoadingMessage
}
);

const BreachNotificationHistoryPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/Gdpr/BreachNotificationHistoryPage'),
    loading: LoadingMessage
}
);

const PagesManagementPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Containers/PagesManagementPage'),
    loading: LoadingMessage
}
);

const ImageManagmentPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/ImageManager/ImageManagmentPage'),
    loading: LoadingMessage
}
);

const DocumentManagerPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/DocumentManager/DocumentManagerPage'),
    loading: LoadingMessage
}
);

const ApplicationConfigurationPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/ApplicationConfiguration/ApplicationConfigurationPage'),
    loading: LoadingMessage
}
);

const LinkManagerPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Pages/Admin/LinkManager/LinkManagerPage'),
    loading: LoadingMessage
}
);

const LibraryPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "adm"  */ '../Containers/LibraryPage'),
    loading: LoadingMessage
}
);

// CMv3 Pages Start

// Compliance Manager V3 dynamic imports:
const DashboardPageV3 = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/DashboardPageV3'),
    loading: LoadingMessage
});

const TemplatesPageV3 = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/TemplatesPageV3'),
    loading: LoadingMessage
});

const TenantManagementPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/TenantManagementPage'),
    loading: LoadingMessage
});

const ResponsiblePartiesPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/ResponsiblePartiesPage'),
    loading: LoadingMessage
});

const CustomerActionPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/CustomerActionPage'),
    loading: LoadingMessage
});

const ActionItemsPageV3 = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/ActionItemPageV3'),
    loading: LoadingMessage
});

const ControlsInfoAssessmentPage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/ControlsInfoAssessmentPage'),
    loading: LoadingMessage
});

const ControlsInfoTemplatePage = Loadable({
    loader: () => import(/* webpackMode: "lazy", webpackChunkName: "cmv3"   */ '../Pages/ComplianceManagerV3/ControlsInfoTemplatePage'),
    loading: LoadingMessage
});

// CMv3 Pages Ends here

export default class Routes extends React.Component<RouteProps, RouteState> {
    redirectPath: {};
    redirectUrl: string;
    pageRedirectMap: { [key: string]: string; };
    private isGlobalAdmin = AuthorizationInfo.isIncludedInRequiredFlights([ComplianceManagerConstants.GlobalTenantCMV3Flight], FlightMembership.Any);
    constructor(props: RouteProps) {
        super(props);
        this.state = {
            "tenantSettings": {} as TenantSettings
        };
        this.redirectUrl = store.getState().applicationContext.redirectUrl;
        this.redirectPath = store.getState().applicationContext.redirectPath;
        this.pageRedirectMap = store.getState().applicationContext.pageRedirectMapping;
    }

    private navigateToPreviousPath() {
        let previousPathProps = this.props.previousPaths;
        let previousPath = '/';
        if (previousPathProps && previousPathProps.length > 0) {
            if (previousPathProps[previousPathProps.length - 1] !== window.location.pathname) {
                previousPath = previousPathProps[previousPathProps.length - 1];
            } else if (previousPathProps.length > 1) {
                previousPath = previousPathProps[previousPathProps.length - 2];
            }
        }
        window.location.replace(previousPath);
    }

    componentWillMount() {
        // Check if V3 Flighting is enabled for user, if enabled dispatch all the scope permissions required for V3.
        if (_.includes(store.getState().applicationContext.userInfo.flights, ComplianceManagerConstants.ComplianceManagerV3Flights[0])) {
            store.dispatch(ScopePermissionsReducer.addScopePermission(ScopeAuthorizationClientService.getScopePermissionForComplianceManagerV3()));
        }
        if (store.getState().applicationContext.userInfo.anonymousId !== ComplianceManagerConstants.DefaultGuid) {
            this.setState({
                tenantSettings: { ...this.state.tenantSettings, secureScoreIntegrationLevel: store.getState().applicationContext.secureScoreIntegrationLevel }
            });
        }
    }

    render() {
        let complianceManagerRequiredPermissions = [
            {
                scope: '/ComplianceManager/Customer/Controls',
                rights: {
                    actions: ['read']
                }
            },
            {
                scope: '/ComplianceManager/Microsoft',
                rights: {
                    actions: ['read']
                }
            }
        ];

        let shouldRedirect = !this.isGlobalAdmin && this.redirectUrl && this.redirectPath;
        return (
            <Switch>
                <Route exact={true} path="/" render={({ match }) => <DynamicPage pageUrl="HomePageVNext" isInEditMode={false} />} />
                <Route path="/Documents/TrustDocuments" render={({ match }) => <DynamicPage pageUrl="TrustDocuments" isInEditMode={false} />} />
                <Route path="/Documents/ComplianceReports" render={({ match }) => <DynamicPage pageUrl="MSComplianceGuide" isInEditMode={false} />} />
                <Route path="/TrialSubscriptions" component={TrialSubscriptionsPage} />
                <Route
                    path="/ComplianceManager"
                    exact={true}
                    render={() => {
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                                message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                                requiredPermissions={complianceManagerRequiredPermissions}
                                checkAny={true}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                            >
                                <RiskAndCompliancePageV2 />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path="/ComplianceManager/preview"
                    component={() => {
                        return (
                            <ComplianceManagerRedirectPage />
                        );
                    }}
                />
                {/* CMv3 Routes starts from here */}
                <Route
                    path={ComplianceManagerConstants.Routes.Dashboard}
                    exact={true}
                    component={() => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.Dashboard]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.Dashboard]; }
                            return null;
                        }                
                        // TBD:requiredPermissions need to updated based on V3 requirements
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                                message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.ComplianceManagerV3)}
                                checkAny={true}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                {AuthorizationInfo.isIncludedInRequiredFlights([ComplianceManagerConstants.GlobalTenantCMV3Flight], FlightMembership.Any) && store.getState().applicationContext.isCMV4Enabled ?  <TemplatesPageV3 /> : <DashboardPageV3 />}

                            </Authorize>
                        );
                    }}
                />
                <Route
                    path={ComplianceManagerConstants.Routes.Templates}
                    exact={true}
                    render={({ match }) => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.Templates]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.Templates]; }
                            return null;
                        }                
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.TenantManagement)}
                            checkAny={true}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                            requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                        >
                            <TemplatesPageV3 />
                        </Authorize>);
                    }}
                />
                <Route
                    path={ComplianceManagerConstants.Routes.ActionItems}
                    exact={true}
                    render={({ match }) => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ImprovementActions]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ImprovementActions]; }
                            return null;
                        }        
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.ComplianceManagerV3)}
                            checkAny={true}
                            isMainPage={true}
                            requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                        >
                            <ActionItemsPageV3
                            />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/ComplianceManager/V3/ActionItems/:groupId/:assessmentId"
                    exact={true}
                    render={({ match }) => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ImprovementActions]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ImprovementActions]; }
                            return null;
                        }        
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.ComplianceManagerV3)}
                            checkAny={true}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                            requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                        >
                            <ActionItemsPageV3 groupId={match.params.groupId} assessmentId={match.params.assessmentId} />
                        </Authorize>);
                    }}
                />
                <Route
                    path={ComplianceManagerConstants.Routes.TenantManagement}
                    exact={true}
                    render={({ match }) => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.TenantManagement]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.TenantManagement]; }
                            return null;
                        }                
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message={Intl.Get(LocIds.TenantManagement.RestrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.TenantManagement)}
                                checkAny={true}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                <TenantManagementPage />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path={ComplianceManagerConstants.Routes.ResponsibleParties}
                    exact={true}
                    component={() => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.TenantManagement]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.TenantManagement]; }
                            return null;
                        }                
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message={Intl.Get(LocIds.TenantManagement.RestrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.TenantManagement)}
                                checkAny={true}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                <ResponsiblePartiesPage />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path={ComplianceManagerConstants.Routes.CustomerActions}
                    exact={true}
                    component={() => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.TenantManagement]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.TenantManagement]; }
                            return null;
                        }                
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message={Intl.Get(LocIds.TenantManagement.RestrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.TenantManagement)}
                                checkAny={true}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                <CustomerActionPage />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path={ComplianceManagerConstants.Routes.ControlsInfoAssessment}
                    exact={true}
                    component={() => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoAssessment]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoAssessment]; }
                            return null;
                        }                
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message={Intl.Get(LocIds.TenantManagement.RestrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.ComplianceManagerV3)}
                                checkAny={true}
                                isMainPage={true}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                <ControlsInfoAssessmentPage />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path="/ComplianceManager/V3/ControlsInfo/Assessment/:groupId/:assessmentId"
                    render={({ match }) => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoAssessmentId]) {
                            {
                                var redirectHref = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoAssessmentId];
                                redirectHref = redirectHref.replace('{assessmentId}', match.params.assessmentId);
                                window.location.href = redirectHref; 
                            }
                            return null;
                        }                
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message={Intl.Get(LocIds.TenantManagement.RestrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.ComplianceManagerV3)}
                                checkAny={true}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                <ControlsInfoAssessmentPage groupId={match.params.groupId} assessmentId={match.params.assessmentId} />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path={ComplianceManagerConstants.Routes.ControlsInfoTemplate}
                    exact={true}
                    component={() => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoTemplate]) {
                            {window.location.href = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoTemplate]; }
                            return null;
                        }                
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message={Intl.Get(LocIds.TenantManagement.RestrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.TenantManagement)}
                                checkAny={true}
                                isMainPage={true}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                <ControlsInfoTemplatePage />
                            </Authorize>
                        );
                    }}
                />

                <Route
                    path="/ComplianceManager/V3/ControlsInfo/Template/:templateId"
                    render={({ match }) => {
                        if (shouldRedirect && this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoTemplateId]) {
                            {
                                var redirectHref = this.redirectUrl + this.redirectPath[ComplianceManagerConstants.RedirectRoutes.ControlsInfoTemplateId];
                                redirectHref = redirectHref.replace('{templateId}', match.params.templateId);
                                window.location.href = redirectHref; 
                            }
                            return null;
                        }
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message={Intl.Get(LocIds.TenantManagement.RestrictedAccess)}
                                requiredPermissions={ScopeAuthorizationClientService.getScopesForAuthorization(AuthorizationScopes.TenantManagement)}
                                checkAny={true}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                                requiredFlights={ComplianceManagerConstants.ComplianceManagerV3Flights}
                            >
                                <ControlsInfoTemplatePage templateId={match.params.templateId} />
                            </Authorize>
                        );
                    }}
                />

                {/* CMV3 Routes Ends Here */}

                <Route
                    path="/ActionItems/:controlFamilyId?"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ActionItemsPage controlFamilyId={match.params.controlFamilyId} />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/ActionItemsV2/:groupId?/:controlId?"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            requiredFlights={['ComplianceManagerV2']}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ActionItemsPageV2 controlId={match.params.controlId} groupId={match.params.groupId} />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/FrameworkDetail/:tileId/:controlFamilyId?/:controlId?/:docId?/:fileName?"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <FrameworkDetailsPage
                                tileId={match.params.tileId}
                                fileName={match.params.fileName}
                                docId={match.params.docId}
                                controlFamilyId={match.params.controlFamilyId}
                                controlId={match.params.controlId}
                            />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/FrameworkDetails/:cloudServiceName/:frameworkName/:controlFamilyId/:controlId?"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <FrameworkDetailsPage
                                cloudServiceName={match.params.cloudServiceName}
                                frameworkName={match.params.frameworkName}
                                controlFamilyId={match.params.controlFamilyId}
                                controlId={match.params.controlId}
                            />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/FrameworkDetailV2/:tileId/:controlFamilyId?/:controlId?/:docId?/:fileName?"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            requiredFlights={['ComplianceManagerV2']}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <FrameworkDetailPageV2
                                tileId={match.params.tileId}
                                fileName={match.params.fileName}
                                docId={match.params.docId}
                                controlFamilyId={match.params.controlFamilyId}
                                controlId={match.params.controlId}
                            />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/FrameworkDetailSearchV2/:tileId/:controlFamilyId?/:controlId?/:docId?/:fileName?"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            requiredFlights={['ComplianceManagerV2']}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <FrameworkDetailPageV2
                                tileId={match.params.tileId}
                                fileName={match.params.fileName}
                                docId={match.params.docId}
                                controlFamilyId={match.params.controlFamilyId}
                                controlId={match.params.controlId}
                                isSearch={true}
                            />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/FrameworkDetailsV2/:tileId/:articleName?"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            requiredFlights={['ComplianceManagerV2']}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <FrameworkDetailPageV2
                                tileId={match.params.tileId}
                                articleName={match.params.articleName}
                            />
                        </Authorize>);
                    }}
                />
                <Route
                    path="/GDPR/FrameworkDetailV2/:cloudServiceId/:certificationId/:groupId/:controlFamilyId/:controlId"
                    render={({ match }) => {
                        return (<Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            title={Intl.Get(LocIds.ComplianceManager.restrictedAccessTitle)}
                            message={Intl.Get(LocIds.ComplianceManager.restrictedAccess)}
                            requiredPermissions={complianceManagerRequiredPermissions}
                            checkAny={true}
                            isMainPage={true}
                            requiredFlights={['ComplianceManagerV2']}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <FrameworkDetailPageV2
                                groupId={match.params.groupId}
                                controlFamilyId={match.params.controlFamilyId}
                                controlId={match.params.controlId}
                            />
                        </Authorize>);
                    }}
                />

                <Route
                    path="/Library"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <LibraryPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/privacy/ExportService"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message={Intl.Get(LocIds.GDPRCompliance.NoPermissionErrorMessage)}
                            requiredPermissions={[
                                {
                                    scope: '/GDPR/PortalPrivacyManager',
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            requiredFlights={['PrivacyPortalFlight']}
                            checkAny={true}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ExportServiceLinksDirectory />
                        </Authorize>
                    }
                />
                <Route
                    exact={true}
                    path="/privacy/DataLogExport"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message={Intl.Get(LocIds.GDPRCompliance.NoPermissionErrorMessage)}
                            requiredPermissions={[
                                {
                                    scope: '/GDPR/PortalPrivacyManager',
                                    rights: {
                                        actions: [AuthorizationActions.Read]
                                    }
                                }
                            ]}
                            requiredFlights={['PrivacyPortalFlight']}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <PrivacyManagerContainer />
                        </Authorize>
                    }
                />
                <Route
                    exact={true}
                    path="/privacy/ExportDataRequest"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message={Intl.Get(LocIds.GDPRCompliance.NoPermissionErrorMessage)}
                            requiredPermissions={[
                                {
                                    scope: '/GDPR/PortalPrivacyManager',
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            requiredFlights={['PrivacyPortalFlight']}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ExportDataRequest />
                        </Authorize>
                    }
                />
                <Route
                    path="/privacy/ExportedFiles/:exportId"
                    render={({ match }) =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message={Intl.Get(LocIds.GDPRCompliance.NoPermissionErrorMessage)}
                            requiredPermissions={[
                                {
                                    scope: '/GDPR/PortalPrivacyManager',
                                    rights: {
                                        actions: [AuthorizationActions.Read]
                                    }
                                }
                            ]}
                            requiredFlights={['PrivacyPortalFlight']}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ExportedFiles exportId={match.params.exportId} />
                        </Authorize>}
                />
                <Route
                    path="/AdminPage/Vocabularies"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Vocabularies,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <VocabulariesManagementPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/CustomerSettings"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.CustomerSettings,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <CustomerSettingsPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/GDPR/BreachNotification/History/:incidentId"
                    render={({ match }) =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: '/GDPR/BreachNotification/History',
                                    rights: {
                                        actions: [AuthorizationActions.Read]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <BreachNotificationHistoryPage
                                incidentId={match.params.incidentId}
                                exitUrl={'/AdminPage/GDPR/BreachNotification'}
                            />
                        </Authorize>}
                />
                <Route
                    path="/AdminPage/GDPR/BreachNotification"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: '/GDPR/BreachNotification',
                                    rights: {
                                        actions: [AuthorizationActions.Read]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <BreachNotificationPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/ApplicationConfiguration"
                    render={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.ApplicationConfiguration,
                                    rights: {
                                        actions: [AuthorizationActions.Read]
                                    }
                                }
                            ]}
                            requiredFlights={['ApplicationConfiguration']}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ApplicationConfigurationPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/TopNavigationManagement"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: '/TopNavigationSettings',
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <TopNavigationManagementPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/ImageManager"
                    component={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Images,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ImageManagmentPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/Documents"
                    render={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Documents,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <DocumentManagerPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/GDPR/LinkManager"
                    render={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Links,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <LinkManagerPage />
                        </Authorize>
                    }
                />
                 <Route
                    path="/AdminPage/Series"
                    render={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Series,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <SeriesManagementPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/Pages/All"
                    render={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Pages,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <PagesManagementPage />
                        </Authorize>
                    }
                />
                <Route
                    path="/AdminPage/EditPage/:pageId?"
                    render={({ match }) =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Pages,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <DynamicPage isInEditMode={true} pageId={match.params.pageId} />
                        </Authorize>}
                />
                <Route
                    path="/AdminPage/ViewPublicPage/:pageId/"
                    render={({ match }) =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.Pages,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <DynamicPage isInEditMode={false} pageId={match.params.pageId} />
                        </Authorize>}
                />
                <Route
                    path="/AdminPage/TenantManagement"
                    render={() =>
                        <Authorize
                            redirectTarget={TargetRedirection.ErrorPage}
                            message="This view does not exist!!"
                            requiredPermissions={[
                                {
                                    scope: AuthorizationScope.ADTenantManagement,
                                    rights: {
                                        actions: [AuthorizationActions.Modify]
                                    }
                                }
                            ]}
                            isMainPage={true}
                            callBack={() => this.navigateToPreviousPath()}
                        >
                            <ADTenantManagementPage/>
                        </Authorize>
                    }
                />
                <Route
                    path="/LanguageSelection"
                    component={() => {
                        return (
                            <Authorize requiredFlights={['Localization']} >
                                <LanguageSelectionPage />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path="/Search"
                    component={() => {
                        return (
                            <Authorize requiredFlights={['OneSearch']} >
                                <SearchResultPage keyword={this.props.searchKeyword} />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path="/ComplianceManagerDataRefresh"
                    component={() => {
                        return (
                            <Authorize
                                redirectTarget={TargetRedirection.ErrorPage}
                                message="This view does not exist!!"
                                requiredPermissions={[
                                    {
                                        scope: AuthorizationScope.ComplianceManagerControls,
                                        rights: {
                                            actions: [AuthorizationActions.Modify]
                                        }
                                    }
                                ]}
                                isMainPage={true}
                                callBack={() => this.navigateToPreviousPath()}
                            >
                                <ComplianceManagerDataRefreshPage />
                            </Authorize>
                        );
                    }}
                />
                <Route
                    path="/organizations/oauth2/v2.0/logout"
                    render={(match) => {
                        var redirectUrl = this.props.post_logout_redirect_uri ? decodeURIComponent(this.props.post_logout_redirect_uri) : window.location.origin;
                        window.location.href = redirectUrl;
                        return <div />;
                    }}
                />
                <Route
                    path="/DocumentPage/:documentId"
                    render={({ match }) =>
                        <DocumentDetailPage
                            documentId={match.params.documentId}
                        />}
                />
                <Route
                    path="/DocumentDownloadHistoryPage"
                    render={({ match }) =>
                        <DocumentDownloadHistoryPage/>}
                />
                <Route
                    path="/ViewPage/:pageurl"
                    render={({ match }) => {
                        if (this.pageRedirectMap && this.pageRedirectMap[match.params.pageurl.toLowerCase()]) {
                            if (window.location.search.includes('command=Download&downloadType=Document&downloadId=')) {
                                var regexPattern = '\\?command=Download&downloadType=Document&downloadId=([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})';
                                var regexMatch = window.location.search.match(regexPattern);
                                if (regexMatch != null && regexMatch.length == 2) {
                                    return <Redirect
                                        to={{
                                            pathname: "/DocumentPage/" + regexMatch[1]
                                        }}
                                    />
                                }
                            }
                            return <Redirect
                                to={{
                                pathname: "/ViewPage/" + this.pageRedirectMap[match.params.pageurl.toLowerCase()],
                                }}
                            />
                        }  
                        return <DynamicPage
                            isInEditMode={false}
                            pageUrl={match.params.pageurl}
                        />
                    }}
                />
                <Route path="/Error/:message" render={({ match }) => <ErrorPage message={match.params.message} />} />
                <Route path="*" component={ErrorPage} />
            </Switch>
        );
    }
}