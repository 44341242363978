import { ComplianceDashboard, ComplianceDashboardTile, DashBoardType, TileStatus } from '../../App/Models/ComplianceManagerV3';
import { IKeyValuePair } from '../../App/Models/IKeyValuePair';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';

// Declared constanst ,so it can be used in other methods within service to update/delete/add
// tslint:disable-next-line:no-var-requires
export let MockComplianceDashboard: ComplianceDashboard = require('../Json/ComplianceDashboard.json');

export class MockComplianceDashboardV3Service {

    // TBD - Add other crud operations based on the need
    getDashBoardTiles(params: IKeyValuePair[]): ComplianceDashboardTile[] {
        var anonymousTenantId = MockComplianceDashboard.anonymousTenantId;
        var filteredList = MockComplianceDashboard.dashboard.filter(e => e.dashboardType === DashBoardType.Assessment);
        var MockComplianceDashboardFiltered = { anonymousTenantId: anonymousTenantId, dashboard: filteredList };
        return MockComplianceDashboardFiltered.dashboard;
    }

    getTemplateTiles(params: IKeyValuePair[]): ComplianceDashboardTile[] {
        var anonymousTenantId = MockComplianceDashboard.anonymousTenantId;
        var filteredList = MockComplianceDashboard.dashboard.filter(e => e.dashboardType === DashBoardType.Template);
        var MockComplianceDashboardFiltered = { anonymousTenantId: anonymousTenantId, dashboard: filteredList };
        return MockComplianceDashboardFiltered.dashboard;
    }

    postAssessment(): ComplianceDashboardTile {
        return {
            anonymousTenantId: MockComplianceDashboard.anonymousTenantId,
            id: '7e559972-900a-4044-a27c-ac5767128d9c',
            parentTemplateName: 'TemplateName2',
            dashBoardType: 0,
            tileName: 'GDPR',
            groupId: '7e559972-900a-4044-a27c-ac5767128d9c',
            groupName: 'Default Group',
            dimensionIds: [],
            dimensions: [
                {
                    id: '35235345',
                    key: 'Product',
                    value: 'office365',
                    isMultiselectionAllowed: false,
                    isGlobalDimension: false
                },
                {
                    id: '35235345',
                    key: 'Certification',
                    value: 'ISO 27018:2014',
                    isMuisMultiselectionAllowedltiSelect: false,
                    isGlobalDimension: false
                },
                {
                    id: '35235345',
                    key: 'Certification',
                    value: 'GDPR',
                    isMultiselectionAllowed: false,
                    isGlobalDimension: false
                },
                {
                    id: '35235345',
                    key: 'Environment',
                    value: 'MT',
                    isMultiselectionAllowed: false,
                    isGlobalDimension: false
                },
                {
                    id: '35235345',
                    key: 'Environment',
                    value: 'GT',
                    isMultiselectionAllowed: false,
                    isGlobalDimension: false
                }
            ],
            whenCreated: new Date(),
            whoCreated: 'Timothy Affleck',
            whenLastUpdated: new Date(),
            customerControlsCount: 65,
            completedCustomerControlsCount: 0,
            microsoftControlsCount: 49,
            completedMicrosoftControlsCount: 49,
            failedControls: 0,
            microsoftComplianceScore: 264.0,
            microsoftComplianceScoreAchieved: 264.0,
            microsoftComplianceScoreFailed: 0.0,
            customerComplianceScore: 362.0,
            customerComplianceScoreAchieved: 0.0,
            customerComplianceScoreFailed: 0.0,
            isArchived: false,
            isLocked: false,
            isFiltered: false,
            status: TileStatus['Non Compliant'],
            dashboardType: DashBoardType.Assessment,
            customerControls: 0,
            microsoftControls: 0,
            completedCustomerControls: 0,
            completedMicrosoftControls: 0,
            templateId: '',
            categories: [],
            templateTitle: '',
            assessmentId: '',
            inScopeServices: [],
        } as ComplianceDashboardTile;        
    }

    getEmptyDropdownGroups(): IDropdownOption[] {
        let dropDownGroups: IDropdownOption[] = [];
        return dropDownGroups;
    }

    getLoadedDropdownGroups(): IDropdownOption[] {
        let dropDownGroups: IDropdownOption[] = [];
        let dropdownOption: IDropdownOption = { key: "Header", text: "Select Group" };
        dropDownGroups.push(dropdownOption);
        return dropDownGroups;
    }
}


export let MockComplianceDashboardV3ClientService = new MockComplianceDashboardV3Service();