import { Reducer } from 'redux';
import { TemplateFilterV3, getDefault } from '../../Models/ComplianceManagerV3/TemplateFilterV3';
export const UPDATE_TEMPLATE_FILTER_V3 = 'UPDATE_TEMPLATE_FILTER_V3';

export interface FilterAction {
    type: typeof UPDATE_TEMPLATE_FILTER_V3;   
    data: TemplateFilterV3; 
}

export function getUpdateTemplateFilterInStoreAction(data: TemplateFilterV3): FilterAction  {
    return {
        type: UPDATE_TEMPLATE_FILTER_V3,
        data: data,
    };
}

export function getClearTemplateFilterInStoreAction(data: TemplateFilterV3): FilterAction  {
    return getUpdateTemplateFilterInStoreAction(data);
}

export const reducer: Reducer<TemplateFilterV3, FilterAction> = (state: TemplateFilterV3, action: FilterAction): TemplateFilterV3 => {
    switch (action.type) {
        case UPDATE_TEMPLATE_FILTER_V3:
            return action.data;
        default:
            return state || getDefault();
    }    
};