import { Reducer } from 'redux';
import { SeriesDefinition } from '../Models/Series/SeriesDefinition';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type Status = 'Init' | 'Requesting' | 'Finished' | 'Success' | 'Error';

export interface SeriesManagementState {
    status: Status;
    series: SeriesDefinition[];
    onlyNoDocuments: boolean
}

// Define the strings that will be used when dispatching the actions that affect this model.
export const SERIES_FETCHING = 'SERIES_FETCHING';
export type SERIES_FETCHING = typeof SERIES_FETCHING;

export const SERIES_FETCHED = 'SERIES_FETCHED';
export type SERIES_FETCHED = typeof SERIES_FETCHED;

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface SeriesFetching {
    type: SERIES_FETCHING;
}

interface SeriesFetched {
    type: SERIES_FETCHED;
    data: SeriesDefinition[];
}

interface SeriesFetchingError {
    type: 'SERIES_FETCHING_ERROR';
    // tslint:disable-next-line:no-any
    data: any;
}

export type SeriesManagementActions = SeriesFetching | SeriesFetched | SeriesFetchingError;

export function fetchSeries(): SeriesManagementActions {
    return {
        type: SERIES_FETCHING
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: SeriesManagementState = {
    status: 'Init',
    series: [],
    onlyNoDocuments: false
};

export const reducer: Reducer<SeriesManagementState> = (state: SeriesManagementState, action: SeriesManagementActions) => {
    switch (action.type) {
        case 'SERIES_FETCHING':
            return {
                ...state,
                status: 'Requesting'
            };
        case 'SERIES_FETCHED':
            return {
                ...state,
                status: 'Finished',
                series: action.data
            };
       
        case 'SERIES_FETCHING_ERROR':
            return {
                ...state,
                status: 'Error'
            };
        default:
        return state || unloadedState;
    }    
};