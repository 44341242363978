import { Reducer } from 'redux';
import { EnvironmentSettings } from '../Models';
import { getWindowLocationHostname } from '../../Common/Utilities/BrowserGlobals';

// STATE - This defines the type of data maintained in the Redux store.

export type Status = 'Init' | 'Loading' | 'Loaded' | 'Error' | 'Update';

export interface EnvironmentSettingsState {
    // Values that come from the web API.
    environmentName: string;
    webApiEndpointUrl: string;
    imageLibraryCdnUrl: string;
    useMockServices: boolean;
    // Other values.
    status: Status;
    axiosRequestTimeoutInMilliSeconds: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface EnvironmentSettingsLoading {
    type: 'ENVIRONMENT_SETTINGS_LOADING';
}

interface EnvironmentSettingsLoaded {
    type: 'ENVIRONMENT_SETTINGS_LOADED';
    data?: EnvironmentSettings;
}

interface EnvironmentSettingsError {
    type: 'ENVIRONMENT_SETTINGS_ERROR';
}

interface EnvironmentSettingsUpdate {
    type: 'ENVIRONMENT_SETTINGS_UPDATED';
    data?: EnvironmentSettingsState;
}

export type EnvironmentSettingsActions = EnvironmentSettingsLoading | EnvironmentSettingsLoaded | EnvironmentSettingsError | EnvironmentSettingsUpdate;

export function getEnviromentSettingsLoadingAction(): EnvironmentSettingsActions {
    return {
        type: 'ENVIRONMENT_SETTINGS_LOADING',
    };
}

export function getEnviromentSettingChangedAction(environmentSettingsData: EnvironmentSettings | undefined): EnvironmentSettingsActions {
    return {
        type: 'ENVIRONMENT_SETTINGS_LOADED',
        data: environmentSettingsData,
    };
}

export function getEnviromentSettingUpdateAction(environmentSettingsData: EnvironmentSettingsState | undefined): EnvironmentSettingsActions {
    return {
        type: 'ENVIRONMENT_SETTINGS_UPDATED',
        data: environmentSettingsData,
    };
}
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<EnvironmentSettingsState> = (state: EnvironmentSettingsState, action: EnvironmentSettingsActions) => {
    let newState: EnvironmentSettingsState;

    switch (action.type) {
        case 'ENVIRONMENT_SETTINGS_LOADING':
            newState = {
                ...state,
                environmentName: '',
                webApiEndpointUrl: '',
                imageLibraryCdnUrl: '',
                useMockServices: false,
                status: 'Loading',
                axiosRequestTimeoutInMilliSeconds: 120000 // setting default to 120 seconds.
            };
            break;
        case 'ENVIRONMENT_SETTINGS_LOADED':
            var webApiEndPointUrl = '';

            if (action.data) {
                webApiEndPointUrl = action.data.isUseDefaultWebApiEndPointUrl 
                    ? action.data.defaultWebApiEndpointUrl 
                    : (action.data.webApiEndPointUrls[getWindowLocationHostname()] 
                        ? action.data.webApiEndPointUrls[getWindowLocationHostname()]
                        : action.data.defaultWebApiEndpointUrl);
            }

            newState = (action.data === undefined) 
            ? { ...state }
            : {
                ...state,
                environmentName: action.data.environmentName,
                webApiEndpointUrl: webApiEndPointUrl,
                imageLibraryCdnUrl: action.data.imageLibraryCdnOptions.url[getWindowLocationHostname()] ? 
                    action.data.imageLibraryCdnOptions.url[getWindowLocationHostname()] : 
                    webApiEndPointUrl, 
                useMockServices: action.data.useMockServices,               
                status: 'Loaded',
                axiosRequestTimeoutInMilliSeconds: action.data.axiosRequestTimeoutInMilliSeconds
            };
            break;
        case 'ENVIRONMENT_SETTINGS_UPDATED':
            newState = (action.data === undefined) 
            ? { ...state } 
            : {
                ...state,
                useMockServices: action.data.useMockServices
            };
            break;
        case 'ENVIRONMENT_SETTINGS_ERROR':
        default:
            newState = (state === undefined) 
            ? {
                environmentName: '',
                webApiEndpointUrl: '',
                imageLibraryCdnUrl: '',
                useMockServices: false,
                status: 'Init',
                axiosRequestTimeoutInMilliSeconds: 120000 // setting default to 120 seconds.
            }
            : state;
    }
    return newState;
};