import { StoreState } from '../../Store';
import { connect } from 'react-redux';
import { PageLayoutModel, PageLayoutEvents, default as PageLayoutNext } from '../Pages/PageLayoutNext';
import * as NotificationSummary from '../Reducers/NotificationSummary';
import { AnyAction, Dispatch } from 'redux';
import { ErrorResponse } from '../Services/Models';
import { PageLayoutProps } from '../Pages/PageLayoutProps';
import "@ms-mwf/moray/dist/css/main.min.css";

function mapStateToProps(state: StoreState, props: PageLayoutProps): PageLayoutModel {
    return {
        isNextGenUIEnabled: props.isNextGenUIEnabled === true,
        environmentSettingsStatus: state.environmentSettings.status,
        applicationContextStatus: state.applicationContext.status,
        localizedStringsStatus: state.localizedStrings.status,
        localizedStringsCulture: state.localizedStrings.cultureName,
        applicationContextCulture: state.applicationContext.cultureName,
        applicationContextAppInsightId: state.applicationContext.appInsightsId,
        complianceMgrShowCondition: state.applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement &&
                                state.authentication.isSignedIn,        
        isSignedIn: state.authentication.isSignedIn,
        isAnalyticsCookiesConsented: state.cookieConsent.Analytics === true
    };
}

export function mapDispatchToProps(dispatch: Dispatch<AnyAction>): PageLayoutEvents {
    return {
        requestNotificationSummary: () => { dispatch(NotificationSummary.requestNotificationSummary()); },
        receivedNotificationSummary: (totalDocumentsUpdated?: number, totalDocumentsSubscribed?: number) => { 
            dispatch(NotificationSummary.receivedNotificationSummary(totalDocumentsUpdated || 0 , totalDocumentsSubscribed || 0)); 
        },
        errorNotificationSummary: (error: ErrorResponse) => { dispatch(NotificationSummary.errorNotificationSummary(error)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLayoutNext);
