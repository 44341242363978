import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { ContentPlacementPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme, CallToActionTheme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import {
    deleteOnePanelFromCollection, moveSelectedColumn, getContentPlacementColumn,
    getThemeClassName, getSimpleThemeOptions,
    getCallToActionThemeClassName, getSimpleCallToActionThemeOptions
} from '../../Utilities/PageInfrastructure/PanelsHelper';
import { ComboBox, IComboBox, IComboBoxOption, Toggle } from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { ContentPlacementColumnPropertiesData } from '../../Models/PageInfrastructure/PanelPropertiesData';
import { Link } from 'react-router-dom';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import Constants from '../../Utilities/Constants';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import * as ReactMarkdown from 'react-markdown';
import { ExternalNavigationService } from '../../Services/ExternalNavigationService';

export class ContentPlacementPanel extends BasePanel<ContentPlacementPanelPropertiesData> {
    private static MAX_COLUMN = 4;
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private PrimaryCallToActionThemeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private SecondaryCallToActionThemeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.SecondaryCallToActionThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private calltoActionThemes: string[] = ['Primary', 'Secondary'];
    private externalNavigation = new ExternalNavigationService();

    constructor(props: BasicPanelProperties<ContentPlacementPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));
        this.PrimaryCallToActionThemeOptions = this.PrimaryCallToActionThemeOptions.concat(getSimpleCallToActionThemeOptions());
        this.SecondaryCallToActionThemeOptions = this.SecondaryCallToActionThemeOptions.concat(getSimpleCallToActionThemeOptions());
        if (!this.props.panel.panelInfo.columns) {
            this.props.panel.panelInfo.columns = [];
        }
    }

    // Render the section info, fixed the original <a> inside <a> issue
    // make external link open in new window, internal link open without refreshing the whole page
    renderSectionContent(data: ContentPlacementColumnPropertiesData) {
        return (
            <div>
                {data.imageId ?
                    <picture className="c-image">
                        <img
                            className="lazyloaded"
                            src={imageHelper.getImageUrl(data.imageId)}
                            data-src={imageHelper.getImageUrl(data.imageId)}
                            alt={getLocalizedString(data.heading)}
                        />
                    </picture>
                    : null}
                    {getLocalizedString(data.badge) ?
                        <strong style={{margin: '7px 0px'}} className="badge bg-yellow">{getLocalizedString(data.badge)}</strong>
                        : null}
                    {this.state.showEditor ?
                        <h4 style={{fontWeight: 700}}>{getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h4>
                        : (getLocalizedString(data.heading) || '').trim() ? <h4 style={{fontWeight: 700}}>{getLocalizedString(data.heading)}</h4>
                            : null}
                    {this.state.showEditor ?
                        <p>{getLocalizedString(data.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)} </p>
                        : (getLocalizedString(data.subHeading) || '').trim() ? <p>{getLocalizedString(data.subHeading)} </p>
                            : null}
                        {(getLocalizedString(data.callToActionLabel) || '').trim() ? /^https?:\/\//.test(data.callToActionUrl ? data.callToActionUrl : '') ?
                            <a
                                href={data.callToActionUrl}
                                className={`cta ${getCallToActionThemeClassName(CallToActionTheme[data.callToActionTheme || 'Simple'])}`}
                                style={{ 'textTransform': 'uppercase' }}
                                target="_blank"
                                aria-label={Intl.Get(LocIds.PageAndPanels.ContentPlacementLinkAriaLabel, {
                                    linkTitle: getLocalizedString(data.callToActionLabel),
                                    sectionTitle: getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)
                                })}
                            >
                                {getLocalizedString(data.callToActionLabel)}
                            </a> :
                            <Link
                                to={data.callToActionUrl ? data.callToActionUrl : ''}
                                className={`c-call-to-action c-glyph ${getCallToActionThemeClassName(CallToActionTheme[data.callToActionTheme || 'Simple'])}`}
                                style={{ 'textTransform': 'uppercase' }}
                                aria-label={Intl.Get(LocIds.PageAndPanels.ContentPlacementLinkAriaLabel, {
                                    linkTitle: getLocalizedString(data.callToActionLabel),
                                    sectionTitle: getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)
                                })}
                                title={getLocalizedString(data.callToActionLabel)}
                            >
                            </Link>
                            : null
                        }
                        {(getLocalizedString(data.secondaryCallToActionLabel) || '').trim() ? /^https?:\/\//.test(data.secondaryCallToActionUrl ? data.secondaryCallToActionUrl : '') ?
                            <a
                                href={data.secondaryCallToActionUrl}
                                className={`c-call-to-action c-glyph ${getCallToActionThemeClassName(CallToActionTheme[data.secondaryCallToActionTheme || 'Simple'])}`}
                                style={{ 'textTransform': 'uppercase' }}
                                target="_blank"
                                aria-label={getLocalizedString(data.secondaryCallToActionLabel)}
                            >
                                <span aria-hidden={true}>{getLocalizedString(data.secondaryCallToActionLabel)}</span>
                            </a> :
                            <Link
                                to={data.secondaryCallToActionUrl ? data.secondaryCallToActionUrl : ''}
                                className={`c-call-to-action c-glyph ${getCallToActionThemeClassName(CallToActionTheme[data.secondaryCallToActionTheme || 'Simple'])}`}
                                style={{ 'textTransform': 'uppercase' }}
                                aria-label={getLocalizedString(data.secondaryCallToActionLabel)}
                            >
                                <span aria-hidden={true}>{getLocalizedString(data.secondaryCallToActionLabel)}</span>
                            </Link>
                            : null
                        }                
            </div>
        );
    }

    getActualPanel() {
        return (
            <div
                className={`row ${getThemeClassName(Theme[this.state.panelData.theme || 'None'])}`}
                data-panel-type="Content Placement"
            >
                {getLocalizedString(this.state.panelData.heading) &&
                    <h3 className="c-heading-3" style={{ paddingBottom: '24px', textAlign: 'center' }} >{getLocalizedString(this.state.panelData.heading)}</h3>
                }
                {this.state.panelData.columns ?
                    this.state.panelData.columns.map((data, index) =>
                        <div
                            key={data.toString().concat(index.toString())}
                            className="col"
                            onClick={() => { if (this.state.showEditor && this.panelValidator.validate()) { this.setState({ selectedIndex: index }); } }}
                            style={this.state.selectedIndex === index && this.state.showEditor ? { border: 'solid 1px #778899', backgroundColor: this.state.selectedIndex === index ? '#f4f4f4' : '' } : {}}
                        >
                            <div className="p-5" data-mount="click-group">
                                {this.renderSectionContent(data)}
                            </div>
                        </div>
                    )
                    :
                    null
                }
            </div>
        );
    }

    onAddColumn() {
        if (!this.state.panelData.columns || (this.state.panelData.columns.length >= ContentPlacementPanel.MAX_COLUMN || !this.panelValidator.validate())) {
            return;
        }
        this.state.panelData.columns.push(getContentPlacementColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    onDeleteColumn() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            var panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    onMoveLeft() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    onMoveRight() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: 0 }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? (this.state.panelData.columns[this.state.selectedIndex].imageTitle || '') : ''}
                                                disabled={true}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={images => {
                                                    if (images && images.length && this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                        this.state.panelData.columns[this.state.selectedIndex].imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextField
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].badge) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'badge', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.BadgeLabel)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                    }
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length <= Constants.PANEL_TITLE_MAX) {
                                                            setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal);
                                                        }
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].subHeading) : ''}
                                                onChange={(newVal) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'subHeading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                required={false}
                                                validator={this.panelValidator}
                                                selectedIndex={this.state.selectedIndex}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'callToActionLabel', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? this.state.panelData.columns[this.state.selectedIndex].callToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionUrlLabel)}
                                                isPage={true}
                                                url={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                selectedKey={this.state.panelData.columns && this.state.panelData.columns[this.state.selectedIndex].callToActionTheme || 'Simple'}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.PrimaryCallToActionThemeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionTheme = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].secondaryCallToActionLabel) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'secondaryCallToActionLabel', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.SecondaryCallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? this.state.panelData.columns[this.state.selectedIndex].secondaryCallToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].secondaryCallToActionUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.SecondaryCallToActionUrlLabel)}
                                                isPage={true}
                                                url={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                selectedKey={this.state.panelData.columns && this.state.panelData.columns[this.state.selectedIndex].secondaryCallToActionTheme || 'Simple'}
                                                label={Intl.Get(LocIds.PageAndPanels.SecondaryCallToActionThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SecondaryCallToActionThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.SecondaryCallToActionThemeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].secondaryCallToActionTheme = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextField
                                                value={this.state.panelData.heading ? getLocalizedString(this.state.panelData.heading) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelHeadingLabel)}
                                            />
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                            <Toggle
                                                checked={this.state.panelData.isVertical || false}
                                                label={Intl.Get(LocIds.PageAndPanels.LayoutLabel)}
                                                onAriaLabel={Intl.Get(LocIds.PageAndPanels.VerticalLabel)}
                                                offAriaLabel={Intl.Get(LocIds.PageAndPanels.HorizontalLabel)}
                                                onText={Intl.Get(LocIds.PageAndPanels.VerticalLabel)}
                                                offText={Intl.Get(LocIds.PageAndPanels.HorizontalLabel)}
                                                onChanged={(value) => {
                                                    this.state.panelData.isVertical = value;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddColumnLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length >= ContentPlacementPanel.MAX_COLUMN)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeleteColumnLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}