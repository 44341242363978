import * as React from 'react';
import { Unsubscribe } from 'redux';
import { store } from '../../Store';
import { PageMetadata } from '../Models/PageInfrastructure';
import { ComboBox, IComboBox, IComboBoxOption, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { pageServicesV2Client } from '../Services/PageServicesV2';
import { savePages } from '../Reducers/PagesManagement';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';

interface PagesDropdownListBoxProps {
    defaultSelectedKey?: string;
    label?: string;
    ariaLabel?: string;
    allowFreeform?: boolean;
    autoComplete?: string;
    onChange: (option?: IComboBoxOption, index?: number, value?: string) => void;
}

interface DropDownListBoxState {
    pages: PageMetadata[];
}

// Define the component itself.
export class PagesDropdownListBox extends React.Component<PagesDropdownListBoxProps, DropDownListBoxState> {
    private pageOptions: IComboBoxOption[] = [];
    private storeUnsubscribe: Unsubscribe;

    constructor(props: PagesDropdownListBoxProps) {
        super(props);
        this.state = {
            pages: []
        };
    }

    componentDidMount() {
        this.storeUnsubscribe = store.subscribe(() => {
            if (store.getState().pagesManagement.status === 'Finished') {
                this.setState({ pages: store.getState().pagesManagement.pagesMetadata });
            }
        });
        if (store.getState().pagesManagement.status === 'Init') {
            pageServicesV2Client.getPublicPageMetadataList(
                r => {
                    let data = r.data as PageMetadata[];
                    store.dispatch(savePages(data));
                },
                () => { return; });
        } else if (store.getState().pagesManagement.status === 'Finished') {
            this.setState({ pages: store.getState().pagesManagement.pagesMetadata });
        }
    }

    componentWillUnmount() {
        this.storeUnsubscribe();
    }

    buildPagesSelector() {
        this.pageOptions = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.PageDropdownlistboxTitle), itemType: SelectableOptionMenuItemType.Header }];
        this.state.pages.forEach(element => {
            this.pageOptions.push({ key: element.urlName, text: element.urlName });
        });
    }

    render() {
        this.buildPagesSelector();
        return (
            <ComboBox
                defaultSelectedKey={this.props.defaultSelectedKey ? this.props.defaultSelectedKey : ''}
                label={this.props.label ? this.props.label : ''}
                ariaLabel={this.props.ariaLabel ? this.props.ariaLabel : ''}
                allowFreeform={this.props.allowFreeform ? this.props.allowFreeform : false}
                onChange={(ev: React.FormEvent<IComboBox>, items: IComboBoxOption, index: number, value: string) => this.props.onChange(items, index, value)}
                options={this.pageOptions}
            />
        );
    }
}
export default PagesDropdownListBox;
