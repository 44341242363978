import { Reducer } from 'redux';
import { ReviewDashboard } from '../Models/ComplianceDashboard';
import { DashboardFilter } from '../Models/ComplianceDashboard';
import { CloudService } from '../Models/ComplianceDashboard';
import { Framework } from '../Models/ComplianceDashboard';

export const REQUEST_DASHBOARDDETAILS = 'REQUEST_DASHBOARDDETAILS';
export const RECEIVE_DASHBOARDDETAILS = 'RECEIVE_DASHBOARDDETAILS';
export const DELETE_DASHBOARDTILE = 'DELETE_DASHBOARDTILE';
export const ARCHIVE_DASHBOARDTILE = 'ARCHIVE_DASHBOARDTILE';
export const HELPTOUR_COMPLETE = 'HELPTOUR_COMPLETE';
export const RENAME_DASHBOARDTILE = 'RENAME_DASHBOARDTILE';
export const FILTER_DASHBOARDTILE = 'FILTER_DASHBOARDTILE';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_FRAMEWORKS = 'GET_FRAMEWORKS';
export const ADD_FRAMEWORKS = 'ADD_FRAMEWORKS';

export interface DashboardState {
    isLoading: boolean;
    dashboard: ReviewDashboard | null;
    filter: DashboardFilter;
    products: Array<CloudService>;
    frameworks: Array<Framework>;
}

const unloadedState: DashboardState = {
    isLoading: false,
    dashboard: null,
    filter: { ProductFilter: [], StatusFilter: [] },
    products: [],
    frameworks: [],
};

export interface ReceiveDashboardDetails {
    type: typeof RECEIVE_DASHBOARDDETAILS;
    data: ReviewDashboard;
}

export interface RequestDashboardDetails {
    type: typeof REQUEST_DASHBOARDDETAILS;
}

export interface DeleteDashboardTile {
    type: typeof DELETE_DASHBOARDTILE;
    data: ReviewDashboard;
}

export interface ArchiveDashboardTile {
    type: typeof ARCHIVE_DASHBOARDTILE;
    data: ReviewDashboard;
}

interface HelpTourComplete {
    type: typeof HELPTOUR_COMPLETE;
}

interface RenameDashboardTile {
    type: typeof RENAME_DASHBOARDTILE;
    tileId: string;
    name: string;
}

export interface FilterDashboardTile {
    type: typeof FILTER_DASHBOARDTILE;
    filterData: DashboardFilter;
}

export interface GetProducts {
    type: typeof GET_PRODUCTS;
    productsData: Array<CloudService>;
}

export interface GetFrameworks {
    type: typeof GET_FRAMEWORKS;
    frameworksData: Array<Framework>;
}

export interface AddFrameworks {
    type: typeof ADD_FRAMEWORKS;
    data: ReviewDashboard;
}

export type KnownAction = RequestDashboardDetails | ReceiveDashboardDetails | DeleteDashboardTile | ArchiveDashboardTile |
    HelpTourComplete | RenameDashboardTile | FilterDashboardTile | GetProducts | GetFrameworks | AddFrameworks;

export function archiveDashboardTile(data: ReviewDashboard): ArchiveDashboardTile {
    return {
        type: ARCHIVE_DASHBOARDTILE,
        data: data,
    };
}

export function deleteDashboardTile(data: ReviewDashboard): DeleteDashboardTile {
    return {
        type: DELETE_DASHBOARDTILE,
        data: data,
    };
}

export function renameDashboardTileWithId(tileId: string, name: string): RenameDashboardTile {
    return {
        type: RENAME_DASHBOARDTILE,
        tileId: tileId,
        name: name,
    };
}

export function addFrameworks(data: ReviewDashboard): AddFrameworks {
    return {
        type: ADD_FRAMEWORKS,
        data: data,
    };
}

export function getFrameworks(data: Framework[]): GetFrameworks {
    return {
        type: GET_FRAMEWORKS,
        frameworksData: data,
    };
}

export function getProducts(data: CloudService[]): GetProducts {
    return {
        type: GET_PRODUCTS,
        productsData: data,
    };
}

export function filterDashboardTiles(data: DashboardFilter): KnownAction {
    return {
        type: FILTER_DASHBOARDTILE,
        filterData: data,
    };
}

export function receiveDashboardDetails(data: ReviewDashboard): ReceiveDashboardDetails {
    return {
        type: RECEIVE_DASHBOARDDETAILS,
        data: data,
    };
}

export function helpTourComplete(): HelpTourComplete {
    return {
        type: HELPTOUR_COMPLETE,
    };
}

export const reducer: Reducer<DashboardState> = (state: DashboardState, action: KnownAction): DashboardState => {
    // tslint:disable-next-line:switch-default
    switch (action.type) {
        case REQUEST_DASHBOARDDETAILS:
            return {
                isLoading: true,
                dashboard: {} as ReviewDashboard,
                filter: state.filter,
                frameworks: state.frameworks,
                products: state.products,
            };
        case RECEIVE_DASHBOARDDETAILS:
            return {
                isLoading: false,
                dashboard: action.data,
                filter: state.filter,
                frameworks: state.frameworks,
                products: state.products,
            };
        case FILTER_DASHBOARDTILE:
            return {
                isLoading: false,
                dashboard: state.dashboard,
                filter: action.filterData,
                frameworks: state.frameworks,
                products: state.products,
            };
        case GET_PRODUCTS:
            return {
                isLoading: false,
                dashboard: state.dashboard,
                filter: state.filter,
                frameworks: state.frameworks,
                products: action.productsData,
            };
        case GET_FRAMEWORKS:
            return {
                isLoading: false,
                dashboard: state.dashboard,
                filter: state.filter,
                products: state.products,
                frameworks: action.frameworksData,
            };
        case ADD_FRAMEWORKS:
            return {
                isLoading: false,
                dashboard: action.data,
                filter: state.filter,
                products: state.products,
                frameworks: state.frameworks,
            };

        case RENAME_DASHBOARDTILE:
            let newRenameState = {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    dashboard: state.dashboard && state.dashboard.dashboard ? state.dashboard.dashboard.slice() : null,
                }
            } as DashboardState;
            if (newRenameState.dashboard && newRenameState.dashboard.dashboard) {
                let index = newRenameState.dashboard.dashboard.findIndex(d => d.id === action.tileId);
                let tile = newRenameState.dashboard.dashboard[index];
                if (tile) {
                    tile.tileName = action.name;
                }                
            }
            return newRenameState;
    }

    return state || unloadedState;
};