import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { AxiosResponse } from 'axios';
import { ErrorResponse, SKAiResponse } from './Models';

export class SKApiService {
    AskQuestion(
        question: string,
        conversationId: string,
        onRequestSuccess: (response: SKAiResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = `/api/v2/Ask?question=${question}&conversationId=${conversationId}`

        ServiceTrustClient.doRequest_New(
            'get', api, {},  () => null, (response: AxiosResponse) => {
                onRequestSuccess(response.data);
            },
            onRequestFailed
        );
    }
}

export let skApiServiceClient = new SKApiService();