import * as React from 'react';
import { Dialog, DialogType } from 'office-ui-fabric-react';
import { ErrorInfo } from '../Models/ErrorInfo';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { LocalizationIds as LocIds } from './../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../App/Services/GlobalizationService';

export interface ErrorDialogProps {
    errorInfo: ErrorInfo;
    showDialog: boolean;    
    errorMessage: string;
    isExceptionDialogStyle: boolean;
}
export interface ErrorDialogActions {
    hideDialog: () => void;
}

interface ErrorDialogState {
    isShowingDialog: boolean;
    displayMoreInfo: boolean;
}

export class ErrorDialog extends React.Component<ErrorDialogProps & ErrorDialogActions, ErrorDialogState> {
    constructor(props: ErrorDialogProps & ErrorDialogActions) {
        super(props);
        this.onDismiss = this.onDismiss.bind(this);
        this.state = { 
            isShowingDialog: this.props.showDialog, 
            displayMoreInfo: false            
         };
    }

    UNSAFE_componentWillReceiveProps(newProps: ErrorDialogProps) {
        this.setState({ isShowingDialog: newProps.showDialog });
    }

    render() {
        return (
            <Dialog
                containerClassName="errorDialog"
                isOpen={this.state.isShowingDialog}
                dialogContentProps={{
                    type: DialogType.close,
                    title: this.props.isExceptionDialogStyle ?  'Something went wrong' : Intl.Get(LocIds.UserVisibleException.OperationFailedTitle)  
                }}
                modalProps={{
                    isBlocking: true,
                }}
                onDismiss={() => this.onDismiss()}
            >
                   <div>
                    {!this.props.isExceptionDialogStyle &&
                        <div>
                            <div data-grid="col-12" >
                            <span>{this.props.errorMessage}</span>
                            </div>      
                        </div>
                    }
                    {this.props.isExceptionDialogStyle &&
                        <div>
                            <div data-grid="col-12" >
                            <span>Sorry, we ran into a problem.</span>
                            </div>                        
                            <div data-grid="col-12" className="customSpacingSmall" />
                                {!this.state.displayMoreInfo &&
                                    <div data-grid="col-12" onClick={() => this.setState({ displayMoreInfo: true })}>
                                    <span className="moreErrorInfo">More info</span>
                                    </div>
                                }

                            {this.state.displayMoreInfo &&
                                <div>
                                    <div data-grid="col-12">Correlation ID: {this.props.errorInfo.correlationId} </div>
                                    <div data-grid="col-12">Operation ID: {this.props.errorInfo.operationId} </div>
                                    <div data-grid="col-12">Region ID: {this.props.errorInfo.regionId} </div>
                                    <div data-grid="col-12">Status code: {this.props.errorInfo.statusCode} </div>
                                    <div data-grid="col-12">Message: {this.props.errorInfo.message} </div>
                                    <div data-grid="col-12">Start time: {this.props.errorInfo.whenStarted} </div>
                                    <div data-grid="col-12">Elapsed time: {this.props.errorInfo.elapsedTime} ms</div>
                                    <div data-grid="col-12">Request type: {this.props.errorInfo.requestType} </div>
                                    <div data-grid="col-12">Request URL: {this.props.errorInfo.requestUrl} </div>
                                </div>
                            }
                        </div>
                    }

                    <div data-grid="col-12 pad-12x" >
                        <div className="errorDialogFooter" >
                            <PrimaryButton
                                className="buttoncolor"
                                text="OK"
                                onClick={() => this.onDismiss()}
                            />
                        </div>
                    </div>
                
                </div>
            </Dialog>
        );
    }

    onDismiss() {
        this.setState({ isShowingDialog: false, displayMoreInfo: false });
        this.props.hideDialog();
    }
}