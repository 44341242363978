import { Reducer } from 'redux';
import { VocabularyDefinition } from '../Models/Vocabulary';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type Status = 'Init' | 'Requesting' | 'Finished' | 'Success' | 'Error';

export interface VocabulariesManagementState {
    status: Status;
    vocabularyDefinitions: VocabularyDefinition[];
}

// Define the strings that will be used when dispatching the actions that affect this model.
export const VOCABULARIES_FETCHING = 'VOCABULARIES_FETCHING';
export type VOCABULARIES_FETCHING = typeof VOCABULARIES_FETCHING;

export const VOCABULARIES_FETCHED = 'VOCABULARIES_FETCHED';
export type VOCABULARIES_FETCHED = typeof VOCABULARIES_FETCHED;

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface VocabulariesFetching {
    type: VOCABULARIES_FETCHING;
}

interface VocabulariesFetched {
    type: VOCABULARIES_FETCHED;
    data: VocabularyDefinition[];
}

interface VocabulariesFetchingError {
    type: 'VOCABULARIES_FETCHING_ERROR';
    // tslint:disable-next-line:no-any
    data: any;
}

interface VocabulariesCreating {
    type: 'VOCABULARIES_CREATING';
}

interface VocabulariesDeleting {
    type: 'VOCABULARIES_DELETING';
}

interface VocabulariesDeleted {
    type: 'VOCABULARIES_DELETED';
    // tslint:disable-next-line:no-any
    data: any;
}

interface VocabulariesDeletingError {
    type: 'VOCABULARIES_DELETING_ERROR';
    // tslint:disable-next-line:no-any
    data: any;
}

interface VocabulariesCreated {
    type: 'VOCABULARIES_CREATED';
    // tslint:disable-next-line:no-any
    data: any;
}

interface VocabulariesCreatingError {
    type: 'VOCABULARIES_CREATING_ERROR';
    // tslint:disable-next-line:no-any
    data: any;
}

export type VocabulariesManagementActions = VocabulariesFetching | VocabulariesFetched | VocabulariesFetchingError
    | VocabulariesCreating | VocabulariesCreated | VocabulariesCreatingError
    | VocabulariesDeleting | VocabulariesDeleted | VocabulariesDeletingError;

export function saveVocabulary(data: VocabularyDefinition[]): VocabulariesManagementActions {
    return {
        type: VOCABULARIES_FETCHED,
        data: data,
    };
}

export function fetchVocabularies(): VocabulariesManagementActions {
    return {
        type: VOCABULARIES_FETCHING
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: VocabulariesManagementState = {
    status: 'Init',
    vocabularyDefinitions: []
};

export const reducer: Reducer<VocabulariesManagementState> = (state: VocabulariesManagementState, action: VocabulariesManagementActions) => {
    switch (action.type) {
        case 'VOCABULARIES_FETCHING':
        case 'VOCABULARIES_CREATING':
        case 'VOCABULARIES_DELETING':            
            return {
                ...state,
                status: 'Requesting'
            };
        case VOCABULARIES_FETCHED:
            return {
                ...state,
                status: 'Finished',
                vocabularyDefinitions: action.data
            };
        case 'VOCABULARIES_CREATED':
        case 'VOCABULARIES_DELETED':
            return {
                ...state,
                status: 'Success'
            };
        case 'VOCABULARIES_FETCHING_ERROR':
        case 'VOCABULARIES_CREATING_ERROR':
        case 'VOCABULARIES_DELETING_ERROR':
            return {
                ...state,
                status: 'Error'
            };
        default:
        return state || unloadedState;
    }    
};