import * as React from 'react';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { LibraryNotificationPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Intl } from '../../Services/GlobalizationService';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { ButtonTypes, CommandBarButtonFactory } from '../../../Common/Utilities/CommanBarButtonFactory';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';

import './LibraryNotificationPanel.css';
import { BasePanelState } from './BasePanel';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';

export interface LibraryNotificationPanelRedux {
    isSignedIn?: boolean;
    loaded?: boolean;
    totalDocumentsSubscribed?: number;
    totalDocumentsUpdated?: number;
}

export type LibraryNotificationPanelModel = BasicPanelProperties<LibraryNotificationPanelPropertiesData> & LibraryNotificationPanelRedux;

export interface LibraryNotificationPanelComponentState {
    dismissed: boolean;
}

export type LibraryNotificationPanelState = BasePanelState<LibraryNotificationPanelPropertiesData> & LibraryNotificationPanelComponentState;

export class LibraryNotificationPanel extends React.Component<
    LibraryNotificationPanelModel, 
    LibraryNotificationPanelState
> {
    private deletePanelButton: IContextualMenuItem;
    private movePanelUpButton: IContextualMenuItem;
    private movePanelDownButton: IContextualMenuItem;

    constructor(props: BasicPanelProperties<LibraryNotificationPanelPropertiesData>) {
        super(props);

        this.state = {
            ...this.state,
            dismissed: false,
        };

        this.deletePanelButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.delete(this.props.panel), Intl.Get(LocIds.PageAndPanels.DeleteButtonLabel));
        this.movePanelUpButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveUp, () => this.props.moveUp(this.props.panel), Intl.Get(LocIds.PageAndPanels.MoveUpPanelButtonDescription));
        this.movePanelDownButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveDown, () => this.props.moveDown(this.props.panel), Intl.Get(LocIds.PageAndPanels.MoveDownPanelButtonDescription));
    }

    private dismiss = () => {
        this.setState({ dismissed: true });
    }

    private getActualPanel = () => {
        const { isInEditMode, totalDocumentsUpdated } = this.props;
        const { dismissed } = this.state;
        const docsUpdated = isInEditMode ? 3 : totalDocumentsUpdated; // in edit mode we show a fake version

        if (!docsUpdated || !(docsUpdated > 0) || dismissed) { return; }

        return (
            <div className="stp-notification">
                <div className="m-header x-clearfix">
                    <strong>{Intl.Get(LocIds.PageAndPanels.UpdateLabel)}:</strong> {this.createAlertMarkup(docsUpdated)}
                </div>
            </div>
        );
    }

    private createAlertMarkup = (totalDocumentsUpdated?: number) => {
        const value = Intl.Get(LocIds.PageAndPanels.LibraryAlertText, { totalDocumentsUpdated });
        const tokens = value.match(/__[^_]+__/ig);

        if (tokens === null) { return [ value ]; }

        const token = tokens[0];

        const strings = value.split(token);
        const linkText = token.replace(/_/g, ''); // remove all the underscores

        let nodes = [];

        for (let i = 0; i < strings.length; i++) {
            nodes.push(strings[i]);

            if (i < (strings.length - 1)) {
                nodes.push(<a key="library-link" href="/Library" className="c-hyperlink">{linkText}</a>);
            }
        }

        return nodes;
    }

    public render() {
        const { isInEditMode } = this.props;

        return (
            <div data-grid="col-12">
                {isInEditMode && (
                    <div>
                        <CommandBar
                            items={[]}
                            farItems={[this.deletePanelButton, this.movePanelUpButton, this.movePanelDownButton]}
                        />

                        <div data-grid="col-12">
                            <blockquote>This will be displayed to the user if they have updated documents in their library.</blockquote>
                        </div>
                    </div>
                )}

                <div data-grid="col-12">
                    {this.getActualPanel()}
                </div>
            </div>
        );
    }
}

export default LibraryNotificationPanel;
