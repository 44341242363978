import * as Redux from 'redux';
import { ErrorResponse } from '../Services/Models';
import { PageMetadata } from '../Models/PageInfrastructure';

export interface PanelPages {
    loaded: boolean;
    pending: boolean;
    pages?: PageMetadata[];
    error?: string;
}

export const initialState: PanelPages = {
    loaded: false,
    pending: false
};

// Define the action type strings
export const PANEL_PAGES_REQUEST = 'PANEL_PAGES_REQUEST';
export type PANEL_PAGES_REQUEST = typeof PANEL_PAGES_REQUEST;

export const PANEL_PAGES_RECEIVED = 'PANEL_PAGES_RECEIVED';
export type PANEL_PAGES_RECEIVED = typeof PANEL_PAGES_RECEIVED;

export const PANEL_PAGES_ERROR = 'PANEL_PAGES_ERROR';
export type PANEL_PAGES_ERROR = typeof PANEL_PAGES_ERROR;

// For each action, define a strongly-typed interface which includes the action-specific properties.
export interface PanelPagesRequestAction {
    type: PANEL_PAGES_REQUEST;
}

export interface PanelPagesReceivedAction {
    type: PANEL_PAGES_RECEIVED;
    pages?: PageMetadata[];
}

export interface PanelPagesErrorAction {
    type: PANEL_PAGES_ERROR;
    error: string;
}

// Define the merged set of all the strongly-typed actions that can affect this model.
export type PanelPagesAction =
    PanelPagesRequestAction | PanelPagesReceivedAction | PanelPagesErrorAction;

// Define helper functions that return strongly-typed routable action instances that inject the action-specific properties into the routable objects.
export function requestPanelPages(): PanelPagesAction {
    return {
        type: PANEL_PAGES_REQUEST
    };
}

export function receivedPanelPages(pages?: PageMetadata[]): PanelPagesAction {
    return {
        pages,
        type: PANEL_PAGES_RECEIVED
    };
}

export function errorPanelPages(error: ErrorResponse): PanelPagesAction {
    return {
        error: error.message,
        type: PANEL_PAGES_ERROR
    };
}

// Define the reducer that performs the model changes required for all of the actions that can affect this model.
export const reducer: Redux.Reducer<PanelPages> =
    (oldState: PanelPages, action: PanelPagesAction): PanelPages => {
        switch (action.type) {
            case PANEL_PAGES_REQUEST:
                return {
                    ...oldState,
                    loaded: false,
                    pending: true
                };

            case PANEL_PAGES_RECEIVED:
                return {
                    ...oldState,
                    loaded: true,
                    pending: false,
                    pages: action.pages,
                };

            case PANEL_PAGES_ERROR:
                return {
                    ...oldState,
                    pages: undefined,
                    error: action.error,
                    loaded: false,
                    pending: false
                };

            default:
                return {
                    ...initialState,
                    ...oldState
                };
        }
    };
