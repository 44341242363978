import * as React from 'react';
import { ContextualMenu, DirectionalHint, IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Intl } from '../../App/Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../Utilities/Globalization/IntlEnum';

export interface MultiSelectContextualMenuProps {
  menuItems: IContextualMenuItem[];
  text: string;
  style?: React.CSSProperties;
  ariaLabelPrefix?: string;
}

export interface MultiSelectContextualMenuState {
  isContextMenuVisible: boolean;
}

export class MultiSelectContextualMenu extends React.Component<MultiSelectContextualMenuProps, MultiSelectContextualMenuState> {

  private contextualMenuButton: HTMLAnchorElement;

  public constructor(props: MultiSelectContextualMenuProps) {
    super(props);

    this._onClick = this._onClick.bind(this);
    this._onDismiss = this._onDismiss.bind(this);

    this.state = { isContextMenuVisible: false };
  }

  public render() {
    return (
      <div className="c-pivot" style={this.props.style ? this.props.style : {}}>
        <div>
          <span tabIndex={0} aria-label={this.state.isContextMenuVisible ? "" : this.props.ariaLabelPrefix + " " + this.props.text + " " + Intl.Get(LocIds.Search.DropdownCollapsedText)} onKeyUp={(event) => this._onKeyUp(event)} onClick={this._onClick} ref={(e: HTMLAnchorElement) => this.contextualMenuButton = e} >
            {this.props.text}
            <i className="ms-Icon ms-Icon--ChevronDown gylph-foxNav" aria-hidden="true" style={{ marginLeft: '5px' }} />
          </span>
        </div>
        {this.state.isContextMenuVisible ? (
          <ContextualMenu
            target={this.contextualMenuButton}
            shouldFocusOnMount={true}
            onDismiss={this._onDismiss}
            directionalHint={DirectionalHint.bottomLeftEdge}
            items={this.props.menuItems}
            className={'fontSize'}
          />) : (null)}
      </div>
    );
  }

  private _onClick() {
    this.setState({ isContextMenuVisible: true });
  }
  private _onKeyUp(event: React.KeyboardEvent<HTMLAnchorElement>) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this._onClick();
    }
  }

  private _onDismiss() {
    this.setState({ isContextMenuVisible: false });
  }
}
