import { Reducer, AnyAction } from 'redux';
import { ControlFilter } from '../../Models/ComplianceManagerV3/ControlFilter';
import { DashBoardType } from '../../Models/ComplianceManagerV3';

export const ASSESSMENT_CONTROLINFO_SET_FILTER_CMV3 = 'ASSESSMENT_CONTROLINFO_SET_FILTER_CMV3';
export const TEMPLATE_CONTROLINFO_SET_FILTER_CMV3 = 'TEMPLATE_CONTROLINFO_SET_FILTER_CMV3';

export interface SaveAssessmentControlInfoFilterAction extends AnyAction {
    type: typeof ASSESSMENT_CONTROLINFO_SET_FILTER_CMV3;
    data: ControlFilter;
}

export interface SaveTemplateControlInfoFilterAction extends AnyAction {
    type: typeof TEMPLATE_CONTROLINFO_SET_FILTER_CMV3;
    data: ControlFilter;
}

// gets new instance of the SaveAssessmentControlInfoFilterAction redux action object to be used for saving ControlFilter object into the store
export function getSaveAssessmentControlInfoFilterAction(filter: ControlFilter): SaveAssessmentControlInfoFilterAction {
    return {
        type: ASSESSMENT_CONTROLINFO_SET_FILTER_CMV3,
        data: filter,
    };
}

// gets new instance of the SaveTemplateControlInfoFilterAction redux action object to be used for saving ControlFilter object into the store
export function getSaveTemplateControlInfoFilterAction(filter: ControlFilter): SaveTemplateControlInfoFilterAction {
    return {
        type: TEMPLATE_CONTROLINFO_SET_FILTER_CMV3,
        data: filter,
    };
}

// gets new instance of the SaveAssessmentControlInfoFilterAction redux action object to be used for clearing ControlFilter object from the store
export const getClearAssessmentControlInfoFilterAction: () => SaveAssessmentControlInfoFilterAction =
    () => getSaveAssessmentControlInfoFilterAction(getDefaultState());

// gets new instance of the SaveTemplateControlInfoFilterAction redux action object to be used for clearing ControlFilter object from the store
export const getClearTemplateControlInfoFilterAction: () => SaveTemplateControlInfoFilterAction =
    () => getSaveTemplateControlInfoFilterAction(getDefaultState(DashBoardType.Template));

// gets unloaded state for ActionItemFilter
export function getDefaultState(type?: DashBoardType): ControlFilter {
    return {
        dashboardType: type ? type : DashBoardType.Assessment,
        tileId: '',
        mappedControlCategories: [],
        implementationStatus: [],
        testResults: [],
        assignedUser: ''
    };
}

export type SaveControlsInfoFilterFlyoutAction = SaveAssessmentControlInfoFilterAction | SaveTemplateControlInfoFilterAction;

// defines reducer function setting ControlFilter for either assessments or templates control info filter
export const TemplateFilter = (state: ControlFilter, action: SaveControlsInfoFilterFlyoutAction): ControlFilter => {
    switch (action.type) {
        case TEMPLATE_CONTROLINFO_SET_FILTER_CMV3:
            return action.data;
        default:
            return state || getDefaultState();
    }
};

// defines reducer function setting ControlFilter for either assessments or templates control info filter
export const AssessmentFilter = (state: ControlFilter, action: SaveControlsInfoFilterFlyoutAction): ControlFilter => {
    switch (action.type) {
        case ASSESSMENT_CONTROLINFO_SET_FILTER_CMV3:
            return action.data;
        default:
            return state || getDefaultState();
    }
};