import { authContext } from '../../Common/Utilities/ADAuthenticate';
import { store } from '../../Store';
import { getWindowLocationOrigin, getWindowLocationPathname } from './BrowserGlobals';

export function login(context: typeof authContext, itar?: boolean): void {
    if (!isAuthenticated(context)) {
        if (!(store.getState().environmentSettings.status === 'Loaded')) {
            throw new Error('Login was called before the environment settings were fetched.');
        }
        context.login(store.getState().environmentSettings.webApiEndpointUrl, itar);
    }
}

export function logout(context: typeof authContext): void {
    if (isAuthenticated(context)) {
        // if the user is in Compliance Manager, we need to take to the homepage.
        var logoutUrl;
        var path = getWindowLocationPathname();
        if (path && path.match('(/CheckServiceCompliance|/ActionItems|/FrameworkDetail).*')) {
            logoutUrl = getWindowLocationOrigin();
        }
        context.logOut(logoutUrl);
    }
}

export function isAuthenticated(context: typeof authContext): boolean {
    if (!context) {
       return false; 
    }
    const cachedUser = context.getCachedUser();
    return cachedUser && cachedUser.userName !== '' && cachedUser.authData !== undefined;
}

export function altSecIdLogin(context: typeof authContext): void {
    if (!isAuthenticated(context)) {
        if (!(store.getState().environmentSettings.status === 'Loaded')) {
            throw new Error('Login was called before the environment settings were fetched.');
        }
        context.performAutoSignForAltSecId(store.getState().environmentSettings.webApiEndpointUrl);
    }
}