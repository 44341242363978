import {
  ApplicationInsights,
  IDependencyTelemetry,
  ITelemetryItem,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { PropsIdScrubber } from "./PropsIdScrubber";

export class TelemetryClient {
  private static appInsights: ApplicationInsights;
  private static instrumentationKey: string;
  private static isInstrumentationKeyLoaded: boolean = false;
  private static scrubber: PropsIdScrubber = new PropsIdScrubber();

  /**
   * getAppInsightsKey is to get Application Insights Instrumentation Key.
   */
  public static getAppInsightsKey(): string {
    return TelemetryClient.instrumentationKey;
  }

  /**
   * Initialize for Application Insights.
   * This will download and install with an Application Insights Instrumentation Key.
   * @param {string} appInsightsKey - Application Insights Instrumentation Key
   * @param {boolean} isAnalyticsCookiesConsented - A boolean that indicates whether to disable the use of cookies by the SDK. If false, the SDK will not store or read any data from cookies.
   */
  public static Initialize(
    appInsightsKey: string,
    isAnalyticsCookiesConsented: boolean
  ) {
    if (!TelemetryClient.isInstrumentationKeyLoaded) {
      this.instrumentationKey = appInsightsKey;
      if (TelemetryClient.instrumentationKey) {
        if (ApplicationInsights) {
          TelemetryClient.appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: TelemetryClient.instrumentationKey,
              disableCookiesUsage: !isAnalyticsCookiesConsented,
            },
          });
          TelemetryClient.appInsights.loadAppInsights();
          TelemetryClient.appInsights.addTelemetryInitializer(
            TelemetryClient.getTelemetryInitializer
          );
          TelemetryClient.isInstrumentationKeyLoaded = true;
        }
      }
    }
  }

  public static UpdateCookieConfig(isAnalyticsCookiesConsented: boolean): void {
    if (TelemetryClient.appInsights) {
      const cookieMgr = TelemetryClient.appInsights.getCookieMgr();

      if (!isAnalyticsCookiesConsented) {
        cookieMgr.del("ai_user");
        cookieMgr.del("ai_session");
      }

      cookieMgr.setEnabled(isAnalyticsCookiesConsented);
    }
  }

  /**
   * Starts timing how long the user views a page or other item. Call this when the page opens.
   * This method doesn't send any telemetry. Call {@link stopTrackTelemetry} to log the page when it closes.
   * @param   name  A string that idenfities this item, unique within this HTML document. Defaults to the document title.
   */
  // tslint:disable-next-line:no-any
  public static startTrackPage(name?: string): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.startTrackPage(name);
    }
  }

  /**
   * Logs how long a page or other item was visible, after {@link startTrackPage}. Call this when the page closes.
   * @param   name  The string you used as the name in startTrackPage. Defaults to the document title.
   * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
   * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  public static stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    // tslint:disable-next-line:no-any
    measurements?: { [name: string]: number }
  ): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.stopTrackPage(
        name,
        url,
        properties,
        measurements
      );
    }
  }

  /**
   * Logs that a page or other item was viewed.
   * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
   */
  public static trackPageView(url: string) {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.trackPageView({ uri: url });
    }
  }

  /**
   * Start timing an extended event. Call {@link stopTrackEvent} to log the event when it ends.
   * @param   name    A string that identifies this event uniquely within the document.
   */
  // tslint:disable-next-line:no-any
  public static startTrackEvent(name: string): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.startTrackEvent(name);
    }
  }

  /**
   * Log an extended event that you started timing with {@link startTrackEvent}.
   * @param   name    The string you used to identify this event in startTrackEvent.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  public static stopTrackEvent(
    name: string,
    properties?: { [name: string]: string },
    // tslint:disable-next-line:no-any
    measurements?: { [name: string]: number }
  ): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.stopTrackEvent(
        name,
        properties,
        measurements
      );
    }
  }

  /**
   * Log a user action or other occurrence.
   * @param   name    A string to identify this event in the portal.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  public static trackEvent(
    name: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.trackEvent(
        { name },
        {
          ...properties,
          ...measurements,
        }
      );
    }
  }

  /**
   * Log a dependency call
   * @param dependency IDependencyTelemetry
   * @returns
   */
  public static trackDependencyData(dependency: IDependencyTelemetry): void {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.trackDependencyData(dependency);
    }
  }

  /**
   * Log an exception you have caught.
   * @param   exception   An Error from a catch clause, or the string error message.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   * @param   severityLevel   AI.SeverityLevel - severity level
   */
  public static trackException(
    exception: Error,
    handledAt?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number },
    severityLevel?: SeverityLevel
  ) {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.trackException(
        { exception: exception, severityLevel },
        { handledAt, ...properties, ...measurements }
      );
    }
  }

  /**
   * Log a numeric value that is not associated with a specific event. Typically used to send regular reports of performance indicators.
   * To send a single measurement, use just the first two parameters. If you take measurements very frequently, you can reduce the
   * telemetry bandwidth by aggregating multiple measurements and sending the resulting average at intervals.
   * @param   name    A string that identifies the metric.
   * @param   average Number representing either a single measurement, or the average of several measurements.
   * @param   sampleCount The number of measurements represented by the average. Defaults to 1.
   * @param   min The smallest measurement in the sample. Defaults to the average.
   * @param   max The largest measurement in the sample. Defaults to the average.
   */
  public static trackMetric(
    name: string,
    average: number,
    sampleCount?: number,
    min?: number,
    max?: number,
    // tslint:disable-next-line:no-any
    properties?: { [name: string]: string }
  ): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.trackMetric(
        { name, average, min, max, sampleCount },
        properties
      );
    }
  }

  /**
   * Log a diagnostic message.
   * @param    message A message string
   * @param    properties  map[string, string] - additional data used to filter traces in the portal. Defaults to empty.
   */
  public static trackTrace(
    message: string,
    properties?: { [name: string]: string },
    severityLevel?: SeverityLevel
    // tslint:disable-next-line:no-any
  ): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.trackTrace(
        { message, severityLevel },
        properties
      );
    }
  }

  /**
   * Immediately send all queued telemetry.
   */
  // tslint:disable-next-line:no-any
  public static flush(): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.flush();
    }
  }

  /**
   * Sets the autheticated user id and the account id in this session.
   * User auth id and account id should be of type string. They should not contain commas, semi-colons, equal signs, spaces, or vertical-bars.     *
   * @param authenticatedUserId {string} - The authenticated user id. A unique and persistent string that represents each authenticated user in the service.
   * @param accountId {string} - An optional string to represent the account associated with the authenticated user.
   */
  public static setAuthenticatedUserContext(
    authenticatedUserId: string,
    accountId?: string,
    storeInCookie?: boolean
    // tslint:disable-next-line:no-any
  ): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.setAuthenticatedUserContext(
        authenticatedUserId,
        accountId
      );
    }
  }

  /**
   * Clears the authenticated user id and the account id from the user context.
   */
  // tslint:disable-next-line:no-any
  public static clearAuthenticatedUserContext(): any {
    if (TelemetryClient.isInstrumentationKeyLoaded && ApplicationInsights) {
      return TelemetryClient.appInsights.clearAuthenticatedUserContext();
    }
  }

  /**
   * Telemetry initializers are used to modify the contents of collected telemetry before being sent from the user's browser.
   */
  private static getTelemetryInitializer = (envelope: ITelemetryItem) => {
    if (envelope.tags && envelope.data) {
      let userId = envelope.tags["ai.user.id"];
      envelope.tags["ai.user.id"] = "";

      if (envelope.ext && envelope.ext.user && envelope.ext.user.id) {
        userId = envelope.ext.user.id;
      }

      if (envelope.data.baseData.properties) {
        TelemetryClient.scrubber.stripIdsFromProperties(
          envelope.data.baseData.properties,
          userId
        );
      }
    }
  };
}
