import { MockServiceSettings } from '../Mock/Model/MockServiceSettings';
import { MockComplianceDashboardV3ClientService } from '../Mock/Services/MockComplianceDashboardV3Service';
import { MockNotificationServicesClient } from '../Mock/Services/MockNotificationServices';
import { MockDimensionLookupClientService } from '../Mock/Services/MockDimensionLookupService';
import { MockResponsiblePartiesClientLookupService } from '../Mock/Services/MockResponsiblePartiesLookupService';
import { MockCustomerActionLookupClientService } from '../Mock/Services/MockCustomerActionLookupService';
import { HttpVerbs, ComplianceManagerConstants } from '../Common/Models/ComplianceManagerConstants';
import { MockClientActionItemService } from './Services/MockActionItemService';
import { MockClientGroupService } from './Services/MockGroupService';
import { MockAssessmentClientService } from './Services/MockAssessmentService';
import { MockCustomControlServicesClient } from '../Mock/Services/MockAddCustomControlData';
import { MockControlFamilyClientService } from '../Mock/Services/MockControlFamily';
import { MockAddTemplateDataClient } from './Services/MockAddTemplateData';
import { MockTenantSettings } from '../Mock/Services/MockTenantSettingsService';

export module MockSettings {
    export const Settings: MockServiceSettings[] =
        [            
            {
                'url': ComplianceManagerConstants.ApiPaths.Dimensions,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockDimensionLookupClientService.getDimensionLookup
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Dimensions,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockDimensionLookupClientService.postDimensionLookup
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Dimensions,
                'httpMethod': HttpVerbs.delete,
                'responseMethod': MockDimensionLookupClientService.deleteDimension
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ResponsibleParties,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockResponsiblePartiesClientLookupService.getResponsiblePartiesLookup
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ResponsibleParties,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockResponsiblePartiesClientLookupService.postResponsibleParty
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ResponsibleParties,
                'httpMethod': HttpVerbs.delete,
                'responseMethod': MockResponsiblePartiesClientLookupService.deleteResponsibleParty
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.CustomerActions,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockCustomerActionLookupClientService.getCustomerActionsLookup
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.CustomerActions,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockCustomerActionLookupClientService.postCustomerAction
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.CustomerActions,
                'httpMethod': HttpVerbs.put,
                'responseMethod': MockCustomerActionLookupClientService.postCustomerAction
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.CustomerActions,
                'httpMethod': HttpVerbs.delete,
                'responseMethod': MockCustomerActionLookupClientService.deleteCustomerAction
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Assessments,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockComplianceDashboardV3ClientService.getDashBoardTiles
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Templates,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockComplianceDashboardV3ClientService.postAssessment
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Templates,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockComplianceDashboardV3ClientService.getTemplateTiles
            },
            {
                'url': '/api/V3/Notifications/Summary',
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockNotificationServicesClient.getSummary
            },
            {
                'url': '/api/V3/Notifications/Documents',
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockNotificationServicesClient.getDocuments
            },
            {
                'url': '/api/V3/Notifications/Settings',
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockNotificationServicesClient.getSettings
            },
            {
                'url': '/api/V3/Notifications/PostSettings',
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockNotificationServicesClient.postSettings
            },
            {
                'url': '/api/V3/Notifications/PostDocument',
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockNotificationServicesClient.postDocument
            },
            {
                'url': '/api/V3/Notifications/DeleteDocument',
                'httpMethod': HttpVerbs.delete,
                'responseMethod': MockNotificationServicesClient.deleteDocument
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ActionItems,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockClientActionItemService.getActionItems
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ActionItems,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockClientActionItemService.getActionItems
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ActionItems,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockClientActionItemService.PostActionItems
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.microsoftActionItem,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockClientActionItemService.getMicrosoftActionItem
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Groups,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockClientGroupService.getGroupLookup
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.AssessmentsByGroup,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockAssessmentClientService.getAssessmentLookup
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Templates,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockComplianceDashboardV3ClientService.postAssessment
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.Templates,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockComplianceDashboardV3ClientService.getTemplateTiles
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.AddCustomControl,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockCustomControlServicesClient.postCustomControlData
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.AddCustomControl,
                'httpMethod': HttpVerbs.post,
                'responseMethod': MockCustomControlServicesClient.postCustmomeControlFilterData
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ControlFamily,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockControlFamilyClientService.getControlFamilies
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ResolveAssessmentConflict,
                'httpMethod': HttpVerbs.patch,
                'responseMethod': MockAssessmentClientService.getAssessmentLookup
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ResolveTemplateConflict,
                'httpMethod': HttpVerbs.patch,
                'responseMethod': MockAddTemplateDataClient.getDocuments
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ControlsCategory,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockAssessmentClientService.getControCategories
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.ValidateImport,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockAddTemplateDataClient.validateTemplate,
            },
            {
                'url': ComplianceManagerConstants.ApiPaths.TenantSettings,
                'httpMethod': HttpVerbs.get,
                'responseMethod': MockTenantSettings.getTenantSettings
            }
        ];
}