import { ErrorResponse } from '../../App/Services/Models';
import { MockServiceSettings } from '../../Mock/Model/MockServiceSettings';
import { ServiceTrustClient } from '../Utilities/ServiceTrustClient';
import { IKeyValuePair } from '../../App/Models/IKeyValuePair';
import { MockSettings } from '../../Mock/MockSettings';
import { HttpVerbs } from '../../Common/Models/ComplianceManagerConstants';
import { authContext } from '../../Common/Utilities/ADAuthenticate';

// ServiceFactory to determine whether to call actual service vs mock service.
export class ServiceFactoryToExecuteApi {
    // reading the settings file directly.  
    private readonly mockServiceSettings: MockServiceSettings[] = MockSettings.Settings;
    ExecuteRequest<T>(apiUrl: string,
                      params: IKeyValuePair[],
                      httpMethod: string | HttpVerbs,
                      // tslint:disable-next-line:no-any
                      data: any,
                      onRequestSuccess: (response: T) => void,
                      onRequestFailed: (error: ErrorResponse) => void,
                      useMockService: boolean = false, // Pass the parameter by reading it from store through components.
                      beforeRequest?: () => void,
                      customRequestTimeOut?: number
    ) {
        if (typeof httpMethod === 'string') {
            httpMethod = HttpVerbs[httpMethod];
        }
        let foundMockSettings: MockServiceSettings | undefined = this.mockServiceSettings.find(x => x.url === apiUrl && x.httpMethod === httpMethod);

        // check if  mock services flag is enabled and there is an entry in the mock settings file for the url requested. 
        if (useMockService && foundMockSettings) {
            if (foundMockSettings.responseMethod) {
                // execute method based on the method type
                let responseData = foundMockSettings.responseMethod(params, data);
                // If no method found to execute send empty response.
                onRequestSuccess(responseData);
            } else if (foundMockSettings.responseJsonPath) {

                let responseData = require(foundMockSettings.responseJsonPath);
                // If no method found to execute send empty response.
                onRequestSuccess(responseData);
            }
        } else {
            apiUrl = this.getapiUrl(apiUrl, params);
            // Add additional parameters for doRequest_New if required.
            ServiceTrustClient.doRequest_New(
                HttpVerbs[httpMethod],
                apiUrl,
                data,
                () => beforeRequest,
                (serviceResponse) => {
                    let responseData: T = serviceResponse.data;
                    onRequestSuccess(responseData);
                },
                onRequestFailed, 
                undefined, 
                undefined, 
                customRequestTimeOut ? customRequestTimeOut : undefined
            );
        }
    }

    // Append api url with params.
    private getapiUrl(apiUrl: string,
                      params: IKeyValuePair[]) {
        let urlWithParams: string = apiUrl;
        if (params && params.length > 0) {
            params.forEach(param => {
                urlWithParams = urlWithParams.concat('/').concat(param.Value);
            });
        }
        return urlWithParams;
    }

    // Can be used to determine if next api call will be authorized or not.
    // Optional needSetComponentState callback parameter will be used to allow a caller to short-circuit the
    // the api calls by returning false in case component's state has already been set.
    public willMakeAuthorizedRequest = (needSetComponentState?: () => boolean): boolean => {
        if (!authContext.isSignedIn()) {
            return false;
        }
        if (needSetComponentState) {
            return needSetComponentState();
        }
        return true;
    }
}
export const ServiceFactoryClientExecuteApi = new ServiceFactoryToExecuteApi();