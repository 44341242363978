import * as React from 'react';
import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import './ScrollToTop.css';

export interface ScrollToTopSettings {
    intervalId: number;
    scrollStepInPx: number;
    delayInMs: number;
}

interface ScrollToTopState {
    intervalId: number | NodeJS.Timeout;
}

export class ScrollButton extends React.Component<ScrollToTopSettings, ScrollToTopState> {

    constructor(props: ScrollToTopSettings) {
        super(props);
    
        this.state = {
            intervalId: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        let scrollBottom = document.body.clientHeight - scrollTop - window.innerHeight;
        let backToTopButton = document.getElementById('btnBackToTop');

        // if back to top button is on page
        if (backToTopButton) {
            // document has scrolled at least 800px and has not scrolled within 460px of bottom
            if (scrollTop > 800 && scrollBottom > 460 ) {
                backToTopButton.setAttribute('aria-disabled', 'false');
                backToTopButton.setAttribute('tabindex', '0');
                backToTopButton.style.bottom = '24px';
            // document is now within 460px of bottom of document, so footer is visible
            } else if (scrollTop > 800 && scrollBottom <= 460) {
                backToTopButton.setAttribute('aria-disabled', 'false');
                backToTopButton.setAttribute('tabindex', '0');
                backToTopButton.style.bottom = `${460 - scrollBottom}px`;
            } else {
                backToTopButton.setAttribute('aria-disabled', 'true');
                backToTopButton.setAttribute('tabindex', '-1');
            }
        }
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId as number);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId });
    }

    render() {
        return (
            <a
                role="button"
                tabIndex={-1}
                aria-label={Intl.Get(LocIds.StandardButtons.BackToTop)}
                title={Intl.Get(LocIds.StandardButtons.BackToTop)}
                aria-disabled="true"
                id="btnBackToTop"
                className="btn bg-body btn-faint-secondary text-center w-sm-auto backToTopBtn"
                onClick={this.handleOnClick}
                onKeyDown={this.handleOnKeyDown}
            >
                <span className="glyph-prepend glyph-prepend-xsmall glyph-prepend-up d-inline-flex">
                    <span className="px-1"/>
                    {Intl.Get(LocIds.StandardButtons.BackToTop)}
                </span>
            </a>
        );
    }

    private handleOnClick = () => {
        const topButton = document.getElementById('uhfLogo');

        this.scrollToTop();

        if (topButton) {
            topButton.focus();
        }
    }

    private handleOnKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleOnClick();
        }
    }
}
