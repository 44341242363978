import * as React from 'react';
import ErrorDialog from '../Containers/ErrorDialog';
import { AuthenticationSessionDialog } from './AuthenticationSessionDialog';
import { Authorize } from 'src/Common/Components/Authorize';
import { ChatBotContainer } from './ChatBot/OriginalChatBot/ChatBotContainer';
import { CopilotStudioChatBotContainer } from './ChatBot/CopilotStudioChatBot/CopilotStudioChatBotContainer';

const TopOfPageComponent = () => {
    return (
        <div>
            <ErrorDialog />
            <AuthenticationSessionDialog />
            <Authorize requiredFlights={['OpenAiChatBot']}>
                <div className="toolsFloat">
                    <ChatBotContainer/>
                </div>
            </Authorize>
            <Authorize requiredFlights={['CopilotStudioOpenAiChatBot']}>
                <div className="toolsFloat">
                    <CopilotStudioChatBotContainer/>
                </div>
            </Authorize>
        </div>
    );
};

export default TopOfPageComponent;