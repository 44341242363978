import * as React from 'react';
import { ExternalNavigationService } from '../Services/ExternalNavigationService';
const externalNavigation = new ExternalNavigationService();
import { Link } from 'react-router-dom';
import { TooltipHost } from 'office-ui-fabric-react/lib/';
import { Icon } from '../../Common/Components/Icon';
import { Path } from 'history';

/**
 * This will take a URL and a label param and render either a page link or a full HREF
 * depending on it's match to "/^https?:\/\//" and return a rendered node for React
 *
 * @param url the URL to link
 * @param label the label to show in the anchor tag or <Link>
 * @param className the CSS classes to add to the anchor tag or <Link>
 */
export function renderLink(url: string | undefined, label: string | undefined, className?: string | undefined, ariaLabel?: string | undefined) {
    let classes: string = '';
    if (className !== undefined) {
        classes = className.includes('c-call-to-action') ? className : `c-hyperlink ${className}`;
    } else {
        classes = 'c-hyperlink';
    }
    if (ariaLabel === undefined) {
        ariaLabel = '';
    }
    return (
        /^https?:\/\//.test(url || '')
            ?
            (
                <a
                    href={url}
                    onClick={
                        () => {
                            externalNavigation.navigateTo({
                                url: url || ''
                            });
                        }
                    }
                    className={classes}
                    target="_blank"
                >
                    <span aria-label={ariaLabel}>{label}</span>
                    <Icon iconName={'NavigateExternal'} style={{ fontSize: '12px', margin: '0 0 0 4px' }} />
                </a>

            )
            :
            (
                <Link to={url || ''} aria-label={ariaLabel} className={classes}>
                    <span>{label}</span>
                </Link>
            )
    );
}

export function isEmptyString(str: string | undefined | null): boolean {
    if (typeof str === 'undefined') { return true; }
    if (null === str) { return true; }
    if (str.trim().length === 0) { return true; }

    return false;
}

/*
For use as renderer for ReactMarkdown
Decorates links with icons, target, and/or tooltip
*/
export const RichTextLink: React.SFC<{
    title?: string;
    href?: string | Path;
    children?: JSX.Element;
}> = ({ title, href, children }) => {
    // determine if the link href is pointing to current URL
    // includes is case sensitive, so make them both lowercase to avoid any case issues
    const linkCurrentUrlClass = window.location.href.toLowerCase().includes(href.toLowerCase()) ? 'stp_current-url-hyperlink' : '';
    // if internal link with tooltip text (url.title) provided
    // rich text input example: (Link Text)[/ViewPage/TestPage "This is the tooltip text"]
    if (href && href.charAt(0) === '/' && title) {
        return (
            <TooltipHost
                content={title}
                id="richTextLinkTooltip"
                calloutProps={{ isBeakVisible: false }}
            >
                <Link className={`c-hyperlink ${linkCurrentUrlClass}`} to={href as Path}>
                    {children}
                </Link>
            </TooltipHost>
        );
        // if internal link with no tooltip text (url.title) provided
        // rich text input example: (Link Text)[/ViewPage/TestPage]
    } else if (href && href.charAt(0) === '/' && !title) {
        return (
            <Link className={`c-hyperlink ${linkCurrentUrlClass}`} to={href as Path}>
                {children}
            </Link>
        );
        // if external link with tooltip text (title) provided
        // rich text input example: (Link Text)[https://www.microsoft.com "This is the tooltip text"]
    } else if (/^https?:\/\//.test(href || '') && title) {
        return (
            <TooltipHost
                content={title}
                id="richTextLinkTooltip"
                calloutProps={{ isBeakVisible: false }}
            >
                <a target="_blank" className={`c-hyperlink ${linkCurrentUrlClass}`} href={href}>
                    {children}
                    <Icon iconName={'NavigateExternal'} style={{ fontSize: '12px', margin: '0 0 0 4px' }} />
                </a>
            </TooltipHost>
        );
        // if external link without tooltip text (title)
        // rich text input example: (Link Text)[https://www.microsoft.com]
    } else if (/^https?:\/\//.test(href || '') && !title) {
        return (
            <a target="_blank" className={`c-hyperlink ${linkCurrentUrlClass}`} href={href}>
                {children}
                <Icon iconName={'NavigateExternal'} style={{ fontSize: '12px', margin: '0 0 0 4px' }} />
            </a>
        );
    } else {
        return (
            <a href={href || ''} className={`c-hyperlink ${linkCurrentUrlClass}`}>
                {children}
            </a>
        );
    }
};
