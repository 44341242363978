import { store } from '../../Store';
import { ServiceTrustClient } from './ServiceTrustClient';
import { AuthorizationInfo } from './AuthorizationInfo';

export function getImageUrl(imageId: string | undefined): string {
    return AuthorizationInfo.isIncludedInRequiredFlights(['imageLibraryCdnUrl'])
        ? getImageLibraryCdnUrl().concat(imageId || '')
        : ServiceTrustClient.getWebApiEndpointUrl().concat(imageId || '');
}

function getImageLibraryCdnUrl(): string {
    return store.getState().environmentSettings.imageLibraryCdnUrl;
}
