import { ServiceTrustClient, BasicClientInterface } from '../../Common/Utilities/ServiceTrustClient';
import { Page } from '../Utilities/PageInfrastructure/PagesHelper';
import { ButtonTypes } from '../../Common/Utilities/CommanBarButtonFactory';
import { AxiosResponse } from 'axios';
import { ErrorResponse } from './Models';
import { makeLocalizedString } from '../../Common/Utilities/LocalizationHelper';
import { DocumentPanelPropertiesData } from '../Models/PageInfrastructure';
import { SelectedVocabulary } from '../Models/Vocabulary';

export class PageServicesV2 {
    private client: BasicClientInterface;

    constructor(client?: BasicClientInterface) {
        this.client = client || ServiceTrustClient;
    }

    // Called by end user to fetch the real public page
    getPublicPageByUrlName(urlName: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            `/api/v2/GetPublicPageByUrl/${urlName}`,
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // Called by end user to fetch STP banner from home page
    getHomePageBanner( onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            `/api/v2/GetHomePageBanner/`,
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // Called by page to fetch UHF header ( top navigation menu, search and login) and footer
    getUHFContent( locale: string, adminCode: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            `/api/v2/GetUHFControls/${locale || 'en-US'}/${adminCode}`,
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // When admin try to modify a page or create a new page, query by page id
    getVNextVersionByPageId(pageId: string | undefined, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            `/api/v2/GetVNextVersionByPageId/${pageId || ''}`,
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // When admin try to view a page's current verion, use the pageId to query
    // because vNext version may have a different urlName
    getPublicPageByPageId(pageId: string | undefined, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            `/api/v2/GetPublicPageByPageId/${pageId || ''}`,
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // This method is called when admin try to load a version of the page
    getPageVersionById(id: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            `/api/v2/GetPageVersionById/${id}`,
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // Used to fetch all the versions of one page by page id. This method is called for the history version dropdown
    getPageVersionsMetadataByPageId(pageId: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            `/api/v2/GetPageVersionsMetadataByPageId/${pageId}`,
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // In page management page, admin call this method to get all pages with the latest version in page metadata
    getPageMetadataListOfLatestVersion(onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            '/api/v2/GetPageMetadataListOfLatestVersion/',
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // Called by page dropdown list which is used by panels or top navigation to get current live page list
    getPublicPageMetadataList(onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'GET',
            '/api/v2/GetPublicPageMetadataList/',
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // This method is called when admin save the changes. Because page controller v1 already use the name of savePage, change it to updatePage
    updatePage(page: Page, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'POST',
            '/api/v2/UpdatePage/',
            page,
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    duplicatePage(code: string, newPageUrlName: string, newPageDescription: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.client.doRequest_New(
            'POST',
            '/api/v2/DuplicatePage/' + code + '/' + newPageUrlName + '/' + newPageDescription,
            null,
            () => null,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    getFilteredPagesMetadata(filters: SelectedVocabulary[] | undefined, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        const fauxPage: DocumentPanelPropertiesData = {
            theme: null,
            heading: makeLocalizedString('New and Archived Audit Reports'),
            subHeading: makeLocalizedString('Use these reports to stay current on the latest privacy, security, and compliance-related information for Microsoft’s cloud services.'),
            isInViewMode: true,
            hidePanelIfNoResult: true,
            groupingInfo: [],
            firstTabName: 'fake',
            id: 'fake',
            itemStorageType: 'Microsoft.Services.ServiceTrust.Model.PanelProperties.FilterablePanelProperties',
            // all those fake values are described in the contract but it should only care about these:
            filteringInfo: filters || [],
        };

        this.client.doRequest_New(
            'POST',
            '/api/v2/GetFilteredPageMetadataList',
            (!filters || filters.length === 0) ? undefined : fauxPage,
            () => undefined,
            onRequestSuccess,
            onRequestFailed,
        );
    }

    // All the following method has the same interface taking page object ids to do bulk operation for approve, delete and reject purpose, using bulkPagesOperation
    publishPages(ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.bulkPagesOperation(ids, ButtonTypes.Publish, onRequestSuccess, onRequestFailed);
    }

    deletePages(ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.bulkPagesOperation(ids, ButtonTypes.Delete, onRequestSuccess, onRequestFailed);
    }

    approvePages(ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.bulkPagesOperation(ids, ButtonTypes.ApprovePublish, onRequestSuccess, onRequestFailed);
    }

    forcePublishPages(ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.bulkPagesOperation(ids, ButtonTypes.ForcePublish, onRequestSuccess, onRequestFailed);
    }

    approveDeletePages(ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.bulkPagesOperation(ids, ButtonTypes.ApproveDeletion, onRequestSuccess, onRequestFailed);
    }

    rejectPages(ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.bulkPagesOperation(ids, ButtonTypes.RejectPublish, onRequestSuccess, onRequestFailed);
    }

    rejectDeletePages(ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        this.bulkPagesOperation(ids, ButtonTypes.RejectDeletion, onRequestSuccess, onRequestFailed);
    }

    private bulkPagesOperation(ids: string[], pageOperationType: ButtonTypes, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void): void {
        let url = '';
        switch (pageOperationType) {
            case ButtonTypes.Publish:
                url = '/api/v2/PublishPages/';
                break;
            case ButtonTypes.Delete:
                url = '/api/v2/DeletePages/';
                break;
            case ButtonTypes.ApprovePublish:
                url = '/api/v2/ApprovePages/';
                break;
            case ButtonTypes.ApproveDeletion:
                url = '/api/v2/ApproveDeletePages/';
                break;
            case ButtonTypes.ForcePublish:
                url = '/api/v2/ForcePublishPages/';
                break;
            case ButtonTypes.RejectPublish:
                url = '/api/v2/RejectPages/';
                break;
            case ButtonTypes.RejectDeletion:
                url = '/api/v2/RejectDeletePages/';
                break;
            default:
                break;
        }
        if (url) {
            this.client.doRequest_New('POST', url, ids, () => null, onRequestSuccess, onRequestFailed);
        }
    }
}

// explicitly instantiate this with ServiceTrustClient for most consumers
export let pageServicesV2Client = new PageServicesV2(ServiceTrustClient);