import * as React from 'react';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';
import { Redirect } from 'react-router-dom';
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import { Async } from 'office-ui-fabric-react/lib/Utilities';

interface ComplianceManagerRedirectPageState {
    percentComplete: number;
}

const INTERVAL_DELAY = 1000;
const INTERVAL_INCREMENT = 0.1;

export default class ComplianceManagerRedirectPage extends React.Component<{}, ComplianceManagerRedirectPageState> {
    private async: Async;

    constructor() {
        super({});
        this.state = {
            percentComplete: 0
        };
        this.async = new Async(this);
    }

    componentDidMount() {
        this.startProgress();
    }
    
    componentWillUnmount() {
        this.async.dispose();
    }    
    
    render() {
        const { percentComplete } = this.state;
        return (
            <div style={{paddingTop: '10px'}}>
                <ProgressIndicator
                    label={Intl.Get(LocIds.ComplianceManagerV2.RedirectMessage)}
                    percentComplete={percentComplete}
                />
                {this.state.percentComplete >= 1.0 && <Redirect to="/ComplianceManager" />}
            </div>
        );
    }

    private startProgress(): void {
        this.setState({
            percentComplete: 0
        });
        this.async.setInterval(
            () => {
                let percentComplete = this.state.percentComplete + INTERVAL_INCREMENT;
                if (percentComplete >= 1.0) {
                    percentComplete = 1.0;
                }
                this.setState({
                    percentComplete: percentComplete
                });
            }, 
            INTERVAL_DELAY
        );
    }
}