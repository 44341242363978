import * as React from 'react';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import TableItem from '../../Models/TableItem';

export interface TableRowCheckboxProps {
    index: number;
    checked: boolean;
    item: TableItem;
    onChange: (index: number, item: TableItem, ev: React.FormEvent<HTMLElement>, isChecked: boolean) => void;
}

export const TableRowCheckbox: React.FunctionComponent<TableRowCheckboxProps> = ({ index, onChange, item, checked }) => {
    function _handleChange(ev: React.FormEvent<HTMLElement>, isChecked: boolean) {
        // send along our index along with the checkbox event so the parent can do something with it
        onChange(index, item, ev, isChecked);
    }

    return (
        <Checkbox
            ariaLabelledBy={Intl.Get(LocIds.PageAndPanels.SelectTableRow, { title: item.title })}
            ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectTableRow, { title: item.title })}
            className="advancedDocs__checkbox"
            onChange={_handleChange}
            checked={checked}
        />
    );
};

export default TableRowCheckbox;
