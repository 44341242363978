import * as React from 'react';
import { IComboBox, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { ComboBox, DefaultButton, IComboBoxOption, PrimaryButton } from 'office-ui-fabric-react/lib/';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { CtaPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { getPanelInsetOptions, getAlignOptions, getSimpleCallToActionThemeOptions,
    getCallToActionThemeClassName } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { isEmptyString, renderLink } from '../../../App/Utilities/RenderUtilities';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { StandardLonghandProperties } from 'csstype';
import { CSSProperties } from 'react';
import { CallToActionTheme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';

import './CtaPanel.css';

export class CtaPanel extends BasePanel<CtaPanelPropertiesData> {

    public insetOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.InsetLabel), itemType: SelectableOptionMenuItemType.Header }];
    public alignOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.AlignLabel), itemType: SelectableOptionMenuItemType.Header }];
    private PrimaryCallToActionThemeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<CtaPanelPropertiesData>) {
        super(props);
        this.insetOptions = this.insetOptions.concat(getPanelInsetOptions());
        this.alignOptions = this.alignOptions.concat(getAlignOptions());
        this.PrimaryCallToActionThemeOptions = this.PrimaryCallToActionThemeOptions.concat(getSimpleCallToActionThemeOptions());
    }

    private getPanelContents = (): JSX.Element | undefined => {

        const { alignment, heading, headingUrl, description, callToActionLabel, callToActionUrl, callToActionTheme } = this.state.panelData;
        let styleProps: CSSProperties = {};
        let headingElement = !isEmptyString(headingUrl)
            ?
            renderLink(headingUrl, getLocalizedString(heading))
            :
            (getLocalizedString(heading));
        if (alignment) {
            styleProps = {textAlign: alignment as StandardLonghandProperties<string | number>};
        }

        return (
            <div
                className="stp_cta-panel_content"
                style={styleProps}
            >
                {heading &&
                    <h2>{headingElement}</h2>
                }
                {description &&
                    <p className="c-paragraph-2">{getLocalizedString(description)}</p>
                }
                {callToActionLabel && callToActionUrl &&
                    renderLink(callToActionUrl, getLocalizedString(callToActionLabel), `c-call-to-action c-glyph f-lightweight ${getCallToActionThemeClassName(CallToActionTheme[callToActionTheme || 'Simple'])}`)
                }
            </div>
        );
    }

    private getActualPanel = (): JSX.Element | undefined => {

        const { inset } = this.state.panelData;

        return (
            <div
                className="stp_cta-panel"
                data-grid="col-12 pad-6x stack-3"
                data-panel-type="CTA"
            >
                {/* if panel is full width */}
                {inset === 'full' &&
                    <div data-grid="container">
                        <div data-grid="col-12">
                            {this.getPanelContents()}
                        </div>
                    </div>
                }
                {/* if panel is inset */}
                {inset === 'inset' &&
                    <div data-grid="container">
                        <div data-grid="col-1"/>
                        <div data-grid="col-9">
                            {this.getPanelContents()}
                        </div>
                        <div data-grid="col-2"/>
                    </div>
                }
                {/* if panel is extra inset */}
                {inset === 'extra-inset' &&
                    <div data-grid="container">
                        <div data-grid="col-3"/>
                        <div data-grid="col-6">
                            {this.getPanelContents()}
                        </div>
                        <div data-grid="col-3"/>
                    </div>
                }
            </div>
        );
    }

    public render() {

        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.alignment || 'None'}
                                                label={'Text Align:'}
                                                ariaLabel={'Text Align'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.alignOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.alignment = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.inset === 'inset' ? 'Inset Panel' : 'Full Width Panel'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectInsetLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectInsetLabel)}
                                                text={this.state.panelData.inset === 'inset' ? 'Inset Panel' : 'Full Width Panel'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.insetOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.setState({ panelData: { ...this.state.panelData, inset: item.key as string }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.headingUrl ? this.state.panelData.headingUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal) => {
                                                    this.state.panelData.headingUrl = newVal;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.description)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_DESCRIPTION_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'description', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData ? getLocalizedString(this.state.panelData.callToActionLabel) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'callToActionLabel', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.callToActionUrl ? this.state.panelData.callToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal) => {
                                                    this.state.panelData.callToActionUrl = newVal;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionUrlLabel)}
                                                isPage={true}
                                                url={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                selectedKey={this.state.panelData.callToActionTheme || 'Simple'}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.PrimaryCallToActionThemeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.callToActionTheme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <PrimaryButton onClick={() => { this.onSave(); }} text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)} />
                                    <DefaultButton onClick={() => { this.onDiscardChange(); }} text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );

    }

}