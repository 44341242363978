import { Reducer } from 'redux';
import { ComplianceDashboard, TemplateViewMode } from '../../Models/ComplianceManagerV3';
export const RECEIVE_TEMPLATEDETAILSV3 = 'RECEIVE_TEMPLATEDETAILSV3';
export const RECEIVE_TEMPLATEIDFROMCONTROLINFO = 'RECEIVE_TEMPLATEIDFROMCONTROLINFO'; // Action type for setting current template Id
export interface ComplianceDashboardTemplateStateV3 {
    isLoading: boolean;
    dashboard: ComplianceDashboard | null;
    viewMode: TemplateViewMode;  
    templateId?: string;
}

export const getUnloadedState = (): ComplianceDashboardTemplateStateV3 => {
    return {
        isLoading: false,
        dashboard: null,
        viewMode: { ShowTemplateTileView: true },
    };
};

export interface ReceiveTemplateDetails {
    type: typeof RECEIVE_TEMPLATEDETAILSV3;
    data: ComplianceDashboard;
}
export interface ReceiveTemplateIDFromControlInfo {
    type: typeof RECEIVE_TEMPLATEIDFROMCONTROLINFO;
    templateId?: string;
}

export type KnownAction = ReceiveTemplateDetails|ReceiveTemplateIDFromControlInfo | { type: '' };

export function receiveTemplateDetails(data: ComplianceDashboard): ReceiveTemplateDetails {
    return {
        type: RECEIVE_TEMPLATEDETAILSV3,
        data: data,
    };
}
export function getSetTemplateIDFromControlInfo(templateId: string | undefined): ReceiveTemplateIDFromControlInfo {
    return {
        type: RECEIVE_TEMPLATEIDFROMCONTROLINFO,
        templateId: templateId,
    };
}

export const reducer: Reducer<ComplianceDashboardTemplateStateV3> = (state: ComplianceDashboardTemplateStateV3, action: KnownAction): ComplianceDashboardTemplateStateV3 => {
    switch (action.type) {
        case RECEIVE_TEMPLATEDETAILSV3:
            return {
                ...state,
                isLoading: false,
                dashboard: action.data,

            };
            case RECEIVE_TEMPLATEIDFROMCONTROLINFO:
                return {
                    ...state,
                    templateId: action.templateId,
                };
        default:
            return state || getUnloadedState();
    }    
};