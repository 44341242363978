import { Reducer } from 'redux';
import { ComplianceDashboard, ComplianceDashboardTile, Group, Certification, DashboardFilter, CloudService, CloudServiceProduct } from '../../Models/ComplianceManagerV2';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
export const REQUEST_DASHBOARDDETAILS = 'REQUEST_DASHBOARDDETAILS';
export const RECEIVE_DASHBOARDDETAILSV2 = 'RECEIVE_DASHBOARDDETAILSV2';
export const DELETE_DASHBOARDTILE = 'DELETE_DASHBOARDTILE';
export const ARCHIVE_DASHBOARDTILE = 'ARCHIVE_DASHBOARDTILE';
export const RENAME_DASHBOARDGROUP = 'RENAME_DASHBOARDGROUP';
export const FILTER_DASHBOARDTILE = 'FILTER_DASHBOARDTILE';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_FRAMEWORKS = 'GET_FRAMEWORKS';
export const ADD_FRAMEWORKS = 'ADD_FRAMEWORKS';
export const GET_CLOUDSERVICEPRODUCT = 'GET_CLOUDSERVICEPRODUCT';

export interface ComplianceDashboardState {
    isLoading: boolean;
    dashboard2: ComplianceDashboard | null;
    filter: DashboardFilter;
    products: Array<CloudService>;
    groups: Group[];
    certifications: Certification[];
    cloudServiceProduct: CloudServiceProduct | null;
}

const unloadedState: ComplianceDashboardState = {
    isLoading: false,
    dashboard2: null,
    filter: { ProductFilter: [], StatusFilter: [], GroupFilter: [], ShowArchived: false },
    products: [],
    groups: [],
    certifications: [],
    cloudServiceProduct: null
};

export interface ReceiveDashboardDetails {
    type: typeof RECEIVE_DASHBOARDDETAILSV2;
    data: ComplianceDashboard;
}

export interface RequestDashboardDetails {
    type: typeof REQUEST_DASHBOARDDETAILS;
}

export interface DeleteDashboardTile {
    type: typeof DELETE_DASHBOARDTILE;
    data: ComplianceDashboard;
}

export interface ArchiveDashboardTile {
    type: typeof ARCHIVE_DASHBOARDTILE;
    data: ComplianceDashboard;
}

interface RenameDashboardGroup {
    type: typeof RENAME_DASHBOARDGROUP;
    tileId: string;
    name: string;
}

export interface FilterDashboardTile {
    type: typeof FILTER_DASHBOARDTILE;
    filterData: DashboardFilter;
}

export interface GetFrameworks {
    type: typeof GET_FRAMEWORKS;
    frameworksData: Array<Certification>;
}

export interface AddFrameworks {
    type: typeof ADD_FRAMEWORKS;
    data: ComplianceDashboardTile;
}

export interface GetCloudServiceProduct {
    type: typeof GET_CLOUDSERVICEPRODUCT;
    data: CloudServiceProduct;
}

export type KnownAction = RequestDashboardDetails | ReceiveDashboardDetails | DeleteDashboardTile | ArchiveDashboardTile |
    RenameDashboardGroup | FilterDashboardTile | AddFrameworks | GetFrameworks | GetCloudServiceProduct;

export function archiveDashboardTile(data: ComplianceDashboard): ArchiveDashboardTile {
    return {
        type: ARCHIVE_DASHBOARDTILE,
        data: data,
    };
}

export function deleteDashboardTile(data: ComplianceDashboard): DeleteDashboardTile {
    return {
        type: DELETE_DASHBOARDTILE,
        data: data,
    };
}

export function renameDashboardTileWithId(tileId: string, name: string): RenameDashboardGroup {
    return {
        type: RENAME_DASHBOARDGROUP,
        tileId: tileId,
        name: name,
    };
}

export function addFrameworks(data: ComplianceDashboardTile): AddFrameworks {
    return {
        type: ADD_FRAMEWORKS,
        data: data,
    };
}

export function getFrameworks(data: Certification[]): GetFrameworks {
    return {
        type: GET_FRAMEWORKS,
        frameworksData: data,
    };
}

export function filterDashboardTiles(data: DashboardFilter): KnownAction {
    return {
        type: FILTER_DASHBOARDTILE,
        filterData: data,
    };
}

export function receiveDashboardDetails(data: ComplianceDashboard): ReceiveDashboardDetails {
    return {
        type: RECEIVE_DASHBOARDDETAILSV2,
        data: data,
    };
}

export function getCloudServiceProduct(data: CloudServiceProduct): GetCloudServiceProduct {
    return {
        type: GET_CLOUDSERVICEPRODUCT,
        data: data,
    };
}

export const reducer: Reducer<ComplianceDashboardState> = (state: ComplianceDashboardState, action: KnownAction): ComplianceDashboardState => {
    // tslint:disable-next-line:switch-default
    switch (action.type) {
        case REQUEST_DASHBOARDDETAILS:
            return {
                isLoading: true,
                dashboard2: {} as ComplianceDashboard,
                filter: state.filter,
                groups: state.groups,
                products: state.products,
                certifications: state.certifications,
                cloudServiceProduct: state.cloudServiceProduct
            };
        case RECEIVE_DASHBOARDDETAILSV2:
            let groups: Group[] = [];
            if (action.data.dashboard) {
                for (let i = 0; i < action.data.dashboard.length; i++) {
                    let grp = new Group();
                    grp.id = action.data.dashboard[i].groupId;
                    grp.name = action.data.dashboard[i].groupName;
                    if (!action.data.dashboard[i].isArchived && groups.findIndex(k => k.name === grp.name) === -1) {
                        groups.push(grp);
                    }
                }
                return {
                    isLoading: false,
                    dashboard2: action.data,
                    filter: state.filter,
                    groups: groups,
                    certifications: state.certifications,
                    products: state.products,
                    cloudServiceProduct: state.cloudServiceProduct
                };
            } else {
                return {
                    isLoading: false,
                    dashboard2: action.data,
                    filter: state.filter,
                    groups: state.groups,
                    certifications: state.certifications,
                    products: state.products,
                    cloudServiceProduct: state.cloudServiceProduct
                };
            }

        case FILTER_DASHBOARDTILE:
            return {
                isLoading: false,
                dashboard2: state.dashboard2,
                filter: action.filterData,
                groups: state.groups,
                certifications: state.certifications,
                products: state.products,
                cloudServiceProduct: state.cloudServiceProduct
            };
        case GET_FRAMEWORKS:
            return {
                isLoading: false,
                dashboard2: state.dashboard2,
                filter: state.filter,
                products: state.products,
                groups: state.groups,
                certifications: state.certifications,
                cloudServiceProduct: state.cloudServiceProduct
            };
        case ADD_FRAMEWORKS:
            let newGroups: Group[] = [];
            let newDBState = { ...state };
            if (newDBState.dashboard2) {
                let dashboard = newDBState.dashboard2;
                dashboard.dashboard.push(action.data);
                for (let i = 0; i < dashboard.dashboard.length; i++) {
                    let grp = new Group();
                    grp.id = dashboard.dashboard[i].groupId;
                    grp.name = dashboard.dashboard[i].groupName;
                    if (!dashboard.dashboard[i].isArchived && newGroups.findIndex(k => k.name === grp.name) === -1) {
                        newGroups.push(grp);
                    }
                }
                newDBState.dashboard2 = getDeepCopy(dashboard);
                newDBState.groups = newGroups;
            }

            return newDBState;

        case RENAME_DASHBOARDGROUP:
            let newRenameState = {
                ...state,
                dashboard: {
                    ...state.dashboard2,
                    dashboard: state.dashboard2 && state.dashboard2.dashboard ? state.dashboard2.dashboard.slice() : null,
                }
            } as ComplianceDashboardState;
            if (newRenameState.dashboard2 && newRenameState.dashboard2.dashboard) {
                let index = newRenameState.dashboard2.dashboard.findIndex(d => d.id === action.tileId);
                let tile = newRenameState.dashboard2.dashboard[index];
                if (tile) {
                    tile.tileName = action.name;
                }
                return newRenameState;
            }

            return newRenameState;
        case GET_CLOUDSERVICEPRODUCT:
            return {
                isLoading: false,
                dashboard2: state.dashboard2,
                filter: state.filter,
                products: state.products,
                groups: state.groups,
                certifications: state.certifications,
                cloudServiceProduct: action.data
            };
    }

    return state || unloadedState;
};