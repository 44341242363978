import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PrimaryButton, ActionButton, IconButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Dialog, DialogFooter, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { Icon } from '../../Common/Components/Icon';
import * as React from 'react';
import { TableContextMenu } from '../../Common/Components/TableContextMenu';
import { VocabularyFilterDropdown } from '../../App/Components/VocabularyFilterDropdown';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { getLocalizedValue } from '../../Common/Utilities/LocalizationHelper';
import { LicenseAgreementPage } from '../Components/Licensing/LicenseAgreementPage';
import { DocumentVersionInfo } from '../Models';
import { SelectedVocabulary, VocabularyDefinition, VocabularyValueDefinition } from '../Models/Vocabulary';
import { MyLibraryDocument } from '../Reducers/MyLibrary';
import { Intl } from '../Services/GlobalizationService';
import {
    downloadFile,
    getDownloadDocumentLink,
    getVocabularyChoicesFromItem,
    filterItemsByVocabulary,
    getDocumentTypeAriaLabel,
    getFabricIconByExtension,
    getTableItemId,
    getDocumentDetailLink
} from '../Utilities/DocumentHelper';
import ShowMoreText from './Utility/ShowMoreText';
import { NoticationSettingsServiceClient } from '../Services/NotificationsSettingsService';
import { ErrorResponse } from '../Services/Models';
import { ScreenReaderAnnouncerClient } from '../Services/ScreenReaderAnnouncerService';
import './AdvancedDocumentTable.css';
import NotificationSettingsDialog from './NotificationSettingsDialog';
import * as authHelper from '../../Common/Utilities/AuthenticationHelper';
import { authContext } from '../../Common/Utilities/ADAuthenticate';
import { NotificationSettings } from '../Models/NotificationSettings';
import { ComplianceManagerConstants } from '../../Common/Models/ComplianceManagerConstants';
import { PageMetadata } from '../Models/PageInfrastructure';
import { TableRowCheckbox, FilterChoiceSummary } from './Table';
import { TableItem } from '../Models/TableItem';
import { isEmptyString } from '../Utilities/RenderUtilities';
import DocumentTypeDropdown from './DocumentTypeDropdown';
import DocumentDateDropdown from './DocumentDateDropdown';
import Collapsible from '../../Common/Components/Collapsible';
import Constants from '../Utilities/Constants';
import { DocumentDetails } from '../Models/NotificationSettings/DocumentDetails';
import { DocumentType } from '../Models/NotificationSettings/DocumentType';
import DocumentSeriesVersionsFlyout from './SeriesVersionsFlyout';
import { FocusTrapZone } from 'office-ui-fabric-react/lib/FocusTrapZone';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { store } from 'src/Store';

const HIDDEN_MOBILE_CLASSES = 'x-hidden-vp1';
const VISIBLE_MOBILE_CLASSES = 'x-hidden-vp3 x-hidden-vp4 x-hidden-vp5';

const ExtensionsByKey = {
    'Excel': ['.xls', '.xlsx'],
    'PDF': ['.pdf'],
    'PowerPoint': ['.ppt', '.pptx'],
    'Text': ['.txt'],
    'Word': ['.doc', '.docx'],
};

const numeric3Months = 3;
const numeric9Months = 9;
const numeric1Year = 12;
const numeric1PlusYear = 13;

// Define the properties that this component accepts as input and drives its behavior.
export interface AdvancedDocumentTableModel {
    myLibraryDocuments: MyLibraryDocument[];
    myLibraryLoaded: boolean;
    myLibraryPending: boolean;
    authContext: typeof authContext;
    isSignedIn: boolean;
    hasAgreedToCurrentLicenseAgreement: boolean;
}

export interface AdvancedDocumentTablePassthrough {
    caption: string;
    documents: DocumentVersionInfo[];
    vocabularies: VocabularyDefinition[];
    clientFilters?: SelectedVocabulary[];
    groupingInfo?: SelectedVocabulary[];
    filteringInfo?: SelectedVocabulary[];
    hideDescription: boolean;
    hideSeries: boolean;
    hideDate: boolean;
    hideDatePicker: boolean;
    hideDocType: boolean;
    hideVocabularies: boolean;
    hideSettings: boolean;
    hideRemoveFromLibrary: boolean;
    maxDownloads?: number;
    pages: PageMetadata[];
    parentId?: string;
    panelId?: string;
    showSettingsOnMount?: boolean;
    isInViewMode: boolean | undefined;
    firstTabName: string | null | undefined;
    noDocumentFoundText: string | null;
    noFilterResultText: string | null;
}

// Define the events that this component can raise.
export interface AdvancedDocumentTableActions {
    requestMyLibraryList: () => void;
    receivedMyLibraryList: (docList?: MyLibraryDocument[]) => void;
    errorMyLibraryList: (error: ErrorResponse) => void;
    requestAddToMyLibrary: (docs: MyLibraryDocument[]) => void;
    receivedAddToMyLibrary: () => void;
    errorAddToMyLibrary: (error: ErrorResponse) => void;
    requestRemoveFromMyLibrary: (doc: MyLibraryDocument) => void;
    receivedRemoveFromMyLibrary: () => void;
    errorRemoveFromMyLibrary: (error: ErrorResponse) => void;
}

// Define the merged set of properties and events that are exposed by this component.
export type AdvancedDocumentTableProps = AdvancedDocumentTableModel & AdvancedDocumentTablePassthrough & AdvancedDocumentTableActions
export interface AdvancedDocumentTableState {
    allItemsChecked: boolean;
    addDocumentsAfterConfirm?: MyLibraryDocument[];
    checkedRows: string[];
    confirmSettingsHeader: boolean;
    documentsToBeDownloaded: string[];
    index: number;
    notificationSettings: NotificationSettings | null;
    orderBy: { name: string, asc: boolean }; // title, description, date; true asc false desc
    pages: PageMetadata[];
    savingNotifications: boolean;
    selectedFilters: SelectedVocabulary[];
    showAgreement: boolean;
    showEditor: boolean;
    showWarningDialog: boolean;
    showSettingsDialog: boolean;
    showMobileFilters: boolean;
    filterDocumentMonthsRange: number | undefined;
    filterDocType: string[] | undefined;
    mustSelectTab: string;
    showSeriesVersionsFlyout: boolean;
    selectedSeriesId?: string;
    showAddToLibraryDialog: boolean;
    showRemoveFromLibraryDialog: boolean;
    selectedContextMenuDocument: TableItem | undefined;
    displayedChoiceGroup: string;
    ariaLiveString: string;
    removeFilterMessage: null | string;
    skipButton: boolean;
}

export const ContextActions = {
    ADDTOLIBRARY: 'ADDTOLIBRARY',
    BROWSER: 'BROWSER',
    DOWNLOAD: 'DOWNLOAD',
    REMOVEFROMLIBRARY: 'REMOVEFROMLIBRARY',
    VIEWALLVERSIONS: 'VIEWALLVERSIONS'
};

function formatDate(parsed: Date): string | undefined {
    const month = parsed.getMonth() + 1;
    const date = parsed.getDate();
    return parsed.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date;
}

export interface GroupedData {
    choiceId: string;
    key: string;
    children: GroupedData[];
    items: TableItem[];
    isSelected: boolean;
}

/**
 * This connected is responsible for displaying a sort-able, filter-able document table.  It is also connected to the
 * store and actions to allow it to add and remove documents from My Library and (eventually) adjust the users'
 * notification settings.
 */
export class AdvancedDocumentTable extends React.Component<AdvancedDocumentTableProps, AdvancedDocumentTableState> {
    private removeFilterMessageRef: React.RefObject<HTMLElement> = React.createRef();

    private itemToBeDownloaded?: TableItem;
    private settingsPending: boolean = false;
    private myLibraryPending: boolean = false;
    private hasDocumentsToShow: boolean = false;

    // Related with pivot tabs slide left and right
    private downloadTooltipRef: HTMLButtonElement | null;
    private tabsContainer: HTMLElement;
    private pivotContainer: HTMLElement;
    private tabTags: HTMLAnchorElement[];
    private prevButton: HTMLButtonElement;
    private nextButton: HTMLButtonElement;
    private hiddenWidth: number;
    private leftMostTabIndex: number;
    private skipVisible: boolean;

    constructor(props: AdvancedDocumentTableProps) {
        super(props);

        this.state = {
            allItemsChecked: false,
            addDocumentsAfterConfirm: undefined,
            checkedRows: [],
            confirmSettingsHeader: false,
            documentsToBeDownloaded: [] as string[],
            filterDocumentMonthsRange: undefined,
            filterDocType: undefined,
            index: 0,
            notificationSettings: null,
            orderBy: { name: 'date', asc: false },
            pages: [],
            savingNotifications: false,
            selectedFilters: [] as SelectedVocabulary[],
            showAgreement: false,
            showEditor: false,
            showSettingsDialog: false,
            showWarningDialog: false,
            showMobileFilters: false,
            mustSelectTab: this.getSelectTabInParameters(),
            showSeriesVersionsFlyout: false,
            selectedSeriesId: undefined,
            showAddToLibraryDialog: false,
            showRemoveFromLibraryDialog: false,
            selectedContextMenuDocument: undefined,
            displayedChoiceGroup: '',
            ariaLiveString: '',
            removeFilterMessage: null,
            skipButton: false,
        };

        this.dismissVersionsFlyout = this.dismissVersionsFlyout.bind(this);
        this._closeAddToLibraryDialog = this._closeAddToLibraryDialog.bind(this);
        this.renderAddToLibraryDialog = this.renderAddToLibraryDialog.bind(this);
        this.renderRemoveFromLibraryDialog = this.renderRemoveFromLibraryDialog.bind(this);

        // Related with pivot tabs slide left and right
        this.hiddenWidth = 0;
        this.leftMostTabIndex = 0;
        this.skipVisible = false;
    }

    public componentWillMount() {
        // make a request to load the current my library list
        if (this.props.isSignedIn) {
            if (!this.props.myLibraryLoaded) { this.fetchLibrary(); }
            if (!this.state.notificationSettings) { this.fetchNotificationSettings(); }
        }
    }

    public componentDidMount() {
        if (this.props.showSettingsOnMount === true) {
            this.setState({
                showSettingsDialog: true
            });
        }
        this.loadScrollButton();
        window.addEventListener('resize', () => this.updateTabScrollButtons());
        window.addEventListener('keydown', this.handleOnKeyDown);
    }

    public componentWillUnmount() {
        window.removeEventListener('keydown', this.handleOnKeyDown);
    }

    public componentDidUpdate() {
        this.updateTabScrollButtons();
    }

    private handleOnKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            this.toggleAndFocusSkipButton();
        }
    }

    private toggleAndFocusSkipButton() {
        this.skipVisible = !this.skipVisible;
        this.setState({ skipButton: this.skipVisible });
        if (this.skipVisible) {
            const skipLink = (document.getElementById('skipTable') as HTMLElement);
            skipLink.focus();
        }
    }

    public render() {
        this.tabTags = [];
        let tabValue = -1;
        this.hasDocumentsToShow = true;

        // load the current items, filter them by saved filters, then user-selected filters, then sort
        const items = this.getItemsToRender();
        let groupedItems = this.groupItems(items, this.props.groupingInfo);
        groupedItems = this.setGroupFirstTab(groupedItems);
        const clientFilteredItems = this.clientFilteredItems(items);


        if (clientFilteredItems.length === 0) {
            this.hasDocumentsToShow = items.length > 0;
        }

        const { isSignedIn } = this.props;
        const { selectedFilters, showMobileFilters, filterDocType, filterDocumentMonthsRange, skipButton } = this.state;


        return (
            <div className="advancedDocs m-advanced-docs" data-grid="col-12 stack-3">
                <div className={HIDDEN_MOBILE_CLASSES} data-grid="col-12">
                    {this.renderFilterList()}
                </div>

                <div className={`${VISIBLE_MOBILE_CLASSES}`} data-grid="col-12">
                    <div className="advancedDocs__mobile-filters">
                        <div>
                            <ActionButton
                                className="advancedDocs__mobile-filter-button"
                                onClick={this.showMobileFilters}
                                iconProps={{ iconName: 'Equalizer', className: 'advancedDocs__mobile-filter-button-icon' }}
                            >
                                {Intl.Get(LocIds.PageAndPanels.FilterButton)}
                            </ActionButton>
                        </div>
                        <div>
                            {this.renderDownloadAll()}
                            {this.renderItemCount(groupedItems)}
                        </div>
                    </div>
                </div>

                {showMobileFilters && (
                    <FocusTrapZone>
                        <div
                            ref={(ref) => ref && ref.focus()}
                            aria-label={Intl.Get(LocIds.PageAndPanels.FiltersModal)}
                            className="advancedDocs__mobile-filter-modal"
                        >
                            <div className="advancedDocs__mobile-filter-header">
                                <IconButton
                                    ariaLabel={Intl.Get(LocIds.PageAndPanels.CloseFilters)}
                                    title={Intl.Get(LocIds.PageAndPanels.CloseFilters)}
                                    iconProps={{ iconName: 'ChromeClose' }}
                                    onClick={this.hideMobileFilters}
                                />
                                {Intl.Get(LocIds.PageAndPanels.FilterButton)}
                            </div>
                            <div className="advancedDocs__mobile-filter-body">
                                {this.renderFilterList()}
                            </div>
                            <div className="advancedDocs__mobile-filter-footer">
                                {(items.length > 0) ? (
                                    <PrimaryButton text={`See ${items.length} Documents`} onClick={this.hideMobileFilters} />
                                ) : (
                                    this.renderNoResults()
                                )}
                            </div>
                        </div>
                    </FocusTrapZone>
                )}

                {(selectedFilters.length > 0 || filterDocumentMonthsRange || (filterDocType && filterDocType.length > 0)) &&
                    <div data-grid="col-12">
                        {this.renderChoiceSummary()}
                        <div id="content" aria-live="assertive">
                            {this.state.ariaLiveString}
                        </div>
                        <button className="advancedDocs__clear-filters c-hyperlink" onClick={this._clearSelectedFilters}>
                            {Intl.Get(LocIds.PageAndPanels.ClearFilters)}
                        </button>
                    </div>
                }

                {this.renderFilterResultAriaLiveString(clientFilteredItems, groupedItems)}

                <div className={`advancedDocs__meta ${HIDDEN_MOBILE_CLASSES}`} data-grid="col-12" style={{ marginTop: '10px' }}>
                    <div className={`c-paragraph-4`}>
                        {this.renderItemCount(groupedItems)}
                        {!isSignedIn && (
                            <div className="advancedDocs__call-to-action advancedDocs__sign-in">
                                {this.createHintMarkup(
                                    Intl.Get(LocIds.PageAndPanels.SignInCallToActionText),
                                    linkText => (
                                        <a
                                            href="#"
                                            onClick={() => this.signIn()}
                                            className="c-hyperlink"
                                        >
                                            <Icon iconName={'Lock'} style={{ width: '16px', height: '16px' }} /> {linkText}
                                        </a>
                                    )
                                )}
                            </div>
                        )}

                        {this.renderTableActions()}
                    </div>
                </div>

                <div className="advancedDocs__call-to-action to-left"> {skipButton &&
                    (<button id="skipTable" onClick={this.skipToGiveFeedback} tabIndex={0} className="c-button" onBlur={this.toggleAndFocusSkipButton} > {Intl.Get(LocIds.PageAndPanels.SkipTable)} </button>)}
                </div>

                <div className="table f-divided" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending" data-grid="col-12" style={{ overflow: 'hidden', minWidth: '1000px' }}>
                    {
                        (groupedItems.length > 0 && groupedItems.findIndex(x => x.key === '') === -1) ?
                            this.props.isInViewMode ?
                                <div>
                                    <div className="c-pivot" style={{ overflow: 'hidden' }} ref={(pivotContainer: HTMLDivElement) => this.pivotContainer = pivotContainer}>
                                        <button
                                            className="tabscroll tabscroll-prev"
                                            aria-label="Show prev"
                                            ref={(prev: HTMLButtonElement) => this.prevButton = prev}
                                            style={{ display: 'none' }}
                                            onClick={() => {
                                                if (this.leftMostTabIndex > 0) {
                                                    this.hiddenWidth -= (this.tabTags[--this.leftMostTabIndex].offsetWidth + 24);
                                                    if (this.hiddenWidth < 0) {
                                                        this.hiddenWidth = 0;
                                                    }
                                                    this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';
                                                    if (this.leftMostTabIndex === 0) {
                                                        this.prevButton.style.display = 'none';
                                                        this.tabsContainer.style.paddingLeft = '0px';
                                                    } else {
                                                        this.tabsContainer.style.paddingLeft = '40px';
                                                    }
                                                    if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                                        this.nextButton.style.display = 'none';
                                                    } else {
                                                        this.nextButton.style.display = 'inline-block';
                                                    }
                                                }
                                            }}
                                        />
                                        <header role="tablist" ref={(tabsContainer: HTMLElement) => this.tabsContainer = tabsContainer} className="tabsContainer tabAnimation">
                                            {groupedItems.map((data, index) =>
                                                <a
                                                    key={index}
                                                    onClick={() => this.handlePivotTabClick(index, data.choiceId)}
                                                    onKeyDown={(e) => this.handlePivotTabKeyDown(e, index, data.choiceId)}
                                                    role="tab"
                                                    tabIndex={tabValue + 1}
                                                    className={data.isSelected ? 'f-active' : 'notSelected-focus'}
                                                    aria-selected={data.isSelected ? 'true' : 'false'}
                                                    aria-controls={data.key.replace(/\s/g, '_')}
                                                    ref={(tabTag: HTMLAnchorElement) => this.tabTags[index] = tabTag}
                                                >
                                                    {data.key}
                                                </a>
                                            )}
                                        </header>
                                        <button
                                            className="tabscroll tabscroll-next"
                                            aria-label="Show next"
                                            ref={(next: HTMLButtonElement) => this.nextButton = next}
                                            onClick={() => {
                                                if (this.leftMostTabIndex < this.tabTags.length - 1) {
                                                    this.prevButton.style.display = 'inline-block';
                                                    this.tabsContainer.style.paddingLeft = '40px';

                                                    this.hiddenWidth += (this.tabTags[this.leftMostTabIndex++].offsetWidth + 24);
                                                    this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';

                                                    if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                                        this.nextButton.style.display = 'none';
                                                    } else {
                                                        this.nextButton.style.display = 'inline-block';
                                                    }
                                                }
                                            }}
                                        />

                                        {groupedItems.map((data, index) =>
                                            <section key={index} id={data.key.replace(/\s/g, '_')} role="tabpanel" aria-hidden={data.isSelected ? 'false' : 'true'} style={{ clear: 'both' }}>
                                                {data.children.length > 0 ?
                                                    data.children.map((row, i) =>
                                                        <div key={row.key.concat(i.toString())} style={{ marginLeft: '12px' }}>
                                                            <Collapsible
                                                                trigger={<div>{row.key}<span className={'customCounterDocs'}>{row.items.length.toString().concat(' Document').concat(row.items.length > 1 ? 's' : '')}</span></div>}
                                                                classParentString="fdCollapsible"
                                                            >
                                                                <div className="c-table f-divided clearBoth" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending" style={{ minWidth: '1000px' }}>
                                                                    <table aria-label={this.props.caption} className="advancedDocs__table" data-f-sort="true" id="tableId">
                                                                        {this.getTableHeader(data.key + index.toString())}
                                                                        <tbody>{row.items.map((item, rowIndex) => this.getTableRow(item, rowIndex, data))}</tbody>
                                                                    </table>
                                                                </div>
                                                            </Collapsible>
                                                        </div>
                                                    )
                                                    :
                                                    <div>
                                                        <div className="c-table f-divided clearBoth" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending" style={{ minWidth: '1000px' }}>
                                                            <table aria-label={this.props.caption} className="advancedDocs__table" data-f-sort="true" id="tableId">
                                                                {this.getTableHeader(data.key + index.toString())}
                                                                {data.items.length > 0 && <tbody>{data.items.map((item, rowIndex) => this.getTableRow(item, rowIndex, data))}</tbody>}
                                                            </table>
                                                        </div>
                                                        {data.items.length === 0 && this.renderNoResults()}
                                                    </div>
                                                }
                                            </section>)}
                                    </div>
                                </div>
                                : groupedItems.map((data, index) =>
                                    <div key={data.key.concat(index.toString())} onKeyDown={(e) => this.handlePivotTabKeyDown(e, index, data.choiceId)}>
                                        <Collapsible
                                            trigger={<div>{data.key}<span className={'customCounterDocs'}>{data.items.length.toString().concat(' Document').concat(data.items.length > 1 ? 's' : '')}</span></div>}
                                            classParentString="fdCollapsible"
                                        >
                                            {data.children.length > 0 ?
                                                data.children.map((row, i) =>
                                                    <div key={row.key.concat(i.toString())} style={{ marginLeft: '12px' }}>
                                                        <Collapsible
                                                            trigger={<div>{row.key}<span className={'customCounterDocs'}>{row.items.length.toString().concat(' Document').concat(row.items.length > 1 ? 's' : '')}</span></div>}
                                                            classParentString="fdCollapsible"
                                                        >
                                                            <div className="c-table f-divided" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending" style={{ minWidth: '1000px' }}>
                                                                <table aria-label={this.props.caption} className="advancedDocs__table" data-f-sort="true" id="tableId">
                                                                    {this.getTableHeader(data.key + index.toString())}
                                                                    <tbody>{row.items.map((item, index0) => this.getTableRow(item, index0))}</tbody>
                                                                </table>
                                                            </div>
                                                        </Collapsible>
                                                    </div>
                                                )
                                                :
                                                <div className="c-table f-divided" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending" style={{ minWidth: '1000px' }}>
                                                    <table aria-label={this.props.caption} className="advancedDocs__table" data-f-sort="true" id="tableId">
                                                        {this.getTableHeader(data.key + index.toString())}
                                                        <tbody>{data.items.map((item, index1) => this.getTableRow(item, index1))}</tbody>
                                                    </table>
                                                </div>
                                            }
                                        </Collapsible>
                                    </div>)
                            :
                            (clientFilteredItems.length > 0) ? (
                                <table aria-label={this.props.caption} className="advancedDocs__table" data-f-sort="true" id="tableId">
                                    {this.getTableHeader(items[0].documentId)}
                                    <tbody>{clientFilteredItems.map((item, index) => this.getTableRow(item, index))}</tbody>
                                </table>
                            ) : (
                                this.renderNoResults()
                            )
                    }

                </div>

                {this.state.showAgreement &&
                    <LicenseAgreementPage
                        onAgree={() => {
                            this.setState({ showAgreement: false });
                            const document = this.itemToBeDownloaded;
                            if (document) {
                                this.itemToBeDownloaded = undefined;
                                this.downloadDocument(document);
                            }
                        }}
                        onDismiss={() => {
                            this.itemToBeDownloaded = undefined;
                            this.setState({ showAgreement: false });
                        }}
                        callBack={() => {
                            this.itemToBeDownloaded = undefined;
                            this.setState({ showAgreement: false });
                        }}
                    />}

                {(this.state.showSeriesVersionsFlyout && this.state.selectedSeriesId) &&
                    <DocumentSeriesVersionsFlyout
                        seriesId={this.state.selectedSeriesId}
                        onDismissPanel={this.dismissVersionsFlyout}
                        isSignedIn={this.props.isSignedIn}
                        hasAgreedToCurrentLicenseAgreement={this.props.hasAgreedToCurrentLicenseAgreement}
                    />
                }
                {this.state.showWarningDialog && this.renderConfirmationDialog()}
                {this.state.showAddToLibraryDialog && this.renderAddToLibraryDialog()}
                {this.state.showRemoveFromLibraryDialog && this.renderRemoveFromLibraryDialog()}

                {(this.state.showSettingsDialog && this.state.notificationSettings !== null) && (
                    /* We want to unmount this after it closes so we can set the defaults to the new settings */
                    <NotificationSettingsDialog
                        confirmSettingsHeader={this.state.confirmSettingsHeader}
                        saving={this.state.savingNotifications}
                        currentSettings={this.ensureDefaultEmail(this.state.notificationSettings)}
                        hidden={!this.state.showSettingsDialog}
                        onConfirm={this._onNewSettings}
                        onCancel={this._onCancelSettings}
                        options={[]}
                    />
                )}
            </div>
        );
    }

    private handlePivotTabClick = (index: number, choiceId: string) => {
        this.setState({ index: index, mustSelectTab: choiceId, orderBy: { name: 'date', asc: false }, allItemsChecked: false, checkedRows: [] });
        document.title = Intl.Get(LocIds.PageAndPanels.AuditReportsLabel) + '-' + choiceId;
    }


    private handlePivotTabKeyDown = (e: React.KeyboardEvent<HTMLElement>, index: number, choiceId: string) => {
        if (e.key === 'Enter') {
            this.handlePivotTabClick(index, choiceId);
        }
    }

    private showMobileFilters = () => {
        this.setState({ showMobileFilters: true });
    }

    private hideMobileFilters = () => {
        this.setState({ showMobileFilters: false });
    }

    private ensureDefaultEmail = (settings: NotificationSettings): NotificationSettings => {
        const { authContext: { user } } = this.props;

        if (
            settings.emails.length === 0 ||
            (settings.emails.length > 0 && isEmptyString(settings.emails[0]))
        ) {
            return {
                ...settings,
                emails: [user.userName]
            };
        }

        return settings;
    }

    private dismissVersionsFlyout() {
        this.setState({
            showSeriesVersionsFlyout: false
        });
    }

    private renderNoResults = () => {
        let noResult: string = '';

        if (this.hasDocumentsToShow) {
            noResult = this.props.noFilterResultText || Intl.Get(LocIds.PageAndPanels.NoFilterResults);
        } else {
            noResult = this.props.noDocumentFoundText || Intl.Get(LocIds.PageAndPanels.NoDocuments);
        }

        return (
            <div>{noResult}</div>
        );
    }

    private renderItemCount = (groupedItems: GroupedData[]) => {
        //TODO: Disable this for now till we decide how we are counting the records.
        return;

        if (groupedItems.length <= 0) {
            return;
        }
        let current: number = 0;
        let total: number = 0;

        if (groupedItems.findIndex(x => x.key === '') === -1) {
            groupedItems.forEach(c => {
                total += c.items.length;
            });

            current = groupedItems[this.state.index].items.length;
        } else {
            current = groupedItems[0].items.length;
            total = groupedItems[0].items.length;

        }

        if (current > 0) {
            return (
                <div>
                    {Intl.Get(LocIds.PageAndPanels.TableFilterItemCount, { current, total })}
                </div>
            );
        }

        return undefined;
    }

    private renderDownloadAll = () => {

        const starterPack = this.getStarterPackZip();

        return (starterPack && starterPack.documentId) && (
            <button className="advancedDocs__download-all c-button" key="download" onClick={() => this.downloadSummary(starterPack.documentId)}>
                <Icon iconName="Download" style={{ color: '#005da6', fontSize: '14px' }} />
                {Intl.Get(LocIds.PageAndPanels.DownloadAll)}
            </button>
        );
    }

    private createHintMarkup = (value: string, formatter: (s: string) => JSX.Element | undefined): JSX.Element[] | string[] => {
        const tokens = value.match(/__[^_]+__/ig);

        if (tokens === null) { return [value]; }

        const token = tokens[0];

        const strings = value.split(token);
        const linkText = token.replace(/_/g, ''); // remove all the underscores

        let nodes: (JSX.Element | string | undefined)[] = [];

        for (let i = 0; i < strings.length; i++) {
            nodes.push(<span key={'str' + i.toString()}>{strings[i]}</span>);

            if (i < (strings.length - 1)) {
                nodes.push(<span key={'lnk' + i.toString()}>{formatter(linkText)}</span>);
            }
        }

        return nodes as JSX.Element[];
    }

    public _onNewSettings = (settings: NotificationSettings) => {
        this.setState({ savingNotifications: true });

        NoticationSettingsServiceClient.postNotificationSettings(
            settings,
            (response) => {
                this.fetchNotificationSettings(() => {
                    const docs = this.state.addDocumentsAfterConfirm;
                    this.setState({ savingNotifications: false, showSettingsDialog: false });

                    if (docs !== undefined) {
                        this.props.requestAddToMyLibrary(docs);

                        const newDocList = this.props.myLibraryDocuments.map((lib) => {
                            const document: DocumentDetails = {
                                id: lib.documentId,
                                documentType: lib.documentType,
                                contentHash: lib.contentHash
                            };
                            return document;
                        }).concat(
                            docs.map(doc => {
                                const document: DocumentDetails = {
                                    id: doc.documentId,
                                    documentType: doc.documentType,
                                    contentHash: doc.contentHash
                                };
                                return document;
                            })
                        );

                        NoticationSettingsServiceClient.postNotificationDocument(
                            newDocList,
                            () => {
                                this.props.receivedAddToMyLibrary();
                                this.setState({
                                    addDocumentsAfterConfirm: undefined
                                });
                            },
                            (error) => {
                                this.props.errorAddToMyLibrary(error);
                            }
                        );
                    }
                });
            },
            (error) => {
                // TODO: handle errors
            }
        );
    }

    private _onCancelSettings = () => {
        this.setState({ showSettingsDialog: false });
    }

    private renderFilterList = () => {
        const { selectedFilters, filterDocumentMonthsRange, filterDocType } = this.state;
        const { vocabularies, clientFilters, hideDatePicker, hideDocType, hideVocabularies } = this.props;
        const clientFilterNames: string[] = clientFilters ? clientFilters.map((filter) => filter.vocabulary) : [];

        return (

            <div className="advancedDocs__filter-list">
                {true !== hideDatePicker && (
                    <div key={`filterDocumentDate`} className="advancedDocs__filter-list-item">
                        <DocumentDateDropdown
                            className="filterSelector"
                            key={'docDateFilter'}
                            filter={filterDocumentMonthsRange || []}
                            dropdownWidth={180}
                            onSelectionChanged={this.onSelectDocumentDate}
                            options={[
                                {
                                    key: numeric3Months,
                                    text: Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: numeric3Months }),
                                    disabled: false
                                },
                                {
                                    key: numeric9Months,
                                    text: Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: numeric9Months }),
                                    disabled: false
                                },
                                {
                                    key: numeric1Year,
                                    text: Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: numeric1Year }),
                                    disabled: false
                                },
                                {
                                    key: numeric1PlusYear,
                                    text: Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: '12+' }),
                                    disabled: false
                                }
                            ]}
                        />
                    </div>
                )}

                {true !== hideDocType && (
                    <div key={`filterDocumentType`} className="advancedDocs__filter-list-item">
                        <DocumentTypeDropdown
                            className="filterSelector"
                            key={'docTypeFilter'}
                            filter={filterDocType || []}
                            dropdownWidth={180}
                            onSelectionChanged={this.onDocTypeChanged}
                            options={[]}
                        />
                    </div>
                )}

                {vocabularies && true !== hideVocabularies &&
                    <div style={{ display: 'inline' }}>
                        {vocabularies.map(vocab =>
                        ((clientFilterNames.indexOf(vocab.name) >= 0) && (
                            <div key={`filter${vocab.name}`} className="advancedDocs__filter-list-item">
                                <VocabularyFilterDropdown
                                    className="filterSelector"
                                    key={vocab.id}
                                    vocabulary={vocab}
                                    selectedFilters={selectedFilters}
                                    dropdownWidth={180}
                                    onSelectionChanged={this.onFilterChanged}
                                />
                            </div>
                        ))
                        )}
                    </div>
                }

                {(this.props.isSignedIn && this.state.notificationSettings !== null && true !== this.props.hideSettings) && (
                    <div className="advancedDocs__notification-action">
                        <a
                            role="button"
                            tabIndex={0}
                            onClick={this.handleNotificationSettingsOnClick}
                            onKeyDown={(e) => this.handleNotificationSettingsOnKeyDown(e)}
                        >
                            {Intl.Get(LocIds.PageAndPanels.NotificationSettingsButtonText)}
                        </a>
                    </div>
                )}

            </div>
        );
    }

    private handleNotificationSettingsOnClick = () => {
        this.setState({ showSettingsDialog: true, confirmSettingsHeader: false });
    }

    private handleNotificationSettingsOnKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleNotificationSettingsOnClick();
        }
    }

    private renderChoiceSummary = () => {
        const { selectedFilters, filterDocumentMonthsRange, filterDocType } = this.state;

        return (
            <>
                <p ref={this.removeFilterMessageRef} className='removeFilterMessage' aria-label={this.state.removeFilterMessage} tabIndex={this.state.removeFilterMessage ? 0 : -1}></p>
                <ul className="c-group f-wrap-items advancedDocs__filters" style={{ display: 'inline-flex' }}>


                    {selectedFilters.map((filter) => {
                        const joinedArray = filter.choice.map((item, index) => ({ choice: item, text: filter.choiceDisplay[index] }));
                        return joinedArray.map((choice) =>
                            <FilterChoiceSummary
                                className="filterChoiceSummary"
                                key={choice.choice}
                                choice={choice.choice}
                                text={choice.text}
                                filter={filter}
                                onRemove={this._onRemoveSelectedFilter}
                            />
                        );
                    })}
                    {filterDocumentMonthsRange && (
                        <FilterChoiceSummary
                            className="filterChoiceSummary"
                            key="start-date"
                            text={filterDocumentMonthsRange === numeric1PlusYear ?
                                Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: '12+' })
                                : Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: filterDocumentMonthsRange })}
                            onRemove={this.onRemoveStartDate}
                        />
                    )}
                    {filterDocType && (
                        filterDocType.map((type) => (
                            <FilterChoiceSummary
                                className="filterChoiceSummary"
                                key={`document-type-${type}`}
                                text={`${type} Document`}
                                onRemove={() => { this.onRemoveDocType(type); }}
                            />
                        ))
                    )}
                </ul>
            </>
        );
    }

    private renderFilterResultAriaLiveString = (
        clientFilteredItems: TableItem[],
        groupedItems: GroupedData[]
    ) => {
        const { selectedFilters, filterDocType, filterDocumentMonthsRange } =
            this.state;

        // e.g. Cloud Service Type filter Azure, Cloud Service Type filter LinkedIn and Industries filter Banking
        const selectedFiltersDic = selectedFilters.reduce(
            (acc: Record<string, string[]>, cur: SelectedVocabulary) => {
                const origin: string[] = acc[cur.vocabulary] || [];
                acc[cur.vocabulary] = [...origin, ...cur.choiceDisplay];
                return acc;
            },
            {} as Record<string, string[]>
        );
        const selectedFiltersString = Object.keys(selectedFiltersDic)
            .map((k) => selectedFiltersDic[k].map(v => `${k} Type filter ${v}`).join(', '))
            .join(', ');

        // e.g. PDF Document Type filter, Excel Document Type filter
        const filterDocTypeString = filterDocType ? filterDocType.map((f) => `${f} Document Type filter`).join(', ') : undefined;

        // e.g. Date filter Last 90 days
        const filterDocumentDateString = filterDocumentMonthsRange
            ? filterDocumentMonthsRange === numeric1PlusYear ?
                Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: '12+' })
                : Intl.Get(LocIds.PageAndPanels.DateFilterLabel, { numberofmonths: filterDocumentMonthsRange })
            : undefined;

        const filterString = [
            selectedFiltersString,
            filterDocTypeString,
            filterDocumentDateString,
        ]
            .filter((v) => !!v)
            .join(', ');

        var filteredDocCount = clientFilteredItems.length;
        var selectedItem = groupedItems.find(g => g.isSelected && g.key !== '');
        if (selectedItem) {
            filteredDocCount = selectedItem.items.length;
        }

        return (
            <div aria-live="assertive">
                {filterString && Intl.Get(LocIds.PageAndPanels.FiltersAppliedText, {
                    filter: filterString,
                    count: filteredDocCount,
                })}
            </div>
        );
    };

    public signIn = () => {
        authHelper.login(this.props.authContext);
    }

    public skipToGiveFeedback = () => {
        const giveFeedback = (document.getElementById('feedbackButton') as HTMLElement);
        giveFeedback.focus();
    }

    public fetchLibrary = () => {
        if (
            this.props.myLibraryLoaded === true ||
            this.props.myLibraryPending === true ||
            this.myLibraryPending === true
        ) { return; }

        this.myLibraryPending = true;

        this.props.requestMyLibraryList();

        NoticationSettingsServiceClient.getNotificationDocuments(
            (response: DocumentVersionInfo[]) => {
                const libraryDocs = response.map((doc: DocumentVersionInfo): MyLibraryDocument => {
                    let docId = doc.documentType === DocumentType.Series ? doc.seriesId : doc.documentId;
                    return { documentId: docId, documentType: doc.documentType };
                });

                this.myLibraryPending = false;
                this.props.receivedMyLibraryList(libraryDocs);
            },
            (error) => {
                this.myLibraryPending = false;
                this.props.errorMyLibraryList(error);
            }
        );
    }

    public fetchNotificationSettings = (callback?: () => void) => {
        if (this.settingsPending) {
            return;
        }

        this.settingsPending = true;

        NoticationSettingsServiceClient.getNotificationSettings(
            (notificationSettings: NotificationSettings) => {
                this.setState({ notificationSettings });
                this.settingsPending = false;
                if (callback) { callback(); }
            },
            (error) => {
                this.settingsPending = false;
            }
        );
    }


    /**
     * This method will dispatch the actions around requesting an addition
     * to the library and make the call to the service client itself.
     */
    public addToLibrary = (docs: MyLibraryDocument[]) => {

        const newDocList = this.props.myLibraryDocuments.map((lib) => {
            const document: DocumentDetails = {
                id: lib.documentId,
                documentType: lib.documentType,
                contentHash: lib.contentHash
            };
            return document;
        }).concat(
            docs.map(doc => {
                const document: DocumentDetails = {
                    id: doc.documentId,
                    documentType: doc.documentType,
                    contentHash: doc.contentHash
                };
                return document;
            })); // all the current docs plus all the new docs
        const initialDocs = this.props.myLibraryDocuments.length || 0;

        if (initialDocs === 0) {
            this.setState({
                showSettingsDialog: true,
                confirmSettingsHeader: true,
                addDocumentsAfterConfirm: docs
            });
            return;
        }

        this.props.requestAddToMyLibrary(docs);

        ScreenReaderAnnouncerClient.setAnnouncement(Intl.Get(LocIds.PageAndPanels.SavedToLibraryAnnouncement), true);
        NoticationSettingsServiceClient.postNotificationDocument(
            newDocList,
            () => {
                this.props.receivedAddToMyLibrary();
            },
            (error) => {
                this.props.errorAddToMyLibrary(error);
            }
        );
    }

    private removeFromLibrary = (docs: MyLibraryDocument[]) => {
        for (const doc of docs) {
            // fire an action per doc to remove from the redux store
            this.props.requestRemoveFromMyLibrary(doc);
        }

        let documents = docs.map(doc => {
            let document: DocumentDetails = {
                id: doc.documentId,
                documentType: doc.documentType,
                contentHash: doc.contentHash
            };
            return document;
        });
        NoticationSettingsServiceClient.deleteNotificationDocument(
            documents,
            () => {
                this.props.receivedRemoveFromMyLibrary();
                this.setState({ ariaLiveString: Intl.Get(LocIds.PageAndPanels.RemovedFromLibraryMessage) });
            },
            (error) => {
                this.props.errorRemoveFromMyLibrary(error);
            }
        );
    }

    private onDocTypeChanged = (selectedItems: string[]) => {
        if (0 === selectedItems.length) {
            this.setState({
                allItemsChecked: false,
                filterDocType: undefined,
                ariaLiveString: ''
            });
        } else {
            this.setState({
                allItemsChecked: false,
                filterDocType: selectedItems,
                ariaLiveString: ''
            });
        }
    }

    private onFilterChanged = (vocabulary: VocabularyDefinition | undefined, selectedItems: VocabularyValueDefinition[]) => {
        const { selectedFilters } = this.state;
        const newFilters = [...selectedFilters];

        if (!vocabulary) { return; }

        const newSelectedVocabulary: SelectedVocabulary = {
            choice: selectedItems.map((item) => item.id),
            choiceDisplay: selectedItems.map((item) => getLocalizedValue(item.displayValues, false)),
            shouldExcludeValueOfAll: false,
            vocabulary: vocabulary.name,
        };

        if (0 === selectedItems.length) {
            // we need to remove the selected vocabulary from the current filter list entirely
            const index = selectedFilters.findIndex((filter) => filter.vocabulary === vocabulary.name);
            if (index >= 0) {
                newFilters.splice(index, 1);
            }
        } else {
            const index = selectedFilters.findIndex((filter) => filter.vocabulary === vocabulary.name);
            if (index === -1) {
                newFilters.push(newSelectedVocabulary);
            } else {
                newFilters[index] = newSelectedVocabulary;
            }
        }

        this.setState({
            allItemsChecked: false,
            selectedFilters: newFilters,
            ariaLiveString: ""
        });
    }

    private downloadSummary = (documentId: string): void => {
        let items = this.getBaseItems().filter((item) => (item.documentId === documentId));

        if (items && items.length > 0) {
            this.downloadDocument(items[0]);
        }
    }

    private multiSelectAction = (action: string): void => {
        const { isSignedIn } = this.props;
        const { checkedRows } = this.state;
        let items = this.getBaseItems();
        // only keep documents that we've explicitly checked
        items = items.filter((item) => checkedRows.indexOf(getTableItemId(item)) !== -1);
        if (ContextActions.DOWNLOAD === action) {
            if (items.length > (this.props.maxDownloads || Constants.DOCUMENT_DOWNLOAD_MAX)) {
                if (this.downloadTooltipRef) {
                    this.downloadTooltipRef.focus();
                }
                return; // we don't want to deselect and take the rest of these actions...
            } else {
                items.forEach((item: TableItem) => {
                    this.downloadDocument(item);
                });
            }
        } else if (ContextActions.ADDTOLIBRARY === action) {
            if (!isSignedIn) {
                this.setState({ showAgreement: true });
            } else {
                var hasSeries = items.filter((item) => item.documentType === DocumentType.Series).length > 0;
                this.setState({ showAddToLibraryDialog: true, displayedChoiceGroup: (hasSeries ? 'Series' : '') });
            }
        } else if (ContextActions.REMOVEFROMLIBRARY === action) {
            if (!isSignedIn) {
                this.setState({ showAgreement: true });
            } else {
                items.map(item => ({ documentId: getTableItemId(item), documentType: item.documentType }));
                var hasSeries = items.filter((item) => item.documentType === DocumentType.Series).length > 0;
                this.setState({ showRemoveFromLibraryDialog: true, displayedChoiceGroup: (hasSeries ? 'Series' : '') });
            }
        } else {
            throw new Error(`Don't know how to ${action} on ${items.map((item) => item.documentId).join(', ')}`);
        }
    }

    private _clearSelectedFilters = (ev?: React.MouseEvent<HTMLElement>) => {
        if (ev) { ev.preventDefault(); }
        ScreenReaderAnnouncerClient.setAnnouncement('Cleared document filters', true);
        this.setState(
            {
                filterDocumentMonthsRange: undefined,
                filterDocType: undefined,
                selectedFilters: [],
                ariaLiveString: Intl.Get(LocIds.PageAndPanels.FiltersClearedMessage)
            },
            this.setFocusOnFilterRemove
        );
    }

    private _onRemoveSelectedFilter = (choice: string, text: string, filter: SelectedVocabulary) => {
        const { vocabularies } = this.props;
        const { selectedFilters } = this.state;
        const newSelectedFilters = [...selectedFilters];

        if (!vocabularies || !selectedFilters) { return; }

        const index = newSelectedFilters.findIndex((f) => f.vocabulary === filter.vocabulary);
        if (index < 0) { return; } // we don't have that vocabulary in the current selectedFilters

        const choiceIndex = newSelectedFilters[index].choice.indexOf(choice);
        if (choiceIndex >= 0) {
            // remove this 'choice' value
            newSelectedFilters[index].choice.splice(choiceIndex, 1);
        }

        const textIndex = newSelectedFilters[index].choiceDisplay.indexOf(text);
        if (textIndex >= 0) {
            // remove this 'choiceDisplay' value
            newSelectedFilters[index].choiceDisplay.splice(textIndex, 1);
        }

        // if there are no active choices in this selectedvocabulary then remove it entirely
        if (newSelectedFilters[index].choice.length === 0) {
            newSelectedFilters.splice(index, 1);
        }
        this.setState({ selectedFilters: newSelectedFilters, removeFilterMessage: text + ' removed' }, this.setFocusOnFilterRemove);
    }

    private setFocusOnFilterRemove = () => {
        // After filter is removed narrator should confirm that filter is removed and that message is stored in this element
        const removeMessage = document.querySelector('.removeFilterMessage')

        if (removeMessage) {
            // Narrator will read aria-label of this element
            (removeMessage as HTMLElement).focus();
            // Once focus is gone remove message will be restarted
            removeMessage.addEventListener('blur', () => this.setState({ removeFilterMessage: null }))
        }
    }

    public onSelectDocumentDate = (key: number | undefined): void => {
        this.setState({ filterDocumentMonthsRange: key });
    }

    public onRemoveStartDate = () => {
        this.setState({ filterDocumentMonthsRange: undefined }, this.setFocusOnFilterRemove);
    }

    private onRemoveDocType = (type: string) => {
        const currentFilter = this.state.filterDocType || [];
        this.setState({ filterDocType: currentFilter.filter(f => f !== type) }, this.setFocusOnFilterRemove);
    }

    /**
     * This method renders the table header row in a <thead>
     *
     * @param id a unique key for the header elements
     * @return TSX
     */
    private getTableHeader = (id: string) => {
        const { allItemsChecked } = this.state;
        const { hideDescription, hideDate } = this.props;

        return (
            <thead>
                <tr>
                    <th className={`advancedDocs__column-header advancedDocs__column-header--checkbox `}>
                        <Checkbox
                            ariaLabelledBy="Check all rows"
                            ariaLabel='Check all rows'
                            className="advancedDocs__checkbox"
                            checked={allItemsChecked}
                            onChange={this.onAllItemsCheckedChange}
                        />
                    </th>
                    {/* if "Description" is hidden this is 3-columns */}
                    <th
                        id={'documentTitleColumnHeader' + id}
                        scope="col"
                        className="f-sortable advancedDocs__column-header"
                        style={true === hideDescription ? { width: '70%' } : { width: '30%' }}
                    >
                        <button
                            aria-label={this._getSortAriaLabel('title', Intl.Get(LocIds.PageAndPanels.TitleColumnTitle))}
                            style={{ fontSize: '16px' }}
                            onClick={() => this.sortButtonOnClick('title')}
                        >
                            {Intl.Get(LocIds.PageAndPanels.TitleColumnTitle)} {this._renderSortIndicator('title')} {this.trickScreenReaderOnSort()}
                        </button>
                    </th>
                    {!!!this.props.hideSeries &&
                        <th scope="col"
                            className="f-sortable"
                            style={{ textAlign: 'left' }}
                            colSpan={hideDescription && hideDate ? 2 : 1}
                        >
                            <button style={{ fontSize: '16px' }} title={Intl.Get(LocIds.DocumentManagementPage.SeriesColumnDescription)}>{Intl.Get(LocIds.DocumentManagementPage.SeriesColumnLabel)}</button>
                        </th>
                    }
                    {true !== hideDescription && (
                        <th id={'documentDescriptionColumnHeader' + id} scope="col" className={`f-sortable advancedDocs__column-header`} colSpan={hideDate ? 2 : 1}>
                            <button
                                aria-label={this._getSortAriaLabel('description', Intl.Get(LocIds.PageAndPanels.DescriptionColumnTitle))}
                                style={{ fontSize: '16px' }}
                                onClick={() => this.sortButtonOnClick('description')}
                            >
                                {Intl.Get(LocIds.PageAndPanels.DescriptionColumnTitle)} {this._renderSortIndicator('description')} {this.trickScreenReaderOnSort()}
                            </button>
                        </th>
                    )}
                    {true !== hideDate && (
                        <th id={'documentReportDateColumnHeader' + id} scope="col" className={`f-sortable advancedDocs__column-header`} colSpan={1}>

                            <button
                                aria-label={this._getSortAriaLabel('date', Intl.Get(LocIds.PageAndPanels.DateColumnTitle))}
                                id={'sortByReportDate' + id}
                                style={{ fontSize: '16px', width: '125px', textAlign: 'left' }}
                                onClick={() => this.sortButtonOnClick('date')}
                            >
                                {Intl.Get(LocIds.PageAndPanels.DateColumnTitle)} {this._renderSortIndicator('date')} {this.trickScreenReaderOnSort()}
                            </button>
                        </th>
                    )}
                    <th scope="col" className="f-sortable" style={{ textAlign: 'left' }}>
                        <button style={{ width: '110px', fontSize: '16px' }}>{Intl.Get(LocIds.DocumentManagementPage.MoreOptionsColumnLabel)}</button>
                    </th>
                </tr>
            </thead>
        );
    }

    private onRowCheckboxChange = (index: number, item: TableItem, ev: React.FormEvent<HTMLElement>, isChecked: boolean): void => {
        const checkedRows = [...this.state.checkedRows];
        const documentId = item.getDocumentOrSeriesId();
        const allItems = this.getCheckableItems().map((itm) => itm.getDocumentOrSeriesId());

        if (isChecked) {
            if (checkedRows.indexOf(documentId) === -1) { checkedRows.push(documentId); }
            this.setState({ checkedRows, allItemsChecked: (allItems.length === checkedRows.length) });
        } else {
            if (checkedRows.indexOf(documentId) !== -1) { checkedRows.splice(checkedRows.indexOf(documentId), 1); }
            this.setState({
                allItemsChecked: false,  // since we're removing one, we'll uncheck the "all" state (we don't have an indeterminate checkbox)
                checkedRows
            });
        }
    }

    public sortButtonOnClick = (name: string) => {
        let asc = false;

        if (name !== this.state.orderBy.name) {
            asc = name === 'date' ? false : true;
        } else {
            asc = !this.state.orderBy.asc;
        }

        this.setState({ orderBy: { name, asc } });
    }

    private _renderSortIndicator = (name: string): JSX.Element | undefined => {
        const { orderBy } = this.state;

        if (orderBy.name === name) {
            return <Icon iconName={orderBy.asc ? 'Up' : 'Down'} className={'advancedDocs__sort-icon'} />;
        }

        return;
    }

    private _getSortAriaLabel = (name: string, title: string): string => {
        const { orderBy } = this.state;
        let status = Intl.Get(LocIds.PageAndPanels.NotSortedSortStatus);

        if (orderBy.name === name) {
            status = orderBy.asc ? Intl.Get(LocIds.PageAndPanels.AscendingSortStatus) : Intl.Get(LocIds.PageAndPanels.DescendingSortStatus);
        }

        return Intl.Get(LocIds.PageAndPanels.SortByAriaLabel, { title, status });
    }

    private trickScreenReaderOnSort = () => {
        // The screen does not see a difference when switching from ascending to descending arrow and therefor does not alert the change.
        // Alternating between a space and no space tricks the screen readers in thinking something changed and the alert happens correctly.
        return this.state.orderBy.asc ? ' ' : '';
    }

    private onAllItemsCheckedChange = () => {
        const { allItemsChecked } = this.state;

        if (allItemsChecked) {
            this.setState({
                allItemsChecked: false,
                checkedRows: []
            });
        } else {
            this.setState({
                allItemsChecked: true,
                checkedRows: this.getCheckableItems().map((item) => getTableItemId(item))
            });
        }
    }

    /**
     * This provides a collection of items based on the saved panel props
     * for this table (aka the 'base filter')
     */
    private getBaseItems = (): TableItem[] => {
        let items = this.props.documents.map((doc) => TableItem.fromDocument(doc))
            .concat(this.props.pages.map((page) => TableItem.fromPage(page)));

        // pull in the full list of items, filtered by the filteringInfo props (which is the "pre-filter" saved in the editor)
        return this.filteredItems(items, this.props.filteringInfo);
    }

    private isFilteringOnStarterPack = (): boolean => {
        if (!this.props.filteringInfo) { return false; }

        let filtered = this.props.filteringInfo.filter((selected) => ((/starterpack/i).test(selected.vocabulary)));

        return (filtered && filtered.length > 0);
    }

    private getStarterPackZip = (): TableItem | undefined => {
        if (this.isFilteringOnStarterPack() === false) { return undefined; }

        let items = this.getBaseItems();

        // only return documents that are not starter pack zip files
        let zips = items.filter((item) => (true === item.isStarterPackZip()));

        if (zips && zips.length > 0) {
            return zips[0];
        }

        return undefined;
    }

    /**
     * Get the current list of documents to render based on the stored filters,
     * the client-side filters, and the current sorting.
     *
     * @return TableItem[] list of docs to render in the table
     */
    private getItemsToRender = (): TableItem[] => {
        let items = this.getBaseItems();

        // now fiter that list by the user's selected filters
        items = items.filter((item) => (true !== item.isStarterPackZip())); // everything except starter pack zips

        items = items.sort((i1, i2) => TableItem.sort(i1, i2, this.state.orderBy));

        return items;
    }

    private getCheckableItems = (): TableItem[] => {
        return this.getItemsToRender().filter(item => item.canCheckItem());
    }

    private filteredItems = (items: TableItem[], filteringInformation: SelectedVocabulary[] | undefined): TableItem[] => {
        let ret: TableItem[] = [...items];

        let filtersInEffect = (filteringInformation || []).length || 0;

        if (filtersInEffect === 0) { return ret; }

        (filteringInformation || []).forEach((filter) => {
            if (filter.choice.length === 0) {
                return;
            }

            const temp: TableItem[] = [];
            filterItemsByVocabulary(
                ret, // use the currently filtered collection as the basis, if a filter has eliminated all possibilities then we won't have any to go off of
                filter,
                this.props.vocabularies
            ).forEach((t: TableItem) => {
                if (temp.indexOf(t) < 0) {
                    temp.push(t);
                }
            });

            ret = temp;
        });

        return ret;
    }

    private clientFilteredItems = (items: TableItem[]): TableItem[] => {
        let ret: TableItem[] = [...items];

        const { filterDocumentMonthsRange, filterDocType, selectedFilters } = this.state;

        ret = this.filteredItems(ret, selectedFilters);

        // filter by the date criteria the user has selected
        if (filterDocumentMonthsRange) {
            if (filterDocumentMonthsRange === numeric1PlusYear) {
                // value == numeric1PlusYear means user selected 'Past 12+ months'
                const oneYearAgo = new Date();
                oneYearAgo.setDate(oneYearAgo.getDate() - 30 * numeric1Year);
                ret = ret.filter((item) => item.date.getTime() < oneYearAgo.getTime());
            }
            else {
                // other values may be Past 3,9,12 months
                const someDaysAgo = new Date();
                someDaysAgo.setDate(someDaysAgo.getDate() - 30 * filterDocumentMonthsRange);
                ret = ret.filter((item) => item.date.getTime() > someDaysAgo.getTime());
            }
        }

        if (filterDocType && filterDocType.length > 0) {
            ret = ret.filter((item) => {
                const extensions: string[] = filterDocType.reduce(
                    (prev, current) => {
                        return prev.concat(ExtensionsByKey[current]);
                    },
                    []
                );

                return extensions.indexOf(item.fileExtension || '') !== -1;
            });
        }

        return ret;
    }

    /**
     * Render the DOM elements for the table header - the multi-select actions Download/Save
     * will show up based on what types of documents have been checked.
     */
    private renderTableActions = (): JSX.Element | undefined => {

        const { checkedRows, allItemsChecked } = this.state;
        const starterPack = this.getStarterPackZip();
        const canDownloadAll = (allItemsChecked === true && starterPack && starterPack.documentId);
        const { hideRemoveFromLibrary } = this.props;

        let items = this.getBaseItems();
        // remove any starter pack zip files from table
        items = items.filter((item) => (true !== item.isStarterPackZip()));

        // only keep documents that we've explicitly checked
        items = items.filter((item) => (checkedRows.indexOf(item.getDocumentOrSeriesId()) !== -1));

        let canDownload = true;

        let groupedItems = this.groupItems(items, this.props.groupingInfo);
        groupedItems = this.setGroupFirstTab(groupedItems);

        const groupedItem = (groupedItems.length > 1 && this.state.mustSelectTab !== '') ? groupedItems.filter((g) => g.choiceId === this.state.mustSelectTab)[0] : groupedItems[0];

        if (groupedItem && groupedItem.items.filter((item) => item.isLink).length > 0) {
            canDownload = false;
        }

        const libraryIds = this.props.myLibraryDocuments.map((doc) => doc.documentId);
        // if none of these docs are currently in the library then offer to "add"
        const itemsInLibrary = items.filter((item) => libraryIds.indexOf(item.getDocumentOrSeriesId()) !== -1);
        const canAdd: boolean = (items.length - itemsInLibrary.length) > 0; // if at least one doc is not in library, we show add per #1050669
        const canRemove: boolean = !canAdd; // if _all of the docs_ are in the library then show remove

        const downloadDisabled: boolean = items.length > (this.props.maxDownloads || Constants.DOCUMENT_DOWNLOAD_MAX);

        return (
            <div className="advancedDocs__multiselect-actions">

                <div className="advancedDocs__multiselect-actions_item">

                    {canAdd && (
                        <button
                            className="c-button"
                            key="addtolibrary"
                            onClick={() => this.multiSelectAction(ContextActions.ADDTOLIBRARY)}
                        >
                            <Icon iconName="SingleBookmark" style={{ color: '#005da6', fontSize: '10px' }} />
                            {Intl.Get(LocIds.PageAndPanels.AddToLibrary)}
                        </button>
                    )}

                    {canRemove && hideRemoveFromLibrary !== true && checkedRows.length > 0 && (
                        <button
                            className="c-button"
                            onClick={() => this.multiSelectAction(ContextActions.REMOVEFROMLIBRARY)}
                        >
                            <Icon iconName="PageRemove" style={{ color: '#005da6', fontSize: '10px' }} />
                            {Intl.Get(LocIds.PageAndPanels.RemoveFromLibrary)}
                        </button>
                    )}

                    {this.renderDownloadAll()}

                    {canDownload && checkedRows.length > 0 && (
                        <button
                            key="download"
                            onClick={() => this.multiSelectAction(ContextActions.DOWNLOAD)}
                            className={`advancedDocs__multiselect-download c-button ${downloadDisabled ? 'advancedDocs__multiselect-download--disabled' : ''}`}
                        >
                            <Icon iconName="Download" style={{ color: downloadDisabled ? '#cccccc' : '#005da6', fontSize: '12px' }} />
                            {Intl.Get(LocIds.PageAndPanels.DownloadDocument)}
                        </button>
                    )}

                    {canDownload && checkedRows.length > 0 && (

                        <TooltipHost
                            content={Intl.Get(LocIds.PageAndPanels.MaxDownloadsTooltip, { max: this.props.maxDownloads })}
                            id="richTextLinkTooltip"
                            calloutProps={{ isBeakVisible: false }}
                        >
                            <button className="advancedDocs__multiselect-download-tooltip" ref={(ref) => this.downloadTooltipRef = ref}>
                                <Icon
                                    ariaLabel={Intl.Get(LocIds.PageAndPanels.MaxDownloadsTooltip, { max: this.props.maxDownloads })}
                                    iconName={'Info'}
                                />
                            </button>
                        </TooltipHost>
                    )}
                </div>
            </div>
        );
    }

    /**
     * When either action is taken from the context menu we'll respond by inspecting the
     * data stored in the IContextualMenuItem.
     */
    public onTableContextMenu = (ev?: React.MouseEvent<HTMLButtonElement>, menuitem?: IContextualMenuItem): boolean => {
        if (!menuitem) { return true; }

        const { item, action } = menuitem.data;
        const { isSignedIn } = this.props;

        if (document) {
            if (action === ContextActions.DOWNLOAD) {
                this.downloadDocument(item);
            } else if (action === ContextActions.BROWSER) {
                window.open(item.getLink(), '_blank');
            } else if (action === ContextActions.ADDTOLIBRARY) {
                if (!isSignedIn) {
                    this.setState({ showAgreement: true });
                } else {
                    this.setState({
                        showAddToLibraryDialog: true,
                        displayedChoiceGroup: 'Series',
                        selectedContextMenuDocument: item
                    });
                }
            } else if (action === ContextActions.REMOVEFROMLIBRARY) {
                if (!isSignedIn) {
                    this.setState({ showAgreement: true });
                } else {
                    this.setState({
                        showRemoveFromLibraryDialog: true,
                        displayedChoiceGroup: 'Series',
                        selectedContextMenuDocument: item
                    });
                }
            } else if (action === ContextActions.VIEWALLVERSIONS) {
                if (!isSignedIn) {
                    this.setState({ showAgreement: true });
                } else {
                    this.setState({
                        selectedSeriesId: item.seriesId,
                        showSeriesVersionsFlyout: true
                    });
                }
            }
        }

        return true; // close the context menu - https://developer.microsoft.com/en-us/fabric#/components/contextualmenu
    }

    /**
     * Render a row of the document table
     *
     * @param {TableItem} item a document model with versioning info
     * @param {number} i index of the current row
     */
    private getTableRow = (item: TableItem, i: number, groupData?: GroupedData): JSX.Element | undefined => {

        let docTabId = '';
        if (groupData) {
            docTabId = this.props.panelId + '_' + groupData.choiceId;
        }

        const contextMenuItems: IContextualMenuItem[] = [];

        const libraryIds = this.props.myLibraryDocuments.map((doc) => doc.documentId);
        const documentIsInMyLibrary = libraryIds.indexOf(getTableItemId(item)) !== -1;
        const { checkedRows } = this.state;
        const { hideDescription, hideDate, hideRemoveFromLibrary, isSignedIn } = this.props;

        if (!item.isLink) {
            contextMenuItems.push({
                data: {
                    action: ContextActions.DOWNLOAD,
                    item,
                    workaroundOnClick: this.onTableContextMenu
                },
                iconProps: {
                    iconName: 'Download',
                    style: { color: '#005da6' }
                },
                // can't use this and dismiss the menu unless we update Fabric... onClick: this.onTableContextMenu,
                key: `download${item.documentId}`,
                name: Intl.Get(LocIds.PageAndPanels.DownloadDocument)
            });
        } else {
            contextMenuItems.push({
                data: {
                    action: ContextActions.BROWSER,
                    item,
                    workaroundOnClick: this.onTableContextMenu
                },
                iconProps: {
                    iconName: 'Storyboard',
                    style: { color: '#005da6' }
                },
                // can't use this and dismiss the menu unless we update Fabric... onClick: this.onTableContextMenu,
                key: `open${item.documentId}`,
                name: 'Open In Browser'
            });
        }

        if (true === item.isDocument) {
            if (documentIsInMyLibrary) {
                contextMenuItems.push({
                    data: {
                        action: ContextActions.REMOVEFROMLIBRARY,
                        item,
                        workaroundOnClick: this.onTableContextMenu
                    },
                    iconProps: {
                        iconName: 'PageRemove',
                        style: { color: '#005da6' }
                    },
                    // can't use this and dismiss the menu unless we update Fabric... onClick: this.onTableContextMenu,
                    key: `remove${item.documentId}`,
                    name: Intl.Get(LocIds.PageAndPanels.RemoveFromLibrary)
                });
            } else {
                contextMenuItems.push({
                    data: {
                        action: ContextActions.ADDTOLIBRARY,
                        item,
                        workaroundOnClick: this.onTableContextMenu
                    },
                    iconProps: {
                        iconName: 'SingleBookmark',
                        style: { color: '#005da6' }
                    },
                    // can't use this and dismiss the menu unless we update Fabric... onClick: this.onTableContextMenu,
                    key: `add${item.documentId}`,
                    name: Intl.Get(LocIds.PageAndPanels.AddToLibrary)
                });
            }
        }

        if (item.documentType === DocumentType.Series) {
            contextMenuItems.push({
                data: {
                    action: ContextActions.VIEWALLVERSIONS,
                    item,
                    workaroundOnClick: this.onTableContextMenu
                },
                iconProps: {
                    iconName: 'History',
                    style: { color: '#005da6' }
                },
                key: `viewversion${item.seriesId}`,
                name: Intl.Get(LocIds.PageAndPanels.ViewAllVersions)
            });
        }

        const localTime = formatDate(item.date);
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        let isNewDocument: boolean = item.reportDate.getTime() > thirtyDaysAgo.getTime(); // should we show the New badge?
        let isUpdatedDocument: boolean = item.isContentUpdated !== undefined && item.isContentUpdated && item.date.getTime() > thirtyDaysAgo.getTime(); // should we show the (updated) badge?
        const isNewUI = store.getState().applicationContext.userInfo.flights.indexOf('NewUIDesign') > -1;

        function getHyperlinkIcon(linkitem: TableItem, documentType: string) {
            if (linkitem.isLink) {
                if (linkitem.isDocument) {
                    return (
                        <Icon
                            iconName={'NavigateExternal'}
                            style={{ width: '12px', stroke: '#005da6' }}
                            ariaLabel={Intl.Get(LocIds.PageAndPanels.NavigateExternalIcon)}
                            title={Intl.Get(LocIds.PageAndPanels.NavigateExternalIcon)}
                        />
                    );
                } else {
                    return undefined;
                }
            } else {
                return (
                    <Icon
                        iconName={'Download'}
                        ariaLabel={Intl.Get(LocIds.PageAndPanels.DownloadDocumentAriaLabel, { type: documentType })}
                        title={Intl.Get(LocIds.PageAndPanels.DownloadDocumentAriaLabel, { type: documentType })}
                    />
                );
            }
        }

        return (
            <tr key={item.documentId.concat(i.toString())}
                className={`advancedDocs__table-row ${this.state.selectedContextMenuDocument &&
                    this.state.selectedContextMenuDocument.documentId === item.documentId ? "selected" : ""}`}>
                <td>
                    <div style={{ visibility: (true === item.isDocument) ? 'visible' : 'hidden' }}>
                        <TableRowCheckbox
                            index={i}
                            checked={(checkedRows.indexOf(item.getDocumentOrSeriesId()) !== -1)}
                            item={item}
                            onChange={this.onRowCheckboxChange}
                        />
                    </div>
                </td>
                <td className="advancedDocs__cell-title">
                    <div style={{ display: 'table' }}>
                        {(this.state.documentsToBeDownloaded.indexOf(item.documentId) !== -1) &&
                            <Spinner size={SpinnerSize.small} ariaLive="assertive" className="docDownloadSpinner" />
                        }

                        <div className="advancedDocs__cell-title__contents">
                            <i
                                className={
                                    item.isLink ? 'ms-Icon ms-Icon--Globe'
                                        : `ms-Icon ${getFabricIconByExtension(item.fileExtension)}`
                                }
                                style={{ fontSize: '20px' }}
                                aria-hidden="true"
                            />
                        </div>
                        <div className="advancedDocs__cell-title__contents">
                            {
                                item.isLink ?
                                    <a
                                        href={item.getLink()}
                                        className="c-hyperlink"
                                        target="_blank"
                                    >
                                        {item.title}
                                        {getHyperlinkIcon(item, getDocumentTypeAriaLabel(item.fileExtension))}
                                        {isNewDocument && <strong className="c-badge newBadge">NEW</strong>}
                                    </a>
                                    :
                                    <a
                                        href={isNewUI ? getDocumentDetailLink(item.documentId) : getDownloadDocumentLink(item.documentId, this.props.parentId)}
                                        onClick={(e) => {
                                            if (!isNewUI) {
                                                e.preventDefault();
                                                this.downloadDocument(item);
                                            }
                                        }}
                                        className="c-hyperlink"
                                    >
                                        {item.title}
                                        {getHyperlinkIcon(item, getDocumentTypeAriaLabel(item.fileExtension))}
                                        {isNewDocument && <strong className="c-badge newBadge">NEW</strong>}
                                    </a>
                            }
                            {true !== hideDate && (
                                <div className={`advancedDocs__document-date ${VISIBLE_MOBILE_CLASSES}`}>
                                    {localTime} {isUpdatedDocument && <div><a className="f-small">(updated)</a></div>}
                                </div>
                            )}
                        </div>
                    </div>
                </td>
                {!!!this.props.hideSeries &&
                    <td>
                        {(item.documentType === DocumentType.Series || item.seriesId !== ComplianceManagerConstants.DefaultGuid) && <Icon iconName={'CheckMark'} style={{ fontSize: '20px' }} />}
                    </td>
                }
                {true !== hideDescription && (
                    <td>
                        <ShowMoreText
                            key={`description_${item.documentId}`}
                            limit={170}
                            text={`${item.description}`}
                            title={item.title}
                            className={`advancedDocs__document-description`}
                        />
                    </td>
                )}
                {true !== hideDate && (
                    <td style={{ whiteSpace: 'nowrap', minWidth: '160px' }}>
                        <div className="advancedDocs__document-date">
                            {localTime} {isUpdatedDocument && (
                                !isEmptyString(item.updateNotes) ?
                                    <TooltipHost
                                        content={item.updateNotes}
                                        id="updatedTooltip"
                                        calloutProps={{ isBeakVisible: false }}
                                    >
                                        <a className="f-small">(updated)</a>
                                    </TooltipHost>
                                    :
                                    <a className="f-small">(updated)</a>
                            )}
                        </div>
                    </td>
                )}

                <td className="advancedDocs__see-more">
                    {(true === item.isPublic || isSignedIn) ? (
                        <div />
                    ) : (
                        <Icon iconName={'Lock'} className='advancedDocs__icon' />
                    )}
                    <TableContextMenu
                        menuItems={contextMenuItems}
                    />
                </td>
            </tr>
        );
    }

    private downloadDocument = (item: TableItem, docTabId?: string) => {
        const { isSignedIn, hasAgreedToCurrentLicenseAgreement } = this.props;

        const hasPermissionToDownload =
            item.isPublic ||
            (isSignedIn && hasAgreedToCurrentLicenseAgreement);

        // user doesn't have permission so we'll have them login and/or do the agreement
        if (!hasPermissionToDownload) {
            window.history.pushState(null, '', getDownloadDocumentLink(item.documentId, this.props.panelId, docTabId));
            this.itemToBeDownloaded = item;
            this.setState({ showAgreement: true });
            return;
        }

        const fileName = item.title + item.fileExtension;
        const index = this.state.documentsToBeDownloaded.indexOf(item.documentId);
        if (index !== -1) { return; }

        this.setState({
            documentsToBeDownloaded: [...this.state.documentsToBeDownloaded, item.documentId],
            showWarningDialog: item.isSpecialDocument
        });

        downloadFile(item.documentId, fileName, () => {
            const indexToBeDeleted = this.state.documentsToBeDownloaded.indexOf(item.documentId);
            if (indexToBeDeleted > -1) {
                this.state.documentsToBeDownloaded.splice(indexToBeDeleted, 1);
                this.setState({ documentsToBeDownloaded: this.state.documentsToBeDownloaded });
            }
        });
    }

    private _closeAddToLibraryDialog = () => {
        this.setState({ showAddToLibraryDialog: false, selectedContextMenuDocument: undefined, displayedChoiceGroup: '', allItemsChecked: false, checkedRows: [] });
    }

    private renderAddToLibraryDialog = (): JSX.Element => {
        return (
            <Dialog
                hidden={!this.state.showAddToLibraryDialog}
                onDismiss={this._closeAddToLibraryDialog}
                dialogContentProps={{
                    showCloseButton: true,
                    title: Intl.Get(LocIds.PageAndPanels.AddToLibrary),
                    type: DialogType.largeHeader,
                }}
                modalProps={{
                    containerClassName: 'ms-dialogMainOverride',
                    isBlocking: true,
                }}
            >
                {this.state.displayedChoiceGroup === 'Series' && (this.state.selectedContextMenuDocument === undefined || this.state.selectedContextMenuDocument!.documentType === DocumentType.Series) ?
                    <p>
                        {Intl.Get(this.state.selectedContextMenuDocument === undefined ?
                            LocIds.DocumentManagementPage.SubscribeToMultipleDocumentsAndSeriesConfirmation :
                            LocIds.DocumentManagementPage.SubscribeToSeriesConfirmation)}
                    </p> :
                    <p>
                        {Intl.Get(this.state.selectedContextMenuDocument === undefined ?
                            LocIds.DocumentManagementPage.SubscribeToMultipleDocumentsConfirmation :
                            LocIds.DocumentManagementPage.SubscribeToDocumentConfirmation)}
                    </p>
                }
                <br />
                <DialogFooter>
                    <PrimaryButton onClick={this.saveToLibraryButtonClick} text={Intl.Get(LocIds.DocumentManagementPage.LibraryYesButtonLabel)} />
                    <DefaultButton onClick={this._closeAddToLibraryDialog} text={Intl.Get(LocIds.DocumentManagementPage.LibraryNoButtonLabel)} />
                </DialogFooter>
            </Dialog>
        );
    }

    private saveToLibraryButtonClick = () => {
        if (this.state.selectedContextMenuDocument) {
            let doc: MyLibraryDocument = {
                documentId: this.state.selectedContextMenuDocument.documentId,
                documentType: DocumentType.Standalone,
                contentHash: this.state.selectedContextMenuDocument.contentHash
            };

            if (this.state.selectedContextMenuDocument.documentType === DocumentType.Series) {
                doc.documentId = this.state.selectedContextMenuDocument.seriesId;
                doc.documentType = DocumentType.Series;
            }

            this.addToLibrary([doc]);
        } else {
            let items = this.getBaseItems();
            // only keep documents that we've explicitly checked
            items = items.filter((item) => this.state.checkedRows.indexOf(getTableItemId(item)) !== -1);
            this.addToLibrary(items.map((item) => ({ documentId: getTableItemId(item), documentType: item.documentType, contentHash: item.contentHash })));
        }

        this._closeAddToLibraryDialog();
    }

    private removeFromLibraryButtonClick = () => {
        if (this.state.selectedContextMenuDocument) {
            let doc: MyLibraryDocument = {
                documentId: this.state.selectedContextMenuDocument.documentId,
                documentType: DocumentType.Standalone,
                contentHash: this.state.selectedContextMenuDocument.contentHash
            };

            if (this.state.selectedContextMenuDocument.documentType === DocumentType.Series) {
                doc.documentId = this.state.selectedContextMenuDocument.seriesId;
                doc.documentType = DocumentType.Series;
            }

            this.removeFromLibrary([doc]);
        } else {
            let items = this.getBaseItems();
            // only keep documents that we've explicitly checked
            items = items.filter((item) => this.state.checkedRows.indexOf(getTableItemId(item)) !== -1);
            this.removeFromLibrary(items.map((item) => ({ documentId: getTableItemId(item), documentType: item.documentType, contentHash: item.contentHash })));
        }

        this._closeRemoveFromLibraryDialog();
    }

    private _closeRemoveFromLibraryDialog = () => {
        this.setState({ showRemoveFromLibraryDialog: false, selectedContextMenuDocument: undefined, displayedChoiceGroup: '', allItemsChecked: false, checkedRows: [] });
    }

    private renderRemoveFromLibraryDialog = (): JSX.Element => {
        return (
            <Dialog
                hidden={!this.state.showRemoveFromLibraryDialog}
                onDismiss={this._closeRemoveFromLibraryDialog}
                dialogContentProps={{
                    showCloseButton: true,
                    title: Intl.Get(LocIds.PageAndPanels.RemoveFromLibrary),
                    type: DialogType.largeHeader,
                }}
                modalProps={{
                    containerClassName: 'ms-dialogMainOverride',
                    isBlocking: true,
                }}
            >
                {this.state.displayedChoiceGroup === 'Series' && (this.state.selectedContextMenuDocument === undefined || this.state.selectedContextMenuDocument!.documentType === DocumentType.Series) ?
                    <p>
                        {Intl.Get(this.state.selectedContextMenuDocument === undefined ?
                            LocIds.DocumentManagementPage.UnsubscribeToMultipleDocumentsAndSeriesConfirmation :
                            LocIds.DocumentManagementPage.UnsubscribeToSeriesConfirmation)}
                    </p> :
                    <p>
                        {Intl.Get(this.state.selectedContextMenuDocument === undefined ?
                            LocIds.DocumentManagementPage.UnsubscribeToMultipleDocumentsConfirmation :
                            LocIds.DocumentManagementPage.UnsubscribeToDocumentConfirmation)}
                    </p>
                }
                <br />
                <DialogFooter>
                    <PrimaryButton onClick={this.removeFromLibraryButtonClick} text={Intl.Get(LocIds.DocumentManagementPage.LibraryYesButtonLabel)} />
                    <DefaultButton onClick={this._closeRemoveFromLibraryDialog} text={Intl.Get(LocIds.DocumentManagementPage.LibraryNoButtonLabel)} />
                </DialogFooter>
            </Dialog>
        );
    }

    private _closeWarningDialog = () => {
        this.setState({ showWarningDialog: false });
    }

    private renderConfirmationDialog = (): JSX.Element => {
        return (
            <Dialog
                hidden={!this.state.showWarningDialog}
                onDismiss={this._closeWarningDialog}
                dialogContentProps={{
                    subText: Intl.Get(LocIds.DocumentManagementPage.DocumentDownloadWarningDescription),
                    title: Intl.Get(LocIds.DocumentManagementPage.DocumentDownloadWarningHeader),
                    type: DialogType.largeHeader,
                }}
                modalProps={{
                    containerClassName: 'ms-dialogMainOverride',
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={this._closeWarningDialog} text={Intl.Get(LocIds.DocumentManagementPage.ConfirmButtonLabel)} />
                </DialogFooter>
            </Dialog>
        );
    }

    private setGroupFirstTab(groupedItems: GroupedData[]): GroupedData[] {
        if (groupedItems.length) {
            groupedItems.sort((a, b) => a.key.localeCompare(b.key));
            if (this.props.firstTabName) {
                let index = groupedItems.findIndex(g => g.choiceId === this.props.firstTabName);
                if (index !== -1) {
                    let firstTab = groupedItems[index];
                    groupedItems.splice(index, 1);
                    groupedItems.unshift(firstTab);
                }
            }
            var hasSelectedTab = false;
            groupedItems.forEach((x, i) => {
                x.items.sort((d1, d2) => this.sortItems(d1, d2));
                if (this.state.mustSelectTab) {
                    x.isSelected = (this.state.mustSelectTab === x.choiceId);
                } else {
                    x.isSelected = (i === this.state.index);
                }
                if (x.isSelected) {
                    hasSelectedTab = true;
                }
            });
            if (!hasSelectedTab) {
                groupedItems[0].isSelected = true;
            }
        }

        return groupedItems;
    }

    private groupItems(items: TableItem[], groupingInformation: SelectedVocabulary[] | undefined): GroupedData[] {
        let ret: GroupedData[] = [];
        if (groupingInformation && groupingInformation.length > 0) {
            groupingInformation.forEach(groupKey => {
                if (ret.length <= 0) {
                    let temp = this.getGroupedItems(items, groupKey, this.props.vocabularies);
                    temp.forEach((value, subGroupKey) => {
                        let displayChoice = this.getChoiceDisplayValue(groupKey.vocabulary, subGroupKey);
                        // After delete the values of vocabulary, document still hold that value.
                        // But that value should not be shown as tab
                        if (displayChoice) {
                            value = this.clientFilteredItems(value);
                            ret.push({ key: displayChoice, choiceId: subGroupKey, children: [], items: value, isSelected: false });
                        }
                    });
                } else {
                    ret.forEach(mainGroup => {
                        let temp = this.getGroupedItems(mainGroup.items, groupKey, this.props.vocabularies);
                        temp.forEach((value, subGroupKey) => {
                            mainGroup.children.push({ key: subGroupKey, choiceId: '', children: [], items: value, isSelected: false });
                        });
                    });
                }
            });
        } else {
            ret.push({ key: '', choiceId: '', children: [], items: items, isSelected: false });
        }
        return ret;
    }

    private getGroupedItems(items: TableItem[], selectedVocabulary: SelectedVocabulary, vocabularies: VocabularyDefinition[]): Map<string, TableItem[]> {
        let ret: Map<string, TableItem[]> = new Map();
        items.forEach(item => {
            let vocabulary = vocabularies.find(sel => sel.name === selectedVocabulary.vocabulary);
            let choices = getVocabularyChoicesFromItem(item, selectedVocabulary, vocabulary);
            if (choices && choices.vocabularyValueIds && choices.vocabularyValueIds.length > 0) {
                choices.vocabularyValueIds.forEach(choiceId => {
                    var choiceDisplay = choiceId;
                    if (ret.has(choiceDisplay)) {
                        let temp = ret.get(choiceDisplay);
                        if (temp) {
                            ret.set(choiceDisplay, [...temp, item]);
                        }
                    } else {
                        ret.set(choiceDisplay, [item]);
                    }
                });
            }
        });
        return ret;
    }

    private getChoiceDisplayValue(vocabularyName: string, choiceId: string) {
        let vocab = this.props.vocabularies && this.props.vocabularies.find(v => v.name === vocabularyName);
        if (vocab) {
            let choice = vocab.choices.find(c => c.id === choiceId);
            // Only if that value appear in that vocabulary we return the value
            // Else we return an empty string means the value should not be shown as tab
            return choice ? choice.displayText && choice.displayText.lv ? choice.displayText.lv : choice.displayValues[0].displayValue : '';
        }
        return '';
    }

    private getSelectTabInParameters(): string {
        var response = '';
        var parametters = window.location.search;
        if (parametters) {
            parametters = parametters.substr(1);
            var params = parametters.split('&');
            params.forEach(p => {
                var contents = p.split('=');
                if (contents.length === 2) {
                    if (contents[0] === 'docTab') {
                        let values = decodeURIComponent(contents[1]).split('_');
                        if (values.length >= 2) {
                            if (values[0] === this.props.panelId) {
                                response = values[1];
                                for (var i = 2; i < values.length; i++) {
                                    response = response + ' ' + values[i];
                                }
                            }
                        }
                    }
                }
            });
        }
        return response;
    }

    private sortItems(d1: TableItem, d2: TableItem) {
        if (this.state.orderBy.name === 'title') {
            let title1 = d1.title;
            let title2 = d2.title;
            return this.state.orderBy.asc ?
                (title1 < title2 ? -1 : title1 > title2 ? 1 : 0) :
                (title1 > title2 ? -1 : title1 < title2 ? 1 : 0);
        } else if (this.state.orderBy.name === 'description') {
            let des1 = d1.description;
            let des2 = d2.description;
            return this.state.orderBy.asc ?
                (des1 < des2 ? -1 : des1 > des2 ? 1 : 0) :
                (des1 > des2 ? -1 : des1 < des2 ? 1 : 0);
        } else {
            let time1 = d1.date.getTime();
            let time2 = d2.date.getTime();
            return this.state.orderBy.asc ? time1 - time2 : time2 - time1;
        }
    }

    private loadScrollButton() {
        if (this.prevButton) {
            this.prevButton.style.display = 'none';
        }
        if (this.tabsContainer) {
            this.tabsContainer.style.paddingLeft = '0px';
        }
        if (this.pivotContainer && this.tabsContainer) {
            if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                this.nextButton.style.display = 'none';
            } else {
                this.nextButton.style.display = 'inline-block';
            }
        }
    }

    private updateTabScrollButtons() {
        if (this.nextButton) {
            this.nextButton.style.display = (this.leftMostTabIndex === 0) ? 'none' : 'inline-block';
        }
        if (this.tabsContainer) {
            this.nextButton.style.display = (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) ? 'none' : 'inline-block';
        }
    }

}

export default AdvancedDocumentTable;




