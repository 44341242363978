class ScreenReaderAnnouncerService {
    private clearAnnouncementTimer: number | undefined;

    public setAnnouncement(message: string, isAssertive?: boolean) {
        const announcerElement = document.getElementById('screenReaderAnnouncer');

        if (announcerElement) {
            announcerElement.setAttribute('aria-live', isAssertive ? 'assertive' : 'polite');
            // required to add a space to the message so live region is updated and announcement is made
            announcerElement.innerText = announcerElement.innerText === message ? `${message} ` : message;
            this.startClearAnnouncementTimer(announcerElement);
        }
    }

    private cancelClearAnnouncementTimer() {
        if (this.clearAnnouncementTimer) {
          clearTimeout(this.clearAnnouncementTimer);
          this.clearAnnouncementTimer = undefined;
        }
      }
    
      private startClearAnnouncementTimer(announcerElement: HTMLElement) {
        this.cancelClearAnnouncementTimer();
    
        this.clearAnnouncementTimer = window.setTimeout(
          () => {
            announcerElement.innerText = '';
          },
          2000);
    }
}

export const ScreenReaderAnnouncerClient = new ScreenReaderAnnouncerService();