import { StoreState } from '../../Store';
import { connect } from 'react-redux';
import PageLayout from '../Pages/PageLayout';
import { PageLayoutProps } from '../Pages/PageLayoutProps';

function mapStateToProps(state: StoreState, props: PageLayoutProps): PageLayoutProps {    
    return {
        ...props,
        isNextGenUIEnabled: state.applicationContext.userInfo.flights.indexOf('NextGenUI') > -1
    };
}
export default connect(mapStateToProps)(PageLayout);