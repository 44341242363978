import { ResponsiblePartiesLookup, ResponsibleParty } from '../../App/Models/ComplianceManagerV3';
import { IKeyValuePair } from '../../App/Models/IKeyValuePair';
import * as _ from 'lodash';
import { HttpStatusCode } from '../../Common/Models/ComplianceManagerConstants';

// Declared constanst ,so it can be used in other methods within service to update/delete/add
export const mockResponsiblepartieslookup: ResponsiblePartiesLookup = {
    responsibleParties: [
        {
            id: '2783b597-43e4-4200-baae-ed96763e5ca3',
            name: 'SFB',
            description: 'Skype For Business',
            isMappedToComplianceEntities: true,
            isGlobalOwner: false
        },
        {
            id: '8654a597-43e4-4200-baae-ed96763e5ca3',
            name: 'EXO',
            description: 'Exchange Online',
            isMappedToComplianceEntities: false,
            isGlobalOwner: false
        }
    ]
};

export class MockResponsiblePartiesLookupService {

    // TBD - Add other crud operations based on the need
    getResponsiblePartiesLookup(): ResponsiblePartiesLookup {
        return mockResponsiblepartieslookup;
    }

    deleteResponsibleParty(params: IKeyValuePair[]) {
        var responsiblePartLookupData = mockResponsiblepartieslookup.responsibleParties;
        if (responsiblePartLookupData && params.length > 0 && _.find(responsiblePartLookupData, x => x.name === params[0].Value)) {
            
            return { status: HttpStatusCode.ok, data:  _.find(responsiblePartLookupData, x => x.name === params[0].Value)};
        }
        return  { status: HttpStatusCode.internalServerError, data: null };
    }

    postResponsibleParty(params: IKeyValuePair[], postData: ResponsibleParty) {
        if (_.findIndex(mockResponsiblepartieslookup.responsibleParties, x => x.name === postData.name) > 0) {
            mockResponsiblepartieslookup.responsibleParties = _.remove(mockResponsiblepartieslookup.responsibleParties, x => x.name === postData.name);
        }
        let modifiedResponsiblepartieslookup = {responsibleParties:  mockResponsiblepartieslookup.responsibleParties.concat(postData)};
       
        if (modifiedResponsiblepartieslookup.responsibleParties.length > 0) {
            return { status: HttpStatusCode.ok, data: modifiedResponsiblepartieslookup };
        }
        return  { status: HttpStatusCode.internalServerError, data: null };
    }
}

export let MockResponsiblePartiesClientLookupService = new MockResponsiblePartiesLookupService();