import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import PageLayout from './App/Pages/PageLayoutWrapper';
import { ScreenReaderAnnouncer } from './App/Components/ScreenReaderAnnouncer';
import { store } from './Store';
import { Intl } from './App/Services/GlobalizationService';
import { LocalizationIds as LocIds } from './Common/Utilities/Globalization/IntlEnum';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { initializeIcons } from '@uifabric/icons';
import { registerIcons } from '@uifabric/styling';
import { CookieConsentServiceInstance } from './Common/Services/CookieConsentService';
import { getCookieConsentUpdateAction } from './App/Reducers/CookieConsentReducer';
import { Diagnostics } from './Common/Utilities/Diagnostics';

// Let the document know when the mouse is being used to hide the focus outline when keyboard navigation is not being used.
document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse');
});
document.body.addEventListener('keydown', () => {
    document.body.classList.remove('using-mouse');
});

__webpack_nonce__ = window.CSPNonce;

// Register icons and pull the fonts from the default SharePoint cdn:
initializeIcons();
registerIcons({
    fontFace: {
        fontFamily: `"CustomIconFont"`
    },
    icons: {
        'CustomContextMenu': '\uE803',
        'NavigateExternal': '\uE802',
    }
});
// tslint:disable-next-line:no-var-requires
const { detect } = require('detect-browser');
const browser = detect();

// tslint:disable-next-line:no-var-requires
require('json3');
// tslint:disable-next-line:no-var-requires
require('es5-shim');
// tslint:disable-next-line:no-var-requires
require('es6-shim');
// tslint:disable-next-line:no-var-requires
require('location-origin');

const getCookie = (cname: string) => {
  if (!document || !document.cookie) return "";

  var name = cname + "=";
  var ca = document.cookie.split(";");

  for (const cItem of ca) {
    let c = cItem;
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

/**
 * Prepare required services for React App.
 *
 * For example, cookie consent related information should be initialized before any react components is rendered,
 * since cookies are dropped in the constructor of entry component(<PageLayoutCurrent/>).
 */
const prepareInitialServices = async () => {
  const culture = getCookie("lan");
  
  const onConsentChanged = (newConsent: Record<WcpConsent.consentCategories, boolean>) => {
    store.dispatch(getCookieConsentUpdateAction(newConsent));
  };
  
  try {
    const consent = await CookieConsentServiceInstance.initialize(
      culture,
      "cookie-banner",
      onConsentChanged
    );
    store.dispatch(getCookieConsentUpdateAction(consent));
  } catch(err) {
    Diagnostics.writeLine(`prepareInitialServices: Error occurred when initializing required services: ${err}`)
  }
};

prepareInitialServices();

let version = browser.version.split('.', 1);
let verNumber = +version;
let supported = (window === window.parent && browser) &&
    ((browser.name.toUpperCase() !== 'IE')
        || (browser.name.toUpperCase() === 'IE' && verNumber >= 11));
if (supported) {
    ReactDOM.render(
        <Fabric>
            <Provider store={store}>
                <Router>
                    <PageLayout />
                </Router>
                <ScreenReaderAnnouncer />
            </Provider>
        </Fabric>
        ,
        document.getElementById('root') as HTMLElement
        ,
        () => {
            let lan = getCookie('lan');
            let docElement = document.documentElement;
            if (docElement) {
                if (lan) {
                    docElement.setAttribute('lang', lan);
                } else if (navigator.language) {
                    docElement.setAttribute('lang', navigator.language);
                } else {
                    docElement.setAttribute('lang', 'en-US');
                }
            }
        }
    );
} else {
    ReactDOM.render(
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <div>{Intl.Get(LocIds.HomePage.BrowserSupport)} </div>
        </div>,
        document.getElementById('root'));
}
registerServiceWorker();