import { DimensionLookup } from '../../App/Models/ComplianceManagerV3';
import { IKeyValuePair } from '../../App/Models/IKeyValuePair';
import { HttpStatusCode } from '../../Common/Models/ComplianceManagerConstants';
import * as _ from 'lodash';

// Declared constanst ,so it can be used in other methods within service to update/delete/add
export const mockDimensionlookup: DimensionLookup = {
    dimensions: [
        {
            id: "5283b597-43e4-4200-baae-ed96763e5ca3",
            key: 'Product',
            value: 'O365',
            isMultiselectionAllowed: false,
            isMappedToComplianceEntities: true
        },
        {
            id: "6283b597-43e4-4200-baae-ed96763e5ca3",
            key: 'Certification',
            value: 'HIPAA',
            isMultiselectionAllowed: false,
            isMappedToComplianceEntities: false
        },
        {
            id: "7283b597-43e4-4200-baae-ed96763e5ca3",
            key: 'Certification',
            value: 'IS0 27000',
            isMultiselectionAllowed: false,
            isMappedToComplianceEntities: false
        },
        {
            id: "0af80b1f-eb13-4ad9-bd95-05e890f8c5c3",
            key: 'Environment',
            value: 'MT',
            isMultiselectionAllowed: false,
            isMappedToComplianceEntities: false
        },
        {
            id: "b27e35a5-ce0c-4c3b-936a-e01017b3ccc0",
            key: 'Environment',
            value: 'GT',
            isMultiselectionAllowed: false,
            isMappedToComplianceEntities: false
        }  
    ]
};
export class MockDimensionLookupService {

    // TBD - Add other crud operations based on the need
    getDimensionLookup(): DimensionLookup {
        return mockDimensionlookup;
    }

    deleteDimension(params: IKeyValuePair[]) {
        var dimensionLookupData: DimensionLookup = mockDimensionlookup;
        if (dimensionLookupData.dimensions && params.length > 0) {
            _.remove(dimensionLookupData.dimensions, x => x.key === params[0].Value);
            return { status: HttpStatusCode.ok, data: dimensionLookupData };
        }
        return { status: HttpStatusCode.internalServerError, data: null }
    }

    postDimensionLookup(params: IKeyValuePair[], data: any) {
        if (_.find(mockDimensionlookup.dimensions, x => x.key === data.dimensions[0].key)) {
            _.remove(mockDimensionlookup.dimensions, x => x.key === data.dimensions[0].key);
        }
        let newDimensionLookup: DimensionLookup = { dimensions: mockDimensionlookup.dimensions.concat(data.dimensions) };
        if (newDimensionLookup.dimensions.length > 0) {
            return { status: HttpStatusCode.ok, data: newDimensionLookup };
        }
        return { status: HttpStatusCode.internalServerError, data: null }
    }
}

export let MockDimensionLookupClientService = new MockDimensionLookupService();