import { Reducer } from 'redux';
import { PrivacyOperation } from '../../Models/PrivacyPortal';

type Status = 'Init' | 'Requesting' | 'Finished' | 'Success' | 'Error' | 'Loaded' | 'Loading';

export interface PrivacyManagerState {
    status: Status;
    privacyOperations: PrivacyOperation[];
    message?: string;
    exportSuccessMessage?: string;
}
// 
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface PrivacyOperationsRequesting {
    type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_REQUESTING';
}

interface PrivacyOperationsRequestSucceeded {
    type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_SUCCEEDED';
    privacyOperations: PrivacyOperation[];
    message: string;
}

interface PrivacyOperationsRequestFailed {
    type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_ERROR';
    message: string;
}

interface PrivacyOperationsExportSuccessmessage {
    type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_EXPORT_SUCCESS_MESSAGE';
    exportSuccessMessage: string;
}

interface PrivacyOperationsDismissExportSuccessmessage {
    type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_EXPORT_SUCCESS_MESSAGE_DISMISS';
}

export type PrivacyManagerActions = PrivacyOperationsRequesting | PrivacyOperationsRequestSucceeded | PrivacyOperationsRequestFailed | PrivacyOperationsExportSuccessmessage | PrivacyOperationsDismissExportSuccessmessage;

export function getRequestingAction(): PrivacyManagerActions {
    return {
        type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_REQUESTING',
    };
}

export function getRequestSucceededAction(message: string, privacyOperations: PrivacyOperation[]): PrivacyManagerActions {
    return {
        type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_SUCCEEDED',
        message: message,
        privacyOperations: privacyOperations
    };
}

// tslint:disable-next-line:no-any
export function getRequestErrorAction(message: string): PrivacyManagerActions {
    return {
        type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_ERROR',
        message: message,
    };
}

export function getExportDataRequestSuccessAction(exportSuccessMessage: string) {
    return {
        type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_EXPORT_SUCCESS_MESSAGE',
        exportSuccessMessage: exportSuccessMessage
    };
}

export function getExportDataRequestMessageDismissAction() {
    return {
        type: 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_EXPORT_SUCCESS_MESSAGE_DISMISS'
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<PrivacyManagerState> = (state: PrivacyManagerState, action: PrivacyManagerActions) => {
    let newState: PrivacyManagerState;
    switch (action.type) {
        case 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_REQUESTING':
            newState = { 
                ...state, 
                privacyOperations: [] as PrivacyOperation[], 
                status: 'Requesting' 
            };
            break;

        case 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_SUCCEEDED':
            newState = {
                ...state,
                status: 'Success',
                message: action.message,
                privacyOperations: action.privacyOperations
            };
            break;

        case 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_ERROR':
            newState = {
                ...state,
                status: 'Error',
                privacyOperations: [] as PrivacyOperation[],
                message: action.message,
            };
            break;
        case 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_EXPORT_SUCCESS_MESSAGE':
            newState = (state === undefined)
                ? {
                    status: 'Init',
                    privacyOperations: [] as PrivacyOperation[],
                    message: ''
                }
                : state;
            newState = {
                ...newState,
                exportSuccessMessage: action.exportSuccessMessage
            };
            break;
        case 'PRIVACY_PORTAL_PRIVACY_OPERATIONS_EXPORT_SUCCESS_MESSAGE_DISMISS':
            newState = {
                ...state,
                exportSuccessMessage: undefined
            };
            break;
        default:
            newState = (state === undefined)
                ? {
                    status: 'Init',
                    privacyOperations: [] as PrivacyOperation[],
                    message: ''
                }
                : state;
    }
    return newState;
};
