import * as React from 'react';
import { Unsubscribe } from 'redux';
import { store } from '../../../../Store';
import { ErrorResponse } from '../../../Services/Models';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Checkbox, Spinner, SpinnerSize } from 'office-ui-fabric-react/lib';
import { switchCultureToEnglish } from '../../../Utilities/PageInfrastructure/PagesHelper';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../../Services/GlobalizationService';
import { SeriesServiceClient } from 'src/App/Services/SeriesService';
import { SeriesManagementActions, SeriesManagementState } from 'src/App/Reducers/SeriesManagement';
import SeriesTable from './SeriesTable';
import { SeriesDefinition } from 'src/App/Models/Series/SeriesDefinition';

let api = SeriesServiceClient;

interface SeriesManagementPageProps {
}


export class SeriesManagementPage extends React.Component<SeriesManagementPageProps, SeriesManagementState> {
    private isOnlyNoDocumetsChecked : boolean;
    private storeUnsubscribe: Unsubscribe;    
    private originalSeries: SeriesDefinition[];
 
    constructor(props: SeriesManagementPageProps) {
        super(props);
        this.isOnlyNoDocumetsChecked = false;
        switchCultureToEnglish();
        this.state = {
            status: 'Init',
            series: [],
            onlyNoDocuments: false
        };
        this.storeUnsubscribe = store.subscribe(() => {
            this.setState( store.getState().seriesManagement);
        });
    }

    public getSeries(): void {
        api.listSeries(
            () => {
                var action: SeriesManagementActions = {
                    type: 'SERIES_FETCHING',
                };
                store.dispatch(action);
            },
            (response) => {
                var action: SeriesManagementActions = {
                    type: 'SERIES_FETCHED',
                    data: response.data
                };
                this.originalSeries = response.data;
                store.dispatch(action);
            },
            (failed: ErrorResponse) => {
                var action: SeriesManagementActions = {
                    type: 'SERIES_FETCHING_ERROR',
                    data: failed
                };
                store.dispatch(action);
            });
    }

   
    public componentDidMount(): void {
        if (this.state.status === 'Init') {
            this.getSeries();
        }

        document.title = Intl.Get(LocIds.SeriesManagmentPage.Title);
    }
    
    componentWillUnmount() {
        this.storeUnsubscribe();
    }

    public render() {
        if (this.state.status === 'Init') {
            return (
                <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.InitializingLabel)} ariaLive="assertive" />
            );
        } else if (this.state.status === 'Requesting') {
            return (
                <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingLabel)} ariaLive="assertive" />
            );
        }  else if (this.state.status === 'Error') {
            return (
                <div>
                    <p>{Intl.Get(LocIds.SeriesManagmentPage.FaildToLoad)}</p>
                    <DefaultButton onClick={() => this.getSeries()}>Reload</DefaultButton>
                </div>
            );
        } else { 
            return (this.state.series && this.state.series.length > 0 ?
                this.renderTableWithFilter() : this.renderNoResults());
        } 
    }

    private renderTableWithFilter = () => {
        return (
            <div>
                <div>
                <Checkbox
                        label={Intl.Get(LocIds.SeriesManagmentPage.HasNoDocuments)}
                        aria-label={Intl.Get(LocIds.SeriesManagmentPage.HasNoDocuments)}
                        checked={this.state.onlyNoDocuments}
                        onChange={() => {
                            this.filterSeries();
                            this.setState({ onlyNoDocuments: this.isOnlyNoDocumetsChecked });
                        }}
                    /></div>
                <div><SeriesTable seriesList = { this.state.series} /></div>
          </div>
        );
    }

    private filterSeries = () => {
       this.isOnlyNoDocumetsChecked = !this.isOnlyNoDocumetsChecked;
        if (this.isOnlyNoDocumetsChecked) {
            this.setState({ series: this.originalSeries.filter(x=>x.seriesDocuments.length==0)});
        } else {
            this.setState({ series: this.originalSeries});
        }
    }

    private renderNoResults = () => {
        return (
            <div>
                {Intl.Get(LocIds.SeriesManagmentPage.NoResult)}
            </div>
        );
    }
}
export default SeriesManagementPage;