import { Assessment } from '../../App/Models/ComplianceManagerV3/Assessment';
import { TileStatus } from '../../App/Models/ComplianceManagerV3';
import { ControlCategory } from 'src/App/Models/ComplianceManagerV3/ControlCategory';
import { Control } from 'src/App/Models/ComplianceManagerV2';

export const AssessmentLookup: Assessment[] = [
    {
        id: '66d3bb8d-0ead-4dec-85fa-b785e379d3a2',
        groupId: 'a6c11990-197e-4174-86cf-e72ff33ec575',
        tileName: 'Office ISO Assessment',
        status: TileStatus.Compliant,
        dimensions: [
            {
                id: '5283b597-43e4-4200-baae-ed96763e5ca3',
                key: 'Product',
                value: 'O365',
                isMultiselectionAllowed: false,
                isMappedToComplianceEntities: true,
                isGlobalDimension: false,
            },
            {
                id: '7283b597-43e4-4200-baae-ed96763e5ca3',
                key: 'Certification',
                value: 'IS0 27000',
                isMultiselectionAllowed: false,
                isMappedToComplianceEntities: false,
                isGlobalDimension: false,
            },
            {
                id: '0af80b1f-eb13-4ad9-bd95-05e890f8c5c3',
                key: 'Environment',
                value: 'MT',
                isMultiselectionAllowed: false,
                isMappedToComplianceEntities: false,
                isGlobalDimension: false,
            }
        ],
        categories: [
            {
                id: '76d3bb8d-0ead-4dec-85fa-b785e379d3a2',
                addedControlIds: [],
                completedCustomerControls: 0,
                completedMicrosoftControls: 0,
                complianceScore: 57,
                controlsAdded: 0,
                controlsUpdated: 0,
                customerControls: 5,
                displayName: "Risk Mitigation",
                microsoftControls: 0,
                updatedControlIds: [],
                description: 'Risk Mitigation',
                controlDetails: [],
            }
        ],
        whenLastUpdated: new Date('2018-03-02')
    },
    {
        id: '76d3bb8d-0ead-4dec-85fa-b785e379d3a2',
        groupId: 'b6c11990-197e-4174-86cf-e72ff33ec575',
        tileName: 'Azure 27018 Assessment',
        status: TileStatus.Compliant,
        dimensions: [
            {
                id: '6583b597-43e4-4200-baae-ed96763e5ca3',
                key: 'Product',
                value: 'Azure',
                isMultiselectionAllowed: false,
                isMappedToComplianceEntities: true,
                isGlobalDimension: false,
            },
            {
                id: '7283b597-43e4-4200-baae-ed96763e5ca3',
                key: 'Certification',
                value: 'IS0 27000',
                isMultiselectionAllowed: false,
                isMappedToComplianceEntities: false,
                isGlobalDimension: false,
            },
            {
                id: '0af80b1f-eb13-4ad9-bd95-05e890f8c5c3',
                key: 'Environment',
                value: 'GT',
                isMultiselectionAllowed: false,
                isMappedToComplianceEntities: false,
                isGlobalDimension: false,
            }
        ],
        categories: [
            {
                id: '15962a51-3a8f-4b8b-b4ed-114b14cd4774',
                addedControlIds: [],
                completedCustomerControls: 0,
                completedMicrosoftControls: 0,
                complianceScore: 104,
                controlsAdded: 0,
                controlsUpdated: 0,
                customerControls: 5,
                displayName: "Security Operations",
                microsoftControls: 0,
                updatedControlIds: [],
                description: 'Risk Mitigation',
                controlDetails: [],
            }
        ],
        whenLastUpdated: new Date('2018-07-02')
    }
];

export class MockAssessmentService {
    getAssessmentLookup(): Assessment[] {
        return AssessmentLookup;
    }

    getControCategories(): ControlCategory[] {
        var controlCaterogies: ControlCategory[] = [] 
        AssessmentLookup.map(assessment => {
            controlCaterogies.push(assessment.categories[0]);
        });
        return controlCaterogies;
    }
}

export const MockAssessmentClientService = new MockAssessmentService();