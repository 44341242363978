import { Async } from 'office-ui-fabric-react/lib/Utilities';
import { authContext } from './ADAuthenticate';
import { AuthData } from './ADAuthenticationHelper';
import { Disposable } from '../Models/Disposable';
import { AuthenticationSessionService } from '../../App/Services/AuthenticationSessionService';
import { LocalizationIds as LocIds } from './Globalization/IntlEnum';
import { Intl } from '../../App/Services/GlobalizationService';
import { AuthorizationInfo, FlightMembership } from './AuthorizationInfo';
import { Unsubscribe } from 'redux';
import { store } from '../../Store';

const SessionManagementFlightName: string = 'AuthSessionManagement';
const PollingIntervalMilliseconds: number = 2000;    // Two seconds polling interval
const MaxNumberOfTickBeforeAuthDataRefresh: number = 2; // number ot timer ticks before calling authContext.RefreshAuthData();
const DialogDelayMilliseconds: number = 30000; // 30 seconds to delay before we can re-open the dialog.
const TimeBeforeDismissSessionDialog: number = 120000; // Wait two minutes before dismissing an expiration dialog.

export enum AuthenticationSessionDialogMode {
    NoDialog,
    SessionAboutToExpireWarning,
    SessionExpiredMessage,
    SessionLifeAboutToEndWarning,
    SessionEndOfLifeMessage,
    FailedToRefreshSession
}

export interface AuthenticationSessionModel {
    authSession?: AuthenticationSessionController; // controller reference
}

export interface ExpirationTimeElements {
    minutesBeforeExpiration: number; // minutes part of the time span before expiration time
    secondsBeforeExpiration: number; // seconds part of the time span before expiration time
    maxLifeTimeHours: number; // maximum life time of a user sesssion in hours
}

export interface AuthenticationSessionDialogArgs {
    title: string;
    expirationMessage: string;
    instructionMessage?: string;
    continueButtonLabel: string; // Text displayed as Ok button's caption (Continue, Ok, Save, etc.)
    logoutButtonLabel: string; // Text displayed as Cancel button's caption (Cloce, Cancel, Log out, etc.)    
    elementClassName: string; // contains class name for the dialog to be displayed.
    timeElements: ExpirationTimeElements;
}

export interface AuthenticationSessionState {
    currentDialogMode: AuthenticationSessionDialogMode; // current state of the dialog
    dialogArgs: AuthenticationSessionDialogArgs; // Arguments to be used with an authentication dialog
    isSpinnerVisible: boolean; // If set indicates the spinner must be visible
}

export type AuthenticationSessionDialogStateUpdater = (newState: AuthenticationSessionState) => boolean;

interface TimeDiscrepancyParams {
    localTimeDiscrepancyMilliseconds: number; // time discrepancy between browser and server in milliseconds
    maxLifeTimeHours: number; // maximum life time of a user sesssion in hours
}

enum RefreshAttemptStatus {
    none,
    inProgress,
    failed,
    success
}

export class AuthenticationSessionController implements Disposable {    
    public dialogStateUpdater: AuthenticationSessionDialogStateUpdater;    
    public disableTimer: boolean;    
    private readonly async: Async;
    private sessionManagementEnabled: boolean;
    private timeLastChecked: Date;
    private timeLastClosed: Date;    
    private alreadyStarted = false;
    private startPostponed = false;
    private currentDialogMode: AuthenticationSessionDialogMode = AuthenticationSessionDialogMode.NoDialog;
    private blockingDialogMode: AuthenticationSessionDialogMode = AuthenticationSessionDialogMode.NoDialog;
    private authService: AuthenticationSessionService;
    private lastRefreshStatus: RefreshAttemptStatus = RefreshAttemptStatus.none;
    private whenBlockingDialogWasSet: Date;
    private unsubscribe: Unsubscribe | undefined;
    private timerId: number | undefined;
    private numberOfTicksBeforeAuthDataRefresh: number = MaxNumberOfTickBeforeAuthDataRefresh; 

    static getElementClassName(mode: AuthenticationSessionDialogMode) {
        return 'stp-session-dialog-' + mode.toString();
    }

    constructor(stateUpdater: AuthenticationSessionDialogStateUpdater, featureIsActiveGetter?: () => boolean ) {
        this.disableTimer = false;
        this.async = new Async(this);
        if (featureIsActiveGetter !== undefined) {
            this.sessionManagementEnabled = featureIsActiveGetter();
        } else {            
            this.unsubscribe = store.subscribe(() => {
                if (store.getState().applicationContext) {
                    const user = store.getState().applicationContext.userInfo;
                    if (user && user.anonymousId) {
                        this.unsubscribeFromStore();
                        this.sessionManagementEnabled = 
                        AuthorizationInfo.isIncludedInRequiredFlights([SessionManagementFlightName] , 
                                                                      FlightMembership.Any);
                        if (this.startPostponed && this.sessionManagementEnabled) {
                            this.startTimer();
                        }
                    }                                            
                }
            });
        }
        this.timeLastChecked = new Date(0);
        this.whenBlockingDialogWasSet = this.timeLastChecked;
        this.timeLastClosed = this.timeLastChecked;
        this.dialogStateUpdater = stateUpdater;
        this.authService = new AuthenticationSessionService();        
    }

    // Me must dispose here since async requires disposal
    dispose() {
        this.unsubscribeFromStore();
        this.async.dispose();        
        this.timeLastChecked = new Date(0);
        this.timeLastClosed = this.timeLastChecked;
        this.dialogStateUpdater = (m) => false;
        this.currentDialogMode = AuthenticationSessionDialogMode.NoDialog;        
    }

    // Returns authentication data
    get AuthProps(): AuthData | undefined {
        if (!this.HasAuthContext) {
            return undefined;
        }
        if (authContext.user === null || authContext.user === undefined) {
            return undefined;
        }
        return authContext.user.authData;
    }

    get SessionBeginAt(): Date {
        return this.getAuthSessionDate((authP) => authP.sessionBeginAt);        
    }

    get SessionWarningAt(): Date {
        return this.getAuthSessionDate((authP) => authP.sessionEndWarningAt);
    }

    get SessionExpiresAt(): Date {
        return this.getAuthSessionDate((authP) => authP.expiresOn);
    }

    get SessionEndOfLifeWarningAt(): Date {
        return this.getAuthSessionDate((authP) => authP.sessionEolWarningAt);
    }   
    
    get SessionEndOfLifeAt(): Date {
        return this.getAuthSessionDate((authP) => authP.sessionEolAt);
    }

    get IsTimeToDisplaySessionWarning(): boolean {
        return this.getIsTimeUp(() => this.SessionWarningAt);
    }

    get IsTimeToDisplaySessionExpiredMessage(): boolean {
        return this.getIsTimeUp(() => this.SessionExpiresAt);
    }

    get IsTimeToDisplaySessionEndOfLifeWarning(): boolean {
        return this.getIsTimeUp(() => this.SessionEndOfLifeWarningAt);                 
    }

    get IsTimeToDisplaySessionEndOfLifeMessage(): boolean {
        return this.getIsTimeUp(() => this.SessionEndOfLifeAt);
    }

    get MustBeInDialogMode(): AuthenticationSessionDialogMode {
        
        if (!this.sessionManagementEnabled) {
            return AuthenticationSessionDialogMode.NoDialog;
        }

        if (this.blockingDialogMode !== AuthenticationSessionDialogMode.NoDialog) {
            if (this.blockExistsButExpired()) {
                return AuthenticationSessionDialogMode.NoDialog;
            }
            return this.blockingDialogMode;
        }

        if (this.IsTimeToDisplaySessionEndOfLifeMessage) {
            return AuthenticationSessionDialogMode.SessionEndOfLifeMessage;
        }
        if (this.IsTimeToDisplaySessionEndOfLifeWarning) {
            if (this.lastRefreshStatus === RefreshAttemptStatus.none || this.lastRefreshStatus === RefreshAttemptStatus.failed) {
                return AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning;
            }            
        }        
        if (this.IsTimeToDisplaySessionExpiredMessage) {
            return AuthenticationSessionDialogMode.SessionExpiredMessage;
        }
        if (this.IsTimeToDisplaySessionWarning) {
            return AuthenticationSessionDialogMode.SessionAboutToExpireWarning;
        }
        return AuthenticationSessionDialogMode.NoDialog;
    }
    
    startTimer(): void {
        if (this.alreadyStarted || this.disableTimer || !this.sessionManagementEnabled) {            
            this.startPostponed = true;
            return;
        }
        this.alreadyStarted = true;
        this.timerId = this.async.setInterval(
            () => this.checkSessionStatus(),
            PollingIntervalMilliseconds
        );
    }

    processContinueAction(): void {
        authContext.RefreshAuthData(); // first check if session has been renewed from another tab.
        switch (this.MustBeInDialogMode) {
            case AuthenticationSessionDialogMode.SessionAboutToExpireWarning:                
                this.lastRefreshStatus = RefreshAttemptStatus.inProgress;
                this.authService.refreshAuthSession(
                    (response) => {
                        this.lastRefreshStatus = RefreshAttemptStatus.success;                        
                        authContext.RefreshAuthData();
                        // Older browsers (IE11 and earlier) may not be able to read response headers even
                        // when CORS policies allow it. If that's the case then we need to use this response
                        // result to set refreshed authentication session properties.
                        const newtoken = response.data;
                        if (newtoken && newtoken.refreshToken && newtoken.refreshToken.length > 0) {
                            if (this.HasAuthToken && this.AuthProps) {
                                const existingTokenProps = this.AuthProps;
                                if (existingTokenProps.token !== newtoken.refreshToken) {
                                    authContext.setAuthenticationProperties(newtoken.refreshToken);
                                }                                
                            }
                        }                        
                    },
                    (response) => {
                        this.lastRefreshStatus = RefreshAttemptStatus.failed;
                    }
                );
                this.processUserAction();
                break;
            case AuthenticationSessionDialogMode.NoDialog:
            case AuthenticationSessionDialogMode.FailedToRefreshSession:
                this.processUserAction();
                break;
            case AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning:
                this.processLogoutAction();
                break;
            default:
                this.processLogoutAction();
                break;
        }        
    }

    processLogoutAction(): void {                
        if (this.HasAuthContext && authContext.isSignedIn()) {
            authContext.logOut();
        } else if (window && window.location) {
            window.location.reload(true);
        }
    }

    // Returns default dialog state
    getDefaultState(): AuthenticationSessionDialogArgs {
        return {
            title: '',
            timeElements: this.getDefaultTimeElements(),
        } as AuthenticationSessionDialogArgs;
    }

    prepareNewState(newMode: AuthenticationSessionDialogMode): AuthenticationSessionState {
        let timeElements = this.getDefaultTimeElements();
        if (newMode === AuthenticationSessionDialogMode.SessionAboutToExpireWarning) {
            timeElements = this.prepareExiringStateDateElements(newMode, this.SessionExpiresAt);            
        } else if (newMode === AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning) {
            timeElements = this.prepareExiringStateDateElements(newMode, this.SessionEndOfLifeAt);
        }
        return this.prepareNewStateLabels(newMode, timeElements);
    }

    public get HasAuthContext(): boolean {
        return authContext !== null && authContext !== undefined;
    }

    public get HasLastSyncDate(): boolean {
        return this.timeLastChecked && this.timeLastChecked.getTime() !== 0;
    }

    public get HasAuthToken(): boolean {        
        let authData = this.AuthProps;
        return authData !== null && authData !== undefined 
               && authData.token !== null && authData.token !== undefined
               && authData.token.length > 0;
    }
    
    // Processes timer tick and triggers update of the dialog state if necessary
    public checkSessionStatus(): void {
        this.checkIfNeedToRefreshAuthData();
        if (!this.HasAuthToken) {
            // No auth session token: clear the last check date
            this.timeLastChecked = new Date(0);
        } else if (!this.HasLastSyncDate) {
            // Init the last check date for the first use
            this.timeLastChecked = new Date();
        }
        let newDialogMode = this.MustBeInDialogMode;

        // Reset last check date
        this.timeLastChecked = new Date();

        let mustUpdateSeconds = 
            newDialogMode === AuthenticationSessionDialogMode.SessionAboutToExpireWarning ||
            newDialogMode === AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning;        
        let rememberedExpiredBlock = this.blockExistsButExpired();
        // See if it's time to update the dialog view
        if ((mustUpdateSeconds || newDialogMode !== this.currentDialogMode) &&
            this.blockingDialogMode === AuthenticationSessionDialogMode.NoDialog &&
            this.dialogStateUpdater(this.prepareNewState(newDialogMode))) {
                this.currentDialogMode = newDialogMode;               
                this.setBlockingDialogMode(
                    this.getMustBlockFurtherUpdate(newDialogMode) ? newDialogMode : AuthenticationSessionDialogMode.NoDialog);
        }
        // We had the final expiration dialog up long enough. It's time to just close it.
        if (rememberedExpiredBlock) {
            this.processLogoutAction();
        } else if (newDialogMode === AuthenticationSessionDialogMode.SessionAboutToExpireWarning &&
                    this.lastRefreshStatus === RefreshAttemptStatus.failed) {
            // Retry refresh
            this.processContinueAction();
        }
    }

    // Clears current timer and stops session management notifications
    public clearTimer(): void {
        this.alreadyStarted = false;
        this.startPostponed = false;
        if (this.timerId) {
            try {
                this.async.clearInterval(this.timerId); 
            } catch {
                // not critical
            }
        }
        this.timerId = undefined;
    }

    private checkIfNeedToRefreshAuthData(): void {
        this.numberOfTicksBeforeAuthDataRefresh--;
        if (this.numberOfTicksBeforeAuthDataRefresh <= 0) {
            this.numberOfTicksBeforeAuthDataRefresh = MaxNumberOfTickBeforeAuthDataRefresh;
            authContext.RefreshAuthData();
        }
    }
    
    private unsubscribeFromStore(): void {
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = undefined;
        }
    }

    private getDefaultTimeElements(): ExpirationTimeElements {
        return {
            minutesBeforeExpiration: 0,
            secondsBeforeExpiration: 0,
            maxLifeTimeHours: 24,
        } as ExpirationTimeElements;
    }

    private processUserAction(): void {
        this.timeLastClosed = new Date();
        this.timeLastChecked = this.HasAuthToken ? this.timeLastClosed : (new Date(0));
        this.setBlockingDialogMode(AuthenticationSessionDialogMode.NoDialog);        
    }

    private setBlockingDialogMode(mode: AuthenticationSessionDialogMode) {
        let effectiveMode = mode;
        if (mode !== AuthenticationSessionDialogMode.SessionExpiredMessage &&
            mode !== AuthenticationSessionDialogMode.SessionEndOfLifeMessage) {
                effectiveMode = AuthenticationSessionDialogMode.NoDialog;
            }
        this.blockingDialogMode = effectiveMode;
        if (effectiveMode === AuthenticationSessionDialogMode.NoDialog) {
            this.whenBlockingDialogWasSet = new Date(0);
        } else {
            this.whenBlockingDialogWasSet = new Date();
        }
    }

    private getMustBlockFurtherUpdate(newDialogMode: AuthenticationSessionDialogMode): boolean {
        let result = (
            newDialogMode === AuthenticationSessionDialogMode.SessionAboutToExpireWarning || 
            newDialogMode === AuthenticationSessionDialogMode.SessionExpiredMessage ||
            newDialogMode === AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning);
        if (result && this.blockExistsButExpired()) {
            result = !result;
        }
        return result;
    }
    
    private blockExistsButExpired(): boolean {
        let blockTimeMilliseconds = this.whenBlockingDialogWasSet.getTime();
        if (blockTimeMilliseconds === 0) {
            return false;
        }
        if (this.blockingDialogMode !== AuthenticationSessionDialogMode.SessionExpiredMessage &&
            this.blockingDialogMode !== AuthenticationSessionDialogMode.SessionEndOfLifeMessage) {
                return false;
        }
        return ((new Date()).getTime() - blockTimeMilliseconds) > TimeBeforeDismissSessionDialog;
    }   

    // Returns new state with initialized minutes and seconds for the expiration date contains
    private prepareExiringStateDateElements(newMode: AuthenticationSessionDialogMode, expDate: Date): ExpirationTimeElements {
        const millisecondsInMinute = 60000;
        let result: ExpirationTimeElements = this.getDefaultTimeElements();
        if (expDate !== new Date(0) && 
            (newMode === AuthenticationSessionDialogMode.SessionAboutToExpireWarning ||
             newMode === AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning)) {
            let discrepancy = this.DiscrepancyParams;
            let timeDifferenceMilliseconds = expDate.getTime() - this.timeLastChecked.getTime() - discrepancy.localTimeDiscrepancyMilliseconds;
            let timeDifferenceWholeMinutes = Math.floor(timeDifferenceMilliseconds / millisecondsInMinute);
            result = {
                minutesBeforeExpiration: timeDifferenceWholeMinutes,
                secondsBeforeExpiration: Math.round((timeDifferenceMilliseconds - timeDifferenceWholeMinutes * millisecondsInMinute) / 1000),
                maxLifeTimeHours: discrepancy.maxLifeTimeHours,
            } as ExpirationTimeElements;
        }
        return result;
    }

    private prepareState(newMode: AuthenticationSessionDialogMode): AuthenticationSessionState {
        return { 
            currentDialogMode: newMode,
            isSpinnerVisible: false
        } as AuthenticationSessionState;
    }

    // Prepares localized authenticationSessionState labels
    private prepareNewStateLabels(
        newMode: AuthenticationSessionDialogMode, elements: ExpirationTimeElements):
        AuthenticationSessionState {
        let result = this.prepareState(newMode);

        switch (newMode) {            
            case AuthenticationSessionDialogMode.SessionAboutToExpireWarning:
                const continueLabel = Intl.Get(LocIds.AuthenticationSessionExpiring.ContunueButtonLabel);
                const logOutLabel = Intl.Get(LocIds.AuthenticationSessionExpiring.LogOutButtonLabel);        
                result.dialogArgs = {
                   title: Intl.Get(LocIds.AuthenticationSessionExpiring.Title),
                   expirationMessage: Intl.Get(LocIds.AuthenticationSessionExpiring.ExpirationMessage, { 
                        Minutes: elements.minutesBeforeExpiration,
                        Seconds: elements.secondsBeforeExpiration,
                        Hours: elements.maxLifeTimeHours,
                   }),
                   instructionMessage: Intl.Get(LocIds.AuthenticationSessionExpiring.InstructionMessage, {
                        Continue: continueLabel,
                        LogOut: logOutLabel
                    }),
                   continueButtonLabel: continueLabel,
                   logoutButtonLabel: logOutLabel,
                   timeElements: elements,
                } as AuthenticationSessionDialogArgs;
                break;
            case AuthenticationSessionDialogMode.SessionExpiredMessage:
                result.dialogArgs = {
                   title: Intl.Get(LocIds.AuthenticationSessionExpired.Title),
                   expirationMessage: Intl.Get(LocIds.AuthenticationSessionExpired.ExpirationMessage),
                   instructionMessage: Intl.Get(LocIds.AuthenticationSessionExpired.InstructionMessage),
                   continueButtonLabel: '',
                   logoutButtonLabel: Intl.Get(LocIds.AuthenticationSessionExpired.CloseButtonLabel),
                   timeElements: elements,
                } as AuthenticationSessionDialogArgs;
                break;
            case AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning:
                result.dialogArgs = {
                   title: Intl.Get(LocIds.AuthenticationSessionEndOfLifeWarning.Title),
                   expirationMessage: Intl.Get(LocIds.AuthenticationSessionEndOfLifeWarning.ExpirationMessage, { 
                        Minutes: elements.minutesBeforeExpiration,
                        Seconds: elements.secondsBeforeExpiration,
                        Hours: elements.maxLifeTimeHours,
                   }),
                   instructionMessage: Intl.Get(LocIds.AuthenticationSessionEndOfLifeWarning.InstructionMessage),
                   continueButtonLabel: Intl.Get(LocIds.AuthenticationSessionEndOfLifeWarning.CloseButtonLabel),
                   logoutButtonLabel: Intl.Get(LocIds.AuthenticationSessionEndOfLifeWarning.LogOutButtonLabel),
                   timeElements: elements,
                } as AuthenticationSessionDialogArgs;
                break;
            case AuthenticationSessionDialogMode.SessionEndOfLifeMessage:
                result.dialogArgs = {
                   title: Intl.Get(LocIds.AuthenticationSessionExpired.Title),
                   expirationMessage: Intl.Get(LocIds.AuthenticationSessionEndOfLife.ExpirationMessage),
                   instructionMessage: Intl.Get(LocIds.AuthenticationSessionEndOfLife.InstructionMessage),
                   continueButtonLabel: Intl.Get(LocIds.AuthenticationSessionEndOfLife.CloseButtonLabel),                   
                   timeElements: elements,
                } as AuthenticationSessionDialogArgs;
                break;
            default:
                result.dialogArgs = this.getDefaultState();
        }        
        result.dialogArgs.elementClassName = AuthenticationSessionController.getElementClassName(newMode);
        return result;
    }    
    
    private getAuthSessionDate(dateReader: (authSource: AuthData) => string): Date {
        let authP = this.AuthProps;
        if (authP !== undefined) {
            var dateReturned = dateReader(authP);
            if (dateReturned !== undefined && dateReturned.length > 0) {
                return new Date(1000 * parseInt(dateReturned, 10));
            }            
        }
        return new Date(0);
    }

    private getIsTimeUp(warningDateReader: () => Date): boolean {
        let nullTime = 0;
        if (this.timeLastChecked.getTime() === nullTime) {
            return false;
        }
        let currentTime = (new Date()).getTime() + this.DiscrepancyParams.localTimeDiscrepancyMilliseconds;
        let lastClosedTime = this.timeLastClosed.getTime();        
        if (lastClosedTime > 0 && (currentTime - lastClosedTime) < DialogDelayMilliseconds) {
            return false;
        }

        let warnAt = warningDateReader();
        let warnTime = warnAt.getTime();
        if (warnTime === nullTime) {
            return false;
        }        
        return warnTime <= currentTime;
    }

    private get DiscrepancyParams(): TimeDiscrepancyParams {
        let authData = this.AuthProps;
        let sessionLifeTimeHours = 24;
        let localTimeDiscrepancy: number = 0;
        if (authData !== undefined) {
            // read milliseconds adjustment value for the local vs. server utc time discrepancy
            localTimeDiscrepancy = authData.localTimeDiscrepancyMilliseconds;
            sessionLifeTimeHours = Math.ceil((parseInt(authData.sessionEolAt, 10) - parseInt(authData.sessionBeginAt, 10)) / 3600);
        }
        return {
            localTimeDiscrepancyMilliseconds: localTimeDiscrepancy,
            maxLifeTimeHours: sessionLifeTimeHours,
        } as TimeDiscrepancyParams;
    }
}