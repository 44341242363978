import * as React from 'react';
import { MicrosoftServiceLinksServiceClient } from '../../Services/MicrosoftServiceLinksService';
import { store } from '../../../Store';
import * as PrivacyPortalReducer from '../../Reducers/PrivacyPortal';
import { MicrosoftServiceLinks, ServiceLinks } from '../../Models/PrivacyPortal';
import { ErrorResponse } from '../../Services/Models';
import { AxiosResponse } from 'axios';
import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import './ExportServiceLinksDirectoryPage.css';
import { ExternalNavigationService } from '../../Services/ExternalNavigationService';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

let eppExternalNavigationTag = ['EPPDataExport'];
let externalNavigation = new ExternalNavigationService();

export interface ExportServiceLinksDirectoryPageProps {
    microsoftServiceLinks: MicrosoftServiceLinks;
    allServiceLinks: ServiceLinks;
    mostRequestedServices: ServiceLinks;
    status: string;
}

export default class ExportServiceLinksDirectoryPage extends React.Component<ExportServiceLinksDirectoryPageProps> {

    constructor(props: ExportServiceLinksDirectoryPageProps) {
        super(props);
    }

    getAllServicesBlock() {
        return (
            this.props.allServiceLinks.map((service, index) => (
                <tr key={service.id}>
                    <td>{service.serviceName}</td>
                    <td>{service.productName}</td>
                    <td>
                        <a
                            href={service.accessUrl}
                            target="_blank"
                            className="c-hyperlink"
                            onClick={() =>
                                externalNavigation.navigateTo({
                                    url: service.accessUrl,
                                    tags: eppExternalNavigationTag
                                })
                            }
                        >
                            {service.accessUrl}
                        </a>
                    </td>
                    <td><a href={service.documentationUrl} target="_blank" className="c-hyperlink">{service.documentationUrl}</a></td>
                </tr>
            ))
        );
    }

    getMostRequestedServicesBlock() {
        return (
            this.props.mostRequestedServices.map((service, index) => (
                <tr key={service.id}>
                    <td>{service.serviceName}</td>
                    <td>{service.productName}</td>
                    <td>
                        <a
                            href={service.accessUrl}
                            target="_blank"
                            className="c-hyperlink"
                            onClick={
                                () =>
                                    externalNavigation.navigateTo({
                                        url: service.accessUrl,
                                        tags: eppExternalNavigationTag
                                    })
                            }
                        >
                            {service.accessUrl}
                        </a>
                    </td>
                    <td><a href={service.documentationUrl} target="_blank" className="c-hyperlink">{service.documentationUrl}</a></td>
                </tr>
            ))
        );
    }

    getCurrentMicrosoftServiceLinks(): void {
        MicrosoftServiceLinksServiceClient.getCurrentLinks(
            (response: AxiosResponse) => {
                store.dispatch(PrivacyPortalReducer.getServiceLinksRequestSucceedAction('', response.data));
            },
            (failed: ErrorResponse) => {
                let message: string = failed.code + ' ' + failed.status + ' - ' + failed.message;
                store.dispatch(PrivacyPortalReducer.getServiceLinksRequestErrorAction(message));
            });
    }

    public componentDidMount(): void {
        this.getCurrentMicrosoftServiceLinks();
    }

    render() {
        window.scrollTo(0, 0);
        return (
            <div>
                {this.props.status === 'Init' &&
                    <Spinner
                        className="loading-panel-full"
                        size={SpinnerSize.large}
                        label={Intl.Get(LocIds.Spinner.LoadingLabel)}
                        ariaLive="assertive"
                    />
                }
                <div data-grid="col-12">
                    <div data-grid="col-8">
                        <h6 className="col-12 c-heading-6">{Intl.Get(LocIds.GDPRCompliance.ExportServiceHeaderTitle)}</h6>
                        <h3 className="col-12 c-heading-3 tile-Progress" style={{ padding: 0 }}>{Intl.Get(LocIds.GDPRCompliance.ExportServiceHeaderSubTitle)}</h3>
                    </div>
                </div>
                <div data-grid="col-12" className="contentContainer">
                    <h4 className="c-heading-5">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportTitle)}</h4>
                    <p className="c-caption-1">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportDescription)}</p>
                    <div className="c-table">
                        <table>
                            <caption>{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportMostRequestedServicesHeader)}</caption>
                            <thead>
                                <tr>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportServiceColumn)}</th>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportProductColumn)}</th>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportAccessColumn)}</th>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportDocumentationColumn)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getMostRequestedServicesBlock()}
                            </tbody>
                        </table>
                    </div>
                    <hr className="c-subdivider f-pad-top-6x" />
                    <div className="c-table">
                        <table>
                            <caption>{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportAllServicesHeader)}</caption>
                            <thead>
                                <tr>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportServiceColumn)}</th>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportProductColumn)}</th>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportAccessColumn)}</th>
                                    <th scope="col">{Intl.Get(LocIds.GDPRCompliance.ExportServiceIndividualDataExportDocumentationColumn)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getAllServicesBlock()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}