import { StoreState } from '../../../Store';
import { connect } from 'react-redux';
import { ExportedFilesPageProps } from '../../Pages/PrivacyPortal/ExportedFilesPage';
import ExportedFilesPage from '../../Pages/PrivacyPortal/ExportedFilesPage';
import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { IPersonaProps } from 'office-ui-fabric-react';

interface ContainerProps {
    exportId: string;
}

function mapStateToProps(state: StoreState, containerProps: ContainerProps): ExportedFilesPageProps {
    if (state.privacyPortalExportedFiles.privacyOperation.State === 0) {
        state.privacyPortalExportedFiles.privacyOperation.StateText = Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOperationStatusPendingLabel);
    } else if (state.privacyPortalExportedFiles.privacyOperation.State === 1) {
        state.privacyPortalExportedFiles.privacyOperation.StateText = Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOperationStatusCompletedLabel);
    }
    let userInfo: IPersonaProps = {} as IPersonaProps;
    let currentState = state.privacyPortalExportedFiles;
    if (currentState.privacyOperation.User) {
        let imageUri: string = currentState.privacyOperation.User.image && currentState.privacyOperation.User.image.length ? currentState.privacyOperation.User.image : '';
        let secondaryText: string = currentState.privacyOperation.User.mail && currentState.privacyOperation.User.mail.length ? currentState.privacyOperation.User.mail : currentState.privacyOperation.User.userPrincipalName;
        userInfo = <IPersonaProps> { imageUrl: imageUri, primaryText: currentState.privacyOperation.User.displayName, secondaryText: secondaryText };
    }

    return {
        exportId: containerProps.exportId,
        files: state.privacyPortalExportedFiles.exportedFiles,
        status: state.privacyPortalExportedFiles.status,
        message: state.privacyPortalExportedFiles.message,
        privacyOperation: state.privacyPortalExportedFiles.privacyOperation,
        user: userInfo
    };
}

export default connect<ExportedFilesPageProps, null, ContainerProps>(mapStateToProps)(ExportedFilesPage);
