import { Icon, Stack } from 'office-ui-fabric-react';
import * as React from 'react'
import './ChatWindowHeader.css';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from './../../../Services/GlobalizationService';

interface ChatWindowHeaderProps {
    title: string;
    onDismiss: () => void
}

export const ChatWindowHeader: React.FC<ChatWindowHeaderProps> = (props) => {
    const { title, onDismiss } = props;
    return (
        <div className='chatWindowHeader__wrapper'>         
            <Stack horizontal verticalAlign='center' horizontalAlign='space-between' className="chatWindowHeaderStack">
                <p>{title}</p>
                <Icon className="chatWindowHeader__close" iconName='ChromeClose' onClick={onDismiss}/>
            </Stack>
            <div className='chatWindowHeaderDisclosure'>{Intl.Get(LocIds.CopilotChatBot.AIDisclosure)}</div>
        </div>
    )
}