import { ScopedAuthorizationRights, AuthorizationInfo, AuthorizationActions } from '../Utilities/AuthorizationInfo';
import { AuthorizationScopes, ComplianceManagerConstants } from '../Models/ComplianceManagerConstants';
import { ScopePermissions } from '../Models/ScopesPermissions';

export class ScopeAuthorizationService {
    getScopesForAuthorization(Scopes: AuthorizationScopes): ScopedAuthorizationRights[] {
        switch (Scopes) {
            case AuthorizationScopes.ComplianceManagerV3:
                return [
                    {
                        scope: ComplianceManagerConstants.Scopes.ComplianceManagerV3Customer,
                        rights: {
                            actions: [AuthorizationActions.Read,
                            ]
                        }
                    },
                    {
                        scope: ComplianceManagerConstants.Scopes.ComplianceManagerV3Evidence,
                        rights: {
                            actions: [AuthorizationActions.Read,
                            ]
                        }
                    },
                    {
                        scope: ComplianceManagerConstants.Scopes.ComplianceManagerV3Test,
                        rights: {
                            actions: [AuthorizationActions.Read,
                            ]
                        }
                    }
                ] as ScopedAuthorizationRights[];
            case AuthorizationScopes.TenantManagement:
                return [
                    {
                        scope: ComplianceManagerConstants.Scopes.ComplianceManagerV3Master,
                        rights: {
                            actions: [AuthorizationActions.Read]
                        }
                    }
                ] as ScopedAuthorizationRights[];
            default:
                return [];
        }
    }

    public getScopePermissionForComplianceManagerV3(): ScopePermissions {
        return {
            canModifyComplianceManagerV3Master: AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Master, [{ actions: [AuthorizationActions.Modify] }]),
            canViewComplianceManagerV3Master: AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Master, [{ actions: [AuthorizationActions.Read] }]),
            canViewComplianceManagerV3Customer: AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Customer, [{ actions: [AuthorizationActions.Read] }]),
            canModifyComplianceManagerV3Customer: AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Customer, [{ actions: [AuthorizationActions.Modify] }]),
            canViewTenantData: this.CanViewTenantData(),
            canModifyTenantData: this.CanModifyTenantData(),
            canViewTestData: this.CanViewTestData(),
            canModifyTestData: this.CanModifyTestData(),
            canViewEvidenceData: this.CanViewEvidenceData(),
            canModifyEvidenceData: this.CanModifyEvidenceData(),
            isGlobalAdmin: this.isGlobalAdmin()
        }
    }

    private CanViewTenantData(): boolean {
        return (AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Customer, [{ actions: [AuthorizationActions.Read] }]));
    }

    private CanModifyTenantData(): boolean {
        return (AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Customer, [{ actions: [AuthorizationActions.Modify] }]));
    }

    private CanViewEvidenceData(): boolean {
        return (AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Evidence, [{ actions: [AuthorizationActions.Read] }]));
    }

    private CanModifyEvidenceData(): boolean {
        return (AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Evidence, [{ actions: [AuthorizationActions.Modify] }]));
    }

    private CanViewTestData(): boolean {
        return (AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Test, [{ actions: [AuthorizationActions.Read] }]));
    }

    private CanModifyTestData(): boolean {
        return (AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerV3Test, [{ actions: [AuthorizationActions.Modify] }]));
    }

    private isGlobalAdmin(): boolean {
        return (AuthorizationInfo.isIncludedInRequiredFlights(ComplianceManagerConstants.ComplianceManagerV3GlobalTenantFlights)) 
                && (AuthorizationInfo.isAuthorizedForAny(ComplianceManagerConstants.Scopes.ComplianceManagerGlobalUser, [{ actions: [AuthorizationActions.Modify] }]));
    }
}

export let ScopeAuthorizationClientService = new ScopeAuthorizationService();