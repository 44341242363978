import { PanelData } from '../../Models/PageInfrastructure';
import { PanelPropertiesDataBase, PanelColumnsPropertiesDataBase, ColumnPropertiesDataBase,
    DocumentPanelPropertiesData,
    HeaderPanelPropertiesData,
    ViewPanelPropertiesData,
    AreaHeadingPanelPropertiesData,
    AreaHeadingWithImagePanelPropertiesData,
    RichHeadingPanelPropertiesData,
    ContentPivotPanelPropertiesData, ContentPivotPanelColumnPropertiesData,
    ContentRichBlockPanelPropertiesData, ContentRichBlockPanelColumnPropertiesData,
    ContentPlacementPanelPropertiesData, ContentPlacementColumnPropertiesData,
    ContentPlacementIconPanelPropertiesData, ContentPlacementIconColumnPropertiesData,
    ContentPlacementImagePanelPropertiesData, ContentPlacementImageColumnPropertiesData,
    LinkListPropertiesData, LinkListColumnPropertiesData,
    HeroItemPanelPropertiesData,
    HeroPanelPropertiesData, HeroPanelColumnPropertiesData,
    FeaturePanelPropertiesData,
    MosaicPanelPropertiesData, MosaicPanelColumnPropertiesData,
    TopResourcePanelPropertiesData, TopResourceColumnPanelPropertiesData,
    StructuredListPanelPropertiesData,
    FAQPanelPropertiesData,
    QuotePanelPropertiesData,
    CardPanelPropertiesData, CardColumnPanelPropertiesData,
    GuideHelpPanelPropertiesData, GuideHelpColumnPropertiesData,
    SortFilterDocumentPanelPropertiesData,
    DocumentListPanelPropertiesData,
    ImageRichTextPanelPropertiesData,
    LibraryNotificationPanelPropertiesData,
    LeftRightViewsPanelPropertiesData,
    SidebarGridPanelPropertiesData,
    SidebarGridColumnPropertiesData,
    CtaPanelPropertiesData,
} from '../../Models/PageInfrastructure/PanelPropertiesData';
import { Theme, TextPosition, CallToActionTheme, MainPanelOptions } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { IComboBoxOption } from 'office-ui-fabric-react';
import { LocalizedString } from '../../../Common/Models';
import Constants from '../Constants';

export const newPanelImage: string = '/Images/Panel-Infrastructure/Missing-Image.png';
export const newPanelCallToActionLabel: string = 'SEE MORE';

export function moveSelectedColumn(panelData: PanelColumnsPropertiesDataBase<ColumnPropertiesDataBase>, selectedIndex: number, left: boolean) {
    let index: number;
    if (left) {
        index = selectedIndex - 1;
    } else {
        index = selectedIndex + 1;
    }
    if (panelData.columns && index < panelData.columns.length && (selectedIndex < panelData.columns.length)) {
        let column = panelData.columns[index];
        panelData.columns[index] = panelData.columns[selectedIndex];
        panelData.columns[selectedIndex] = column;
    }
}

export function deleteOnePanelFromCollection(panelData: PanelColumnsPropertiesDataBase<ColumnPropertiesDataBase>, selectedIndex: number) {
    if (panelData.columns) {
        let columns = panelData.columns;
        panelData.columns = [];

        for (let i: number = 0; i < columns.length; i++) {
            if (i !== selectedIndex) {
                panelData.columns.push(columns[i]);
            }
        }
    }
}

export function getPositionOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: TextPosition[TextPosition.LeftTop], text: 'Left Top' },
        { key: TextPosition[TextPosition.LeftMiddle], text: 'Left Middle' },
        { key: TextPosition[TextPosition.LeftBottom], text: 'Left Bottom' },
        { key: TextPosition[TextPosition.CenterTop], text: 'Center Top' },
        { key: TextPosition[TextPosition.CenterMiddle], text: 'Center Middle' },
        { key: TextPosition[TextPosition.CenterBottom], text: 'Center Bottom' },
        { key: TextPosition[TextPosition.RightTop], text: 'Right Top' },
        { key: TextPosition[TextPosition.RightMiddle], text: 'Right Middle' },
        { key: TextPosition[TextPosition.RightBottom], text: 'Right Bottom' },
    ];
    return items;
}

export function getPositionClassName(position: TextPosition) {
    let ret: string = 'm-rich-heading f-image';
    switch (position) {
        case TextPosition.LeftTop:
            ret = 'f-x-left f-y-top';
            break;
        case TextPosition.CenterTop:
            ret = 'f-x-center f-y-top';
            break;
        case TextPosition.RightTop:
            ret = 'f-x-right f-y-top';
            break;
        case TextPosition.LeftMiddle:
            ret = 'f-x-left f-y-center';
            break;
        case TextPosition.CenterMiddle:
            ret = 'f-x-center f-y-center';
            break;
        case TextPosition.RightMiddle:
            ret = 'f-x-right f-y-center';
            break;
        case TextPosition.LeftBottom:
            ret = 'f-x-left f-y-bottom';
            break;
        case TextPosition.CenterBottom:
            ret = 'f-x-center f-y-bottom';
            break;
        case TextPosition.RightBottom:
            ret = 'f-x-right f-y-bottom';
            break;
        default:
    }
    return ret;
}

export function getLinkOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: 'Page', text: 'Page' },
        { key: 'URL', text: 'Extenal URL' }
    ];
    return items;
}

export function getThemeOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: Theme[Theme.DarkImage], text: 'Dark Theme Image' },
        { key: Theme[Theme.DarkAccent], text: 'Dark Theme Accent' },
        { key: Theme[Theme.DarkNeutral], text: 'Dark Theme Neutral' },
        { key: Theme[Theme.LightNeutral], text: 'Light Theme Neutral' },
    ];
    return items;
}

export function getSimpleThemeOptions(allowTransparent: boolean = false): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: Theme[Theme.Dark], text: 'Dark' },
        { key: Theme[Theme.Light], text: 'Light' }
    ];
    if (allowTransparent) {
        items.unshift({ key: Theme[Theme.None], text: 'None' });
    }
    return items;
}

export function getStructuredListThemeOptions(allowTransparent: boolean = false): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: Theme[Theme.Dark], text: 'Dark' },
        { key: Theme[Theme.Light], text: 'Light' },
        { key: Theme[Theme.Border], text: 'Border' }
    ];
    if (allowTransparent) {
        items.unshift({ key: Theme[Theme.None], text: 'None' });
    }
    return items;
}

export function getRichTextThemeOptions(allowTransparent: boolean = false): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: Theme[Theme.Grey], text: 'Grey' },
        { key: Theme[Theme.Light], text: 'Light' }
    ];
    if (allowTransparent) {
        items.unshift({ key: Theme[Theme.None], text: 'None' });
    }
    return items;
}

export function getMosaicPatternOptions(): IComboBoxOption[] {
    const items: IComboBoxOption[] = [
        { key: 'pattern-1', text: 'Pattern 1' },
        { key: 'pattern-2', text: 'Pattern 2' }
    ];
    return items;
}

export function getSimpleCallToActionThemeOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: CallToActionTheme[CallToActionTheme.Simple], text: 'Simple' },
        { key: CallToActionTheme[CallToActionTheme.Primary], text: 'Primary' },
        { key: CallToActionTheme[CallToActionTheme.Secondary], text: 'Secondary' }
    ];
    return items;
}

export function getHeroItemLayoutOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: 'Thumbnail', text: 'Thumbnail' },
        { key: 'Featured', text: 'Featured' }
    ];
    return items;
}

export function getHeroItemThemeOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: Theme[Theme.Light], text: 'Light' },
        { key: 'Blue', text: 'Blue' }
    ];
    return items;
}

export function getAlignOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: 'None', text: 'None' },
        { key: 'Left', text: 'Left' },
        { key: 'Center', text: 'Center' },
        { key: 'Right', text: 'Right' }
    ];
    return items;
}

export function getBackgroundMaskOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: '', text: 'None' },
        { key: 'f-mask-10', text: 'f-mask-10' },
        { key: 'f-mask-20', text: 'f-mask-20' },
        { key: 'f-mask-40', text: 'f-mask-40' },
        { key: 'f-mask-60', text: 'f-mask-60' },
        { key: 'f-mask-80', text: 'f-mask-80' },
        { key: 'f-mask-100', text: 'f-mask-100' }
    ];
    return items;
}

export function getFeatureAlignmentOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: 'f-align-left', text: 'Copy Left' },
        { key: 'f-align-right', text: 'Copy Right' },
        { key: 'f-align-center', text: 'Copy Center' }
    ];
    return items;
}

export function getFeatureBackgroundOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: '', text: 'None (White)' },
        { key: 'f-background-neutral-10', text: 'Neutral 10%' },
        { key: 'f-background-neutral-20', text: 'Neutral 20%' },
        { key: 'f-background-neutral-30', text: 'Neutral 30%' },
        { key: 'f-background-neutral-45', text: 'Neutral 45%' },
        { key: 'f-background-neutral-55', text: 'Neutral 55%' },
        { key: 'f-background-neutral-65', text: 'Neutral 65%' },
        { key: 'f-background-neutral-75', text: 'Neutral 75%' },
        { key: 'f-background-neutral-80', text: 'Neutral 80%' },
        { key: 'f-background-neutral-90', text: 'Neutral 90%' },
        { key: 'f-background-neutral-100', text: 'Neutral 100%' },
        { key: 'f-background-accent', text: 'Accent Color' }
    ];
    return items;
}

export function getBackgroundColorOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: '', text: 'None' },
        { key: 'f-background-neutral-10', text: 'Neutral 10%' },
        { key: 'f-background-neutral-20', text: 'Neutral 20%' },
        { key: 'f-background-neutral-30', text: 'Neutral 30%' },
        { key: 'f-background-neutral-45', text: 'Neutral 45%' },
        { key: 'f-background-neutral-55', text: 'Neutral 55%' },
        { key: 'f-background-neutral-65', text: 'Neutral 65%' },
        { key: 'f-background-neutral-75', text: 'Neutral 75%' },
        { key: 'f-background-neutral-80', text: 'Neutral 80%' },
        { key: 'f-background-neutral-90', text: 'Neutral 90%' },
        { key: 'f-background-neutral-100', text: 'Neutral 100%' },
        { key: 'f-background-accent', text: 'Accent Color' }
    ];
    return items;
}

export function getCallToActionOptions(): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: 'f-lightweight', text: 'Light' },
        { key: 'f-primary', text: 'Primary Button' },
        { key: 'f-secondary', text: 'Secondary Button' }

    ];
    return items;
}

export function getNeutralThemeOptions(allowTransparent: boolean = false): IComboBoxOption[] {
    var items: IComboBoxOption[] = [
        { key: Theme[Theme.Neutral10], text: 'Neutral 10' },
        { key: Theme[Theme.Neutral20], text: 'Neutral 20' },
        { key: Theme[Theme.Neutral30], text: 'Neutral 30' },
        { key: Theme[Theme.Neutral45], text: 'Neutral 45' },
        { key: Theme[Theme.Neutral55], text: 'Neutral 55' },
        { key: Theme[Theme.Neutral65], text: 'Neutral 65' },
        { key: Theme[Theme.Neutral75], text: 'Neutral 75' },
        { key: Theme[Theme.Neutral80], text: 'Neutral 80' },
        { key: Theme[Theme.Neutral90], text: 'Neutral 90' },
        { key: Theme[Theme.Neutral100], text: 'Neutral 100' },
        { key: Theme[Theme.Accent], text: 'Accent' }
    ];
    if (allowTransparent) {
        items.unshift({ key: Theme[Theme.None], text: 'None' });
    }
    return items;
}

export function getMainPanelOptions(): IComboBoxOption[] {
    return  [
        {key: MainPanelOptions[MainPanelOptions.Left], text: 'Left'},
        {key: MainPanelOptions[MainPanelOptions.Right], text: 'Right'}
    ];
}

export function getMosaicTheme(theme: string) {
    if ('Light' === theme) { return 'theme-light'; }
    if ('Dark' === theme) { return 'theme-dark'; }
    return '';
}

export function getThemeClassName(theme: Theme) {
    let ret: string = 'm-rich-heading f-image';
    switch (theme) {
        case Theme.DarkImage:
            ret = 'm-rich-heading f-image';
            break;
        case Theme.DarkAccent:
            ret = 'm-rich-heading theme-dark f-accent';
            break;
        case Theme.DarkNeutral:
            ret = 'm-rich-heading theme-dark f-brand-neutral-60';
            break;
        case Theme.LightNeutral:
            ret = 'm-rich-heading theme-light f-brand-neutral-10';
            break;
        case Theme.Dark:
            ret = 'theme-dark';
            break;
        case Theme.Light:
            ret = 'theme-light';
            break;
        case Theme.Border:
            ret = 'theme-border';
            break;
        case Theme.Neutral10:
            ret = 'f-background-neutral-10';
            break;
        case Theme.Neutral20:
            ret = 'f-background-neutral-20';
            break;
        case Theme.Neutral30:
            ret = 'f-background-neutral-30';
            break;
        case Theme.Neutral45:
            ret = 'f-background-neutral-45';
            break;
        case Theme.Neutral55:
            ret = 'f-background-neutral-55';
            break;
        case Theme.Neutral65:
            ret = 'f-background-neutral-65';
            break;
        case Theme.Neutral75:
            ret = 'f-background-neutral-75';
            break;
        case Theme.Neutral80:
            ret = 'f-background-neutral-80';
            break;
        case Theme.Neutral90:
            ret = 'f-background-neutral-90';
            break;
        case Theme.Neutral100:
            ret = 'f-background-neutral-100';
            break;
            case Theme.Accent:
            ret = 'f-background-accent';
            break;
        case Theme.None:
            ret = '';
            break;
        case Theme.Grey:
            ret = 'theme-grey';
            break;
        default:
    }
    return ret;
}

export function getCallToActionThemeClassName(theme: CallToActionTheme) {
    let ret: string = '';
    switch (theme) {
        case CallToActionTheme.Primary:
            ret = 'f-primary-stp';
            break;
        case CallToActionTheme.Secondary:
            ret = 'f-secondary-stp';
            break;
        case CallToActionTheme.Simple:
            break;
        default:
    }
    return ret;
}

export function GetPagePublicUrl(pageUrl?: string) {
    if (!pageUrl) {
        return '';
    }
    return `/ViewPage/${pageUrl}`;
}

export class PanelSelectionHelperFactory {
    public static PANEL_TYPE_POSTFIX            = 'Microsoft.Services.ServiceTrust.Model.PanelProperties.';
    public static PIVOT_PANEL                   = 'PivotPanelProperties';
    public static LEFTRIGHT_PANEL               = 'LeftRightViewPanelProperties';
    public static LINKLIST_PANEL                = 'LinkListPanelProperties';
    public static VIEW_PANEL                    = 'ViewPanelProperties';
    public static HEADER_PANEL                  = 'HeaderPanelProperties';
    public static RICH_HEADING_PANEL            = 'RichHeadingPanelProperties';
    public static CONTENT_RICH_BLOCK_PANEL      = 'ContentRichBlockPanelProperties';
    public static CONTENT_PLACEMENT             = 'ContentPlacementPanelProperties';
    public static HERO                          = 'HeroPanelProperties';
    public static FEATURE_PANEL                 = 'FeaturePanelProperties';
    public static MOSAIC                        = 'MosaicPanelProperties';
    public static LIBRARY_NOTIFICATION          = 'LibraryNotificationPanelProperties';
    public static HERO_ITEM_PANEL               = 'HeroItemPanelProperties';
    public static AREAHEADING                   = 'AreaHeadingPanelProperties';
    public static AREA_HEADING_WITH_IMAGE       = 'AreaHeadingWithImagePanelProperties';
    public static CONTENT_PLACEMENT_ICON        = 'ContentPlacementIconPanelProperties';
    public static CONTENT_PLACEMENT_IMAGE       = 'ContentPlacementImagePanelProperties';
    public static SIDEBAR_GRID                  = 'SidebarGridPanelProperties';
    public static CONTENT_PIVOT                 = 'ContentPivotPanelProperties';
    public static DOCUMENTS_PANEL               = 'DocumentPanelProperties';
    public static SORT_FILTER_DOCUMENTS_PANEL   = 'SortFilterDocumentPanelProperties';
    public static DOCUMENT_LIST_PANEL           = 'DocumentListPanelProperties';
    public static TOPRESOURCE_PANEL             = 'TopResourcePanelProperties';
    public static STRUCTEDLIST_PANEL            = 'StructuredListPanelProperties';
    public static DRAWER_PANEL                  = 'DrawerPanelProperties';
    public static FAQ_PANEL                     = 'FAQPanelProperties';
    public static GUIDE_HELP_PANEL              = 'GuideHelpPanelProperties';
    public static QUOTE_PANEL                   = 'QuotePanelProperties';
    public static CARDS_PANEL                   = 'CardsPanelProperties';
    public static IMAGE_RICH_TEXT_PANEL         = 'ImageRichTextPanelProperties';
    public static CTA_PANEL                     = 'CtaPanelProperties';

    static getPanel<T extends PanelPropertiesDataBase>(type: string): PanelData<T> {
        var uuid = require('uuid');
        let panel: PanelData<T> = { id: uuid.v1(), type: type, panelInfo: {id: uuid.v1(),  itemStorageType: PanelSelectionHelperFactory.PANEL_TYPE_POSTFIX + type } as T, views: [] };
        switch (type) {
            case PanelSelectionHelperFactory.HEADER_PANEL:
                polulateHeaderPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.DOCUMENTS_PANEL:
                polulateDocumentPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.SORT_FILTER_DOCUMENTS_PANEL:
                polulateSortFilterDocumentPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.DOCUMENT_LIST_PANEL:
                polulateDocumentListPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.RICH_HEADING_PANEL:
                polulateRichHeadingPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.AREAHEADING:
                polulateAreaHeadingPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.AREA_HEADING_WITH_IMAGE:
                polulateAreaHeadingWithImagePanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.CONTENT_RICH_BLOCK_PANEL:
                polulateContentRichBlockPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.CONTENT_PLACEMENT:
                polulateContentPlacementPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.CONTENT_PLACEMENT_ICON:
                polulateContentPlacementIconPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.MOSAIC:
                polulateMosaicPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.LIBRARY_NOTIFICATION:
                polulateLibraryNotificationPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.CONTENT_PLACEMENT_IMAGE:
                polulateContentPlacementImagePanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.SIDEBAR_GRID:
                polulateSidebarGridPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.HERO:
                polulateHeroPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.FEATURE_PANEL:
                polulateFeaturePanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.HERO_ITEM_PANEL:
                polulateHeroItemPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.LINKLIST_PANEL:
                polulateLinkListPanel(panel.panelInfo);
                break;
            case PanelSelectionHelperFactory.TOPRESOURCE_PANEL:
                polulateTopResourcePanel(panel.panelInfo);
                break;
            case PanelSelectionHelperFactory.STRUCTEDLIST_PANEL:
                polulateStructedListPanel(panel.panelInfo);
                break;
            case PanelSelectionHelperFactory.CONTENT_PIVOT:
                polulateContentPivotPanel(panel.panelInfo);
                break;
            case PanelSelectionHelperFactory.FAQ_PANEL:
                const panelInf: unknown = panel.panelInfo;
                polulateFAQPanel(panelInf as FAQPanelPropertiesData);
                break;
            case PanelSelectionHelperFactory.PIVOT_PANEL:
                panel.panelInfo.itemStorageType = PanelSelectionHelperFactory.PANEL_TYPE_POSTFIX + PanelSelectionHelperFactory.VIEW_PANEL;
                polulatePivotPanel(panel);
                return panel;
            case PanelSelectionHelperFactory.LEFTRIGHT_PANEL:
                const panelUnknown: unknown = panel;
                polulateLeftRightPanel(panelUnknown as PanelData<LeftRightViewsPanelPropertiesData>);
                break;
            case PanelSelectionHelperFactory.DRAWER_PANEL:
                panel.panelInfo.itemStorageType = PanelSelectionHelperFactory.PANEL_TYPE_POSTFIX + PanelSelectionHelperFactory.VIEW_PANEL;
                polulateDrawerPanel(panel);
                break;
            case PanelSelectionHelperFactory.CARDS_PANEL:
                panel.panelInfo.itemStorageType = PanelSelectionHelperFactory.PANEL_TYPE_POSTFIX + PanelSelectionHelperFactory.CARDS_PANEL;
                populateCardsPanel(panel.panelInfo);
                break;
            case PanelSelectionHelperFactory.GUIDE_HELP_PANEL:
                polulateGuideHelpPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.QUOTE_PANEL:
                polulateQuotePanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.IMAGE_RICH_TEXT_PANEL:
                populateImageRichTextPanel(panel.panelInfo);
                return panel;
            case PanelSelectionHelperFactory.CTA_PANEL:
                populateCtaPanel(panel.panelInfo);
                return panel;
            default:
                throw new Error('Invalid PanelType was provided.');
        }
        return panel;
    }
}

export function polulateDocumentPanel(panelInfo: DocumentPanelPropertiesData) {
    panelInfo.heading =  getLocalizedString('');
    panelInfo.subHeading =  getLocalizedString('');
    panelInfo.filteringInfo = [];
    panelInfo.groupingInfo = [];
    panelInfo.hidePanelIfNoResult = true;
}

export function polulateSortFilterDocumentPanel(panelInfo: SortFilterDocumentPanelPropertiesData) {
    panelInfo.heading =  getLocalizedString('');
    panelInfo.subHeading =  getLocalizedString('');
    panelInfo.groupingInfo = [];
    panelInfo.filteringInfo = [];
    panelInfo.hidePanelIfNoResult = true;
    panelInfo.hideDate = false;
    panelInfo.hideDescription = false;
    panelInfo.maxDownloads = Constants.DOCUMENT_DOWNLOAD_MAX;
}

export function polulateDocumentListPanel(panelInfo: DocumentListPanelPropertiesData) {
    panelInfo.heading =  getLocalizedString('');
    panelInfo.filteringInfo = [];
    panelInfo.clientFilters = [];
    panelInfo.hidePanelIfNoResult = true;
}

export function polulateHeaderPanel(panelInfo: HeaderPanelPropertiesData) {
    panelInfo.heading =  getLocalizedString('');
    panelInfo.subHeading =  getLocalizedString('');
    panelInfo.isCompactMode = true;
}

export function polulateAreaHeadingPanel(panelInfo: AreaHeadingPanelPropertiesData) {
    panelInfo.heading =  getLocalizedString('');
    panelInfo.subHeading =  getLocalizedString('');
}

export function polulateAreaHeadingWithImagePanel(panelInfo: AreaHeadingWithImagePanelPropertiesData) {
    panelInfo.heading =  getLocalizedString('');
    panelInfo.subHeading =  getLocalizedString('');
}

export function polulateRichHeadingPanel(panelInfo: RichHeadingPanelPropertiesData) {
    panelInfo.heading = getLocalizedString('');
    panelInfo.subHeading = getLocalizedString('');
    panelInfo.imageId = newPanelImage;
}

export function polulateHeroItemPanel(panelInfo: HeroItemPanelPropertiesData) {
    panelInfo.heading = getLocalizedString('');
    panelInfo.subHeading = getLocalizedString('');
    panelInfo.imageId = newPanelImage;
    panelInfo.imageTitle = getLocalizedString('');
    panelInfo.thumbnailImageId = newPanelImage;
    panelInfo.thumbnailImageTitle = getLocalizedString('');
    panelInfo.backgroundImageId = newPanelImage;
}

export function polulatePivotPanel(panelData: PanelData<ViewPanelPropertiesData>) {
    panelData.views = [];
    for (let i = 1 ; i <= 2 ; i++) {
        panelData.views.push({
            isSelected: false,
            name: getLocalizedString(''),
            title: getLocalizedString(''),
            description: getLocalizedString(''),
            panels: []
        });
    }
}

export function polulateLeftRightPanel(panelData: PanelData<LeftRightViewsPanelPropertiesData>) {
    panelData.views = [];
    panelData.views.push({panels: [], widthInGridUnits: 8, isSelected: false});
    panelData.views.push({panels: [], widthInGridUnits: 4, isSelected: false});
    panelData.panelInfo.inPageNavLinks = [];
    panelData.panelInfo.selectedMainView = 'Left';
    panelData.panelInfo.itemStorageType = PanelSelectionHelperFactory.PANEL_TYPE_POSTFIX + PanelSelectionHelperFactory.LEFTRIGHT_PANEL;
}

export function polulateDrawerPanel(panelData: PanelData<ViewPanelPropertiesData>) {
    panelData.views = [];
    for (let i = 1 ; i <= 3 ; i++) {
        panelData.views.push({
            isSelected: false,
            name: getLocalizedString(''),
            panels: []
        });
    }
}

export function polulateContentPivotPanel(panelInfo: ContentPivotPanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 2 ; i++) {
        panelInfo.columns.push(getContentPivotColumn());
    }
}

export function polulateFAQPanel(panelInfo: FAQPanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 2 ; i++) {
        panelInfo.columns.push({name: getLocalizedString(''), content: getLocalizedString('')});
    }
}

export function getContentPivotColumn(): ContentPivotPanelColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        subHeading: getLocalizedString(''),
        title:  getLocalizedString(''),
        imageId: newPanelImage
    };
}

export function polulateLinkListPanel(panelInfo: LinkListPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 3 ; i++) {
        panelInfo.columns.push(getLinkListColumn());
    }
}

export function getLinkListColumn(): LinkListColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        links: [
            {title: getLocalizedString(''), type: 'Page', url: ''},
            {title: getLocalizedString(''), type: 'Page', url: ''},
        ]
    };
}

export function polulateTopResourcePanel(panelInfo: TopResourcePanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 2 ; i++) {
        panelInfo.columns.push(getTopResourceColumn());
    }
}

export function getTopResourceColumn(): TopResourceColumnPanelPropertiesData {
    return {
        heading: getLocalizedString(''),
        links: [
            {title: getLocalizedString(''), type: 'Page', url: '', description: getLocalizedString('')},
            {title: getLocalizedString(''), type: 'Page', url: '', description: getLocalizedString('')},
        ],
        callToActionLabel: getLocalizedString('View All'),
        callToActionUrl: ''
    };
}

export function populateImageRichTextPanel(panelInfo: ImageRichTextPanelPropertiesData){
    panelInfo.imageColumnWidth = 3; //default to full width.
    panelInfo.imageId = '';
    panelInfo.useCardPadding = false;
    panelInfo.theme = 'Dark';
}

export function populateCtaPanel(panelInfo: CtaPanelPropertiesData) {
    panelInfo.inset = 'inset';
    panelInfo.alignment = 'None';
    panelInfo.heading =  getLocalizedString('');
    panelInfo.headingUrl =  '';
    panelInfo.description =  getLocalizedString('');
    panelInfo.callToActionLabel =  getLocalizedString('');
    panelInfo.callToActionUrl =  '';
    panelInfo.callToActionTheme =  '';
}

export function polulateContentPlacementPanel(panelInfo: ContentPlacementPanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 4 ; i++) {
        panelInfo.columns.push(getContentPlacementColumn());
    }
}

export function getContentPlacementColumn(): ContentPlacementColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        subHeading: getLocalizedString(''),
        imageId: '',
        badge: getLocalizedString(''),
        callToActionLabel: getLocalizedString(newPanelCallToActionLabel),
        callToActionUrl: '',
        callToActionTheme: '',
        secondaryCallToActionLabel: getLocalizedString(newPanelCallToActionLabel),
        secondaryCallToActionUrl: '',
        secondaryCallToActionTheme: ''
    };
}

export function polulateContentRichBlockPanel(panelInfo: ContentRichBlockPanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 4 ; i++) {
        panelInfo.columns.push(getContentRichBlockColumn());
    }
}

export function getContentRichBlockColumn(): ContentRichBlockPanelColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        subHeading: getLocalizedString('')
    };
}

export function polulateHeroPanel(panelInfo: HeroPanelPropertiesData) {
    panelInfo.autoPlay = false;
    panelInfo.slideChangeIntervalInSeconds = 6;
    panelInfo.columns = [];
    for (let i = 0 ; i < 2 ; i++) {
        panelInfo.columns.push(getHeroColumn());
    }
}

export function polulateMosaicPanel(panelInfo: MosaicPanelPropertiesData) {
    // by default we will set up with pattern-1 (50%/50%) and two columns to fulfill the pattern
    panelInfo.theme = '';
    panelInfo.pattern = 'pattern-1';
    panelInfo.panelCallToAction = getLocalizedString('');
    panelInfo.panelCallToActionUrl = '';
    panelInfo.panelHeading = getLocalizedString('');
    panelInfo.panelHeadingUrl = '';

    panelInfo.columns = [];
    for (let i = 0 ; i < 2 ; i++) {
        panelInfo.columns.push(getMosaicColumn());
    }
}

export function polulateLibraryNotificationPanel(panelInfo: LibraryNotificationPanelPropertiesData) {
    return { theme: '' };
}

export function getHeroColumn(): HeroPanelColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        subHeading: getLocalizedString(''),
        badge: getLocalizedString(''),
        callToActionLabel: getLocalizedString(''),
        callToActionUrl: '',
        imageId: newPanelImage
    };
}

export function polulateFeaturePanel(panelInfo: FeaturePanelPropertiesData) {
    panelInfo.imageAlignment = 'f-align-left';
    panelInfo.imagePriority = false;
    panelInfo.imageId = '';
    panelInfo.setRatio = false;
    panelInfo.backgroundColor = '';
    panelInfo.heading = getLocalizedString('');
    panelInfo.headingUrl = '';
    panelInfo.subHeading = getLocalizedString('');
    panelInfo.callToActionOne = getLocalizedString('');
    panelInfo.callToActionOneUrl = '';
    panelInfo.callToActionOneStyle = '';
    panelInfo.callToActionTwo = getLocalizedString('');
    panelInfo.callToActionTwoUrl = '';
    panelInfo.callToActionTwoStyle = '';
    panelInfo.callToActionThree = getLocalizedString('');
    panelInfo.callToActionThreeUrl = '';
    panelInfo.callToActionThreeStyle = '';
    panelInfo.callToActionFour = getLocalizedString('');
    panelInfo.callToActionFourUrl = '';
    panelInfo.callToActionFourStyle = '';
}

export function polulateContentPlacementIconPanel(panelInfo: ContentPlacementIconPanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 3 ; i++) {
        panelInfo.columns.push(getContentPlacementIconColumn());
    }
}

export function getContentPlacementIconColumn(): ContentPlacementIconColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        subHeading: getLocalizedString(''),
        callToActionLabel: getLocalizedString(newPanelCallToActionLabel),
        callToActionUrl: ''
    };
}

export function getMosaicColumn(): MosaicPanelColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        headingUrl: '',
        subHeading: getLocalizedString(''),
        imageId: '',
        theme: 'None',
        backgroundMask: '',
        backgroundColor: ''
    };
}

export function populateCardsPanel(panelInfo: CardPanelPropertiesData) {
    panelInfo.textAlignment = 'Center';
    panelInfo.panelShowBorder = true;
    panelInfo.columns = [];
    panelInfo.columns.push(getCardsColumn());
}

export function getCardsColumn(): CardColumnPanelPropertiesData {
    return {
        imageId: '',
        heading: getLocalizedString(''),
        headingUrl: '',
        callToActionLabel: getLocalizedString(''),
        callToActionUrl: '',
        content: getLocalizedString('')
    };
}

export function polulateContentPlacementImagePanel(panelInfo: ContentPlacementImagePanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 4 ; i++) {
        panelInfo.columns.push(getContentPlacementImageColumn());
    }
}

export function polulateSidebarGridPanel(panelInfo: SidebarGridPanelPropertiesData) {
    panelInfo.columns = [];
    for (let i = 0 ; i < 4 ; i++) {
        panelInfo.columns.push(getSidebarGridColumn());
    }
}

export function getContentPlacementImageColumn(): ContentPlacementImageColumnPropertiesData {
    return {
        imageId: newPanelImage,
        heading: getLocalizedString(''),
        headingUrl: '',
        description: getLocalizedString(''),
        callToActionLabel: getLocalizedString(''),
        callToActionUrl: ''
    };
}

export function getContentPlacementImagePanelLayoutOptions(): IComboBoxOption[] {
    const items: IComboBoxOption[] = [
        { key: 'col-3', text: '4 Column' },
        { key: 'col-4', text: '3 Column' }
    ];
    return items;
}

export function getPanelInsetOptions(): IComboBoxOption[] {
    const items: IComboBoxOption[] = [
        { key: 'full', text: 'Full Width Panel' },
        { key: 'inset', text: 'Inset Panel' },
        { key: 'extra-inset', text: 'Extra Inset Panel' }
    ];
    return items;
}

export function getSidebarGridColumn(): SidebarGridColumnPropertiesData {
    return {
        imageId: '', // it can be omitted
        imageTitle: undefined,
        heading: getLocalizedString(''),
        headingUrl: '',
        content: getLocalizedString(''),
        callToActionLabel: getLocalizedString(''),
        callToActionUrl: '',
        callToActionTheme: ''
    };
}

export function polulateStructedListPanel(panelInfo: StructuredListPanelPropertiesData) {
    panelInfo.items = [
        {imageId: newPanelImage, description: getLocalizedString(''), useImage:true},
        {imageId: newPanelImage, description: getLocalizedString(''), useImage:true}
    ];
}

export function polulateGuideHelpPanel(panelInfo: GuideHelpPanelPropertiesData) {
    panelInfo.autoPlay = false;
    panelInfo.slideChangeIntervalInSeconds = 6;
    panelInfo.columns = [];
    for (let i = 0 ; i < 2 ; i++) {
        panelInfo.columns.push(getGuideHelpColumn());
    }
}

export function polulateQuotePanel(panelInfo: QuotePanelPropertiesData){
    panelInfo.quote = getLocalizedString('');
    panelInfo.author = '';
}

export function getGuideHelpColumn(): GuideHelpColumnPropertiesData {
    return {
        heading: getLocalizedString(''),
        subHeading: getLocalizedString(''),
        badge: getLocalizedString(''),
        callToActionLabel: getLocalizedString(''),
        callToActionUrl: '',
        imageId: newPanelImage
    };
}

export function getLocalizedString(value: string): LocalizedString {
    var uuid = require('uuid');
    return {
        li: uuid.v1(),
        lv: value
    };
}

// tslint:disable:no-any
export function getPanelBorderClassName(panelData: any) {
    return shouldShowLocalizationPending(panelData) ? 'pendingLocalizationBorder' : '';
}

function shouldShowLocalizationPending(structuredData: any) {
    // Do not show localization pending boarder when it is en-US
    var lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    if (lang && lang === 'en-US') {
        return false;
    }
    // Do a DFS to search if there is any LocalizedString type object whose isLocalized value is false
    let isLocalized = (obj: any) => {
        // If object itself is null or undefined, then it is not a LocalizedString type
        if (obj === null || obj === undefined) {
            return true;
        }
        // If it does not even have value from en-US, that means nothing need to be localized
        if (!obj.isLocalized && obj.lv === null) {
            return true;
        }
        // If it both has li and lv, then it is a LocalizedString type, and isLocalized's value need to be checked and return
        if (obj.li !== undefined && obj.lv !== undefined) {
            return obj.isLocalized === undefined || obj.isLocalized;
        }
        for (var prop in obj) {
            // Only if the property is an object we do recursive check and update the res
            if (typeof(obj[prop]) === 'object' && !isLocalized(obj[prop])) {
                return false;
            }
        }
        return true;
    };
    return !isLocalized(structuredData);
}