import { IFeedbackInitOptions, FeedbackAuthenticationType, FeedbackPolicyValue, IFeedbackConfig } from '@ms/inapp-feedback-loader'
import { EnvironmentSettings } from 'src/App/Models'

const ppeEnvironmentString = "ppe";
const prodEnvironmentString = "prod";

// OCV (One Customer Voice) Application Ids for each respective environment. OCV is the portal in which user feedback uploads into.
let environmentToFeedbackOcvAppId = {
  "ppe" : 50329, // AppId for https://ocv-ppe.microsoft.com
  "prod" : 2153  // AppId for https://ocv.microsoft.com
}

let environmentSettings: EnvironmentSettings = window['__appSettings'];
let ocvAppId : number;
let sendDataToOcvProductionEnvironment = false;

// if for some reason environment settings aren't defined, we just assume PPE settings
if (!environmentSettings)
{
  ocvAppId = environmentToFeedbackOcvAppId[ppeEnvironmentString];
}

if (environmentSettings.environmentName.toLowerCase().includes(prodEnvironmentString))
{
  ocvAppId = environmentToFeedbackOcvAppId[prodEnvironmentString];
  sendDataToOcvProductionEnvironment = true;
} 
// in all other cases (DevBox -- DBX, PPE, etc) we just assume we are sending feedback to OCV PPE
else
{
  ocvAppId = environmentToFeedbackOcvAppId[ppeEnvironmentString];
}

/* This is just sample values, do not reuse variables which you do not need
 */
export const FeedbackOptions: IFeedbackInitOptions = {
  appId: ocvAppId, // For STP PPE: 50329, for STP PROD: ??? --- We might need to set this dynamically
  // ageGroup: FeedbackAgeGroup.Adult, // agegroup must be dynamically fetched from the host app. Mandatory for MSA
  authenticationType: FeedbackAuthenticationType.Unauthenticated, //authenticationType must be dynamically fetched from the host app
  // Please uncomment and implement functions if you want to have callback functionality in any of the following cases
  // callbackFunctions: {
  //   onDismiss: onDismissPrint,
  //   onError: onErrorPrint,
  //   onSuccess: onSuccessPrint,
  //   initializationComplete: initializationCompletePrint
  // },
  clientName: 'ServiceTrustPortal', // name of your app that has onboarded with Centro. Once we are fully onboarded this will be set to "ServiceTrustPortal"
  isProduction: sendDataToOcvProductionEnvironment, // note: if this is set to false it will send feedback to PPE (which will be sent to https://ocv-ppe.microsoft.com/)
  feedbackConfig: {
    // Please uncomment below line only if you have usecases for the same/ to test the behaviour
    // appData: '{"AppData1":"AppData1 Value", "AppData2":"AppData2 Value"}', // appData is optional. Define your own appData to be passed as part of feedback
    complianceChecks: {
    // connectedExperiences: FeedbackPolicyValue.Enabled,
    policyAllowContact: FeedbackPolicyValue.Enabled,
    // policyAllowContent: FeedbackPolicyValue.Enabled,
    policyAllowFeedback: FeedbackPolicyValue.Enabled,
    // policyAllowCopilotFeedback: FeedbackPolicyValue.Enabled,
    policyAllowScreenshot: FeedbackPolicyValue.Enabled,
    // policyAllowSurvey: FeedbackPolicyValue.Enabled,
    policyEmailCollectionDefault: FeedbackPolicyValue.Enabled,
    // policyScreenshotDefault: FeedbackPolicyValue.Enabled,
    // policyContentSamplesDefault: FeedbackPolicyValue.Enabled
    }, // complianceChecks is required for AAD users. The host app needs to query OCPS API to get the compliance checks and pass on to the feedback module
    
    isEmailCollectionEnabled: true,
    isScreenshotEnabled: true,

    // You can provide custom URLs for these, but for now leaving them commented out but left in this file for documentation purposes.
    // feedbackForumUrl: 'https://example.com', //Define your own feedback forum url
    // myFeedbackUrl: 'https://example.com',
    // privacyUrl: 'https://example.com',

    /**
     * IMPORTANT: Please make this variable true to display the Feedback interface
     */
    isDisplayed: false,
  },

  // tenantId: 'd0000000-0000-0000-0000-000000000000', // tenantId must be dynamically fetched from the host app
  themeOptions: { baseTheme: 'WindowsLight' }, // The host app needs to set their own theme options. The values are for representation only
}

// helper function to get the default settings, in case they were altered
export function GetDefaultFeedBackOptions() : IFeedbackConfig {
  return  {
    complianceChecks: {
      policyAllowContact: FeedbackPolicyValue.Enabled,
      policyAllowFeedback: FeedbackPolicyValue.Enabled,
      policyAllowScreenshot: FeedbackPolicyValue.Enabled,
      policyEmailCollectionDefault: FeedbackPolicyValue.Enabled,
    },
    
    isEmailCollectionEnabled: true,
    isScreenshotEnabled: true,
    isDisplayed: false,
  }
}
