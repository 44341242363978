/**
 * Default user consent records
 */
export const DEFAULT_COOKIE_CONSENT = {
  Required: true,
  Analytics: false,
  SocialMedia: false,
  Advertising: false,
};

/**
 * The cookie consent service that displays the consent banner and options
 */
interface ICookieConsentService {
  /**
   * Initializes the cookie consent service and displays the banner if needed
   *
   * @param culture - the user's culture
   * @param placeholderId - the element ID
   * @returns a promise to represent the async operation
   */
  initialize(
    culture: string,
    placeholderId: string,
    onConsentChanged?: (
      newConsent: Record<WcpConsent.consentCategories, boolean>
    ) => void
  ): Promise<Record<WcpConsent.consentCategories, boolean>>;

  /**
   * Initiates the cookie preference dialog.
   * For regions where consent is not required, this method will not show the dialog.
   */
  manageConsent(): void;

  /**
   * Informs if user consent is required. If consent is not required then any cookies can be dropped.
   *
   * @returns true if required
   */
  isConsentRequired(): boolean;

  /**
   * Returns whether Analytics cookies is consented
   *
   * @returns true if consented
   */
  isAnalyticsCookiesConsented(): boolean;
}

/**
 * The cookie consent service that displays the banner and options
 */
class CookieConsentService implements ICookieConsentService {
  /**
   * The site consent object
   */
  private siteConsent: WcpConsent.SiteConsent;

  public initialize = (
    culture: string,
    placeholderId: string,
    onConsentChanged?: (
      newConsent: Record<WcpConsent.consentCategories, boolean>
    ) => void
  ): Promise<Record<WcpConsent.consentCategories, boolean>> => {
    return new Promise((resolve, reject) => {
      window.WcpConsent &&
        WcpConsent.init(
          culture,
          placeholderId,
          (err?: Error, _siteConsent?: WcpConsent.SiteConsent) => {
            if (err) {
              reject(err);
            } else if (!_siteConsent) {
              reject(new Error("_siteConsent was null after initialization"));
            } else {
              // Save the reference to the site consent object
              this.siteConsent = _siteConsent;
              resolve(this.getUserConsent());
            }
          },
          onConsentChanged
        );
    });
  };

  public manageConsent(): void {
    if (
      this.isConsentRequired() &&
      this.siteConsent &&
      typeof this.siteConsent.manageConsent === "function"
    ) {
      this.siteConsent.manageConsent();
    }
  }

  public isConsentRequired(): boolean {
    if (this.siteConsent) {
      return this.siteConsent.isConsentRequired;
    }
    return true;
  }

  public isAnalyticsCookiesConsented = () => {
    return this.getUserConsent()["Analytics"];
  };

  private getUserConsent = (): Record<
    WcpConsent.consentCategories,
    boolean
  > => {
    if (this.siteConsent && typeof this.siteConsent.getConsent === "function") {
      return this.siteConsent.getConsent();
    }
    return DEFAULT_COOKIE_CONSENT;
  };
}

export const CookieConsentServiceInstance = new CookieConsentService();
