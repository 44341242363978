import './DocumentTypeDropdown.css';

import * as React from 'react';

import { Dropdown, IDropdownOption, IDropdownProps } from 'office-ui-fabric-react/lib/Dropdown';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';

// Define the properties that this component accepts as input and drives its behavior.
export interface DocumentTypeDropdownModel {
    filter?: string[];
}

// Define the events that this component can raise.
export interface DocumentTypeDropdownEvents {
    onSelectionChanged?: (selectedChoices: string[]) => void;
}

export interface DocumentTypeDropdownState {
    options: IDropdownOption[];
}

// Define the merged set of properties and events that are exposed by this component.
export type DocumentTypeDropdownProps = DocumentTypeDropdownModel & DocumentTypeDropdownEvents & IDropdownProps;

export class DocumentTypeDropdown extends React.Component<
    DocumentTypeDropdownProps,
    DocumentTypeDropdownState
> {
    constructor(props: DocumentTypeDropdownProps) {
        super(props);

        this.state = {
            options: [
                {
                    key: 'Excel',
                    text: 'Excel',
                    data: {
                        icon: `ms-Icon ms-Icon--ExcelDocument`,
                    },
                    disabled: false
                },
                {
                    key: 'PDF',
                    text: 'PDF',
                    data: {
                        icon: `ms-Icon ms-Icon--PDF`,
                    },
                    disabled: false
                },
                {
                    key: 'PowerPoint',
                    text: 'PowerPoint',
                    data: {
                        icon: `ms-Icon ms-Icon--PowerPointDocument`,
                    },
                    disabled: false
                },
                {
                    key: 'Text',
                    text: 'Text',
                    data: {
                        icon: `ms-Icon ms-Icon--TextDocument`,
                    },
                    disabled: false
                },
                {
                    key: 'Word',
                    text: 'Word',
                    data: {
                        icon: `ms-Icon ms-Icon--WordDocument`,
                    },
                    disabled: false
                },
            ]
        };
    }

    public onChangeMultiSelect = (item: IDropdownOption, index: number): void => {
        const { filter } = this.props;
        const updatedSelectedChoices = [...filter || []];

        // now we augment the current list with the newly taken action
        if (item.selected) {
            // add the option if it's checked
            updatedSelectedChoices.push(item.key.toString());
        } else {
            // remove the option if it's unchecked
            const currIndex = updatedSelectedChoices.indexOf(item.key.toString());
            if (currIndex > -1) {
                updatedSelectedChoices.splice(currIndex, 1);
            }
        }

        // ... and send the new list to the event handler
        if (this.props.onSelectionChanged) { this.props.onSelectionChanged(updatedSelectedChoices || []); }
    }

    render() {
        const { filter, ...props } = this.props;
        const { options } = this.state;

        return (
            <Dropdown
                placeHolder={Intl.Get(LocIds.PageAndPanels.DocumentTypeLabel)}
                label=""
                selectedKeys={filter}
                ariaLabel={Intl.Get(LocIds.PageAndPanels.DocumentTypeLabel)}
                title={Intl.Get(LocIds.PageAndPanels.DocumentTypeLabel)}
                onChanged={this.onChangeMultiSelect}
                onRenderPlaceHolder={this._onRenderPlaceHolder}
                onRenderTitle={this._onRenderTitle}
                onRenderOption={this._onRenderOption}
                onRenderCaretDown={this._onRenderCaretDown}
                multiSelect={true}
                options={options}
                role="combobox"
                {...props}
            />
        );
    }

    private _onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <div className="dropdownExample-option">
                {option.data &&
                    option.data.icon && (
                        <Icon style={{ marginRight: '8px' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
                    )}
                <span>{option.text}</span>
            </div>
        );
    }

    private _onRenderPlaceHolder = (props: IDropdownProps): JSX.Element => {
        return this._renderName();
    }

    private _onRenderCaretDown = (props: IDropdownProps): JSX.Element => {
        return <Icon iconName="ChevronDown" />;
    }

    /**
     * Our design is going to show the selections is a separate group of 'choice summary'
     * so we'll continue to show the placeholder here...
     *
     * This method usually displays the option titles, but we really need the IDropdownProps
     * to get the placeholder... We'll work around it by explicitly picking it off our
     * component's props.
     */
    private _onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        return this._renderName();
    }

    private _renderName = (): JSX.Element => {
        return (
            <div className="dropdown-documentType">
                <span>{Intl.Get(LocIds.PageAndPanels.DocumentTypeLabel)}</span>
            </div>
        );
    }
}

export default DocumentTypeDropdown;
