import { AxiosResponse } from 'axios';
import { ErrorResponse, SaveDocumentInfoRequest } from './Models';
import { DocumentVersionInfo } from '../Models/DocumentVersionInfo';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { DocumentsFilter } from '../Models/Documents/DocumentsFilter';
import { PanelData, DocumentPanelPropertiesData } from '../Models/PageInfrastructure';
import { DocumentSeries } from '../Models/NotificationSettings/DocumentSeries';
import * as StatusMessageReducer from '../../App/Reducers/StatusMessageReducer';
import { DocumentsDownloadHistoryFilter } from '../Models/Documents/DocumentsDownloadHistoryFilter';

class DocumentService {
    getPanelDocuments(panelData: PanelData<DocumentPanelPropertiesData>, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/GetPanelDocuments/', panelData, () => { return; }, onRequestSuccess, onRequestFailed);
    }

    getPagedDocuments(filter: DocumentsFilter, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/GetPagedDocuments/', filter, () => { return; }, onRequestSuccess, onRequestFailed);
    }

    getPagedDocumentsDownloadHistory(filter: DocumentsDownloadHistoryFilter, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/GetPagedDocumentDownloadHistoryDetail/', filter, () => { return; }, onRequestSuccess, onRequestFailed);
    }

    saveDocument(request: SaveDocumentInfoRequest, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId: string) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/SaveDocument/', request, () => { return; }, onRequestSuccess, onRequestFailed, statusMessageId, undefined, undefined, true);
    }

    exportDownloadHistory(onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) {
        return ServiceTrustClient.doRequest_New('GET', '/api/v2/ExportDocumentDownloadHistoryDetail/', {}, () => { return; }, onRequestSuccess, onRequestFailed);
    }

    getLiveDocument(docId: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) {
        return ServiceTrustClient.doRequest_New('GET', '/api/v2/GetLiveDocument/' + docId, {}, () => { return; }, onRequestSuccess, onRequestFailed);
    }

    GetLiveDocumentIncludingOldSeriesDoc(docId: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, onRequestCancelled?: () => void, customRequestTimeOut?: number, supressClientErrorPopup?: boolean, suppressClientErroPopupIfResponseCodes?: number[]) {
        return ServiceTrustClient.doRequest_New('GET', '/api/v2/GetLiveDocumentIncludingOldSeriesDoc/' + docId, {}, () => { return; }, onRequestSuccess, onRequestFailed, StatusMessageReducer.COMMON_STATUS_NAME, onRequestCancelled, customRequestTimeOut, supressClientErrorPopup, suppressClientErroPopupIfResponseCodes);
    }

    approveDocument(doc: DocumentVersionInfo, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId?: string) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/ApproveDocument/', doc, () => { return; }, onRequestSuccess, onRequestFailed, statusMessageId);
    }
    
    approveDeletion(doc: DocumentVersionInfo, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId?: string) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/ApproveDeletion/', doc, () => { return; }, onRequestSuccess, onRequestFailed, statusMessageId);
    }

    rejectDocument(doc: DocumentVersionInfo, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId?: string) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/RejectDocument/', doc, () => { return; }, onRequestSuccess, onRequestFailed, statusMessageId);
    }

    deleteDocument(docIds: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/DeleteDocument/', docIds, () => { return; }, onRequestSuccess, onRequestFailed);
    }

    getAllVersionsInDocument(documentId: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId: string) {
        return ServiceTrustClient.doRequest_New('GET', `/api/v2/GetAllVersionsInDocument/${documentId}`, {}, () => { return; }, onRequestSuccess, onRequestFailed, statusMessageId);
    }

    getAllDocumentSeries(onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId: string) {
        return ServiceTrustClient.doRequest_New('GET', `/api/v2/Notifications/Document/Series`, {}, () => { return; }, onRequestSuccess, onRequestFailed, statusMessageId);
    }

    getDocumentVersionsBySeriesId(seriesId: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId: string) {
        return ServiceTrustClient.doRequest_New('GET', `/api/v2/Notifications/Document/${seriesId}`, {}, () => { return; }, onRequestSuccess, onRequestFailed, statusMessageId);
    }

    saveDocumentSeries(series: DocumentSeries, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void, statusMessageId: string) {
        return ServiceTrustClient.doRequest_New('POST', '/api/v2/Notifications/Document/Series', series, () => { return; }, onRequestSuccess, onRequestFailed);
    }
}

export let DocumentServiceClient = new DocumentService();
