import { Reducer } from 'redux';
import { ImageInfo } from '../Models/Image';
// import { ErrorResponse } from '../../App/Services/Models/ErrorResponse';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type Status = 'Init' | 'Requesting' | 'UploadStart' | 'Uploading' | 'UploadComplete' | 'Saving' | 'Saved'
                    | 'Finished' | 'Success' | 'Error' | 'Confirming' | 'Cancelled' | 'Deleting' | 'TitleChange';

export interface FileUploadProperties {
    fileId: string;
    fileName: string;
    fileSize: number;
    fragmentIndex: number;
    fragmentCount: number;
}

export interface ImageUploaderDialogState {
    fileProps?: FileUploadProperties;
    uploadInProgress: boolean;
    uploadCompleted: boolean;
    title: string;
    hidden: boolean;
}

export interface ImagePickerDialogState {
    hidden: boolean;
    viewStyle: string;
}

export interface ImageManagmentState {
    imageInfos: ImageInfo[];
    status: Status;
    selectedItems: ImageInfo[];
    uploadDialogState: ImageUploaderDialogState;
    pickerDialogState: ImagePickerDialogState;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ImageDefsFetching {
    type: 'IMAGEDEFS_FETCHING';
}

interface ImageDefsFetched {
    type: 'IMAGEDEFS_FETCHED';
    data: ImageInfo[]|undefined;
}

interface ImageDefsFetchingError {
    type: 'IMAGEDEFS_FETCHING_ERROR';
}

interface ImageDefsDeleting {
    type: 'IMAGEDEFS_DELETING';
}

interface ImageDefsDeletingError {
    type: 'IMAGEDEFS_DELETING_ERROR';
}

interface ImageDefsDeleteConfirming {
    type: 'IMAGEDEFS_DELETE_CONFIRMING';
}

interface ImageDefsDeleteConfirmed {
    type: 'IMAGEDEFS_DELETE_CONFIRMED';
}

interface ImageDefsDeleteCancelled {
    type: 'IMAGEDEFS_DELETE_CANCELLED';
}

interface SelectionChanged {
    type: 'SELECTION_CHANGED';
    data: {
            allImages: ImageInfo[];
            selections: ImageInfo[];
        };
}

interface ImagePickStart {
    type: 'PICK_IMAGES';
}

interface ImagePicked {
    type: 'IMAGE_SELECTED';
}

interface ImagePickCancelled {
    type: 'PICK_IMAGES_CANCELLED';
}

interface ImagePickViewChanged {
    type: 'PICK_IMAGES_VIEWCHANGE';
    data: string;
}

interface FileUploadStart {
    type: 'FILE_UPLOAD_START';
    data: FileUploadProperties;
}

interface FileUploading {
    type: 'FILE_UPLOADING';
}

interface FileUploadCancelled {
    type: 'FILE_UPLOAD_CANCELLED';
}

interface FileUploadError {
    type: 'FILE_UPLOAD_ERROR';
}

interface FileUploadProgress {
    type: 'FILE_UPLOAD_PROGRESS';
    data: FileUploadProperties;
}

interface FileUploadComplete {
    type: 'FILE_UPLOAD_COMPLETE';
    data: FileUploadProperties;
}

interface TitleChange {
    type: 'TITLE_CHANGE';
    data: string;
}

interface FileSaveStart {
    type: 'FILE_SAVE_START';
}

interface FileSaving {
    type: 'FILE_SAVING';
}

interface FileSaveError {
    type: 'FILE_SAVE_ERROR';
}

interface FileSaved {
    type: 'FILE_SAVED';
}

export type ImageManagementActions = ImageDefsFetching | ImageDefsFetched | ImageDefsFetchingError
    | ImageDefsDeleting | ImageDefsDeletingError | SelectionChanged | FileUploadStart | FileUploading | FileUploadError
    | FileUploadCancelled | FileUploadProgress | FileUploadComplete | ImageDefsDeleteConfirming | ImageDefsDeleteConfirmed 
    | ImageDefsDeleteCancelled | FileSaveStart | FileSaving | FileSaveError | FileSaved | TitleChange
    | ImagePickStart | ImagePicked | ImagePickCancelled | ImagePickViewChanged;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
var dialogState: ImageUploaderDialogState = {  
    uploadInProgress: false,
    uploadCompleted: false,
    title: '',
    hidden: true
};

var pickerDialogState: ImagePickerDialogState = {
    hidden: true,
    viewStyle: 'thumbnails'
};

const unloadedState: ImageManagmentState = {
    status: 'Init',
    imageInfos: [],
    selectedItems: [],
    uploadDialogState: dialogState,
    pickerDialogState: pickerDialogState
};

export const reducer: Reducer<ImageManagmentState> = (state: ImageManagmentState, action: ImageManagementActions) => {
    switch (action.type) {
        case 'IMAGEDEFS_FETCHING':
        case 'IMAGEDEFS_DELETING':
        case 'FILE_SAVING':
            return {
                ...state,
                status: 'Requesting'
            };
        case 'IMAGEDEFS_DELETE_CANCELLED':
            return {
                ...state,
                status: 'Cancelled'
            };
        case 'IMAGEDEFS_FETCHING_ERROR':
        case 'IMAGEDEFS_DELETING_ERROR':
        case 'FILE_UPLOAD_ERROR':
        case 'FILE_SAVE_ERROR':
            return {
                ...state,
                status: 'Error'
            };
        case 'IMAGEDEFS_FETCHED':
            return {
                ...state,
                status: 'Finished',
                imageInfos: action.data ? action.data : []
            };
        case 'FILE_UPLOADING':
            return {
                ...state,
                status: 'Uploading',
                uploadDialogState: {
                    ...(state.uploadDialogState),
                    hidden: false
                }
            };
        case 'FILE_UPLOAD_START':
            return {
                ...state,
                status: 'UploadStart',
                uploadDialogState: {
                    ...(state.uploadDialogState),
                    uploadInProgress: true,
                    uploadCompleted: false,
                    fileProps: action.data,
                    // TODO: Trim the extension off the end of the file name
                    title: (state && state.uploadDialogState && state.uploadDialogState.fileProps) ? state.uploadDialogState.fileProps.fileName : ''
                }
            };
        case 'FILE_UPLOAD_CANCELLED':
            return {
                ...state,
                status: 'Cancelled',
                uploadDialogState: {
                    ...(state.uploadDialogState),
                    uploadInProgress: false,
                    uploadCompleted: false,                    
                    title: '',
                    hidden: true
                }
            };
        case 'FILE_UPLOAD_PROGRESS':
            if (state.uploadDialogState.uploadInProgress) {
                return {
                    ...state,
                    status: 'Uploading',
                    uploadDialogState : {
                        ...(state.uploadDialogState),
                        uploadCompleted: false,
                        fileProps: action.data
                    }
                };
            }
            return state;
        case 'FILE_UPLOAD_COMPLETE':
            return {
                ...state,
                status: 'UploadComplete',
                uploadDialogState: {
                    ...(state.uploadDialogState),
                    uploadInProgress: false,
                    uploadCompleted: true,                    
                    fileProps: action.data,
                    title: (state && state.uploadDialogState && state.uploadDialogState.fileProps) ? state.uploadDialogState.fileProps.fileName : ''
                }
            };
        case 'SELECTION_CHANGED':
            return {
                ...state,
                imageInfos: action.data.allImages,
                selectedItems: action.data.selections ? action.data.selections : []
            };
        case 'IMAGEDEFS_DELETE_CONFIRMING':
            return {
                ...state,
                status: 'Confirming'
            };
        case 'IMAGEDEFS_DELETE_CONFIRMED':
            return {
                ...state,
                status: 'Deleting'
            };
        case 'FILE_SAVE_START':
            return {
                ...state,
                status: 'Saving'
            };
        case 'FILE_SAVED':
            return {
                ...state,
                status: 'Saved',
                uploadDialogState: {
                    ...(state.uploadDialogState),
                    uploadInProgress: false,
                    uploadCompleted: false,                    
                    fileProps: undefined,
                    title: '',
                    hidden: true
                }
            };
        case 'TITLE_CHANGE':
            return {
                ...state,
                status: 'TitleChange',
                uploadDialogState: {
                    ...(state.uploadDialogState),                    
                    title: action.data
                }
            };
        case 'PICK_IMAGES':
            return {
                ...state,
                status: 'Requesting',
                pickerDialogState: {
                    ...(state.pickerDialogState),                    
                    hidden: false
                }
            };
        case 'IMAGE_SELECTED':
            return {
                ...state,
                status: 'Saved',
                pickerDialogState: {
                    ...(state.pickerDialogState),                    
                    hidden: true
                }
            };
        case 'PICK_IMAGES_CANCELLED':
            return {
                ...state,
                status: 'Cancelled',
                pickerDialogState: {
                    ...(state.pickerDialogState),                    
                    hidden: true
                }
            };
        case 'PICK_IMAGES_VIEWCHANGE':
            return {
                ...state,
                status: 'Cancelled',
                pickerDialogState: {
                    ...(state.pickerDialogState),                    
                    viewStyle: action.data
                }
            };
        default:
            return state || unloadedState;
    }
};