import * as Redux from 'redux';
import { ErrorResponse } from '../Services/Models';
import { DocumentType } from '../Models/NotificationSettings/DocumentType';

export interface MyLibrary {
    loaded: boolean;
    pending: boolean;
    docList: MyLibraryDocument[];
    error?: string;
}

export const initialState: MyLibrary = {
    loaded: false,
    pending: false,
    docList: [],
    error: undefined
};

// Define the action type strings
export const MYLIBRARY_LIST_REQUEST = 'MYLIBRARY_LIST_REQUEST';
export type MYLIBRARY_LIST_REQUEST = typeof MYLIBRARY_LIST_REQUEST;

export const MYLIBRARY_LIST_RECEIVED = 'MYLIBRARY_LIST_RECEIVED';
export type MYLIBRARY_LIST_RECEIVED = typeof MYLIBRARY_LIST_RECEIVED;

export const MYLIBRARY_LIST_ERROR = 'MYLIBRARY_LIST_ERROR';
export type MYLIBRARY_LIST_ERROR = typeof MYLIBRARY_LIST_ERROR;

export const MYLIBRARY_ADD_REQUEST = 'MYLIBRARY_ADD_REQUEST';
export type MYLIBRARY_ADD_REQUEST = typeof MYLIBRARY_ADD_REQUEST;

export const MYLIBRARY_ADD_RECEIVED = 'MYLIBRARY_ADD_RECEIVED';
export type MYLIBRARY_ADD_RECEIVED = typeof MYLIBRARY_ADD_RECEIVED;

export const MYLIBRARY_ADD_ERROR = 'MYLIBRARY_ADD_ERROR';
export type MYLIBRARY_ADD_ERROR = typeof MYLIBRARY_ADD_ERROR;

export const MYLIBRARY_REMOVE_REQUEST = 'MYLIBRARY_REMOVE_REQUEST';
export type MYLIBRARY_REMOVE_REQUEST = typeof MYLIBRARY_REMOVE_REQUEST;

export const MYLIBRARY_REMOVE_RECEIVED = 'MYLIBRARY_REMOVE_RECEIVED';
export type MYLIBRARY_REMOVE_RECEIVED = typeof MYLIBRARY_REMOVE_RECEIVED;

export const MYLIBRARY_REMOVE_ERROR = 'MYLIBRARY_REMOVE_ERROR';
export type MYLIBRARY_REMOVE_ERROR = typeof MYLIBRARY_REMOVE_ERROR;

export interface MyLibraryDocument {
    documentId: string;
    documentType: DocumentType;
    contentHash?: string;
}

// For each action, define a strongly-typed interface which includes the action-specific properties.
export interface MyLibraryListRequestAction {
    type: MYLIBRARY_LIST_REQUEST;
}

export interface MyLibraryListReceivedAction {
    type: MYLIBRARY_LIST_RECEIVED;
    docList: MyLibraryDocument[];
}

export interface MyLibraryListErrorAction {
    type: MYLIBRARY_LIST_ERROR;
    error: string;
}

export interface MyLibraryAddRequestAction {
    type: MYLIBRARY_ADD_REQUEST;
    docsList: MyLibraryDocument[];
}

export interface MyLibraryAddReceivedAction {
    type: MYLIBRARY_ADD_RECEIVED;
}

export interface MyLibraryAddErrorAction {
    type: MYLIBRARY_ADD_ERROR;
    error: string;
}

export interface MyLibraryRemoveRequestAction {
    type: MYLIBRARY_REMOVE_REQUEST;
    documentId: string;
    docType: DocumentType;
}

export interface MyLibraryRemoveReceivedAction {
    type: MYLIBRARY_REMOVE_RECEIVED;
}

export interface MyLibraryRemoveErrorAction {
    type: MYLIBRARY_REMOVE_ERROR;
    error: string;
}

// Define the merged set of all the strongly-typed actions that can affect this model.
export type MyLibraryAction =
    MyLibraryListRequestAction | MyLibraryListReceivedAction | MyLibraryListErrorAction |
    MyLibraryAddRequestAction | MyLibraryAddReceivedAction | MyLibraryAddErrorAction |
    MyLibraryRemoveRequestAction | MyLibraryRemoveReceivedAction | MyLibraryRemoveErrorAction;

// Define helper functions that return strongly-typed routable action instances that inject the action-specific properties into the routable objects.
export function requestMyLibraryList(): MyLibraryAction {
    return {
        type: MYLIBRARY_LIST_REQUEST
    };
}

export function receivedMyLibraryList(docList: MyLibraryDocument[]): MyLibraryAction {
    return {
        docList,
        type: MYLIBRARY_LIST_RECEIVED
    };
}

export function errorMyLibraryList(error: ErrorResponse): MyLibraryAction {
    return {
        error: error.message,
        type: MYLIBRARY_LIST_ERROR
    };
}

export function requestAddToMyLibrary(docs: MyLibraryDocument[]): MyLibraryAction {
    return {
        type: MYLIBRARY_ADD_REQUEST,
        docsList: docs
    };
}

export function receivedAddToMyLibrary(): MyLibraryAction {
    return {
        type: MYLIBRARY_ADD_RECEIVED
    };
}

export function errorAddToMyLibrary(error: ErrorResponse): MyLibraryAction {
    return {
        error: error.message,
        type: MYLIBRARY_ADD_ERROR
    };
}

export function requestRemoveFromMyLibrary(doc: MyLibraryDocument): MyLibraryAction {
    return {
        type: MYLIBRARY_REMOVE_REQUEST,
        documentId: doc.documentId,
        docType: doc.documentType
    };
}

export function receivedRemoveFromMyLibrary(): MyLibraryAction {
    return {
        type: MYLIBRARY_REMOVE_RECEIVED
    };
}

export function errorRemoveFromMyLibrary(error: ErrorResponse): MyLibraryAction {
    return {
        error: error.message,
        type: MYLIBRARY_REMOVE_ERROR
    };
}

export const DefaultMyLibrary: MyLibrary = {
    docList: [],
    loaded: false,
    pending: false,
};

// Define the reducer that performs the model changes required for all of the actions that can affect this model.
export const reducer: Redux.Reducer<MyLibrary> =
    (oldState: MyLibrary, action: MyLibraryAction): MyLibrary => {
        switch (action.type) {
            case MYLIBRARY_LIST_REQUEST:
                return {
                    ...oldState,
                    loaded: false,
                    pending: true
                };

            case MYLIBRARY_LIST_RECEIVED:
                return {
                    ...oldState,
                    docList: action.docList,
                    loaded: true,
                    pending: false,
                };

            case MYLIBRARY_LIST_ERROR:
                return {
                    ...oldState,
                    docList: [],
                    error: action.error,
                    loaded: false,
                    pending: false
                };

            case MYLIBRARY_ADD_REQUEST: {
                return {
                    ...oldState,
                    docList: [...oldState.docList, ...action.docsList]
                };
            }

            case MYLIBRARY_ADD_RECEIVED:
                return {
                    ...oldState,
                    error: undefined
                };

            case MYLIBRARY_ADD_ERROR:
                return {
                    ...oldState,
                    error: action.error
                };

            case MYLIBRARY_REMOVE_REQUEST:
                // optimistically remove the ID now
                return {
                    ...oldState,
                    docList: oldState.docList.filter((doc) => doc.documentId !== action.documentId)
                };

            case MYLIBRARY_REMOVE_RECEIVED:
                return {
                    ...oldState,
                    error: undefined
                };

            case MYLIBRARY_REMOVE_ERROR:
                return {
                    ...oldState,
                    error: action.error
                };

            default:
                return {
                    ...DefaultMyLibrary,
                    ...oldState
                };
        }
    };
