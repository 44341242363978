import './CopilotOfficeFeedback.css';

import * as React from "react";
import { FontIcon, Stack, Stylesheet } from "office-ui-fabric-react";
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { IconNames} from "./FeedbackConstants";
import { store } from '../../../Store';
import { loadFeedbackAndBootstrap } from './FeedbackUtility';

import {
    updateFeedbackOptions,
    FeedbackUiType,
    FeedbackType,
    InAppFeedbackScenarioType,
  } from '@ms/inapp-feedback-loader'

import { FeedbackOptions } from "./FeedbackOptions"

let feedbackData = FeedbackOptions
const conversationHistoryFileName = "conversationHistory.json";

interface CopilotFeedbackState {
  thumbsUpSelected: boolean;
  thumbsDownSelected: boolean;  
  thumbsUpIconName: string;
  thumbsDownIconName: string;
  displayString: string;
  userName: string;
  feedbackZIndexSet: boolean;
}

interface CopilotFeedbackProps {
  conversationId: string
}

export class CopilotFeedback extends React.Component<CopilotFeedbackProps, CopilotFeedbackState> {    
  constructor(props: CopilotFeedbackProps) {
    super(props);
    const authentication = store.getState().authentication;
    const currentUser = authentication && authentication.context ? authentication.context.user : undefined;
    this.state = {
        thumbsUpSelected: false,
        thumbsDownSelected: false,
        thumbsDownIconName: IconNames.Dislike,
        thumbsUpIconName: IconNames.Like,
        displayString: Intl.Get(LocIds.CopilotChatBot.ProvideFeedback),
        userName: currentUser ? currentUser.userName : '',
        feedbackZIndexSet : false,
    };
    
    loadFeedbackAndBootstrap();
  }

  public componentDidMount() {
    // make sure the z-index of the OCV feedback window is higher than the chatbot
    this.changeZIndexOfOCVContainer();
  }

  /**
 * Both the OCV container and the Copilot side panel have a z-index of 1000000.
 * This makes the Copilot's OCV dialog to be rendered behind the Copilot side panel,
 * when a user moves from the emoji OCV feedback UI to the Copilot.
 * To fix the issue, the z-index of the OCV container is increased
 */
public changeZIndexOfOCVContainer = () => {
  const feedbackWindowId = "fluent-default-layer-host";
  var feedbackElement = document.getElementById(feedbackWindowId);

  if (feedbackElement)
  {
      // until we come up with a better solution, set the z-index to an obnoxiously high value
      feedbackElement.style.zIndex = "1500000";

      // forcing reload of component by setting state by specify that the z-index was set
      this.setState({feedbackZIndexSet: true});
  }
};

  private updateToThumbsUp() {
    feedbackData.feedbackConfig!.feedbackUiType = FeedbackUiType.Modal;
  
    feedbackData.feedbackConfig!.initialFeedbackType = FeedbackType.Smile;
    feedbackData.feedbackConfig!.diagnosticsConfig = { isShareContextDataEnabled: false }
    feedbackData.feedbackConfig!.scenarioConfig = { scenarioType: InAppFeedbackScenarioType.AIThumbsUp, isScenarioEnabled: true }
    feedbackData.feedbackConfig!.featureAreas = ["Chatbot Experience", "Other"];

    // get the updated conversation history from the store at the actual time of click
    let conversationHistory = store.getState().conversationHistory;

    const jsonStringOfConversationHistory = JSON.stringify(conversationHistory);

    /* 
      Creates a file to be uploaded to OCV upon feedback submission if the user clicked "yes" on the consent to send data.
      Feedback will be available as an attachment on the feedback entry in the OCV portal.
      File is not stored locally, only in memory.
    */
    feedbackData.feedbackConfig!.diagnosticsConfig = {
      isShareContextDataEnabled: true,
      getContextData: (): Promise<File[]> => {
        const fileContent: BlobPart[] = []
        fileContent.push(jsonStringOfConversationHistory)
        const file = new File(fileContent, conversationHistoryFileName)
        return Promise.resolve([file])
      },
      
    }
  
    feedbackData.feedbackConfig!.isDisplayed = true;
    updateFeedbackOptions(feedbackData);
  }
  
  private updateToThumbsDown() {
    feedbackData.feedbackConfig!.feedbackUiType = FeedbackUiType.Modal;
  
    feedbackData.feedbackConfig!.initialFeedbackType = FeedbackType.Frown;
    feedbackData.feedbackConfig!.scenarioConfig = { scenarioType: InAppFeedbackScenarioType.AIThumbsDown, isScenarioEnabled: true }
    feedbackData.feedbackConfig!.featureAreas = ["Chatbot Experience", "Other"];

    // get the updated conversation history from the store at the actual time of click
    let conversationHistory = store.getState().conversationHistory;

    const jsonStringOfConversationHistory = JSON.stringify(conversationHistory);
  
    /* 
      Creates a file to be uploaded to OCV upon feedback submission if the user clicked "yes" on the consent to send data.
      Feedback will be available as an attachment on the feedback entry in the OCV portal.
      File is not stored locally, only in memory.
    */
    feedbackData.feedbackConfig!.diagnosticsConfig = {
      isShareContextDataEnabled: true,
      getContextData: (): Promise<File[]> => {
        const fileContent: BlobPart[] = []
        fileContent.push(jsonStringOfConversationHistory)
        const file = new File(fileContent, conversationHistoryFileName)
        return Promise.resolve([file])
      },
    }

    feedbackData.feedbackConfig!.isDisplayed = true;
    updateFeedbackOptions(feedbackData);
  }
  
  private _onThumbsUpClickHandler = () => {
    this.setState({
      thumbsUpSelected: true,      
      thumbsDownIconName: IconNames.Dislike,
      thumbsUpIconName: IconNames.LikeSolid,
      displayString: Intl.Get(LocIds.CopilotChatBot.FeedbackSubmitted)
    });
    this.updateToThumbsUp();
  };
  
  private _onThumbsDownClickHandler = () => {
    this.setState({
      thumbsDownSelected: true,      
      thumbsDownIconName: IconNames.DislikeSolid,
      thumbsUpIconName: IconNames.Like,
      displayString: Intl.Get(LocIds.CopilotChatBot.FeedbackSubmitted)
    });
    this.updateToThumbsDown();
  };

  public render() {
    return (
        <Stack horizontal className="feedback" tokens={{ childrenGap: 15 }}>           
            <p>{this.state.displayString}</p>
            <Stack horizontal className="feedbackIcon" tokens={{ childrenGap: 15 }}>    
              <a>           
                <FontIcon
                    iconName={this.state.thumbsUpIconName}
                    onClick={this._onThumbsUpClickHandler}
                    data-testid="copilot-thumb-up"
                    style={{cursor: 'pointer'}}
                />
              </a>
              <a>
                <FontIcon
                    iconName={this.state.thumbsDownIconName}
                    onClick={this._onThumbsDownClickHandler}
                    data-testid="copilot-thumb-down"
                    style={{cursor: 'pointer'}}
                />
              </a>
            </Stack>
        </Stack>
    );
  }    
}