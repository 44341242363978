import { Reducer } from "redux";
import { DEFAULT_COOKIE_CONSENT } from "../../Common/Services/CookieConsentService";

export type CookieConsentState = Record<WcpConsent.consentCategories, boolean>;

interface CookieConsentUpdateAction {
  type: "COOKIE_CONSENT_UPDATE";
  data: Record<WcpConsent.consentCategories, boolean>;
}

type CookieConsentActions = CookieConsentUpdateAction;

export const defaultCookieConsentState = DEFAULT_COOKIE_CONSENT;

export const getCookieConsentUpdateAction = (
  newConsent: Record<WcpConsent.consentCategories, boolean>
): CookieConsentUpdateAction => {
  return {
    type: "COOKIE_CONSENT_UPDATE",
    data: newConsent,
  };
};

export const reducer: Reducer<CookieConsentState> = (
  state: CookieConsentState,
  action: CookieConsentActions
) => {
  switch (action.type) {
    case "COOKIE_CONSENT_UPDATE":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state || defaultCookieConsentState;
  }
};
