import * as React from 'react';
import { getLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { PanelSelectionHelperFactory } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { PanelCollection } from './PanelsCollection';
import { CommandBarButtonFactory, ButtonTypes } from '../../../Common/Utilities/CommanBarButtonFactory';
import { ViewData, PanelData } from '../../Models/PageInfrastructure';
import { PanelPropertiesDataBase } from '../../Models/PageInfrastructure/PanelPropertiesData/';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import * as ReactMarkdown from 'react-markdown';
import { RichTextLink } from '../../../App/Utilities/RenderUtilities';
import './PivotViewsCollection.css';

interface PivotViewsCollectionProps {
    views: ViewData[];
    isInEditMode: boolean;
    addNewView: () => void;
    modifySelectedView: (index: number) => void;
    moveSelectedViewLeft: (index: number) => void;
    moveSelectedViewRight: (index: number) => void;
    deleteSelectedView: (index: number) => void;
    makeDirty: () => void;
    delete: () => void;
    moveUp: () => void;
    moveDown: () => void;
    idOfPage: string;
    parentId: string;
}

interface ViewsCollectionState {
    views: ViewData[];
}

export class PivotViewsCollection extends React.Component<PivotViewsCollectionProps, ViewsCollectionState> {
    private addViewButton: IContextualMenuItem;
    private modifyViewButton: IContextualMenuItem;
    private moveLeftViewButton: IContextualMenuItem;
    private moveRightViewButton: IContextualMenuItem;
    private deleteViewButton: IContextualMenuItem;
    private deletePanelButton: IContextualMenuItem;
    private movePanelUpButton: IContextualMenuItem;
    private movePanelDownButton: IContextualMenuItem;
    private selectedIndex: number;
    private mustSelectTab: number;

    private tabsContainer: HTMLElement;
    private pivotContainer: HTMLElement;
    private tabTags: HTMLAnchorElement[];
    private prevButton: HTMLButtonElement;
    private nextButton: HTMLButtonElement;
    private hiddenWidth: number;
    private leftMostTabIndex: number;

    constructor(props: PivotViewsCollectionProps) {
        super(props);
        this.state = { views: props.views ? props.views : [] };
        this.selectedIndex = 0;
        this.addViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Add, () => this.props.addNewView(), Intl.Get(LocIds.PageAndPanels.AddViewButtonLabel));
        this.modifyViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Edit, () => this.props.modifySelectedView(this.selectedIndex));
        this.moveLeftViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveLeft, () => this.props.moveSelectedViewLeft(this.selectedIndex));
        this.moveRightViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveRight, () => this.props.moveSelectedViewRight(this.selectedIndex));
        this.deleteViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.deleteSelectedView(this.selectedIndex));
        this.deletePanelButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.delete(), Intl.Get(LocIds.PageAndPanels.DeletePanelLabel), 'DeletePanel');
        this.movePanelUpButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveUp, () => this.props.moveUp(), Intl.Get(LocIds.PageAndPanels.MoveUpPanelButtonDescription));
        this.movePanelDownButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveDown, () => this.props.moveDown(), Intl.Get(LocIds.PageAndPanels.MoveDownPanelButtonDescription));
        this.mustSelectTab = this.getSelectTabInParametters();
        
        this.hiddenWidth = 0;
        this.leftMostTabIndex = 0;

        var isSelectTab = false;
        this.props.views.forEach((view, index) => {
            view.isSelected = (index === this.mustSelectTab);
            if (view.isSelected) {
                isSelectTab = true;
            }
        });
        if (!isSelectTab && this.props.views.length) {
            this.props.views[0].isSelected = true;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: PivotViewsCollectionProps) {
        if (nextProps.views && nextProps.views.length > 0) {
            let selectedItem = nextProps.views.find(x => x.isSelected);
            if (selectedItem) {
                this.selectedIndex = nextProps.views.indexOf(selectedItem);
            }
        }
        this.setState({ views: nextProps.views ? nextProps.views : [] });
    }

    componentDidMount() {
        if (this.prevButton) {
            this.prevButton.style.display = 'none';
        }
        if (this.tabsContainer) {
            this.tabsContainer.style.paddingLeft = '0px';
        }
        if (this.nextButton) {
            if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                this.nextButton.style.display = 'none';
            } else {
                this.nextButton.style.display = 'inline-block';
            }
        }        
        window.addEventListener('resize', () => this.updateTabScrollButtons());
    }
    componentDidUpdate() {
        this.updateTabScrollButtons();
    }
    updateTabScrollButtons() {
        if (this.nextButton) {
            this.nextButton.style.display = (this.leftMostTabIndex === 0) ? 'none' : 'inline-block';
        }
        if (this.tabsContainer) {
            this.nextButton.style.display = (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) ? 'none' : 'inline-block';
        }
    }

    getSelectTabInParametters(): number {
        var response = 0;
        var parametters = window.location.search;
        if (parametters) {
            parametters = parametters.substr(1);
            var params = parametters.split('&');
            params.forEach(p => {
                var contents = p.split('=');
                if (contents.length === 2) {
                    if (contents[0] === 'tab') {
                        let values = decodeURIComponent(contents[1]).split('_');
                        if (values.length === 2) {
                            if (values[0] === this.props.parentId) {
                                response = parseInt(values[1], 0);
                            }
                        }
                    }
                }
            });
        }
        return response;
    }

    selectTab(index: number) {
        let views: ViewData[] = this.state.views;
        views.forEach((value: ViewData, i: number) => {
            value.isSelected = (i === index);
        });
        this.selectedIndex = index;
        this.setState({ views: views });
    }

    addNewPanel(type: string) {
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        selectedView.panels.push(PanelSelectionHelperFactory.getPanel(type));
        this.props.makeDirty();
    }

    deletedPanel(panel: PanelData<PanelPropertiesDataBase>) {
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        let index = selectedView.panels.indexOf(panel);
        let panels = selectedView.panels;
        selectedView.panels = [];
        for (let i = 0; i < panels.length; i++) {
            if (i !== index) {
                selectedView.panels.push(panels[i]);
            }
        }
        this.props.makeDirty();
    }

    movePanelUp(panel: PanelData<PanelPropertiesDataBase>) {
        let index = this.props.views[this.selectedIndex].panels.indexOf(panel);
        if (index > 0) {
            this.swapPanels(index, index - 1);
            this.props.makeDirty();
        }
    }

    movePanelDown(panel: PanelData<PanelPropertiesDataBase>) {
        let index = this.props.views[this.selectedIndex].panels.indexOf(panel);
        if (index < this.props.views[this.selectedIndex].panels.length - 1) {
            this.swapPanels(index, index + 1);
            this.props.makeDirty();
        }
    }

    swapPanels(index1: number, index2: number) {
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        var temp = selectedView.panels[index1];
        selectedView.panels[index1] = selectedView.panels[index2];
        selectedView.panels[index2] = temp;
    }

    render() {
        this.tabTags = [];
        return (
            <div className="stp_pivot-panel">
                {this.props.isInEditMode &&
                    <div data-grid="col-12"><CommandBar
                        items={[this.addViewButton, this.modifyViewButton, this.moveLeftViewButton, this.moveRightViewButton, this.deleteViewButton]}
                        farItems={[this.deletePanelButton, this.movePanelUpButton, this.movePanelDownButton]}
                    /></div>}
                {this.state.views.length > 1 || this.props.isInEditMode ?
                    <div data-grid="col-12" className="c-pivot" style={{overflow: 'hidden'}} ref={(pivotContainer: HTMLDivElement) => this.pivotContainer = pivotContainer} >
                        <button
                            className="tabscroll tabscroll-prev"
                            aria-label="Show prev"
                            ref={(prev: HTMLButtonElement) => this.prevButton = prev}
                            onClick={() => {
                                if (this.leftMostTabIndex > 0) {
                                    this.hiddenWidth -= (this.tabTags[--this.leftMostTabIndex].offsetWidth + 24);
                                    if (this.hiddenWidth < 0) {
                                        this.hiddenWidth = 0;
                                    }
                                    this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';
                                    if (this.leftMostTabIndex === 0) {
                                        this.prevButton.style.display = 'none';
                                        this.tabsContainer.style.paddingLeft = '0px';
                                    } else {
                                        this.tabsContainer.style.paddingLeft = '40px';
                                    }
                                    if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                        this.nextButton.style.display = 'none';
                                    } else {
                                        this.nextButton.style.display = 'inline-block';
                                    }
                                }
                            }}
                        />
                        <header role="tablist" ref={(tabsContainer: HTMLElement) => this.tabsContainer = tabsContainer} className="tabsContainer tabAnimation">
                            {this.state.views.map((data: ViewData, index) => 
                                <a
                                    style={{fontSize: 25}}
                                    key={index}
                                    onClick={() => this.selectTab(index)}
                                    role="tab"
                                    className={data.isSelected ? 'f-active' : ''}
                                    aria-selected={data.isSelected ? 'true' : 'false'}
                                    aria-controls={getLocalizedString(data.name)}
                                    ref={(tabTag: HTMLAnchorElement) => this.tabTags[index] = tabTag}
                                >
                                    {getLocalizedString(data.name) || Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}
                                </a>
                            )}
                        </header>
                        <button
                            className="tabscroll tabscroll-next"
                            aria-label="Show next"
                            ref={(next: HTMLButtonElement) => this.nextButton = next}
                            onClick={() => {
                                if (this.leftMostTabIndex < this.tabTags.length - 1) {
                                    this.prevButton.style.display = 'inline-block';
                                    this.tabsContainer.style.paddingLeft = '40px';

                                    this.hiddenWidth += (this.tabTags[this.leftMostTabIndex++].offsetWidth + 24);
                                    this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';

                                    if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                        this.nextButton.style.display = 'none';
                                    } else {
                                        this.nextButton.style.display = 'inline-block';
                                    }
                                }
                            }}
                        />
                        {this.state.views.map((data: ViewData, index) =>
                            <section key={index} id={getLocalizedString(data.title)} role="tabpanel" aria-hidden={data.isSelected ? 'false' : 'true'}>
                                <div data-grid="col-12">
                                    {getLocalizedString(data.title) && 
                                    <h3 className="c-heading-3">{getLocalizedString(data.title)}</h3>
                                    }
                                    <ReactMarkdown
                                        className="c-paragraph-1"
                                        source={getLocalizedString(data.description) || ''}
                                        renderers={{ link: RichTextLink }}
                                        skipHtml={true}
                                    />
                                </div>
                                <div data-grid="col-12">
                                    <PanelCollection
                                        panels={data.panels}
                                        deletePanel={(n) => this.deletedPanel(n)}
                                        isInEditMode={this.props.isInEditMode}
                                        addNewPanel={(type) => this.addNewPanel(type)}
                                        makeDirty={() => this.props.makeDirty()}
                                        movePanelUp={(n) => this.movePanelUp(n)}
                                        movePanelDown={(n) => this.movePanelDown(n)}
                                        idOfPage={this.props.idOfPage}
                                        parentId={index === 0 ? '' : this.props.parentId + '_' + index}
                                        hasInPageNav={false}
                                    />
                                </div>
                            </section>)}
                    </div>
                    :
                    this.state.views.length > 0 &&
                    <div>
                        <h3 className="c-heading-3">{getLocalizedString(this.state.views[0].title)}</h3>
                        <ReactMarkdown
                            className="c-paragraph-1"
                            source={getLocalizedString(this.state.views[0].description) || ''}
                            renderers={{ link: RichTextLink }}
                            skipHtml={true}
                        />
                        <PanelCollection
                            panels={this.state.views[0].panels}
                            deletePanel={(n) => this.deletedPanel(n)}
                            isInEditMode={this.props.isInEditMode}
                            addNewPanel={(type) => this.addNewPanel(type)}
                            makeDirty={() => this.props.makeDirty()}
                            movePanelUp={(n) => this.movePanelUp(n)}
                            movePanelDown={(n) => this.movePanelDown(n)}
                            idOfPage={this.props.idOfPage}
                            parentId=""
                            hasInPageNav={false}
                        />
                    </div>
                }
            </div>
        );
    }
}