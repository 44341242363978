import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { SearchResult } from '../Models/Search/SearchResult';
import { SearchRecord, LinkType } from '../Models/Search/SearchRecord';
import { SearchCondition } from '../Models/Search/SearchCondition';
import { SearchFilter } from '../Models/Search/SearchFilter';
import { VocabularyFilter } from '../Models/Search/VocabularyFilter';
import { Link } from 'react-router-dom';
import { searchServicesClient } from '../Services/SearchService';
import { SearchRequest, SearchResponse } from '../Services/Models';
import { VocabularySelector, SelectionMode } from '../Components/VocabularySelector';
import { LicenseAgreementPage } from '../Components/Licensing/LicenseAgreementPage';
import { downloadFile, getDownloadDocumentLink } from '../Utilities/DocumentHelper';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Authorize } from '../../Common/Components/Authorize';
import { FlightMembership } from '../../Common/Utilities/AuthorizationInfo';
import { store } from '../../Store';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';
import {
  Pivot,
  PivotItem,
  PivotLinkSize,
  PivotLinkFormat
} from 'office-ui-fabric-react/lib/Pivot';

import './SearchResultPage.css';
import { DefaultButton } from 'office-ui-fabric-react';
import { ExternalNavigationService } from '../Services/ExternalNavigationService';

interface SearchResultPageProps {
    keyword?: string;
}

interface SearchResultPageState {
    // searchCondition for searchType: All, Document, Guides, etc. and filter with vocabulary
    searchCondition: SearchCondition;

    searchResult: SearchResult;
    // isLoading show the spinner when loading the search result
    isLoading: boolean;
    // current page number, start from 0
    currentPage: number;
    // page size, default is the smallest as displayed by the dropdown at the bottom
    pageSize: number;
    // if the user hasn't signed in and wants to download non-public doc,
    // it will show the pop up to require sign-in
    showAgreement: boolean;
    documentsToBeDownloaded: string[];
}

export default class SearchResultPage extends React.Component<SearchResultPageProps, SearchResultPageState> {
    private keywordInput: HTMLInputElement;
    private searchButton: HTMLButtonElement;
    private previewDelimiter: string = '{`~@$';
    
    private externalNavigation = new ExternalNavigationService();

    constructor(props: SearchResultPageProps) {
        super(props);
        this.state = this.initializeSearchCondition();
    }

    // Initialize search condition in constructor:
    // Read from query string to set the filters or set it with default value
    // When anonymous users download non-public doc, it requires sign-in,
    // thus query string used to store the filter info and after user signed in,
    // he stayed at the page showing the same results and start download the doc. 
    initializeSearchCondition(): SearchResultPageState {
        // Parse the query string
        let url = require('url');
        let filters = url.parse(window.location.search, true).query;

        // Values in the filters can be one string or a array of strings
        // We need to make sure that values be array 
        // tslint:disable-next-line:no-any
        let getValues = (values: any): string[] => {
            return values ? typeof values === 'string' ? [values] : values : [];
        };

        let searchFilter: SearchFilter = {
            searchType: filters.searchType ? filters.searchType : 'Document',
            vocabularies: [
                {name: 'Cloud Service', values: getValues(filters.cloudServices)},
                {name: 'Category', values: getValues(filters.categories)},
                {name: 'Regions', values: getValues(filters.regions)},
                {name: 'Industries', values: getValues(filters.industries)}
            ],
        };
        let searchCondition: SearchCondition = {
            keyword: filters.keyword ? filters.keyword : this.props.keyword ? this.props.keyword : '',
            searchFilter: searchFilter,
            pageNumber: filters.pageNumber ? filters.pageNumber : 0,
            pageSize: filters.pageSize ? filters.pageSize : 30,
        };
        return {
            searchCondition: searchCondition,
            searchResult: {searchRecords: [], recordTotalCount: 0},
            isLoading: false,
            currentPage: searchCondition.pageNumber,
            pageSize: searchCondition.pageSize,
            showAgreement: false,
            documentsToBeDownloaded: [] as string[]
        } as SearchResultPageState;
    }
    
    // Fetch the search result and update the loading status.
    // Either success or failed, hide the spinner
    getSearchResult() {
        if (this.state.searchCondition && this.state.searchCondition.keyword) {
            if (this.getSearchType() === 'ComplianceManager' && !store.getState().authentication.isSignedIn) {
                return;
            }
            let appplicationContext = store.getState().applicationContext;
            this.setState({isLoading: true});
            searchServicesClient.GetSearchResult(
                {searchCondition: this.state.searchCondition} as SearchRequest,
                appplicationContext.userInfo,
                () => { return; },
                (response: SearchResponse) => {
                    // When finished scroll the window to the top.
                    window.scrollTo(0, 0);
                    // If the user changed the pagesize, the current page should also be reset
                    let newCurrentPage = this.state.searchCondition.pageSize === this.state.pageSize ? this.state.searchCondition.pageNumber : 0;
                    this.setState({
                        searchResult: response.searchResult,
                        isLoading: false,
                        currentPage: newCurrentPage,
                        pageSize: this.state.searchCondition.pageSize,
                        searchCondition: this.state.searchCondition
                    });
                }, 
                () => {this.setState({isLoading: false}); }
            );
        }
    }

    componentDidMount() {
        this.getSearchResult();
    }
    
    // Render each result item with icon and link(doc to download and page to navigate)
    renderRecordItem(record: SearchRecord, key: string) {
        // used for the background color for each type of doc or link types
        let backgroundClass: string = record.linkType === LinkType.ComplianceManager ? 'recordTypeCONTROL' : record.fileExtension === '.pdf' ? 'recordTypePDF' 
        : record.fileExtension === '.doc' || record.fileExtension === '.docx' ? 'recordTypeDOC'
        : record.fileExtension === '.xls' || record.fileExtension === '.xlsx' ? 'recordTypeEXCEL'
        : record.fileExtension === '.html' ? 'recordTypeHTML'
        : record.fileExtension === '.ppt' || record.fileExtension === '.pptx' ? 'recordTypePPT'
        : 'recordTypeTXT';

        // parse the date and display in proper local format with yyyy-mm-dd
        // may need to put this function into utility
        // and also may related with localization
        var parsed = new Date(record.date);
        var year = parsed.getFullYear();
        var month = parsed.getMonth() + 1;
        var date = parsed.getDate();
        var localTime = (year < 10 ? '000' : year < 100 ? '00' : year < 1000 ? '0' : '') + year + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date;
        var previewSections = record.linkType === LinkType.Document ? record.preview.split(this.previewDelimiter) : record.preview.split('|||');
        if (record.linkUrl.indexOf(',') !== -1) {
            let parsedDocInfo = record.linkUrl.split(',');
            record.isPublic = parsedDocInfo[0] === 'True';
            record.linkUrl = parsedDocInfo[1];
        }

        return (
            <li key={key} className="recordItem">
                <div className={'recordTypeImage ' + backgroundClass}>
                    <i
                        className={
                            record.linkType === LinkType.ComplianceManager ? 'ms-Icon ms-Icon--ViewDashboard' :
                            record.fileExtension === '.pdf' ? 'ms-Icon ms-Icon--PDF' 
                            : record.fileExtension === '.doc' || record.fileExtension === '.docx' ? 'ms-Icon ms-Icon--WordDocument'
                            : record.fileExtension === '.xls' || record.fileExtension === '.xlsx' ? 'ms-Icon ms-Icon--ExcelDocument'
                            : record.fileExtension === '.html' ? 'ms-Icon ms-Icon--NavigateExternalInline'
                            : record.fileExtension === '.ppt' || record.fileExtension === '.pptx' ? 'ms-Icon ms-Icon--PowerPointDocument'
                            : 'ms-Icon ms-Icon--Document'
                        }
                        aria-hidden="true"
                    />
                    {this.state.documentsToBeDownloaded.indexOf(record.linkUrl) !== -1 && <Spinner size={SpinnerSize.large} ariaLive="assertive" className="downloadSpinner" />}
                </div>
                <div className="recordInfo">
                    <div className="recordDate">{localTime}</div>
                    <div
                        className="recordTitle" role = "link" aria-label = {record.title.replace(/{`~@\$/g, '')}
                    >
                        <Link to={'/DocumentPage/' + record.linkUrl}>{record.title.replace(/{`~@\$/g, '')}</Link>
                    </div>
                    <div className="recordPreview">
                        {record.linkType === LinkType.Document ? this.renderHighlight(previewSections, '') : this.renderCMDetail(previewSections)}
                    </div>
                </div>
            </li>
        );
    }

    renderCMDetail(sections: string[]) {
        let titles = [Intl.Get(LocIds.Search.ControlID), Intl.Get(LocIds.Search.ControlTitle), Intl.Get(LocIds.Search.ControlDescription)];
        return (
            <div>
                {sections.map((section, index) => {
                    return section !== ' ' ? this.renderHighlight(section.split(this.previewDelimiter), titles[index]) : null;
                })}
            </div>
        );
    }

    renderHighlight(sections: string[], title: string) {
        return (
            <div>
                {title && <b>{title}</b>}
                {sections.map((s, index) => {
                    if (index % 2 === 1) {
                        return <b>{s}</b>;
                    } else {
                        return <span>{s}</span>;
                    }
                })}
            </div>
        );
    }

    renderPaginationBar() {
        // if there is no result, display nothing
        if (this.state.searchResult.recordTotalCount === 0) {
            return null;
        }

        // calculate the max page number, start with 0
        let maxPageNumber = Math.ceil(this.state.searchResult.recordTotalCount / this.state.searchCondition.pageSize) - 1;
        // calculate the start page number and end page number
        // pagenation bar will contains at most 10 choices
        let start = Math.floor(this.state.currentPage / 10) * 10;
        let end = start + 9 < maxPageNumber ? start + 9 : maxPageNumber;
        let pageNumbers = [];
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }

        return (
            <ul data-grid="col-12" className="pagination">
                {this.state.currentPage !== 0 &&
                    <li className="page-item">
                        <span
                            className="page-link"
                            tabIndex={0}
                            aria-label={Intl.Get(LocIds.Search.PreviousPageAriaLabel)}
                            role="link"
                            onClick={() => this.handlePaginationOnClick(null, null)}
                            onKeyDown={(e) => this.handlePaginationOnKeyDown(e, null, null)}
                        >
                            {Intl.Get(LocIds.Search.PreviousPage)}
                        </span>
                    </li>
                }
                {
                    // Render page number: if current on that page make that number highlighted;
                    // else display it as a clickable link
                    pageNumbers.map((num, index) => {
                        // === doesn't work for compare these two numbers
                        if (Math.abs(num - this.state.searchCondition.pageNumber) < 0.1) {
                            return (
                                <li key={'Page' + num + 1} className="page-item f-active" data-label={'Page' + num + 1}>
                                    <a className="page-link">
                                        <span aria-label={Intl.Get(LocIds.Search.CurrentOnPageAriaLabel).replace('[p]', num)}>{num + 1}</span>
                                    </a>
                                </li>
                            );
                        } else {
                            return (
                                <li key={'Page' + num + 1} data-label={'Currently on page ' + num + 1} className="page-item">
                                    <a
                                        tabIndex={0}
                                        aria-label={Intl.Get(LocIds.Search.PageNumberAriaLabel).replace('[p]', num)}
                                        onClick={() => this.handlePaginationOnClick(null, num)}
                                        onKeyDown={(e) => this.handlePaginationOnKeyDown(e, null, num)}
                                        className="page-link"
                                        role="link"
                                    >
                                        <span>{num + 1}</span>
                                    </a>
                                </li>
                            );
                        }
                    })
                }
                {this.state.currentPage < maxPageNumber &&
                <li>
                    <a
                        className="page-link"
                        tabIndex={0}
                        aria-label={Intl.Get(LocIds.Search.NextPageAriaLabel)}
                        onClick={() => this.handlePaginationOnClick(maxPageNumber, null)}
                        onKeyDown={(e) => this.handlePaginationOnKeyDown(e, maxPageNumber, null)}
                        role="link"
                    >
                        <span>{Intl.Get(LocIds.Search.NextPage)}</span>
                    </a>
                </li>}
            </ul>
        );
    }

    handlePaginationOnClick = (maxPageNumber: number | null, pageNumber: number | null) => {
        // Previous
        if (!maxPageNumber && !pageNumber && this.state.searchCondition.pageNumber > 0) {
            this.state.searchCondition.pageNumber--;
            this.getSearchResult();
            this.cleanQueryString();
        }

        // Page
        if (pageNumber && !maxPageNumber) {
            this.state.searchCondition.pageNumber = pageNumber;
            this.getSearchResult();
            this.cleanQueryString();
        }

        // Next
        if (maxPageNumber && !pageNumber && this.state.searchCondition.pageNumber < maxPageNumber) {
            this.state.searchCondition.pageNumber++;
            this.getSearchResult();
            this.cleanQueryString();
        }
    }

    handlePaginationOnKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>, maxPageNumber: number | null, pageNumber: number | null) => {
        if (e.key === 'Enter') {
            this.handlePaginationOnClick(maxPageNumber, pageNumber);
        }
    }

    // When change the selection of the vocabulary,
    // update the search condition and do search request
    selectVocabulary(vocaName: string, selectedKey: string[]) {
        if (this.state.searchCondition.searchFilter) {
            if (!selectedKey) {
                selectedKey = [];
            }
            // hard code to set each vocalbulary's values to search filter
            if (vocaName === 'Cloud Service') {
                this.state.searchCondition.searchFilter.vocabularies[0].values = selectedKey;
            } else if (vocaName === 'Category') {
                this.state.searchCondition.searchFilter.vocabularies[1].values = selectedKey;
            } else if (vocaName === 'Regions') {
                this.state.searchCondition.searchFilter.vocabularies[2].values = selectedKey;
            } else if (vocaName === 'Industries') {
                this.state.searchCondition.searchFilter.vocabularies[3].values = selectedKey;
            }
            this.state.searchCondition.pageNumber = 0;
            this.getSearchResult();
        }
        this.cleanQueryString();
    }

    // Construct the filter string for recover the same search result when the user signed in at the same page
    getFilterString() {
        let filterString: string = '';
        let condition = this.state.searchCondition;
        filterString += 'keyword=' + condition.keyword;
        filterString += '&pageNumber=' + condition.pageNumber;
        filterString += '&pageSize=' + condition.pageSize;

        let filter = condition.searchFilter;
        filterString += '&searchType=' + filter.searchType;

        let cloudServices = filter.vocabularies[0];
        let categories = filter.vocabularies[1];
        let regions = filter.vocabularies[2];
        let industries = filter.vocabularies[3];

        let appendVocabulary = (name: string, vocabulary: VocabularyFilter) => {
            for (var voc of vocabulary.values) {            
                filterString = filterString + '&' + name + '=' + voc;
            }
        };
        appendVocabulary('cloudServices', cloudServices);
        appendVocabulary('categories', categories);
        appendVocabulary('regions', regions);
        appendVocabulary('industries', industries);

        return filterString;
    }

    cleanQueryString() {
        let cleanUrl = window.location.href.replace(window.location.search, '') + '?' + this.getFilterString();
        window.history.pushState('', '', cleanUrl);
    }

    onPageSizeChange(selection: React.SyntheticEvent<HTMLSelectElement>) {
        let searchCondition = this.state.searchCondition;
        searchCondition.pageSize = parseInt(selection.currentTarget.value, 10);
        searchCondition.pageNumber = 0;
        this.setState({searchCondition: searchCondition}, () => {
            this.getSearchResult();
            this.cleanQueryString();
        });
    }

    onSearchClick(type: string | undefined) {
        if (!this.keywordInput.value) {
            return;
        }
        let searchCondition = this.state.searchCondition;
        searchCondition.searchFilter.searchType = type === 'ComplianceManager' ? 'ComplianceManager' : 'Document';
        // When click either search button of the search panel or the top nav search button,
        // 1. update the keyword
        // 2. clear all the vocabulary filter and set the current page to the first.
        // 3. do request to fetch result
        searchCondition.keyword = this.keywordInput.value;
        searchCondition.pageNumber = 0;
        for (let i = 0; i < 4; i++) {
            searchCondition.searchFilter.vocabularies[i].values = [];
        }
        if (type === 'ComplianceManager') {
            if (!store.getState().authentication.isSignedIn) {
                this.setState({showAgreement: true, searchResult: {searchRecords: [], recordTotalCount: 0}});
                this.cleanQueryString();
                return;
            }
        }
        this.setState({searchResult: {searchRecords: [], recordTotalCount: 0}, searchCondition: searchCondition}, () => {
            this.getSearchResult();
            this.cleanQueryString();
        });
    }

    getSearchType(): string {
        return this.state.searchCondition.searchFilter.searchType === 'ComplianceManager' ? 'ComplianceManager' : 'Document';
    }

    render() {
        let recordStartIndex = this.state.pageSize * this.state.currentPage + 1;
        let recordEndIndex = recordStartIndex + this.state.searchResult.searchRecords.length - 1;
        let isNewUI = store.getState().applicationContext.userInfo.flights.indexOf('NewUIDesign') > -1;
        let resultSummary = this.state.searchResult.recordTotalCount > 1 ? 
        Intl.Get(LocIds.Search.ResultsSummaryMultiple).replace('[s]', recordStartIndex).replace('[e]', recordEndIndex).replace('[total]', this.state.searchResult.recordTotalCount) : 
        this.state.searchResult.recordTotalCount === 1 ? Intl.Get(LocIds.Search.ResultsSummaryOne) : 
        Intl.Get(LocIds.Search.ResultsSummaryNone);
        if (this.getSearchType() !== 'ComplianceManager') {
            resultSummary += Intl.Get(LocIds.Search.ResultsSummaryFilters);
            let filterTextTemplate = Intl.Get(LocIds.Search.ResultsSummaryFilterSelected);
            let filterText = '';
            let voca = this.state.searchCondition.searchFilter.vocabularies;
            for (var voc of voca) {
                if (voc.values.length > 0) {
                    if (filterText.length > 0) {
                        filterText += ', ';
                    }
                    filterText = filterText + voc.name + filterTextTemplate.replace('[n]', voc.values.length.toString());
                }
            }
            resultSummary = resultSummary + ' ' + (filterText ? filterText : Intl.Get(LocIds.Search.ResultsAllResult));
        }
        return (
            <div>
                {this.state.isLoading && <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingLabel)} ariaLive="assertive" className="loading-panel-full" />}
                {this.state.showAgreement &&
                <LicenseAgreementPage 
                    onAgree={() => {
                        this.setState({showAgreement: false});
                    }}
                    onDismiss={() => {
                        this.setState({showAgreement: false});
                    }}
                    callBack={() => {
                        this.setState({showAgreement: false});
                    }}
                />}
                {/*Search Panel*/}
                <div className="searchPanel">
                    <div className="searchPanelInner">
                        <div>
                            <span className="searchTitle">Search</span>
                        </div>
                        <div className="c-search">
                            <input
                                aria-label={Intl.Get(LocIds.Search.EnterYourSearchAriaLabel)}
                                type="search"
                                name="search-field"
                                role="searchbox"
                                placeholder={Intl.Get(LocIds.Search.Placeholder)}
                                defaultValue={this.state.searchCondition.keyword}
                                ref={(input: HTMLInputElement) => this.keywordInput = input}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        // When clicking enter button, do search process and make the search button lose focus (to remove the dot border)
                                        this.searchButton.click();
                                        this.searchButton.blur();
                                    }
                                }}
                            />
                            {/*Search Button*/}
                            <button
                                className="c-glyph"
                                name="search-button"
                                onClick={(e) => {
                                    e.preventDefault(); 
                                    this.onSearchClick(this.getSearchType());
                                }}
                                ref={(button: HTMLButtonElement) => this.searchButton = button}
                            >
                                <span className="x-screen-reader">Search</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/*Search Tab, Doc or Compliance Manager*/}
                <Authorize
                    requiredFlights={['CMSearch']}
                    flightMembership={FlightMembership.Any}
                    requiredPermissions={[]}
                >  
                    <div>
                        <div className="resultTypeTab">
                            <Pivot
                                linkFormat={PivotLinkFormat.links}
                                linkSize={PivotLinkSize.normal}
                                onLinkClick={(item: PivotItem) => {
                                    this.setState({searchResult: {searchRecords: [], recordTotalCount: 0}});
                                    this.onSearchClick(item.props.itemKey);
                                }}
                                selectedKey={this.state.searchCondition && this.getSearchType()}
                            >
                                <PivotItem key="Document" itemKey="Document" linkText="Documents" />
                            </Pivot>
                        </div>
                    </div>
                </Authorize>
                {/*Filter Bar*/}
                {this.getSearchType() !== 'ComplianceManager' && <div className="filterBar">
                    <div className="filterFloat">
                        <div className="filterText">
                            {Intl.Get(LocIds.Search.FilterBy)}
                        </div>
                    </div>
                    <div className="filterFloat">
                        <VocabularySelector
                            key={'Cloud Service'}
                            vocabularyName={'Cloud Service'}
                            values={this.state.searchCondition.searchFilter && this.state.searchCondition.searchFilter.vocabularies[0].values ? this.state.searchCondition.searchFilter.vocabularies[0].values : []}
                            selectionMode={SelectionMode.multiple}
                            onChanged={(vocaName, values) => this.selectVocabulary(vocaName, values)}
                            isNewUI={false}
                        />
                    </div>
                    <div className="filterFloat">
                        <VocabularySelector
                            key={'Category'}
                            vocabularyName={'Category'}
                            values={this.state.searchCondition.searchFilter && this.state.searchCondition.searchFilter.vocabularies[1].values ? this.state.searchCondition.searchFilter.vocabularies[1].values : []}
                            style={{padding: 0}}
                            selectionMode={SelectionMode.multiple}
                            onChanged={(vocaName, values) => this.selectVocabulary(vocaName, values)}
                            isNewUI={isNewUI}
                        />
                    </div>
                    <div className="filterFloat">
                        <VocabularySelector
                            key={'Regions'}
                            vocabularyName={'Regions'}
                            values={this.state.searchCondition.searchFilter && this.state.searchCondition.searchFilter.vocabularies[2].values ? this.state.searchCondition.searchFilter.vocabularies[2].values : []}
                            style={{padding: 0}}
                            selectionMode={SelectionMode.multiple}
                            onChanged={(vocaName, values) => this.selectVocabulary(vocaName, values)}
                            isNewUI={isNewUI}
                        />
                    </div>
                    <div className="filterFloat">
                        <VocabularySelector
                            key={'Industries'}
                            vocabularyName={'Industries'}
                            values={this.state.searchCondition.searchFilter && this.state.searchCondition.searchFilter.vocabularies[3].values ? this.state.searchCondition.searchFilter.vocabularies[3].values : []}
                            style={{padding: 0}}
                            selectionMode={SelectionMode.multiple}
                            onChanged={(vocaName, values) => this.selectVocabulary(vocaName, values)}
                            isNewUI={false}
                        />
                    </div>
                    <div className="filterFloat">
                        <DefaultButton
                            text={Intl.Get(LocIds.Search.ClearFilter)}
                            onClick={() => {
                                let searchCondition = this.state.searchCondition;
                                searchCondition.pageNumber = 0;
                                searchCondition.searchFilter.searchType = 'Document';
                                for (let i = 0; i < 4; i++) {
                                    searchCondition.searchFilter.vocabularies[i].values = [];
                                }
                                this.setState({searchCondition: searchCondition}, () => {
                                    this.getSearchResult();
                                    this.cleanQueryString();
                                });
                            }} 
                        />
                    </div>
                </div>}
                <div className="resultSection">
                    <div className="resultContentMargin" aria-live="polite" aria-relevant="additions text">
                        {this.state.searchCondition.keyword && (!this.state.isLoading || this.state.searchResult.recordTotalCount > 0) ? resultSummary : ''}
                    </div>
                    <div className="resultContentMargin">
                        <ul className="recordList">
                            {this.state.searchResult.searchRecords.map((record, index) => {
                                return this.renderRecordItem(record, index.toString());
                            })}
                        </ul>
                    </div>
                    {
                        this.state.searchCondition.keyword && (!this.state.isLoading || this.state.searchResult) &&
                        <div className="pageBar">
                            <div className="pageNumberBar resultContentMargin">
                                {this.renderPaginationBar()}
                            </div>
                            <div className="pageSizeSelection resultContentMargin">
                                <label id="pageSizeSelectionLabel" className="contentVertical">{Intl.Get(LocIds.Search.ResultsPerPage)}</label>
                                <select
                                    value={this.state.searchCondition.pageSize}
                                    onChange={(selection) => this.onPageSizeChange(selection)}
                                    className="contentVertical"
                                    aria-labelledby="pageSizeSelectionLabel"
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}