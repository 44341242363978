export enum LinkType {
    'Document',
    'ComplianceManager'
}
export interface SearchRecord {
    linkType: LinkType;
    linkUrl: string;
    title: string;
    preview: string;
    date: string;
    fileExtension: string;    
    externalUrl: string;
    isPublic: boolean;
}