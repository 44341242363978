import * as React from 'react';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';
import { store } from '../../Store';
import './ErrorPage.css'

interface ErrorProps {
    message?: string;
}

export class ErrorPage extends React.Component<ErrorProps> {
    render() {
        return (
            <p className="error-message">
                {this.props.message ?
                    <h4>
                        {decodeURIComponent(this.props.message)}
                    </h4>
                    :
                    <h4>
                        {(store.getState().authentication.isSignedIn) ? Intl.Get(LocIds.ErrorPage.NotExistOrNoPermission) : Intl.Get(LocIds.ErrorPage.NotExistOrNotSignedIn)}
                    </h4>
                }
            </p>
        );
    }
}