import * as React from 'react';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PanelPropertiesDataBase } from '../../Models/PageInfrastructure';
import { Validator } from '../../../Common/Utilities/Validator';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';

export interface BasePanelState<T extends PanelPropertiesDataBase> {
    showEditor: boolean;
    panelData: T;
    selectedIndex: number;
    selectedLinkIndex: number;
    showImagePicker: boolean;
    showBackgroundImagePicker: boolean;
    showThumbnailImagePicker: boolean;
}

export class BasePanel<T extends PanelPropertiesDataBase> extends React.Component<BasicPanelProperties<T>, BasePanelState<T>> {
    protected backup: T;
    protected panelValidator: Validator = new Validator();

    constructor(props: BasicPanelProperties<T>) {
        super(props);

        const panelData = getDeepCopy(this.props.panel.panelInfo);
        this.state = {
            showEditor: false,
            panelData: panelData,
            selectedIndex: 0,
            selectedLinkIndex: -1,
            showImagePicker: false,
            showBackgroundImagePicker: false,
            showThumbnailImagePicker: false
        };
        this.backup = getDeepCopy(panelData);
    }

    UNSAFE_componentWillReceiveProps(nextProps: BasicPanelProperties<T>) {
        if (!(nextProps.panel && nextProps.panel.panelInfo)) { return; }

        if (JSON.stringify(this.props.panel.panelInfo) !== JSON.stringify(nextProps.panel.panelInfo)) {
            const newPanelData = {
                ...this.state.panelData,
                ...nextProps.panel.panelInfo
            };

            this.setState({
                panelData: newPanelData,
                selectedIndex: 0,
                selectedLinkIndex: -1
            });
            this.backup = getDeepCopy(newPanelData);
        }
    }

    onDiscardChange() {
        this.props.panel.panelInfo = getDeepCopy(this.backup);
        this.setState({ panelData: this.props.panel.panelInfo, showEditor: false });
    }

    onSave() {
        if (!this.panelValidator.validate()) {
            return;
        }
        this.backup = getDeepCopy(this.state.panelData);
        this.props.panel.panelInfo = getDeepCopy(this.backup);
        this.setState({ showEditor: false });
        this.props.makeDirty();
    }

    onImagePicker() {
        this.setState({showImagePicker: true });
    }
}
