import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { ContentPivotPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { getNeutralThemeOptions, getThemeClassName, getContentPivotColumn, deleteOnePanelFromCollection, moveSelectedColumn } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { ComboBox, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import Constants from '../../Utilities/Constants';
import * as ReactMarkdown from 'react-markdown';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import './ContentPivotPanel.css';

export class ContentPivotPanel extends BasePanel<ContentPivotPanelPropertiesData> {
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    private tabsContainer: HTMLElement;
    private pivotContainer: HTMLElement;
    private tabTags: HTMLAnchorElement[];
    private prevButton: HTMLButtonElement;
    private nextButton: HTMLButtonElement;
    private hiddenWidth: number;
    private leftMostTabIndex: number;

    constructor(props: BasicPanelProperties<ContentPivotPanelPropertiesData>) {
        super(props);
        this.hiddenWidth = 0;
        this.leftMostTabIndex = 0;
        this.themeOptions = this.themeOptions.concat(getNeutralThemeOptions(true));
    }
    componentDidMount() {
        if (this.prevButton) {
            this.prevButton.style.display = 'none';
        }
        if (this.nextButton) {
            if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                this.nextButton.style.display = 'none';
            } else {
                this.nextButton.style.display = 'inline-block';
            }
        }        
        window.addEventListener('resize', () => this.updateTabScrollButtons());
    }
    componentDidUpdate() {
        this.updateTabScrollButtons();
    }
    updateTabScrollButtons() {
        if (this.nextButton) {
            this.nextButton.style.display = (this.leftMostTabIndex === 0) ? 'none' : 'inline-block';
        }
        if (this.tabsContainer) {
            this.nextButton.style.display = (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) ? 'none' : 'inline-block';
        }
    }

    getActualPanel() {
        return (
            <div className="content-pivot-panel" data-panel-type="Content Pivot">
                {this.state.panelData.columns ?
                    <div className="c-mosaic">
                        <div className="theme-dark" data-f-mosaic="f-vp1-whole f-vp4-half f-height-medium">
                            <section className={'c-mosaic-placement f-height-large f-width-large ' + getThemeClassName(Theme[this.state.panelData.columns[this.state.selectedIndex].theme || 'Accent'])}>
                                <picture className="c-image">
                                    <img
                                        aria-hidden={true}
                                        style={{ width: '100%' }} 
                                        src={imageHelper.getImageUrl(this.state.panelData.columns[this.state.selectedIndex].imageId)}
                                        data-src={imageHelper.getImageUrl(this.state.panelData.columns[this.state.selectedIndex].imageId)}
                                        alt={this.state.panelData.columns[this.state.selectedIndex].imageTitle} 
                                    />
                                </picture>
                            </section>
                        </div>
                        <div className="theme-dark" data-f-mosaic="f-vp1-whole f-vp4-half f-height-medium">
                            <section className={'c-mosaic-placement f-height-large f-width-large ' + getThemeClassName(Theme[this.state.panelData.columns[this.state.selectedIndex].theme || 'Accent'])}>
                                <div className="c-pivot" style={{overflow: 'hidden'}} ref={(pivotContainer: HTMLDivElement) => this.pivotContainer = pivotContainer}>
                                    <button
                                        className="tabscroll tabscrollContentPivot tabscroll-prev"
                                        aria-label="Show prev"
                                        ref={(prev: HTMLButtonElement) => this.prevButton = prev}
                                        onClick={() => {
                                            if (this.leftMostTabIndex > 0) {
                                                this.hiddenWidth -= (this.tabTags[--this.leftMostTabIndex].offsetWidth + 24);
                                                if (this.hiddenWidth < 0) {
                                                    this.hiddenWidth = 0;
                                                }
                                                this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';
                                                if (this.leftMostTabIndex === 0) {
                                                    this.prevButton.style.display = 'none';
                                                }
                                                if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                                    this.nextButton.style.display = 'none';
                                                } else {
                                                    this.nextButton.style.display = 'inline-block';
                                                }
                                            }
                                        }}
                                    />
                                    <header role="tablist" ref={(tabsContainer: HTMLElement) => this.tabsContainer = tabsContainer} className="tabsContainer tabAnimation">
                                        {this.state.panelData.columns.map((data, index) =>
                                            <a
                                                key={index}
                                                role="tab"
                                                className={index === this.state.selectedIndex ? 'f-active' : ''}
                                                aria-selected={index === this.state.selectedIndex ? 'true' : 'false'}
                                                aria-controls={getLocalizedString(data.title)}
                                                onClick={() => {
                                                    if (this.props.isInEditMode && !this.panelValidator.validate()) {
                                                        return;
                                                    }
                                                    this.setState({ selectedIndex: index });
                                                }}
                                                ref={(tabTag: HTMLAnchorElement) => this.tabTags[index] = tabTag}
                                            >
                                                {getLocalizedString(data.title) || Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}
                                            </a>)}
                                    </header>
                                    <button
                                        className="tabscroll tabscrollContentPivot tabscroll-next"
                                        aria-label="Show next"
                                        ref={(next: HTMLButtonElement) => this.nextButton = next}
                                        onClick={() => {
                                            if (this.leftMostTabIndex < this.tabTags.length - 1) {
                                                this.prevButton.style.display = 'inline-block';
                                                this.tabsContainer.style.paddingLeft = '40px';

                                                this.hiddenWidth += (this.tabTags[this.leftMostTabIndex++].offsetWidth + 24);
                                                this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';

                                                if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                                    this.nextButton.style.display = 'none';
                                                } else {
                                                    this.nextButton.style.display = 'inline-block';
                                                }
                                            }
                                        }}
                                    />
                                    <div role="tabpanel">
                                        <h2 style={{ whiteSpace: 'normal' }} className="c-heading-2">{getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h2>
                                        <ReactMarkdown
                                            className="c-paragraph-1 pivot-para"
                                            source={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            skipHtml={true}
                                        />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }

    onAddColumn() {
        if (!this.state.panelData.columns || !this.panelValidator.validate()) {
            return;
        }
        this.state.panelData.columns.push(getContentPivotColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    onDeleteColumn() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            var panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    onMoveLeft() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    onMoveRight() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    render() {
        this.tabTags = [];
        return (
            <div>
                {this.props.isInEditMode ?
                    <div className="row" style={{marginLeft: '0px'}}>
                        {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                        <div className="col">
                            {this.getActualPanel()}
                            {this.state.showEditor && this.state.panelData.columns ?
                                <div className="editPanel">
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.columns[this.state.selectedIndex].theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].theme = item.key.toString();
                                                        this.setState({ panelData: this.state.panelData });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].title)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length > Constants.PANEL_TITLE_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_TITLE_MAX);
                                                        }
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'title', newVal);
                                                        this.setState({ panelData: this.state.panelData });
                                                    }
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.TitleLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns[this.state.selectedIndex].imageTitle || ''}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                disabled={true}
                                                required={true}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={images => {
                                                    if (images && images.length && this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                        this.state.panelData.columns[this.state.selectedIndex].imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                        }
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal);
                                                        this.setState({ panelData: this.state.panelData });
                                                    }
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].subHeading)}
                                                onChange={x => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'subHeading', x);
                                                        this.setState({ panelData: this.state.panelData });
                                                    }
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                selectedIndex={this.state.selectedIndex}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add'}}
                                        text={Intl.Get(LocIds.PageAndPanels.AddPanelLabel)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeletePanelLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight'}} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                                :
                                null}
                        </div>
                    </div >
                    :
                    this.getActualPanel()
                }
            </div>
        );
    }
}