import { RelatedArticles } from '../../App/Models/ComplianceManagerV3/RelatedArticles';
import { Control } from '../../App/Models/ComplianceManagerV3/Control';
import { ControlType } from '../../App/Models/ComplianceManagerV3/ControlType';
import { UserActions } from '../../App/Models/ComplianceManagerV3/UserActions';
import { CertificationControlFamily } from '../../App/Models/ComplianceManagerV3/CertificationControlFamily';

export const relatedArticles: RelatedArticles[] = [
    {
        certificateId: '1davdf234',
        certificateName: 'NIST 800-171',
        controlId: '1avdfv234',
        controlName: '3.1.1',
        description: '1234',
        title: '1234',
        articleName: '1234',
        isGdpr: true,
        controlFamilyId: '1234',
    },
    {
        certificateId: '12vadfv34',
        certificateName: 'HIPAA',
        controlId: '123avfdv4',
        controlName: '45 C.F.R. § 164.308(a)(3)(i)',
        description: '1234',
        title: '1234',
        articleName: '1234',
        isGdpr: true,
        controlFamilyId: '1234',
    },
    {
        certificateId: '12afsvaf34',
        certificateName: 'CSA CCM301',
        controlId: '12afvafv34',
        controlName: 'GRM-04, IAM-02',
        description: '1234',
        title: '1234',
        articleName: '1234',
        isGdpr: true,
        controlFamilyId: '1234',
    },
    {
        certificateId: '123afvadfv4',
        certificateName: 'ISO 27001:2013',
        controlId: '123vafsvadf4',
        controlName: 'A.9.1.1',
        description: '1234',
        title: '1234',
        articleName: '1234',
        isGdpr: true,
        controlFamilyId: '1234',
    }
];

export const controls: Control[] = [
    {
        id: '1234',
        tileId: '1234',
        certificationId: '1234',
        certificationName: '1234',
        controlFamilyId: '1234',
        controlId: '1234',
        name: '7.2.2',
        title: 'Access Control Policy And Procedures',
        // tslint:disable-next-line:max-line-length
        description: 'The organization: Develops, documents, and disseminates to [Assignment: organization-defined personnel or roles]: An access control policy that addresses purpose, scope, roles, responsibilities, management commitment, coordination among organizational entities, and compliance',
        complianceScore: 6,
        userAction: new UserActions(),
        customerActionDescription: '1234',
        customerActionId: '1234',
        relatedArticles: relatedArticles,
        articleName: '1234',
        isGdpr: true,
        status: 0,
        controlType: ControlType.CustomerManagedControl,
        customerActions: []
    },
    {
        id: '1234',
        tileId: '1234',
        certificationId: '1234',
        certificationName: '1234',
        controlFamilyId: '1234',
        controlId: '1234',
        name: '7.2.2',
        title: 'Identify lawful basis',
        description: 'Article (10): Processing of personal data relating to criminal convictions and offences or related security measures based on Article 6(1) shall be carried out only under the control of official authority',
        complianceScore: 12,
        userAction: new UserActions(),
        customerActionDescription: '1234',
        customerActionId: '1234',
        relatedArticles: relatedArticles,
        articleName: '1234',
        isGdpr: true,
        status: 0,
        controlType: ControlType.CustomerManagedControl,
        customerActions: []
    },
    {
        id: '1234',
        tileId: '1234',
        certificationId: '1234',
        certificationName: '1234',
        controlFamilyId: '1234',
        controlId: '1234',
        name: '7.2.3',
        title: 'Determine when consent is to be obtained',
        description: 'The controller shall make reasonable efforts to verify in such cases that consent is given or authorised by the holder of parental responsibility over the child, taking into consideration available technology.',
        complianceScore: 3,
        userAction: new UserActions(),
        customerActionDescription: '1234',
        customerActionId: '1234',
        relatedArticles: relatedArticles,
        articleName: '1234',
        isGdpr: true,
        status: 0,
        controlType: ControlType.CustomerManagedControl,
        customerActions: []
    },
    {
        id: '1234',
        tileId: '1234',
        certificationId: '1234',
        certificationName: '1234',
        controlFamilyId: '1234',
        controlId: '1234',
        name: '7.2.4',
        title: 'Obtain and record consent',
        description: 'Where processing is based on consent, the controller shall be able to demonstrate that the data subject has consented to processing of his or her personal data',
        complianceScore: 9,
        userAction: new UserActions(),
        customerActionDescription: '1234',
        customerActionId: '1234',
        relatedArticles: relatedArticles,
        articleName: '1234',
        isGdpr: true,
        status: 0,
        controlType: ControlType.CustomerManagedControl,
        customerActions: []
    }
];

export const certificationControlFamilies: CertificationControlFamily[] = [

    {
        id: '1234',
        name: 'PII sharing, transfer, and disclosure',
        title: 'PII sharing, transfer, and disclosure',
        description: 'Description',
        completedCustomerControls: 0,
        customerControls: 4,
        completedMicrosoftControls: 0,
        microsoftControls: 4,
        msControls: [],
        cmControls: controls,
        isLoading: true
    }

];

export class MockControlFamilyService {

    // TBD - Add other crud operations based on the need
    getControlFamilies(): CertificationControlFamily[] {
        return certificationControlFamilies;
    }

}

export let MockControlFamilyClientService = new MockControlFamilyService();