import * as React from 'react';
import { store } from '../../Store';
import { AvailableCulture } from '../Models/Settings/AvailableCulture';
import './LanguageSelectionPage.css';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';

export interface LanguageSelectionPageProps {
}
export interface LanguageSelectionPageState {
    availableCultures: AvailableCulture[];
}
export default class LanguageSelectionPage extends React.Component<LanguageSelectionPageProps, LanguageSelectionPageState> {
    constructor(props: LanguageSelectionPageProps) {
        super(props);
        let applicationContext = store.getState().applicationContext;
        let availableCultures = applicationContext.availableCultures.sort(
            (a: AvailableCulture, b: AvailableCulture) => a.englishName.localeCompare(b.englishName));
        this.state = {
            availableCultures: availableCultures
        };
    }

    handleChangeLanguage = (node: AvailableCulture) => {
        // Create a session cookie to store the user selected language
        // Will be passed in dorequest_new in the header
        // After create the cookie redirect the window to homepage (reload the Application Context)
        document.cookie = 'lan=' + node.name + ';path=/;';
        document.location.href = '/';
    }

    render() {
        window.scrollTo(0, 0);
        return (
            <div className="cultureDiv">
                <h1 className="cultureSelectionTitle">{Intl.Get(LocIds.LanguageSelectionPage.Title)}</h1>
                <ul className="cultureSelection">
                    {
                        Array.from(this.state.availableCultures).map((node, index) => {
                            return (
                                <li key={'culture-' + index}>
                                    <a tabIndex={0}
                                        onClick={() => this.handleChangeLanguage(node)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') this.handleChangeLanguage(node)
                                        }}
                                    >
                                        {node.englishName + ' - ' + node.nativeName}
                                    </a>
                                </li>);
                        })
                    }
                </ul>
            </div>
        );
    }
}