import { AxiosResponse, AxiosError, CancelTokenSource, default as axios, AxiosPromise } from 'axios';
import { store } from '../../Store';
import { ErrorResponse } from '../../App/Services/Models/index';
import * as StatusMessageReducer from '../../App/Reducers/StatusMessageReducer';
import { TelemetryClient } from './Telemetry';
import * as FileSaver from 'file-saver';
import { Intl } from '../../App/Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { ErrorInfo } from '../../App/Models/ErrorInfo';
import { UUID } from 'angular2-uuid';
import { getWindowLocationHref, getCookieValue } from './BrowserGlobals';

let telemetry = TelemetryClient;

const correlationIdHeader: string = 'x-stp-externalcorrelationid';

class CustomHeaders {
    Authorization: string;
    SelectedCulture: string;
    'x-stp-externalcorrelationid': string;
}

export interface BasicClientInterface {
    doRequest_New: (        
        method: string, 
        urlOrApi: string, 
        // tslint:disable-next-line:no-any
        data: any, 
        beforeRequest: () => void, 
        onRequestSuccess: (response: AxiosResponse) => void, 
        onRequestFailed: (error: ErrorResponse) => void, 
        statusMessageId?: string, onRequestCancelled?: () => void, 
        customRequestTimeOut?: number,
        supressClientErrorPopup?: boolean,
        suppressClientErroPopupIfResponseCodes?: number[]) => void;
}

class ServiceTrustWebApi implements BasicClientInterface {
    apiErrorMessage: string;
    serviceUnreachableMessage: string;

    constructor() {
        this.apiErrorMessage = Intl.Get(LocIds.ServiceTrustClient.ApiErrorMessage);
        this.serviceUnreachableMessage = Intl.Get(LocIds.ServiceTrustClient.ServiceUnReachableMessage);
    }

    getWebApiEndpointUrl(): string {
        return store.getState().environmentSettings.webApiEndpointUrl;
    }

    getAxiosRequestTimeOut(): number {
        var axiosReqTimeout = store.getState().environmentSettings.axiosRequestTimeoutInMilliSeconds;
        return axiosReqTimeout;
    }

    doCancelRequest(source: CancelTokenSource, message?: string): void {
        if (source) {
            source.cancel(message);
        }
    }

    // Takes a response object and parses refreshed lite Jwt token out of headers. If found then token cache gets updated
    jwtTokenWasUpdated(response?: AxiosResponse): boolean {
        try {
            if (response && response.headers) {
                // tslint:disable-next-line:no-string-literal
                var refreshHeaderValue = response.headers['x-stp-jwtliterefresh'];
                if (refreshHeaderValue) {
                    store.getState().authentication.context.setAuthenticationProperties(refreshHeaderValue);
                    return true;
                }
            }
        } catch (errorRefresh) {
            // Ignore. No headers and/or no refresh token.            
        }
        return false;
    }
    // Takes a response object and parses stpstaletoken header. Returns true in case a token is stale.
    checkIfTokenIsStale(response?: AxiosResponse): boolean {
        try {
            if (response && response.headers) {
                var refreshHeaderValue = response.headers.stpstaletoken;
                if (refreshHeaderValue) {
                    return (refreshHeaderValue === 'jwtlite' || refreshHeaderValue === 'accesstoken');
                }
            }
        } catch (errorRefresh) {
            // Ignore. No headers and/or no refresh token.            
        }
        return false;
    }

    checkIfUserSessionIsAlive(response?: AxiosResponse): boolean {
        // If we received new jwt lite the continue normally
        if (!this.jwtTokenWasUpdated(response)) {
            // Check if jwt token is stale.
            if (this.checkIfTokenIsStale(response)) {
                // Jwt token is stale. We must force the logout
                let context = store.getState().authentication.context;
                if (context && context.user && context.user.authData) {
                    context.clearAuthUser();
                    return false;
                }
            }
        }
        return true;
    }

    doRequest_New(
        method: string = 'post',
        urlOrApi: string,
        // tslint:disable-next-line:no-any
        data: any,
        beforeRequest: () => void,
        onRequestSuccess: (response: AxiosResponse) => void,
        onRequestFailed: (error: ErrorResponse) => void,
        statusMessageId: string = StatusMessageReducer.COMMON_STATUS_NAME,
        onRequestCancelled?: () => void,
        customRequestTimeOut?: number,
        supressClientErrorPopup?: boolean,
        suppressClientErroPopupIfResponseCodes?: number[]
    ): CancelTokenSource {
        const canDisplayErrorDialog = supressClientErrorPopup === undefined || !supressClientErrorPopup;    
        const source: CancelTokenSource = axios.CancelToken.source();
        if (beforeRequest != null) {
            beforeRequest();
        }
        let urlToUse: string = urlOrApi.startsWith('/api/', 0) ? this.getWebApiEndpointUrl().concat(urlOrApi) : urlOrApi;
        let authorizationToken: string = store.getState().authentication.idToken;

        // Only add the Authorization header if there is actually an authorization token.
        let headersToUse = new CustomHeaders();
        // Add authorization header that contains jwt lite
        if (authorizationToken) {
            headersToUse.Authorization = authorizationToken;
        }
        // Fetch the 'lan' from the cookie, if 'lan' exist, send that in the header        
        let lan = getCookieValue('lan');
        // Start Measure Time here.
        let startTime = Date.now();
        if (lan) {
            headersToUse.SelectedCulture = lan;
        }

        let uuid = UUID.UUID();
        headersToUse[correlationIdHeader] = uuid.toString();

        axios({
            method: method,
            url: urlToUse,
            headers: headersToUse,
            data: data,
            cancelToken: source.token,
            timeout: customRequestTimeOut ? customRequestTimeOut : this.getAxiosRequestTimeOut()
        }).then((response: AxiosResponse) => {
            if (!this.checkIfUserSessionIsAlive(response)) {
                telemetry.trackEvent('WebApiRequestTelemetry-Ignore-OnRequestSuccess', {
                    WebApiUrl: urlToUse,
                    WebApiMethod: method,
                    WebUIUrl: getWindowLocationHref(),
                });
                return;
            }
            // Track now just after axios Returned. So we dont include the dispatch/state metric and any exception times in such dispatch.
            // Axios is a Promise Call. End Measure Time here.
            let endTime = Date.now();

            telemetry.trackEvent(
                'WebApiRequestTelemetry',
                {
                    WebApiUrl: urlToUse,
                    WebApiMethod: method,
                    WebUIUrl: getWindowLocationHref(),
                    ResponseServiceRegion: response.headers['x-stp-serviceregion'],
                    ResponseTelemetryOperationId: response.headers['x-stp-telemetryoperationid'],
                    ResponseTelemetryCorrelationId: response.headers[correlationIdHeader]
                },
                {
                    'totalMilliseconds': (endTime - startTime)
                }
            );

            try {
                store.dispatch(StatusMessageReducer.getClearAction(statusMessageId));
                onRequestSuccess(response);
            } catch (error) {
                telemetry.trackException(error, 'WebApiThen');
            }
        }).catch((error: AxiosError) => {
            let endTime = Date.now();
            let regionId = (error.response && error.response.headers) ? error.response.headers['x-stp-serviceregion'] : '';
            let operationId = (error.response && error.response.headers) ? error.response.headers['x-stp-telemetryoperationid'] : '';
            let correlationId = (error.config && error.config.headers) ? error.config.headers[correlationIdHeader] : '';

            if (!this.checkIfUserSessionIsAlive(error.response)) {
                telemetry.trackException(error, 'WebApiRequestFailure-NoUserSession', {
                    WebApiUrl: urlToUse,
                    WebApiMethod: method,
                    WebUIUrl: getWindowLocationHref(),
                    ResponseServiceRegion: regionId,
                    ResponseTelemetryOperationId: operationId,
                    ResponseTelemetryCorrelationId: correlationId
                });
                return;
            }
            if (axios.isCancel(error)) {
                telemetry.trackException(error, 'WebApiRequestFailure-IsCancel', {
                    WebApiUrl: urlToUse,
                    WebApiMethod: method,
                    WebUIUrl: getWindowLocationHref(),
                    ResponseServiceRegion: regionId,
                    ResponseTelemetryOperationId: operationId,
                    ResponseTelemetryCorrelationId: correlationId
                });
                if (onRequestCancelled) {                    
                    onRequestCancelled();
                }
            } else {
                telemetry.trackException(error, 'WebApiRequestFailure', {
                    WebApiUrl: urlToUse,
                    WebApiMethod: method,
                    WebUIUrl: getWindowLocationHref(),
                    ResponseServiceRegion: regionId,
                    ResponseTelemetryOperationId: operationId,
                    ResponseTelemetryCorrelationId: correlationId
                });

                if (error.response && error.response.data) {
                    if (canDisplayErrorDialog) {
                        if (error.response.data.HttpStatusCode === 412 || error.response.status === 412) {                             
                            store.dispatch(StatusMessageReducer.getErrorOccurredWithCustomMessageAction(error.response.data.Message));
                        } else
                        if (error.response.data.HttpStatusCode === 400 || error.response.status === 400) {
                            store.dispatch(StatusMessageReducer.getCustomExceptionOccurredAction(error.response.data.Message, statusMessageId));
                        } else {
                            store.dispatch(StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, statusMessageId));
                        }
                    }
                } else if (error.request) {
                    if (canDisplayErrorDialog) {
                        store.dispatch(StatusMessageReducer.getErrorOccurredAction(this.serviceUnreachableMessage, statusMessageId));
                    } else {
                        telemetry.trackEvent('WebApiRequestTelemetry-supressClientErrorPopup', {
                                WebApiUrl: urlToUse,
                                WebApiMethod: method,
                                WebUIUrl: getWindowLocationHref(),
                            }
                        );
                    } 
                } else if (canDisplayErrorDialog) {
                    store.dispatch(StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, statusMessageId));
                }

                var canDisplayErrorDialogIfCodes = (error.response && error.response.data) 
                    && (suppressClientErroPopupIfResponseCodes === undefined || suppressClientErroPopupIfResponseCodes.length === 0 
                        || (suppressClientErroPopupIfResponseCodes.findIndex(x => x === error.response.status) === -1 
                            && suppressClientErroPopupIfResponseCodes.findIndex(x => x === error.response.data.HttpStatusCode) === -1));

                let errorInfo: ErrorInfo = new ErrorInfo();
                errorInfo.elapsedTime = (endTime - startTime);
                errorInfo.operationId = operationId;
                errorInfo.regionId = regionId;
                errorInfo.requestType = method;
                errorInfo.requestUrl = urlToUse;
                errorInfo.statusCode = error.response ? error.response.data ? error.response.data.HttpStatusCode : '' : '';
                errorInfo.whenStarted = (new Date(startTime)).toString();
                errorInfo.correlationId = correlationId;
                errorInfo.message = error.response && error.response.data ? error.response.data.Message : ''                

                if (canDisplayErrorDialog && canDisplayErrorDialogIfCodes) {                  
                    store.dispatch(StatusMessageReducer.getShowErrorAction(errorInfo));                    
                }

                onRequestFailed({
                    code: error.response ? error.response.status : 0,
                    detailedErrorMessages: error.response && error.response.data ? error.response.data : undefined,
                    message: error.response && error.response.data ? error.response.data.message : '',
                    request: error.request,
                    response: error.response,
                    status: error.response ? error.response.statusText : '',
                });
            }
        });

        return source;
    }

    downloadFileWithFilter(
        api: string,
        fileName: string,
        // tslint:disable-next-line:no-any
        data: any
    ) {
        var url = this.getWebApiEndpointUrl().concat(api);
        axios.request({
            responseType: 'arraybuffer',
            url: url,
            method: 'post',
            headers: {
                'Authorization': store.getState().authentication.idToken,
            },
            data: data,
        }).then((result: AxiosResponse) => {
            this.saveFile(result, fileName);
            return;
        }).catch((error: AxiosError) => {
            if (error.response && error.response.data) {
                if (error.response.data.HttpStatusCode === 404 || error.response.status === 404) {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(Intl.Get(LocIds.ServiceTrustClient.ResourceNotFound), 'error_ComplianceManager')
                    );
                } else {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, '_common')
                    );
                }
            }
        });
    }

    downloadFileWithFilterV2(
        api: string,
        fileName: string,
        data: {}) {
        var url = this.getWebApiEndpointUrl().concat(api);
        axios.request({
            responseType: 'arraybuffer',
            url: url,
            method: 'post',
            headers: {
                'Authorization': store.getState().authentication.idToken,
            },
            data: data,
        }).then((result: AxiosResponse) => {
            this.saveFile(result, fileName);
            return;
        }).catch((error: AxiosError) => {
            if (error.response && error.response.data) {
                if (error.response.data.HttpStatusCode === 404 || error.response.status === 404) {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(Intl.Get(LocIds.ServiceTrustClient.ResourceNotFound), 'error_ComplianceManager')
                    );
                } else {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, '_common')
                    );
                }
            }
        });
    }

    downloadFileWithFilterV3(
        api: string,
        fileName: string,
        tileId: string
    ): AxiosPromise {
        var url = this.getWebApiEndpointUrl().concat(api + '/' + tileId);
        return axios.request({
            responseType: 'arraybuffer',
            url: url,
            method: 'post',
            headers: {
                'Authorization': store.getState().authentication.idToken,
            }
        });        
    }

    downloadUserGDPRPrivacyData(
        api: string,
        fileName: string,
        data: {},
        callback: () => void) {
        var url = this.getWebApiEndpointUrl().concat(api);
        axios.request({
            responseType: 'arraybuffer',
            url: url,
            method: 'post',
            headers: {
                'Authorization': store.getState().authentication.idToken,
            },
            data: data,
        }).then((result: AxiosResponse) => {
            if (result.status === 204) {
                alert('No assigned control action items or uploaded documents for this user.');
                callback();
                return;
            }
            this.saveFile(result, fileName);
            callback();
            return;
        }).catch((error: AxiosError) => {
            if (error.response && error.response.data) {
                if (error.response.data.HttpStatusCode === 404 || error.response.status === 404) {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(Intl.Get(LocIds.ServiceTrustClient.ResourceNotFound), 'error_ComplianceManager')
                    );
                } else {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, '_common')
                    );
                }
            }
            callback();
        });
    }

    downloadFile(
        api: string,
        fileName: string) {
        var url = this.getWebApiEndpointUrl().concat(api);
        axios.request({
            responseType: 'arraybuffer',
            url: url,
            method: 'post',
            headers: {
                'Authorization': store.getState().authentication.idToken,
            },
        }).then((result: AxiosResponse) => {
            this.saveFile(result, fileName);
            return;
        }).catch((error: AxiosError) => {
            if (error.response && error.response.data) {
                if (error.response.data.HttpStatusCode === 404 || error.response.status === 404) {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(Intl.Get(LocIds.ServiceTrustClient.ResourceNotFound), 'error_ComplianceManager')
                    );
                } else {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, '_common')
                    );
                }
            }
        });
    }

    downloadEvidence(
        api: string,
        fileName: string,
        controlFamilyId: string,
        controlId: string) {
        var url = this.getWebApiEndpointUrl().concat(api + '/' + controlFamilyId + '/' + controlId);
        axios.request({
            responseType: 'arraybuffer',
            url: url,
            method: 'get',
            headers: {
                'Authorization': store.getState().authentication.idToken,
            },
        }).then((result: AxiosResponse) => {
            this.saveFile(result, fileName);
            return;
        }).catch((error: AxiosError) => {
            if (error.response && error.response.data) {
                if (error.response.data.HttpStatusCode === 404 || error.response.status === 404) {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(Intl.Get(LocIds.ServiceTrustClient.ResourceNotFound), 'error_ComplianceManager')
                    );
                } else {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, '_common')
                    );
                }
            }
        });
    }

    downloadEvidenceV2(
        api: string,
        fileName: string) {
        var url = this.getWebApiEndpointUrl().concat(api);

        axios.request({
            responseType: 'arraybuffer',
            url: url,
            method: 'get',
            headers: {
                'Authorization': store.getState().authentication.idToken,
            },
        }).then((result: AxiosResponse) => {
            this.saveFile(result, fileName);
            return;
        }).catch((error: AxiosError) => {
            if (error.response && error.response.data) {
                if (error.response.data.HttpStatusCode === 404 || error.response.status === 404) {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(Intl.Get(LocIds.ServiceTrustClient.ResourceNotFound), 'error_ComplianceManager')
                    );
                } else {
                    store.dispatch(
                        StatusMessageReducer.getErrorOccurredAction(this.apiErrorMessage, '_common')
                    );
                }
            }
        });
    }

    saveFile(response: AxiosResponse, fileName: string) {
        var blob = new Blob([response.data], { type: 'application/octet-stream;charset=utf-8' });
        FileSaver.saveAs(blob, fileName);
    }
}

export let ServiceTrustClient = new ServiceTrustWebApi();