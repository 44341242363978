import * as React from 'react';
import './ExportData.css';
import { Link, Redirect } from 'react-router-dom';
import { PrimaryButton, Spinner, SpinnerSize, IDropdownOption } from 'office-ui-fabric-react';
import PeoplePickerCompact from '../../../Common/Components/PeoplePickerCompact';
import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { AzureSubscription, AzureStorageAccount } from '../../Models/PrivacyPortal';
import { PrivacyPortalClient } from '../../Services/PrivacyPortalService';
import { store } from '../../../Store';
import * as PrivacyPortalReducer from '../../Reducers/PrivacyPortal';
import { ExportPersonalDataResponse, GetAzureSubscriptionsResponse, GetAzureStorageAccountsResponse } from '../../Services/Models/PrivacyPortal/index';
import { ErrorResponse } from '../../Services/Models';
import { UserPersona } from '../../Models/ComplianceManagerV2';
import { ExportPersonalDataRequest } from '../../Services/Models/PrivacyPortal/ExportPersonalDataRequest';
import { Dropdown } from 'office-ui-fabric-react/lib/components/Dropdown';
import * as PrivacyManagerReducer from '../../Reducers/PrivacyPortal/PrivacyManager';
import { AuthorizationInfo, FlightMembership } from '../../../Common/Utilities/AuthorizationInfo';
import { CloudInstance } from '../../../Common/Utilities/ApplicationContext';
import { getAzureResourcesCloudInstance } from '../../../Common/Utilities/ApplicationContextHelper';

let api = PrivacyPortalClient;

export interface ExportDataRequestPageProps {
    azureSubscriptions: AzureSubscription[];
    azureStorageAccounts: AzureStorageAccount[];
}

export interface ExportDataRequestPageState {
    selectedUser: UserPersona | null;
    exportResponse: ExportPersonalDataResponse | null;
    sasUri: string | null;
    loading: boolean;
    redirectToDashboard: boolean;
    selectedSubscription: DropDownDetail;
    selectedStorageAccount: DropDownDetail | undefined;
    showNotice: boolean;
    showNoSubscriptionError: boolean;
    exportDataRequestFlightEnabled: boolean;
    showFeatureNotice: boolean;
    isBlackforestInstance: boolean;
}

export class DropDownDetail {
    key: string;
    text: string;
}

export default class ExportDataRequestPage extends React.Component<ExportDataRequestPageProps, ExportDataRequestPageState> {
    private _picker: PeoplePickerCompact;

    constructor(props: ExportDataRequestPageProps) {
        super(props);
        this.onSubscriptionChanged = this.onSubscriptionChanged.bind(this);
        this.onStorageAccountChanged = this.onStorageAccountChanged.bind(this);
        this.state = {
            selectedUser: null,
            exportResponse: null,
            sasUri: null,
            loading: false,
            redirectToDashboard: false,
            selectedSubscription: new DropDownDetail(),
            selectedStorageAccount: new DropDownDetail(),
            showNotice: true,
            showNoSubscriptionError: false,
            exportDataRequestFlightEnabled: false,
            showFeatureNotice: true,
            isBlackforestInstance: true
        };
    }

    getAzureSubscriptions(): void {
        api.GetAzureSubscriptions(
            {},
            () => {
                store.dispatch(PrivacyPortalReducer.getAzureSubscriptionRequestingAction());
            },
            (response: GetAzureSubscriptionsResponse) => {
                this.setState({ showNoSubscriptionError: response.azureSubscriptions.length === 0 });
                store.dispatch(PrivacyPortalReducer.getAzureSubscriptionRequestSucceedAction('', response.azureSubscriptions));
            },
            (failed: ErrorResponse) => {
                let message: string = failed.code + ' ' + failed.status + ' - ' + failed.message;
                store.dispatch(PrivacyPortalReducer.getAzureSubscriptionRequestErrorAction(message));
            });
    }

    getAzureStorageAccounts(subscriptionId: string): void {
        api.GetAzureStorageAccounts(
            subscriptionId,
            () => {
                store.dispatch(PrivacyPortalReducer.getAzureStorageAccountRequestingAction());
            },
            (response: GetAzureStorageAccountsResponse) => {
                store.dispatch(PrivacyPortalReducer.getAzureStorageAccountRequestSucceedAction('', response.azureStorageAccounts));
            },
            (failed: ErrorResponse) => {
                let message: string = failed.code + ' ' + failed.status + ' - ' + failed.message;
                store.dispatch(PrivacyPortalReducer.getAzureStorageAccountRequestErrorAction(message));
            });
    }

    public componentDidMount(): void {
        this.setState({
            exportDataRequestFlightEnabled:
                AuthorizationInfo.isIncludedInRequiredFlights(['PrivacyPortalExportPersonalData'], FlightMembership.Any)
        });
        this.getAzureSubscriptions();
        this.setState({isBlackforestInstance: getAzureResourcesCloudInstance() === CloudInstance.BlackForest});
    }

    exportButtonClicked(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        if (this.state.selectedUser != null && 
            this.state.selectedSubscription.key != null && 
            (this.state.selectedStorageAccount && 
            this.state.selectedStorageAccount.key) != null) {
            let storageAccountName = this.state.selectedStorageAccount && this.state.selectedStorageAccount.text || '';
            let resourceGroup = this.state.selectedStorageAccount && this.props.azureStorageAccounts.filter(item => item.name === storageAccountName)[0].storageId || '';
            let exportReq: ExportPersonalDataRequest = {
                userPrincipalName: this.state.selectedUser.userPrincipalName,
                id: this.state.selectedUser.id,
                sasUri: this.state.sasUri || '',
                subscriptionId: this.state.selectedSubscription.key,
                storageAccountName: storageAccountName,
                resourceGroup: resourceGroup,
                containerName: ''
            };
            api.ExportPersonalData(
                exportReq,
                () => {
                    this.setState({ loading: true });
                },
                // tslint:disable-next-line:no-empty
                (response: ExportPersonalDataResponse) => {
                    this.setState({ loading: false, exportResponse: response, redirectToDashboard: !response.error });
                    if (!response.error) {
                        store.dispatch(PrivacyManagerReducer.getExportDataRequestSuccessAction(Intl.Get(LocIds.GDPRCompliance.CreateExportDataCreateDataRequestSuccess)));
                    }
                },
                (failed: ErrorResponse) => {
                    this.setState({ loading: false });
                    this.setState({ exportResponse: { error: true, errorCode: failed.message, exportId: '' } as ExportPersonalDataResponse });
                }
            );
        }
    }

    onPersonaSelectionChange(v: UserPersona | null) {
        this.setState({ selectedUser: v });
    }

    getErrorMessage(): string {
        if (this.state.exportResponse && this.state.exportResponse.error) {
            switch (this.state.exportResponse.errorCode) {
                case 'InvalidUrl':
                    return Intl.Get(LocIds.GDPRCompliance.CreateExportDataInvalidSASUri);
                case 'InvalidStartDate':
                    return Intl.Get(LocIds.GDPRCompliance.CreateExportDataInvalidStartDate);
                case 'InvalidEndDate':
                    return Intl.Get(LocIds.GDPRCompliance.CreateExportDataInvalidEndDate);
                case 'InvalidDates':
                    return Intl.Get(LocIds.GDPRCompliance.CreateExportDataInvalidDates);
                case 'ContainsReadAccess':
                    return Intl.Get(LocIds.GDPRCompliance.StorageUriContainsReadAccess);
                case 'ContainsListAccess':
                    return Intl.Get(LocIds.GDPRCompliance.StorageUriContainsListAccess);
                case 'NoWriteAccess':
                    return Intl.Get(LocIds.GDPRCompliance.StorageUriContainsNoWriteAccess);
                case 'UsedContainer':
                    return Intl.Get(LocIds.GDPRCompliance.UsedStorageContainer);
                case 'NoSASCredentials':
                    return Intl.Get(LocIds.GDPRCompliance.StorageUriContainsNoSASCredentials);
                default: {
                    return Intl.Get(LocIds.GDPRCompliance.CreateExportDataUnknownErrorOccured);
                }
            }
        }
        return '';
    }

    onSubscriptionChanged(option: IDropdownOption) {
        let selectedSubscription: DropDownDetail = new DropDownDetail();
        selectedSubscription.key = option.key.toString();
        selectedSubscription.text = option.text;
        this.setState({ selectedSubscription: selectedSubscription, selectedStorageAccount: undefined });
        this.getAzureStorageAccounts(option.key.toString());
    }
    
    onStorageAccountChanged(option: IDropdownOption) {
        let selectedStorageAccount: DropDownDetail = new DropDownDetail();
        selectedStorageAccount.key = option.key.toString();
        selectedStorageAccount.text = option.text;
        this.setState({ selectedStorageAccount: selectedStorageAccount });
    }
    
    onDismiss() {
        this.setState({ showNotice: false });
    }

    render() {
        if (this.state.redirectToDashboard) {
            return (
                <Redirect to={'/privacy/DataLogExport'} />
            );
        }

        let dataLogExportSubtitle: string = Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestExportForSubTitle);
        let parsedDataLogExportSubtitle = dataLogExportSubtitle.split('**');
        let noSubscriptionErrorMessage: string = Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestNoSubscriptionErrorLabel);
        let parsedNoSubscriptionErrorMessage = noSubscriptionErrorMessage.split('**');
        
        return (
            <div style={{ marginTop: '10px' }} >
                {this.state.loading && <Spinner size={SpinnerSize.large} className="loading-panel-full" label={Intl.Get(LocIds.Spinner.LoadingLabel)} ariaLive="assertive" />}
                {this.state.showNotice &&
                    <div className="m-alert f-error" role="alert">
                        <button className="c-action-trigger c-glyph glyph-cancel" aria-label="Close alert" onClick={() => this.onDismiss()} />
                        <div>
                            <p className="c-paragraph">
                                <div data-grid="col-10">
                                {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestNotificationLabel)}
                                </div>
                                <span className="c-group">
                                    <a className="c-action-trigger" href="https://aka.ms/complianceframework/download">
                                        {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestNotificationLinkLabel)}
                                    </a>
                                </span>
                            </p>
                        </div>
                    </div>
                }
                <div data-grid="col-12" style={{ paddingTop: 30 }}>
                    <Link to={'/privacy/DataLogExport'}>
                        <a className="c-hyperlink privacy-portal-label" data-grid="col-12"> 
                            <i style={{ fontSize: 10 }} className="ms-Icon ms-Icon--ChevronLeft" aria-hidden="true" /> 
                            {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestBackToDashboard)}
                        </a>
                    </Link>
                </div>
                <div data-grid="col-12">
                    <h1 className="c-heading-1" data-grid="col-9"> {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestTitle)}</h1>
                </div>
                <div data-grid="col-12" style={{ paddingTop: 30 }}>
                    <img
                        className="export-data-request-brand-icons"
                        src="/Images/PrivacyPortal/ExportDataRequest/Exchange.jpg"
                        alt="exchangeBrand icon"
                    />
                    <img
                        className="export-data-request-brand-icons"
                        src="/Images/PrivacyPortal/ExportDataRequest/Sharepoint.jpg"
                        alt="sharepointBrand icon"
                    />
                    {!this.state.isBlackforestInstance && <img
                        className="export-data-request-brand-icons"
                        src="/Images/PrivacyPortal/ExportDataRequest/MicrosoftTeams.jpg"
                        alt="teamsBrand icon"
                    />}
                    <img
                        className="export-data-request-brand-icons"
                        src="/Images/PrivacyPortal/ExportDataRequest/SkypeForBus.jpg"
                        alt="skypeBrand icon"
                    />
                    <img
                        className="export-data-request-brand-icons"
                        src="/Images/PrivacyPortal/ExportDataRequest/Mail.jpg"
                        alt="mailBrand icon"
                    />                    
                    {!this.state.isBlackforestInstance && <img
                        className="export-data-request-brand-icons"
                        src="/Images/PrivacyPortal/ExportDataRequest/Todo.jpg"
                        alt="todoBrand icon"
                    />}
                    <img
                        className="export-data-request-brand-icons"
                        src="/Images/PrivacyPortal/ExportDataRequest/OneDrive.jpg"
                        alt="oneDriveBrand icon"
                    />
                </div>
                <div data-grid="col-12">
                    <header className="c-heading-5 f-heavyweight" data-grid="col-6">
                        <h4 className="c-heading">{Intl.Get(this.state.isBlackforestInstance ? LocIds.GDPRCompliance.CreateExportDataRequestSubTitleForBlackforestCloudInstance : LocIds.GDPRCompliance.CreateExportDataRequestSubTitle)}</h4>
                    </header>
                </div>
                <div data-grid="col-12">
                    <p className="c-paragraph-3 privacy-portal-label" data-grid="col-6">
                        {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestTitleDescription)}
                    </p>
                </div>
                <div data-grid="col-12" style={{ paddingTop: 30 }}>
                    <a
                        href="https://aka.ms/STPSecurityAndCompliance"
                        className="c-hyperlink privacy-portal-label"
                        target="_blank"
                        style={{ 'textTransform': 'uppercase' }}
                    >
                        {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestCTAPortalLinkText)} <span className="c-action-trigger c-glyph glyph-chevron-right" />
                    </a>

                </div>
                <hr className="c-divider f-pad-vertical-6x" data-grid="col-12" />
                {this.state.showFeatureNotice &&
                    <div className="m-alert f-error" role="alert" data-grid="col-12">
                        <button className="c-action-trigger c-glyph glyph-cancel" aria-label="Close alert" onClick={() => this.setState({ showFeatureNotice: false })} />
                        <div>
                            <p className="c-paragraph">
                                {Intl.Get(LocIds.GDPRCompliance.FeatureNotificationLabel)}
                            </p>
                        </div>
                    </div>
                }
                <div data-grid="col-12">
                    <header className="c-heading-5 f-heavyweight" data-grid="col-12">
                        <h4 className="c-heading">{Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestExportForHeading)}</h4>
                    </header>
                </div>
                {!this.state.exportDataRequestFlightEnabled &&
                    <div data-grid="col-12">
                        <div className="m-alert f-warning" role="alert" >
                            <div>
                                <p className="c-paragraph">
                                    <div data-grid="col-10">
                                        {Intl.Get(LocIds.GDPRCompliance.ExportDataNotEnabledLabel)}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                }
                <div data-grid="col-12">
                    <p className="c-paragraph-3 privacy-portal-label" data-grid="col-6">
                        {parsedDataLogExportSubtitle[0]}
                        <a href="https://aka.ms/STPAzureHelp" style={{ color: '#106ebe', textDecoration: 'underline' }} target="_blank">{parsedDataLogExportSubtitle[1]}</a>
                        {parsedDataLogExportSubtitle[2]}
                    </p>
                </div>
                <div data-grid="col-12" style={{ paddingTop: 30 }}>
                    <div data-grid="col-2">
                        {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestExportForLabel)}
                    </div>
                    <div data-grid="col-4">
                        <PeoplePickerCompact
                            ref={(o: PeoplePickerCompact) => this._picker = o}
                            className="peoplePicker"
                            onChange={(v: UserPersona | null) => this.onPersonaSelectionChange(v)}
                        />
                    </div>
                </div>

                <div data-grid="col-12" style={{ paddingTop: 20 }}>
                    <div data-grid="col-2">
                        <div data-grid="col-12">
                            {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestAzureSubscriptionLabel)}
                        </div>
                    </div>
                    <div data-grid="col-4 pad-2x">
                        <div>
                            <Dropdown
                                selectedKey={this.state.selectedSubscription.key}
                                placeHolder={Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestSelectSubscriptionLabel)}
                                options={this.props.azureSubscriptions.map(item => ({ key: item.subscriptionId, text: item.name }))}
                                onChanged={(option) => { this.onSubscriptionChanged(option); }}
                            />
                        </div>
                    </div>
                </div>
                {(this.state.showNoSubscriptionError) &&
                    <div className="m-alert f-error" role="alert" data-grid="col-12">
                        <button className="c-action-trigger c-glyph glyph-cancel" aria-label="Close alert" onClick={() => { this.setState({showNoSubscriptionError: false}); }} />
                        <div>
                            <div className="c-glyph glyph-info" aria-label="Information message" />
                            <p className="c-paragraph" style={{ display: 'inline' }}>
                                {parsedNoSubscriptionErrorMessage[0]}
                                <a href="https://aka.ms/STPAzureHelp" style={{ color: '#106ebe', textDecoration: 'underline' }} target="_blank">{parsedNoSubscriptionErrorMessage[1]}</a>
                                {parsedNoSubscriptionErrorMessage[2]}                            
                            </p>
                        </div>
                    </div>}                
                <div data-grid="col-12" style={{ paddingTop: 20 }}>
                    <div data-grid="col-2">
                        <div data-grid="col-12">
                            {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestAzureStorageAccountLabel)}
                        </div>
                        <div data-grid="col-12">
                            <a href="https://aka.ms/stpprivacyazurestoragehelp" style={{ color: '#106ebe', textDecoration: 'underline' }} target="_blank">
                                <i className="ms-Icon ms-Icon--Info" aria-hidden="true" style={{ fontSize: 12 }} />
                                <span>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridHelpLink)}</span>
                            </a>
                        </div>
                    </div>
                    <div data-grid="col-4 pad-2x">
                        <div>
                            <Dropdown
                                selectedKey={this.state.selectedStorageAccount && this.state.selectedStorageAccount.key || ''}
                                placeHolder={Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestSelectStorageAccountLabel)}
                                options={this.props.azureStorageAccounts.map(item => ({ key: item.name, text: item.name }))}
                                onChanged={(option) => { this.onStorageAccountChanged(option); }}
                            />
                        </div>
                    </div>
                    <div data-grid="col-5 pad-2x" style={{ paddingLeft: 10 }}>
                        <div data-grid="col-12">
                            <div data-grid="col-5" >
                                <PrimaryButton
                                    className="create-export-request-btn"
                                    disabled={!this.state.exportDataRequestFlightEnabled 
                                        || this.state.selectedUser === null 
                                        || this.state.selectedSubscription.key === undefined 
                                        || (this.state.selectedStorageAccount === undefined) 
                                        || this.state.loading}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.exportButtonClicked(e)}
                                >
                                    {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestExportButton)}
                                    <span className="c-action-trigger c-glyph glyph-chevron-right" />
                                </PrimaryButton>
                            </div>
                            <div data-grid="col-2" style={{ paddingTop: 25 }}>
                                <a href="https://aka.ms/stpfaq" style={{ color: '#106ebe', textDecoration: 'underline' }} target="_blank">
                                    <span>{Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestExportFAQLabel)}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-grid="col-12">
                    <p className="c-paragraph-3" style={{ fontStyle: 'italic', fontSize: '12px' }} data-grid="col-8">
                        {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestExportButtonDescription)}
                    </p>
                </div>                
                {(this.state.exportResponse && this.state.exportResponse.error) &&
                    <div className="m-alert f-warning" role="alert" data-grid="col-12">
                        <button className="c-action-trigger c-glyph glyph-cancel" aria-label="Close alert" onClick={() => { this.setState({ exportResponse: null }); }} />
                        <div>
                            <div className="c-glyph glyph-info" aria-label="Information message" />
                            <p className="c-paragraph">{this.getErrorMessage()}</p>
                        </div>
                    </div>}
                <hr className="c-divider f-pad-vertical-6x" data-grid="col-12" />

                <div data-grid="col-12">
                    <header className="c-heading-5 f-heavyweight" data-grid="col-12">
                        <h4 className="c-heading">{Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestAllServicesHeading)} </h4>
                    </header>
                </div>

                <div data-grid="col-12">
                    <p className="c-paragraph-3 privacy-portal-label" data-grid="col-6">
                        {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestAllServicesSummary)}
                    </p>
                </div>

                <div data-grid="col-12" style={{ paddingTop: 30 }}>
                    <Link
                        to="/ViewPage/AddLogExport"
                        className="c-hyperlink privacy-portal-label"
                    >
                        {Intl.Get(LocIds.GDPRCompliance.CreateExportDataRequestAllServicesLinkText)} <span className="c-action-trigger c-glyph glyph-chevron-right" />
                    </Link>

                </div>
            </div>
        );
    }
}