import { Reducer } from 'redux';
import { PageMetadata } from '../Models/PageInfrastructure';
import { Status } from '../Reducers/Status';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface PagesManagementState {
    status: Status;
    pagesMetadata: PageMetadata[];
}

// Define the strings that will be used when dispatching the actions that affect this model.
export const PAGES_FETCHED = 'PAGES_FETCHED';
export type PAGES_FETCHED = typeof PAGES_FETCHED;

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface PagesFetching {
    type: 'PAGES_FETCHING';
}

interface PagesFetched {
    type: PAGES_FETCHED;
    data: PageMetadata[];
}

interface PagesFetchingError {
    type: 'PAGES_FETCHING_ERROR';
    // tslint:disable-next-line:no-any
    data: any;
}

interface PagesCreating {
    type: 'PAGES_CREATING';
}

interface PagesDeleting {
    type: 'PAGES_DELETING';
}

interface PagesDeleted {
    type: 'PAGES_DELETED';
}

interface PagesDeletingError {
    type: 'PAGES_DELETING_ERROR';
}

interface PagesCreated {
    type: 'PAGES_CREATED';
}

interface PagesCreatingError {
    type: 'PAGES_CREATING_ERROR';
    // tslint:disable-next-line:no-any
    data: any;
}

export type PagesManagementActions = PagesFetching | PagesFetched | PagesFetchingError
    | PagesCreating | PagesCreated | PagesCreatingError
    | PagesDeleting | PagesDeleted | PagesDeletingError;

export function savePages(data: PageMetadata[]): PagesManagementActions {
    return {
        type: PAGES_FETCHED,
        data: data,
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PagesManagementState = {
    status: 'Init',
    pagesMetadata: []
};

export const reducer: Reducer<PagesManagementState> = (state: PagesManagementState, action: PagesManagementActions) => {
    switch (action.type) {
        case 'PAGES_FETCHING':
        case 'PAGES_CREATING':
        case 'PAGES_DELETING':            
            return {
                ...state,
                status: 'Requesting'
            };
        case PAGES_FETCHED:
            return {
                ...state,
                status: 'Finished',
                pagesMetadata: action.data
            };
        case 'PAGES_CREATED':
        case 'PAGES_DELETED':
            return {
                ...state,
                status: 'Success'
            };
        case 'PAGES_FETCHING_ERROR':
        case 'PAGES_CREATING_ERROR':
        case 'PAGES_DELETING_ERROR':
            return {
                ...state,
                status: 'Error'
            };          

        default:
    }

    return state || unloadedState;
};
