import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { HeroPanelPropertiesData, HeroPanelColumnPropertiesData } from '../../Models/PageInfrastructure';
import { Theme, TextPosition } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { deleteOnePanelFromCollection, moveSelectedColumn, getHeroColumn, getSimpleThemeOptions, getThemeClassName, getPositionOptions, getPositionClassName, getBackgroundMaskOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { IComboBox, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { ComboBox, IComboBoxOption, Checkbox } from 'office-ui-fabric-react';
import { enableCarousel } from '../../Utilities/MwfHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { Link } from 'react-router-dom';
import Constants from '../../Utilities/Constants';
import { ExternalNavigationService } from '../../Services/ExternalNavigationService';

const MAX_CONTENT_LENGTH = 205;

export class HeroPanel extends BasePanel<HeroPanelPropertiesData> {
    private static MAX_COLUMN = 9;
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private textPositionOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.TextPositionLabel), itemType: SelectableOptionMenuItemType.Header }];
    private backgroundMaskOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.BackgroundMaskLabel), itemType: SelectableOptionMenuItemType.Header }];
    private externalNavigation = new ExternalNavigationService();

    constructor(props: BasicPanelProperties<HeroPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions());
        this.textPositionOptions = this.textPositionOptions.concat(getPositionOptions());
        this.backgroundMaskOptions = this.backgroundMaskOptions.concat(getBackgroundMaskOptions());
        if (!this.props.panel.panelInfo.columns) {
            this.props.panel.panelInfo.columns = [];
        }
    }

    componentDidMount() {
        var hasPanel = this.state.panelData.columns && this.state.panelData.columns.length > 1;
        var canEnableCarousel = !this.props.isInEditMode;
        if (hasPanel && canEnableCarousel) {
            enableCarousel();
        }
    }

    componentDidUpdate(nextProps: BasicPanelProperties<HeroPanelPropertiesData>) {
        var hasPanel = this.state.panelData.columns && this.state.panelData.columns.length > 1;
        var canEnableCarousel = !this.props.isInEditMode;
        if (hasPanel && canEnableCarousel) {
            enableCarousel();
        }
    }

    getActualPanel() {
        // In the view mode, MWF will handle the slides change, so we don't need to handle it again for the previous or next button.
        // This is to avoid two slides from triggering active at the same time.
        return (
            <div className="m-hero" data-panel-type="Hero">
                {/* Statement below breaks the Hero panel to prevent it from scrolling if there are any validation errors while editing. 
                    This is to keep the panel and the details in-sync.*/}
                {this.state.showEditor && <div style={{display: 'none'}} />}
                <div
                    className={'c-carousel f-multi-slide f-scrollable-next f-scrollable-previous ' + (this.state.panelData.autoPlay ? 'f-auto-play' : '')}
                    role="region"
                    aria-label="View Hero Panel"
                    data-js-interval={(this.state.panelData.slideChangeIntervalInSeconds || 6) * 1000}
                >
                    {this.state.panelData.columns && this.state.panelData.columns.length > 1 &&
                        <div className="c-group">
                            <div className="c-sequence-indicator" role="tablist">
                                {this.state.panelData.columns.map((data, index) =>
                                    <button
                                        key={'button' + data.toString().concat(index.toString())}
                                        onClick={(e) => {
                                            if (this.state.showEditor) {
                                                e.preventDefault();
                                                if (!this.panelValidator.validate()) {
                                                    return;
                                                }
                                                this.setState({ selectedIndex: index });
                                            }
                                        }}
                                        role="tab"
                                        aria-label={'View heropanel' + index.toString()}
                                        aria-controls={'heropanel' + index.toString()}
                                        aria-selected={this.state.selectedIndex === index ? 'true' : 'false'}
                                    />)
                                }
                            </div>
                            {this.state.panelData.autoPlay && 
                                <button className="c-action-toggle c-glyph glyph-play f-toggle" data-toggled-label="Pause" data-toggled-glyph="glyph-pause" aria-label="Play" />
                            }
                        </div>
                    }
                    {this.state.panelData.columns && this.state.panelData.columns.length > 1 &&
                        <div>
                            <button
                                className="c-flipper f-previous"
                                aria-hidden="true"
                                tabIndex={-1}
                                onClick={(e) => {
                                    if (this.state.showEditor) {
                                        e.preventDefault();
                                        if (!this.panelValidator.validate()) {
                                            return;
                                        }
                                        if (this.state.selectedIndex > 0) {
                                            this.setState({ selectedIndex: this.state.selectedIndex - 1 });
                                        } else {
                                            this.setState({ selectedIndex: this.state.panelData.columns ? this.state.panelData.columns.length - 1 : 0 });
                                        }
                                    }
                                }}
                            />
                            <button
                                className="c-flipper f-next"
                                aria-hidden="true"
                                tabIndex={-1}
                                onClick={(e) => {
                                    if (this.state.showEditor) {
                                        e.preventDefault();
                                        if (!this.panelValidator.validate()) {
                                            return;
                                        }
                                        if (this.state.panelData.columns && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
                                            this.setState({ selectedIndex: this.state.selectedIndex + 1 });
                                        } else {
                                            this.setState({ selectedIndex: 0 });
                                        }
                                    }
                                }}
                            />
                        </div>
                    }
                    <div>
                        <ul role="none">
                            {this.state.panelData.columns &&
                                this.state.panelData.columns.map((data, index, allData) => this.renderSlide(data, index, allData.length))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    renderSlide(data: HeroPanelColumnPropertiesData, index: number, dataLength: number) {
        let heading: string = getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder);
        let subHeading: string = getLocalizedString(data.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder);
        let callToActionUrl = data.callToActionUrl ? data.callToActionUrl : '';
        let hasCallToActionButton = data.callToActionLabel && getLocalizedString(data.callToActionLabel) !== '' && data.callToActionUrl;
        let isExternalLink = /^https?:\/\//.test(callToActionUrl);
        if (dataLength === 1) {
            document.title = heading;
        }

        return (
            <li
                key={'panel' + data.toString().concat(index.toString())}
                id={'heropanel' + index.toString()}
                data-f-theme={getThemeClassName(Theme[data.theme || 'Light'])}
                className={this.state.selectedIndex === index ? 'f-active' : ''}
            >
                <section className={`${getPositionClassName(TextPosition[data.textPosition || 'LeftTop'])} m-hero-item context-accessory ${data.backgroundMask} ${getThemeClassName(Theme[data.theme || 'Light'])} hero-panel-section`} >
                    <picture>
                        <img
                            aria-hidden={true}
                            className="lazyloaded"
                            src={imageHelper.getImageUrl(data.imageId)}
                            data-src={imageHelper.getImageUrl(data.imageId)}
                            alt={heading}
                        />
                    </picture>
                    <div>
                        <div className={this.getContentClassName(heading, subHeading)}>
                            <h1 className="c-heading-2">{heading}</h1>
                            <p className="c-subheading-2">{subHeading}</p>
                            {hasCallToActionButton && 
                                <div>
                                    <Link
                                        to={callToActionUrl}
                                        className="c-call-to-action c-glyph"
                                        style={{ 'textTransform': 'uppercase' }}
                                        target={isExternalLink ? '_blank' : '_self'}
                                        onClick={
                                            () => {
                                                if (isExternalLink) {
                                                    this.externalNavigation.navigateTo({
                                                        url: data.callToActionUrl || ''
                                                    });
                                                }
                                            }
                                        }
                                    >
                                        <span>{getLocalizedString(data.callToActionLabel)}</span>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </li>       
        );
    }

    getContentClassName(heading: string, subheading: string) {
        // Calculate sum length of the heading and subheading with diffrent weight. 
        return heading.length * 3 + subheading.length > MAX_CONTENT_LENGTH ? 'hero-panel-richcontent' : '';
    }

    onAddColumn() {
        if (!this.state.panelData.columns || (this.state.panelData.columns.length >= HeroPanel.MAX_COLUMN) || !this.panelValidator.validate()) {
            return;
        }
        this.state.panelData.columns.push(getHeroColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    onDeleteColumn() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            var panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    onMoveLeft() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    onMoveRight() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: 0 }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor && this.state.panelData.columns ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.columns[this.state.selectedIndex].theme || 'Light'}
                                                text={this.state.panelData.columns[this.state.selectedIndex].theme || 'Light'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].theme = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.columns[this.state.selectedIndex].textPosition || 'LeftTop'}
                                                text={this.state.panelData.columns[this.state.selectedIndex].textPosition || 'LeftTop'}
                                                label={Intl.Get(LocIds.PageAndPanels.TextPositionLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.TextPositionLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.textPositionOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].textPosition = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.columns[this.state.selectedIndex].backgroundMask || 'None'}
                                                text={this.state.panelData.columns[this.state.selectedIndex].backgroundMask || 'None'}
                                                label={'Background Mask:'}
                                                ariaLabel={'Background Mask:'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.backgroundMaskOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].backgroundMask = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextField
                                                value={this.state.panelData.columns ? (this.state.panelData.columns[this.state.selectedIndex].imageTitle || '') : ''}
                                                disabled={true}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={images => {
                                                    if (images && images.length && this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                        this.state.panelData.columns[this.state.selectedIndex].imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? (getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading) || '') : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                        }
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                multiline={true}
                                                value={this.state.panelData.columns ? (getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].subHeading) || '') : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length > Constants.PANEL_DESCRIPTION_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX);
                                                        }
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'subHeading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextField
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel) || ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'callToActionLabel', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionLabel)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? (this.state.panelData.columns[this.state.selectedIndex].callToActionUrl || '') : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                validator={this.panelValidator}
                                                customValidator={(value) => {
                                                    if (this.state.panelData.columns && getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel)) {
                                                        return value === '' ? 'Required' : '';
                                                    }
                                                    return '';
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <Checkbox
                                                checked={this.state.panelData.autoPlay || false}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.state.panelData.autoPlay = isChecked;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.AutoPlayLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.AutoPlayLabel)}
                                            />
                                            <TextFieldWrapper
                                                value={(this.state.panelData.slideChangeIntervalInSeconds || 1).toString()}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.state.panelData.slideChangeIntervalInSeconds = parseInt(newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                disabled={!this.state.panelData.autoPlay}
                                                type="number"
                                                label={Intl.Get(LocIds.PageAndPanels.SlideChangeIntervalLabel)}
                                                validator={this.panelValidator}
                                                customValidator={(value) => {
                                                    if (this.state.panelData.autoPlay) {
                                                        return !this.state.panelData.slideChangeIntervalInSeconds ? Intl.Get(LocIds.PageAndPanels.RequiredError) : '';
                                                    }
                                                    return '';
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddPanelLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length >= HeroPanel.MAX_COLUMN)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeletePanelLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}