export enum EmailFrequency {
    Never = 0,
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Quarterly = 4,
    Yearly = 5,
    AsNeeded = 6
}

export interface NotificationSettings {
    emails: string[];
    interval: EmailFrequency; // 1- daily, 2- weekly, 3-monthly
    savedDocuments: string[];
    totalUpdatedCount: number;
    isNotificationOn: boolean;
}
