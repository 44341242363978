import * as React from 'react';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { getLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { MosaicPanelColumnPropertiesData, MosaicPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Intl } from '../../Services/GlobalizationService';
import { getMosaicTheme } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { renderLink } from '../../Utilities/RenderUtilities';

/**
 * 
 * @param data the complete column data object
 * @param mosaic "f-vp1-whole f-vp4-half f-height-large"
 */
function renderColumn(columnData: MosaicPanelColumnPropertiesData | undefined, mosaic: string, onClick: () => void, selected: boolean, showEditor: boolean, largeHeading: boolean = false) {
    const data = {
        backgroundColor: '',
        backgroundMask: '',
        heading: undefined,
        headingUrl: undefined,
        imageId: undefined,
        subHeading: undefined,
        theme: '',
        columnHeadingClass: largeHeading ? 'c-heading-3' : 'c-heading-5',
        ...columnData
    };
    const themeClass = getMosaicTheme(data.theme);
    const selectedStyles = (selected && showEditor) ? { border: '2px solid red' } : {};

    return (
        <div className={themeClass} data-f-mosaic={mosaic} style={selectedStyles} onClick={data.heading ? onClick : () => undefined}>
            {data.heading ?
                (
                    <section className={`c-mosaic-placement ${data.backgroundColor} ${data.backgroundMask} mosaicPanel__item`}>
                        <picture>
                            {data.imageId &&
                                <img
                                    aria-hidden={true}
                                    className="lazyloaded"
                                    src={imageHelper.getImageUrl(data.imageId)}
                                    data-src={imageHelper.getImageUrl(data.imageId)}
                                    alt={getLocalizedString(data.heading) || ''}
                                />
                            }
                        </picture>
                        <div>
                            <h3 className={`${data.columnHeadingClass} mosaicPanel__item-heading`}>
                                {(data.headingUrl !== '' && typeof data.headingUrl !== 'undefined') ?
                                    renderLink(data.headingUrl, getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder), 'c-hyperlink')
                                    :
                                    (getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder))
                                }
                            </h3>
                            {data.subHeading && data.subHeading.lv && 
                                <p className="c-paragraph-2 f-lean">{getLocalizedString(data.subHeading)}</p>
                            }
                        </div>
                    </section>
                ) : (
                    <section className="c-mosaic-placement">
                        <div>
                            <h3 className="c-heading">Please add an additional panel</h3>
                        </div>
                    </section>
                )
            }
        </div>
    );
}

export function one(panelData: MosaicPanelPropertiesData, onEditorClick: (index: number) => void, selectedIndex: number, showEditor: boolean) {
    const { columns } = panelData;

    return (
        <div className="c-mosaic theme-dark">
            {renderColumn(columns && columns[0], 'f-vp1-whole f-vp4-half f-height-large', () => { onEditorClick(0); }, selectedIndex === 0, showEditor)}
            {renderColumn(columns && columns[1], 'f-vp1-whole f-vp4-half f-height-large', () => { onEditorClick(1); }, selectedIndex === 1, showEditor)}
        </div>
    );
}

export function two(panelData: MosaicPanelPropertiesData, onEditorClick: (index: number) => void, selectedIndex: number, showEditor: boolean) {
    const { columns } = panelData;

    return (
        <div className="c-mosaic theme-dark">
            {renderColumn(columns && columns[0], 'f-vp1-whole f-vp4-half f-height-large', () => { onEditorClick(0); }, selectedIndex === 0, showEditor, true)}
            <div data-f-mosaic="f-vp1-whole f-vp4-half">
                {renderColumn(columns && columns[1], 'f-vp1-whole f-vp2-half f-height-medium', () => { onEditorClick(1); }, selectedIndex === 1, showEditor)}
                {renderColumn(columns && columns[2], 'f-vp1-whole f-vp2-half f-height-medium', () => { onEditorClick(2); }, selectedIndex === 2, showEditor)}
                {renderColumn(columns && columns[3], 'f-vp1-whole f-height-medium', () => { onEditorClick(3); }, selectedIndex === 3, showEditor)}
            </div>
        </div>
    );
}

export default { one, two };
