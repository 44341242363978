import * as React from 'react';
import { PanelData } from '../../Models/PageInfrastructure';
import { PanelEditor } from '../../Components/PageInfrastructure/PanelEditor';
import { PanelPropertiesDataBase } from '../../Models/PageInfrastructure/PanelPropertiesData';

export interface BasicPanelProperties<T extends PanelPropertiesDataBase> {
    panel: PanelData<T>;
    isInEditMode: boolean;
    makeDirty: () => void;
    delete: (panel: PanelData<T>) => void;
    moveUp: (panel: PanelData<T>) => void;
    moveDown: (panel: PanelData<T>) => void;
    idOfPage: string;
    parentId?: string;
}

export function getEditableSideBarCol<T extends PanelPropertiesDataBase>(props: BasicPanelProperties<T> , showEditor: () => void) {
    return (
        <div className="row-cols-8" style={{ backgroundColor: '#f4f4f4' }}>
            <PanelEditor
                edit={() => showEditor()}
                delete={() => props.delete(props.panel)}
                moveUp={() => props.moveUp(props.panel)}
                moveDown={() => props.moveDown(props.panel)}
            />
        </div>
    );
}