import * as React from 'react';
import { PanelData } from '../../Models/PageInfrastructure';
import { PanelPropertiesDataBase, LeftRightViewsPanelPropertiesData, FAQPanelPropertiesData } from '../../Models/PageInfrastructure/PanelPropertiesData/';
import { PanelSelectionHelperFactory, getPanelBorderClassName } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { RichHeadingPanel } from './RichHeadingPanel';
import { ContentRichBlockPanel } from './ContentRichBlockPanel';
import { ContentPlacementPanel } from './ContentPlacementPanel';
import { ContentPlacementIconPanel } from './ContentPlacementIconPanel';
import { ContentPlacementImagePanel } from './ContentPlacementImagePanel';
import { SidebarGridPanel } from './SidebarGridPanel';
import { HeroPanel } from './HeroPanel';
import { FeaturePanel } from './FeaturePanel';
import { MosaicPanel } from './MosaicPanel';
import { default as LibraryNotificationPanel } from '../../Containers/LibraryNotificationPanel'; // import the redux-connect version
import { HeroItemPanel } from './HeroItemPanel';
import { GuideHelpPanel } from './GuideHelpPanel';
import { DocumentsPanel } from './DocumentsPanel';
import SortFilterDocumentPanel from '../../Containers/SortFilterDocumentPanel';
import DocumentListPanel from '../../Containers/DocumentListPanel';
import { ViewsPanel } from './ViewsPanel';
import { HeaderPanel } from './HeaderPanel';
import { AreaHeadingPanel } from './AreaHeadingPanel';
import { AreaHeadingWithImagePanel } from './AreaHeadingWithImagePanel';
import { ContentPivotPanel } from './ContentPivotPanel';
import { LinkListPanel } from './LinkListPanel';
import { TopResourcePanel } from './TopResourcePanel';
import { StructuredListPanel } from './StructuredListPanel';
import { DrawerPanel } from './DrawerPanel';
import { LeftRightPanel } from './LeftRightPanel';
import { FAQPanel } from './FAQPanel';
import { QuotePanel } from './QuotePanel';
import { CardPanel } from './CardPanel';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ButtonTypes, CommandBarButtonFactory } from '../../../Common/Utilities/CommanBarButtonFactory';
import './PanelSelectorDialog.css';
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { PanelFlightAndPermissionEditor } from './PanelFlightAndPermissionEditor';
import './Panels.css';
import { ImageRichTextPanel } from './ImageRichTextPanel';
import { CtaPanel } from './CtaPanel';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';

interface PanelsCollectionProps {
    panels: Array<PanelData<PanelPropertiesDataBase>>;
    isInEditMode: boolean;
    // When the page contributer is modifying the page, this property will be true.
    // Flight and Authorize will be disabled
    pageEditorView?: boolean;
    addNewPanel: (type: string) => void;
    makeDirty: () => void;
    deletePanel: (panel: PanelData<PanelPropertiesDataBase>) => void;
    selectView?: () => void;
    movePanelUp: (panel: PanelData<PanelPropertiesDataBase>) => void;
    movePanelDown: (panel: PanelData<PanelPropertiesDataBase>) => void;
    updateFlightAndPermission?: (panel: PanelData<PanelPropertiesDataBase>) => void;
    idOfPage: string;
    parentId: string;
    hasInPageNav: boolean;
    title: string;
}

interface PanelsCollectionStates {
    showModal: boolean;
}

export class PanelCollection extends React.Component<PanelsCollectionProps, PanelsCollectionStates> {
    private addPanelButton: IContextualMenuItem;
    private lastPanelDiv: HTMLDivElement;
    private panelAdded: boolean;

    constructor(props: PanelsCollectionProps) {
        super(props);
        this.state = { showModal: false };
        this.panelAdded = false;
        this.addPanelButton = CommandBarButtonFactory.GetButton(ButtonTypes.Add, () => this.setState({ showModal: true }), Intl.Get(LocIds.PageAndPanels.AddPanelLabel));
    }

    componentDidUpdate() {
        if (this.lastPanelDiv && this.panelAdded && this.state.showModal) {
            this.lastPanelDiv.scrollIntoView();
        }

        document.title = this.props.title;
    }

    addNewPanel(type: string) {
        if (this.addNewPanel) {
            this.props.addNewPanel(type);
            this.panelAdded = true;
        }
    }

    renderAddPanelSection(previewImgSrc: string, panelTitle: string, panelSelector: string) {

        return (
        <div style={{margin: '1.5rem 0'}}>
            <div className="row">
                <h3 style={{fontWeight: '350', marginLeft: '15px'}}>{Intl.Get(panelTitle)}</h3>
            </div>
            <div className="row">
                <div className="col-md-10">
                    <img
                        className="c-image"
                        srcSet={previewImgSrc}
                        src={previewImgSrc}
                        alt={Intl.Get(panelTitle)}
                    />
                </div>
                <div className="col-md-2">
                    <PrimaryButton
                        onClick={() => this.addNewPanel(panelSelector)}
                        text={Intl.Get(LocIds.PageAndPanels.AddButtonLabel)}
                        name={Intl.Get(panelTitle) + ' ' + Intl.Get(LocIds.PageAndPanels.AddButtonLabel)}
                    />
                </div>
            </div>
        </div>);
    }

    render() {
        return (
            <div id="panel-collection" className="panels-collection">
                {this.props.isInEditMode &&
                    <div>
                        <div style={{ marginBottom: '6px' }} />
                        <div onClick={this.selectViewOnClick}>
                            <CommandBar items={[this.addPanelButton]} />
                        </div>
                        <div style={{ marginBottom: '6px' }} />
                        <Modal
                            isOpen={this.state.showModal}
                            onDismiss={() => this.setState({ showModal: false })}
                            onDismissed={() => this.panelAdded && this.lastPanelDiv.scrollIntoView()}
                            isBlocking={true}
                            containerClassName="ms-modal-container modal-container"
                        >
                            <div>
                                <div className="ms-modal-header" style={{ backgroundColor: '#0078D7' }}>
                                    <div className="c-heading-3" style={{ marginLeft: '12px', marginBottom: '12px', color: '#ffffff' }}>
                                        <h2>{Intl.Get(LocIds.PageAndPanels.PanelSelectTitle)}</h2>
                                    </div>
                                </div>
                                <div className="panelSelectorDialog ms-modal-body">
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/HeroItemPanelPreview.jpg',
                                        LocIds.PageAndPanels.HeroItemPanelTitle,
                                        PanelSelectionHelperFactory.HERO_ITEM_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        'Images/Panel-Infrastructure/Panel-Preview/SidebarGridPreview.jpg',
                                        LocIds.PageAndPanels.SidebarGridPanelTitle,
                                        PanelSelectionHelperFactory.SIDEBAR_GRID
                                    )}
                                    {this.renderAddPanelSection(
                                        'Images/Panel-Infrastructure/Panel-Preview/RichHeadingPanelPreview.jpg',
                                        LocIds.PageAndPanels.RichHeadingPanelTitle,
                                        PanelSelectionHelperFactory.RICH_HEADING_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/HeaderPanelPreview.jpg',
                                        LocIds.PageAndPanels.HeaderPanelTitle,
                                        PanelSelectionHelperFactory.HEADER_PANEL
                                    )}                                    
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/DocumentListPanelPreview.jpg',
                                        LocIds.PageAndPanels.DocumentFilterableListPanelTitle,
                                        PanelSelectionHelperFactory.DOCUMENT_LIST_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/ContentPlacementPreview.jpg',
                                        LocIds.PageAndPanels.ContentPlacementPanelTitle,
                                        PanelSelectionHelperFactory.CONTENT_PLACEMENT
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/AreaHeadingPanelPreview.jpg',
                                        LocIds.PageAndPanels.AreaHeadingPanelTitle,
                                        PanelSelectionHelperFactory.AREAHEADING
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/AreaHeadingPaneWithImagelPreview.jpg',
                                        LocIds.PageAndPanels.AreaHeadingWithIconPanelTitle,
                                        PanelSelectionHelperFactory.AREA_HEADING_WITH_IMAGE
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/ViewsPanelPreview.jpg',
                                        LocIds.PageAndPanels.PivotPanelTitle,
                                        PanelSelectionHelperFactory.PIVOT_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/ContentRichBlockPreview.jpg',
                                        LocIds.PageAndPanels.ContentRichBlockPanelTitle,
                                        PanelSelectionHelperFactory.CONTENT_RICH_BLOCK_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/DocumentsPanelPreview.jpg',
                                        LocIds.PageAndPanels.DocumentPanelTitle,
                                        PanelSelectionHelperFactory.DOCUMENTS_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/AdvancedDocumentsPanelPreview.jpg',
                                        LocIds.PageAndPanels.AdvancedDocumentPanelTitle,
                                        PanelSelectionHelperFactory.SORT_FILTER_DOCUMENTS_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/ContentPlacementIconPreview.jpg',
                                        LocIds.PageAndPanels.ContentPlacementWithIconPanelTitle,
                                        PanelSelectionHelperFactory.CONTENT_PLACEMENT_ICON
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/ContentPlacementImagePreview.jpg',
                                        LocIds.PageAndPanels.ContentPlacementWithImagePanelTitle,
                                        PanelSelectionHelperFactory.CONTENT_PLACEMENT_IMAGE
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/HeroPanelPreview.jpg',
                                        LocIds.PageAndPanels.HeroPanelTitle,
                                        PanelSelectionHelperFactory.HERO
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/FeaturePanelPreview.jpg',
                                        LocIds.PageAndPanels.FeaturePanelTitle,
                                        PanelSelectionHelperFactory.FEATURE_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/MosaicPanelPreview.jpg',
                                        LocIds.PageAndPanels.MosaicPanelTitle,
                                        PanelSelectionHelperFactory.MOSAIC
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/LibraryNotificationPanelPreview.jpg',
                                        LocIds.PageAndPanels.LibraryNotificationPanelTitle,
                                        PanelSelectionHelperFactory.LIBRARY_NOTIFICATION
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/GuidePanelPreview.jpg',
                                        LocIds.PageAndPanels.GuideHelpPanelTitle,
                                        PanelSelectionHelperFactory.GUIDE_HELP_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/ContentPivotPanelPreview.jpg',
                                        LocIds.PageAndPanels.ContentPivotPanelTitle,
                                        PanelSelectionHelperFactory.CONTENT_PIVOT
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/LinkListPanelPreview.jpg',
                                        LocIds.PageAndPanels.LinkListPanelTitle,
                                        PanelSelectionHelperFactory.LINKLIST_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/TopResourcePanelPreview.jpg',
                                        LocIds.PageAndPanels.TopResourcePanelTitle,
                                        PanelSelectionHelperFactory.TOPRESOURCE_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/StructuredListPreview.jpg',
                                        LocIds.PageAndPanels.StructuredListPanelTitle,
                                        PanelSelectionHelperFactory.STRUCTEDLIST_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/LeftRightPanelPreview.jpg',
                                        LocIds.PageAndPanels.ColumnsPanelTitle,
                                        PanelSelectionHelperFactory.LEFTRIGHT_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/DrawerPanalPreview.jpg',
                                        LocIds.PageAndPanels.DrawerPanelTitle,
                                        PanelSelectionHelperFactory.DRAWER_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/DrawerPanalPreview.jpg',
                                        LocIds.PageAndPanels.FAQPanelTitle,
                                        PanelSelectionHelperFactory.FAQ_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/CardsPanelPreview.jpg',
                                        LocIds.PageAndPanels.CardsPanelTitle,
                                        PanelSelectionHelperFactory.CARDS_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/QuotePanelPreview.jpg',
                                        LocIds.PageAndPanels.QuotePanelTitle,
                                        PanelSelectionHelperFactory.QUOTE_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/ImageRichTextPanelPreview.jpg',
                                        LocIds.PageAndPanels.ImageRichTextPanelTitle,
                                        PanelSelectionHelperFactory.IMAGE_RICH_TEXT_PANEL
                                    )}
                                    {this.renderAddPanelSection(
                                        '/Images/Panel-Infrastructure/Panel-Preview/CtaPanelPreview.jpg',
                                        LocIds.PageAndPanels.CTAPanelTitle,
                                        PanelSelectionHelperFactory.CTA_PANEL
                                    )}
                                </div>
                            </div>
                            <div className="panelDialogfooter">
                                <DefaultButton onClick={() => this.setState({ showModal: false })}>Exit</DefaultButton>
                            </div>
                        </Modal>
                    </div>
                }

                {this.props.panels && this.props.panels.map((panel, index) =>
                    <div
                        data-grid="col-12"
                        key={panel.type.concat(index.toString())}
                        style={{
                            clear: 'both',
                            margin: this.props.isInEditMode ? '6px 0' : '',
                            border: this.props.isInEditMode ? 'solid 1px #778899' : '',
                            padding: this.props.isInEditMode ? '6px' : '',
                        }}
                        ref={(div: HTMLDivElement) => {
                            if (index === this.props.panels.length - 1) {
                                this.lastPanelDiv = div;
                            }
                        }}
                        className={!this.props.isInEditMode ? getPanelBorderClassName(panel) : ''}
                        id={this.props.hasInPageNav ? `in-page-nav-${index}` : ''}
                    >
                        {this.props.isInEditMode && <div><PanelFlightAndPermissionEditor panel={panel} updateFlightAndPermission={this.props.updateFlightAndPermission}/></div>}
                        {panel.type === PanelSelectionHelperFactory.RICH_HEADING_PANEL ?
                            <RichHeadingPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.HERO_ITEM_PANEL ?
                            <HeroItemPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.HEADER_PANEL ?
                            <HeaderPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.PIVOT_PANEL ?
                            <ViewsPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.CONTENT_RICH_BLOCK_PANEL ?
                            <ContentRichBlockPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.CONTENT_PLACEMENT ?
                            <ContentPlacementPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.HERO ?
                            <HeroPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.FEATURE_PANEL ?
                            <FeaturePanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.MOSAIC ?
                            <MosaicPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.LIBRARY_NOTIFICATION ?
                            <LibraryNotificationPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.GUIDE_HELP_PANEL ?
                            <GuideHelpPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.AREAHEADING ?
                            <AreaHeadingPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.AREA_HEADING_WITH_IMAGE ?
                            <AreaHeadingWithImagePanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.CONTENT_PLACEMENT_ICON ?
                            <ContentPlacementIconPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.CONTENT_PLACEMENT_IMAGE ?
                            <ContentPlacementImagePanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.SIDEBAR_GRID ?
                            <SidebarGridPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.CONTENT_PIVOT ?
                            <ContentPivotPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.DOCUMENTS_PANEL ?
                            <DocumentsPanel {...this.getPanelProps(panel)} parentId={this.props.parentId} />
                            : panel.type === PanelSelectionHelperFactory.SORT_FILTER_DOCUMENTS_PANEL ?
                            <SortFilterDocumentPanel {...this.getPanelProps(panel)} parentId={this.props.parentId} />
                        : panel.type === PanelSelectionHelperFactory.DOCUMENT_LIST_PANEL ?
                            <DocumentListPanel {...this.getPanelProps(panel)} parentId={this.props.parentId} />
                        : panel.type === PanelSelectionHelperFactory.LINKLIST_PANEL ?
                            <LinkListPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.TOPRESOURCE_PANEL ?
                            <TopResourcePanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.STRUCTEDLIST_PANEL ?
                            <StructuredListPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.LEFTRIGHT_PANEL ? 
                            <LeftRightPanel {...this.getPanelProps<LeftRightViewsPanelPropertiesData>(panel as PanelData<LeftRightViewsPanelPropertiesData>)} />
                        : panel.type === PanelSelectionHelperFactory.DRAWER_PANEL ?
                            <DrawerPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.FAQ_PANEL ?
                            <FAQPanel {...this.getPanelProps<FAQPanelPropertiesData>(panel as PanelData<FAQPanelPropertiesData>)} />
                        : panel.type === PanelSelectionHelperFactory.QUOTE_PANEL ? 
                            <QuotePanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.CARDS_PANEL ?
                            <CardPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.IMAGE_RICH_TEXT_PANEL ? 
                            <ImageRichTextPanel {...this.getPanelProps(panel)} />
                        : panel.type === PanelSelectionHelperFactory.CTA_PANEL ? 
                            <CtaPanel {...this.getPanelProps(panel)} />
                        : null}
                    </div>
                )}
            </div>
        );
    }

    getPanelProps<T extends PanelPropertiesDataBase>(panel: PanelData<T>) {
        return {
            panel: panel,
            panelInfo: panel.panelInfo,
            isInEditMode: this.props.isInEditMode,
            delete: (p: PanelData<PanelPropertiesDataBase>) => this.props.deletePanel(p),
            moveUp: (p: PanelData<PanelPropertiesDataBase>) => this.props.movePanelUp(p),
            moveDown: (p: PanelData<PanelPropertiesDataBase>) => this.props.movePanelDown(p),
            makeDirty: () => this.props.makeDirty(),
            idOfPage: this.props.idOfPage
        } as BasicPanelProperties<T>;
    }

    selectViewOnClick = () => {
        if (this.props.selectView) {
            this.props.selectView();
        }
    }
}