export class ErrorInfo {
    operationId: string;
    regionId: string;
    requestUrl: string;
    whenStarted: string;
    statusCode: string;
    requestType: string;
    elapsedTime: number;
    correlationId: string;
    message: string;
}