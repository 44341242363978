import { PanelData, PageData } from '../../Models/PageInfrastructure';
import { PanelPropertiesDataBase } from '../../Models/PageInfrastructure/PanelPropertiesData/';
import { AxiosResponse } from 'axios';
import { ErrorResponse } from '../../Services/Models';
import { AuthorizationRights } from '../../../Common/Utilities/AuthorizationInfo';
import { getDeepCopy } from '../DeepCopyHelper';
import { VocabularySelection } from '../../Models/Vocabulary/VocabularySelection';
import { ModelWithVocabularySelections } from '../../Models/ModelWithVocabularySelections';
import { LocalizedString } from '../../../Common/Models';

export enum PageStatus {
    Live = 'Public',
    Saved = 'Edit in Progress',
    PendingApproval = 'Pending Approval',
    PendingLocalization = 'Pending Localization',
    History = 'History',
    Deleted = 'Deleted'
}

export enum PageRedirectType {
    None, AddNew, Edit, PageList
}

export const addNewPage: string = 'AddNewPage';

export const defaultUrlName: string = 'NewPage';

export class Page implements PageData, ModelWithVocabularySelections {
    public id: string;
    public pageId: string;
    public whoLastModified: string;
    public whenLastModified: string;
    public whoApprovedOrRejected: string;
    public whenApprovedOrRejected: string;
    public status: string;
    public deletePending: boolean;
    public requiredFlights?: string[];
    public requiredVocabularies: string[];
    public vocabularySelections: VocabularySelection[];
    public vocabularySelectionsV2022: VocabularySelection[];
    public scope: string;
    public requiredPermissions: AuthorizationRights;
    public urlName: string;
    public keyword: LocalizedString;
    public cloudService: string;
    public industry: string;
    public roleTask: string;
    public panels: Array<PanelData<PanelPropertiesDataBase>>;
    public showFallbackLanguageMessage: boolean;
    public pageAvailableInLanguages: string[];
    public previousId: string;
    public title: LocalizedString;
    public description: LocalizedString;
    public owner: string;
    public redirectFrom: string[];
    private clonedPage: Page;

    public static getNewPage(): Page {
        let newPage: Page = new Page();
        newPage.urlName = defaultUrlName;
        newPage.panels = [];
        newPage.status = '';
        newPage.deletePending = false;
        newPage.showFallbackLanguageMessage = false;
        newPage.title = {li: '', lv: ''} as LocalizedString;
        newPage.owner = '';
        return newPage;
    }

    public static buildPage(page: Page): Page {
        let ret = new Page();
        // Since we already have a deep copy of the object so we know all properties are present in cloned object
        // we can avoid the hasOwnProperty check to get faster iterations
        // tslint:disable-next-line:forin
        for (let prop in page) {
            ret[prop] = page[prop];
        }
        ret.makeBackup();
        return ret;
    }    

    public makeBackup() {
        this.clonedPage = getDeepCopy(this) as Page;
    }

    public retrieveBackup(): Page {
        let ret = Page.buildPage(this.clonedPage);
        return ret;
    }
}

export interface PagesAPI {
    getAllPages: (page: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    getAllPageVersions: (pageId: string, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    getPage: (page: string, status: string, id: string, isInEditMode: boolean, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    savePage: (page: Page, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    rejectPage: (page: Page, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    publishPage: (page: Page, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    approvePage: (page: Page, onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    bulkApprovePages: (ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
    bulkDeletePages: (ids: string[], onRequestSuccess: (response: AxiosResponse) => void, onRequestFailed: (response: ErrorResponse) => void) => void;
}

// This method is used in admin page to force localized string modified in English
export function switchCultureToEnglish() {
    try {
        if (document && document !== null) {
            var lang = document.getElementsByTagName('html')[0].getAttribute('lang');
            if (lang !== 'en-US') {
                document.cookie = 'lan=en-US;path=/;';
                if (document.location) {
                    document.location.reload(true);
                }            
            }
        }        
    } catch {
        // Ignore. DOM is not there
    }
}