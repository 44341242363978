import { CertificationControl } from './CertificationControl';
import { FrameworkControl } from './FrameworkControl';
import { FrameworkUserActions } from './FrameworkUserActions';

export class FrameworkControlViewModel {
    public controlFamilyId: string;
    public microsoftControl: FrameworkControl;
    public userActions: FrameworkUserActions | null;
    public isVNextVersion: boolean;
    public isNew: boolean;
    public hasVNextVersion: boolean;
    public isExpanded?: boolean;
    public frameworkControls: { [key: string]: CertificationControl[]; };

    constructor(frameworkControl: FrameworkControl, frameworkUserActions: FrameworkUserActions | null) {
        this.microsoftControl = frameworkControl;
        this.userActions = frameworkUserActions;
    }
}
