import * as React from 'react';
import { Unsubscribe } from 'redux';
import { store } from '../../../../Store';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { StatusMessage } from '../../../Components/StatusMessage';
import { ImageManagmentState } from '../../../Reducers/ImageManagement';
import { ImageManagementServiceClient, ImageResponse } from '../../../../App/Services/ImageManagementService';
import { ErrorResponse } from '../../../../App/Services/Models/ErrorResponse';
import { IObjectWithKey } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { DetailsList, DetailsListLayoutMode, SelectionMode, Selection, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { ImageInfo } from '../../../Models/Image';
import { ServiceTrustClient } from '../../../../Common/Utilities/ServiceTrustClient';
import { FocusZone } from 'office-ui-fabric-react/lib/FocusZone';
import { ChoiceGroup, IChoiceGroupOption } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { List } from 'office-ui-fabric-react/lib/List';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../../Services/GlobalizationService';
import { TooltipHost, DirectionalHint } from 'office-ui-fabric-react/lib/Tooltip';
import './ImageManagement.css';
import { sortImage, updateColumnSortStatus, getImageUsingStatus } from '../../../Utilities/ImageHelper';

enum SortColumnName {
    Title = 'Title',
    Size = 'Size',
    LastModified = 'LastModified'
}

export interface ImagePickerDialogProps {
    onImageSelection: (selection: ImageInfo[]) => void;
    onDismiss: () => void;
    hidden: boolean;
}

export interface Rectangle {
    left: number;
    top: number;
    width: number;
    height: number;
    right?: number;
    bottom?: number;
}

const ROWS_PER_PAGE = 10;
const MAX_ROW_HEIGHT = 250;

export class ImagePickerDialog extends React.Component<ImagePickerDialogProps, ImageManagmentState> {
    private _selection: Selection;
    private _storeUnsubscriber: Unsubscribe;
    private rowHeight: number;
    private detailListKey: number = 0;
    private isDescending: boolean = false;
    private currentSortColumn: SortColumnName = SortColumnName.LastModified;
    private _columns: IColumn[] = [
        {
            key: 'Title',
            name: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogTitleLabel),
            fieldName: 'title',
            minWidth: 40,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: ImageInfo) => {
                return (
                    <div>
                        {item.title}
                    </div>
                );
            },
            onColumnClick: () => {
                this.sortImageOnImagePicker(SortColumnName.Title);
            }
        },
        {
            key: 'PreviewUrl',
            name: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogPreviewUrlLabel),
            fieldName: 'PreviewUrl',
            minWidth: 60,
            onRender: (item: ImageInfo) => {
                return (
                    <a target="_blank" href={ServiceTrustClient.getWebApiEndpointUrl().concat(item.previewUrl)}>
                        {Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogPreviewUrlText)}
                    </a>
                );
            }
        },
        {
            key: 'Size',
            name: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogSizeLabel),
            fieldName: 'size',
            minWidth: 35,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: ImageInfo) => {
                return (
                    <div>
                        {this.formatBytes(item.size)}
                    </div>
                );
            },
            onColumnClick: () => {
               this.sortImageOnImagePicker(SortColumnName.Size);
            }
        },
        {
            key: 'Resolution',
            name: 'Resolution',
            fieldName: 'resolution',
            minWidth: 50,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: ImageInfo) => {
                return (
                    <div>
                        {item.width + ' × ' + item.height}
                    </div>
                );
            }
        },
        {
            key: 'LastModified',
            name: Intl.Get(LocIds.ImageManagmentPage.LastModifiedLabel),
            fieldName: 'lastModified',
            minWidth: 80,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: ImageInfo) => {
                return (
                    <div>
                        {new Date(item.whenCreated).toLocaleDateString()}
                    </div>
                );
            },
            onColumnClick: () => {
                this.sortImageOnImagePicker(SortColumnName.LastModified);
            },
            isSorted: true,
            isSortedDescending: true
        },
        {
            key: 'Using Status',
            name: 'Using Status',
            fieldName: 'using Status',
            minWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: ImageInfo) => {
                return (
                    <div>
                        <ul>{getImageUsingStatus(item).map((status) => <li key={status}>{status}</li>)}</ul>
                    </div>
                );
            }
        }
    ];
    constructor(props: ImagePickerDialogProps) {
        super(props);

        this.state = store.getState().imageManagement;
        this._storeUnsubscriber = store.subscribe(() => {
            this.setState(store.getState().imageManagement);
        });

        this.setItemsInSelection = this.setItemsInSelection.bind(this);
        this.showImages = this.showImages.bind(this);
        this.modifySelection = this.modifySelection.bind(this);
        this.getItemCountForPage = this.getItemCountForPage.bind(this);
        this.getPageHeight = this.getPageHeight.bind(this);
    }

    public componentDidUpdate(): void {
        if (this.state.status === 'Init') {
            this.getAllImageMetadata();
        }

        if (this.state.status === 'Saved') {
            if (this.props.onImageSelection) {
                this.props.onImageSelection(this.state.selectedItems);
            }
        }
    }

    public componentWillMount() {
        this._selection = new Selection({
            onSelectionChanged: () =>
                this.dispatchAction('SELECTION_CHANGED', { allImages: this.getImagesUpdated(), selections: this._selection.getSelection() as ImageInfo[] }), selectionMode: SelectionMode.single
        });
        this.setItemsInSelection();
    }

    public componentWillUnmount() {
        this._storeUnsubscriber();
    }

    public render(): JSX.Element {
        this.setItemsInSelection();
        let { viewStyle } = this.state.pickerDialogState;
        return (
            <div>
                <Dialog
                    containerClassName="ImagePickerDialog"
                    hidden={this.props.hidden}
                    onDismiss={() => { this.dispatchAction('PICK_IMAGES_CANCELLED'); }}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogTitle)
                    }}
                    modalProps={{
                        titleAriaId: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogTitle),
                        subtitleAriaId: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogTitle),
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <StatusMessage id="uploadImageDialogMessage" />
                    <div>
                        <ChoiceGroup
                            label={Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogChoiceGroupLabel)}
                            selectedKey={viewStyle}
                            options={[{
                                key: 'thumbnails',
                                text: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogChoiceGroupOption1Text)
                            },
                            {
                                key: 'details',
                                text: Intl.Get(LocIds.ImageManagmentPage.ImagePickerDialogChoiceGroupOption2Text)
                            }]}
                            onChange={(ev: React.FormEvent<HTMLInputElement>, option: IChoiceGroupOption) => {
                                this.dispatchAction('PICK_IMAGES_VIEWCHANGE', option.key);
                            }}
                        />
                    </div>
                    {this.showImages()}
                    <div>
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => {
                                    if (this.props.onImageSelection) {
                                        this.props.onImageSelection(this.state.selectedItems);
                                    }
                                }}
                                text="Ok"
                            />
                            <DefaultButton
                                onClick={() => {
                                    if (this.props.onDismiss) {
                                        this.props.onDismiss();
                                    }
                                }}
                                text="Cancel"
                            />
                        </DialogFooter>
                    </div>
                </Dialog>
            </div>
        );
    }

    private getImagesUpdated(): ImageInfo[] {
        var items: IObjectWithKey[] = this._selection.getItems();
        for (var i = 0; i < items.length; i++) {
            var imgDef = items[i] as ImageInfo;
            imgDef.isSelected = this._selection.isIndexSelected(i);
        }
        return items as ImageInfo[];
    }

    private getItemCountForPage(itemIndex: number, surfaceRect: Rectangle) {
        let columnCount = 1;
        if (itemIndex === 0) {
            columnCount = Math.ceil(surfaceRect.width / MAX_ROW_HEIGHT);
            let columnWidth = Math.floor(surfaceRect.width / columnCount);
            this.rowHeight = columnWidth;
        }

        return columnCount * ROWS_PER_PAGE;
    }

    private getPageHeight(itemIndex: number, surfaceRect: Rectangle) {
        return this.rowHeight * ROWS_PER_PAGE;
    }

    private showImages(): JSX.Element {
        if (this.state.imageInfos.length === 0) {
            return <div className="ImagePickerEmpty" />;
        }
        return this.state.pickerDialogState.viewStyle === 'details' ? (
            <div className="ImagePickerContainer">
                <DetailsList
                    key={this.detailListKey}
                    items={this.state.imageInfos}
                    columns={this._columns}
                    selectionMode={SelectionMode.single}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selection={this._selection}
                    selectionPreservedOnEmptyClick={true}
                    setKey={'set'}
                    onShouldVirtualize={() => false}
                />
            </div>) : (
            <div className="ImagePickerContainer">
                <FocusZone>
                    <List
                        className="ImagePickerList"
                        items={this.state.imageInfos}
                        getItemCountForPage={this.getItemCountForPage}
                        getPageHeight={this.getPageHeight}
                        renderedWindowsAhead={ROWS_PER_PAGE}
                        onRenderCell={(item: ImageInfo, index: number | undefined) => {
                            return (
                                <div
                                    className="ImagePickerTile"
                                    data-is-focusable={true}
                                    style={{
                                        width: '25%'
                                    }}
                                >
                                    <TooltipHost
                                        content={`Title: ${item.title} Resolution: ${item.width} × ${item.height} \n ${getImageUsingStatus(item).map(p => p)}`}
                                        calloutProps={{ isBeakVisible: false, gapSpace: 0 }}
                                        tooltipProps={{ directionalHint: DirectionalHint.topLeftEdge }}
                                    >
                                        <div className="ImagePickerSizer">
                                            <div className="ImagePickerCell">
                                                <img
                                                    src={ServiceTrustClient.getWebApiEndpointUrl().concat(item.previewUrl)}
                                                    className="ImagePickerImage"
                                                    alt=""
                                                /> 
                                                <Checkbox
                                                    className="ImagePickerCheck"
                                                    checked={item.isSelected}
                                                    onChange={(ev: React.FormEvent<HTMLInputElement>, checked: boolean) => {
                                                        this.modifySelection(item, checked);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </TooltipHost>
                                </div>
                            );
                        }}
                    />
                </FocusZone>
            </div>);
    }

    private modifySelection(item: ImageInfo, checked: boolean) {
        var items: IObjectWithKey[] = this._selection.getItems();
        if (items.length === 0) {
            items = this.setItemsInSelection();
        }
        this._selection.setAllSelected(false);
        for (var i = 0; i < items.length; i++) {
            var imgDef = items[i] as ImageInfo;
            if (imgDef.id === item.id) {
                imgDef.isSelected = checked;
                this._selection.setIndexSelected(i, checked, false);
                this.dispatchAction('SELECTION_CHANGED', { allImages: JSON.parse(JSON.stringify(items)), selections: this._selection.getSelection() as ImageInfo[] });
                break;
            }
        }
    }

    private setItemsInSelection(): IObjectWithKey[] {
        var items: IObjectWithKey[] = this._selection.getItems();
        if (items.length === 0) {
            this.state.imageInfos.forEach(imgInfo => {
                imgInfo.isSelected = false;
                items.push(Object.assign({ key: imgInfo.id }, imgInfo));
            });

            this._selection.setItems(items);
        }

        return items;
    }

    private getAllImageMetadata(): void {
        ImageManagementServiceClient.getAllImages(
            () => {
                this.dispatchAction('IMAGEDEFS_FETCHING');
            },
            (response: ImageResponse) => {
                this.dispatchAction('IMAGEDEFS_FETCHED', response.imageInfos);
            },
            (failed: ErrorResponse) => {
                this.dispatchAction('IMAGEDEFS_FETCHING_ERROR', failed);
            });
    }

    // tslint:disable-next-line:no-any
    private dispatchAction(actionType: string, data?: any): void {
        if (data) {
            store.dispatch({ type: actionType, data: data });
        } else {
            store.dispatch({ type: actionType });
        }
    }

    private formatBytes(bytes: number): string {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes > 0) {
            var i = Math.floor(Math.log(bytes) / Math.log(1024));
            let size = bytes / Math.pow(1024, i);
            return +size.toFixed(2) + ' ' + sizes[i];
        } 
        return '0' + sizes[0];
    }

    private sortImageOnImagePicker(columnName: SortColumnName) {
        let imageInfos = this.state.imageInfos;
        var newCurrentColumnAndIsDescending = updateColumnSortStatus(columnName, this.currentSortColumn, this._columns, this.isDescending);
        this.currentSortColumn = newCurrentColumnAndIsDescending.currentSortColumn;
        this.isDescending = newCurrentColumnAndIsDescending.isDescending;
        sortImage(imageInfos, columnName, this.isDescending);
        // because detailslist won't rerender although update the items, change the key to force rerender.
        this.detailListKey = 1 - this.detailListKey;
        this.setState({imageInfos: imageInfos});
    }
} 