import { AxiosResponse } from 'axios';
import { ErrorResponse } from './Models';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { MicrosoftServiceLinks } from '../Models/PrivacyPortal';

/** 
 * The MicrosoftServiceLinksService class provides helper methods to 
 * perform various actions on Microsoft service links.
 */
class MicrosoftServiceLinksService {
    public getCurrentLinks = (
        onRequestSuccess: (response: AxiosResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void
    ) => {
        return ServiceTrustClient.doRequest_New(
            'GET',
            '/api/v2/privacyPortal/microsoftServiceLinks/Current',
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }

    public getNextLinks = (
        onRequestSuccess: (response: AxiosResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void
    ) => {
        return ServiceTrustClient.doRequest_New(
            'GET',
            '/api/v2/privacyPortal/microsoftServiceLinks/Next',
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }

    public updateLinks = (
        links: MicrosoftServiceLinks,
        onRequestSuccess: (r: AxiosResponse) => void,
        onRequestFailed: (r: ErrorResponse) => void
    ) => {
        return ServiceTrustClient.doRequest_New(
            'PUT',
            '/api/v2/privacyPortal/microsoftServiceLinks/Next',
            links,
            () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }

    public publishLinks = (
        onRequestSuccess: (r: AxiosResponse) => void,
        onRequestFailed: (r: ErrorResponse) => void
    ) => {
        return ServiceTrustClient.doRequest_New(
            'POST',
            '/api/v2/privacyPortal/microsoftServiceLinks/Publish',
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }

    public approveLinks = (
        onRequestSuccess: (r: AxiosResponse) => void,
        onRequestFailed: (r: ErrorResponse) => void
    ) => {
        return ServiceTrustClient.doRequest_New(
            'POST',
            '/api/v2/privacyPortal/microsoftServiceLinks/Approve',
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }

    public rejectLinks = (
        onRequestSuccess: (r: AxiosResponse) => void,
        onRequestFailed: (r: ErrorResponse) => void
    ) => {
        return ServiceTrustClient.doRequest_New(
            'POST',
            '/api/v2/privacyPortal/microsoftServiceLinks/Reject',
            {},
            () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }
}

export let MicrosoftServiceLinksServiceClient = new MicrosoftServiceLinksService();