import { connect } from 'react-redux';
import { StoreState } from '../../Store';
import { DynamicPage, DynamicPageProps } from '../Pages/Admin/PageInfrastructure/DynamicPage';

export interface ContainerProps {
    pageUrl?: string;
    pageId?: string;
    isInEditMode: boolean;
    isNextGenUIEnabled?: boolean;
}

function mapStateToProps(state: StoreState, ownProps: ContainerProps): DynamicPageProps {
    let user = state.authentication.context.getCachedUser();
    return {
        pageUrl: ownProps.pageUrl,
        pageId: ownProps.pageId,
        isInEditMode: ownProps.isInEditMode,
        userName: user ? user.userName : '',
        availableCulture: state.applicationContext.availableCultures,
        isNextGenUIEnabled: state.applicationContext.userInfo.flights.indexOf('NextGenUI') > -1
    };
}

export default connect(mapStateToProps)(DynamicPage);