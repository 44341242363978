import * as React from 'react';
import * as uuid from 'uuid';
import { ContextualMenu, DirectionalHint, IContextualMenuItem, IContextualMenuProps } from 'office-ui-fabric-react/lib/ContextualMenu';
import { Icon } from './Icon';
import { Intl } from '../../App/Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';

/**
 * A lightly wrapped version of Office UI Fabric ContextMenu for use in our document table.
 *
 * @see https://developer.microsoft.com/en-us/fabric#/components/contextualmenu
 */
export class TableContextMenu extends React.Component<{ menuItems: IContextualMenuItem[]; key?: string; }, { isContextMenuVisible: boolean; }> {
  state = { isContextMenuVisible: false };
  private contextualMenuButton: HTMLButtonElement;

  public render() {
    const key = this.props.key || uuid.v4();

    return (
      <div>
        <div>
          <button
            title={Intl.Get(LocIds.ContextMenus.TableContextMenuTitle)}
            tabIndex={0}
            onClick={this._onOpenClick}
            ref={(e: HTMLButtonElement) => this.contextualMenuButton = e}
            aria-haspopup="true"
            aria-expanded={this.state.isContextMenuVisible ? 'true' : 'false'}
            aria-label={Intl.Get(LocIds.ContextMenus.TableContextMenuTitle)}
            id={`togglecontextmenu-${key}`}
          >
            <Icon iconName={'CustomContextMenu'} style={{color: '#005da6', fontSize: '16px' }}/>
          </button>
        </div>
        {this.state.isContextMenuVisible && (
          <ContextualMenu
            id={`tablecontextmenu-${key}`}
            aria-labelledby={`togglecontextmenu-${key}`}
            className={'fontSize'}
            directionalHint={DirectionalHint.bottomRightEdge}
            /* newer versions of fabric support this: hidden={!this.state.isContextMenuVisible} instead of conditional rendering */
            items={this.props.menuItems}
            onDismiss={this._onDismiss}
            onMenuOpened={this._onMenuOpened}
            onMenuDismissed={this._onMenuDismissed}
            onItemClick={this._onItemClick}
            shouldFocusOnMount={true}
            target={this.contextualMenuButton}
          />
        )}
      </div>
    );
  }

  private _onItemClick = (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): boolean => {
    this.setState({ isContextMenuVisible: false });
    this.contextualMenuButton.focus();

    // the older version of Fabric doesn't allow you to easily manage open/close from the items so we use this workaround
    if (item && item.data.workaroundOnClick) { item.data.workaroundOnClick.apply(null, [ev, item]); }

    return true; // force the context menu to close even if preventDefault was called
  }

  private _onOpenClick = () => {
    this.setState({ isContextMenuVisible: true });
  }

  private _onDismiss = () => {
    this.setState({ isContextMenuVisible: false });
  }

  private _onMenuOpened = (contextualMenu: IContextualMenuProps) => {
    (contextualMenu.target as HTMLElement).classList.add('is-expanded');
  }

  private _onMenuDismissed = (contextualMenu: IContextualMenuProps) => {
    (contextualMenu.target as HTMLElement).classList.remove('is-expanded');
  }
}
