import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { AxiosResponse } from 'axios';
import { SearchRequest, SearchResponse, ErrorResponse } from './Models';
import { ApplicationUserInfo } from '../../Common/Utilities/ApplicationUserInfo';

export class SearchService {
    GetSearchResult(
        request: SearchRequest,
        applicationUserInfo: ApplicationUserInfo,
        beforeRequest: () => void,
        onRequestSuccess: (response: SearchResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/GetSearchResult';

        if (applicationUserInfo.flights.indexOf('SearchV2') > -1) {
            api = '/api/v2/GetSearchV2Result';
        }

        ServiceTrustClient.doRequest_New(
            'POST', api, request.searchCondition, beforeRequest, (response: AxiosResponse) => {
                let res: SearchResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    searchResult: response.data
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );
    }
}

export let searchServicesClient = new SearchService();