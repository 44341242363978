import * as React from 'react';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { Link } from 'react-router-dom';
import { DropdownMenuComponent } from '../../../Common/Components/DropdownMenu';
import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import './ExportData.css';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { PrivacyOperation } from '../../Models/PrivacyPortal/PrivacyOperation';
import { PrivacyPortalClient } from '../../Services/PrivacyPortalService';
import { store } from '../../../Store';
import * as PrivacyManagerReducer from '../../Reducers/PrivacyPortal/PrivacyManager';
import { GetPrivacyOperationsResponse, GetExportedFilesResponse } from '../../Services/Models/PrivacyPortal/index';
import { ErrorResponse } from '../../Services/Models/index';
import { PrivacyOperationSubject, ExportedFile } from '../../Models/PrivacyPortal/index';
import { UserInfo } from '../../Models/PrivacyPortal/UserInfo';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { IPersonaProps, Persona, PersonaPresence } from 'office-ui-fabric-react/lib/Persona';

let api = PrivacyPortalClient;

const maxPage: number = 15;

export enum StatusFilter {
    All = -1,
    Pending = 0,
    Completed = 1, 
    Failed = 2,
}

export interface PrivacyManagerPrivacyOperation {
    privacyOperation: PrivacyOperation;
    user: IPersonaProps;
    exportedFiles: ExportedFile[] | undefined;
}

export interface PrivacyManagerProps {
    privacyOperations: PrivacyManagerPrivacyOperation[];
    exportSuccessMessage?: string;
    pendingCount: number;
    completedCount: number;
    failedCount: number;
    isLoading: boolean;
}

export interface PrivacyManagerState {
    showPanel: boolean;
    showDetailsFor: PrivacyManagerPrivacyOperation;
    pagination: Pagination;
    statusFilter?: StatusFilter;
    dateRequestedFilter?: string;
    searchText?: string;
    showPrivacyOperationsErrorMessage: boolean;
    showFeatureNotice: boolean;
}

export interface Pagination {
    itemsPerPage: string;
    currentPage: number;
}

export function getStateTextFromState(state?: StatusFilter): (string | undefined) {
    if (state === StatusFilter.All) {
        return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOperationFiltersAllOptionLabel);
    } else if (state === StatusFilter.Pending) {
        return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOperationStatusPendingLabel);
    } else if (state === StatusFilter.Completed) {
        return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOperationStatusCompletedLabel);
    } else if (state === StatusFilter.Failed) {
        return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOperationStatusFailedLabel);
    }
    return undefined;
}

export default class PrivacyManagerPage extends React.Component<PrivacyManagerProps, PrivacyManagerState> {
    static readonly DateRequestedAllOptionKey = '-1';

    constructor(props: PrivacyManagerProps) {
        super(props);
        this.state = {
            showPanel: false,
            showDetailsFor: {
                privacyOperation: {
                    id: '',
                    State: StatusFilter.Pending,
                    StateText: '',
                    DestinationUri: '',
                    Context: '',
                    SubmittedTime: '',
                    Subject: {} as PrivacyOperationSubject,
                    User: {} as UserInfo,
                    exportFileContainer: ''
                }, user: {} as IPersonaProps, exportedFiles: undefined
            },
            statusFilter: StatusFilter.All,
            dateRequestedFilter: PrivacyManagerPage.DateRequestedAllOptionKey,
            pagination: { itemsPerPage: '5', currentPage: 1 },
            showPrivacyOperationsErrorMessage: false,
            showFeatureNotice: true
        };
        this._onShowPanel = this._onShowPanel.bind(this);
        this._onClosePanel = this._onClosePanel.bind(this);
    }

    public componentDidMount(): void {
        api.GetPrivacyOperations(
            {},
            () => {
                store.dispatch(PrivacyManagerReducer.getRequestingAction());
            },
            (response: GetPrivacyOperationsResponse) => {
                store.dispatch(PrivacyManagerReducer.getRequestSucceededAction(response.message, response.data.value));
            },
            (failed: ErrorResponse) => {
                let message: string = failed.code + ' ' + failed.status + ' - ' + failed.message;
                this.setState({ showPrivacyOperationsErrorMessage: true });
                store.dispatch(PrivacyManagerReducer.getRequestErrorAction(message));
            });
    }

    public componentWillUnmount(): void {
        store.dispatch(PrivacyManagerReducer.getExportDataRequestMessageDismissAction());
    }

    getExportRequestItems() {
        return (
            <span>{this.getCurrentPageItems().map((item, _) => <div key={item.privacyOperation.id} data-grid="col-12" className="privacy-portal-each-datarequest">
                <div data-grid="col-3">
                    <div className="c-age-rating">
                        <Persona
                            {...item.user}
                            presence={PersonaPresence.none}
                        />
                    </div>
                </div>
                <div data-grid="col-1">
                    <p className="c-subheading-6 privacy-portal-label">{item.privacyOperation.StateText}</p>
                </div>
                <div data-grid="col-1">
                    <p className="c-subheading-6 privacy-portal-label">{item.privacyOperation.SubmittedTime ? new Date(item.privacyOperation.SubmittedTime).toLocaleDateString() : ''}</p>
                </div>
                <div data-grid="col-4">
                    { item.privacyOperation.DestinationUri &&
                        <Link to={item.privacyOperation.DestinationUri} target="_blank" >
                            <p className="c-hyperlink c-subheading-6 privacy-portal-label privacy-portal-link" data-grid="col-12">{item.privacyOperation.exportFileContainer}</p>
                        </Link> 
                    }
                </div>
                <div data-grid="col-3">
                    <p className="c-subheading-6 privacy-portal-label">{item.privacyOperation.id}</p>
                </div>
            </div>)}
            </span>
        );
    }

    getPagination() {
        return (
            <div data-grid="col-12" style={{ paddingTop: 10 }}>
                <div data-grid="col-12">
                    <p className="c-paragraph-4 privacy-portal-label" data-grid="col-12">
                        {this._getShowingPagesText()}
                    </p>
                </div>
                <ul data-grid="col-9" className="m-pagination">
                    {this.state.pagination && this.state.pagination.currentPage > 1 ? <li>
                        <a className="c-glyph privacy-portal-a" aria-label="Previous Page" onClick={() => this.setCurrentPage(this.state.pagination.currentPage - 1)}>
                            <span>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPaginationPreviousLabel)}</span>
                        </a>
                    </li> : null}
                    {this.getTotalPages().map((pageNumber, idx) =>
                        pageNumber === this.state.pagination.currentPage ?
                            (<li key={idx} className={'f-active'} data-label="Page 1">
                                <span aria-label={'Currently on page ' + pageNumber}>{pageNumber}</span>
                            </li>) : (<li key={idx}>
                                <a className="privacy-portal-a" aria-label={'Page' + pageNumber} onClick={() => this.setCurrentPage(pageNumber)} >{pageNumber}</a>
                            </li>))
                    }
                    {this.state.pagination && this.state.pagination.currentPage < this.getLastPage() ?
                        <li>
                            <a className="c-glyph privacy-portal-a" aria-label="Next Page" onClick={() => this.setCurrentPage(this.state.pagination.currentPage + 1)}>
                                <span>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPaginationNextLabel)}</span>
                            </a>
                        </li>
                        : null}
                </ul>
                <div data-grid="col-3">
                    <div style={{ float: 'right' }}>
                        <span className="alignleft privacy-portal-label" style={{ padding: 0, margin: 0 }}>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerResultsPerPage)} </span>
                        <span className="alignleft privacy-portal-dropdownmenu-wrapper" style={{ paddingLeft: 5 }}>
                            <DropdownMenuComponent
                                items={[
                                    {
                                        key: '5',
                                        name: '5'
                                    },
                                    {
                                        key: '10',
                                        name: '10'
                                    },
                                    {
                                        key: '20',
                                        name: '20'
                                    },
                                ]}
                                selectedText={this.state.pagination.itemsPerPage}
                                onChange={(param: string) => { this.resultsPerPageChanged(param); }}
                            />
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    getFilteredItems(): PrivacyManagerPrivacyOperation[] {
        let items = this.props.privacyOperations;

        if (this.state.statusFilter !== StatusFilter.All) {
            items = items.filter(i => i.privacyOperation.State === this.state.statusFilter);
        }

        if (this.state.dateRequestedFilter !== PrivacyManagerPage.DateRequestedAllOptionKey) {
            let daysback = Number(this.state.dateRequestedFilter);
            let date = new Date();
            date.setDate(date.getDate() - daysback);
            items = items.filter(i => (new Date(i.privacyOperation.SubmittedTime)) >= date);
        }

        if (this.state.searchText) {
            let s: string = this.state.searchText.toLowerCase();
            items = items.filter(i => 
                (i.user.primaryText && i.user.primaryText.toLowerCase().indexOf(s) === 0)
            );
        }

        return items;
    }

    getLastPage() {
        let itemsPerPage = this.getItemsPerPage();
        let totalItems = this.getFilteredItems().length;
        let lastPage = Number(Math.ceil(totalItems / itemsPerPage));
        return lastPage;
    }

    resultsPerPageChanged(resultsPerPage: string) {
        this.setState({ pagination: { itemsPerPage: resultsPerPage, currentPage: 1 } });
    }

    setCurrentPage(pageNumber: Number) {
        if (pageNumber < this.getLastPage() + 1) {
            this.setState({ pagination: { itemsPerPage: this.state.pagination.itemsPerPage, currentPage: Number(pageNumber) } });
        }
    }

    getItemsPerPage(): number {
        return Number(this.state.pagination.itemsPerPage);
    }

    getTotalPages(): Number[] {
        let lastPage = this.getLastPage();
        let pages: Number[] = [];
        let midPageNumber = Number(Math.floor(maxPage / 2)); 
        let firstPageNumber = this.state.pagination.currentPage - midPageNumber + 1;
        firstPageNumber = firstPageNumber <= 0 ? 1 : firstPageNumber;
        let lastPageNumber = firstPageNumber + maxPage - 1;
        if (lastPageNumber > lastPage) {
            lastPageNumber = lastPage;
            firstPageNumber = lastPageNumber - maxPage + 1 <= 0 ? 1 : lastPageNumber - maxPage + 1; 
        }

        for (let i = firstPageNumber; i <= lastPageNumber; i++) {
            pages.push(i);
        }
        return pages;
    }

    getCurrentPageItems(): PrivacyManagerPrivacyOperation[] {
        let currentFiles: PrivacyManagerPrivacyOperation[] = [];
        let skip = (this.state.pagination.currentPage - 1) * this.getItemsPerPage();
        let filteredItems = this.getFilteredItems();
        if ((skip === 0 && filteredItems.length > 0) || skip <= filteredItems.length) {
            for (let i = skip; i < skip + this.getItemsPerPage(); i++) {
                if (filteredItems[i] !== undefined) {
                    currentFiles.push(filteredItems[i]);
                }
            }
        }
        return currentFiles;
    }

    render() {
        window.scrollTo(0, 0);

        let previewReleaseNotesLabel = Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPreviewReleaseNotesLabel);
        let parsedpreviewReleaseNotesLabel = previewReleaseNotesLabel.split('[LinkToReleaseNotes]');

        return (
            <div>
                {this.state.showFeatureNotice &&
                    <div className="m-alert f-error" role="alert">
                        <button className="c-action-trigger c-glyph glyph-cancel" aria-label="Close alert" onClick={() => this.setState({ showFeatureNotice: false })} />
                        <div>
                            <p className="c-paragraph">
                                {Intl.Get(LocIds.GDPRCompliance.FeatureNotificationLabel)}
                            </p>
                        </div>
                    </div>
                }
                {this.state.showPrivacyOperationsErrorMessage &&
                    <div data-grid="col-12">
                        <div className="m-alert f-error" role="alert">
                            <button className="c-action-trigger c-glyph glyph-cancel" aria-label="Close alert" onClick={() => this.setState ({ showPrivacyOperationsErrorMessage: false })} />
                            <div>
                                <div className="c-glyph glyph-info" aria-label="Information message" />
                                <p className="c-paragraph">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOptionsListError)}</p>
                            </div>
                        </div>
                    </div>
                }
                <div data-grid="col-12">
                    {this.props.exportSuccessMessage ?
                        <div className="m-alert f-information" role="alert">
                            <button className="c-action-trigger c-glyph glyph-cancel" aria-label="Close alert" onClick={() => this.onDismissExportDataSuccessAlert()} />
                            <div>
                                <div className="c-glyph glyph-info" aria-label="Information message" />
                                <p className="c-paragraph">{this.props.exportSuccessMessage}</p>
                            </div>
                        </div>
                        : null}
                </div>
                <div style={{ marginTop: '10px' }}>
                    <div data-grid="col-9">
                        <div data-grid="col-12">
                            <div data-grid="col-8">
                                <h6 className="col-12 c-heading-6">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerTitle)}</h6>
                                <h3 className="col-12 c-heading-3 tile-Progress" style={{ padding: 0 }}>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerSubTitle)}</h3>
                            </div>
                        </div>
                        <div data-grid="col-8">
                            <p className="col-12 c-paragraph-3 privacy-portal-label">
                                {Intl.Get(LocIds.GDPRCompliance.PrivacyManagerTitleSummary)}
                            </p>
                        </div>
                    </div>
                    <div data-grid="col-3">
                        <div data-grid="col-12" style={{ marginTop: 39 }}>
                            <p className="c-paragraph">
                                {parsedpreviewReleaseNotesLabel[0]}
                                <a href="https://aka.ms/STPPrivacyReleaseNotes" style={{ color: '#106ebe', textDecoration: 'underline' }} target="_blank">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPreviewReleaseNotesLinkLabel)}</a>
                                {parsedpreviewReleaseNotesLabel[1]}
                            </p>
                        </div>
                    </div>
                </div>
                <div data-grid="col-12" style={{ paddingTop: 30 }}>
                    <div data-grid="col-3">
                        <SearchBox
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, param: string) => { this.searchFilterTextChanged(param); }}
                        />
                    </div>
                    <div data-grid="col-2" style={{ paddingLeft: 10, paddingTop: 5 }}>
                        <span className="alignleft privacy-portal-label" style={{ padding: 0, margin: 0 }}>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerStatusLabel)} </span>
                        <span className="alignleft privacy-portal-dropdownmenu-wrapper" style={{ paddingLeft: 5}}>
                            <DropdownMenuComponent
                                items={[
                                    {
                                        key: StatusFilter.All.toString(),
                                        name: getStateTextFromState(StatusFilter.All)
                                    },
                                    {
                                        key: StatusFilter.Pending.toString(),
                                        name: getStateTextFromState(StatusFilter.Pending)
                                    },
                                    {
                                        key: StatusFilter.Completed.toString(),
                                        name: getStateTextFromState(StatusFilter.Completed)
                                    },
                                    {
                                        key: StatusFilter.Failed.toString(),
                                        name: getStateTextFromState(StatusFilter.Failed)
                                    },
                                ]}
                                selectedText={getStateTextFromState(this.state.statusFilter)}
                                onChange={(param: string) => { this.filterChangedForStatus(Number(param)); }}
                            />
                        </span>
                    </div>
                    <div data-grid="col-3" style={{ paddingLeft: 10, paddingTop: 5 }}>
                        <span className="alignleft privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerDataRequestedLabel)} </span>
                        <span className="alignleft privacy-portal-dropdownmenu-wrapper" style={{ paddingLeft: 5}}>
                            <DropdownMenuComponent
                                items={[
                                    {
                                        key: PrivacyManagerPage.DateRequestedAllOptionKey,
                                        name: this.getDateRequestedTextFromDateRequestedFilter(PrivacyManagerPage.DateRequestedAllOptionKey)
                                    },
                                    {
                                        key: '30',
                                        name: this.getDateRequestedTextFromDateRequestedFilter('30')
                                    },
                                    {
                                        key: '90',
                                        name: this.getDateRequestedTextFromDateRequestedFilter('90')
                                    },
                                    {
                                        key: '365',
                                        name: this.getDateRequestedTextFromDateRequestedFilter('365')
                                    },
                                ]}
                                selectedText={this.getDateRequestedTextFromDateRequestedFilter(this.state.dateRequestedFilter)}
                                onChange={(param: string) => { this.filterChangedForDateRequested(param); }}
                            />
                        </span>
                    </div>
                    <div data-grid="col-3" />
                </div>
                <div data-grid="col-12">
                    <hr className="c-divider f-pad-top-3x f-pad-bottom-3x" />
                </div>
                <div data-grid="col-12">
                    <div data-grid="col-3">
                        <p className="c-subheading-3" data-grid="col-12">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerDataRequestsLabel)}</p>
                        <p className="c-paragraph-4 privacy-portal-label" data-grid="col-12">
                            {this._getShowingPagesText()}
                        </p>
                    </div>

                    <div data-grid="col-1" className="c-subheading-1 privacy-portal-label privacy-porta-label-bolder" style={{ float: 'left', marginTop: 0, paddingTop: 0 }}>
                        <strong>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerRequestSummary)}</strong>
                    </div>

                    <div data-grid="col-1" style={{ float: 'left' }}>
                        <div className="privacy-portal-vertial-divider" style={{ float: 'left' }} />
                        <div data-grid="col-10" className="c-subheading-6 privacy-portal-label" style={{ float: 'right', textAlign: 'right' }}>
                            {Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridPendingLabel)}
                        </div>
                        <div data-grid="col-10" style={{ float: 'right' }}>
                            <p className="c-subheading-1" style={{ color: '#0078D7', float: 'right', fontWeight: 'bold' }}>{this.props.pendingCount}</p>
                        </div>
                    </div>

                    <div data-grid="col-1" style={{ paddingLeft: 5, float: 'left' }}>
                        <div className="privacy-portal-vertial-divider" style={{ float: 'left' }} />
                        <div data-grid="col-10" className="c-subheading-6 privacy-portal-label" style={{ float: 'right', textAlign: 'right' }}>
                            {Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridCompletedLabel)}
                        </div>
                        <div data-grid="col-10" style={{ float: 'right' }}>
                            <p className="c-subheading-1" style={{ color: '#0078D7', float: 'right', fontWeight: 'bold' }}>{this.props.completedCount}</p>
                        </div>
                    </div>
                    <div data-grid="col-1" style={{ paddingLeft: 5, float: 'left' }}>
                        <div className="privacy-portal-vertial-divider" style={{ float: 'left' }} />
                        <div data-grid="col-10" className="c-subheading-6 privacy-portal-label" style={{ float: 'right', textAlign: 'right' }}>
                            {Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridFailedLabel)}
                        </div>
                        <div data-grid="col-10" style={{ float: 'right' }}>
                            <p className="c-subheading-1" style={{ color: '#0078D7', float: 'right', fontWeight: 'bold' }}>{this.props.failedCount}</p>
                        </div>
                    </div>                    
                    <div data-grid="col-2" />
                    <div data-grid="col-3">
                        <span className="textalignright" data-grid="col-12">
                            <Link
                                style={{ minHeight: 40 }}
                                to="/privacy/ExportDataRequest"
                                className="c-call-to-action c-glyph"
                                aria-label="Create export data request"
                            >
                                <span>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridCreateRequestButton)}</span>
                            </Link>
                        </span>
                    </div>
                </div>
                <div data-grid="col-12">
                    <hr className="c-divider f-pad-top-3x f-pad-bottom-3x" />
                </div>
                <div data-grid="col-12">
                    <div data-grid="col-3">
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridNameLabel)}</p>
                    </div>
                    <div data-grid="col-1">
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridStatusLabel)}</p>
                    </div>
                    <div data-grid="col-1">
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridDateRequestedLabel)}</p>
                    </div>
                    <div data-grid="col-4">
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridContainerLabel)}</p>
                    </div>
                    <div data-grid="col-3">
                        <p className="c-subheading-6 privacy-portal-label">{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridRequestIdLabel)}</p>
                    </div>
                </div>
                <div data-grid="col-12">
                    <hr className="c-divider f-pad-top-3x f-pad-bottom-3x" />
                </div>
                { this.props.isLoading && 
                    <div data-grid="col-12">
                        <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingLabel)} ariaLive="assertive"/>
                    </div>
                }
                {this.getExportRequestItems()}
                {this.getPagination()}
                <Panel
                    isOpen={this.state.showPanel}
                    type={PanelType.smallFixedFar}
                    onDismiss={this._onClosePanel}
                    headerText={Intl.Get(LocIds.GDPRCompliance.PrivacyManagerDetailsPanelHeader)}
                    closeButtonAriaLabel="Close"
                >
                    <div data-grid="col-12">
                        <p>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerDetailsPanelUserLabel)}</p>
                    </div>
                    <div data-grid="col-12">
                        <p>{this.state.showDetailsFor.user.primaryText}</p>
                    </div>
                    <div data-grid="col-12">
                        <p>{this.state.showDetailsFor.user.secondaryText}</p>
                    </div>

                    <div data-grid="col-12" style={{ paddingTop: 30 }}>
                        <p>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridStatusLabel)}</p>
                    </div>
                    <div data-grid="col-12">
                        <p>{this.state.showDetailsFor.privacyOperation.StateText}</p>
                    </div>

                    <div data-grid="col-12" style={{ paddingTop: 30 }}>
                        <p>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridDateRequestedLabel)}</p>
                    </div>
                    <div data-grid="col-12">
                        <p>{this.state.showDetailsFor.privacyOperation.SubmittedTime ? new Date(this.state.showDetailsFor.privacyOperation.SubmittedTime).toLocaleDateString() : ''}</p>
                    </div>
                    <div data-grid="col-12" style={{ paddingTop: 30 }}>
                        <p>{Intl.Get(LocIds.GDPRCompliance.PrivacyManagerGridSizeLabel)}</p>
                    </div>
                   
                </Panel>
            </div>
        );
    }

    private _onShowPanel(showDetails: PrivacyManagerPrivacyOperation): void {
        this.setState({ showPanel: true, showDetailsFor: showDetails });
        if (showDetails.exportedFiles === undefined) {
            api.GetExportedFiles(
                { operationId: showDetails.privacyOperation.id },
                () => {
                    this.setState({ showDetailsFor: { ...showDetails, exportedFiles: [] as ExportedFile[] } });
                },
                (response: GetExportedFilesResponse) => {
                    this.setState({ showDetailsFor: { ...showDetails, exportedFiles: response.data.files } });
                },
                (failed: ErrorResponse) => {
                    // let message: string = failed.code + ' ' + failed.status + ' - ' + failed.message;
                    this.setState({ showDetailsFor: { ...showDetails, exportedFiles: [] as ExportedFile[] } });
                });
        }
    }

    private _onClosePanel(): void {
        this.setState({ showPanel: false });
    }

    private onDismissExportDataSuccessAlert(): void {
        store.dispatch(PrivacyManagerReducer.getExportDataRequestMessageDismissAction());
    }

    private filterChangedForStatus(statusFilter: StatusFilter): void {
        this.setState({ statusFilter: statusFilter });
        this.setCurrentPage(1);
    }

    private filterChangedForDateRequested(dateRequestedFilter: string): void {
        this.setState({ dateRequestedFilter: dateRequestedFilter });
        this.setCurrentPage(1);
    }

    private searchFilterTextChanged(param: string): void {
        this.setState({ searchText: param });
        this.setCurrentPage(1);
    }

    private getDateRequestedTextFromDateRequestedFilter(dateRequested?: string): (string | undefined) {
        if (dateRequested === '30') {
            return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerDateRequestedFilterLast30Days);
        } else if (dateRequested === '90') {
            return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerDateRequestedFilterLast90Days);
        } else if (dateRequested === '365') {
            return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerDateRequestedFilterLast365Days);
        } else if (dateRequested === PrivacyManagerPage.DateRequestedAllOptionKey) {
            return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPrivacyOperationFiltersAllOptionLabel);
        }
        return undefined;
    }

    private _getShowingPagesText(): string {
        let totalPages = this.getLastPage();
        let currentPage = totalPages > 0 ? this.state.pagination.currentPage : 0;
        return Intl.Get(LocIds.GDPRCompliance.PrivacyManagerPaginationShowingLabel, { currentPage: currentPage, totalPages: totalPages });
    }
}