import { store } from '../../Store';
import { MeControlOptions } from '../Models/MeControlOptions';
import { authContext } from './ADAuthenticate';
import { AuthorizationActions, AuthorizationInfo, AuthorizationScope } from './AuthorizationInfo';
import { Intl } from '../../App/Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';

declare const window: any;

export function loadMeControl() {
    const config = {
        meControlContainerId: 'meControl',
        meControlApiGeneration: 'GEN2',
        meControlAuthType: 'webAadOnly'
    };
    
    const meControlContainer = document.getElementById(config.meControlContainerId);
    if (!meControlContainer) {
            throw new Error('Can\'t find meControl element');
        }
    
    var itarEnabled = store.getState().applicationContext.isItarEnabled;

    var serviceTrustControlOptions: MeControlOptions;
    serviceTrustControlOptions = {
            apiGeneration: config.meControlApiGeneration,
            authProviderConfig: {
                type: config.meControlAuthType,
                appSignInUrl: authContext.getNavigateUrlForMeControl(true, itarEnabled),
                appSignOutUrl: authContext.getNavigateUrlForMeControl(false, itarEnabled)
            },
            containerId: config.meControlContainerId
        };

    var appCont = store.getState().applicationContext;

    if (authContext.isSignedIn()) {
        serviceTrustControlOptions.currentAccount =  {
            type: config.meControlAuthType,
            authenticatedState:  'signedIn',
            memberName: appCont.userInfo.principalName,
            firstName: appCont.userInfo.principalName,
            lastName: appCont.userInfo.principalName,
            initials: appCont.userInfo.displayName
        };
    }

        // Call load
    const loadSuccess = window.MeControl.Loader.load(serviceTrustControlOptions);
    if (!loadSuccess) {
            throw new Error('Me Control did not load successfully');
        }
    
        // Clean up callback
    window.onMeControlReadyToLoad = null;
    window.MeControl.API.addEventListener('beforesignoutfromapp', () => {
        let context = store.getState().authentication.context;
        if (context && context.user && context.user.authData) {
            context.logOut();
        }
    });
}

// Decode cookie
export function getCookie(cname: string) {
    var name = cname + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trimLeft();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
export function setInnerHtml(elm: HTMLElement, html: string) {
    elm.innerHTML = html;

    Array.from( elm.querySelectorAll('script')).forEach((oldScriptElm) => {
        const newScriptEl = document.createElement('script');
        newScriptEl.setAttribute('nonce', window.CSPNonce);
        newScriptEl.crossOrigin = 'anonymous';

        Array.from(oldScriptElm.attributes).forEach((attr) => {
            newScriptEl.setAttribute(attr.name, attr.value);
            });

        const scriptText = document.createTextNode(oldScriptElm.innerHTML);
        newScriptEl.appendChild(scriptText);
            
        oldScriptElm.parentNode.replaceChild(newScriptEl, oldScriptElm);
    });
    if (!store.getState().authentication.isSignedIn){
        let meControlElement = document.getElementById('meControl');
        if (meControlElement) {
            meControlElement.title = Intl.Get(LocIds.UHFHeader.GovUserReminder);
        }
    }
}

export function getAdminCode()
{
    if (isAdmin()) { return 'SiteAdmin'; }
    if (isTenantAdmin()) { return 'TenantAdmin'; }
    if (isDocAdmin()) { return 'DocAdmin'; }
    return 'User';
}

function isAdmin() {
    // user has required permissions
    return AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.Pages, [ {actions: [AuthorizationActions.Modify]}])
            && AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.Vocabularies, [ {actions: [AuthorizationActions.Modify]}])
            && AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.Images, [ {actions: [AuthorizationActions.Modify]}])
            && AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.Documents, [ {actions: [AuthorizationActions.Modify]}]);
}

function isTenantAdmin() {
    // user has required permissions
    return AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.ADTenantManagement, [ {actions: [AuthorizationActions.Modify]}])
            && AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.Documents, [ {actions: [AuthorizationActions.Modify]}]);
}

function isDocAdmin() {
    // user has required permissions
    return AuthorizationInfo.isAuthorizedForAny(
        AuthorizationScope.Documents, [ {actions: [AuthorizationActions.Modify]}]);
}