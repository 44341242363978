
import { Dimension } from './Dimension';
import { TileStatus } from './TileStatus';
import { ApprovalStatus } from './ApprovalStatus';
import { DashBoardType } from './DashBoardType';
import { ControlCategory } from '../ComplianceManagerV3/ControlCategory';
import { UpdateNotes } from './UpdateNotes';

export class DashboardSummary {
    public customerManagedActionCount: number;
    public assessedCmActionCount: number;
    public completedCustomerManagedActionCount: number;
    public customerComplianceScore: number;
    public customerComplianceScoreAchieved: number;

    public microsoftManagedActionCount: number;
    public completedMicrosoftManagedActionCount: number;
    public microsoftComplianceScore: number;
    public microsoftComplianceScoreAchieved: number;
}

export class ComplianceDashboardTile {
    public anonymousTenantId: string;
    public id: string;
    public parentTemplateName: string;
    public dashboardType: DashBoardType;
    public tileName: string;
    public groupId: string;
    public groupName: string;
    public dimensionIds: string[];
    public dimensions: Dimension[];
    public whenCreated: Date;
    public whoCreated: string;
    public whenApproved: Date;
    public whoApproved: string;
    public whenPublished: Date;
    public whoPublished: string;
    public whenLastUpdated: Date;
    public customerControls: number;
    public completedCustomerControls: number;
    public microsoftControls: number;
    public completedMicrosoftControls: number;
    public failedControls: number;  // need this to show the progress ring
    public microsoftComplianceScore: number;
    public microsoftComplianceScoreAchieved: number;
    public microsoftComplianceScoreFailed: number;
    public customerComplianceScore: number;
    public customerComplianceScoreAchieved: number;
    public customerComplianceScoreFailed: number;
    public isArchived: boolean;
    public isLocked: boolean;   // used for template tile only.     
    public isFiltered: boolean;
    public status: TileStatus;
    public templateId: string;
    public categories: ControlCategory[];
    public templateTitle: string;
    public assessmentId: string;
    public inScopeServices: string[];
    public importStatus: ApprovalStatus;
    public hasVNext: boolean;
    public previousVersionTemplateId: string;
    public importedFileId: string;
    public hasConflict: boolean;
    public silentUpdate: boolean;
    public showVersioningIcon: boolean;
    public templateUpdateHistory: UpdateNotes[];
    public customerManagedActions: number;
    public microsoftManagedActions: number;
    public completedCustomerManagedActions: number;
    public completedMicrosoftManagedActions: number;
    public assessedCmActionCount: number;
    public groupSummary : DashboardSummary | null;
}