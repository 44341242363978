import { StoreState } from '../../Store';
import { connect } from 'react-redux';
import { PageLayoutModel } from '../Pages/PageLayoutCurrent';
import PageLayoutCurrent from '../Pages/PageLayoutCurrent';
import { PageLayoutProps } from '../Pages/PageLayoutProps';

function mapStateToProps(state: StoreState, props: PageLayoutProps): PageLayoutModel {
    return {
        isNextGenUIEnabled: props.isNextGenUIEnabled === true,
        environmentSettingsStatus: state.environmentSettings.status,
        applicationContextStatus: state.applicationContext.status,
        localizedStringsStatus: state.localizedStrings.status,
        localizedStringsCulture: state.localizedStrings.cultureName,
        applicationContextCulture: state.applicationContext.cultureName,
        applicationContextAppInsightId: state.applicationContext.appInsightsId,
        complianceMgrShowCondition: state.applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement &&
                                state.authentication.isSignedIn,  
        isAnalyticsCookiesConsented: state.cookieConsent.Analytics === true     
    };
}

export default connect(mapStateToProps)(PageLayoutCurrent);
