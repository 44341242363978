export function enableCarousel() {
    // tslint:disable-next-line no-string-literal
    var mwfObject = window['mwf'];
    if (!mwfObject) {
        return;
    }
    mwfObject.ComponentFactory.create([
        {'component': mwfObject.MultiSlideCarousel,
        selector: '.c-carousel'}
    ]);
}