import { IComboBox, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { ComboBox, DefaultButton, IComboBoxOption, PrimaryButton } from 'office-ui-fabric-react/lib/';
import { DirectionalHint, TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { ContentPlacementImagePanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { deleteOnePanelFromCollection, getContentPlacementImageColumn, getSimpleThemeOptions, getThemeClassName, moveSelectedColumn, getContentPlacementImagePanelLayoutOptions, getPanelInsetOptions, getAlignOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { isEmptyString, renderLink } from '../../../App/Utilities/RenderUtilities';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { StandardLonghandProperties } from 'csstype';
import { CSSProperties } from 'react';

import './ContentPlacementImagePanel.css';

export enum panelInset {
    FULL = 'full',
    INSET = 'inset',
}

export enum panelInsetText {
    FULL = 'Full Width Panel',
    INSET = 'Inset Panel',
}

export class ContentPlacementImagePanel extends BasePanel<ContentPlacementImagePanelPropertiesData> {
    private static MAX_COLUMN = 20;
    public themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    public layoutOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.LayoutLabel), itemType: SelectableOptionMenuItemType.Header }];
    public insetOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.InsetLabel), itemType: SelectableOptionMenuItemType.Header }];
    public alignOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.AlignLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<ContentPlacementImagePanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));
        this.layoutOptions = this.layoutOptions.concat(getContentPlacementImagePanelLayoutOptions());
        this.insetOptions = this.insetOptions.concat(getPanelInsetOptions());
        this.alignOptions = this.alignOptions.concat(getAlignOptions());
    }

    // tslint:disable-next-line:no-any
    public routerLink = (props: any) => {
        const target = props.href.match(/^(https?:)?\/\//) ? '_blank' : '_self';
        return <Link target={target} className="c-hyperlink" to={props.href}>{props.children}</Link>;
    }

    private getActualPanel = (): JSX.Element | undefined => {

        const { panelHeading, panelSubheading, panelCallToActionLabel, panelCallToActionUrl, columns, layout, inset } = this.state.panelData;
        const numberOfColumns = columns ? columns.length : 0;
        const insetPanel: boolean = inset === panelInset.INSET;

        let styleProps: CSSProperties = {};
        if (this.state.panelData.alignment) {
            styleProps = {textAlign: (this.state.panelData as StandardLonghandProperties<string | number>).alignment};
        }

        return (
            <div
                className={getThemeClassName(Theme[this.state.panelData.theme || 'None']) + ' content-placement-image-panel'}
                data-grid="col-12 pad-6x stack-3"
                data-panel-type="Content Placement Image"
            >
                {/* these two were lifted from AreaHeadingPanel and may be missing some styles scoped to m-area-heading */}
                {panelHeading && (
                <div data-grid="container">
                    {insetPanel && 
                        <div data-grid="col-1"/>
                    }
                    <div
                        className="m-banner"
                        data-grid={insetPanel ? 'col-10' : 'col-12'}
                    >
                        <h2 className="clearfix">{getLocalizedString(panelHeading)}</h2>
                        {panelSubheading &&
                            <ReactMarkdown
                                renderers={{link: this.routerLink}}
                                className="c-paragraph-2"
                                source={getLocalizedString(panelSubheading)}
                                skipHtml={true}
                            />
                        }
                    </div>
                    {insetPanel && 
                        <div data-grid="col-1"/>
                    }
                </div>
                )}

                <div data-grid="container">
                    {insetPanel && 
                        <div data-grid="col-1"/>
                    }
                    <div 
                        data-grid={insetPanel ? 'col-10' : 'col-12'} 
                        className="stp_content-placement-image-panel_items"
                    >
                        {this.state.panelData.columns ?
                            this.state.panelData.columns.map((data, index) =>
                                <div
                                    className="content-placement-image-item"
                                    key={data.toString().concat(index.toString())}
                                    data-grid={layout || 'col-3'}
                                    onClick={() => { if (this.state.showEditor && this.panelValidator.validate()) { this.setState({ selectedIndex: index }); } }}
                                    style={this.state.selectedIndex === index && this.state.showEditor ? { border: 'solid 1px #778899' } : {}}
                                >
                                    <section className="m-banner m-banner-image" style={styleProps}>
                                        {data.imageId && !data.imageId.includes('Missing-Image.png') && (
                                            <picture className="c-image">
                                                <img
                                                    className="lazyloaded"
                                                    src={imageHelper.getImageUrl(data.imageId)}
                                                    data-src={imageHelper.getImageUrl(data.imageId)}
                                                    alt={getLocalizedString(data.heading)}
                                                />
                                            </picture>
                                        )}

                                        <TooltipHost
                                            content={getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            calloutProps={{ isBeakVisible: false, gapSpace: 0 }}
                                            tooltipProps={{ directionalHint: DirectionalHint.topCenter }}
                                        >
                                            <div className="c-heading-5 f-lean">
                                                {/* if there's a URL then render as a link, otherwise just show the label in the <div> */}
                                                {!isEmptyString(data.headingUrl)
                                                 ?
                                                 renderLink(data.headingUrl, getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder))
                                                 :
                                                 (getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder))
                                                }
                                            </div>
                                            {!isEmptyString(getLocalizedString(data.description)) && (
                                                <p className="c-paragraph-3">{getLocalizedString(data.description)}</p>
                                            )}
                                        </TooltipHost>
                                        {!isEmptyString(data.callToActionUrl) && !!data.callToActionLabel && !isEmptyString(getLocalizedString(data.callToActionLabel)) && (
                                            renderLink(data.callToActionUrl, getLocalizedString(data.callToActionLabel), 'c-call-to-action c-glyph f-lightweight')
                                        )}
                                    </section>
                                </div>
                            )
                            :
                            null
                        }
                    </div>
                    {insetPanel && 
                        <div data-grid="col-1"/>
                    }
                </div>

                {!isEmptyString(getLocalizedString(panelCallToActionLabel)) && (
                <div data-grid="container">
                    {insetPanel && 
                        <div data-grid="col-1"/>
                    }
                    <div
                        className="m-banner"
                        data-grid={insetPanel ? 'col-10' : 'col-12'}
                        style={{ textAlign: 'center', paddingTop: '22px' }}
                    >
                        {renderLink(panelCallToActionUrl, getLocalizedString(panelCallToActionLabel), 'c-call-to-action c-glyph f-lightweight')}
                    </div>
                    {insetPanel && 
                        <div data-grid="col-1"/>
                    }
                </div>
                )}
            </div>
        );
    }

    public onAddColumn = () => {
        if (!this.state.panelData.columns || (this.state.panelData.columns.length >= ContentPlacementImagePanel.MAX_COLUMN || !this.panelValidator.validate())) {
            return;
        }
        this.state.panelData.columns.push(getContentPlacementImageColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    private onDeleteColumn = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            const panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    private onMoveLeft = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    private onMoveRight = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    public render() {
        const { columns } = this.state.panelData;
        const numberOfColumns = columns ? columns.length : 0;

        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: 0 }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor && this.state.panelData.columns ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12">
                                        <label className="ms-Label x-hidden-focus">Image</label>
                                        <p>
                                            <small>
                                                <strong>Please use an image that is 400 x 400px</strong><br />
                                                This image will be shown centered at the top of the column.
                                            </small>
                                        </p>
                                    </div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={false}
                                                disabled={true}
                                                value={this.state.panelData.columns ? this.state.panelData.columns[this.state.selectedIndex].imageTitle : ''}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length && this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                        this.state.panelData.columns[this.state.selectedIndex].imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal.substr(0, Constants.PANEL_HEADING_MAX));
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns[this.state.selectedIndex].headingUrl}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].headingUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].description)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'description', newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX));
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            />
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'callToActionLabel', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns[this.state.selectedIndex].callToActionUrl ? this.state.panelData.columns[this.state.selectedIndex].callToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />

                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                text={this.state.panelData.theme || 'None'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.setState({ panelData: { ...this.state.panelData, theme: item.key.toString() }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                   <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.layout === 'col-3' ? '4 Column' : '3 Column'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectLayoutLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectLayoutLabel)}
                                                text={this.state.panelData.layout === 'col-3' ? '4 Column' : '3 Column'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.layoutOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.setState({ panelData: { ...this.state.panelData, layout: item.key.toString() }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                   <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.inset === panelInset.INSET ? panelInsetText.INSET : panelInsetText.FULL}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectInsetLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectInsetLabel)}
                                                text={this.state.panelData.inset === panelInset.INSET ? panelInsetText.INSET : panelInsetText.FULL}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.insetOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.setState({ panelData: { ...this.state.panelData, inset: item.key as string }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                   <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.alignment || 'None'}
                                                label={'Text Align:'}
                                                ariaLabel={'Text Align'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.alignOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.alignment = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelHeading ? getLocalizedString(this.state.panelData.panelHeading) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>,  newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelHeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />

                                            <MarkDownEditor
                                                value={this.state.panelData.panelSubheading ? getLocalizedString(this.state.panelData.panelSubheading) : ''}
                                                onChange={(newVal) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelSubheading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelSubHeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelCallToActionLabel ? getLocalizedString(this.state.panelData.panelCallToActionLabel) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelCallToActionLabel', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelCallToActionUrl ? this.state.panelData.panelCallToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal) => {
                                                    this.state.panelData.panelCallToActionUrl = newVal;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddColumnLabel)}
                                        disabled={numberOfColumns >= ContentPlacementImagePanel.MAX_COLUMN}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeleteColumnLabel)}
                                        disabled={numberOfColumns <= 1}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}