import { AxiosResponse } from 'axios';
import { ErrorResponse } from './Models/ErrorResponse';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';

export interface NavigateToOptions {
    url: string;
    tags?: string[];
    redirect?: boolean;
}

export class ExternalNavigationService {

    navigateTo(
        options: NavigateToOptions
    ): void {
        var api: string = '/api/TrackExternalNavigation';
        ServiceTrustClient.doRequest_New(
            'post', api, {
                urlReferrer: window.location.href,
                url: options.url,
                tags: options.tags
            },
            () => null,
            (response: AxiosResponse) => {
                if (options.redirect) { window.location.href = options.url; }
            },
            (response: ErrorResponse) => {
                if (options.redirect) { window.location.href = options.url; }
            }
        );
    }
}
