import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PivotViewsCollection } from './PivotViewsCollection';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { getDefaultView } from '../../Utilities/PageInfrastructure/ViewsHelper';
import { ViewData, PanelData, ViewPanelPropertiesData } from '../../Models/PageInfrastructure';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { Validator } from '../../../Common/Utilities/Validator';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';

interface ViewsPanelState {
    showEditor: boolean;
    panelData: PanelData<ViewPanelPropertiesData>;
    selectedView: ViewData;
    modifiedViewName: string;
    modifiedViewTitle: string;
    modifiedViewDescription: string;
}

export class ViewsPanel extends React.Component<BasicPanelProperties<ViewPanelPropertiesData>, ViewsPanelState> {
    private backup: PanelData<ViewPanelPropertiesData>;
    private panelValidator: Validator = new Validator();

    constructor(props: BasicPanelProperties<ViewPanelPropertiesData>) {
        super(props);
        this.backup = getDeepCopy(this.props.panel);
        this.setDefaultSelected(this.props.panel);
        this.state = {
            showEditor: false,
            panelData: this.props.panel,
            selectedView: this.props.panel.views[this.props.panel.views.findIndex(x => x.isSelected)],
            modifiedViewName: '',
            modifiedViewTitle: '',
            modifiedViewDescription: ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: BasicPanelProperties<ViewPanelPropertiesData>) {
        this.setDefaultSelected(nextProps.panel);
        this.setState({
            showEditor: false,
            panelData: nextProps.panel,
            selectedView: nextProps.panel.views[nextProps.panel.views.findIndex(x => x.isSelected)]
        });
        this.backup = getDeepCopy(nextProps.panel);
    }

    setDefaultSelected(panel: PanelData<ViewPanelPropertiesData>) {
        if (panel.views.length > 0 && panel.views.findIndex(x => x.isSelected) === -1) {
            panel.views.forEach((v) => v.isSelected = false);
            panel.views[0].isSelected = true;
        }
    }

    saveModifiedViewData() {
        if (!this.panelValidator.validate()) {
            return;
        }
        setDefaultLocalizedString(this.state.selectedView, 'name', this.state.modifiedViewName);
        setDefaultLocalizedString(this.state.selectedView, 'title', this.state.modifiedViewTitle);
        setDefaultLocalizedString(this.state.selectedView, 'description', this.state.modifiedViewDescription);
        this.setState({ showEditor: false });
        this.props.makeDirty();
    }

    showViewDataModal() {
        if (this.state.selectedView) {
            this.setState({ 
                showEditor: true, 
                modifiedViewName: getLocalizedString(this.state.selectedView.name),
                modifiedViewTitle: getLocalizedString(this.state.selectedView.title),
                modifiedViewDescription: getLocalizedString(this.state.selectedView.description)
            });
        }
    }

    dismissModal() {
        this.setState({ showEditor: false });
    }

    onAddNewView() {
        let newView: ViewData = getDefaultView();
        this.state.panelData.views.forEach(x => x.isSelected = false);
        this.state.panelData.views.push(newView);
        newView.isSelected = true;
        this.setState({ selectedView: newView });
        this.props.makeDirty();
    }

    onModifyView(index: number) {
        let selectedView: ViewData = this.state.panelData.views[index];
        if (selectedView) {
            this.setState({ selectedView: selectedView }, () => {
                this.showViewDataModal();
            });
        }
    }

    onMoveViewLeft(index: number) {
        if (index > 0) {
            this.swapViews(index, index - 1);
            this.props.makeDirty();
        }

    }

    onMoveViewRight(index: number) {
        if (index < this.state.panelData.views.length - 1) {
            this.swapViews(index, index + 1);
            this.props.makeDirty();
        }

    }

    swapViews(index1: number, index2: number) {
        let temp = this.state.panelData.views[index1];
        this.state.panelData.views[index1] = this.state.panelData.views[index2];
        this.state.panelData.views[index2] = temp;
    }

    onDeleteView(index: number) {
        let views = this.state.panelData.views;
        this.state.panelData.views = [];
        views.forEach((x, i) => {
            if (i !== index) {
                this.state.panelData.views.push(x);
            }
        });
        this.props.makeDirty();
    }

    render(): JSX.Element {
        return (
            <div>
                <PivotViewsCollection
                    views={this.state.panelData.views}
                    isInEditMode={this.props.isInEditMode}
                    addNewView={() => this.onAddNewView()}
                    modifySelectedView={(index: number) => this.onModifyView(index)}
                    moveSelectedViewLeft={(index: number) => this.onMoveViewLeft(index)}
                    moveSelectedViewRight={(index: number) => this.onMoveViewRight(index)}
                    deleteSelectedView={(index: number) => this.onDeleteView(index)}
                    makeDirty={() => this.props.makeDirty()}
                    moveUp={() => this.props.moveUp(this.state.panelData)}
                    moveDown={() => this.props.moveDown(this.state.panelData)}
                    delete={() => this.props.delete(this.state.panelData)}
                    idOfPage={this.props.idOfPage}
                    parentId={this.props.panel.id}
                />
                <Dialog
                    hidden={!this.state.showEditor}
                    onDismiss={() => this.dismissModal()}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: Intl.Get(LocIds.PageAndPanels.SelectViewTitle),
                        subText: Intl.Get(LocIds.PageAndPanels.SelectViewDescription)
                    }}
                    modalProps={{
                        titleAriaId: Intl.Get(LocIds.PageAndPanels.SelectViewTitle),
                        subtitleAriaId: Intl.Get(LocIds.PageAndPanels.SelectViewDescription),
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <div>
                        <TextFieldWrapper 
                            label={Intl.Get(LocIds.PageAndPanels.TitleLabel)} 
                            value={this.state.modifiedViewName} 
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                if (newVal.length > Constants.PANEL_TITLE_MAX) {
                                    newVal = newVal.substr(0, Constants.PANEL_TITLE_MAX);
                                }
                                this.setState({modifiedViewName: newVal});
                            }}
                            required={true}
                            validator={this.panelValidator}
                            placeholder={Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)} 
                        />
                        <div style={{ marginBottom: '6px' }} />
                        <TextField 
                            label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)} 
                            value={this.state.modifiedViewTitle} 
                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                    newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                }
                                this.setState({modifiedViewTitle: newVal});
                            }}
                            placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                        />
                        <div style={{ marginBottom: '6px' }} />
                        <MarkDownEditor
                            showCommandButton={false}
                            value={this.state.modifiedViewDescription}
                            onChange={(newVal) => {
                                this.setState({modifiedViewDescription: newVal});
                            }}
                            label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                            placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                            maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                            required={false}
                            validator={this.panelValidator}
                        />                             
                    </div>
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.saveModifiedViewData()}>Save</PrimaryButton>
                        <DefaultButton onClick={() => this.dismissModal()}>Undo Changes</DefaultButton>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}