import { Reducer } from 'redux';
import { GdprIncident } from '../Models/index';
import { Status } from './GdprBreachNotification';

const BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_REQUESTING = 'BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_REQUESTING';
const BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_SUCCEED = 'BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_SUCCEED';
const BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_ERROR = 'BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_ERROR';
const BREACH_NOTIFICATION_HISTORY_EXIT_VIEW_PANEL = 'BREACH_NOTIFICATION_HISTORY_EXIT_VIEW_PANEL';
const BREACH_NOTIFICATION_HISTORY_SWITCH_TO_VIEW_PANEL = 'BREACH_NOTIFICATION_HISTORY_SWITCH_TO_VIEW_PANEL';

export interface BreachNotificationHistoryState {
    status: Status;
    marker: string | null;
    incidents: GdprIncident[];
    view: {
        index: number;
    } | null;
    isExit: boolean;
}

const unloadedState: BreachNotificationHistoryState = {
    status: 'Init',
    marker: null,
    incidents: [],
    view: null,
    isExit: false,
} as BreachNotificationHistoryState;

interface BreachNotificationListHistoryRecordsRequesting {
    type: typeof BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_REQUESTING;
}

export function ListHistoryRecords(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_REQUESTING,
    };
}

interface BreachNotificationListHistoryRecordsSucceed {
    type: typeof BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_SUCCEED;
    incidents: GdprIncident[];
    marker: string;
    isMore: boolean;
}

export function ListHistoryRecordsSucceed(incidents: GdprIncident[], marker: string, isMore: boolean): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_SUCCEED,
        marker: marker,
        incidents: incidents,
        isMore: isMore
    };
}

interface BreachNotificationListHistoryRecordsError {
    type: typeof BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_ERROR;
    message: string;
}

export function ListHistoryRecordsError(message: string): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_ERROR,
        message: message
    };
}

interface BreachNotificationHistoryExitViewPanel {
    type: typeof BREACH_NOTIFICATION_HISTORY_EXIT_VIEW_PANEL;
}

export function exitPanel(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_HISTORY_EXIT_VIEW_PANEL,
    };
}

interface BreachNotificationHistorySwitchToViewPanel {
    type: typeof BREACH_NOTIFICATION_HISTORY_SWITCH_TO_VIEW_PANEL;
    index: number;
}

export function switchToViewPanel(index: number): BreachNotificationHistorySwitchToViewPanel {
    return {
        type: BREACH_NOTIFICATION_HISTORY_SWITCH_TO_VIEW_PANEL,
        index: index
    };
}

export type BreachNotificationActions = BreachNotificationListHistoryRecordsError |
    BreachNotificationListHistoryRecordsSucceed |
    BreachNotificationHistoryExitViewPanel |
    BreachNotificationHistorySwitchToViewPanel |
    BreachNotificationListHistoryRecordsRequesting
    ;

export const reducer: Reducer<BreachNotificationHistoryState> = (state: BreachNotificationHistoryState, action: BreachNotificationActions) => {
    let next = state ? JSON.parse(JSON.stringify(state)) : unloadedState;
    switch (action.type) {
        case BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_REQUESTING:
            next.status = 'Requesting';
            return next;

        case BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_SUCCEED:
            next.status = 'Success';
            next.marker = action.marker;
            if (action.isMore === true) {
                next.incidents = next.incident.concat(action.incidents);
            } else { 
                next.incidents = action.incidents;
            }
            return next;

        case BREACH_NOTIFICATION_LIST_HISTORY_RECORDS_ERROR:
            next.status = 'Error';
            next.message = action.message;
            return next;

        case BREACH_NOTIFICATION_HISTORY_EXIT_VIEW_PANEL:
            next.view = null;
            return next;

        case BREACH_NOTIFICATION_HISTORY_SWITCH_TO_VIEW_PANEL:
            next.view = {
                index: action.index
            };
            return next;

        default:
    }

    return state || unloadedState;
};