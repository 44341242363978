import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DrawerViewsCollection } from './DrawerViewsCollection';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { getDefaultView } from '../../Utilities/PageInfrastructure/ViewsHelper';
import { ViewData, PanelData, ViewPanelPropertiesData } from '../../Models/PageInfrastructure';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import Constants from '../../Utilities/Constants';

interface DrawerPanelState {
    showEditor: boolean;
    panelData: PanelData<ViewPanelPropertiesData>;
    selectedView: ViewData;
    modifiedViewName: string;
}

export class DrawerPanel extends React.Component<BasicPanelProperties<ViewPanelPropertiesData>, DrawerPanelState> {
    private backup: PanelData<ViewPanelPropertiesData>;

    constructor(props: BasicPanelProperties<ViewPanelPropertiesData>) {
        super(props);
        this.backup = getDeepCopy(this.props.panel);
        this.setDefaultSelected(this.props.panel);
        let selectedView = this.props.panel.views[this.props.panel.views.findIndex(x => x.isSelected)];
        this.state = {
            showEditor: false,
            panelData: this.props.panel,
            selectedView: selectedView,
            modifiedViewName: selectedView && selectedView.name ? getLocalizedString(selectedView.name) : ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: BasicPanelProperties<ViewPanelPropertiesData>) {
        this.setDefaultSelected(nextProps.panel);
        this.setState({
            showEditor: false,
            panelData: nextProps.panel,
            selectedView: nextProps.panel.views[nextProps.panel.views.findIndex(x => x.isSelected)]
        });
        this.backup = getDeepCopy(nextProps.panel);
    }

    setDefaultSelected(panel: PanelData<ViewPanelPropertiesData>) {
        if (panel.views.length > 0 && panel.views.findIndex(x => x.isSelected) === -1) {
            panel.views.forEach((v) => v.isSelected = false);
            panel.views[0].isSelected = true;
        }
    }

    saveModifiedViewData() {
        setDefaultLocalizedString(this.state.selectedView, 'name', this.state.modifiedViewName ? this.state.modifiedViewName : '');
        this.setState({ showEditor: false });
        this.props.makeDirty();
    }

    showViewDataModal() {
        this.resetModifiedViewData();
        this.setState({ showEditor: true });
    }

    resetModifiedViewData() {
        this.setState({
            modifiedViewName : this.state.selectedView ? getLocalizedString(this.state.selectedView.name) : ''
        });
    }

    dismissModal() {
        this.resetModifiedViewData();
        this.setState({ showEditor: false });
    }

    onAddNewView() {
        let newView: ViewData = getDefaultView();
        newView.widthInGridUnits = 2;

        this.state.panelData.views.forEach(x => x.isSelected = false);
        this.state.panelData.views.push(newView);
        newView.isSelected = true;
        this.setState({ selectedView: newView });
        this.props.makeDirty();
    }

    onModifyView(index: number) {
        let selectedView: ViewData = this.state.panelData.views[index];
        if (selectedView) {
            this.setState({ selectedView: selectedView }, () => {
                this.showViewDataModal();
            });
        }
    }

    onMoveViewLeft(index: number) {
        if (index > 0) {
            this.swapViews(index, index - 1);
            this.props.makeDirty();
        }

    }

    onMoveViewRight(index: number) {
        if (index < this.state.panelData.views.length - 1) {
            this.swapViews(index, index + 1);
            this.props.makeDirty();
        }

    }

    swapViews(index1: number, index2: number) {
        let temp = this.state.panelData.views[index1];
        this.state.panelData.views[index1] = this.state.panelData.views[index2];
        this.state.panelData.views[index2] = temp;
    }

    onDeleteView(index: number) {
        let views = this.state.panelData.views;
        if (index >= views.length) {
            return;
        }
        var deleteView = views[index];
        this.state.panelData.views = [];
        views.forEach((x, i) => {
            x.widthInGridUnits = x.widthInGridUnits || 0;
            if (i === (index - 1)) {
                x.widthInGridUnits += deleteView && deleteView.widthInGridUnits ? deleteView.widthInGridUnits : 0;
            }
            if (i !== index) {
                this.state.panelData.views.push(x);
            }
        });
        this.props.makeDirty();
    }

    render(): JSX.Element {
        return (
            <div>
                <DrawerViewsCollection
                    views={this.state.panelData.views}
                    isInEditMode={this.props.isInEditMode}
                    addNewView={() => this.onAddNewView()}
                    modifySelectedView={(index: number) => this.onModifyView(index)}
                    moveSelectedViewLeft={(index: number) => this.onMoveViewLeft(index)}
                    moveSelectedViewRight={(index: number) => this.onMoveViewRight(index)}
                    deleteSelectedView={(index: number) => this.onDeleteView(index)}
                    makeDirty={() => this.props.makeDirty()}
                    moveUp={() => this.props.moveUp(this.state.panelData)}
                    moveDown={() => this.props.moveDown(this.state.panelData)}
                    delete={() => this.props.delete(this.state.panelData)}
                    idOfPage={this.props.idOfPage}
                    parentId={this.props.panel.id}
                />
                <Dialog
                    hidden={!this.state.showEditor}
                    onDismiss={() => this.dismissModal()}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: Intl.Get(LocIds.PageAndPanels.ModifySelectViewTitle),
                    }}
                    modalProps={{
                        titleAriaId: Intl.Get(LocIds.PageAndPanels.ModifySelectViewTitle),
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                >
                    <div>
                    <TextField 
                        label={Intl.Get(LocIds.PageAndPanels.NameLabel)} 
                        value={this.state.modifiedViewName ? this.state.modifiedViewName : ''} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                            if (newVal && newVal.length > Constants.PANEL_TITLE_MAX) {
                                newVal = newVal.substr(0, Constants.PANEL_TITLE_MAX);
                            }
                            this.setState({modifiedViewName: newVal ? newVal : ''});
                        }}
                        placeholder={Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}
                    />                     
                    </div>
                    <DialogFooter>
                        <PrimaryButton disabled={!this.state.modifiedViewName || this.state.modifiedViewName.length <= 0} onClick={() => this.saveModifiedViewData()}>{Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}</PrimaryButton>
                        <DefaultButton onClick={() => this.dismissModal()}>{Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}</DefaultButton>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}