import { store } from '../../Store';
import { CloudInstance } from './ApplicationContext';

export function getAzureResourcesCloudInstance() {
    let applicationContext = store.getState().applicationContext;
    return applicationContext && applicationContext.azureResourcesCloudInstance ? applicationContext.azureResourcesCloudInstance : CloudInstance.Public;
}

export function isSovereignCloudInstance() {
    let cloudInstance =  getAzureResourcesCloudInstance();
    return cloudInstance === CloudInstance.BlackForest || cloudInstance === CloudInstance.Gallatin || cloudInstance === CloudInstance.ITAR;
}

// Blackforest does not show the feedback div and button
export function shouldShowFeedback() {
    return getAzureResourcesCloudInstance() !== CloudInstance.BlackForest;
}