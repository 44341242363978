// Declared constanst ,so it can be used in other methods within service to update/delete/add
export let MockDocuments: any = require("../../Mock/Json/AddTemplateData.json");
export let validationResponse: any = require("../../Mock/Json/ValidateAddTemplate.json")

export class MockAddTemplateData {
  // TBD - Add other crud operations based on the need

  getDocuments(): any {
    return MockDocuments;
  }

  validateTemplate(): any {
    return validationResponse;
  }

  postTempData(): any {
    return true;
  }
}

export let MockAddTemplateDataClient = new MockAddTemplateData();
