import { ViewData } from '../../Models/PageInfrastructure';
import { makeLocalizedString } from '../../../Common/Utilities/LocalizationHelper';

export const defaultViewTitle: string = '';
export const defaultViewName: string = '';
export const defaultViewDescription: string = '';

export function getDefaultView(): ViewData {
    let newView: ViewData = {
        isSelected: false,
        name: makeLocalizedString(defaultViewName),
        title: makeLocalizedString(defaultViewTitle),
        description: makeLocalizedString(defaultViewDescription),
        panels: []
    };
    return newView;
}