export default class NonlocalizableStringConstants {
    static STANDARD_BUTTONS_EDIT: string = 'Edit';
    static STANDARD_BUTTONS_SAVE: string = 'Save';
    static STANDARD_BUTTONS_UNDO: string = 'Undo';
    static STANDARD_BUTTONS_CLOSE: string = 'Close';
    static STANDARD_BUTTONS_ADD: string = 'Add';
    static STANDARD_BUTTONS_REMOVE: string = 'Remove';
    static STANDARD_BUTTONS_DELETE: string = 'Delete';
    static STANDARD_BUTTONS_RESTORE: string = 'Restore';
    static STANDARD_BUTTONS_HISTORY: string = 'History';
    static STANDARD_BUTTONS_COPY: string = 'Copy';
    static STANDARD_BUTTONS_EXIT: string = 'Exit';
    static STANDARD_BUTTONS_RETURN: string = 'Return';
    static STANDARD_BUTTONS_MOVEUP: string = 'Move Up';
    static STANDARD_BUTTONS_MOVEDOWN: string = 'Move Down';

    static STANDARD_BUTTONS_ADDNEWPAGE: string = 'Add New Page';
    static STANDARD_BUTTONS_DUPLICATEPAGE: string = 'Duplicate Page';
    static STANDARD_BUTTONS_SENDFORAPPROVAL: string = 'Send For Approval';
    static STANDARD_BUTTONS_APPROVEPUBLISH: string = 'Approve Publish';
    static STANDARD_BUTTONS_APPROVEDELETION: string = 'Approve Deletion';
    static STANDARD_BUTTONS_REJECTPUBLISH: string = 'Reject Publish';
    static STANDARD_BUTTONS_REJECTDELETION: string = 'Reject Deletion';
    static STANDARD_BUTTONS_FORCEPUBLISH: string = 'Force Publish';

    static STANDARD_BUTTONS_TOGGLE_YES: string = 'Yes';
    static STANDARD_BUTTONS_TOGGLE_NO: string = 'No';

    static STANDARD_BUTTONS_TAGGING: string = 'Tagging';

}