import { connect } from 'react-redux';
import { LibraryNotificationPanel, LibraryNotificationPanelRedux } from '../Components/PageInfrastructure/LibraryNotificationPanel';
import { StoreState } from '../../Store';
import { BasicPanelProperties } from '../Utilities/PageInfrastructure/EditablePanelHelper';
import { LibraryNotificationPanelPropertiesData } from '../Models/PageInfrastructure';

// Define how application-specific global state maps to the component's public properties.
export function mapStateToProps(state: StoreState, ownProps: BasicPanelProperties<LibraryNotificationPanelPropertiesData>): LibraryNotificationPanelRedux {
    const {
        authentication: { isSignedIn },
        notificationSummary: { loaded, totalDocumentsUpdated, totalDocumentsSubscribed },
    } = state;

    return {
        isSignedIn,
        loaded,
        totalDocumentsSubscribed,
        totalDocumentsUpdated
    };
}

export default connect(mapStateToProps)(LibraryNotificationPanel);
