// Declared constanst ,so it can be used in other methods within service to update/delete/add
export let MockDocuments: any = require("../../Mock/Json/AddTemplateData.json");

export class MockAddCustomControlData {
  // TBD - Add other crud operations based on the need

  postCustomControlData(param: any, data: any): any {
    return data;
  }

  postCustmomeControlFilterData(param: any, data: any): any {
    return data;
  }
}

export let MockCustomControlServicesClient = new MockAddCustomControlData();
