// Declared constanst ,so it can be used in other methods within service to update/delete/add
export let MockDocuments: any = require('../Json/MyLibraryDocuments.json');

//Declared constanst, so it can be used in other methods within service to update/delete/add
export const MockSummary: any = {
	"totalDocumentsUpdated": 3,
	"noOfDays":30,
	"totalDocsSubscribed": "",
};


//Declared constanst, so it can be used in other methods within service to update/delete/add
export const MockSettings: any = {
	"notificationOn": false,
	"emails": [
		"test@microsoft.com" // Future scope
	],
	"interval": 2, //1- daily, 2- weekly, 3-monthly
}


export class MockNotificationServices {

    // TBD - Add other crud operations based on the need
    getSummary(): any {
        return MockSummary;
    }

    getDocuments(): any {
        return MockDocuments;
    }

    getSettings(): any {
        return MockSettings;
    }

    postSettings(): any {
        return true;
    }

    postDocument(): any {
        return true;
    }

    deleteDocument(): any {
        return true;
    }
}

export let MockNotificationServicesClient = new MockNotificationServices();