import { LocalizedValue, LocalizedString } from '../../../Common/Models';

export interface VocabularyValueDefinition {
    id: string;
    displayValues: LocalizedValue[];
    displayText: LocalizedString;
    useToRestrictContentToCertainCustomers: boolean;
    valueType: string;
}

export enum ValueType { UsedInBothUI, UsedInOldUI, UsedInNewUI }

export function isVocabularyShown(isNewUI: boolean, value: VocabularyValueDefinition) {
    return ValueType[value.valueType] == ValueType.UsedInBothUI || 
        (isNewUI && ValueType[value.valueType] == ValueType.UsedInNewUI) || 
        (!isNewUI && ValueType[value.valueType] == ValueType.UsedInOldUI);
}