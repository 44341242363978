import { Reducer } from 'redux';
import { ExportedFile, PrivacyOperation } from '../../Models/PrivacyPortal/index';

type Status = 'Init' | 'Requesting' | 'Finished' | 'Success' | 'Error' | 'Loaded' | 'Loading';

export interface ExportedFilesState {
    status: Status;
    exportedFiles: ExportedFile[];
    privacyOperation: PrivacyOperation;
    message?: string;
}
// 
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ExportedFilesRequesting {
    type: 'PRIVACY_PORTAL_EXPORTED_FILES_REQUESTING';
}

interface ExportedFilesRequestSucceeded {
    type: 'PRIVACY_PORTAL_EXPORTED_FILES_SUCCEEDED';
    exportedFiles: ExportedFile[];
    message: string;
    privacyOperation: PrivacyOperation;
}

interface ExportedFilesRequestFailed {
    type: 'PRIVACY_PORTAL_EXPORTED_FILES_ERROR';
    message: string;
}

export type ExportedFilesActions = ExportedFilesRequesting | ExportedFilesRequestSucceeded | ExportedFilesRequestFailed;

export function getExportedFilesRequestingAction(): ExportedFilesActions {
    return {
        type: 'PRIVACY_PORTAL_EXPORTED_FILES_REQUESTING',
    };
}

export function getExportedFilesRequestSucceededAction(message: string, files: ExportedFile[], privacyOperation: PrivacyOperation): ExportedFilesActions {
    return {
        type: 'PRIVACY_PORTAL_EXPORTED_FILES_SUCCEEDED',
        message: message,
        exportedFiles: files,
        privacyOperation: privacyOperation
    };
}

// tslint:disable-next-line:no-any
export function getExportedFilesRequestErrorAction(message: string): ExportedFilesActions {
    return {
        type: 'PRIVACY_PORTAL_EXPORTED_FILES_ERROR',
        message: message,
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ExportedFilesState> = (state: ExportedFilesState, action: ExportedFilesActions) => {
    let newState: ExportedFilesState;
    switch (action.type) {
        case 'PRIVACY_PORTAL_EXPORTED_FILES_REQUESTING':
            newState = { ...state, status: 'Requesting' };
            break;

        case 'PRIVACY_PORTAL_EXPORTED_FILES_SUCCEEDED':
            newState = {
                ...state,
                status: 'Success',
                message: action.message,
                exportedFiles: action.exportedFiles,
                privacyOperation: action.privacyOperation
            };
            break;

        case 'PRIVACY_PORTAL_EXPORTED_FILES_ERROR':
            newState = {
                ...state,
                status: 'Error',
                exportedFiles: [] as ExportedFile[],
                message: action.message,
            };
            break;

        default:
            newState = (state === undefined)
                ? {
                    status: 'Init',
                    exportedFiles: [] as ExportedFile[],
                    message: '',
                    privacyOperation: {} as PrivacyOperation
                }
                : state;
    }
    return newState;
};
