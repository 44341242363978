import { Reducer } from 'redux';
import { ErrorInfo } from '../Models/ErrorInfo';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

// Define the strings that will be used when dispatching the actions that affect this model.
export const SHOW_BANNER = 'SHOW_BANNER';
export type SHOW_BANNER = typeof SHOW_BANNER;
export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export type ERROR_OCCURRED = typeof ERROR_OCCURRED;
export const ERROR_OCCURRED_WITH_CUSTOM_MESSAGE = 'ERROR_OCCURRED_WITH_CUSTOM_MESSAGE';
export type ERROR_OCCURRED_WITH_CUSTOM_MESSAGE = typeof ERROR_OCCURRED_WITH_CUSTOM_MESSAGE;
export const CUSTOM_EXCEPTION_OCCURRED = 'CUSTOM_EXCEPTION_OCCURRED';
export type CUSTOM_EXCEPTION_OCCURRED = typeof CUSTOM_EXCEPTION_OCCURRED;
export const CLEAR_BANNER = 'CLEAR_BANNER';
export type CLEAR_BANNER = typeof CLEAR_BANNER;
export const CLEAR = 'CLEAR';
export type CLEAR = typeof CLEAR;
export const CLEAR_ALL = 'CLEAR_ALL';
export type CLEAR_ALL = typeof CLEAR_ALL;
export const SHOW_ERROR = 'SHOW_ERROR';
export type SHOW_ERROR = typeof SHOW_ERROR;

// Name of the common status message on top
export const COMMON_STATUS_NAME = '_common';

export type APIStatusMessageType = 'ClientSide' | 'ServerSide';

export interface StatusAlertMessage {
    status: APIStatusMessageType;
    title: string;
}

export interface StatusAlertState {
    StatusAlerts: { [id: string]: StatusAlertMessage };
    IsBanner: boolean;
    IsError: boolean;
    errorInfo: ErrorInfo;
    errorMessage: string;
    isExceptionDialogStyle: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ErrorOccurredAction {
    type: ERROR_OCCURRED;
    statusMessageId: string;
    errorMessage: string;
}

export function getErrorOccurredAction(errorMessage: string, statusMessageId: string = COMMON_STATUS_NAME): ErrorOccurredAction {
    return {
        type: ERROR_OCCURRED,
        statusMessageId: statusMessageId,
        errorMessage: errorMessage,
    };
}

interface ErrorOccurredWithCustomMessageAction {
    type: ERROR_OCCURRED_WITH_CUSTOM_MESSAGE;
    statusMessageId: string;
    errorMessage: string;
}

/*
    http error with status code 412: Precondition Failed
*/
export function getErrorOccurredWithCustomMessageAction(errorMessage: string): ErrorOccurredWithCustomMessageAction {
    return {
        type: ERROR_OCCURRED_WITH_CUSTOM_MESSAGE,
        statusMessageId: COMMON_STATUS_NAME,
        errorMessage: errorMessage,
    };
}

interface ShowErrorAction {
    type: SHOW_ERROR;
    errorInfo: ErrorInfo;
}

export function getShowErrorAction(errorInfo: ErrorInfo): ShowErrorAction {
    return {
        type: SHOW_ERROR,
        errorInfo: errorInfo
    };
}

interface BannerAction {
    type: SHOW_BANNER;
    statusMessageId: string;
    errorMessage: string;
}

export function getShowBannerAction(errorMessage: string, statusMessageId: string = COMMON_STATUS_NAME): BannerAction {
    return {
        type: SHOW_BANNER,
        statusMessageId: statusMessageId,
        errorMessage: errorMessage,
    };
}

interface CustomExceptionOccurredAction {
    type: CUSTOM_EXCEPTION_OCCURRED;
    statusMessageId: string;
    errorMessage: string;
}

/*
    http error with status code 400: Bad Request
*/
export function getCustomExceptionOccurredAction(errorMessage: string, statusMessageId: string = COMMON_STATUS_NAME): CustomExceptionOccurredAction {
    return {
        type: CUSTOM_EXCEPTION_OCCURRED,
        statusMessageId: statusMessageId,
        errorMessage: errorMessage,
    };
}

interface ClearAction {
    type: CLEAR;
    statusMessageId: string;
}

interface ClearBannerAction {
    type: CLEAR_BANNER;
    statusMessageId: string;
}

export function getClearAction(statusMessageId: string = COMMON_STATUS_NAME): ClearAction {
    return {
        type: CLEAR,
        statusMessageId: statusMessageId,
    };
}

export function getClearBannerAction(statusMessageId: string = COMMON_STATUS_NAME): ClearBannerAction {
    return {
        type: CLEAR_BANNER,
        statusMessageId: statusMessageId,
    };
}

interface ClearAllAction {
    type: CLEAR_ALL;
}
export function getClearAllAction(): ClearAllAction {
    return {
        type: CLEAR_ALL,
    };
}

export type StatusMessageActions = ErrorOccurredAction | CustomExceptionOccurredAction | BannerAction | ClearAction | ClearAllAction | ClearBannerAction | ShowErrorAction | ErrorOccurredWithCustomMessageAction;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: StatusAlertState = {
    StatusAlerts: {},
    IsBanner: false,
    IsError: false,
    errorInfo: new ErrorInfo(),
    errorMessage: '',
    isExceptionDialogStyle: true
};

export const reducer: Reducer<StatusAlertState> = (oldState: StatusAlertState, action: StatusMessageActions) => {
    switch (action.type) {
        case 'ERROR_OCCURRED':
            return {
                ...oldState,
                StatusAlerts: {
                    ...oldState.StatusAlerts,
                    [action.statusMessageId]: {
                        ...oldState.StatusAlerts[action.statusMessageId],
                        status: 'ServerSide',
                        title: action.errorMessage,
                    }
                },
                IsBanner: false,
            };
        case 'ERROR_OCCURRED_WITH_CUSTOM_MESSAGE':      // http statuscode: 412
            return {
                ...oldState,
                StatusAlerts: {
                    ...oldState.StatusAlerts,
                    [action.statusMessageId]: {
                        ...oldState.StatusAlerts[action.statusMessageId],
                        status: 'ClientSide',
                        title: action.errorMessage,                        
                    }
                },
                IsBanner: false,
                errorMessage: action.errorMessage,
                isExceptionDialogStyle: false
            };
        case 'CUSTOM_EXCEPTION_OCCURRED':            // http statuscode: 400
            return {
                ...oldState,
                StatusAlerts: {
                    ...oldState.StatusAlerts,
                    [action.statusMessageId]: {
                        ...oldState.StatusAlerts[action.statusMessageId],
                        status: 'ClientSide',
                        title: action.errorMessage,
                    }
                },
                IsBanner: false,
                errorMessage: action.errorMessage,
                isExceptionDialogStyle: false
            };
        case 'SHOW_BANNER':
            return {
                ...oldState,
                StatusAlerts: {
                    ...oldState.StatusAlerts,
                    [action.statusMessageId]: {
                        ...oldState.StatusAlerts[action.statusMessageId],
                        status: 'ClientSide',
                        title: action.errorMessage,
                    }
                },
                IsBanner: true,
            };
        case 'SHOW_ERROR':
            return {
                ...oldState,
                IsError: true,
                errorInfo: action.errorInfo,
            };
        case 'CLEAR':
            if (!oldState.IsBanner) {
                return {
                    ...oldState,
                    StatusAlerts: {
                        ...oldState.StatusAlerts,
                        [action.statusMessageId]: {
                            ...oldState.StatusAlerts[action.statusMessageId],
                            title: '',
                        }
                    },
                    IsBanner: false,
                    IsError: false,
                };
            }
            break;
        case 'CLEAR_BANNER':
            return {
                ...oldState,
                StatusAlerts: {
                    ...oldState.StatusAlerts,
                    [action.statusMessageId]: {
                        ...oldState.StatusAlerts[action.statusMessageId],
                        title: '',
                    }
                },
                IsError: false,
                IsBanner: false,
            };
        case 'CLEAR_ALL':
            return {
                StatusAlerts: {},
                IsError: false,
                IsBanner: false,
                errorInfo: new ErrorInfo(),
                errorMessage: '',
                isExceptionDialogStyle: true
            };
        default:
    }

    return oldState || unloadedState;
};