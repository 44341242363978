import * as React from 'react';

// set validation for prop types
interface CollapsibleProps {
  transitionTime?: number;
  easing?: string;
  open?: boolean;
  className?: string;
  classParentString?: string;
  openedClassName?: string;
  triggerClassName?: string;
  triggerOpenedClassName?: string;
  contentOuterClassName?: string;
  contentInnerClassName?: string;
  accordionPosition?: string | number;
  handleTriggerClick?: Function;
  trigger?: string | JSX.Element;
  triggerWhenOpen?: string | JSX.Element;
  triggerDisabled?: boolean;
  lazyRender?: boolean;
  overflowWhenOpen?: 'hidden' | 'visible' | 'auto' | 'scroll' | 'inherit' | 'initial' | 'unset';
  triggerSibling?: JSX.Element;
  onOpen?: () => void;
  onClose?: () => void;
}

interface CollapsibleState {
  isClosed?: boolean;
  shouldSwitchAutoOnNextCycle?: boolean;
  height?: number | string;
  overflow?: 'hidden' | 'visible' | 'auto' | 'scroll' | 'inherit' | 'initial' | 'unset';
  transition?: string;
  hasBeenOpened?: boolean;
}

class Collapsible extends React.Component<CollapsibleProps, CollapsibleState> {

  private outer: HTMLDivElement | null = {} as HTMLDivElement;
  private inner: HTMLDivElement | null = {} as HTMLDivElement;

  // if no transition time or easing is passed then default to this
  static get getDefaultProps() {
    return {
      transitionTime: 400,
      easing: 'linear',
      open: false,
      classParentString: 'Collapsible',
      triggerDisabled: false,
      lazyRender: false,
      overflowWhenOpen: 'hidden',
      openedClassName: '',
      triggerClassName: '',
      triggerOpenedClassName: '',
      contentOuterClassName: '',
      contentInnerClassName: '',
      className: '',
      triggerSibling: null,
    };
  }

  // defaults the dropdown to be closed
  constructor(props: CollapsibleProps) {
    super(props);
    if (this.props.open) {
      this.state = {
        isClosed: false,
        shouldSwitchAutoOnNextCycle: false,
        height: 'auto',
        transition: 'none',
        hasBeenOpened: true,
        overflow: this.props.overflowWhenOpen,
      };
    } else {
      this.state = {
        isClosed: true,
        shouldSwitchAutoOnNextCycle: false,
        height: 0,
        transition: 'height ' + this.props.transitionTime + 'ms ' + this.props.easing,
        hasBeenOpened: false,
        overflow: 'hidden',
      };
    }
    this.handleTriggerClick = this.handleTriggerClick.bind(this);
  }

  // taken from https://github.com/EvandroLG/transitionEnd/
  // determines which prefixed event to listen for
  // tslint:disable-next-line:no-any
  whichTransitionEnd(element: any) {
    let transitions = {
      'WebkitTransition': 'webkitTransitionEnd',
      'MozTransition': 'transitionend',
      'OTransition': 'oTransitionEnd otransitionend',
      'transition': 'transitionend',
    };

    for (let t in transitions) {
      if (element.style[t] !== undefined) {
        return transitions[t];
      }
    }
  }

  componentDidMount() {
    // set up event listener to listen to transitionend so we can switch the height from fixed pixel to auto for much responsiveness;
    // tODO:  Once Synthetic transitionend events have been exposed in the next release of React move this funciton to a function handed to the onTransitionEnd prop
    if (this.outer != null) {
      this.outer.addEventListener(this.whichTransitionEnd(this.outer), (event) => {
        if (this.state.isClosed === false) {
          this.setState({
            shouldSwitchAutoOnNextCycle: true,
          });
        }

      });
    }
  }

  componentDidUpdate(prevProps: CollapsibleProps) {

    if (this.state.shouldSwitchAutoOnNextCycle === true && this.state.isClosed === false) {
      // set the height to auto to make compoenent re-render with the height set to auto.
      // this way the dropdown will be responsive and also change height if there is another dropdown within it.
      this.makeResponsive();
    }

    if (this.state.shouldSwitchAutoOnNextCycle === true && this.state.isClosed === true) {
      this.prepareToOpen();
    }

    // if there has been a change in the open prop (controlled by accordion)
    if (prevProps.open !== this.props.open) {
      if (this.props.open === true) {
        this.openCollapsible();
      } else {
        this.closeCollapsible();
      }
    }
  }

  // tslint:disable-next-line:no-any
  handleTriggerClick(event: any) {

    event.preventDefault();

    if (this.props.triggerDisabled) {
      return;
    }

    if (this.props.handleTriggerClick) {
      this.props.handleTriggerClick(this.props.accordionPosition);
    } else {
      if (this.state.isClosed === true) {
        this.openCollapsible();
      } else {
        this.closeCollapsible();
      }
    }

  }

  closeCollapsible() {
    this.setState({ isClosed: true, shouldSwitchAutoOnNextCycle: true, height: this.inner ? this.inner.offsetHeight : 0, overflow: 'hidden' }, this.props.onClose);
  }

  openCollapsible() {
    this.setState({ height: 'auto', transition: 'height ' + this.props.transitionTime + 'ms ' + this.props.easing, isClosed: false, hasBeenOpened: true }, this.props.onOpen);
  }

  makeResponsive() {
    this.setState({
      height: 'auto',
      transition: 'none',
      shouldSwitchAutoOnNextCycle: false,
      overflow: this.props.overflowWhenOpen,
    });
  }

  prepareToOpen() {
    // the height has been changes back to fixed pixel, we set a small timeout to force the CSS transition back to 0 on the next tick.
    window.setTimeout(() => { this.setState({ height: 0, shouldSwitchAutoOnNextCycle: false, transition: 'height ' + this.props.transitionTime + 'ms ' + this.props.easing }); }, 50);
  }

  renderNonClickableTriggerElement() {
    if (this.props.triggerSibling) {
      return (
        <span className={this.props.classParentString + '__trigger-sibling'}>{this.props.triggerSibling}</span>
      );
    }

    return null;
  }

  render() {

    let dropdownStyle = {
      height: this.state.height,
      WebkitTransition: this.state.transition,
      msTransition: this.state.transition,
      transition: this.state.transition,
      overflow: this.state.overflow,
    };

    let openClass = this.state.isClosed ? 'is-closed' : 'is-open';
    let disabledClass = this.props.triggerDisabled ? 'is-disabled' : '';

    // if user wants different text when tray is open
    let trigger = (this.state.isClosed === false) && (this.props.triggerWhenOpen !== undefined) ? this.props.triggerWhenOpen : this.props.trigger;

    // don't render children until the first opening of the Collapsible if lazy rendering is enabled
    let children = this.props.children;
    if (this.props.lazyRender) {
      if (!this.state.hasBeenOpened) {
        children = null;
      }
    }

    let triggerClassName = this.props.classParentString + '__trigger' + ' ' + openClass + ' ' + disabledClass;

    if (this.state.isClosed) {
      triggerClassName = triggerClassName + ' ' + this.props.triggerClassName;
    } else {
      triggerClassName = triggerClassName + ' ' + this.props.triggerOpenedClassName;
    }

    return (
      <div className={this.props.classParentString + ' ' + (this.state.isClosed ? this.props.className : this.props.openedClassName)}>
        {this.renderNonClickableTriggerElement()}
        <span
          role="button"
          tabIndex={0}
          aria-expanded={!this.state.isClosed}
          className={triggerClassName.trim()}
          onClick={this.handleTriggerClick}
          onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
              this.handleTriggerClick(e);
            }
          }}
        >
          {trigger}
        </span>

        <div className={this.props.classParentString + '__contentOuter' + ' ' + this.props.contentOuterClassName} ref={(ref) => this.outer = ref} style={dropdownStyle}>
          <div className={this.props.classParentString + '__contentInner' + ' ' + this.props.contentInnerClassName} ref={(ref) => this.inner = ref}>
            {!this.state.isClosed && children}
          </div>
        </div>
      </div>
    );
  }
}

export default Collapsible;
