import * as React from 'react';
import * as Loadable from 'react-loadable';
import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';

export default class LoadingMessage extends React.Component<Loadable.LoadingComponentProps> {
    constructor(props: Loadable.LoadingComponentProps) {
        super(props);
    }    
    render() {
        if (this.props.isLoading) {
            return <div>{Intl.Get(LocIds.ComplianceManager.Loading)} ...</div>;
        }
        return null;        
    }
}