import { AxiosResponse } from 'axios';
import {
    ExportPersonalDataRequest, ExportPersonalDataResponse, GetPrivacyOperationsRequest, GetPrivacyOperationsResponse,
    GetExportedFilesRequest, GetExportedFilesResponse, GetUserInfoRequest, GetUserInfoResponse,
    GetAzureSubscriptionsRequest, GetAzureSubscriptionsResponse, GetAzureStorageAccountsResponse
} from './Models/PrivacyPortal';
import { ErrorResponse } from './Models';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { ReplaceUser } from '../Models/PrivacyPortal/ReplaceUser';
import { UserPrivacyReportOptions } from '../Models/PrivacyPortal/UserPrivacyReportOptions';

class PrivacyPortalService {

    ExportPersonalData(
        request: ExportPersonalDataRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: ExportPersonalDataResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/privacyPortal/exportPersonalData';

        ServiceTrustClient.doRequest_New(
            'POST', api, request, beforeRequest, (response: AxiosResponse) => {
                let res: ExportPersonalDataResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    error: response.data.error,
                    errorCode: response.data.errorCode,
                    exportId: response.data.exportId
                };
                onRequestSuccess(res);
                // tslint:disable-next-line:no-any
            },
            onRequestFailed
        );
    }

    GetPrivacyOperations(
        request: GetPrivacyOperationsRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: GetPrivacyOperationsResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/privacyPortal/privacyOperations';

        ServiceTrustClient.doRequest_New(
            'GET', api, request, beforeRequest, (response: AxiosResponse) => {
                let res: GetPrivacyOperationsResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    data: response.data,
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );
    }

    GetExportedFiles(
        request: GetExportedFilesRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: GetExportedFilesResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/privacyPortal/privacyOperationFiles' + '?privacyOperationId=' + request.operationId;

        ServiceTrustClient.doRequest_New(
            'GET', api, request, beforeRequest, (response: AxiosResponse) => {
                let res: GetExportedFilesResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    data: response.data
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );
    }

    GetUserInfo(
        request: GetUserInfoRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: GetUserInfoResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/privacyPortal/GetUserInfo' + '?objectId=' + request.objectId;

        ServiceTrustClient.doRequest_New(
            'GET', api, request, beforeRequest, (response: AxiosResponse) => {
                let res: GetUserInfoResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    data: response.data
                };
                onRequestSuccess(res);
            },
            onRequestFailed
        );
    }

    GetAzureSubscriptions(
        request: GetAzureSubscriptionsRequest,
        beforeRequest: () => void,
        onRequestSuccess: (response: GetAzureSubscriptionsResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/privacyPortal/azuresubscriptions';

        ServiceTrustClient.doRequest_New(
            'GET', api, request, beforeRequest, (response: AxiosResponse) => {
                let res: GetAzureSubscriptionsResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    azureSubscriptions: response.data
                };
                onRequestSuccess(res);
                // tslint:disable-next-line:no-any
            },
            onRequestFailed
        );
    }

    GetAzureStorageAccounts(
        subscriptionId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: GetAzureStorageAccountsResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/privacyPortal/azurestorageaccounts/' + subscriptionId;

        ServiceTrustClient.doRequest_New(
            'GET', api, {}, beforeRequest, (response: AxiosResponse) => {
                let res: GetAzureStorageAccountsResponse = {
                    code: response.status,
                    status: response.statusText,
                    message: response.statusText,
                    azureStorageAccounts: response.data
                };
                onRequestSuccess(res);
                // tslint:disable-next-line:no-any
            },
            onRequestFailed
        );
    }

    ReplaceUser(
        replaceUser: ReplaceUser,
        beforeRequest: () => void,
        onRequestSuccess: (response: AxiosResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReplaceUser';
        ServiceTrustClient.doRequest_New(
            'POST', api, replaceUser, beforeRequest, (response: AxiosResponse) => {
                onRequestSuccess(response);
            },
            onRequestFailed
        );
    }

    GetAllDomains(
        beforeRequest: () => void,
        onRequestSuccess: (response: AxiosResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/GetAllDomains';
        ServiceTrustClient.doRequest_New(
            'GET', api, {}, beforeRequest, (response: AxiosResponse) => {
                onRequestSuccess(response);
            },
            onRequestFailed
        );
    }

    CheckUser(
        userPrincipleName: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: AxiosResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/CheckUser';
        ServiceTrustClient.doRequest_New(
            'POST', api, {Key: 'userPrincipleName', Value: userPrincipleName}, beforeRequest, (response: AxiosResponse) => {
                onRequestSuccess(response);
            },
            onRequestFailed
        );
    }

    GetUserReport(
        options: UserPrivacyReportOptions,
        fileName: string,        
        callback: () => void
        ): void {
        ServiceTrustClient.downloadUserGDPRPrivacyData(
            '/api/v2/UserPrivacyReport', 
            fileName, 
            options, 
            callback);
    }
}

export let PrivacyPortalClient = new PrivacyPortalService();