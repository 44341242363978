import * as React from 'react';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import '../AdvancedDocumentTable.css';

export interface ShowMoreTextProps {
    title: string;
    text: string;
    limit: number;
    className: string;
}

export interface ShowMoreTextState {
    show: boolean;
}

export default class ShowMoreText extends React.Component<
    ShowMoreTextProps,
    ShowMoreTextState
> {
    // tslint:disable-next-line:no-any
    constructor(props: any) {
        super(props);
        this.state = { show: false };
    }

    private _onMore = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        this.setState({ show: true });
    }

    private _onLess = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        this.setState({ show: false });
    }

    public render() {
        const { text, limit, className } = this.props;

        return true === this.state.show ?
        (
            <p className={className}>
                {text}
                {text.length > limit &&
                    <span>
                        &nbsp; 
                        <button
                            className="showMore"
                            onClick={this._onLess}
                            role="button"
                            aria-label={Intl.Get(LocIds.PageAndPanels.ShowLessAriaLabel, { title: this.props.title })}
                        >
                            {Intl.Get(LocIds.PageAndPanels.ShowLess)}
                        </button>
                    </span>
                }
            </p>
        ) : (
            <p className={className}>
                {text.substr(0, limit)}
                {text.length > limit &&
                    <span>
                        &hellip;
                        <button
                            className="showMore"
                            onClick={this._onMore}
                            role="button"
                            aria-label={Intl.Get(LocIds.PageAndPanels.ShowMoreAriaLabel, { title: this.props.title })}
                        >
                            {Intl.Get(LocIds.PageAndPanels.ShowMore)}
                        </button>
                    </span>
                }
            </p>
        );
    }
}
