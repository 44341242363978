import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton, ComboBox, IComboBoxOption, IComboBox, Checkbox } from 'office-ui-fabric-react/lib/';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { AreaHeadingPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { getSimpleThemeOptions, getThemeClassName, getAlignOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import * as ReactMarkdown from 'react-markdown';
import { CSSProperties } from 'react';
import { StandardLonghandProperties } from 'csstype';
import { RichTextLink } from '../../Utilities/RenderUtilities';

export class AreaHeadingPanel extends BasePanel<AreaHeadingPanelPropertiesData> {
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private alignOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.AlignLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<AreaHeadingPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));
        this.alignOptions = this.alignOptions.concat(getAlignOptions());
    }

    getActualPanel() {
        let styleProps: CSSProperties = {paddingTop: '24px', paddingLeft: '12px'};
        let extraTextPadding: CSSProperties = this.state.panelData.additionalMargins ? {padding: '24px'} : {};

        if (this.state.panelData.textAlign) {
            styleProps.textAlign = (this.state.panelData as StandardLonghandProperties<string | number>).textAlign;
        }
        if (this.state.panelData.additionalMargins) {
            styleProps.marginTop = '24px';
            styleProps.paddingLeft = '24px';
        }
        return (
            <div 
                data-panel-type="Area Heading"
                data-grid="col-12" 
                className={'m-area-heading material-surface ' + getThemeClassName(Theme[this.state.panelData.theme || 'None'])}
                style={styleProps}
            >
                <h2 className="c-heading-2 clearfix" style={extraTextPadding}>{getLocalizedString(this.state.panelData.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h2>
                <div style={extraTextPadding}>
                <ReactMarkdown
                    renderers={{link: RichTextLink}}
                    className="c-paragraph-1"
                    source={getLocalizedString(this.state.panelData.subHeading).replace(/(\n)/g, '<br>') || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                    skipHtml={false}
                    escapeHtml={false}
                />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div className="row" style={{marginLeft: '0px'}}>
                        {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                        <div className="col">
                            {this.getActualPanel()}
                            {this.state.showEditor &&
                                <div>
                                    <div className="editPanel">
                                        <div>
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.textAlign || 'None'}
                                                label={'Text Align:'}
                                                ariaLabel={'Text Align'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.alignOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.textAlign = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                            <Checkbox
                                                label="Additional Margins and Text Padding"
                                                aria-label="Additional Margins and Text Padding"
                                                checked={this.state.panelData.additionalMargins}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.state.panelData.additionalMargins = isChecked;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                            />
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={getLocalizedString(this.state.panelData.subHeading)}
                                                onChange={(newVal) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'subHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                required={true}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '6px' }} />
                                    <PrimaryButton onClick={() => { this.onSave(); }} text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)} />
                                    <DefaultButton onClick={() => { this.onDiscardChange(); }} text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)} />
                                </div>}
                        </div>
                    </div >
                    :
                    this.getActualPanel()
                }
            </div>
        );
    }
}