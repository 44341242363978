import { LocalizedValue, LocalizedString } from '../Models';

export function getLocalizedValue(dataSet: LocalizedValue[] | undefined, isEdit: boolean): string {
    if (dataSet) {
        if (isEdit) {
            let temp = dataSet.find((data) => data.cultureName === 'en-US');
            if (temp) {
                return temp.displayValue;
            }
        }

        // TODO : Get current localization and return proper data
        return dataSet.length ? dataSet[0].displayValue : '';
    }
    return '';
}

export function setDefaultLocalizedValue(dataSet: LocalizedValue[] | undefined, value: string) {
    if (dataSet) {
        let temp = dataSet.find((data) => data.cultureName === 'en-US');
        if (temp) {
            temp.displayValue = value;
        } else {
            dataSet.push({cultureName: 'en-US', displayValue: value});
        }
    }
}

export function getLocalizedString(dataSet: LocalizedString | undefined): string {
    return (dataSet && dataSet.lv) ? dataSet.lv : '';
}

export function getAbbrivatedHeading(dataSet: LocalizedString | undefined): string {
  if (dataSet && dataSet.lv) {
    if (dataSet.lv.startsWith('APAC')) {
      return dataSet.lv.replace('APAC', 'A,P,A,C');
    }
    if (dataSet.lv.startsWith('MEA')) {
      return dataSet.lv.replace('MEA', 'M,E,A');
    }
    return dataSet.lv;
  } else {
    return '';
  }
}

export function setDefaultLocalizedString(dataSet: object, field: string, value: string): object {
    if (dataSet) {
        var locationzedObject = dataSet[field] as LocalizedString;
        if (!locationzedObject) {
            locationzedObject = makeLocalizedString(value);
            dataSet[field] = locationzedObject;
        }
        locationzedObject.lv = value;
        return dataSet;
    }
    return {};
}

export function makeLocalizedString(value: string): LocalizedString {
    var uuid = require('uuid');
    return {
        li: uuid.v1(),
        lv: value
    };
}