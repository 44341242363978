import * as React from 'react';
import { PanelSelectionHelperFactory } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { getLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { PanelCollection } from './PanelsCollection';
import { CommandBarButtonFactory, ButtonTypes } from '../../../Common/Utilities/CommanBarButtonFactory';
import { ViewData, PanelData } from '../../Models/PageInfrastructure';
import { PanelPropertiesDataBase } from '../../Models/PageInfrastructure/PanelPropertiesData/';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';

interface DrawerViewsCollectionProps {
    views: ViewData[];
    isInEditMode: boolean;
    addNewView: () => void;
    modifySelectedView: (index: number) => void;
    moveSelectedViewLeft: (index: number) => void;
    moveSelectedViewRight: (index: number) => void;
    deleteSelectedView: (index: number) => void;
    makeDirty: () => void;
    delete: () => void;
    moveUp: () => void;
    moveDown: () => void;
    idOfPage: string;
    parentId: string;
}

interface ViewsCollectionState {
    views: ViewData[];
}

export class DrawerViewsCollection extends React.Component<DrawerViewsCollectionProps, ViewsCollectionState> {
    private addViewButton: IContextualMenuItem;
    private modifyViewButton: IContextualMenuItem;
    private moveLeftViewButton: IContextualMenuItem;
    private moveRightViewButton: IContextualMenuItem;
    private deleteViewButton: IContextualMenuItem;
    private deletePanelButton: IContextualMenuItem;
    private movePanelUpButton: IContextualMenuItem;
    private movePanelDownButton: IContextualMenuItem;
    private selectedIndex: number;

    constructor(props: DrawerViewsCollectionProps) {
        super(props);
        this.state = { views: props.views ? props.views : [] };
        this.selectedIndex = -1;
        this.addViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Add, () => this.props.addNewView(), Intl.Get(LocIds.PageAndPanels.AddViewButtonLabel));
        this.modifyViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Edit, () => this.props.modifySelectedView(this.selectedIndex));
        this.moveLeftViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveLeft, () => this.props.moveSelectedViewLeft(this.selectedIndex));
        this.moveRightViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveRight, () => this.props.moveSelectedViewRight(this.selectedIndex));
        this.deleteViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.deleteSelectedView(this.selectedIndex));
        this.deletePanelButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.delete(), Intl.Get(LocIds.PageAndPanels.DeleteButtonLabel), 'DeletePanel');
        this.movePanelUpButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveUp, () => this.props.moveUp(), Intl.Get(LocIds.PageAndPanels.MoveUpPanelButtonDescription));
        this.movePanelDownButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveDown, () => this.props.moveDown(), Intl.Get(LocIds.PageAndPanels.MoveDownPanelButtonDescription));
    }

    UNSAFE_componentWillReceiveProps(nextProps: DrawerViewsCollectionProps) {
        if (nextProps.views && nextProps.views.length > 0) {
            let selectedItem = nextProps.views.find(x => x.isSelected);
            if (selectedItem) {
                this.selectedIndex = nextProps.views.indexOf(selectedItem);
            }
        }
        this.setState({ views: nextProps.views ? nextProps.views : [] });
    }

    selectTab(index: number) {
        if (this.selectedIndex === index && this.props.isInEditMode) {
            return;
        } else if (this.selectedIndex === index) {
            this.selectedIndex = -1;
        } else {
            this.selectedIndex = index;
        }
        let views: ViewData[] = this.state.views;
        views.forEach((value: ViewData, i: number) => {
            value.isSelected = (i === index);
        });
        this.setState({ views: views });
    }

    addNewPanel(selectedIndex: number, type: string) {        
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        selectedView.panels.push(PanelSelectionHelperFactory.getPanel(type));
        this.props.makeDirty();
        this.selectTab(selectedIndex);
    }

    deletedPanel(selectedIndex: number, panel: PanelData<PanelPropertiesDataBase>) {
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        let index = selectedView.panels.indexOf(panel);
        let panels = selectedView.panels;
        selectedView.panels = [];
        for (let i = 0; i < panels.length; i++) {
            if (i !== index) {
                selectedView.panels.push(panels[i]);
            }
        }
        this.props.makeDirty();
        this.selectTab(selectedIndex);
    }

    movePanelUp(selectedIndex: number, panel: PanelData<PanelPropertiesDataBase>) {
        let index = this.props.views[this.selectedIndex].panels.indexOf(panel);
        if (index > 0) {
            this.swapPanels(index, index - 1);
            this.props.makeDirty();
        }
        this.selectTab(selectedIndex);
    }

    movePanelDown(selectedIndex: number, panel: PanelData<PanelPropertiesDataBase>) {
        let index = this.props.views[this.selectedIndex].panels.indexOf(panel);
        if (index < this.props.views[this.selectedIndex].panels.length - 1) {
            this.swapPanels(index, index + 1);
            this.props.makeDirty();
        }
        this.selectTab(selectedIndex);
    }

    swapPanels(index1: number, index2: number) {
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        var temp = selectedView.panels[index1];
        selectedView.panels[index1] = selectedView.panels[index2];
        selectedView.panels[index2] = temp;
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode &&
                    <div data-grid="col-12">
                        <CommandBar
                            items={[this.addViewButton, this.modifyViewButton, this.moveLeftViewButton, this.moveRightViewButton, this.deleteViewButton]}
                            farItems={[this.deletePanelButton, this.movePanelUpButton, this.movePanelDownButton]}
                        />
                    </div>
                }
                {this.state.views.length > 1 || this.props.isInEditMode ?
                    <div data-grid="col-12">                        
                        {this.state.views.map((data: ViewData, index) =>                            
                            <div key={index}  className={'c-drawer f-divider' + (index === this.selectedIndex && this.props.isInEditMode ? ' selected-view' : '')}>
                                <button className="c-glyph" aria-expanded={index === this.selectedIndex} onClick={() => this.selectTab(index)}>
                                    {getLocalizedString(data.name) || Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}
                                </button>
                                <div hidden={index !== this.selectedIndex}>
                                <PanelCollection                                
                                    panels={data.panels}
                                    selectView={() => this.selectTab(index)}
                                    deletePanel={(n) => this.deletedPanel(index, n)}
                                    isInEditMode={this.props.isInEditMode}
                                    addNewPanel={(type) => this.addNewPanel(index, type)}
                                    makeDirty={() => this.props.makeDirty()}
                                    movePanelUp={(n) => this.movePanelUp(index, n)}
                                    movePanelDown={(n) => this.movePanelDown(index, n)}
                                    idOfPage={this.props.idOfPage}
                                    parentId={this.props.parentId}
                                    hasInPageNav = {false}
                                />
                                </div>
                            </div>
                        )}
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}