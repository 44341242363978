import * as React from 'react';
import { Dropdown } from 'office-ui-fabric-react/lib/components/Dropdown';
import Constants from '../Utilities/Constants';

interface PermissionsSelectorProps {
    selectedPermissions: string[] | undefined;
    onPermissionsSelectionChanged: (newPermissions: string[]) => void;
}
// Both page/Top navigation will use this component to get the permission dropdownlist
export class PermissionsSelector extends React.Component<PermissionsSelectorProps, {}> {
    private selectedPermissions: string[];
    constructor(props: PermissionsSelectorProps) {
        super(props);
        this.selectedPermissions = props.selectedPermissions || [];
    }

    UNSAFE_componentWillReceiveProps(nextProps: PermissionsSelectorProps) {
        this.selectedPermissions = nextProps.selectedPermissions || [];
    }

    render() {
        return (
            <Dropdown
                label="Permissions(any)"
                placeHolder="Select Permissions"
                multiSelect={true}
                options={Constants.PERMISSIONS_OPTIONS}
                defaultSelectedKeys={this.props.selectedPermissions}
                onChanged={
                    (option) => {
                        let index = this.selectedPermissions.findIndex(p => p === option.key);
                        if (index < 0 && option.selected) {
                            this.selectedPermissions.push(option.key.toString());
                        } else if (index >= 0 && !option.selected) {
                            this.selectedPermissions.splice(index, 1);
                        }
                        this.props.onPermissionsSelectionChanged(this.selectedPermissions);
                    }
                }
            />
        );
        
    }

}