import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { axios } from 'axios'
import { AxiosResponse } from 'axios';
import { ErrorResponse, ChatBotAuthenticationTokenResponse } from './Models';

export class ChatBotAuthenticationService {
    GetCopilotChatBotToken(
        onRequestSuccess: (response: ChatBotAuthenticationTokenResponse) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {

        let tokenResponse : ChatBotAuthenticationTokenResponse;
        var api: string = '/api/GetChatBotAuthenticationToken'
        
        ServiceTrustClient.doRequest_New(
            'GET', api, {},  () => null, (response: AxiosResponse) => {
                tokenResponse = 
                {
                    token: response.data.Token
                }
                onRequestSuccess(tokenResponse);
            },
            onRequestFailed
        );
    }

    GetToken = async() : Promise<string> => {
        var api: string = '/api/GetChatBotAuthenticationToken'

        let apiUrl = ServiceTrustClient.getWebApiEndpointUrl().concat(api);

        try {
            //let response = await axios.get(apiUrl);
            let response = await fetch(apiUrl, {method: "GET"})
            let json = await response.json()
            return json.token;
        } catch (error) {
            throw new Error("Failed to access api for bot token");
            
        }
        


    }
}

export let chatBotAuthenticationServiceClient = new ChatBotAuthenticationService();