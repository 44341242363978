// Defines a class used to trigger authentication session refresh
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { AxiosResponse } from 'axios';
import { ErrorResponse } from './Models';
import { AuthSessionRefreshResponse } from './Models/Authentication/AuthSessionRefreshResponse';
import { AuthSessionRefreshInfo } from './../../App/Models/Authentication/AuthSessionRefreshInfo';

const refreshAuthSessionRoute: string = '/api/auth/refresh';

export class AuthenticationSessionService {
    public refreshAuthSession(onSuccess: (response: AuthSessionRefreshResponse) => void, 
                              onFailure: (response: ErrorResponse) => void): void {
        try {
            // We simply make a call to the auth refresh endpoint to re-acquire new Jwt token            
            ServiceTrustClient.doRequest_New(
                'get', refreshAuthSessionRoute, {}, () => null,
                (response: AxiosResponse) => 
                    onSuccess(
                        {
                            code: response.status,
                            status: response.statusText,
                            message: response.statusText,
                            data: response.data as AuthSessionRefreshInfo
                        }),                
                (response: ErrorResponse) => onFailure(response),
                undefined, undefined, undefined,  true
            );
        } catch (Error) {
            // Not critical. Ignore exceptions.
            onFailure(Error);         
        }
    }
}