import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { GuideHelpPanelPropertiesData } from '../../Models/PageInfrastructure';
import { deleteOnePanelFromCollection, moveSelectedColumn, getGuideHelpColumn } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { Checkbox } from 'office-ui-fabric-react';
import { enableCarousel } from '../../Utilities/MwfHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import Constants from '../../Utilities/Constants';
import * as ReactMarkdown from 'react-markdown';
import './GuideHelpPanel.css';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Link } from 'react-router-dom';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { ExternalNavigationService } from '../../Services/ExternalNavigationService';

export class GuideHelpPanel extends BasePanel<GuideHelpPanelPropertiesData> {
    private static MAX_COLUMN = 9;
    private externalNavigation = new ExternalNavigationService();

    constructor(props: BasicPanelProperties<GuideHelpPanelPropertiesData>) {
        super(props);
        if (!this.props.panel.panelInfo.columns) {
            this.props.panel.panelInfo.columns = [];
        }
    }

    componentDidMount() {
        var hasPanel = this.state.panelData.columns && this.state.panelData.columns.length > 1;
        var canEnableCarousel = !this.props.isInEditMode;
        if (hasPanel && canEnableCarousel) {
            enableCarousel();
        }
    }

    componentDidUpdate() {
        var hasPanel = this.state.panelData.columns && this.state.panelData.columns.length > 1;
        var canEnableCarousel = !this.props.isInEditMode;
        if (hasPanel && canEnableCarousel) {
            enableCarousel();
        }
    }

    getActualPanel() {
        return (
            <div data-grid="col-12" className="m-hero" data-panel-type="Guide Help">
                <div
                    data-grid="col-12"
                    className={'c-carousel f-multi-slide f-scrollable-next f-scrollable-previous ' + (this.state.panelData.autoPlay ? 'f-auto-play' : '')}
                    role="region"
                    data-js-interval={(this.state.panelData.slideChangeIntervalInSeconds || 6) * 1000}
                >
                    {this.state.panelData.columns && this.state.panelData.columns.length > 1 &&
                        <div className="c-group">
                            <div className="c-sequence-indicator" role="tablist">
                                {this.state.panelData.columns.map((data, index) =>
                                    <button
                                        key={'button' + data.toString().concat(index.toString())}
                                        onClick={(e) => {
                                            if (this.state.showEditor) {
                                                e.preventDefault();
                                            }
                                            if (!this.state.showEditor || !this.panelValidator.validate()) {
                                                return;
                                            }
                                            this.setState({ selectedIndex: index });
                                        }
                                        }
                                        role="tab"
                                        title={data.heading && `${data.heading.lv} panel`}
                                        aria-label={data.heading && `${data.heading.lv} panel`}
                                        aria-controls={'panel' + index.toString()}
                                        aria-selected={this.state.selectedIndex === index ? 'true' : 'false'}
                                    />)
                                }
                            </div>

                            {this.state.panelData.autoPlay && <button className="c-action-toggle c-glyph glyph-play f-toggle" data-toggled-label="Pause" data-toggled-glyph="glyph-pause" aria-label="Play" />}
                        </div>
                    }
                    {this.state.panelData.columns && this.state.panelData.columns.length > 1 &&
                        <div>
                            <button
                                className="c-flipper f-previous"
                                aria-hidden="true"
                                tabIndex={-1}
                                onClick={(e) => {
                                    if (this.state.showEditor) {
                                        e.preventDefault();
                                    }
                                    if (!this.state.showEditor || !this.panelValidator.validate()) {
                                        return;
                                    }
                                    if (this.state.selectedIndex > 0) {
                                        this.setState({ selectedIndex: this.state.selectedIndex - 1 });
                                    } else {
                                        this.setState({ selectedIndex: this.state.panelData.columns ? this.state.panelData.columns.length - 1 : 0 });
                                    }
                                }}
                            />
                            <button
                                className="c-flipper f-next"
                                aria-hidden="true"
                                tabIndex={-1}
                                onClick={(e) => {
                                    if (this.state.showEditor) {
                                        e.preventDefault();
                                    }
                                    if (!this.state.showEditor || !this.panelValidator.validate()) {
                                        return;
                                    }
                                    if (this.state.panelData.columns && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
                                        this.setState({ selectedIndex: this.state.selectedIndex + 1 });
                                    } else {
                                        this.setState({ selectedIndex: 0 });
                                    }
                                }}
                            />
                        </div>
                    }
                    <div data-grid="col-12">
                        <ul role="none">
                            {this.state.panelData.columns &&
                                this.state.panelData.columns.map((data, index) =>
                                    <li
                                        key={'panel' + data.toString().concat(index.toString())}
                                        id={'panel' + index.toString()}
                                        className={this.state.selectedIndex === index ? 'f-active' : ''}
                                    >
                                        <section className={'context-accessory'} >
                                            <div data-grid="col-6" className={'guidehelpdiv '}>
                                                <picture className={'guideimage c-image'}>
                                                    <img
                                                        aria-hidden={true}
                                                        className="lazyloaded"
                                                        src={imageHelper.getImageUrl(data.imageId)}
                                                        data-src={imageHelper.getImageUrl(data.imageId)}
                                                        alt={getLocalizedString(data.heading)}
                                                    />
                                                </picture>
                                            </div>
                                            <div data-grid="col-6" className={'guidehelpdiv'}>
                                                <div>
                                                    <h2 className="c-heading-2 guide-help-panel-heading">{getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h2>
                                                    <ReactMarkdown className="c-paragraph-1" source={getLocalizedString(data.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)} skipHtml={true}/>
                                                    {getLocalizedString(data.callToActionLabel) ?
                                                        data.callToActionUrl && /^https?:\/\//.test(data.callToActionUrl) ?
                                                            <div>
                                                                <a
                                                                    href={data.callToActionUrl}
                                                                    className="c-call-to-action c-glyph"
                                                                    style={{ 'textTransform': 'uppercase' }}
                                                                    onClick={
                                                                        () => {
                                                                            this.externalNavigation.navigateTo({
                                                                                url: data.callToActionUrl || ''
                                                                            });
                                                                        }
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    <span>{getLocalizedString(data.callToActionLabel)}</span>
                                                                </a>
                                                            </div> : data.callToActionUrl ?
                                                                <div>
                                                                    <Link to={data.callToActionUrl} className="c-call-to-action c-glyph" style={{ 'textTransform': 'uppercase' }}><span>{getLocalizedString(data.callToActionLabel)}</span></Link>
                                                                </div>
                                                                : null : null
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    onAddColumn() {
        if (!this.state.panelData.columns || (this.state.panelData.columns.length >= GuideHelpPanel.MAX_COLUMN) || !this.panelValidator.validate()) {
            return;
        }
        this.state.panelData.columns.push(getGuideHelpColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    onDeleteColumn() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            var panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    onMoveLeft() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    onMoveRight() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: 0 }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor && this.state.panelData.columns &&
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12" >
                                        <div>

                                            <div data-grid="col-12">
                                                <div data-grid="col-5">
                                                    <TextFieldWrapper
                                                        value={this.state.panelData.columns ? (this.state.panelData.columns[this.state.selectedIndex].imageTitle || '') : ''}
                                                        disabled={true}
                                                        label={'Image:'}
                                                        required={true}
                                                        validator={this.panelValidator}
                                                    />
                                                </div>
                                                <div data-grid="col-4" style={{ padding: '29px 0 0 10px' }}>
                                                    <PrimaryButton onClick={() => this.onImagePicker()} text={'Choose Image'} />
                                                    <ImagePickerDialog
                                                        hidden={!this.state.showImagePicker}
                                                        onImageSelection={images => {
                                                            if (images && images.length && this.state.panelData.columns) {
                                                                this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                                this.state.panelData.columns[this.state.selectedIndex].imageTitle = images[0].title;
                                                                this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                            } else {
                                                                this.setState({ showImagePicker: false });
                                                            }
                                                        }}
                                                        onDismiss={() => this.setState({ showImagePicker: false })}
                                                    />
                                                </div>
                                            </div>
                                            <div data-grid="col-12">
                                                <div data-grid="col-5">
                                                    <TextFieldWrapper
                                                        value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading) : ''}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                            if (this.state.panelData.columns) {
                                                                if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                                    newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                                }
                                                                setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal);
                                                            }
                                                            this.setState({ panelData: this.state.panelData });
                                                        }}
                                                        label={'Heading:'}
                                                        placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                                        required={true}
                                                        validator={this.panelValidator}
                                                    />
                                                </div>
                                            </div>
                                            <div data-grid="col-12">
                                                <div data-grid="col-5">
                                                    <MarkDownEditor
                                                        showCommandButton={false}
                                                        value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].subHeading)}
                                                        onChange={x => {
                                                            if (this.state.panelData.columns) {
                                                                setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'subHeading', x);
                                                                this.setState({ panelData: this.state.panelData });
                                                            }
                                                        }}
                                                        label="Description:"
                                                        required={true}
                                                        validator={this.panelValidator}
                                                        selectedIndex={this.state.selectedIndex}
                                                        placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                        maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextField
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'callToActionLabel', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={'Call To Action Label:'}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? this.state.panelData.columns[this.state.selectedIndex].callToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={'Call To Action URL:'}
                                                url={true}
                                                isPage={true}
                                                validator={this.panelValidator}
                                                customValidator={(value) => {
                                                    if (this.state.panelData.columns && getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel) !== '') {
                                                        return value === '' ? 'Required' : '';
                                                    }
                                                    return '';
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <Checkbox
                                                checked={this.state.panelData.autoPlay || false}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.state.panelData.autoPlay = isChecked;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={'Auto Play'}
                                            // ariaLabel={'Auto Play'}
                                            />
                                            <TextFieldWrapper
                                                value={(this.state.panelData.slideChangeIntervalInSeconds || 1).toString()}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.state.panelData.slideChangeIntervalInSeconds = parseInt(newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                disabled={!this.state.panelData.autoPlay}
                                                type="number"
                                                label={'Slide Change Interval (Seconds):'}
                                                validator={this.panelValidator}
                                                customValidator={(value) => {
                                                    if (this.state.panelData.autoPlay) {
                                                        return !this.state.panelData.slideChangeIntervalInSeconds ? 'Required' : '';
                                                    }
                                                    return '';
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={'Save'}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={'Discard Changes'}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={'Add Panel'}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length >= GuideHelpPanel.MAX_COLUMN)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={'Delete Panel'}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={'Move Left'} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={'Move Right'} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}