import * as React from 'react';
import { TextField, ITextField } from 'office-ui-fabric-react/lib/TextField';
import { ITextFieldProps } from 'office-ui-fabric-react/lib/TextField';
import { OfficeWrapperProps, ValidatableField } from './OfficeWrapperProps';
import ErrorLabel from './ErrorLabel';

export interface TextFieldWrapperNumericProps extends ITextFieldProps, OfficeWrapperProps {
    numericValue?: number;
    isFloat?: boolean;
    minValue?: number;
    maxValue?: number;
    onChangedNumeric?: (newValue?: number) => void;
}

interface TextFieldWrapperNumericState {
    value: string;
    error: string;
}

export class TextFieldWrapperNumeric extends React.Component<TextFieldWrapperNumericProps, TextFieldWrapperNumericState> implements ValidatableField {
    textField: ITextField | null;
    isUnmount: boolean;
    constructor(props: TextFieldWrapperNumericProps) {
        super(props);
        this.state = {
            value: props.numericValue ? props.numericValue.toString() : '',
            error: ''
        };
        this.validate = this.validate.bind(this);
    }

    onChangedRaiseOnChangedNumeric(newValue: string) {
        // We need to remember the text value for display/editing purposes. If the value property was drawn from the numericValue property
        // then when the value is '5.2' and the user hits backspace the value will automagically become '5.0' and not '5.'. The latter is what
        // the user wants so that they can then type a '3' thus changing the 5.2 into a 5.3.
        // first change the newValue to be valid number, with at most 1 decimal dot for float and no dot for number
        newValue = (this.props.isFloat)
            ? newValue.replace(/[^\d\.]/g, '').replace('.', '$#$').replace(/\./g, '').replace(
            '$#$', '.')
            : newValue.replace(/[^\d]/g, '');
        this.setState({value: newValue});

        let newValueAsNumber: number = (this.props.isFloat)
            ? parseFloat(newValue)
            : parseInt(newValue, undefined);
        if (isNaN(newValueAsNumber)) {
            newValueAsNumber = 0;
        }

        if (this.props.onChangedNumeric) {
            this.props.onChangedNumeric(newValueAsNumber);
        }
    }

    componentDidMount() {
        this.props.validator.add(this);
        this.isUnmount = false;
    }

    componentWillUnmount() {
        this.isUnmount = true;
    }

    validate() {
        if (this.isUnmount) {
            return true;
        }
        if (this.props.required && this.props.value === '') {
            this.setState({error: 'Required'});
            return false;
        }

        // If an optional custom validator was supplied then ask it to validate the value.
        if (this.props.customValidator) {
            const customError = this.props.customValidator(this.props.value || '');
            if (customError !== '') {
                this.setState({error: customError});
                return false;
            }
        }

        if (this.props.minValue || this.props.maxValue) {
            // Confirm that both min and max were specified.
            if ((this.props.minValue && (this.props.maxValue === undefined)) ||
                ((this.props.minValue === undefined) && this.props.maxValue)) {
                this.setState({error: `Internal error: Only part of the range was specified: minValue = ${this.props.minValue}, maxValue = ${this.props.maxValue}`});
                return false;
            }

            // Validate that the value is in the specified range.
            if (this.props.numericValue && ((this.props.numericValue < (this.props.minValue || 0)) || (this.props.numericValue > (this.props.maxValue || 0)))) {
                this.setState({error: `The value must be in the range: ${this.props.minValue} - ${this.props.maxValue}`});
                return false;
            }
        }

        // If we've made it to here then everything looks OK.
        this.setState({error: ''});
        return true;
    }

    render() {
        return (
            <div>
                <TextField 
                    onBlur={this.validate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, newValue: string) => { this.onChangedRaiseOnChangedNumeric(newValue); }}
                    componentRef={(tf: ITextField | null) => this.textField = tf}
                    value={this.state.value ? this.state.value : ''}
                    {...this.props} 
                />
                {this.state.error !== '' && 
                    <ErrorLabel error={this.state.error} />
                }
            </div>
        );
    }
}

export default TextFieldWrapperNumeric;