import { DocumentVersionInfo } from '.';
import { getExternalLink, isExternalLink } from '../Utilities/DocumentHelper';
import * as uuid from 'uuid';
import { getLocalizedString } from '../../Common/Utilities/LocalizationHelper';
import { VocabularyDefinition, VocabularySelection } from './Vocabulary';
import { PageMetadata } from '../Models/PageInfrastructure';
import { ModelWithVocabularySelections } from './ModelWithVocabularySelections';
import { DocumentType } from './NotificationSettings/DocumentType';
import { ComplianceManagerConstants } from '../../../src/Common/Models/ComplianceManagerConstants';

export function getDocumentDate(document: DocumentVersionInfo) {
    return new Date(document.whenApprovedOrRejected);
}

export function getDocumentReportDate(document: DocumentVersionInfo) {
    return new Date(document.reportDate);
}

/**
 * This model allows us to easily abstract out the documents and pages as one
 * common interface for easy sorting and filtering.
 *
 * This is leveraged by two static factory methods that will create a common
 * object with shared props and methods.
 */
export class TableItem implements ModelWithVocabularySelections {
    public documentId: string;
    public title: string;
    public description: string;
    public seriesId: string;
    public documentType: DocumentType;
    public date: Date;
    public vocabularySelections: VocabularySelection[];
    public vocabularySelectionsV2022: VocabularySelection[];
    public isDocument: boolean;
    public isLink: boolean;
    public isPublic: boolean;
    public isSpecialDocument: boolean;
    public fileExtension?: string;
    public contentHash?: string;
    public pageUrl?: string;
    public updateNotes?: string;
    public isContentUpdated?: boolean;
    public reportDate: Date;

    constructor(documentId?: string, title?: string,
                description?: string, date?: Date, reportDate?: Date,
                vocabularies?: VocabularySelection[],
                contentHash?: string, isDocument?: boolean,
                isSpecialDocument?: boolean, isLink?: boolean,
                isPublic?: boolean, fileExtension?: string,
                pageUrl?: string, updateNotes?: string,
                isContentUpdated?: boolean, seriesId?: string, documentType?: DocumentType) {
        this.documentId = documentId || uuid.v1();
        this.title = title || 'Table Item';
        this.description = description || 'Table Description';
        this.date = date || new Date();
        this.reportDate = reportDate || new Date();
        this.vocabularySelections = vocabularies || [];
        this.isDocument = 'undefined' === typeof isDocument ? true : isDocument;
        this.isSpecialDocument = 'undefined' === typeof isSpecialDocument ? false : isSpecialDocument;
        this.isLink = 'undefined' === typeof isLink ? false : isLink;
        this.isPublic = 'undefined' === typeof isPublic ? true : isPublic;
        this.fileExtension = fileExtension;
        this.contentHash = contentHash;
        this.pageUrl = pageUrl;
        this.updateNotes = updateNotes;
        this.isContentUpdated = isContentUpdated;
        this.seriesId = seriesId || ComplianceManagerConstants.DefaultGuid;
        this.documentType = documentType || DocumentType.Standalone;
    }

    public getChoicesByVocabulary = (vocabulary: VocabularyDefinition): VocabularySelection | undefined => {
        const vocabularyName = vocabulary.name;
        const choiceSelections = this.vocabularySelections.find((sel) => sel.vocabularyName.toLowerCase() === vocabularyName.toLowerCase());

        if (choiceSelections && choiceSelections.vocabularyValueIds.length === 1 && choiceSelections.vocabularyValueIds[0] === 'All') {
            // if 'All' is selected then return every choice as an array
            return {
                vocabularyName,
                vocabularyValueIds: vocabulary.choices.map((c) => c.id)
            };
        }

        return choiceSelections;
    }

    public getDocumentOrSeriesId = () : string => {
        return this.documentType === DocumentType.Series ? this.seriesId : this.documentId;
    }

    public getLink = (): string | undefined => {
        if (this.isDocument === true) {
            return getExternalLink(this.contentHash || '');
        }
        return this.pageUrl;
    }

    public isStarterPackZip = (): boolean => {
        const hardCodedZipVocab: VocabularyDefinition = {
            name: 'isStarterPackSummary',
            id: '',
            defaultSelectAllValue: false,
            description: '',
            displayText: { li: '', lv: '' },
            isMultiSelect: false,
            isSensitive: false,
            toolTip: '',
            itemStorageType: '',
            useToRestrictContentToCertainCustomers: false,
            choices: []
        };

        // see if 'true' has been chosen for isStarterPackSummary
        const zip = this.getChoicesByVocabulary(hardCodedZipVocab);
        return (zip !== undefined && zip.vocabularyValueIds && zip.vocabularyValueIds[0] === 'true');
    }

    public canCheckItem = (): boolean => {
        return this.isDocument;
    }

    /**
     * This static helper method can be used to sort TableItems:
     *
     * items.sort((i1, i2) => TableItem.sort(i1, i2, this.state.orderBy));
     *
     * @param i1 TableItem one for sort compare
     * @param i2 TableItem two for sort compare
     * @param orderBy The current orderBy values to sort on
     */
    static sort(i1: TableItem, i2: TableItem, orderBy: { name: string, asc: boolean }) {
        if (orderBy.name === 'title') {
            const title1 = i1.title;
            const title2 = i2.title;
            return orderBy.asc ?
                (title1 < title2 ? -1 : title1 > title2 ? 1 : 0) :
                (title1 > title2 ? -1 : title1 < title2 ? 1 : 0);
        } else if (orderBy.name === 'description') {
            const des1 = i1.description;
            const des2 = i2.description;
            return orderBy.asc ?
                (des1 < des2 ? -1 : des1 > des2 ? 1 : 0) :
                (des1 > des2 ? -1 : des1 < des2 ? 1 : 0);
        } else {
            const time1 = i1.date.getTime();
            const time2 = i2.date.getTime();
            return orderBy.asc ? time1 - time2 : time2 - time1;
        }
    }

    static fromDocument(doc: DocumentVersionInfo, useReportDate: boolean = false) {
        return new TableItem(
            doc.documentId,
            getLocalizedString(doc.title),
            getLocalizedString(doc.description),
            useReportDate ? getDocumentReportDate(doc) : getDocumentDate(doc),
            getDocumentReportDate(doc),
            doc.vocabularySelections,
            doc.contentHash,
            true,
            doc.isSpecialDocument,
            isExternalLink(doc.contentHash),
            doc.isPublic,
            doc.fileExtension,
            undefined,
            getLocalizedString(doc.updateNotes),
            doc.isContentUpdated,
            doc.seriesId,
            doc.documentType
        );
    }

    static fromPage(page: PageMetadata) {
        return new TableItem(
            page.pageId,
            getLocalizedString(page.title) || 'Untitled Page',
            getLocalizedString(page.description) || '',
            new Date(page.whenApprovedOrRejected),
            new Date(page.whenApprovedOrRejected),
            page.vocabularySelections,
            undefined,
            false,
            false,
            true,
            true,
            undefined,
            `/ViewPage/${page.urlName}`,
            undefined,
            false
        );
    }
}

export default TableItem;