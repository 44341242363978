import * as React from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Dropdown } from 'office-ui-fabric-react/lib/components/Dropdown';
import { PanelPropertiesDataBase } from '../../Models/PageInfrastructure/PanelPropertiesData/';
import { PanelData } from '../../Models/PageInfrastructure';
import Constants from '../../Utilities/Constants';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';

interface PanelFlightAndPermissionEditorProps {
    panel: PanelData<PanelPropertiesDataBase>;
    updateFlightAndPermission?: (panel: PanelData<PanelPropertiesDataBase>) => void;
}

interface PanelFlightAndPermissionEditorState {
    // Present the current text in required flight text box, avoid joining all the values with comman all the time
    flightValue: string;
}

export class PanelFlightAndPermissionEditor extends React.Component<PanelFlightAndPermissionEditorProps, PanelFlightAndPermissionEditorState> {
    private panel: PanelData<PanelPropertiesDataBase>;
    constructor(props: PanelFlightAndPermissionEditorProps) {
        super(props);
        this.panel = this.props.panel;
        this.state = {
            flightValue: this.props.panel.requiredFlights ? this.props.panel.requiredFlights.join(',') : ''
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: PanelFlightAndPermissionEditorProps) {
        this.panel = newProps.panel;
    }

    render() {
        return (
            <div className="row" style={{background: '#f2f2f2', margin: '0px', paddingLeft: '10px'}}>
                <div className="col" style={{ marginBottom: '10px' }}>
                    <TextField
                        value={this.state.flightValue} 
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string)=> {
                            this.panel.requiredFlights = newVal ? newVal.split(',') : [];
                            if (this.props.updateFlightAndPermission) {
                                this.setState({flightValue: newVal});
                                this.props.updateFlightAndPermission(this.panel);
                            }
                        }} 
                        label={Intl.Get(LocIds.PageAndPanels.RequiredFlightsLabel)} 
                    />
                </div>
                <div className="col">
                    <TextField 
                        value={this.panel.scope || ''}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {this.panel.scope = newVal.replace(/\s/g, ''); if (this.props.updateFlightAndPermission) {this.props.updateFlightAndPermission(this.panel); }}}
                        label={'Scope'}
                    />
                </div>
                <div className="col">
                    <Dropdown
                        label="Permissions(any)"
                        placeHolder="Select Permissions"
                        multiSelect={true}
                        options={Constants.PERMISSIONS_OPTIONS}
                        defaultSelectedKeys={this.panel.requiredPermissions && this.panel.requiredPermissions.actions ? this.panel.requiredPermissions.actions : undefined}
                        onChanged={
                            (option) => {
                                let newAction: string[] = [];
                                if (!this.panel.requiredPermissions) {
                                    this.panel.requiredPermissions = {actions: [] as string[]};
                                    }
                                let oldAction = this.panel.requiredPermissions.actions;
                                if (!oldAction) {
                                    oldAction = [] as string[];
                                }
                                if (option.selected) {
                                    for (let i = 0; i < Constants.PERMISSIONS_OPTIONS.length; i++) {
                                        if (option.key === Constants.PERMISSIONS_OPTIONS[i].key || oldAction.findIndex(item => item === Constants.PERMISSIONS_OPTIONS[i].key) !== -1) {
                                            newAction.push(Constants.PERMISSIONS_OPTIONS[i].key);
                                        }
                                    }
                                } else {
                                    for (let i = 0; i < Constants.PERMISSIONS_OPTIONS.length; i++) {
                                        if (option.key !== Constants.PERMISSIONS_OPTIONS[i].key && oldAction.findIndex(item => item === Constants.PERMISSIONS_OPTIONS[i].key) !== -1) {
                                            newAction.push(Constants.PERMISSIONS_OPTIONS[i].key);
                                        }
                                    }
                                }
                                this.panel.requiredPermissions.actions = newAction;
                                if (this.props.updateFlightAndPermission) {
                                    this.props.updateFlightAndPermission(this.panel);
                                }
                            }
                        }
                    />    
                </div>    
            </div>
        );
    }
}