import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

export interface ConfirmationDialogProps {
    hidden: boolean;
    title: string;
    subText: string;
    onConfirmed: () => void;
    onCancel: () => void;
    titleAriaId?: string;
    subtitleAriaId?: string;
}

export class ConfirmationDialog extends React.Component<ConfirmationDialogProps> {
    public render() {
        return (
            <Dialog
                hidden={this.props.hidden}
                onDismiss={this.props.onCancel}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: this.props.title,
                    subText: this.props.subText
                }}
                modalProps={{
                    titleAriaId: this.props.titleAriaId === null ? this.props.title : this.props.titleAriaId,
                    subtitleAriaId: this.props.subtitleAriaId === null ? this.props.subText : this.props.subtitleAriaId,
                    isBlocking: true,
                    containerClassName: 'ms-dialogMainOverride'
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => this.props.onConfirmed()} text="Confirm" />
                    <DefaultButton onClick={() => this.props.onCancel()} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }
}