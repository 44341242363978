import { LocalizationIds as LocIds } from '../Utilities/Globalization/IntlEnum';
import { Intl } from '../../App/Services/GlobalizationService';
import { CustomerActionType, UserActionStatus, ImplementationStatus } from '../../App/Models/ComplianceManagerV3';
import { ApprovalStatus } from '../../App/Models/ComplianceManagerV3/ApprovalStatus';

export enum HttpVerbs {
  get = 'get',
  post = 'post',
  delete = 'delete',
  put = 'put',
  patch = 'patch'
}

export enum HttpStatusCode {
  ok = 200,
  internalServerError = 500
}

export interface KeyNamePair {
  key: string;
  name: string;
}

export enum ConflictStatus {
  None,
  Accept,
  Reject,
  Rollback
}

export enum AuthorizationScopes {
  ComplianceManagerV3,
  TenantManagement
}

export interface DropDown {
  key: string;
  text: string;
}

export interface KeyValuePair {
  key: string;
  text: string;
}

export interface ComboBox {
  key: string;
  text: string;
}

export interface Owner {
  key: string;
  text: string;
  multiSelectable: boolean;
}

export enum DashboardStatus {
  Compliant,
  NonCompliant,
  NotStarted,
  InProgress
}

export function ImplementationStatusDropDown(): DropDown[] {
  return [
    { key: 'Select', text: Intl.Get(LocIds.ComplianceManager.ActionItemsColumnSelect) },
    { key: 'NotImplemented', text: Intl.Get(LocIds.ComplianceManager.ActionItemsNotImplementedLabel) },
    { key: 'Implemented', text: Intl.Get(LocIds.ComplianceManager.ActionItemsImplementedLabel) },
    { key: 'AlternativeImplementation', text: Intl.Get(LocIds.ComplianceManager.ActionItemsAlternativeImplementationLabel) },
    { key: 'Planned', text: Intl.Get(LocIds.ComplianceManager.ActionItemsPlannedLabel) },
    { key: 'NotInScope', text: Intl.Get(LocIds.ComplianceManager.ActionItemsNotInScopeLabel) }
  ];
}

export function ActionImplementationStatusDropDown(): DropDown[] {
  return [
    { key: ImplementationStatus[ImplementationStatus.Select], text: Intl.Get(LocIds.ComplianceManager.ActionItemsColumnSelect) },
    { key: ImplementationStatus[ImplementationStatus.NotImplemented], text: Intl.Get(LocIds.ComplianceManager.ActionItemsNotImplementedLabel) },
    { key: ImplementationStatus[ImplementationStatus.Implemented], text: Intl.Get(LocIds.ComplianceManager.ActionItemsImplementedLabel) },
    { key: ImplementationStatus[ImplementationStatus.AlternativeImplementation], text: Intl.Get(LocIds.ComplianceManager.ActionItemsAlternativeImplementationLabel) },
    { key: ImplementationStatus[ImplementationStatus.Planned], text: Intl.Get(LocIds.ComplianceManager.ActionItemsPlannedLabel) },
    { key: ImplementationStatus[ImplementationStatus.NotInScope], text: Intl.Get(LocIds.ComplianceManager.ActionItemsNotInScopeLabel) },
    { key: ImplementationStatus[ImplementationStatus.PartiallyImplemented], text: Intl.Get(LocIds.ComplianceManagerV3.ActionItemsPartiallyImplementedLabel) },
    { key: ImplementationStatus[ImplementationStatus.ToBeDetermined], text: Intl.Get(LocIds.ComplianceManagerV3.ActionItemsToBeDeterminedLabel) },
    { key: ImplementationStatus[ImplementationStatus.CouldNotBeDetermined], text: Intl.Get(LocIds.ComplianceManagerV3.ActionItemsCouldNotBeDeterminedLabel) }
  ];
}

export function TestStatusDropDown(): DropDown[] {
  return [
    { key: 'Select', text: Intl.Get(LocIds.ComplianceManager.ActionItemsColumnSelect) },
    { key: 'NotAssessed', text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailNotAssesseddLabel) },
    { key: 'Passed', text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailPassedLabel) },
    { key: 'FailedLowRisk', text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailFailedLowRiskLabel) },
    { key: 'FailedMediumRisk', text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailFailedMediumRiskLabel) },
    { key: 'FailedHighRisk', text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailFailedHighRiskLabel) },
    { key: 'NotInScope', text: Intl.Get(LocIds.ComplianceManager.ActionItemsNotInScopeLabel) }
  ];
}

export function ActionTestStatusDropDown(): DropDown[] {
  return [
    { key: UserActionStatus[UserActionStatus.Select], text: Intl.Get(LocIds.ComplianceManager.ActionItemsColumnSelect) },
    { key: UserActionStatus[UserActionStatus.NotAssessed], text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailNotAssesseddLabel) },
    { key: UserActionStatus[UserActionStatus.Passed], text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailPassedLabel) },
    { key: UserActionStatus[UserActionStatus.FailedLowRisk], text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailFailedLowRiskLabel) },
    { key: UserActionStatus[UserActionStatus.FailedMediumRisk], text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailFailedMediumRiskLabel) },
    { key: UserActionStatus[UserActionStatus.FailedHighRisk], text: Intl.Get(LocIds.ComplianceManager.FrameworkDetailFailedHighRiskLabel) },
    { key: UserActionStatus[UserActionStatus.NotInScope], text: Intl.Get(LocIds.ComplianceManager.ActionItemsNotInScopeLabel) },
    { key: UserActionStatus[UserActionStatus.ToBeDetermined], text: Intl.Get(LocIds.ComplianceManagerV3.ActionItemsToBeDeterminedLabel) },
    { key: UserActionStatus[UserActionStatus.CouldNotBeDetermined], text: Intl.Get(LocIds.ComplianceManagerV3.ActionItemsCouldNotBeDeterminedLabel) },
    { key: UserActionStatus[UserActionStatus.PartiallyTested], text: Intl.Get(LocIds.ComplianceManagerV3.ActionItemsPartiallyTestedLabel) },
  ];
}

export function statusApprovalDisplay(templateStatus: ApprovalStatus): string {
  switch (templateStatus) {
    case ApprovalStatus.Imported: return Intl.Get(LocIds.ComplianceManagerV3.ImportedApprovalStatusLabel);
    case ApprovalStatus.PendingApproval: return Intl.Get(LocIds.ComplianceManagerV3.PendingApprovalStatusLabel);
    case ApprovalStatus.Approved: return Intl.Get(LocIds.ComplianceManagerV3.ApprovedApprovalStatusLabel);
    case ApprovalStatus.Rejected: return Intl.Get(LocIds.ComplianceManagerV3.RejectedApprovalStatusLabel);
    case ApprovalStatus.Archived: return Intl.Get(LocIds.ComplianceManagerV3.ArchivedApprovalStatusLabel);
    case ApprovalStatus.BackUp: return Intl.Get(LocIds.ComplianceManagerV3.BackUpApprovalStatusLabel);
    default:
      return ''
  }
}

export function GetActionTypeOptions(): DropDown[] {
  return [
    { key: CustomerActionType.None.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesNoneLabel) },
    { key: CustomerActionType.Documentation.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesDocumentationLabel) },
    { key: CustomerActionType.Operational.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesOperationalLabel) },
    { key: CustomerActionType.Procedural.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesProceduralLabel) },
    { key: CustomerActionType.Technical.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesTechnicalLabel) },
    { key: CustomerActionType.Contractual.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesContractualLabel) },
    { key: CustomerActionType.Management.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesManagementLabel) },
    { key: CustomerActionType.Privacy.toString(), text: Intl.Get(LocIds.ComplianceManagerV3.ActionTypesPrivacyLabel) },
  ];
}

export enum ActionItemStatus {
  Implement = 'Implemented',
  Review = 'Review',
  Test = 'Test'
}

export enum RecordType {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete'
}

export enum UsageMapType {
  Assessment,
  Template,
  CustomerAction
}

export enum TestResultStatus {
  Select,
  NotAssessed,
  Passed,
  FailedLowRisk,
  FailedMediumRisk,
  FailedHighRisk,
}

export module ComplianceManagerConstants {

  export enum SelectionMode {
    none = 0,
    single = 1,
    multiple = 2,
  }
  export const Yes: string = 'Yes';
  export const No: string = 'No';
  export const ResponsiblePartiesLookup: string = 'ResponsibleParties';
  export const DashboardTilesEntity = 'DashboardTiles';
  export const CustomerActionsLookup: string = 'CustomerActions';
  export const IsMultiSelect: string = 'isMultiSelect';
  export const customerActionsLookup: string = 'CustomerActions';
  export const ActionTestResult: string = 'testResultStatus';
  export const ActionImplementationStatus: string = 'implementationStatus';
  export const ActionImplementation: string = 'Implementation';
  export const ActionTestPlan: string = 'Test Plan';
  export const ActionManagementResponse: string = 'Management Response';
  export const ActionStatus: string = 'Action Status';
  export const ActionTitle: string = 'title';
  export const ActionTitlefield: string = 'Action Title';
  export const Title: string = 'Title';
  export const Header: string = 'Header';
  export const ActionTestDate: string = 'When Tested';
  export const ActionImplementationDate: string = 'When Implemented';
  export const set: string = 'Set';
  export const New: string = 'New';
  export const Existing: string = 'Existing';
  export const ActionViewHistory: string = 'ViewHistory';
  export const groupId: string = '9876b597-43e4-4200-baae-ed96763e5ca3';
  export const assessmentId: string = '9876b597-43e4-4200-baae-ed96763e5ca3';
  export const defaultComplianceScore: number = 0;
  export const MaxLengthComplianceScore: number = 2;
  export const contextualMenuWidth: number = 185;
  export const ModifiedDate: string = '3 weeks ago';
  export const MaxScore: string = '4034';
  export const InProgress: string = 'In Progress';
  export const DateFormat: string = 'DD-MMM-YYYY';
  export const ControlDetailControlIDLabel: string = 'Control ID:';
  export const ControlDetailControlTitleLabel: string = 'Control Title:';
  export const ControlDetailArticleIDLabel: string = 'Supported GDPR Article(s):';
  export const ControlDetailDescriptionLabel: string = 'Description';
  export const RenameAssessment: string = 'Rename Assessment';
  export const ArchiveAssessment: string = 'Archive Assessment';
  export const AssessmentComplianceScore: string = 'Assessment Score';
  export const ComplianceManagerV3Flights: string[] = ['ComplianceManagerV3'];
  export const ComplianceManagerV3GlobalTenantFlights: string[] = ['ManageGlobalTenantInCMV3'];
  export const Disabled: string = 'Disabled';
  export const DefaultGuid = '00000000-0000-0000-0000-000000000000';
  export const AddGuid = '11111111-1111-1111-1111-111111111111';
  export const defaultControlFamilyGuid: string = '0cf4d9a1-e34f-4a4d-b548-f1915d8d1451';
  export const defaultControlGuid: string = '0cf4d9a1-e34f-4a4d-b548-f1915d8d1452';
  export const customEditPanel: string = '1200px';
  export const customEditDesc: string = '600px';
  export const customAddTemplate: string = '450px';
  export const customControlInfoImport: string = '450px';
  export const customAddControl: string = '500px';
  export const keyLabel: string = 'key';
  export const valueLabel: string = 'value';
  export const Name: string = 'Name';
  export const ManageDocumentsname: string = 'name';
  export const modifiedBy: string = 'modifiedBy';
  export const dateModifiedValue: string = 'dateModifiedValue';
  export const fileSizeRaw: string = 'fileSizeRaw';
  export const Uploading: string = 'Uploading';
  export const Upload: string = 'Upload';
  export const Delete: string = 'Delete';
  export const Download: string = 'Download';
  export const Edit: string = 'edit';
  export const CreatedDate: string = 'CreatedDate';
  export const CreatedDateAscending: string = 'CreatedDateAscending';
  export const CreatedDateDescending: string = 'CreatedDateDescending';
  export const ModifiedDateAscending: string = 'ModifiedDateAscending';
  export const ModifiedDateDescending: string = 'ModifiedDateDescending';
  export const SortModifiedDate: string = 'ModifiedDate';
  export const TenantManagementEdit: string = 'Edit';
  export const Archive: string = 'archive';
  export const Approve: string = 'approve';
  export const Reject: string = 'reject';
  export const CopyLink: string = 'Copy Link';
  export const Copy: string = 'copy';
  export const AssessmentUpdates: string = 'AssessmentUpdates';
  export const TemplateUpdates: string = 'TemplateUpdates';
  export const ExportToExcel: string = 'export';
  export const ExportAsExcel: string = 'exportAsExcel';
  export const Unlock: string = 'unlock';
  export const Lock: string = 'lock';
  export const Publish: string = 'publish';
  export const customerActionTiltle: string = 'customerActionTiltle';
  export const Remove: string = 'Remove';
  export const DimensionLookup: string = 'Dimensions';
  export const AffectedActionLabel: string = 'AffectedAction';
  export const customerActionsContinuousUpdateScore: string = 'Continuous Update by Secure Score';
  export const customerActionsScore: string = 'Max Compliance Score';
  export const divHeader: string = 'divHeader';
  export const customerActionsOwner: string = 'Owner';
  export const Command: string = 'Command';
  export const Ellipsis: string = 'ellipsis';
  // To be deleted and moved to UI Strings
  export const SelectImplementationStatusPlaceHolder: string = 'Select Implementation Status';
  export const SameCustomerActionLinkingValidationMessage = 'Cannot link same action, please select different action.';
  export const SelectAtleastOneActionValidationMessage = 'Please select at-least one action from the list.';
  export const SelectActionNotPartOfExistingActionsValidationMessage = 'Selected action is already linked. Please select different action.';
  export const UIMaxScore: number = 999;
  export const controlIDAlreadyExist: string = 'Control ID already Exists';
  export const SecureScoreMappedActionMessage: string = 'This action cannot be manually implemented as this action is mapped and enabled for Secure Score auto update.';
  // Closed xml does not support xls
  export const validFileExtensionsForImport: string[] = ['xlsx'];
  export const ExportTemplateFileName: string = 'TemplateWithValidations.xlsx';
  export const OwnerSortByName: string = 'name';
  export const SortKey: string = 'whenCreated';
  export const SortOrder: string = 'desc';
  export const GlobalTenantCMV3Flight: string = 'ManageGlobalTenantInCMV3';
  export const RestrictComplianceManagerAccessFlight: string = 'RestrictComplianceManagerAccess';

  export const DownloadSampleFile: string = 'https://go.microsoft.com/fwlink/?linkid=2090531';
  export const DownloadSampleFileV4: string = 'https://go.microsoft.com/fwlink/?linkid=2124865';
  export const ChangeLogLinkCMV2: string = 'https://aka.ms/Bpegyt';
  export const ChangeLogLinkCMV3: string = 'https://aka.ms/cmv3notify';
  
  export const DocumentationLink: string = "https://go.microsoft.com/fwlink/?linkid=2122100"
  export const LearnHowItWorksLink: string = "https://go.microsoft.com/fwlink/?linkid=2109058"
  export const ComplianceCenterLink: string = "https://docs.microsoft.com/en-us/microsoft-365/compliance/microsoft-365-compliance-center"
  export const SetPermissionsLink: string = "https://go.microsoft.com/fwlink/?linkid=2108804"
  export const ComplianceCenterPermissionLink: string = "https://go.microsoft.com/fwlink/?linkid=2143274"
  export const ComplianceManagerAdminRoleLearnMoreLink: string = "https://go.microsoft.com/fwlink/?linkid=2143509"

  export const ProductDimensionKey: string = 'Product';
  export const CertificationDimensionKey: string = 'Certification';
  export const MaxTemplateUploadSizeInBytes : number = 4194304;

  export const Microsoft: string = 'Microsoft';

  export const UploadType = {
    Evidence : 'Evidence',
    ImportTemplate : 'ImportTemplate'
  }

  export const BulkUpdateActionsTemplateName: string = 'Bulk Action Update';

  export const Routes = {
    'Dashboard': '/ComplianceManager/V3',
    'Assessments': '/ComplianceManager/V3',
    'Templates': '/ComplianceManager/V3/Templates',
    'ActionItems': '/ComplianceManager/V3/ActionItems',
    'TenantManagement': '/ComplianceManager/V3/TenantManagement',
    'Dimensions': '/ComplianceManager/V3/TenantManagement',
    'ResponsibleParties': '/ComplianceManager/V3/TenantManagement/ResponsibleParties',
    'CustomerActions': '/ComplianceManager/V3/TenantManagement/CustomerActions',
    'ControlsInfoAssessment': '/ComplianceManager/V3/ControlsInfo/Assessment',
    'ControlsInfoTemplate': '/ComplianceManager/V3/ControlsInfo/Template',
    'ComplianceManagerV2': '/ComplianceManager'
  };

  export enum RedirectRoutes {
    Dashboard = 'Dashboard',
    Assessments = 'Assessments',
    Templates = 'Templates',
    ImprovementActions = 'ImprovementActions',
    ImprovementActionId = 'ImprovementActionId',
    TenantManagement = 'TenantManagement',
    ControlsInfoAssessment = 'ControlsInfoAssessment',
    ControlsInfoAssessmentId = 'ControlsInfoAssessmentId',
    ControlsInfoTemplate = 'ControlsInfoTemplate',
    ControlsInfoTemplateId = 'ControlsInfoTemplateId'
  }

  export const ApiPaths = {
    'Assessments': '/api/v3/Assessments',
    'AssessmentsByGroup': '/api/v3/Assessments/Group',
    'AssessmentById': '/api/v3/AssessmentById',
    'ResolveTemplateConflict': '/api/v3/Templates/AcceptUpdatesToParentTemplate',
    'ResolveAssessmentConflict': '/api/v3/Assessments/AcceptUpdatesToParentTemplate',
    'AssessmentCheckForUpdates': '/api/v3/Assessments/CheckForUpdates',
    'AssessmentsAcceptUpdates': '/api/v3/Assessments/AcceptUpdates',
    'ActionItemsAcceptUpdates':'/api/v3/ActionItems/AcceptUpdates',
    'TemplateById': '/api/v3/TemplateById',
    'Templates': '/api/v3/Template',
    'ValidateImport': '/api/v3/Template/ValidateImport',
    'TemplatesGetAll': '/api/v3/Templates/GetAll',
    'TemplatesAcceptUpdates': '/api/v3/Templates/AcceptUpdates',
    'TemplateCheckForUpdates': '/api/v3/Templates/CheckForUpdates',
    'DownloadTemplate': '/api/v3/Template/DownloadErrorFile',
    'DownloadSample': '/api/v3/Template/DownloadSampleFile',
    'Dimensions': '/api/v3/Dimensions',
    'ResponsibleParties': '/api/v3/ResponsibleParties',
    'CustomerActions': '/api/v3/CustomerActions',
    'CustomerActionsContinuousUpdate': '/api/v3/customeractions/secure-score-continuous-update',
    'CustomerActionsGetAll': '/api/v3/CustomerActions/GetAll',
    'CustomerActionsById': '/api/v3/CustomerActions/{id}',
    'ActionItems': '/api/v3/ActionItems',
    'AssignTask': '/api/v3/ActionItems/AssignTask',
    'microsoftActionItem': '/api/v3/ActionItems',
    'Groups': '/api/v3/Groups',
    'AssessmentsViewForActions': '/api/v3/AssessmentsViewForActions',
    'AddCustomControl': '/api/v3/AddCustomControl',
    'ControlFamily': '/api/v3/ControlFamilies',
    'ControlNameValidation': '/api/v3/ControlFamilies/ValidateControl',
    'ControlFamilyImport': '/api/v3/ControlFamilies/ImportControls',
    'AssignCustomerAction': '/api/v3/AssignCustomerAction',
    'ValidateTemplate': '/api/v3/Template/ValidateFile',
    'ExportToExcel': '/api/v3/ComplianceDashboard/ExportToExcel/',
    'UserActions': '/api/v3/UserAction',
    'GroupSummary': '/api/v3/GroupSummary',
    'FileTransfer': '/api/v3/FileTransfer',
    'GroupDetails': '/api/v3/GroupDetails',
    'ApproveTemplate': '/api/v3/Template/Approve',
    'PublishTemplate': '/api/v3/Template/Publish',
    'RejectTemplate': '/api/v3/Template/Reject',
    'IsTemplateApproved': '/api/v3/Template/IsTemplateApproved',
    'ExportTemplate': '/api/v3/ExportTemplateById',
    'ExportTemplateAsExcel': '/api/v3/ExportTemplate',
    'ExportAllActionsAsExcel': '/api/v3/Actions/Export',
    'FilterdControls': '/api/v3/ControlFamily/FilterdControls',
    'ControlsCategory': '/api/v3/ControlFamily/Categories',
    'GetGroupMetaData': '/api/v3/Groups/Metadata',
    'SetPreferredGroup': '/api/v3/Group/Preferred',
    'AcquireTemplateEtag': '/api/v3/template/AcquireEtag',
    'GetControlById': '/api/v3/GetControlById',
    'ValidatePendingTemplate': '/api/v3/Template/ValidatePending',
    'ValidateTemplateTitle': '/api/v3/template/ValidateTemplateTitle',
    'CheckTemplateReference': '/api/v3/CheckTemplateReference',
    'EnableSecureScoreForTenant': '/api/v3/SecureScoreTenantSettings',
    'TenantSettings': '/api/TenantSettings',
    'IsMSDPAssessmentExist': '/api/v3/IsMSDPAssessmentExist',
    'CreateMSDPAssessmentIfNotExist': '/api/v3/CreateMSDPAssessmentIfNotExist'
  };

  export const Scopes = {
    'All': '/',
    'ComplianceManagerV3': '/ComplianceManagerV3',
    'ComplianceManagerV3Master': '/ComplianceManagerV3/Master',
    'ComplianceManagerV3Customer': '/ComplianceManagerV3/Customer',
    'ComplianceManagerV3Test': '/ComplianceManagerV3/Test',
    'ComplianceManagerV3Evidence': '/ComplianceManagerV3/Evidence',
    'ComplianceManagerGlobalUser': '/ComplianceManager/Microsoft',
    'SettingsAnyRoles': '/Settings/AnyRoles',
    'SettingsAdminRoles': '/Settings/AdminRoles',
    'SettingsNonAdminRoles': '/Settings/NonAdminRoles',
    'SettingsUserPrivacy': '/Settings/UserPrivacy',
    'ComplianceManager': '/ComplianceManager',
    'ComplianceManagerCustomer': '/ComplianceManager/Customer',
    'ComplianceManagerCustomerControls': '/ComplianceManager/Customer/Controls',
    'FileUploader': '/FileUploader'
  };
}