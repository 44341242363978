import * as React from 'react';
import { ContextualMenu, DirectionalHint, IContextualMenuProps, IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';

interface DropdownMenuComponentState {
    isContextMenuVisible?: boolean;
    // tslint:disable-next-line:no-any
    target?: any;
    selectedText?: string;
}

interface DropdownMenuComponentProps extends IContextualMenuProps {
    selectedText?: string;
    onChange?: Function;
    // tslint:disable-next-line:no-any
    context?: any;
    width?: number;
}

export class DropdownMenuComponent extends React.Component<DropdownMenuComponentProps, DropdownMenuComponentState> {
    // tslint:disable-next-line:no-any
    refDiv: any;

    constructor(props: DropdownMenuComponentProps) {
        super(props);
        this.state = {
            isContextMenuVisible: false,
            selectedText: this.props.selectedText,
        };
        this._onClick = this._onClick.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
        this._onDismiss = this._onDismiss.bind(this);
        this._onItemClick = this._onItemClick.bind(this);
    }
    UNSAFE_componentWillReceiveProps(newProps: DropdownMenuComponentProps) {
        this.state = {
            isContextMenuVisible: false,
            selectedText: newProps.selectedText,
        };
    }
    render() {
        return (
            <div style={{ cursor: 'pointer' }}>
                <div tabIndex={0} onKeyPress={this._onKeyPress} onClick={this._onClick}>
                    <div style={{ fontSize: '15px', display: 'inline-flex' }}>
                        <span
                            ref={(div) => this.refDiv = div}
                            style={{width: this.props.width ? this.props.width + 'px' : 'auto', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left', marginLeft: '4px'}}
                        >
                            {this.state.selectedText ? this.state.selectedText : 'Select'}
                        </span>
                        <i style={{ paddingTop: '4px' }} className="ms-Icon ms-Icon--ChevronDown" role="presentation" aria-hidden="true" />
                    </div>
                </div>
                {this.state.isContextMenuVisible ?
                    <ContextualMenu
                        onItemClick={this._onItemClick}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        target={this.refDiv}
                        items={this.props.items}
                        onDismiss={this._onDismiss}
                        className="dropdownMenuClass"
                    />
                    : (null)}
            </div>
        );
    }

    private _onItemClick(ev: React.MouseEvent<HTMLElement>, item: IContextualMenuItem) {
        this.setState({ isContextMenuVisible: false });

        // if there is no change, avoid calling onchange methods or updating values
        if (this.state.selectedText === item.name) {
            return;
        }
        this.setState({ selectedText: item.name });
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(item.key, this.props.context);
        }
    }

    private _onClick(event: React.MouseEvent<{}>) {
        event.stopPropagation();
        this.setState({ isContextMenuVisible: true });
    }
    private _onKeyPress(event: React.KeyboardEvent<HTMLElement>) {
        event.stopPropagation();
        if (event.key === 'Enter') {
            this.setState({ isContextMenuVisible: true });
        }
    }
    private _onDismiss() {
        this.setState({ isContextMenuVisible: false });
    }
}
