import * as React from 'react';
import PageHeading from '../Components/PageHeading';

export interface TrialSubscriptionsPageProps {
}

export default class TrialSubscriptionsPage extends React.Component<TrialSubscriptionsPageProps> {
    render() {
        return (
            <div>
                <PageHeading title="Trial Subscriptions" />
                <section data-grid="col-12 pad-12x">
                    <div data-grid="col-1" />
                    <div data-grid="col-10">
                        <h3 className="c-heading-3">How do I get access to protected information?</h3>
                        <p className="c-paragraph-3">
                            All you need is a paid or free trial subscription to any one of the Microsoft cloud services.
                    </p>
                        <p className="c-paragraph-3">
                            If you already have a subscription then use your existing account to sign in after you click on protected content links.
                    </p>
                        <p className="c-paragraph-3">
                            To get a free trial subscription, you can use any of the links below.
                        Once you create a trial account, you will able to use that account to sign in upon clicking on any protected content download link on this site or Microsoft Trust Center.
                    </p>

                    </div>
                    <div data-grid="col-1" />
                </section>

                <section data-grid="col-12 pad-12x">
                    <div data-grid="col-1" />
                    <div data-grid="col-10">

                        <div className="m-product-placement f-app" data-grid="col-12">
                            <h2 className="c-heading-5">
                                <span aria-hidden="true">Trial Subscriptions</span>
                            </h2>

                            <ul className="c-group">
                                <li>
                                    <section className="m-product-placement-item f-size-medium context-app" itemType="http://schema.org/Offer">
                                        <a href="https://go.microsoft.com/fwlink/?linkid=789061" target="_blank">
                                            <picture>
                                                <source srcSet="/Images/Dynamics365.png" media="(min-width:0)" />
                                                <img className="c-image" srcSet="/Images/Dynamics365.png" src="/Images/Dynamics365.png" alt="Microsoft Dynamics 365 logo" />
                                            </picture>
                                            <div>
                                                <h3 className="c-heading">Dynamics 365 Trial</h3>
                                                <div className="c-price" itemProp="offers" itemType="">
                                                    <meta itemProp="priceCurrency" content="USD" />
                                                    <span itemProp="price">FREE</span>
                                                </div>
                                            </div>
                                        </a>
                                    </section>
                                </li>
                                <li>
                                    <section className="m-product-placement-item f-size-medium context-app" itemType="http://schema.org/Offer">
                                        <a href="https://go.microsoft.com/fwlink/p/?LinkID=507653" target="_blank">
                                            <picture>
                                                <source srcSet="/Images/Office365.png" media="(min-width:0)" />
                                                <img className="c-image" srcSet="/Images/Office365.png" src="/Images/Office365.png" alt="Microsoft Office 365 logo" />
                                            </picture>
                                            <div>
                                                <h3 className="c-heading" itemProp="product name">Office 365 Trial</h3>
                                                <div className="c-price" itemProp="offers" itemType="">
                                                    <meta itemProp="priceCurrency" content="USD" />
                                                    <span itemProp="price">FREE</span>
                                                </div>
                                            </div>
                                        </a>
                                    </section>
                                </li>
                                <li>
                                    <section className="m-product-placement-item f-size-medium context-app" itemType="http://schema.org/Offer">
                                        <a href="http://go.microsoft.com/fwlink/?LinkId=722737" target="_blank">
                                            <picture>
                                                <source srcSet="/Images/Azure.png" media="(min-width:0)" />
                                                <img className="c-image" srcSet="/Images/Azure.png" src="/Images/Azure.png" alt="Microsoft Azure logo" />
                                            </picture>
                                            <div>
                                                <h3 className="c-heading" itemProp="product name">Azure Trial</h3>
                                                <div className="c-price" itemProp="offers" itemType="http://go.microsoft.com/fwlink/?LinkId=722737">
                                                    <meta itemProp="priceCurrency" content="USD" />
                                                    <span itemProp="price">FREE</span>
                                                </div>
                                            </div>
                                        </a>
                                    </section>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div data-grid="col-1" />
                </section>

                <section data-grid="col-12 pad-12x">
                    <div data-grid="col-12" />
                </section>

            </div>
        );
    }
}
