import { CMv3ApiResult, CustomerActionType, CustomerActionModel } from '../../App/Models/ComplianceManagerV3';
import { IKeyValuePair } from '../../App/Models/IKeyValuePair';
import { HttpStatusCode } from '../../Common/Models/ComplianceManagerConstants';
import * as _ from 'lodash';
import { UUID } from 'angular2-uuid';


// Declared constanst ,so it can be used in other methods within service to update/delete/add
export const mockCustomerActionslookup: CMv3ApiResult<CustomerActionModel> = {
    GlobalItems: [],
    TenantItems: [
        {
            id: "5e7b1da7-23e6-a443-8eca-2883c9e9d5c4",
            title: "Customer Action 1",
            isAutoCopyEnabled: true,
            affectedActions: [
                {
                    id: "46a653f7-aeca-4d2a-ba64-e678569f120f",
                    title: "action title 1"
                },
                {
                    id: "46a463f7-aeca-4d2a-ba64-e983753f120f",
                    title: "action title 2"
                }
            ],
            dimensions: [
                {
                    id: "5283b597-43e4-4200-baae-ed96763e5ca3",
                    key: 'Product',
                    value: 'O365',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: true 
                },
                {
                    id: "6283b597-43e4-4200-baae-ed96763e5ca3",
                    key: 'Certification',
                    value: 'HIPAA',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: false 
                },
                {
                    id: "7283b597-43e4-4200-baae-ed96763e5ca3",
                    key: 'Certification',
                    value: 'IS0 27000',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: false 
                },
                {
                    id: "0af80b1f-eb13-4ad9-bd95-05e890f8c5c3",
                    key: 'Environment',
                    value: 'MT',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: false 
                },
                {
                    id: "b27e35a5-ce0c-4c3b-936a-e01017b3ccc0",
                    key: 'Environment',
                    value: 'GT',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: false 
                }
            ],
            descriptions: [
                {
                    id: "103f6ec8-bc74-4aa2-b49f-0bc7468d2c6d",
                    title: "CA 1 Control Description Title 1",
                    description: "This is the First Control Description for the Customer Action 1"

                },
                {
                    id: "103f6ec8-bc74-4aa2-b49f-0bc7468d2c6d",
                    title: "CA 1 Control Description Title 2",
                    description: "This is the Second Control Description for the Customer Action 1"
                }
            ],
            maxComplianceScore: 30,
            actionType: CustomerActionType.Technical,
            isContinuousUpdateEnabled: false,
            responsibleParties: [
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'SFB',
                    description: 'Skype For Business',
                    isMappedToComplianceEntities: true
                },
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'EXO',
                    description: 'Exchange Online',
                    isMappedToComplianceEntities: false

                }
            ],
            GlobalItems: [],
            TenantItems: []
        },
        {
            id: "7c1f680d-320c-4e44-85b5-5b026c2843db",
            title: "Customer Action 2",
            isAutoCopyEnabled: false,
            affectedActions: [
                {
                    id: "47c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "action title 1"
                },
                {
                    id: "467c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "action title 2"
                }
            ],
            dimensions: [
                {
                    id: "6683b597-43h4-4200-baae-ed96763e5ca3",
                    key: 'Product',
                    value: 'O365',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: true 
                },
                {
                    id: "f76595b1-21fb-4e81-ad0d-cf6045941021",
                    key: "Certification",
                    value: "HIPAA",
                    isMultiselectionAllowed: true,
                    isMappedToComplianceEntities: false 
                }
            ],
            descriptions: [
                {
                    id: "107c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "CA 2 Control Description Title 1",
                    description: "Customers are responsible for understanding any legal requirements for processing personal data. Customers are also responsible for determining whether consent must first be obtained prior to processing any personal data. To facilitate monitoring for compliance, Microsoft recommends that your organization create and maintain Data Handling policies and standard operating procedures (SOPs) that document when your organization is required to obtain consent prior to processing personal data. We also recommend that your organization regularly review your Data Handling policies and SOPs to ensure that they address the specific legal requirements (or any changes thereto) pertaining to the processing of personal data inlcuding, but not limited to the consent or directives of the data subject regarding their rights, freedoms, or legitimate interests:the regulations governing specific classes of personal data. specific allowances made for archiving or historical purposes. special circumstances surrounding legal claims or defenses; and substantial public interest, public health, or requirements of the law;"

                },
                {
                    id: "1037c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "CA 2 Control Description Title 2",
                    description: "This is the Second Control Description for the Customer Action 2"
                }
            ],
            maxComplianceScore: 30,
            actionType: CustomerActionType.Technical,
            isContinuousUpdateEnabled: false,
            responsibleParties: [
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'Memphis',
                    description: 'Skype For Business',
                    isMappedToComplianceEntities: true
                },
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'Collosus',
                    description: 'Exchange Online',
                    isMappedToComplianceEntities: false
                }
            ],
            GlobalItems: [],
            TenantItems: []
        },
        {
            id: "7c1f680d-320c-4e44-85b5-5b026c2843db",
            title: "Customer Action 3",
            isAutoCopyEnabled: false,
            affectedActions: [
                {
                    id: "47c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "action title 1"
                },
                {
                    id: "467c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "action title 2"
                }
            ],
            dimensions: [
                {
                    id: "6683b597-43h4-4200-baae-ed96763e5ca3",
                    key: 'Product',
                    value: 'O365',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: true 
                },
                {
                    id: "f76595b1-21fb-4e81-ad0d-cf6045941021",
                    key: "Certification",
                    value: "HIPAA",
                    isMultiselectionAllowed: true,
                    isMappedToComplianceEntities: false 
                }
            ],
            descriptions: [
                {
                    id: "107c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "CA 3 Control Description Title 1",
                    description: "Customers are responsible for understanding any legal requirements for processing personal data. Customers are also responsible for determining whether consent must first be obtained prior to processing any personal data. To facilitate monitoring for compliance, Microsoft recommends that your organization create and maintain Data Handling policies and standard operating procedures (SOPs) that document when your organization is required to obtain consent prior to processing personal data. We also recommend that your organization regularly review your Data Handling policies and SOPs to ensure that they address the specific legal requirements (or any changes thereto) pertaining to the processing of personal data inlcuding, but not limited to the consent or directives of the data subject regarding their rights, freedoms, or legitimate interests:the regulations governing specific classes of personal data. specific allowances made for archiving or historical purposes. special circumstances surrounding legal claims or defenses; and substantial public interest, public health, or requirements of the law;"

                },
                {
                    id: "1037c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "CA 3 Control Description Title 2",
                    description: "This is the Second Control Description for the Customer Action 3"
                }
            ],
            maxComplianceScore: 30,
            actionType: CustomerActionType.Technical,
            isContinuousUpdateEnabled: false,
            responsibleParties: [
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'Memphis',
                    description: 'Skype For Business',
                    isMappedToComplianceEntities: true
                },
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'Collosus',
                    description: 'Exchange Online',
                    isMappedToComplianceEntities: false
                }
            ],
            GlobalItems: [],
            TenantItems: []
        },
        {
            id: "7c1f680d-320c-4e44-85b5-5b026c2843db",
            title: "Customer Action 4",
            isAutoCopyEnabled: false,
            affectedActions: [
                {
                    id: "47c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "action title 1"
                },
                {
                    id: "467c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "action title 2"
                }
            ],
            dimensions: [
                {
                    id: "5283b597-43e4-4200-baae-ed96763e5ca3",
                    key: 'Product',
                    value: 'O365',
                    isMultiselectionAllowed: false,
                    isMappedToComplianceEntities: true
                },
                {
                    id: "f76595b1-21fb-4e81-ad0d-cf6045941021",
                    key: "Certification",
                    value: "HIPAA",
                    isMultiselectionAllowed: true,
                    isMappedToComplianceEntities: false 
                }
            ],
            descriptions: [
                {
                    id: "107c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "CA 4 Control Description Title 1",
                    description: "Customers are responsible for understanding any legal requirements for processing personal data. Customers are also responsible for determining whether consent must first be obtained prior to processing any personal data. To facilitate monitoring for compliance, Microsoft recommends that your organization create and maintain Data Handling policies and standard operating procedures (SOPs) that document when your organization is required to obtain consent prior to processing personal data. We also recommend that your organization regularly review your Data Handling policies and SOPs to ensure that they address the specific legal requirements (or any changes thereto) pertaining to the processing of personal data inlcuding, but not limited to the consent or directives of the data subject regarding their rights, freedoms, or legitimate interests:the regulations governing specific classes of personal data. specific allowances made for archiving or historical purposes. special circumstances surrounding legal claims or defenses; and substantial public interest, public health, or requirements of the law;"

                },
                {
                    id: "1037c1f680d-320c-4e44-85b5-5b026c2843db",
                    title: "CA 4 Control Description Title 2",
                    description: "This is the Second Control Description for the Customer Action 4"
                }
            ],
            maxComplianceScore: 30,
            actionType: CustomerActionType.Procedural,
            isContinuousUpdateEnabled: true,
            responsibleParties: [
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'SFB',
                    description: 'Skype For Business',
                    isMappedToComplianceEntities: true
                },
                {
                    id: '93f0953d-60d5-4087-bd27-22e23a377fcb',
                    name: 'Collosus',
                    description: 'Exchange Online',
                    isMappedToComplianceEntities: false
                }
            ],
            GlobalItems: [],
            TenantItems: []
        }
    ]
};



export class MockCustomerActionLookupService {

    // TBD - Add other crud operations based on the need
    getCustomerActionsLookup(): CMv3ApiResult<CustomerActionModel> {
        return mockCustomerActionslookup;
    }

    postCustomerAction(params: IKeyValuePair[], postData: CustomerActionModel): CMv3ApiResult<CustomerActionModel> {
        //Adding customerAction Id for newly created customer action, only for Mock mode
        if (postData.id === null) {
            postData.id = UUID.UUID();
        }
        return {
            GlobalItems:[],
            TenantItems:[postData]
        };
    }

    deleteCustomerAction(params: IKeyValuePair[]) {
        var customerActionLookupData = mockCustomerActionslookup.TenantItems;
        if (customerActionLookupData && params.length > 0 && _.find(customerActionLookupData, x => x.id === params[0].Value)) {
            return { status: HttpStatusCode.ok, data: _.find(customerActionLookupData, x => x.id === params[0].Value) };
        }
        return { status: HttpStatusCode.internalServerError, data: null }
    }

}

export let MockCustomerActionLookupClientService = new MockCustomerActionLookupService();