import * as React from "react";
import { DefaultButton, DialogFooter, IContextualMenuItem, Label, Panel, PanelType, PrimaryButton } from "office-ui-fabric-react";
import TextFieldWrapper from "src/App/Components/TextFieldWrapper";
import { Validator } from '../../../../Common/Utilities/Validator';
import { ErrorResponse } from '../../../Services/Models';
import { ADUserManagementService } from "src/App/Services/ADTenantManagementService";
import { MultiSelectContextualMenu } from "src/Common/Components/MultiSelectContextualMenu";
import { getDeepCopy } from "src/App/Utilities/DeepCopyHelper";
import { NewUserType } from "./ADTenantManagementPage";
import { ADTenantInfo } from "src/App/Models/ADTenantManagement/ADTenantInfo";
import { ADGroupInfo } from "src/App/Models/ADTenantManagement/ADGroupInfo";
import { ADUserInfo } from "src/App/Models/ADTenantManagement/ADUserInfo";

export interface EditUserDialogProps {
    adTenant: ADTenantInfo;
    tenantADGroups: ADGroupInfo[];
    selectedUser: ADUserInfo;
    isAddingNewUser: boolean;
    newUserType: NewUserType | null;
    onDismiss: () => void;
    onSave: (user: ADUserInfo) => void;
}

interface EditUserDialogState {
    user: ADUserInfo;
    saveError?: ErrorResponse;
    selectedIds: string[];
}

export class EditUserDialog extends React.Component<EditUserDialogProps, EditUserDialogState> {
    private uiValidator: Validator = new Validator();
    private Api: ADUserManagementService;

    public constructor(props: EditUserDialogProps) {
        super(props);
        this.Api = new ADUserManagementService();
        this.state = {
            user: this.props.isAddingNewUser ? this.emptyUser({} as ADUserInfo) : getDeepCopy(props.selectedUser),
            selectedIds: []
        }
    }

    private emptyUser(user: ADUserInfo) : ADUserInfo {
         user.displayName = '';
         user.email = '';
         user.companyName = '';
         user.mailNickname = '';
         user.adGroups = [];
         user.otherEmails = [];
         user.userHasAgreedToCurrentLicenseAgreement = false;
         user.invitationMessageBody = '';
         return user;
    }

    private getTitleLable(): string {
        if (!this.props.isAddingNewUser && this.props.selectedUser) {
            return this.props.selectedUser.userType === 'Member' ? 'Edit internal user' : 'Edit external user';
        }
        return this.props.newUserType === NewUserType.Member ? 'Create new user' : 'Invite external user';
    }

    public render(): JSX.Element {
        return (
            <Panel
                onDismiss = {() => { this.props.onDismiss(); }}
                isOpen = {true}
                type = { PanelType.custom }
                customWidth = "800px"
                headerText = { this.getTitleLable() }
            >
                <div style = {{ height: 'calc(100vh - 235px)', overflowY: 'scroll' }}>
                    <div className = "row-cols-2">
                        <TextFieldWrapper
                            label = { 'Display name' }
                            value = { this.state.user.displayName }
                            ariaLabel = { 'Display name' }
                            required = { true }
                            disabled = { !this.props.isAddingNewUser }
                            maxLength = { 256 }
                            onChange = { (ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                this.state.user.displayName = newText;
                                this.setState({ user: this.state.user });
                            }}
                            validator = { this.uiValidator }
                        />
                    </div>
                    { this.props.newUserType === NewUserType.Member &&
                        <div className = "row-cols-2">
                            <TextFieldWrapper
                                label = { 'Mail nick name' }
                                value = { this.state.user.mailNickname }
                                ariaLabel = { 'Mail nick name' }
                                required = { true }
                                disabled = { !this.props.isAddingNewUser }
                                onChange = { (ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                    this.state.user.mailNickname = newText;
                                    this.state.user.email = newText + '@' + this.props.adTenant.domainName;
                                    this.setState({ user: this.state.user });
                                }}
                                validator = { this.uiValidator }
                            />
                        </div>
                    }
                    <div className = "row-cols-2">
                        <TextFieldWrapper
                            label = { 'Email' }
                            value = { this.state.user.email }
                            ariaLabel = { 'Email' }
                            required = { true }
                            disabled = { !this.props.isAddingNewUser || this.props.newUserType === NewUserType.Member }
                            type = 'email'
                            fieldType = 'email'
                            onChange = { (ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                this.state.user.email = newText;
                                this.setState({ user: this.state.user });
                            }}
                            validator = { this.uiValidator }
                        />
                    </div>
                    <div className = "row-cols-2">
                        <TextFieldWrapper
                            label = { 'OtherEmails' }
                            value = { this.state.user.otherEmails.join(";") }
                            ariaLabel = { 'OtherEmails' }
                            onChange = { (ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                this.state.user.otherEmails = Array.from(newText.trim().split(/,|;/), x => x.trim());
                                this.setState({ user: this.state.user });
                            }}
                            validator = { this.uiValidator }
                        />
                    </div>
                    <div className = "row-cols-2">
                        <TextFieldWrapper
                            label = { 'Company name' }
                            value = { this.state.user.companyName }
                            ariaLabel = { 'Company name' }
                            required = { true }
                            maxLength = { 64 }
                            onChange = { (ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                this.state.user.companyName = newText;
                                this.setState({ user: this.state.user });
                            }}
                            validator = { this.uiValidator }
                        />
                    </div>
                    { this.props.newUserType === NewUserType.Guest && 
                        <div className="row-cols-2">
                            <TextFieldWrapper
                                label = { 'Message' }
                                value = { this.state.user.invitationMessageBody }
                                required = { true }
                                multiline = { true }
                                ariaLabel = { 'Message' }
                                onChange = { (ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                    this.state.user.invitationMessageBody = newText;
                                    this.setState({ user: this.state.user });
                                }}
                                validator = {this.uiValidator }
                            />
                        </div>
                    }
                    <Label>Groups for restricting: </Label>
                    <div className="ms-Grid-col ms-u-sm9 DocumenUploaderDlgBlock">
                    { this.renderTenantGroups() }

                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        onClick = { () => this.onInvite() }
                        text = { this.props.isAddingNewUser ? 'Invite' : 'Resend invitation' }
                        hidden = { this.isHideInviteButton() }
                    />
                    <PrimaryButton
                        onClick = { () => this.onSave() }
                        text = { 'Save' }
                        hidden = { this.isHideSaveButton() }
                    />
                    <DefaultButton 
                        onClick = { () => this.props.onDismiss() }
                        text = { 'Cancel' }
                        name = { 'Cancel' }
                    />
                </DialogFooter>
            </Panel>
        )
    }

    private renderTenantGroups() {
        let groupMenuItems: IContextualMenuItem[] = [];
        let selectedGroups: string[] = [];
        var isNotSelectInThisVocabulary = true;

        if (this.props.tenantADGroups == null) {
            return;
        }

        this.props.tenantADGroups.forEach(tenantGroup => {
            var isChecked = false;
            if (this.state.user.adGroups && this.state.user.adGroups.indexOf(tenantGroup.id) >= 0) {
                isChecked = true;
                selectedGroups.push(tenantGroup.displayName);
                isNotSelectInThisVocabulary = false;
            }
            groupMenuItems.push({
                id: tenantGroup.id,
                key: tenantGroup.id,
                name: tenantGroup.displayName,
                canCheck: true,
                isChecked: isChecked,
                onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => this.selectGroup(item.key)
            });
        });

        groupMenuItems.unshift({
            id: 'None',
            key: 'None',
            name: 'None',
            canCheck: true,
            isChecked: isNotSelectInThisVocabulary,
            onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => this.selectNoneGroup()
        });

        return (
            <div data-grid="col-12">
                <div data-grid="col-3">
                    <MultiSelectContextualMenu text = { 'Tenant groups' } menuItems = { groupMenuItems } style = {{ padding: 0 }} />
                </div>
                <div data-grid="col-9">
                    { selectedGroups.map(g => <span key = { g } className="selected-vocabulary">{ g }</span>) }
                    { (selectedGroups.length === 0) && <span className="selected-vocabulary">None</span> }
                </div>
            </div>
        );
    }

    private selectGroup(selectedKey: string) {
        var groups = this.state.user.adGroups;
        var lastIndex = groups.findIndex(g => g === selectedKey);
        if (lastIndex < 0) {
            groups.push(selectedKey);
        } else {
            groups.splice(lastIndex, 1);
        }
        this.state.user.adGroups = groups;
        this.setState({ user: this.state.user });
    }

    private selectNoneGroup() {
        this.state.user.adGroups = [];
        this.setState({ user: this.state.user });
    }

    private onSave(): void {
        if (!this.uiValidator.validate()) {
            return;
        }
        if (this.props.isAddingNewUser) {
            if (this.props.newUserType === NewUserType.Member) {
                this.Api.addNewTenantUser(this.props.adTenant.id, this.state.user, () => { this.props.onSave(this.state.user); });
            }
        }
        else {
            this.Api.updateTenantUserGroups(this.props.adTenant.id, this.state.user, () => { this.props.onSave(this.state.user); });
        }
    }

    private onInvite() {
        if (!this.uiValidator.validate()) {
            return;
        }
        this.Api.inviteGuestUserToTenant(this.props.adTenant.id, this.state.user, () => { this.props.onSave(this.state.user); });
    }
    
    private isHideInviteButton(): boolean {
        return this.props.newUserType === NewUserType.Member 
            || (this.props.isAddingNewUser === false && this.props.selectedUser.userType === 'Member')
            || (this.props.isAddingNewUser === false && this.props.selectedUser.userType === 'Guest'
                && this.props.selectedUser.externalUserState === 'Accepted');
    }

    private isHideSaveButton(): boolean {
        return this.props.isAddingNewUser && this.props.newUserType === NewUserType.Guest;
    }
}
