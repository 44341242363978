import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { LocalizationIds as LocIds } from '../Utilities/Globalization/IntlEnum';
import { Intl } from '../../App/Services/GlobalizationService';

export enum ButtonTypes {
    None,
    Publish,    // Send For Approval
    Edit,
    Exit,
    Approve,
    ApprovePublish,
    ApproveDeletion,
    Reject,
    RejectPublish,
    RejectDeletion,
    Add,
    Duplicate,
    Delete,
    DeleteNode,
    MoveLeft,
    MoveRight,
    MoveUp,
    MoveDown,
    Save,
    RollBack,   // Restore
    Download,
    Downloading,
    Undo,
    Finish,
    ForcePublish,
    Verify,
    History,
    Return,
    Copy,
    Tagging,
    Refresh
}

export function getEditorConfirmationDialogText() {
    let confirmationDialogText: Array<{title: string, subText: string}> = [];
    confirmationDialogText[ButtonTypes.None] = { title: '', subText: ''};
    confirmationDialogText[ButtonTypes.Delete] = {
        title: 'Confirm Delete',
        subText: 'Are you sure to make a deletion request? If you are deleting vNext version, only draft will be deleted; If you are deleting public version, all the versions - public and history will be deleted.'
    };
    confirmationDialogText[ButtonTypes.DeleteNode] = {
        title: 'Confirm Delete',
        subText: 'Are you sure to delete this item?'
    };
    confirmationDialogText[ButtonTypes.Publish] = {
        title: 'Confirm Send For Approval',
        subText: 'Are you sure to make a publish request?'
    };
    confirmationDialogText[ButtonTypes.Duplicate] = {
        title: 'Confirm page duplication',
        subText: 'Are you sure to make a publish request?'
    };
    confirmationDialogText[ButtonTypes.ApprovePublish] = {
        title: 'Confirm Approve Publish',
        subText: 'Are you sure to approve publish? Once published, it will become pending localization status.'
    };
    confirmationDialogText[ButtonTypes.ApproveDeletion] = {
        title: 'Confirm Approve Deletion',
        subText: 'Are you sure to approve deletion? Once confirmed, vNext version pending deletion will only delete the vNext version, and current version pending deletion will delete all the versions  - public and history'
    };
    confirmationDialogText[ButtonTypes.RejectPublish] = {
        title: 'Confirm Reject Publish',
        subText: 'Are you sure to reject publish? Once rejected, it will become edit in progress status'
    };
    confirmationDialogText[ButtonTypes.RejectDeletion] = {
        title: 'Confirm Reject Deletion',
        subText: 'Are you sure to reject deletion? Once rejected, it will become previous status.'
    };
    confirmationDialogText[ButtonTypes.ForcePublish] = {
        title: 'Confirm Force Publish',
        subText: 'Are you sure to force publish? Once confirmed, it will become public immediately to customer.'
    };
    confirmationDialogText[ButtonTypes.RollBack] = {
        title: 'Confirm Restore',
        subText: 'Are you sure to restore? Once confirmed, current vNext version will be replaced with the selected version and becomes edit in progress status.'
    };
    confirmationDialogText[ButtonTypes.Undo] = {
        title: 'Confirm Undo',
        subText: 'Are you sure to undo the changes? It will come back to the status before you edit it.'
    };
    confirmationDialogText[ButtonTypes.Exit] = {
        title: 'Confirm Exit',
        subText: 'Are you sure to exit edit mode? Please save your changes before you exit. If you click confirm, it will return to the version before you edit it.'
    };
    confirmationDialogText[ButtonTypes.Return] = {
        title: 'Return To Pages List',
        subText: 'Are you sure to return to pages list? Please make sure you have saved your changes before you leave.'
    };
    confirmationDialogText[ButtonTypes.Tagging] = {
        title: 'Tagging ...',
        subText: 'Are you sure to return to pages list?'
    };    
    return confirmationDialogText;
}

export class CommandBarButtonFactory {
    static GetButton(type: ButtonTypes, handler: () => void, name: string = '', key: string = ''): IContextualMenuItem {
        let returnButton: IContextualMenuItem = { key: '' };
        switch (type) {
            case ButtonTypes.Publish:
                returnButton = { key: key || 'Publish', name: name || Intl.Get(LocIds.PageAndPanels.PublicButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.PublicButtonLabel), iconProps: { iconName: 'ReadingMode' } };
                break;
            case ButtonTypes.Edit:
                returnButton = { key: key || 'Edit', name: name || Intl.Get(LocIds.PageAndPanels.EditButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.EditButtonLabel), iconProps: { iconName: 'Edit' } };
                break;
            case ButtonTypes.Approve:
                returnButton = { key: key || 'Approve', name: name || Intl.Get(LocIds.PageAndPanels.ApproveButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.ApproveButtonLabel), iconProps: { iconName: 'Accept' } };
                break;
            case ButtonTypes.ApprovePublish:
                returnButton = {
                    key: key || 'ApprovePublish',
                    name: name || Intl.Get(LocIds.PageAndPanels.ApprovePublishButtonLabel),
                    ariaLabel: name || Intl.Get(LocIds.PageAndPanels.ApprovePublishButtonLabel),
                    iconProps: { iconName: 'Accept'
                }};
                break;
            case ButtonTypes.ApproveDeletion:
                returnButton = { 
                    key: key || 'ApproveDeletion',
                    name: name || Intl.Get(LocIds.PageAndPanels.ApproveDeletionButtonLabel),
                    ariaLabel: name || Intl.Get(LocIds.PageAndPanels.ApproveDeletionButtonLabel),
                    iconProps: { iconName: 'Accept'
                }};
                break;
            case ButtonTypes.Reject:
                returnButton = { key: key || 'Reject', name: name || Intl.Get(LocIds.PageAndPanels.RejectButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.RejectButtonLabel), iconProps: { iconName: 'Cancel' } };
                break;
            case ButtonTypes.RejectPublish:
                returnButton = { 
                    key: key || 'RejectPublish',
                    name: name || Intl.Get(LocIds.PageAndPanels.RejectPublishButtonLabel), 
                    ariaLabel: name || Intl.Get(LocIds.PageAndPanels.RejectPublishButtonLabel),
                    iconProps: { iconName: 'Cancel' } };
                break;
            case ButtonTypes.RejectDeletion:
                returnButton = { 
                    key: key || 'RejectDeletion', name: name || Intl.Get(LocIds.PageAndPanels.RejectDeletionButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.RejectDeletionButtonLabel), iconProps: { iconName: 'Cancel'
                }};
                break;
            case ButtonTypes.Add:
                returnButton = { key: key || 'Add', name: name || Intl.Get(LocIds.PageAndPanels.AddButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.AddButtonLabel), iconProps: { iconName: 'CalculatorAddition' } };
                break;
            case ButtonTypes.Duplicate:
                    returnButton = { key: key || 'Duplicate', name: name || Intl.Get(LocIds.PageAndPanels.DuplicateButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.AddButtonLabel), iconProps: { iconName: 'CalculatorAddition' } };
                    break;
            // Delete/DeleteNode are the same, without break statement, they will both return the same button    
            case ButtonTypes.Delete:
            case ButtonTypes.DeleteNode:
                returnButton = { key: key || 'Delete', name: name || Intl.Get(LocIds.PageAndPanels.DeleteButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.DeleteButtonLabel), iconProps: { iconName: 'RecycleBin' } };
                break;
            case ButtonTypes.Exit:
                returnButton = { key: key || 'Exit', name: name || Intl.Get(LocIds.PageAndPanels.ExitButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.ExitButtonLabel), iconProps: { iconName: 'Clear' } };
                break;
            case ButtonTypes.MoveLeft:
                returnButton = {
                    key: key || 'MoveLeft', name: name || Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel),
                    iconProps: { iconName: 'ChevronLeft' }
                };
                break;
            case ButtonTypes.MoveRight:
                returnButton = {
                    key: key || 'MoveRight',
                    name: name || Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel),
                    ariaLabel: name || Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel),
                    iconProps: { iconName: 'ChevronRight' }
                };
                break;
            case ButtonTypes.MoveUp:
                returnButton = { key: key || 'MoveUp', name: name || Intl.Get(LocIds.PageAndPanels.MoveUpButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.MoveUpButtonLabel), iconProps: { iconName: 'ChevronUp' } };
                break;
            case ButtonTypes.MoveDown:
                returnButton = {
                    key: key || 'MoveDown', name: name || Intl.Get(LocIds.PageAndPanels.MoveDownButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.MoveDownButtonLabel),
                    iconProps: { iconName: 'ChevronDown' }
                };
                break;
            case ButtonTypes.Save:
                returnButton = { key: key || 'Save', name: name || Intl.Get(LocIds.PageAndPanels.SaveButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.SaveButtonLabel), iconProps: { iconName: 'Save' } };
                break;
            case ButtonTypes.RollBack:
                returnButton = {
                    key: key || 'RollBack',
                    name: name || Intl.Get(LocIds.PageAndPanels.RollBackButtonLabel),
                    ariaLabel: name || Intl.Get(LocIds.PageAndPanels.RollBackButtonLabel),
                    iconProps: { iconName: 'FabricMovetoFolder' }
                };
                break;
            case ButtonTypes.Undo:
                returnButton = { key: key || 'Undo', name: name || Intl.Get(LocIds.PageAndPanels.UndoButtonLabel), ariaLabel: name || Intl.Get(LocIds.PageAndPanels.UndoButtonLabel), iconProps: { iconName: 'RevToggleKey' } };
                break;
            case ButtonTypes.Download:
                returnButton = { key: key ? key : 'Download', name: name ? name : 'Download', ariaLabel: name ? name : 'Download', iconProps: { iconName: 'Download' } };
                break;
            case ButtonTypes.Downloading:
                returnButton = { key: key ? key : 'Downloading', name: name ? name : 'Downloading', ariaLabel: name ? name : 'Downloading', iconProps: { iconName: 'Download' }, disabled: true };
                break;
            case ButtonTypes.Finish:
                returnButton = { key: key || 'Finish', name: name || 'Finish', ariaLabel: name || 'Finish', iconProps: { iconName: 'Completed' } };
                break;
            case ButtonTypes.ForcePublish:
                returnButton = { key: key || 'ForcePublish', name: name || 'ForcePublish', ariaLabel: name || 'ForcePublish', iconProps: { iconName: 'WorkforceManagement' } };
                break;
            case ButtonTypes.Verify:
                returnButton = { key: key || 'Verify', name: name || 'Verify', ariaLabel: name || 'Verify', iconProps: { iconName: 'WorkforceManagement' } };
                break;
            case ButtonTypes.History:
                returnButton = { key: key || 'History', name: name || 'History', ariaLabel: name || 'History', iconProps: { iconName: 'CustomList' } };
                break;
            case ButtonTypes.Return:
                returnButton = { key: key || 'Return', name: name || 'Return', ariaLabel: name || 'Return', iconProps: { iconName: 'ReturnToSession' } };
                break;
            case ButtonTypes.Copy:
                returnButton = { key: key || 'Copy', name: name || 'Copy', ariaLabel: name || 'Copy', iconProps: { iconName: 'Copy' } };
                break;
            case ButtonTypes.Tagging:
                returnButton = { key: key || 'Tagging', name: name || 'Tagging', ariaLabel: name || 'Tagging', iconProps: { iconName: 'TimelineDelivery' } };
                break;
            case ButtonTypes.Refresh:
                returnButton = { key: key || 'Refresh', name: name || 'Refresh', ariaLabel: name || 'Refresh', iconProps: { iconName: 'Refresh' } };
                break;
            default:
                throw new Error(('Button Type Not Supported'));
        }
        returnButton.onClick = handler;
        return returnButton;
    }
}