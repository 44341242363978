import { VocabularySelector, SelectionMode } from './VocabularySelector';
import { ModelWithVocabularySelections } from '../Models/ModelWithVocabularySelections';
import * as React from 'react';
import { store } from '../../Store';
import { VocabularyDefinition } from '../Models/Vocabulary/VocabularyDefinition';
import { TooltipHost, DirectionalHint } from 'office-ui-fabric-react';
import { Label } from 'office-ui-fabric-react/lib/';
import { getLocalizedValue } from '../../Common/Utilities/LocalizationHelper';
import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { VocabularyServiceClient } from '../Services/VocabularyService';
import { saveVocabulary } from '../Reducers/VocabulariesManagement';
import { VocabularySelection } from '../Models/Vocabulary/VocabularySelection'; 
import { isVocabularyShown } from '../Models/Vocabulary/VocabularyValueDefinition';

export enum StpContentType {
    Page,
    Document
}

export interface ModelWithVocabularySelectionsEditorProps {
    model: ModelWithVocabularySelections;
    isPublicContent: boolean;
    stpContentType: StpContentType;
    onChange: () => void;
    displayAny: boolean;
    vocabularies: VocabularyDefinition[];
}

export interface ModelWithVocabularySelectionsEditorState {
    lastUpdateOn: Date;
    vocabularies: VocabularyDefinition[];
}

const VocabulariesForAllStpContentTypes =  ['Cloud Service', 'Category', 'Regional Resources', 'Financial Services - Regional', 'Tenant Specific'];
const VocabulariesForDocumentsOnly = ['IncludeDocumentInSearchResults'];
const VocabulariesForPagesOnly = ['IncludePageInSearchResults'];

// Defines a component that allows editing any ModelWithVocabularySelections
export class ModelWithVocabularySelectionsEditor extends
    React.Component<ModelWithVocabularySelectionsEditorProps, ModelWithVocabularySelectionsEditorState> {
    constructor(props: ModelWithVocabularySelectionsEditorProps) {
        super(props);
        this.state = {
            lastUpdateOn: new Date(),
            vocabularies: this.props.vocabularies,
        };
        var storeVocabulary = store.getState().vocabulariesManagement;
        if (storeVocabulary.status === 'Init') {
            store.dispatch({ type: 'VOCABULARIES_FETCHING' });
            VocabularyServiceClient.listVocabularies(
                () => { return; },
                (r) => {
                    store.dispatch(saveVocabulary(r.data));
                },
                () => { return; });
        }
        this.renderTagValueSelection = this.renderTagValueSelection.bind(this);
        this.clearVocabularySelections = this.clearVocabularySelections.bind(this);
    }
    render() {
        let haveRestrictions = false;
        let enableNewVocabEditing = store.getState().applicationContext.userInfo.flights.indexOf('EnableNewVocabEditing') > 0;
        if (!this.VocabularySelectionsV2022) {
            this.Model.vocabularySelectionsV2022 = [];
        }
        if (this.VocabularySelections) {
            this.VocabularySelections.forEach(element => {
                const vocabulary = this.state.vocabularies.find(v => v.name === element.vocabularyName);
                if (vocabulary && vocabulary.useToRestrictContentToCertainCustomers) {
                    if (element.vocabularyValueIds.length) {
                        haveRestrictions = true;
                        return;
                    }
                }
            });
        }

        let vocabulariesToUse = (this.props.stpContentType === StpContentType.Document)
            ? VocabulariesForAllStpContentTypes.concat(VocabulariesForDocumentsOnly)
            : VocabulariesForAllStpContentTypes.concat(VocabulariesForPagesOnly);

        return (
            <div>
                <div className="row-cols-1">
                    <div className="DocumenUploaderDlgBlock">
                        <Label className='ss-intro-header'>{Intl.Get(LocIds.VocabularyManagement.FilteringTitleLabel)}</Label>
                    </div>
                    <div className="DocumenUploaderDlgBlock" style={{ marginLeft: '.75rem', marginRight: '0' }}>
                        {/* Show vocabs that are totally new and modified */}
                        {this.state.vocabularies.filter(v => vocabulariesToUse.indexOf(v.name) > -1).map(
                            (voca, i) => !voca.useToRestrictContentToCertainCustomers && this.renderVocabulary(voca, i, this.VocabularySelectionsV2022, !enableNewVocabEditing, true))
                        }
                    </div>
                </div>
                {!this.props.isPublicContent &&
                    <div className="row-cols-1">
                        <div className="DocumenUploaderDlgBlock">
                            <Label className='ss-intro-header'>{Intl.Get(LocIds.VocabularyManagement.RestrictingTitleLabel)}</Label>
                            <div>
                                <i className="ms-Icon ms-Icon--Warning" style={{ float: 'left', margin: '8px 8px 0 0' }} aria-hidden="true" />
                                {!haveRestrictions ?
                                    <Label>{Intl.Get(LocIds.VocabularyManagement.NorestrictMessage)}</Label>
                                    : <Label>{Intl.Get(LocIds.VocabularyManagement.RestrictMessage)}</Label>
                                }
                            </div>
                        </div>
                        <div className="DocumenUploaderDlgBlock" style={{ marginLeft: '.75rem', marginRight: '0' }}>
                            {this.state.vocabularies.map((voca, i) => voca.useToRestrictContentToCertainCustomers && this.renderVocabulary(voca, i, this.VocabularySelections, false, false))}
                        </div>
                    </div>
                }
            </div>
        );
    }

    componentDidMount() {
        var storeVocabulary = store.getState().vocabulariesManagement;
        if (storeVocabulary.status !== 'Init') {
            this.updateVocabulary();
        }
    }

    private get Model() {
        return this.props.model;
    }

    private get VocabularySelections(): VocabularySelection[] {
        return this.Model.vocabularySelections;
    }

    private get VocabularySelectionsV2022(): VocabularySelection[] {
        return this.Model.vocabularySelectionsV2022;
    }

    // Updates the local state with global vocabularies if they are loaded.
    private updateVocabulary(): void {
        var storeVocabulary = store.getState().vocabulariesManagement;
        if (storeVocabulary.status === 'Finished') {
            this.setState({ vocabularies: storeVocabulary.vocabularyDefinitions }, () => {
                this.addAllVocabularyToModelIfNotSelected();
            });
        }
    }

    // Adds all default vocabularies to the model if none are currently selected
    private addAllVocabularyToModelIfNotSelected(): void {
        if (!this.state.vocabularies) {
            return;
        }
        if (!this.VocabularySelections) {
            this.Model.vocabularySelections = [];
        }
        this.state.vocabularies.forEach(voca => {
            if (voca.useToRestrictContentToCertainCustomers) {
                return;
            }
            let checkingList = this.VocabularySelections;
            let vocabularySelectionInModel = checkingList ? checkingList.find(v => v.vocabularyName === voca.name) : undefined;
            if (!vocabularySelectionInModel) {
                vocabularySelectionInModel = { vocabularyName: voca.name, vocabularyValueIds: [] };
                checkingList.push(vocabularySelectionInModel);
            }
            if (voca.isMultiSelect && voca.defaultSelectAllValue && !vocabularySelectionInModel.vocabularyValueIds.length) {
                vocabularySelectionInModel.vocabularyValueIds = ['All'];
            }
        });
        this.triggerStateUpdate();
    }

    // Renders model vocabulary selection row for a given vocabulary definition.
    // voca: vocabulary definition to render a selection row.
    // i: row number to be used as a row key.
    private renderVocabulary(voca: VocabularyDefinition, i: number, checkingList: VocabularySelection[], isReadOnly: boolean, isNewUI: boolean) {
        let selectedVocabulary = checkingList ? checkingList.find(v => v.vocabularyName === voca.name) : undefined;
        let selectedIds = selectedVocabulary ? selectedVocabulary.vocabularyValueIds : [];
        let selectedValues: string[] = [];
        selectedIds.forEach(id => {
            var choice = voca.choices.find(c => c.id === id);
            if (choice && isVocabularyShown(isNewUI, choice)) {
                selectedValues.push(getLocalizedValue(choice.displayValues, false));
            }
        });
        return (
            <div key={i} className='row' style={{ marginRight: '0' }}>
                <div style={{ width: '40%' }}>
                    {isReadOnly ?
                        voca.name
                        :
                        <TooltipHost
                            content={voca.toolTip || 'Select value(s) for ' + voca.name}
                            calloutProps={{ isBeakVisible: false, gapSpace: 0 }}
                            tooltipProps={{ directionalHint: DirectionalHint.topLeftEdge }}
                        >
                            <VocabularySelector
                                vocabularyName={voca.name}
                                values={selectedIds}
                                style={{ padding: 0 }}
                                selectionMode={voca.isMultiSelect ? SelectionMode.multiple : SelectionMode.single}
                                onChanged={(vocaName, values) => this.selectVocabulary(vocaName, values, checkingList)}
                                displayNone={true}
                                showRestrictedValue={true}
                                displayAny={this.props.displayAny && voca.useToRestrictContentToCertainCustomers}
                                displayAll
                                isNewUI={isNewUI}
                            />
                        </TooltipHost>}
                </div>
                <div style={{ width: '60%' }}>
                    {selectedValues.map(v => this.renderTagValueSelection(voca.name, v, (voc, id) => this.removeVocabulary(voc, id, checkingList), isReadOnly))}
                    {(selectedIds.length === 1 && selectedIds[0] === 'All') && this.renderTagValueSelection(voca.name, Intl.Get(LocIds.VocabularyManagement.AllLabel), (v, id) => this.clearVocabularySelections(v, id, checkingList), isReadOnly)}
                    {(selectedIds.length === 1 && selectedIds[0] === 'Any') && this.renderTagValueSelection(voca.name, Intl.Get(LocIds.VocabularyManagement.AnyLabel), (v, id) => this.clearVocabularySelections(v, id, checkingList), isReadOnly)}
                    {/* Should show none when it has no matching vacaublary values */}
                    {(selectedValues.length === 0 && (selectedIds.length != 1 || (selectedIds[0] != 'All' && selectedIds[0] != 'Any'))) && this.renderTagValueSelection(voca.name, Intl.Get(LocIds.VocabularyManagement.NoneLabel), (v, id) => null, isReadOnly)}
                </div>
            </div>
        );
    }

    // Renders a single vocabulary selection
    private renderTagValueSelection(vocabularyName: string, selectionId: string, removeHandler: (vocabularyName: string, selectionId: string) => void, isReadOnly: boolean): JSX.Element {
        return (
            <span
                key={vocabularyName + '_' + selectionId}
                className="selected-vocabulary"
            >
                {selectionId}
                {!isReadOnly &&
                    <span
                        onClick={() => removeHandler(vocabularyName, selectionId)}
                        className="delete-item"
                    >
                        {selectionId !== 'None' && 'x'}
                    </span>}
            </span>
        );
    }

    private selectVocabulary(vocaName: string, selectedKey: string[], workingList: VocabularySelection[]): void {
        var vocabulary = this.state.vocabularies.find(v => v.name === vocaName);
        if (!vocabulary) {
            return;
        }
        var voca = workingList.find(v => v.vocabularyName === vocaName);
        if (!voca) {
            voca = { vocabularyName: vocaName, vocabularyValueIds: selectedKey };
            workingList.push(voca);
        } else {
            voca.vocabularyValueIds = selectedKey;
        }
        this.triggerStateUpdate();
    }

    private removeVocabulary(vocaName: string, selectedValue: string, workingList: VocabularySelection[]) {
        var vocabulary = this.state.vocabularies.find(v => v.name === vocaName);
        if (!vocabulary) {
            return;
        }
        var selectedChoice = vocabulary.choices.find(c => getLocalizedValue(c.displayValues, false) === selectedValue);
        if (!selectedChoice) {
            return;
        }
        var voca = workingList.find(v => v.vocabularyName === vocaName);
        if (voca && voca.vocabularyValueIds) {
            var index = voca.vocabularyValueIds.indexOf(selectedChoice.id);
            voca.vocabularyValueIds.splice(index, 1);
            this.triggerStateUpdate();
        }
    }

    private clearVocabularySelections(vocaName: string, selectedValue: string, workingList: VocabularySelection[]): void {
        var vocabulary = this.state.vocabularies.find(v => v.name === vocaName);
        if (!vocabulary) {
            return;
        }
        var vocSelection = workingList.find(v => v.vocabularyName === vocaName);
        if (vocSelection && vocSelection.vocabularyValueIds) {
            vocSelection.vocabularyValueIds = [];
            this.triggerStateUpdate();
        }
    }

    private triggerStateUpdate(): void {
        this.setState({ lastUpdateOn: new Date() });
        this.props.onChange();
    }
}