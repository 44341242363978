import { ServiceFactoryClientExecuteApi } from '../../Common/Services/ServiceFactoryToExecuteApi';
import { NotificationSettings, NotificationSummary } from '../Models/NotificationSettings';
import { ErrorResponse } from './Models';
import { DocumentVersionInfo } from '../Models';
import { DocumentDetails } from '../Models/NotificationSettings/DocumentDetails';

export interface NotificationSettingsService {
    getNotificationsSummary(onSuccessCallback: (response: NotificationSummary) => void, onFailureCallback: (response: ErrorResponse) => void): void;
    getNotificationDocuments(onSuccessCallback: (response: DocumentVersionInfo[]) => void, onFailureCallback: (response: ErrorResponse) => void): void;
    getNotificationSettings(onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void): void;
    postNotificationSettings(settings: NotificationSettings, onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void): void;
    postNotificationDocument(documents: DocumentDetails[], onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void): void;
    deleteNotificationDocument(documents: DocumentDetails[], onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void): void;
}

const USE_MOCKS_FOR_NOTIFICATIONS = false;

class NotificationSettingsServices implements NotificationSettingsService {

    public getNotificationsSummary(onSuccessCallback: (response: NotificationSummary) => void, onFailureCallback: (response: ErrorResponse) => void) {
        ServiceFactoryClientExecuteApi.ExecuteRequest<NotificationSummary>(
            '/api/v2/Notifications/Summary',
            [],
            'get',
            null,
            onSuccessCallback,
            onFailureCallback,
            USE_MOCKS_FOR_NOTIFICATIONS
        );
    }

    public getNotificationDocuments(onSuccessCallback: (response: DocumentVersionInfo[]) => void, onFailureCallback: (response: ErrorResponse) => void) {
        ServiceFactoryClientExecuteApi.ExecuteRequest<DocumentVersionInfo[]>(
            '/api/v2/Notifications/Documents',
            [],
            'get',
            null,
            onSuccessCallback,
            onFailureCallback,
            USE_MOCKS_FOR_NOTIFICATIONS
        );
    }

    public getNotificationSettings(onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void) {
        ServiceFactoryClientExecuteApi.ExecuteRequest<NotificationSettings>(
            '/api/v2/Notifications/Settings',
            [],
            'get',
            null,
            onSuccessCallback,
            onFailureCallback,
            USE_MOCKS_FOR_NOTIFICATIONS
        );
    }

    public postNotificationSettings(settings: NotificationSettings, onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void) {
        ServiceFactoryClientExecuteApi.ExecuteRequest<NotificationSettings>(
            '/api/v2/Notifications/Settings',
            [],
            'post',
            {
                emails: settings.emails.filter((email) => email.trim().length > 0),
                interval: settings.interval,
                isNotificationOn: settings.isNotificationOn
            },
            onSuccessCallback,
            onFailureCallback,
            USE_MOCKS_FOR_NOTIFICATIONS
        );
    }

    public postNotificationDocument(documents: DocumentDetails[], onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void) {
        ServiceFactoryClientExecuteApi.ExecuteRequest<NotificationSettings>(
            '/api/v2/Notifications/Documents',
            [],
            'post',
            documents,
            onSuccessCallback,
            onFailureCallback,
            USE_MOCKS_FOR_NOTIFICATIONS
        );
    }

    public deleteNotificationDocument(documents: DocumentDetails[], onSuccessCallback: (response: NotificationSettings) => void, onFailureCallback: (response: ErrorResponse) => void) {
        ServiceFactoryClientExecuteApi.ExecuteRequest<NotificationSettings>(
            '/api/v2/Notifications/Documents',
            [],
            'delete',
            documents,
            onSuccessCallback,
            onFailureCallback,
            USE_MOCKS_FOR_NOTIFICATIONS
        );
    }
}

export const NoticationSettingsServiceClient: NotificationSettingsService = new NotificationSettingsServices();
