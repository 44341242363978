import * as React from 'react';
import { DocumentVersionInfo } from '../Models';
import { Panel, PanelType, PrimaryButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';
import TableItem from '../Models/TableItem';
import { Icon } from '../../../src/Common/Components/Icon';
import ShowMoreText from './Utility/ShowMoreText';
import { downloadFile, getDownloadDocumentLink, getDocumentTypeAriaLabel, getFabricIconByExtension, getDocumentDetailLink } from '../Utilities/DocumentHelper';
import { DocumentServiceClient } from '../Services/DocumentVersionInfo';
import * as _ from 'lodash';
import { store } from 'src/Store';
import LicenseAgreementPage from './Licensing/LicenseAgreementPage';

const HIDDEN_MOBILE_CLASSES = 'x-hidden-vp1 x-hidden-vp2';

export interface DocumentSeriesVersionsFlyoutProps {
    seriesId: string;
    onDismissPanel: () => void;
    isSignedIn: boolean;
    hasAgreedToCurrentLicenseAgreement: boolean;
}

export interface DocumentSeriesVersionsFlyoutState {
    documentsToBeDownloaded: string[];
    documents: DocumentVersionInfo[];
    showLoadingSpinner: boolean;
    showAgreement: boolean;
    documentToBeDownloadedAfterAgreement: TableItem | null;
}

function formatDate(parsed: Date): string | undefined {
    const month = parsed.getMonth() + 1;
    const date = parsed.getDate();
    return parsed.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date;
}

class DocumentSeriesVersionsFlyout extends React.Component<DocumentSeriesVersionsFlyoutProps, DocumentSeriesVersionsFlyoutState> {
    constructor(props: DocumentSeriesVersionsFlyoutProps) {
        super(props);
        this.state = {
            documentsToBeDownloaded: [],
            documents: [],
            showLoadingSpinner: false,
            showAgreement: false,
            documentToBeDownloadedAfterAgreement: null
        };
    }

    componentDidUpdate(prevProps: DocumentSeriesVersionsFlyoutProps) {
        if (!prevProps.hasAgreedToCurrentLicenseAgreement && this.props.hasAgreedToCurrentLicenseAgreement) {
            if (this.state.documentToBeDownloadedAfterAgreement) {
                this.downloadDocument(this.state.documentToBeDownloadedAfterAgreement);
            }
        }
    }

    render() {
        return (
            <div>
                <Panel
                    closeButtonAriaLabel={Intl.Get(LocIds.ComplianceManagerV3.FlyoutCloseButton)}
                    headerText={
                        'Version History'
                    }
                    isOpen={true}
                    headerClassName="panel-Header"
                    onDismiss={() => this.onDismiss()}
                    isFooterAtBottom={true}
                    onRenderFooterContent={this._onRenderFooterContent}
                    type={PanelType.custom}
                    customWidth={'60%'}
                >
                    {this.state.documents && this.state.documents.length > 0 ?
                        this.renderTable() : this.renderNoResults()
                    }

                    {this.state.showLoadingSpinner &&
                        <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingLabel)} ariaLive="assertive" className="loading-panel-full" />
                    }

                    {this.state.showAgreement &&
                        <LicenseAgreementPage
                            onAgree={() => {
                                this.setState({ showAgreement: false });
                            }}
                            onDismiss={() => {
                                this.setState({ showAgreement: false });
                            }}
                            callBack={() => {
                                this.setState({ showAgreement: false });
                            }}
                        />}
                </Panel>
            </div>
        );
    }

    componentDidMount() {
        this.setState({ showLoadingSpinner: true });
        DocumentServiceClient.getDocumentVersionsBySeriesId(
            this.props.seriesId,
            (response) => {
                const documentsVersionsForSeries: DocumentVersionInfo[] = response.data;
                this.setState({
                    documents: documentsVersionsForSeries,
                    showLoadingSpinner: false
                });
            },
            (error) => {
                this.setState({ showLoadingSpinner: false });
            },
            'getDocumentVersionsBySeries'
        );
    }

    private renderTable = (): JSX.Element => {
        const documents: DocumentVersionInfo[] = _.orderBy(this.state.documents, [doc => doc.reportDate], ['desc']);
        const items = documents.map((doc) => TableItem.fromDocument(doc, true));
        return (
            <div className="advancedDocs m-advanced-docs" data-grid="col-12 stack-3">
                <div className="c-table f-divided" data-grid="col-12" style={{ overflow: 'hidden' }}>
                    {
                        <table className="advancedDocs__table" data-f-sort="true">
                            {this.getTableHeader(items[0].documentId)}
                            <tbody>{items.map((item, index) => this.getTableRow(item, index))}</tbody>
                        </table>
                    }
                </div>
            </div>
        );
    }

    private renderNoResults = () => {

        if (this.state.showLoadingSpinner) return;
        
        return (
            <div className="advancedDocs__no-results">
                {Intl.Get(LocIds.DocumentManagementPage.NoResults)}
            </div>
        );
    }

    /**
     * This method renders the table header row in a <thead>
     * @param id a unique key for the header elements
     * @return TSX
     */
    private getTableHeader = (id: string) => {
        return (
            <thead className={HIDDEN_MOBILE_CLASSES}>
                <tr>
                    {/* if "Description" is hidden this is 3-columns */}
                    <th
                        id={'documentTitleColumnHeader' + id}
                        scope="col"
                        className="f-sortable advancedDocs__column-header"
                        colSpan={1}
                        style={{ width: '30%' }}
                    >
                        {Intl.Get(LocIds.PageAndPanels.TitleColumnTitle)}
                    </th>

                    <th id={'documentDescriptionColumnHeader' + id} scope="col" className={`f-sortable advancedDocs__column-header ${HIDDEN_MOBILE_CLASSES}`} colSpan={1}>
                        {Intl.Get(LocIds.PageAndPanels.DescriptionColumnTitle)}
                    </th>

                    <th id={'documentReportDateColumnHeader' + id} scope="col" className={`f-sortable advancedDocs__column-header ${HIDDEN_MOBILE_CLASSES}`} colSpan={3}>
                        {Intl.Get(LocIds.PageAndPanels.DateColumnTitle)}
                    </th>

                </tr>
            </thead>
        );
    }

    private getHyperlinkIcon = (linkitem: TableItem, documentType: string): JSX.Element => {
        return (
            <Icon
                iconName={'Download'}
                ariaLabel={Intl.Get(LocIds.PageAndPanels.DownloadDocumentAriaLabel, { type: documentType })}
                title={Intl.Get(LocIds.PageAndPanels.DownloadDocumentAriaLabel, { type: documentType })}
            />
        );
    }

    /**
     * Render a row of the document table
     *
     * @param {TableItem} item a document model with versioning info
     * @param {number} i index of the current row
     */
    private getTableRow = (item: TableItem, i: number): JSX.Element | undefined => {
        const localTime = formatDate(item.date);
        const isNewUI = store.getState().applicationContext.userInfo.flights.indexOf('NewUIDesign') > -1;

        return (
            <tr key={item.documentId.concat(i.toString())} className="advancedDocs__table-row">
                <td className="advancedDocs__cell-title">
                    <div style={{ display: 'table' }}>
                        {(this.state.documentsToBeDownloaded.indexOf(item.documentId) !== -1) &&
                            <Spinner size={SpinnerSize.small} ariaLive="assertive" className="docDownloadSpinner" />
                        }

                        <div className="advancedDocs__cell-title__contents">
                            <i
                                className={
                                    item.isLink ? 'ms-Icon ms-Icon--Globe'
                                        : `ms-Icon ${getFabricIconByExtension(item.fileExtension)}`
                                }
                                style={{ fontSize: '20px' }}
                                aria-hidden="true"
                            />
                        </div>
                        <div className="advancedDocs__cell-title__contents">
                            {
                                <a
                                    href={isNewUI ? getDocumentDetailLink(item.documentId) : getDownloadDocumentLink(item.documentId, this.props.parentId)}
                                    onClick={(e) => {
                                        if (!isNewUI) {
                                            e.preventDefault();
                                            this.downloadDocument(item);
                                        }
                                    }}
                                    className="c-hyperlink"
                                >
                                    {item.title}
                                    {this.getHyperlinkIcon(item, getDocumentTypeAriaLabel(item.fileExtension))}
                                </a>
                            }
                        </div>
                    </div>
                </td>

                <td>
                    <ShowMoreText
                        key={`description_${item.documentId}`}
                        limit={170}
                        text={`${item.description}`}
                        title={item.title}
                        className={`advancedDocs__document-description`}
                    />
                </td>
                <td style={{ whiteSpace: 'nowrap', minWidth: '160px' }}>
                    <div className="advancedDocs__document-date">
                        {localTime}
                    </div>
                </td>
            </tr>
        );
    }

    private downloadDocument = (item: TableItem) => {
        const { isSignedIn, hasAgreedToCurrentLicenseAgreement } = this.props;

        const hasPermissionToDownload =
            item.isPublic ||
            (isSignedIn && hasAgreedToCurrentLicenseAgreement);

        // user doesn't have permission so we'll have them login and/or do the agreement
        if (!hasPermissionToDownload) {
            this.setState({ showAgreement: true, documentToBeDownloadedAfterAgreement: item });
            return;
        }

        const fileName = item.title + item.fileExtension;
        const index = this.state.documentsToBeDownloaded.indexOf(item.documentId);
        if (index !== -1) { return; }

        this.setState({
            documentsToBeDownloaded: [...this.state.documentsToBeDownloaded, item.documentId],
            documentToBeDownloadedAfterAgreement: null
        });

        downloadFile(item.documentId, fileName, () => {
            const indexToBeDeleted = this.state.documentsToBeDownloaded.indexOf(item.documentId);
            if (indexToBeDeleted > -1) {
                this.state.documentsToBeDownloaded.splice(indexToBeDeleted, 1);
                this.setState({ documentsToBeDownloaded: this.state.documentsToBeDownloaded });
            }
        });
    }

    private _onRenderFooterContent = (): JSX.Element => {
        return (
            <div className="footer-buttons">
                {this.renderDoneButton()}
            </div>
        );
    }

    renderDoneButton() {
        return (
            <PrimaryButton
                className={'buttoncolor floatLeft'}
                text={Intl.Get(LocIds.DocumentManagementPage.CloseButtonLabelText)}
                onClick={() => this.onDismiss()}
            />
        );
    }

    private onDismiss() {
        if (this.props.onDismissPanel) {
            this.props.onDismissPanel();
        }
    }
}

export default DocumentSeriesVersionsFlyout;