import { FrameworkControlFamily, FrameworkDetailsFilter } from '../Models/ComplianceDashboard';
import { AxiosResponse } from 'axios';
import {
    AddFrameworkList,
    FrameworkControlViewModel,
    FrameworkFamilyViewModel,
    FrameworkUserActions,
    ReviewDashboard,
    ReviewDashboardTile,
    PersonaDetails,
    FilteredControlFamilyDetails
} from '../Models/ComplianceDashboard';
import { ErrorResponse } from './Models/ErrorResponse';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { ComplianceManagerConstants } from '../../Common/Models/ComplianceManagerConstants';

export class ComplianceDashboardService {

    // Start - Review Dashboard methods.
    getDashboard(
        beforeRequest: () => void | null,
        onRequestSuccess: (response: ReviewDashboard) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard';
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => {
                onRequestSuccess(response.data);
            },
            onRequestFailed
        );
    }

    getDashboardTile(
        tileId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboardTile) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/' + tileId;
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => {
                let res: ReviewDashboardTile = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    archiveDashboardTile(
        tileId: string,
        archiveStatus: boolean,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboard) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/' + tileId + '/' + archiveStatus;
        ServiceTrustClient.doRequest_New(
            'patch', api, {}, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    renameDashboardTile(
        tileId: string,
        newTileName: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboard) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {

        let data: AddFrameworkList = new AddFrameworkList();
        data.tileName = newTileName;
        var api: string = '/api/v2/ReviewDashboard/' + tileId;
        ServiceTrustClient.doRequest_New(
            'PUT', api, data, beforeRequest,
            (response: AxiosResponse) => {
                let res: ReviewDashboard = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    deleteDashboardTile(
        tileId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboard) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/' + tileId;
        ServiceTrustClient.doRequest_New(
            'delete', api, {}, beforeRequest,
            (response: AxiosResponse) => {
                let res: ReviewDashboard = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    addDashboardTile(
        addFrameworkList: AddFrameworkList,
        newTileName: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboard) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/';
        ServiceTrustClient.doRequest_New(
            'put', api, addFrameworkList, beforeRequest,
            (response: AxiosResponse) => {
                let res: ReviewDashboard = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    helpTourComplete(
        beforeRequest: () => void,
        onRequestSuccess: () => void,
        onRequestFailed: () => void): void {
        var api: string = '/api/UserSettings/MarkComplianceDashboardTourAsComplete/';
        ServiceTrustClient.doRequest_New(
            'patch', api, {}, beforeRequest,
            (response: AxiosResponse) => {
                onRequestSuccess();
            },
            onRequestFailed);
    }
    // End - Review Dashboard methods.

    // Start - Action items and Framework Details methods.

    requestAllFramilies(
        tileId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: FrameworkFamilyViewModel[]) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ActionItem/Schema' + (tileId ? '?tileId=' + tileId : '');
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => {
                let res: FrameworkFamilyViewModel[] = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    saveCertificationControl(
        actionItem: FrameworkUserActions | null,
        beforeRequest: () => void,
        onRequestSuccess: (response: FrameworkUserActions) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ActionItem/PutAsync';
        ServiceTrustClient.doRequest_New(
            'put', api, actionItem, beforeRequest,
            (response: AxiosResponse) => {
                let res: FrameworkUserActions = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    getCollaborators(
        tileId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: Map<string, PersonaDetails>) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/Collaborators/' + tileId;
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => {
                let res: Map<string, PersonaDetails> = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    requestControlFamily(
        controlFamilyId: string,
        tileId: string,
        own: boolean | null,
        beforeRequest: () => void,
        onRequestSuccess: (response: FrameworkControlViewModel[]) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ActionItem/CustomerCertification?familyId=' + controlFamilyId + (tileId !== '' ? '&tileId=' + tileId : '') + (own ? '&own=' + own : '');
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => {
                let res: FrameworkControlViewModel[] = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    publishvNext(
        familyId: string,
        frameworkControlId: string,
        isApproved: boolean,
        beforeRequest: () => void,
        onRequestSuccess: (response: boolean) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/ActionItem/PublishMSControlVNext';
        ServiceTrustClient.doRequest_New(
            'put', api, { familyId: familyId, frameworkControlId: frameworkControlId, isApproved: isApproved }, beforeRequest,
            (response: AxiosResponse) => {
                let res: boolean = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    savevNext(
        frameworkControlFamily: FrameworkControlFamily,
        beforeRequest: () => void,
        onRequestSuccess: (response: boolean) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/ActionItem/SaveMSControlVNext';
        ServiceTrustClient.doRequest_New(
            'put', api, frameworkControlFamily, beforeRequest,
            (response: AxiosResponse) => {
                let res: boolean = response.data;
                onRequestSuccess(res);
            },
            onRequestFailed);
    }

    attachDocToControl(
        fileId: string,
        familyId: string,
        controlId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboard) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ActionItem/AttachDocToControl/' + fileId + '/' + familyId + '/' + controlId;
        ServiceTrustClient.doRequest_New(
            'patch', api, {}, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    exportToExcelWithFilter(
        tileId: string,
        fileName: string,
        filter: FrameworkDetailsFilter | null): void {
        ServiceTrustClient.downloadFileWithFilter('/api/v2/ReviewDashboard/ExportToExcel/' + tileId, fileName, filter);
    }

    exportToExcelWithFilterV3(
        assessmentID: string,
        fileName: string,
    ) {
        return ServiceTrustClient.downloadFileWithFilterV3('/api/v3/ExportAssessment', fileName, assessmentID);
    }

    exportTemplateExcelV3(
        templateId: string,
        fileName: string
    ) {
        return ServiceTrustClient.downloadFileWithFilterV3('/api/v3/ExportTemplate', fileName, templateId);
    }

    saveFile(result: AxiosResponse, fileName: string) {
        ServiceTrustClient.saveFile(result, fileName);
    }

    exportToExcel(
        tileId: string,
        fileName: string): void {
        ServiceTrustClient.downloadFile('/api/v2/ReviewDashboard/ExportToExcel/' + tileId, fileName);
    }

    exportAllActionsV3(): void{
        ServiceTrustClient.downloadFile(ComplianceManagerConstants.ApiPaths.ExportAllActionsAsExcel, "ComplianceManagerActions.xlsx");
    }

    getFilteredUsers(
        searchTerm: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: {}) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/GetFilteredUsers' + (searchTerm ? '?searchTerm=' + searchTerm : '');
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    getUserGroupMemberships(
        beforeRequest: () => void,
        onRequestSuccess: (response: {}) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/GetUserGroupMemberships';
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    deleteDocFromControl(
        fileIds: string,
        familyId: string,
        controlId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboard) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ActionItem/DeleteFileAsync/' + fileIds + '/' + familyId + '/' + controlId;
        ServiceTrustClient.doRequest_New(
            'delete', api, {}, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    getInScopeServiceForDashboardTile(
        tileId: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: string[]) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ActionItem/GetInScopeServiceForDashboardTile/' + tileId;
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    getTile(
        cloudServiceName: string,
        frameworkName: string,
        beforeRequest: () => void,
        onRequestSuccess: (response: ReviewDashboardTile) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/GetTile/' + cloudServiceName + '/' + frameworkName;
        ServiceTrustClient.doRequest_New(
            'get', api, {}, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    filteredControlFamilyDetails(
        frameworkDetailsFilter: FrameworkDetailsFilter,
        beforeRequest: () => void,
        onRequestSuccess: (response: FilteredControlFamilyDetails[]) => void,
        onRequestFailed: (response: ErrorResponse) => void): void {
        var api: string = '/api/v2/ReviewDashboard/FilterData';
        ServiceTrustClient.doRequest_New(
            'post', api, frameworkDetailsFilter, beforeRequest,
            (response: AxiosResponse) => onRequestSuccess(response.data),
            onRequestFailed
        );
    }

    // End - Action items anf Framework details methods.
}

export let ComplianceDashboardServiceClient = new ComplianceDashboardService();