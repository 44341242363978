import * as React from 'react';
import { Unsubscribe } from 'redux';
import { store } from '../../../Store';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import * as Licensing from '../../Reducers/LicenseManagement';
import { LicenseAgreementServiceClient, LicenseAgreementResponse } from '../../../App/Services/LicenseAgreementService';
import { ErrorResponse } from '../../../App/Services/Models/ErrorResponse';
import { StatusMessage } from '../../Components/StatusMessage';
import { SignInSignupDialog } from '../../Components/Licensing/SignInSignupDialog';

export interface LicenseAgreementPageProps {
    onAgree?: () => void;
    onDismiss?: () => void;
    callBack?: () => void;
}

export class LicenseAgreementPage extends React.Component<LicenseAgreementPageProps, Licensing.LicenseManagementState> {
    private _storeUnsubscriber: Unsubscribe;
    constructor(props: LicenseAgreementPageProps) {
        super(props);
        this.state = store.getState().licenseManagement;
        this._storeUnsubscriber = store.subscribe(() => {
            this.setState(store.getState().licenseManagement);
            if (store.getState().authentication.isSignedIn && store.getState().applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement) {
                if (this.props.onAgree) {
                    this.props.onAgree();
                }
            }
        });

        this.getAgreementStatus = this.getAgreementStatus.bind(this);
        this.getAgreement = this.getAgreement.bind(this);
        this.postAgreementAcceptance = this.postAgreementAcceptance.bind(this);
    }

    public componentDidMount() {
        Licensing.licenseReset();
    }

    public componentWillUnmount() {
        this._storeUnsubscriber();
    }

    public componentDidUpdate(): void {
        if (store.getState().authentication.isSignedIn) {
            this.getAgreementStatus();
        } 

        if (this.state.agreedStatus) {
            // Record the acceptance
            this.postAgreementAcceptance();
        }

        if (this.state.cancelled || this.state.signInCancelled) {
            if (this.props.onDismiss) {
                this.props.onDismiss();
            }
        }
    }
    
    render() {
        var title = this.state.licenseDetails ? this.state.licenseDetails.title : '';
        var body = this.state.licenseDetails ? this.state.licenseDetails.agreementBody : '';
        return (
            <div>
                <StatusMessage id="licenseAgreementMessage" />
                <SignInSignupDialog 
                    hidden={store.getState().authentication.isSignedIn || this.state.signInCancelled}
                    title={Intl.Get(LocIds.LicenseAgreementPage.SignInSignUpDialogTitle)}
                    callBack={this.props.callBack ? this.props.callBack : undefined}
                />
                <Dialog
                    containerClassName="LicenseManagementPage"
                    hidden={!this.state.showLicense || this.state.cancelled}
                    onDismiss={() => { Licensing.displayLicense(false); }}
                    dialogContentProps={{
                        type: DialogType.normal,
                    }}
                    modalProps={{
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
                > 
                <div>
                <h3>{title}</h3>
                <section><div
                    dangerouslySetInnerHTML={{ __html: body }} // eslint-disable-line
                    /></section>
                </div> 
                <div className="LicenseFooter">
                    <DialogFooter>
                        <PrimaryButton 
                            onClick={() => { 
                                Licensing.agreedStatus(true);
                            }} 
                            text={Intl.Get(LocIds.LicenseAgreementPage.AgreeButtonLabel)} 
                        />
                        <DefaultButton 
                            onClick={() => { 
                                Licensing.cancelled();
                            }} 
                            text={Intl.Get(LocIds.LicenseAgreementPage.CancelButtonLabel)} 
                        />
                    </DialogFooter>
                </div>
                </Dialog>
            </div>
        );
    }

    /*
    * Get the status whether user has agreed to EULA
    */
    private getAgreementStatus(): void {
        // TODO: This method is going to be fetching the agreed status from the store.
        if (!this.state.licenseDetails) {
            if (!store.getState().applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement) {
                this.getAgreement();
            }
        }
    }

    /*
    * Get the licensing agreement.
    */
    private getAgreement(): void {
        if (!this.state.licenseRequesting && !this.state.licenseDetails) {
            LicenseAgreementServiceClient.getAgreement(
                () => {
                    Licensing.licenseRequesting();
                },
                (response: LicenseAgreementResponse) => {
                    Licensing.licenseAcquired(response.licenseDetails);
                },
                (failed: ErrorResponse) => {
                    Licensing.licenseError();
            });  
        }
    }

    /*
    * Post the agreement to the server.
    */
    private postAgreementAcceptance(): void {
        if (this.state.licenseDetails && !this.state.licensePosting) {
            LicenseAgreementServiceClient.postLicenseAgreement(
                this.state.licenseDetails.agreementId,
                () => { Licensing.licenseAgreementPosting(); },
                () => {            
                    Licensing.licenseReset();
                    if (!store.getState().applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement) {
                        Licensing.updateLicenseAgreement();
                    }
                    if (this.props.onAgree) {
                        this.props.onAgree();
                    }
                },
                (failed: ErrorResponse) => { 
                    Licensing.licenseError(); 
                });
        }
    }
}

export default LicenseAgreementPage;