import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton, SelectableOptionMenuItemType, ComboBox, IComboBoxOption, Checkbox, IComboBox } from 'office-ui-fabric-react/lib/';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { ImageRichTextPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme, CallToActionTheme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { getThemeClassName, getRichTextThemeOptions,
    getCallToActionThemeClassName, getSimpleCallToActionThemeOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import Constants from '../../Utilities/Constants';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import { isEmptyString, RichTextLink, renderLink } from '../../Utilities/RenderUtilities';
import './ImageRichTextPanel.css';
import { SimpleLongText } from '../../../Common/Components/SimpleLongText';
import TextFieldWrapperNumeric from '../TextFieldWrapperNumeric';

export class ImageRichTextPanel extends BasePanel<ImageRichTextPanelPropertiesData> {
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private columnWidthOptions: IComboBoxOption[] = [];
    private calltoActionThemes: String[] = ['Primary', 'Secondary'];
    private PrimaryCallToActionThemeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<ImageRichTextPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getRichTextThemeOptions());
        this.columnWidthOptions = this.getColumnWidthOptions();
        this.PrimaryCallToActionThemeOptions = this.PrimaryCallToActionThemeOptions.concat(getSimpleCallToActionThemeOptions());
    }

    getColumnWidthOptions(): IComboBoxOption[] {
        var items: IComboBoxOption[] = [
            { key: 1, text: '1/3' },
            { key: 2, text: '2/3' },
            { key: 3, text: '3/3' },
        ];
        return items;
    }

    getActualPanel = () => {
        let headingElement = !isEmptyString(this.state.panelData.headingUrl)
            ?
            renderLink(this.state.panelData.headingUrl, getLocalizedString(this.state.panelData.heading))
            :
            (getLocalizedString(this.state.panelData.heading));

        return (
            <div data-panel-type="Image Rich Text" className={`rich-text-container${this.state.panelData.useCardPadding ? ' sidebar-card' : ''}`} data-grid="col-12">
                <div className={getThemeClassName(Theme[this.state.panelData.useCardPadding ? (this.state.panelData.theme || 'Grey') : 'Light'])} >
                    <div className="card-container" data-grid="col-12">
                        {!isEmptyString(this.state.panelData.imageId) && (
                            <div className="image-container" data-grid="col-12">
                                <picture className={`c-image c-card-image-${(this.state.panelData.imageColumnWidth !== undefined ? this.state.panelData.imageColumnWidth : 3)}`}>
                                    <img
                                        className="lazyloaded"
                                        src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                        data-src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                        alt={this.state.panelData.imageTitle}
                                    />
                                </picture>
                            </div>
                        )}

                        {!isEmptyString(getLocalizedString(this.state.panelData.heading)) && (this.state.panelData.useCardPadding ?
                            <h3  className="c-heading-5">{headingElement}</h3>
                        : <h1  className="c-heading-2">{headingElement}</h1>
                        )}
                        
                        <div className="c-paragraph default-list">
                            <SimpleLongText
                                text={this.state.panelData.content ?  getLocalizedString(this.state.panelData.content) : ''}
                                renderers={{link: RichTextLink}}
                                showFlag={this.state.panelData.hideLongerContent!== undefined ? this.state.panelData.hideLongerContent : false}
                                characterLength={this.state.panelData.contentSummaryLength}
                            />
                        </div>
                       
                        {this.state.panelData.useFooterSeparator &&
                            <div className="footer-separator"/>
                        }
                        {!isEmptyString(this.state.panelData.callToActionUrl) &&
                            <div
                            className={(getLocalizedString(this.state.panelData.callToActionLabel) && (this.calltoActionThemes.indexOf(this.state.panelData.callToActionTheme || '') >= 0) ? 'c-group' : undefined)}
                            >
                                {
                                    renderLink(this.state.panelData.callToActionUrl, getLocalizedString(this.state.panelData.callToActionLabel), `c-call-to-action c-glyph ${getCallToActionThemeClassName(CallToActionTheme[this.state.panelData.callToActionTheme || 'Simple'])}`)
                                }
                            </div>
                        }
                    </div>
                </div>
            </div >
        );
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div className="row" style={{marginLeft: '0px'}}>
                        {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                        <div className="col">
                            {this.getActualPanel()}
                            {this.state.showEditor &&
                                <div className="editPanel">
                                    
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <Checkbox id="chkCardPadding"
                                                checked={this.state.panelData.useCardPadding}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, useCardPadding: isChecked }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.UseCardPaddingLabel)}
                                            />
                                        </div>
                                    </div> 

                                    {this.state.panelData.useCardPadding && 
                                        <div data-grid="col-12">
                                            <div data-grid="col-5">
                                                <ComboBox
                                                    id="ddlTheme"
                                                    defaultSelectedKey={this.state.panelData.theme || 'DarkImage'}
                                                    label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                    ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                    allowFreeform={true}
                                                    autoComplete={'on'}
                                                    options={this.themeOptions}
                                                    onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                        this.state.panelData.theme = item.key.toString();
                                                        this.setState({ panelData: this.state.panelData });
                                                        this.props.makeDirty();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={false}
                                                disabled={true}
                                                value={this.state.panelData.imageTitle || ''}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length) {
                                                        this.state.panelData.imageId = images[0].url;
                                                        this.state.panelData.imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                    this.props.makeDirty();
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                        <div data-grid="col-12">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.imageColumnWidth || 3}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectImageWidthLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectImageWidthLabel)}
                                                allowFreeform={false}
                                                autoComplete={'on'}
                                                options={this.columnWidthOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.imageColumnWidth = parseInt(item.key.toString(), 10);
                                                    this.setState({ panelData: this.state.panelData });
                                                    this.props.makeDirty();
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <TextFieldWrapper
                                            required={false}
                                            value={getLocalizedString(this.state.panelData.heading)}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                    newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                }
                                                setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                this.setState({ panelData: this.state.panelData });
                                                this.props.makeDirty();
                                            }}
                                            label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                            validator={this.panelValidator}
                                            placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                        />
                                    </div>
                                    <div data-grid="col-12">
                                        <TextFieldWrapper
                                            value={this.state.panelData.headingUrl ? this.state.panelData.headingUrl : ''}
                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                this.state.panelData.headingUrl = newVal;
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            label={Intl.Get(LocIds.PageAndPanels.HeadingUrlLabel)}
                                            url={true}
                                            isPage={true}
                                            required={false}
                                            validator={this.panelValidator}
                                        />
                                    </div>
                                    <div data-grid="col-12">
                                        <MarkDownEditor
                                            showCommandButton={false}
                                            value={this.state.panelData.content ? getLocalizedString(this.state.panelData.content) : ''}
                                            onChange={(newVal) => {
                                                setDefaultLocalizedString(this.state.panelData, 'content', newVal);

                                                this.setState({ panelData: this.state.panelData });
                                                this.props.makeDirty();
                                            }}
                                            label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                            placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            required={true}
                                            validator={this.panelValidator}
                                            selectedIndex={this.state.selectedIndex}
                                        />
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <Checkbox
                                                checked={this.state.panelData.hideLongerContent}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, hideLongerContent: isChecked }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HideLargeContentLabel)}
                                            />
                                        </div>
                                        </div>
                                        {this.state.panelData.hideLongerContent &&
                                            <div data-grid='col-12'>
                                                <div data-grid="col-5">
                                                    <TextFieldWrapperNumeric
                                                        numericValue={this.state.panelData.contentSummaryLength}
                                                        onChangedNumeric={(newVal: number) => {
                                                            this.state.panelData.contentSummaryLength = newVal;
                                                            this.setState({ panelData: this.state.panelData });
                                                        }}
                                                        label={Intl.Get(LocIds.PageAndPanels.MaxCharacterLengthLabel)}
                                                        required={false}
                                                        validator={this.panelValidator}
                                                    />
                                                </div>
                                            </div> 
                                        }
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <Checkbox
                                                checked={this.state.panelData.useFooterSeparator}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, useFooterSeparator: isChecked }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.UseFooterSeparatorLabel)}
                                            />
                                        </div>
                                    </div> 
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData ? getLocalizedString(this.state.panelData.callToActionLabel) : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'callToActionLabel', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.callToActionUrl ? this.state.panelData.callToActionUrl : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.state.panelData.callToActionUrl = newVal;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionUrlLabel)}
                                                isPage={true}
                                                url={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                selectedKey={this.state.panelData.callToActionTheme || 'Simple'}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.PrimaryCallToActionThemeOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.callToActionTheme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '6px' }} />
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    :
                    this.getActualPanel()
                }
            </div>
        );
    }
}