import { DefaultButton, PrimaryButton, Checkbox, SelectableOptionMenuItemType, ComboBox, IComboBoxOption, IComboBox } from 'office-ui-fabric-react/lib/';
import { DirectionalHint, TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { SidebarGridPanelPropertiesData } from '../../Models/PageInfrastructure';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { deleteOnePanelFromCollection, getSidebarGridColumn, moveSelectedColumn, getSimpleCallToActionThemeOptions,
    getCallToActionThemeClassName } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { RichTextLink, isEmptyString, renderLink } from '../../../App/Utilities/RenderUtilities';
import { CallToActionTheme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';

import './SidebarGridPanel.css';

export class SidebarGridPanel extends BasePanel<SidebarGridPanelPropertiesData> {
    private PrimaryCallToActionThemeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<SidebarGridPanelPropertiesData>) {
        super(props);
        this.PrimaryCallToActionThemeOptions = this.PrimaryCallToActionThemeOptions.concat(getSimpleCallToActionThemeOptions());
    }

    private getActualPanel = (): JSX.Element | undefined => {
        const { panelHeading, columns, horizontalRule } = this.state.panelData;
        const hasHeading = (panelHeading && !isEmptyString(getLocalizedString(panelHeading)));


        return (
            <div
                className="sidebar-grid-panel"
                data-grid="col-12 pad-6x stack-3"
                data-panel-type="Sidebar Grid"
            >
                {/* these two were lifted from AreaHeadingPanel and may be missing some styles scoped to m-area-heading */}
                {hasHeading && (
                    <div
                        className="sidebar-grid-heading"
                        data-grid="col-3"
                    >
                        <h2 className="clearfix">{getLocalizedString(panelHeading)}</h2>
                    </div>
                )}

                <div data-grid={hasHeading ? 'col-9' : 'col-12'}>
                    <div className="sidebar-grid-columns">
                        {columns && columns.map((data, index) =>
                            <div
                                className="sidebar-grid-item"
                                key={data.toString().concat(index.toString())}
                                onClick={() => { if (this.state.showEditor && this.panelValidator.validate()) { this.setState({ selectedIndex: index }); } }}
                                style={this.state.selectedIndex === index && this.state.showEditor ? { border: 'solid 1px #778899' } : {}}
                            >
                                <div>
                                    {!isEmptyString(data.imageId) && (
                                        <picture className="c-image">
                                            <img
                                                className="lazyloaded"
                                                src={imageHelper.getImageUrl(data.imageId)}
                                                data-src={imageHelper.getImageUrl(data.imageId)}
                                                alt={getLocalizedString(data.heading)}
                                            />
                                        </picture>
                                    )}

                                    <TooltipHost
                                        content={getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                        calloutProps={{ isBeakVisible: false, gapSpace: 0 }}
                                        tooltipProps={{ directionalHint: DirectionalHint.topCenter }}
                                    >
                                        <h3 className="c-heading-5">
                                            {/* if there's a URL then render as a link, otherwise just show the label in the <h3> */}
                                            {!isEmptyString(data.headingUrl)
                                            ?
                                            renderLink(data.headingUrl, getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder))
                                            :
                                            (getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder))
                                            }
                                        </h3>
                                    </TooltipHost>

                                    {data.content &&
                                        <ReactMarkdown
                                            renderers={{ Link: RichTextLink }}
                                            source={getLocalizedString(data.content)}
                                            skipHtml={true}
                                        />
                                    }

                                    {!isEmptyString(data.callToActionUrl) && !isEmptyString(getLocalizedString(data.callToActionLabel)) &&
                                        renderLink(data.callToActionUrl, getLocalizedString(data.callToActionLabel), `c-call-to-action c-glyph f-lightweight ${getCallToActionThemeClassName(CallToActionTheme[data.callToActionTheme || 'Simple'])}`)
                                    }
                                </div>
                            </div>
                        )}
                    </div> {/* /columns */}
                </div> {/* /col-9 */}

                {horizontalRule && (
                    <div data-grid="col-12">
                        <hr />
                    </div>
                )}

            </div>
        );
    }

    public onAddColumn = () => {
        if (!this.state.panelData.columns || !this.panelValidator.validate()) {
            return;
        }
        this.state.panelData.columns.push(getSidebarGridColumn(0, true));
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    private onDeleteColumn = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            const panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    private onMoveLeft = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    private onMoveRight = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    public render() {
        const { columns } = this.state.panelData;
        const numberOfColumns = columns ? columns.length : 0;

        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}}>
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor && this.state.panelData.columns ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12" >
                                        <div data-grid="col-12">
                                            <label className="ms-Label x-hidden-focus">Image</label>
                                            <p>
                                                <small>
                                                    <strong>Please use an image that smaller than 128 x 128px</strong><br />
                                                    This image will be shown at the top of the column.
                                                </small>
                                            </p>
                                        </div>

                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={false}
                                                disabled={true}
                                                value={this.state.panelData.columns[this.state.selectedIndex].imageTitle || ''}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length && this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                        this.state.panelData.columns[this.state.selectedIndex].imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading || undefined)}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal.substr(0, Constants.PANEL_HEADING_MAX));
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />

                                            <TextFieldWrapper
                                                value={this.state.panelData.columns[this.state.selectedIndex].headingUrl}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].headingUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />

                                            <MarkDownEditor
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].content || undefined)}
                                                onChange={(newVal) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'content', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                selectedIndex={this.state.selectedIndex}
                                            />

                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel || undefined)}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'callToActionLabel', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />

                                            <TextFieldWrapper
                                                value={this.state.panelData.columns[this.state.selectedIndex].callToActionUrl ? this.state.panelData.columns[this.state.selectedIndex].callToActionUrl : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />

                                            <ComboBox
                                                selectedKey={this.state.panelData.columns[this.state.selectedIndex].callToActionTheme || 'Simple'}
                                                label={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.PrimaryCallToActionThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.PrimaryCallToActionThemeOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if(this.state.panelData.columns!=undefined){
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionTheme = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />

                                        </div>
                                    </div>

                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelHeading ? getLocalizedString(this.state.panelData.panelHeading) : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelHeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <Checkbox
                                                checked={this.state.panelData.horizontalRule}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, horizontalRule: isChecked }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelBorderBottomLabel)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddColumnLabel)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeleteColumnLabel)}
                                        disabled={numberOfColumns <= 1}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}