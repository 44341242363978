import { Icon, Stack, Text } from 'office-ui-fabric-react';
import * as React from 'react';
import { getExtensionFromFileName } from './helper';
import './CitationCard.css';
import { SKAiSourceType } from 'src/App/Services/Models/SKAi/SKAiSourceType';

interface CitationCardProps {
    title: string;
    link: string;
    description: string;
    docExtension?: string;
    citationType: SKAiSourceType;
}

export const CitationCard: React.FC<CitationCardProps> = (props: CitationCardProps) => {
    const { title, link, docExtension, citationType } = props;

    const [showAction, setShowAction] = React.useState(false);

    const openDocumentPage = () => {
        window.open(link);
    }

    const extension = docExtension || getExtensionFromFileName(title);
    return (
        <Stack
            horizontal
            verticalAlign="center"
            className="citationCard__wrapper"
            tokens={{ childrenGap: 4 }}
            onMouseEnter={() => setShowAction(true)}
            onMouseLeave={() => setShowAction(false)}
            onClick={openDocumentPage}
        >
            <Stack.Item disableShrink>
                <Stack horizontal verticalAlign="center">
                    <i
                        className={
                            citationType === SKAiSourceType.Page ? 'ms-Icon ms-Icon--Globe'
                                : extension === '.pdf' ? 'ms-Icon fileextensionIcon ms-Icon--PDF fileextensionPDF'
                                    : extension === '.doc' || extension === '.docx' ? 'ms-Icon fileextensionIcon ms-Icon--WordDocument fileextensionWord'
                                        : extension === '.xls' || extension === '.xlsx' ? 'ms-Icon fileextensionIcon ms-Icon--ExcelDocument fileextensionExcel'
                                            : extension === '.ppt' || extension === '.pptx' ? 'ms-Icon fileextensionIcon ms-Icon--PowerPointDocument fileextensionPPT'
                                                : 'ms-Icon fileextensionIcon ms-Icon--TextDocument'
                        }
                        aria-hidden="true"
                        style={{ fontSize: '28px', margin: 'auto' }}/>                   
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack>
                    <Text className="citationCard__title">{ title }</Text>
                </Stack>
            </Stack.Item>           
        </Stack>
    )
}