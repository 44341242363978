import { StoreState } from '../../Store';
import { connect } from 'react-redux';
import { ExportServiceLinksDirectoryPageProps } from '../Pages/PrivacyPortal/ExportServiceLinksDirectoryPage';
import ExportServiceLinksDirectoryPage from '../Pages/PrivacyPortal/ExportServiceLinksDirectoryPage';
import { ServiceLinks, ServiceLink } from '../Models/PrivacyPortal';

const MOST_REQUESTED_SERVICE_MAX_ITEMS = 20;

const sortServiceLinks = (serviceLinks: ServiceLinks): ServiceLinks => {
    return serviceLinks.sort((a: ServiceLink, b: ServiceLink) => {
        return (a.serviceName.toLowerCase() < b.serviceName.toLowerCase()) ? -1 : 1;
    });
};

const getMostRequestedServices = (serviceLinks: ServiceLinks): ServiceLinks => {
    let mostRequestedServices = serviceLinks.concat()
        .sort((a: ServiceLink, b: ServiceLink) => { // Sort by request counter and then service name
            if (a.requestCounter === b.requestCounter) {
                return (a.serviceName.toLowerCase() < b.serviceName.toLowerCase()) ? -1 : 1; 
            } else {
                return (a.requestCounter > b.requestCounter) ? -1 : 1;
            }
        })                   
        .slice(0, MOST_REQUESTED_SERVICE_MAX_ITEMS); // Get top N items

    return sortServiceLinks(mostRequestedServices);
};

function mapStateToProps(state: StoreState): ExportServiceLinksDirectoryPageProps {
    let microsoftServiceLinks = state.privacyPortal.microsoftServiceLinks || {
                                id: '',
                                serviceLinks: [] as ServiceLinks,
                                mostRequestedServiceMaxItems: 0
                              };

    let allServiceLinksList = microsoftServiceLinks && microsoftServiceLinks.serviceLinks;

    return {
        microsoftServiceLinks: microsoftServiceLinks,
        allServiceLinks: sortServiceLinks(allServiceLinksList),
        mostRequestedServices: getMostRequestedServices(allServiceLinksList),
        status: state.privacyPortal.status
    };
}

export default connect(mapStateToProps)(ExportServiceLinksDirectoryPage);
