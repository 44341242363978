// Defines Azure authentication storage operations
import { Diagnostics } from './Diagnostics';
import { StpLocalStorageAccessor, StpStorageAccessor, StpStorageItem } from '../../App/Services/StpStorageAccessor';

// Defines Stp authentication data model
export interface AuthData {
    tokenType: string;
    expiresOn: string;
    issuedAt: string;
    token: string;
    userName: string;
    userShortName: string;
    sessionBeginAt: string;
    sessionEndWarningAt: string;
    sessionEolAt: string;
    sessionEolWarningAt: string;
    localTimeDiscrepancyMilliseconds: number;
    itarUser: boolean;
}

// Defines Stp user model.
export interface User {
    userName: string;
    userShortName: string;
    authData?: AuthData;
}

// Defines authentication and Jwt lite token functions.
export class ADAuthenticationHelper {
    private static readonly stpAuthStorageKey: string = 'stp-a-session';    
    private readonly storageAgent: StpStorageAccessor;

    constructor(accessor: StpStorageAccessor | undefined) {        
        if (accessor === undefined) {
            this.storageAgent = new StpLocalStorageAccessor(true);
        } else {
            this.storageAgent = accessor;
        }
    }

    // Parses given Jwt token payload and returns User structure
    parseStpJwtLite(token: string): User | null {
        var base64Decoded = this.decodeJwt(token);
        if (base64Decoded !== null) {
            let localTimeDiscrepancy = 0;
            let sessionbeginsAtSeconds = parseInt(base64Decoded.sn_openat, 10);
            if (sessionbeginsAtSeconds !== undefined && sessionbeginsAtSeconds > 0) {
                localTimeDiscrepancy = (new Date()).getTime() - 1000 * sessionbeginsAtSeconds;
            }
            let userObj = {
                userName: base64Decoded.unique_name,
                userShortName: base64Decoded.nameid,                
                authData: {
                    tokenType: 'Bearer',
                    expiresOn: base64Decoded.exp,
                    issuedAt: base64Decoded.nbf,
                    token: token,
                    userName: base64Decoded.unique_name,
                    userShortName: base64Decoded.nameid,
                    sessionBeginAt: base64Decoded.sn_openat,
                    sessionEndWarningAt: base64Decoded.sn_closewarnat,
                    sessionEolAt: base64Decoded.sn_eolat,
                    sessionEolWarningAt: base64Decoded.sn_eolwarnat,
                    localTimeDiscrepancyMilliseconds: localTimeDiscrepancy,
                    itarUser: base64Decoded.sn_itar === "1"
                } as AuthData
            } as User;
            return userObj;
        }
        return null;
    }
    
    // Loads authentication data from local storage
    readAuthData(): User | null {
        try {
            let storageItem = this.storageAgent.getItem(ADAuthenticationHelper.stpAuthStorageKey, true);
            if (storageItem) {
                let storageUser = this.parseStpJwtLite(storageItem.data);
                if (storageUser !== null && storageUser.authData !== undefined) {
                    storageUser.authData.localTimeDiscrepancyMilliseconds = (storageItem.dataInt === undefined) ? 0 : storageItem.dataInt;
                    return storageUser;
                }
            }
        } catch { 
            // ignore. Invalid storage item format
        }
        return null;
    }

    // Saves authentication data into the local storage
    writeAuthData(dataValue: AuthData): void {
        if (dataValue !== undefined && dataValue !== null ) {
            let sessionExpAtSeconds = parseInt(dataValue.expiresOn, 10);
            let newStorageItem = {
                expiresOn: new Date(sessionExpAtSeconds * 1000),                
                data: dataValue.token,
                dataInt: dataValue.localTimeDiscrepancyMilliseconds,
            } as StpStorageItem;

            this.storageAgent.setItem(ADAuthenticationHelper.stpAuthStorageKey, newStorageItem);
        }
    }

    clearAuthData(): void {
        this.storageAgent.removeItem(ADAuthenticationHelper.stpAuthStorageKey);
    }

    // Returns True if given string is empty
    isEmpty(str: string): boolean {
        return (typeof str === 'undefined' || !str || 0 === str.length);
    }

    createEmptyUser(): User {
        return {
            userName: '',
            userShortName: '',
            authData: undefined
        } as User;
    }

    /// Decodes the lite Jwt token and returns token claims in a form of a Json object
    // tslint:disable-next-line:no-any
    private decodeJwt(token: string): any | null {
        if (this.isEmpty(token)) {
            Diagnostics.writeLine('decodeJwt: The token was empty. Doing nothing.');
            return null;
        }
        var idTokenPartsRegex = /^([^\.\s]*)\.([^\.\s]+)\.([^\.\s]*)$/;
        var matches = idTokenPartsRegex.exec(token);
        if (!matches || matches.length < 4) {
            Diagnostics.writeLine('decodeJwt: The token was not in the right format. Doing nothing.');
            return null;
        }
        return this.base64DecodeStringUrlSafe(matches[2]);
    }

    // tslint:disable-next-line:no-any
    private base64DecodeStringUrlSafe(base64token: string): any {
        base64token = base64token.replace(/-/g, '+').replace(/_/g, '/');
        base64token = atob(base64token);
        return JSON.parse(base64token);
    }    
}