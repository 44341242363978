import * as React from 'react';
import { NotificationSettings, EmailFrequency } from '../Models/NotificationSettings';
import { Dialog, ChoiceGroup, IChoiceGroupOption, TextField, PrimaryButton, DialogType, Spinner, SpinnerSize, IDropdownProps, DialogFooter, DefaultButton, Toggle } from 'office-ui-fabric-react';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';

import './NotificationSettingsDialog.css';

enum NotificationString {
    ON = 'Yes',
    OFF = 'No'
}

// Define the properties that this component accepts as input and drives its behavior.
export interface NotificationSettingsDialogModel {
    currentSettings: NotificationSettings;
    hidden: boolean;
    saving: boolean;
    confirmSettingsHeader: boolean;
}

// Define the events that this component can raise.
export interface NotificationSettingsDialogEvents {
    onConfirm: (settings: NotificationSettings) => void;
    onCancel: () => void;
}

export interface NotificationSettingsDialogState {
    notifications: string;
    frequency: string;
    emails: string;
}

// Define the merged set of properties and events that are exposed by this component.
export type NotificationSettingsDialogProps = NotificationSettingsDialogModel & NotificationSettingsDialogEvents & IDropdownProps;

export class NotificationSettingsDialog extends React.Component<
    NotificationSettingsDialogProps,
    NotificationSettingsDialogState
    > {

    private EmailFrequencyMap: object;

    constructor(props: NotificationSettingsDialogProps) {
        super(props);

        const currentSettings: NotificationSettings = props.currentSettings;

        this.EmailFrequencyMap = {
            [EmailFrequency.Daily]: Intl.Get(LocIds.PageAndPanels.DailyFrequency),
            [EmailFrequency.Weekly]: Intl.Get(LocIds.PageAndPanels.WeeklyFrequency),
            [EmailFrequency.Monthly]: Intl.Get(LocIds.PageAndPanels.MonthlyFrequency),
        };

        this.state = {
            notifications: currentSettings.isNotificationOn ? NotificationString.ON : NotificationString.OFF,
            emails: currentSettings.emails.join(', '),
            frequency: this.EmailFrequencyMap[currentSettings.interval]
        };
    }

    private onChangeNotifications = (option: boolean): void => {
        this.setState({ notifications: (option ? NotificationString.ON : NotificationString.OFF) });
    } 

    // tslint:disable-next-line:no-any
    private _onChangeFrequency = (ev: React.FormEvent<HTMLInputElement>, option: any): void => {
        switch (option.key) {
            case this.EmailFrequencyMap[EmailFrequency.Daily]:
            case this.EmailFrequencyMap[EmailFrequency.Weekly]:
            case this.EmailFrequencyMap[EmailFrequency.Monthly]:
                this.setState({ frequency: option.key });
                break;

            default:
                throw new Error(`Can't set notification frequency to: ${option.key}`);
        }
    }

    private _onChangeEmail = (newValue?: string) => {
        this.setState({ emails: newValue || '' });
    }

    render() {
        return (
            <Dialog
                hidden={this.props.hidden}
                onDismiss={this.props.onCancel}
                dialogContentProps = {{
                    title: Intl.Get(LocIds.PageAndPanels.NotificationSettingsConfirmationTitle),
                    type: DialogType.largeHeader,
                    titleProps: {"aria-level": 2}
                }}
                modalProps={{
                    containerClassName: 'ms-dialogMainOverride',
                    isBlocking: true,
                }}
            >
                <p>
                    {Intl.Get(LocIds.PageAndPanels.NotificationSettingsDialogText)}
                </p>
                <br/>
                {this.props.saving &&
                    <div>
                        <Spinner
                            size={SpinnerSize.small}
                            ariaLive="assertive"
                            ariaLabel={`${Intl.Get(LocIds.PageAndPanels.NotificationSettingsAnnouncement)}, ${this.props.confirmSettingsHeader ? Intl.Get(LocIds.PageAndPanels.SavedToLibraryAnnouncement) : ''}`}
                            className="loading-panel-full highZIndex"
                        />
                    </div>
                }
                <div>
                    <Toggle
                        checked={this.state.notifications === NotificationString.ON || false}
                        label={Intl.Get(LocIds.PageAndPanels.NotificationSettingsLabel)}
                        onAriaLabel={Intl.Get(LocIds.PageAndPanels.NotificationSettingsOnArialLabel)}
                        offAriaLabel={Intl.Get(LocIds.PageAndPanels.NotificationSettingsOffArialLabel)}
                        onText={NotificationString.ON}
                        offText={NotificationString.OFF}
                        onChanged={(value) => { this.onChangeNotifications(value) }}
                    />
                    <TextField
                        label={Intl.Get(LocIds.PageAndPanels.NotificationSettingsEmailLabel)}
                        placeholder="name@company.com"
                        ariaLabel={Intl.Get(LocIds.PageAndPanels.NotificationSettingsEmailLabel)}
                        onChange={(ev: React.FormEvent<HTMLInputElement>, newValue: string) => this._onChangeEmail(newValue)}
                        value={this.state.emails}
                    />
                    <br/>
                    <ChoiceGroup
                        selectedKey={this.state.frequency}
                        options={[
                            {
                                key: this.EmailFrequencyMap[EmailFrequency.Daily],
                                text: this.EmailFrequencyMap[EmailFrequency.Daily],
                            } as IChoiceGroupOption,
                            {
                                key: this.EmailFrequencyMap[EmailFrequency.Weekly],
                                text: this.EmailFrequencyMap[EmailFrequency.Weekly],
                            } as IChoiceGroupOption,
                            {
                                key: this.EmailFrequencyMap[EmailFrequency.Monthly],
                                text: this.EmailFrequencyMap[EmailFrequency.Monthly],
                            } as IChoiceGroupOption,
                        ]}
                        onChange={this._onChangeFrequency}
                        label={Intl.Get(LocIds.PageAndPanels.NotificationSettingsFrequencyLabel)}
                    />
                    <DialogFooter>
                        <PrimaryButton onClick={this._onConfirmClick} text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)} />
                        <DefaultButton onClick={this.props.onCancel} text={Intl.Get(LocIds.ComplianceManagerContent.Cancel)} />
                    </DialogFooter>
                </div>
            </Dialog>
        );
    }

    private mapFrequency = (setting: string): number => {
        if (this.EmailFrequencyMap[EmailFrequency.Monthly] === setting) {
            return EmailFrequency.Monthly;
        } else if (this.EmailFrequencyMap[EmailFrequency.Weekly] === setting) {
            return EmailFrequency.Weekly;
        } else {
            return EmailFrequency.Daily;
        }
    }

    private _onConfirmClick = () => {
        const newSettings: NotificationSettings = {
            emails: this.state.emails.split(',').map((s) => s.trim()),
            interval: this.mapFrequency(this.state.frequency),
            isNotificationOn: this.state.notifications !== NotificationString.OFF,
            savedDocuments: [],
            totalUpdatedCount: 0
        };
        this.props.onConfirm(newSettings);
    }
}

export default NotificationSettingsDialog;
