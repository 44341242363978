import * as React from 'react';
import { Unsubscribe } from 'redux';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { VocabularyDefinition } from '../Models/Vocabulary';
import { store } from '../../Store';
import { saveVocabulary } from '../Reducers/VocabulariesManagement';
import { VocabularyServiceClient } from '../Services/VocabularyService';
import { getLocalizedValue } from '../../Common/Utilities/LocalizationHelper';
import { MultiSelectContextualMenu } from '../../Common/Components/MultiSelectContextualMenu';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';
import { isVocabularyShown } from '../Models/Vocabulary/VocabularyValueDefinition';

export enum SelectionMode {
    single = 1,
    multiple = 2,
}

export interface VocabularySelectorProps {
    vocabularyName: string;
    values: string[];
    onChanged?: (vocabulary: string, values: string[]) => void;
    selectionMode: SelectionMode;
    style?: React.CSSProperties;
    displayNone?: boolean;
    showRestrictedValue?: boolean;
    displayAny?: boolean;   
    isNewUI: boolean;
}

export interface VocabularySelectorState {
    vocabulary?: VocabularyDefinition;
    values: string[];
    selectionMode: SelectionMode;
}

export class VocabularySelector extends React.Component<VocabularySelectorProps, VocabularySelectorState> {
    private storeUnsubscribe: Unsubscribe;   

    constructor(props: VocabularySelectorProps) {
        super(props);
        this.state = {
            values: props.values,
            selectionMode: props.selectionMode
        };
    }

    UNSAFE_componentWillReceiveProps(props: VocabularySelectorProps) {
        this.setState({
            values: props.values,
            selectionMode: props.selectionMode
        });
    }
    componentDidMount() {
        var storeVocabulary = store.getState().vocabulariesManagement;
        if (storeVocabulary.status === 'Init') {
            store.dispatch({type: 'VOCABULARIES_FETCHING'});
            VocabularyServiceClient.listVocabularies(
                () => { return; }, 
                (r) => {
                    store.dispatch(saveVocabulary(r.data));
                    let vocabularyDefinitions: VocabularyDefinition[] = r.data;
                    if (vocabularyDefinitions) {
                        let vocabulary = vocabularyDefinitions.find(v => v.name === this.props.vocabularyName);
                        this.setState({ vocabulary: vocabulary });
                    }
                },                                         
                () => { return; });
        } else if (storeVocabulary.status === 'Finished') {
            if (this.props.vocabularyName) {
                let vocabulary = storeVocabulary.vocabularyDefinitions.find( (v: VocabularyDefinition) => v.name === this.props.vocabularyName);
                this.setState({ vocabulary: vocabulary });
            }
        } else {
            VocabularyServiceClient.listVocabularies(
                () => { return; }, 
                (r) => {
                    let vocabularyDefinitions: VocabularyDefinition[] = r.data;
                    if (vocabularyDefinitions) {
                        let vocabulary = vocabularyDefinitions.find(v => v.name === this.props.vocabularyName);
                        this.setState({ vocabulary: vocabulary });
                    }
                },                                         
                () => { return; });
        }

        this.storeUnsubscribe = store.subscribe(() => {
            if (this.props.vocabularyName) {
                let vocabulary = storeVocabulary.vocabularyDefinitions.find( (v: VocabularyDefinition) => v.name === this.props.vocabularyName);
                this.setState({ vocabulary: vocabulary });
            }
            this.storeUnsubscribe();
        });
    }

    callOnchanged() {
        if (this.props.onChanged) {
            this.props.onChanged(this.props.vocabularyName, this.state.values);
        }
    }
    
    componentWillUnmount() {
        this.storeUnsubscribe();
    }

    public render(): JSX.Element {
        if (!this.props.vocabularyName || !this.state.vocabulary) {
            return <span />;
        }
        let vocabularyMenuItems: IContextualMenuItem[] = [];
        this.state.vocabulary.choices.filter(c => isVocabularyShown(this.props.isNewUI, c))
        .forEach(x => {
            if (this.props.showRestrictedValue || !x.useToRestrictContentToCertainCustomers) {
                vocabularyMenuItems.push({
                    id: x.id,
                    key: x.id,
                    name: (x.displayText && x.displayText.lv ? x.displayText.lv : getLocalizedValue(x.displayValues, true)) + (this.props.showRestrictedValue && x.useToRestrictContentToCertainCustomers ? ' (R)' : ''),
                    canCheck: true,
                    isChecked: this.state.values ? this.state.values.indexOf(x.id) >= 0 : false,
                    onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => {
                        let key = item.key;
                        let values = this.state.values.slice();
                        const currentIndex = values.indexOf(key);                        
                        if (currentIndex < 0) {                            
                            if (values.length === 1 && values[0] === 'All') {
                                values = [];
                            }
                            if (this.state.selectionMode === SelectionMode.single) {
                                values = [key];
                            } else {
                                values.push(key);
                            }
                            this.setState({values: values}, () => this.callOnchanged());
                        } else {
                            values.splice(currentIndex, 1);
                            this.setState({values: values}, () => this.callOnchanged());
                        }
                }});
            }
        });

        if (!this.state.vocabulary.useToRestrictContentToCertainCustomers && this.state.selectionMode === SelectionMode.multiple) {
            if (this.props.displayNone) {
                vocabularyMenuItems.unshift({
                    id: 'None',
                    key: 'None',
                    name: 'None',
                    canCheck: true,
                    isChecked: false,
                    onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => {
                        this.setState({values: []}, () => this.callOnchanged());
                    }
                });
            }
            
            if (!this.state.vocabulary.hideAll) {
                vocabularyMenuItems.unshift({
                    id: 'All',
                    key: 'All',
                    name: Intl.Get(LocIds.VocabularyManagement.AllLabel),
                    canCheck: true,
                    isChecked: false,
                    onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => {
                        if (this.props.displayNone) {
                            this.setState({values: ['All']}, () => this.callOnchanged());
                        } else {
                            this.setState({values: this.state.values[0] === 'All' ? [] : ['All']}, () => this.callOnchanged());
                        }
                    }
                });
                if (this.state.values && this.state.values.length && this.state.values[0] === 'All') {
                    vocabularyMenuItems.forEach(item => {
                        item.id === 'All' ? item.isChecked = true : item.isChecked = false;
                    });
                }
                if (this.state.values && !this.state.values.length) {
                    vocabularyMenuItems.forEach(item => {
                        item.isChecked = (item.id === 'None');
                    });
                }
            }
        } else {

            // Add synthetic Any option if needed
            if (this.props.displayAny) {
                vocabularyMenuItems.unshift({
                    id: 'Any',
                    key: 'Any',
                    name: Intl.Get(LocIds.VocabularyManagement.AnyLabel),
                    canCheck: true,
                    isChecked: (this.state.values && this.state.values.length > 0 && this.state.values.indexOf('Any') > -1),
                    onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => {
                        this.setState({values: this.state.values.indexOf('Any') > -1 ? [] : ['Any']}, () => this.callOnchanged());
                    }
                });
            }

            if (this.props.displayNone) {
                vocabularyMenuItems.unshift({
                    id: 'None',
                    key: 'None',
                    name: Intl.Get(LocIds.VocabularyManagement.NoneLabel),
                    canCheck: true,
                    isChecked: (this.state.values && this.state.values.length === 0),
                    onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => {
                        this.setState({values: []}, () => this.callOnchanged());
                    }
                });
            }            
        }

        return (
            <MultiSelectContextualMenu
                text={this.state.vocabulary && this.state.vocabulary.displayText && this.state.vocabulary.displayText.lv ? this.state.vocabulary.displayText.lv : this.props.vocabularyName}
                menuItems={vocabularyMenuItems}
                style={this.props.style}
                ariaLabelPrefix={Intl.Get(LocIds.Search.FilterBy)}
            />
        );
    }
}

export default VocabularySelector;
