export enum ImplementationStatus {
    Select,
    NotImplemented,
    Implemented,
    AlternativeImplementation,
    Planned,
    NotInScope,
    PartiallyImplemented,
    ToBeDetermined,
    CouldNotBeDetermined
}