import { AxiosResponse } from 'axios';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { ErrorResponse } from '../../App/Services/Models';
import { ApplicationUserInfo } from './ApplicationUserInfo';
import { TopNavigationSettings } from '../../App/Models/TopNavigationSettings';
import { AvailableCulture } from '../../App/Models/Settings/AvailableCulture';
import { TelemetryClient } from '../../Common/Utilities/Telemetry';
import { SecureScoreIntegrationLevel } from '../../App/Models/ComplianceManagerV3/SecureScoreIntegrationLevel';
import { CookieConsentServiceInstance } from '../Services/CookieConsentService';

export enum CloudInstance {
    OneBox = 'OneBox',
    Preproduction = 'Preproduction',
    Public = 'Public',
    BlackForest = 'BlackForest',
    Gallatin = 'Gallatin',
    ITAR = 'ITAR'
}

// This class is used to describe the application execution context that is received from the web API.
export class ApplicationContext {
    applicationClientId: string;
    applicationLoginUrl: string;
    applicationGraphUrl: string;
    applicationScopes: string;
    cultureName: string;
    userInfo: ApplicationUserInfo;
    topNavigationSettings: TopNavigationSettings;
    availableCultures: AvailableCulture[];
    assemblyVersion: string;
    appInsightsId: string;
    azureResourcesCloudInstance: CloudInstance;
    maxFileUploadSizeInBytes: number;
    complianceManagerInfoMessage: string;
    complianceManagerInfoMessageV3: string;
    maxEvidenceFileUploadSizeInBytes: number;
    secureScoreIntegrationLevel:SecureScoreIntegrationLevel;
    isCMV4Enabled: boolean;
    isItarEnabled: boolean;
    redirectUrl: string;
    redirectPath: {};
    pageRedirectMapping: {[key: string]: string;};
    maxTemplateUploadSizeInBytes : number;
}

// This interface is used for dependency injection to fetch the ApplicationContext.
export interface ApplicationContextFactory {
    startRequestToGetApplicationContext: (
        onSuccess?: (applicationContext: ApplicationContext) => void,
        onFailed?: () => void
    ) => void;
}

export class DefaultApplicationContextFactory implements ApplicationContextFactory {
    public startRequestToGetApplicationContext(onSuccess?: (applicationContext: ApplicationContext) => void, onFailed?: () => void): void {
        ServiceTrustClient.doRequest_New(
            'get', // method
            '/api/v2/ApplicationContext', // urlOrApi
            {}, // data
            () => null, // beforeRequest
            // onRequestSuccess
            (response: AxiosResponse) => {
                let applicationContext: ApplicationContext = response.data;
                if (onSuccess) {
                    onSuccess(applicationContext);
                    if (applicationContext.appInsightsId) {
                        // Pass this applicationContext.appInsightsId to Initialize
                        TelemetryClient.Initialize(applicationContext.appInsightsId, CookieConsentServiceInstance.isAnalyticsCookiesConsented());
                    }
                }
            },
            // onRequestFailed
            (response: ErrorResponse) => {
                if (onFailed) {
                    onFailed();
                }
            },
            'statusMessageIdThatDoesNotExist' // statusMessageId
        );
    }
}
