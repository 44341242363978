import { ImageInfo } from '../Models/Image';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';

enum SortColumnName {
    Title = 'Title',
    Size = 'Size',
    LastModified = 'LastModified'
}

export function sortImage(imageinfos: ImageInfo[] | undefined, sortType: SortColumnName, isDescending: boolean) {
    if (!imageinfos) {
        return;
    }   
    switch (sortType) {
        case SortColumnName.Title:
            sortImageByTitle(imageinfos, isDescending);
            break;
        case SortColumnName.Size:
            sortImageBySize(imageinfos, isDescending);
            break;
        case SortColumnName.LastModified:
            sortImageByDateModified(imageinfos, isDescending);
            break;
        default:
            return;
    }
}

export function updateColumnSortStatus(newColumn: SortColumnName, currentSortColumn: SortColumnName, columns: IColumn[], isDescending: boolean, reset?: boolean) {
    // reset the order icon of all columns
    columns.map((column) => {
        column.isSorted = undefined;
        column.isSortedDescending = undefined;
    });
    // reset sort order
    if (newColumn === currentSortColumn && !reset) {
        isDescending = !isDescending;
    } else {
        // if the newColumn admin choose is not 'LastModified', set the sort order as ascending, 
        // if the newColumn admin choose is 'LastModified', set the sort order as descending.
        isDescending = newColumn === 'LastModified';
        currentSortColumn = newColumn;
    }
    let targetColumnIndex = columns.findIndex(column => column.key === newColumn);
    if (targetColumnIndex >= 0) {
        columns[targetColumnIndex].isSorted = true;
        columns[targetColumnIndex].isSortedDescending = isDescending;
    }
    return {currentSortColumn: currentSortColumn, isDescending: isDescending};
}

export function getImageUsingStatus(item: ImageInfo): string[] {
    let imageUsingStatus: string[] = [];
    if (item.usedByPages && Object.keys(item.usedByPages).length > 0) {
        Object.keys(item.usedByPages).map(function(key: string) {
            for (var i = 0; i < item.usedByPages[key].length; i++) {
                imageUsingStatus.push(`${key}: ${item.usedByPages[key][i]}`);
            }
         });
    } else {
        imageUsingStatus = ['Not in use.'];
    }
    return imageUsingStatus;
}

function sortImageByTitle(imageInfos: ImageInfo[], isDescending: boolean) {
    imageInfos.sort((a, b) => {
        let aLower = a.title.toLocaleLowerCase();
        let bLower = b.title.toLocaleLowerCase();
        if (isDescending) {
            return aLower < bLower ? 1 : aLower > bLower ? -1 : 0;
        } else {
            return aLower < bLower ? -1 : aLower > bLower ? 1 : 0;
        }
    });
}

function sortImageBySize(imageInfos: ImageInfo[], isDescending: boolean) {
    imageInfos.sort((a, b) => {
        if (isDescending) {
            return a.size < b.size ? 1 : a.size > b.size ? -1 : 0;
        } else {
            return a.size < b.size ? -1 : a.size > b.size ? 1 : 0;
        }
    });
}

function sortImageByDateModified(imageInfos: ImageInfo[], isDescending: boolean) {
    imageInfos.sort((a, b) => {
        if (isDescending) {
            return a.whenCreated < b.whenCreated ? 1 : a.whenCreated > b.whenCreated ? -1 : 0;
        } else {
            return a.whenCreated < b.whenCreated ? -1 : a.whenCreated > b.whenCreated ? 1 : 0;
        }
    });
}