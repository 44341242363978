export class PropsIdScrubber {
  public stripIdsFromProperties(
    props: { [name: string]: string | undefined },
    userId: string
  ): void {
    var vals = Object.keys(props)
      .map((key) => (props[key] || "").toLowerCase())
      .filter((v) => !!v);
    if (vals.indexOf(userId.toLowerCase()) > 0) {
      Object.keys(props).forEach(function (key: string) {
        if (
          (props[key] || "").toLowerCase().indexOf(userId.toLowerCase()) > 0
        ) {
          props[key] = (props[key] || "")
            .toLowerCase()
            .replace(userId.toLowerCase(), ""); // When property contains user id, replace with an empty string.
        }
      });
    }
  }
}
