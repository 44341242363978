import { Reducer } from 'redux';
import { TopNavigationSettings } from '../Models/TopNavigationSettings';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export type Status = 'Init' | 'Requesting' | 'Success' | 'Error' | 'Finished';

export interface TopNavigationManagementState {
    status: Status;
    topNavigationSettingsVersions: TopNavigationSettings[];
    message?: string;
}

// 
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface TopNavigationSettingsRequesting {
    type: 'TOP_NAVIGATION_SETTINGS_REQUESTING';
}

interface TopNavigationSettingsRequestSucceed {
    type: 'TOP_NAVIGATION_SETTINGS_REQUEST_SUCCEED';
    message: string;
    topNavigationSettingsVersions: TopNavigationSettings[];
}

interface TopNavigationSettingsRequestError {
    type: 'TOP_NAVIGATION_SETTINGS_REQUEST_ERROR';
    // tslint:disable-next-line:no-any
    message: string;
}

export type TopNavigationSettingsActions =  TopNavigationSettingsRequesting | TopNavigationSettingsRequestSucceed | TopNavigationSettingsRequestError;

export function getTopNavigationSettingsRequestingAction(): TopNavigationSettingsActions {
    return {
        type: 'TOP_NAVIGATION_SETTINGS_REQUESTING',
    };
}

export function getTopNavigationSettingsRequestSucceedAction(message: string, data: TopNavigationSettings[]): TopNavigationSettingsActions {
    return {
        type: 'TOP_NAVIGATION_SETTINGS_REQUEST_SUCCEED',
        message: message,
        topNavigationSettingsVersions: data
    };
}

// tslint:disable-next-line:no-any
export function getTopNavigationSettingsRequestErrorAction(message: string): TopNavigationSettingsActions {
    return {
        type: 'TOP_NAVIGATION_SETTINGS_REQUEST_ERROR',
        message: message,
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<TopNavigationManagementState> = (state: TopNavigationManagementState, action: TopNavigationSettingsActions) => {
    
    switch (action.type) {
        case 'TOP_NAVIGATION_SETTINGS_REQUESTING':
            return { 
                ...state, 
                status: 'Requesting', 
                topNavigationSettingsVersions: [] as TopNavigationSettings[], 
            };           

        case 'TOP_NAVIGATION_SETTINGS_REQUEST_SUCCEED':
            return {
                ...state,
                status: 'Success',
                message: action.message,
                topNavigationSettingsVersions: action.topNavigationSettingsVersions
            };            

        case 'TOP_NAVIGATION_SETTINGS_REQUEST_ERROR':
            return {
                ...state,
                status: 'Error',
                topNavigationSettingsVersions: [] as TopNavigationSettings[],
                message: action.message,
            };

        default:
            return (state === undefined)
            ? {
                status: 'Init',
                topNavigationSettingsVersions: [] as TopNavigationSettings[],
                message: '',
            }
            : state;
    }
};
