export default class Constants {
    static fileUploadFragmentSize: number = 512000;
    static PANEL_TITLE_MAX: number = 300;
    static PANEL_HEADING_MAX: number = 300;
    static PANEL_DESCRIPTION_MAX: number = 5000;
    static RestrictedDocTypes: string[] = ['ADE', 'ADP', 'APP', 'ASP', 'BAS', 'BAT', 'CER', 'CHM', 'CMD', 'CNT', 'COM', 'CPL', 'CRT', 'CSH', 'DER', 'DIAGCAB', 'EXE', 'FXP', 'GADGET', 'GRP',
        'HLP', 'HPJ', 'HTA', 'INF', 'INS', 'ISP', 'ITS', 'JAR', 'JNLP', 'JS', 'JSE', 'KSH', 'LNK', 'MAD', 'MAF', 'MAG', 'MAM', 'MAQ', 'MAR', 'MAS', 'MAT', 'MAU', 'MAV', 'MAW', 'MCF', 'MDA',
        'MDB', 'MDE', 'MDT', 'MDW', 'MDZ', 'MSC', 'MSH', 'MSH1', 'MSH2', 'MSHXML', 'MSH1XML', 'MSH2XML', 'MSI', 'MSP', 'MST', 'MSU', 'OPS', 'OSD', 'PCD', 'PIF', 'PL', 'PLG', 'PRF', 'PRG',
        'PRINTEREXPORT', 'PS1', 'PS1XM', 'PS2', 'PS2XML', 'PSC1', 'PSC2', 'PSD1', 'PSDM1', 'PST', 'REG', 'SCF', 'SCR', 'SCT', 'SHB', 'SHS', 'THEME', 'TMP', 'URL', 'VB', 'VBE', 'VBP', 'VBS',
        'VSMACROS', 'VSW', 'WEBPNP', 'WEBSITE', 'WS', 'WSC', 'WSF', 'WSH', 'XBAP', 'XLL', 'XNK'];
    // Values for Permission Dropdown, paired with Scope
    static PERMISSIONS_OPTIONS: { key: string, text: string }[] = [
        { key: 'create', text: 'Create' },
        { key: 'read', text: 'Read' },
        { key: 'modify', text: 'Modify' },
        { key: 'archive', text: 'Archive' },
        { key: 'delete', text: 'Delete' },
        { key: 'publish', text: 'Publish' },
        { key: 'approve', text: 'Approve' },
        { key: 'assess', text: 'Assess' }
    ];
    static GUID_EMPTY: string = '00000000-0000-0000-0000-000000000000';

    static STATIC_PAGES_LIST: { key: string, text: string }[] = [
        { key: '/', text: 'Service Trust' },
        { key: '/ComplianceManager', text: 'Compliance Manager' },
        { key: '/Setting/UserRoles', text: 'Settings' },
        { key: '/Library', text: 'My Library' },
        { key: '/DocumentDownloadHistoryPage', text: 'My Download History' },
        { key: '/PrivacyPortal', text: 'Privacy Portal' },
        { key: '/AdminPage/GDPR/BreachNotification', text: 'Breach Notification' },
        { key: '/AdminPage/ApplicationConfiguration', text: 'Application Configuration' },
        { key: '/AdminPage/TopNavigationSettings', text: 'Top Navigation Management' },
        { key: '/AdminPage/Vocabularies', text: 'Vocabularies Management' },
        { key: '/AdminPage/ImageManager', text: 'Image Management' },
        { key: '/AdminPage/GDPR/LinkManager', text: 'GDPR Hub Link Management' },
        { key: '/Pages/All', text: 'Manage Pages' },
    ];

    static DOCUMENT_DOWNLOAD_MAX: number = 10;
}
