import { ImplementationStatus, UserActionStatus } from '../../Models/ComplianceManagerV2';

export class CertificationDetailsFilter {
    cloudServiceId: string;
    tileId: string;
    articleName: string;
    articleControls: string[];
    controlIds: string[];
    controlFamilyIds: string[];
    assessmentUserFilter: string[];
    statusFilter: ImplementationStatus[];
    testResultFilter: UserActionStatus[];
}