import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';

export interface UserPersona extends IPersonaProps {
    anonymousId: string;
    userPrincipalName?: string;
    id: string;
    email: string;
}

export const EmptyUserPersona: UserPersona = {
    anonymousId: '',
    id: '',
    email: ''
};