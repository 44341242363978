import * as React from 'react';
import { TextField, ITextField } from 'office-ui-fabric-react/lib/TextField';
import { ITextFieldProps } from 'office-ui-fabric-react/lib/TextField';
import { OfficeWrapperProps, ValidatableField } from './OfficeWrapperProps';
import ErrorLabel from './ErrorLabel';

export interface TextFieldWrapperProps extends ITextFieldProps, OfficeWrapperProps {
    url?: boolean;
    isPage?: boolean;
    fieldType?: string;
}

interface TextFieldWrapperState {
    error: string;
}

export class TextFieldWrapper extends React.Component<TextFieldWrapperProps, TextFieldWrapperState> implements ValidatableField {
    textField: ITextField | null;
    isUnmount?: boolean;
    constructor(props: TextFieldWrapperProps) {
        super(props);
        this.state = {
            error: ''
        };
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        this.props.validator.add(this);
        this.isUnmount = false;
    }

    componentWillUnmount() {
        this.isUnmount = true;
    }

    validate() {
        if (this.isUnmount) {
            return true;
        }
        if (this.props.required && this.props.value === '') {
            this.setState({error: 'Required'});
            return false;
        }
        if (this.props.url && this.props.isPage === undefined && this.props.value !== '') {
            let regex = new RegExp('^https://.*\..*');
            if (!regex.test(this.props.value || '')) {
                this.setState({error: 'URL must start with https:// and must have proper format.'});
                return false;
            }
        }
        if (this.props.url && this.props.isPage && this.props.value !== '') {
            let regexUrl = new RegExp('^https://.*\..*');
            let regexPage = new RegExp('^/.*\..*');
            if (!regexUrl.test(this.props.value || '') && !regexPage.test(this.props.value || '')) {
                this.setState({error: 'URL must start with https:// or page must start with /, and must have proper format.'});
                return false;
            }
        }
        if (this.props.pattern && this.props.value !== '') {
            let regex = new RegExp(this.props.pattern || '');
            if (!regex.test(this.props.value || '')) {
                this.setState({error: 'Value is not valid.'});
                return false;
            }
        }
        if (this.props.fieldType === 'email') {
            // no accurate regular express, this one is used in ASP.NET by the RegularExpressionValidator
            let regex = new RegExp('^\\w+([-+.\']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$')
            if (!regex.test(this.props.value || '')) {
                this.setState({error: 'Please input a valid email address.'});
                return false;
            }
        }
        if (this.props.customValidator) {
            const customError = this.props.customValidator(this.props.value || '');
            if (customError !== '') {
                this.setState({error: customError});
                return false;
            }
        }
        this.setState({error: ''});
        return true;
    }

    render() {
        return (
            <div>
                <TextField 
                    onBlur={this.validate} 
                    componentRef={(tf: ITextField | null) => this.textField = tf}
                    {...this.props} 
                />
                {this.state.error !== '' && 
                    <ErrorLabel error={this.state.error} />
                }
            </div>
        );
    }
}

export default TextFieldWrapper;