import './VocabularyFilterDropdown.css';

import * as React from 'react';

import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react/lib/Dropdown';
import { SelectedVocabulary, VocabularyDefinition, VocabularyValueDefinition } from '../Models/Vocabulary';
import { isVocabularyShown } from '../Models/Vocabulary/VocabularyValueDefinition';
import { getLocalizedString, getLocalizedValue } from '../../Common/Utilities/LocalizationHelper';

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { store } from 'src/Store';

// Define the properties that this component accepts as input and drives its behavior.
export interface VocabularyFilterDropdownModel {
  vocabulary?: VocabularyDefinition;
  selectedFilters?: SelectedVocabulary[];
}

// Define the events that this component can raise.
export interface VocabularyFilterDropdownEvents {
  onSelectionChanged?: (vocabulary: VocabularyDefinition | undefined, selectedChoices: VocabularyValueDefinition[]) => void;
}

export interface VocabularyFilterDropdownState {
  options: IDropdownOption[];
}

// Define the merged set of properties and events that are exposed by this component.
export type VocabularyFilterDropdownProps = VocabularyFilterDropdownModel & VocabularyFilterDropdownEvents & { dropdownWidth: number, className?: string };

export class VocabularyFilterDropdown extends React.Component<
  VocabularyFilterDropdownProps,
  VocabularyFilterDropdownState
> {
  constructor(props: VocabularyFilterDropdownProps) {
    super(props);

    this.state = {
      options: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: VocabularyFilterDropdownProps) {
    if (nextProps && nextProps.vocabulary) {
      this._setOptions(nextProps.vocabulary);
    }
  }

  componentWillMount() {
    if (this.props.vocabulary) { this._setOptions(this.props.vocabulary); }
  }

  public onChangeMultiSelect = (item: IDropdownOption, index: number): void => {
    const { vocabulary } = this.props;
    if (!vocabulary) { return; }

    const selectedKeys = this._getSelectedKeys();
    // we always start with the currently selected list from our props (as a controlled component)
    const updatedSelectedChoices: VocabularyValueDefinition[] = [...vocabulary.choices].filter((c) => selectedKeys.indexOf(c.id) >= 0);
    const choice = vocabulary.choices.find(c => c.id === item.key);

    if (!choice) { return; } // we can't find this choice in our options...

    // now we augment the current list with the newly taken action
    if (item.selected) {
      // add the option if it's checked
      updatedSelectedChoices.push(choice);
    } else {
      // remove the option if it's unchecked
      const currIndex = updatedSelectedChoices.indexOf(choice);
      if (currIndex > -1) {
        updatedSelectedChoices.splice(currIndex, 1);
      }
    }

    // ... and send the new list to the event handler
    if (this.props.onSelectionChanged) { this.props.onSelectionChanged(this.props.vocabulary, updatedSelectedChoices || []); }
  }

  render() {
    const { options } = this.state;
    const { vocabulary, className } = this.props;
    const name = vocabulary ? getLocalizedString(vocabulary.displayText) || vocabulary.name : undefined;

    return (
      <Dropdown
        className={className}
        ariaLabel={name}
        title={name}
        placeHolder={name}
        label=""
        selectedKeys={this._getSelectedKeys()}
        onChanged={this.onChangeMultiSelect}
        onRenderPlaceHolder={this._onRenderPlaceHolder}
        onRenderTitle={this._onRenderTitle}
        onRenderOption={this._onRenderOption}
        onRenderCaretDown={this._onRenderCaretDown}
        multiSelect={true}
        options={options}
        {...this.props}
        aria-label={name}
      />
    );
  }

  private _setOptions = (vocab: VocabularyDefinition) => {
    const isNewUI = store.getState().applicationContext.userInfo.flights.indexOf('NewUIDesign') > -1;
    this.setState({
      options: vocab.choices.filter(c => isVocabularyShown(isNewUI, c)).map((choice) => {
        return {
          key: choice.id,
          text: getLocalizedValue(choice.displayValues, false),
          data: { ...choice },
          disabled: false
        };
      }).sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
    });
  }

  private _onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div className="dropdownExample-option">
        {option.data &&
          option.data.icon && (
            <Icon style={{ marginRight: '8px' }} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
          )}
        <span>{option.text}</span>
      </div>
    );
  }

  private _onRenderPlaceHolder = (props: IDropdownProps): JSX.Element => {
    return this._renderName();
  }

  private _onRenderCaretDown = (props: IDropdownProps): JSX.Element => {
    return <Icon iconName="ChevronDown" />;
  }

  /**
   * Our design is going to show the selections is a separate group of 'choice summary'
   * so we'll continue to show the placeholder here...
   *
   * This method usually displays the option titles, but we really need the IDropdownProps
   * to get the placeholder... We'll work around it by explicitly picking it off our
   * component's props.
   */
  private _onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    return this._renderName();
  }

  private _renderName = (): JSX.Element => {
    const { vocabulary } = this.props;
    const name = vocabulary ? getLocalizedString(vocabulary.displayText) || vocabulary.name : undefined;

    return (
      <div className="dropdownExample-option">
        <span>{name}</span>
      </div>
    );
  }

  private _getSelectedKeys = (): string[] => {
    const { vocabulary, selectedFilters } = this.props;
    if (!vocabulary || !selectedFilters) { return []; }

    const ourFilter = selectedFilters.find((s) => vocabulary.name === s.vocabulary);
    if (!ourFilter) { return []; } // nothing is currently filtered for this vocab

    // this is an array of "choice" keys currently filtered on
    return ourFilter.choice;
  }
}

export default VocabularyFilterDropdown;
