import {
    Checkbox,
    ComboBox,
    IComboBox,
    IComboBoxOption
} from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { FeaturePanelPropertiesData } from '../../Models/PageInfrastructure';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import {
    getCallToActionOptions,
    getFeatureAlignmentOptions,
    getFeatureBackgroundOptions
} from '../../Utilities/PageInfrastructure/PanelsHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import './FeaturePanel.css';
import { isEmptyString, RichTextLink, renderLink } from '../../../App/Utilities/RenderUtilities';

export enum imageAlignmentText {
    'f-align-left' = 'Copy Left',
    'f-align-center' = 'Copy Center',
    'f-align-right' = 'Copy Right',
}

// const Colors = Object.freeze({
//   RED:   { name: "red", hex: "#f00" },
//   BLUE:  { name: "blue", hex: "#00f" },
//   GREEN: { name: "green", hex: "#0f0" }
// });

export class FeaturePanel extends BasePanel<FeaturePanelPropertiesData> {
    private alignmentOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.AlignLabel), itemType: SelectableOptionMenuItemType.Header }];
    private backgroundOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.BackgroundColorLabel), itemType: SelectableOptionMenuItemType.Header }];
    private callToActionOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.CallToActionStyleLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<FeaturePanelPropertiesData>) {
        super(props);
        this.alignmentOptions = this.alignmentOptions.concat(getFeatureAlignmentOptions());
        this.backgroundOptions = this.backgroundOptions.concat(getFeatureBackgroundOptions());
        this.callToActionOptions = this.callToActionOptions.concat(getCallToActionOptions());
    }

    private getActualPanel = () => {
        const { imageAlignment, imageId, imageWidth, imageHeight, imagePriority, setRatio, backgroundColor, heading, headingUrl, subHeading, horizontalRule } = this.state.panelData;
        // prevents layout from collapsing until image has loaded
        let picturePaddingBottom = ((parseInt(imageHeight ? imageHeight : '0', 10) / parseInt(imageWidth ? imageWidth : '0', 10)) * 100) / 2;

        return (
            <div>
                {horizontalRule && (
                    <hr className="stp_panel-border-horizontal-rule-top" />
                )}
                <section
                    data-panel-type="Feature"
                    className={`m-feature ${imageAlignment} ${backgroundColor} ${setRatio && 'f-set-ratio'} ${imagePriority && 'f-image-priority'}`}
                >
                    <picture
                        style={{'paddingBottom': `${picturePaddingBottom}%`}}
                    >
                        {imageId && (
                            <img
                                aria-hidden={true}
                                src={imageHelper.getImageUrl(imageId)}
                                alt={getLocalizedString(heading)}
                                width={imageWidth}
                                height={imageHeight}
                            />
                        )}
                    </picture>
                    <div>
                        {/* these two features are not currently spec'd:
                        <strong className="c-badge f-small f-highlight">BADGE</strong>
                        <div className="c-logo">
                            <img className="c-image" src="http://placehold.it/150x50" alt="Placeholder with grey background and dimension watermark without any imagery" itemscope itemtype="http://schema.org/ImageObject" />
                            <span>logo-image</span>
                        </div>
                        */}
                        <h2 className="c-heading-2">
                            {headingUrl && headingUrl !== '' ?
                                renderLink(headingUrl, (getLocalizedString(heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)))
                            :
                                getLocalizedString(heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)
                            }
                        </h2>
                        {(subHeading && !isEmptyString(getLocalizedString(subHeading))) &&
                            <ReactMarkdown
                                renderers={{ link: RichTextLink }}
                                className="c-paragraph-2"
                                source={getLocalizedString(subHeading)}
                                skipHtml={true}
                            />
                        }
                        <div className="c-group">
                            {['One', 'Two', 'Three', 'Four'].map((item) => {
                                const cta: string = getLocalizedString(this.state.panelData[`callToAction${item}`]);
                                const ctaLink: string = this.state.panelData[`callToAction${item}Url`];
                                const ctaStyle: string = this.state.panelData[`callToAction${item}Style`];

                                if (cta && cta.length > 0 && ctaLink && ctaLink.length > 0) {
                                    return renderLink(ctaLink, cta, `c-call-to-action c-glyph f-lightweight ${ctaStyle}`);
                                }

                                return null;
                            })}
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    public render() {
        return (
            <div className="stp_feature">
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: 0 }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.imageAlignment || 'f-align-left'}
                                                text={this.state.panelData.imageAlignment ? imageAlignmentText[this.state.panelData.imageAlignment] : imageAlignmentText['f-align-left']}
                                                label={Intl.Get(LocIds.PageAndPanels.AlignLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.AlignLabel)}
                                                allowFreeform={false}
                                                autoComplete={'on'}
                                                options={this.alignmentOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.setState({ panelData: { ...this.state.panelData, imageAlignment: item.key.toString() }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <h4 className="c-heading-5">Feature Image</h4>
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={true}
                                                disabled={true}
                                                value={this.state.panelData.imageTitle || ''}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton aria-label="Choose feature image" onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length) {
                                                        this.setState({
                                                            panelData: ({
                                                                ...this.state.panelData,
                                                                imageId: images[0].url,
                                                                imageWidth: images[0].width,
                                                                imageHeight: images[0].height,
                                                                imageTitle: images[0].title
                                                            } as unknown) as FeaturePanelPropertiesData,
                                                            showImagePicker: false
                                                        });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-3" style={{paddingTop: '1em'}}>
                                            <Checkbox
                                                checked={this.state.panelData.setRatio || false}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, setRatio: isChecked }});
                                                }}
                                                label="Lock Aspect Ratio of Image"
                                                ariaLabel="Lock Aspect Ratio of Image"
                                            />
                                        </div>
                                        <div data-grid="col-3" style={{paddingTop: '1em'}}>
                                            <Checkbox
                                                checked={this.state.panelData.imagePriority || false}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, imagePriority: isChecked }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.ImagePriorityLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.ImagePriorityLabel)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.backgroundColor || ''}
                                                text={this.state.panelData.backgroundColor || ''}
                                                label={Intl.Get(LocIds.PageAndPanels.BackgroundColorLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.BackgroundColorLabel)}
                                                allowFreeform={false}
                                                autoComplete={'on'}
                                                options={this.backgroundOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.setState({ panelData: { ...this.state.panelData, backgroundColor: item.key.toString() }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <Checkbox
                                                checked={this.state.panelData.horizontalRule}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, horizontalRule: isChecked }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelBorderTopLabel)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'heading', newVal.substr(0, Constants.PANEL_HEADING_MAX));
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.headingUrl ? this.state.panelData.headingUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.setState({ panelData: { ...this.state.panelData, headingUrl: newVal }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.UrlLabel)}
                                                required={false}
                                                url={true}
                                                isPage={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={getLocalizedString(this.state.panelData.subHeading)}
                                                onChange={(newVal) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'subHeading', newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX));
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    {['One', 'Two', 'Three', 'Four'].map((item) => (
                                        <div data-grid="col-12" key={`edit-${item}`}>
                                            <div data-grid="col-5">
                                                <h4 className="c-heading-5">Call To Action {item}</h4>

                                                <TextFieldWrapper
                                                    value={getLocalizedString(this.state.panelData[`callToAction${item}`])}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                        setDefaultLocalizedString(this.state.panelData, `callToAction${item}`, newVal);
                                                        this.setState({ panelData: this.state.panelData });
                                                    }}
                                                    label={Intl.Get(LocIds.PageAndPanels.CallToActionLabel)}
                                                    required={false}
                                                    multiline={false}
                                                    validator={this.panelValidator}
                                                    placeholder={Intl.Get(LocIds.PageAndPanels.CallToActionLabel)}
                                                />

                                                <TextFieldWrapper
                                                    value={this.state.panelData[`callToAction${item}Url`] ? this.state.panelData[`callToAction${item}Url`] : ''}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                        this.setState({ panelData: { ...this.state.panelData, [`callToAction${item}Url`]: newVal }});
                                                    }}
                                                    label={Intl.Get(LocIds.PageAndPanels.CallToActionUrlLabel)}
                                                    required={false}
                                                    multiline={false}
                                                    url={true}
                                                    isPage={true}
                                                    validator={this.panelValidator}
                                                    placeholder={Intl.Get(LocIds.PageAndPanels.CallToActionUrlLabel)}
                                                />

                                                <ComboBox
                                                    defaultSelectedKey={this.state.panelData[`callToAction${item}Style`] || ''}
                                                    text={this.state.panelData[`callToAction${item}Style`] || ''}
                                                    label={Intl.Get(LocIds.PageAndPanels.CallToActionStyleLabel)}
                                                    ariaLabel={Intl.Get(LocIds.PageAndPanels.CallToActionStyleLabel)}
                                                    allowFreeform={false}
                                                    autoComplete={'on'}
                                                    options={this.callToActionOptions}
                                                    onChange={(ev: React.FormEvent<IComboBox>, choice: IComboBoxOption) => {
                                                        this.setState({ panelData: { ...this.state.panelData, [`callToAction${item}Style`]: choice.key.toString() }});
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}