import {
    initializeInAppFeedback,
    FeedbackInitData,
  } from '@ms/inapp-feedback-loader'

  import { TelemetryClient } from 'src/Common/Utilities/Telemetry'


export async function loadFeedbackAndBootstrap() {
    try {
      let feedbackInfo: FeedbackInitData
      const hostName: string = 'OcvFeedbackDemoHostingApp' // replace it with your onboarded app name with Centro
      const language: string = 'en' // we could pass this dynamically if it is needed
      let inAppFeedbackRef = document.getElementById('inAppFeedbackRef-id') // make sure this element is mounted on DOM.

      if (inAppFeedbackRef && hostName && language) {
        // setting the zindex here to ensure that the value is something large that should be higher than the chatbot

        feedbackInfo = {
          hostName: hostName,
          locale: language,
          inAppFeedbackRef: inAppFeedbackRef,
        }

        await initializeInAppFeedback(feedbackInfo)
      } else {
        TelemetryClient.trackException(new Error("FeedbackUtility.loadFeedbackAndBootstrap One of the following parameter is missing: inAppFeedbackRef, hostName, language"));
      }
    } catch (e) {
        TelemetryClient.trackException(e, "FeedbackUtility.loadFeedbackAndBootstrap")
    }
  }