import { Dimension } from './Dimension';
import { CustomerActionType } from './CustomerActionType';
import { AffectedAction } from './AffectedAction';
import { ControlDescription } from './ControlDescription';
import { ResponsibleParty } from './ResponsibleParty';
import { ControlType } from './ControlType';

export class CustomerActionModel {
    public id: string;
    public title: string;
    public maxComplianceScore: number;
    public actionType: CustomerActionType;
    public controlType: ControlType;
    public isContinuousUpdateEnabled: boolean;
    public isAutoCopyEnabled: boolean;
    public descriptions: ControlDescription[];
    public responsibleParties: ResponsibleParty[];    
    public affectedActions: AffectedAction[];
    public dimensions: Dimension[];
    public assessmentIds: string[];
    public isGlobalCustomerAction: boolean;
    public TenantItems: CustomerActionModel[];
    public GlobalItems: CustomerActionModel[];
    public isSecureScoreMapped: boolean;
    public isSecureScoreActionDelta: boolean;
}