import * as React from 'react';
import { AuthorizationInfo, ScopedAuthorizationRights, FlightMembership } from '../../Common/Utilities/AuthorizationInfo';
import { LicenseAgreementPage } from '../../App/Components/Licensing/LicenseAgreementPage';
import { store, StoreState } from '../../Store';
import { NoAccessErrorDialog } from './NoAccessErrorDialog';
import { connect } from 'react-redux';

export enum TargetRedirection {
    ErrorPage,
    HomePage
}

interface AuthorizationProps {
    requiredPermissions?: ScopedAuthorizationRights[];
    redirectTarget?: TargetRedirection;
    title?: string;
    message?: string;
    inPlaceMessage?: string;
    // tslint:disable-next-line:no-any
    children?: any;
    checkAny?: boolean;
    isMainPage?: boolean;
    requiredFlights?: string[];
    flightMembership?: FlightMembership; // Default is All
    callBack?: () => void;
    errorCallBack?: () => void;
    isDialogVisible?: boolean;
    scopedAuthorizationRights?: ScopedAuthorizationRights[],
    flights?: string[]
}

interface AuthorizationState {
    userHasRequiredPermissions: boolean;
    userHasRequiredFlights: boolean;
}

function mapStateToProps(state: StoreState, props: AuthorizationProps): AuthorizationProps {
    const userInfo = state.applicationContext ? state.applicationContext.userInfo : null;

    return {
        scopedAuthorizationRights: userInfo? userInfo.scopedAuthorizationRights : undefined,
        flights: userInfo? userInfo.flights : undefined  
    };
}

class AuthorizeC extends React.Component<AuthorizationProps, AuthorizationState>  {
    constructor(props: AuthorizationProps) {
        super(props);
        this.state = {
            userHasRequiredPermissions: this.userHasRequiredPermissions(),
            userHasRequiredFlights: this.userHasRequiredFlights(),
        };
    }

    userHasRequiredPermissions(): boolean {
        // If there are no required properties then, by definition, the user has all of the required properties.
        if ((this.props.requiredPermissions === undefined) || (this.props.requiredPermissions.length === 0)) {
            return true;
        }
        if (this.props.checkAny) {
            // If any of the required permission exists, return true
            for (let requiredPermission of this.props.requiredPermissions) {
                if (AuthorizationInfo.isAuthorizedForAny(requiredPermission.scope, [requiredPermission.rights])) {
                    return true;
                }
            }
            return false;
        }
        // return true only if user has all of the required permissions 
        for (let requiredPermission of this.props.requiredPermissions) {
            if (!AuthorizationInfo.isAuthorizedForAny(requiredPermission.scope, [requiredPermission.rights])) {
                return false;
            }
        }
        return true;
    }

    userHasRequiredFlights(): boolean {
        let userHasRequiredFlights = AuthorizationInfo.isIncludedInRequiredFlights(this.props.requiredFlights, this.props.flightMembership);
        return userHasRequiredFlights;
    }

    getErrorUrl(message: string | undefined): string {
        let url: string = '/Error';
        if (message) {
            url = url + '/' + encodeURIComponent(message);
        }
        return url;
    }

    render() {
        // set the state again, as constructor is not being called with right parameters
        this.state = {
            userHasRequiredPermissions: this.userHasRequiredPermissions(),
            userHasRequiredFlights: this.userHasRequiredFlights(),
        };

        const isSignedIn = store.getState().authentication.isSignedIn;
        
        if (this.state.userHasRequiredPermissions && this.state.userHasRequiredFlights) {
            return this.props.children;
        }
        if (this.props.inPlaceMessage !== undefined) {
            return <span>{this.props.inPlaceMessage}</span>;
        }
        if (isSignedIn) {
            if (this.props.isMainPage) {
                return <NoAccessErrorDialog errorCallBack={this.props.errorCallBack || function() {
                window.location.replace('/');
                }} {...this.props}/>
            }
        }
        return this.props.isMainPage ? <LicenseAgreementPage callBack={this.props.callBack} /> : null;
    }
}

export const Authorize = connect(mapStateToProps)(AuthorizeC)