import * as React from 'react';
import PageLayoutCurrent from '../Containers/PageLayoutCurrent';
import PageLayoutNext from '../Containers/PageLayoutNext';
import { PageLayoutProps } from './PageLayoutProps';

const PageLayout = (props: PageLayoutProps) => {
    if (props.isNextGenUIEnabled) {
        return ( 
        <div id="PageLayoutNext">
                <PageLayoutNext {...props} />
        </div>
        );
    } else {
        return (
        <div id="PageLayoutCurrent">
            <PageLayoutCurrent {...props} />
        </div>
        );
    } 
};

export default PageLayout;