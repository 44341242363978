export enum Theme {
    DarkImage,
    DarkAccent,
    DarkNeutral,
    LightNeutral,
    Dark,
    Light,
    Neutral10,
    Neutral20,
    Neutral30,
    Neutral45,
    Neutral55,
    Neutral65,
    Neutral75,
    Neutral80,
    Neutral90,
    Neutral100,
    Accent,
    None,
    Grey,
    Border
}

export enum MainPanelOptions {
    Left,
    Right
}

export enum TextPosition {
    LeftTop,
    LeftMiddle, 
    LeftBottom, 
    CenterTop, 
    CenterMiddle, 
    CenterBottom, 
    RightTop, 
    RightMiddle, 
    RightBottom
}

export enum CallToActionTheme {
    Simple,
    Primary, 
    Secondary
}