import { AxiosResponse } from 'axios';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { ErrorResponse } from '../../App/Services/Models';
import { EnvironmentSettings } from '../../App/Models/EnvironmentSettings';
// This interface is used for dependency injection to fetch the EnvironmentSettings.
export interface EnvironmentSettingsFactory {
    startRequestToGetEnvironmentSettings: (
        onSuccess?: (environmentSettings: EnvironmentSettings) => void,
        onFailed?: () => void
    ) => void;
}

export class DefaultEnvironmentSettingsFactory implements EnvironmentSettingsFactory {
    public startRequestToGetEnvironmentSettings(onSuccess?: (environmentSettings: EnvironmentSettings) => void, onFailed?: () => void): void {
        // tslint:disable-next-line:quotemark
        // tslint:disable-next-line:no-string-literal
        if (!!(window['__appSettings'])) {            
            // tslint:disable-next-line:no-string-literal
            let environmentSettings: EnvironmentSettings = window['__appSettings'];
            if (onSuccess) {
                onSuccess(environmentSettings);
            }
        } else {
            ServiceTrustClient.doRequest_New(
                'get', // method
                '/settings.json', // urlOrApi
                {}, // data
                () => null, // beforeRequest
                // onRequestSuccess
                (response: AxiosResponse) => {
                    let environmentSettings: EnvironmentSettings = response.data;
                    if (onSuccess) {
                        onSuccess(environmentSettings);
                    }
                },
                // onRequestFailed
                (response: ErrorResponse) => {
                    if (onFailed) {
                        onFailed();
                    }
                },
                'statusMessageIdThatDoesNotExist' // statusMessageId
            );     
        }
    }
}
