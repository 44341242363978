import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { AreaHeadingWithImagePanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { getSimpleThemeOptions, getThemeClassName } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { ComboBox, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import * as ReactMarkdown from 'react-markdown';

export class AreaHeadingWithImagePanel extends BasePanel<AreaHeadingWithImagePanelPropertiesData> {
    private static DEFAULT_LOGO = 'OfficeLogo';
    private static DEFAULT_LOGO_SIZE = 90;
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<AreaHeadingWithImagePanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));
    }

    getActualPanel() {
        return (
            <div 
                className={getThemeClassName(Theme[this.state.panelData.theme || 'None'])}
                data-panel-type="Area Heading With Image"
            >
                <div data-grid="col-12" className="m-banner">
                    <i 
                        className={'ms-Icon ms-Icon--' + (this.state.panelData.icon || AreaHeadingWithImagePanel.DEFAULT_LOGO)} 
                        aria-hidden="true" 
                        style={{fontSize: (this.state.panelData.iconSizeInPixels || AreaHeadingWithImagePanel.DEFAULT_LOGO_SIZE)}} 
                    />
                    <h1 className="c-heading-2" style={{paddingTop: '24px'}}>{getLocalizedString(this.state.panelData.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h1>
                    <ReactMarkdown className="c-paragraph-1" source={getLocalizedString(this.state.panelData.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)} skipHtml={true}/>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div className="row" style={{marginLeft: '0px'}}>
                        {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                        <div className="col">
                            {this.getActualPanel()}
                            {this.state.showEditor ?
                                <div className="editPanel">
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.icon || ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal) => {
                                                    this.state.panelData.icon = newVal;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.IconLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={(this.state.panelData.iconSizeInPixels || '').toString()}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length === 0 || newVal.length > 0 && newVal[newVal.length - 1] >= '0' && newVal[newVal.length - 1] <= '9') {
                                                        let num: number = parseInt(newVal, 0);
                                                        if (num > 90) {
                                                            num = 90;
                                                        }
                                                        this.state.panelData.iconSizeInPixels = num;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.IconSizeLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={getLocalizedString(this.state.panelData.subHeading)}
                                                onChange={(newVal) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'subHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                required={true}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '6px' }} />
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                </div>
                                :
                                null}
                        </div>
                    </div >
                    :
                    this.getActualPanel()
                }
            </div>
        );
    }
}