import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton, ComboBox, IComboBoxOption, IComboBox } from 'office-ui-fabric-react/lib/';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { ContentRichBlockPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { deleteOnePanelFromCollection, moveSelectedColumn, getContentRichBlockColumn, getThemeClassName, getSimpleThemeOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import * as ReactMarkdown from 'react-markdown';

export class ContentRichBlockPanel extends BasePanel<ContentRichBlockPanelPropertiesData> {
    private static MAX_COLUMN = 4;
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: 'Themes', itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<ContentRichBlockPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));
        if (!this.props.panel.panelInfo.columns) {
            this.props.panel.panelInfo.columns = [];
        }
    }

    getActualPanel() {
        let grid: string = this.state.panelData && this.state.panelData.columns && this.state.panelData.columns.length ? 'col-'.concat((12 / this.state.panelData.columns.length).toString()) : '';
        return (
            <div
                className={'m-rich-content-block ' + getThemeClassName(Theme[this.state.panelData.theme || 'None'])}
                data-grid="col-12 pad-6x stack-3"
                data-panel-type="Content Rich Block"
            >
                {this.state.panelData.columns ?
                    this.state.panelData.columns.map((data, index) =>
                        <div
                            key={data.toString().concat(index.toString())}
                            data-grid={grid}
                            onClick={() => { if (this.state.showEditor && this.panelValidator.validate()) { this.setState({ selectedIndex: index }); } }}
                            style={this.state.selectedIndex === index && this.state.showEditor ? { border: 'solid 1px #778899', backgroundColor: this.state.selectedIndex === index ? '#f4f4f4' : '' } : {}}
                        >
                            <h3 className="c-heading-3">{getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h3>
                            <ReactMarkdown className="c-paragraph" source={getLocalizedString(data.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)} skipHtml={true}/>
                        </div>
                    )
                    :
                    null
                }
            </div>
        );
    }

    onAddColumn() {
        if (!this.state.panelData.columns || (this.state.panelData.columns.length >= ContentRichBlockPanel.MAX_COLUMN || !this.panelValidator.validate())) {
            return;
        }
        this.state.panelData.columns.push(getContentRichBlockColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    onDeleteColumn() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            var panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    onMoveLeft() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    onMoveRight() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}} >
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: 0 }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                        }
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].subHeading) : ''}
                                                onChange={(newVal) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'subHeading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                required={true}
                                                validator={this.panelValidator}
                                                selectedIndex={this.state.selectedIndex}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddColumnLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length >= ContentRichBlockPanel.MAX_COLUMN)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeleteColumnLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}