import { Reducer } from 'redux';
import { store } from '../../Store';
import { LicenseAgreementDetails } from '../Services/Models/LicenseAgreementDetails';
import * as appContextReducer from './ApplicationContext';

export const DISPLAY_AGREEMENT = 'DISPLAY_AGREEMENT';
export const LICENSE_ACQUIRED = 'LICENSE_ACQUIRED';
export const LICENSE_AGREED_STATUS = 'LICENSE_AGREED_STATUS';
export const LICENSE_REQUESTING = 'LICENSE_REQUESTING';
export const LICENSE_ACQUIRE_ERROR = 'LICENSE_ACQUIRE_ERROR';
export const LICENSE_CANCELLED = 'LICENSE_CANCELLED';
// export const LICENSE_STATUS_REQUESTING = 'LICENSE_STATUS_REQUESTING';
export const LICENSE_RESET = 'LICENSE_RESET';
export const LICENSE_POSTING = 'LICENSE_POSTING';
export const SIGNIN_CANCELLED = 'SIGNIN_CANCELLED';
export const SIGNIN_PROGRESS = 'SIGNIN_PROGRESS';

export interface LicenseManagementState {
    cancelled: boolean;
    showLicense: boolean;
 //   licenseStatusRequesting: boolean;
    licenseRequesting: boolean;
    agreedStatus: boolean;
    licensePosting: boolean;
    signInCancelled: boolean;
    signInProgress: boolean;
    licenseDetails: LicenseAgreementDetails | undefined;
}

export interface SignInStarted {
    type: typeof SIGNIN_PROGRESS;
}

export interface AgreedToLicense {
    type: typeof LICENSE_AGREED_STATUS;
    agreed: boolean;
}

export interface RequestLicenseAgreement {
    type: typeof DISPLAY_AGREEMENT;
    showLicense: boolean;
}

export interface LicenseAcquired {
    type: typeof LICENSE_ACQUIRED;
    licenseDetails: LicenseAgreementDetails;
}

export interface RequestLicensing {
    type: typeof LICENSE_REQUESTING;
}

// export interface RequestLicensingStatus {
//     type: typeof LICENSE_STATUS_REQUESTING;
// }

export interface LicenseAcquireError {
    type: typeof LICENSE_ACQUIRE_ERROR;
}

export interface LicenseCancelled {
    type: typeof LICENSE_CANCELLED;
}

export interface LicenseReset {
    type: typeof LICENSE_RESET;
}

export interface LicensePosting {
    type: typeof SIGNIN_CANCELLED;
}

export interface SignInCancelled {
    type: typeof LICENSE_POSTING;
}

export function agreedStatus(data: boolean): void {
    var action: LicensingActions = {
        type: LICENSE_AGREED_STATUS,
        agreed: data
    };
    store.dispatch(action);
}

export function cancelled(): void {
    var action: LicensingActions = {
        type: LICENSE_CANCELLED
    };
    store.dispatch(action);
}

export function licenseReset(): void {
    var action: LicensingActions = {
        type: LICENSE_RESET
    };
    store.dispatch(action);
}

export function displayLicense(data: boolean): void {
    var action: LicensingActions = {
        type: DISPLAY_AGREEMENT,
        showLicense: data
    };
    store.dispatch(action);
}

export function updateLicenseAgreement(): void {
    var appContext = store.getState().applicationContext;
    appContext.userInfo.hasAgreedToCurrentLicenseAgreement = true;
    store.dispatch(appContextReducer.getApplicationContextChangedAction(appContext));
}

export function licenseAcquired(data: LicenseAgreementDetails): void {
    var action: LicensingActions = {
        type: LICENSE_ACQUIRED,
        licenseDetails: data
    };
    store.dispatch(action);
}

export function licenseAgreementPosting(): void {
    var action: LicensingActions = {
        type: LICENSE_POSTING,
    };
    store.dispatch(action);
}

export function licenseRequesting(): void {
    var action: LicensingActions = {
        type: LICENSE_REQUESTING,
    };
    store.dispatch(action);
}

// export function licenseStatusRequesting(): void {
//     var action: LicensingActions = {
//         type: LICENSE_STATUS_REQUESTING,
//     };
//     store.dispatch(action);
// }

export function licenseError(): void {
    var action: LicensingActions = {
        type: LICENSE_ACQUIRE_ERROR,
    };
    store.dispatch(action);
}

export function signInCancelled(): void {
    var action: LicensingActions = {
        type: SIGNIN_CANCELLED,
    };
    store.dispatch(action);
}

export function signInStarted(): void {
    var action: LicensingActions = {
        type: SIGNIN_PROGRESS
    };
    store.dispatch(action);
}

export type LicensingActions = RequestLicenseAgreement | LicenseAcquired | AgreedToLicense |
                               LicenseReset | RequestLicensing |
                               LicenseAcquireError | LicenseCancelled | LicensePosting |
                               SignInStarted | SignInCancelled;

const unloadedState: LicenseManagementState = {
    cancelled: false,
    showLicense: false,
    agreedStatus: false,
    // licenseStatusRequesting: false,
    licenseRequesting: false,
    licensePosting: false,
    signInCancelled: false,
    signInProgress: false,
    licenseDetails: undefined
};

export const reducer: Reducer<LicenseManagementState> = (state: LicenseManagementState, action: LicensingActions): LicenseManagementState => {
    switch (action.type) {
        case DISPLAY_AGREEMENT:
            return {
                ...state,
                showLicense: action.showLicense
            };
        case LICENSE_ACQUIRED:
            return {
                ...state,
                licenseDetails: action.licenseDetails,
                showLicense: true,
                licenseRequesting: false
            };            
        case LICENSE_AGREED_STATUS:
            return {
                ...state,
                agreedStatus: action.agreed,
                showLicense: !state.showLicense
            };
        case LICENSE_REQUESTING:
            return {
                ...state,                
                licenseRequesting: true,
            };
        case LICENSE_ACQUIRE_ERROR:
            return {
                ...state,
                showLicense: !state.showLicense,
                licenseRequesting: true,
            };
        case LICENSE_CANCELLED:
            return {
                ...state,
                showLicense: false,
                cancelled: true,
            };
        case LICENSE_RESET:
            return {
                ...state,
                showLicense: false,
                cancelled: false,
                licenseDetails: undefined,
                agreedStatus: false,
                signInCancelled: false
            };
        case LICENSE_POSTING:
            return {
                ...state,
                licensePosting: true,
            };
        case SIGNIN_CANCELLED:
            return {
                ...state,
                signInCancelled: true,
            };
        case SIGNIN_PROGRESS:
            return {
                ...state,
                signInProgress: true,
            };
        default:
            return state || unloadedState;
    }
};
