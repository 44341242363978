import { Reducer } from 'redux';
import { ResponsiblePartiesLookup } from '../../Models/ComplianceManagerV3/ResponsiblePartiesLookup';
export const RECIEVE_RESPONSIBLEPARTIES = 'RECIEVE_RESPONSIBLEPARTIES';

export interface ResponsiblePartiesState {
    responsiblePartiesLookup: ResponsiblePartiesLookup | null;
}

const unloadedState: ResponsiblePartiesState = {
    responsiblePartiesLookup: null
};

export interface RecieveResponsibleParties {
    type: typeof RECIEVE_RESPONSIBLEPARTIES;
    data: ResponsiblePartiesLookup;
}

export type KnownAction = RecieveResponsibleParties;

export function recieveResponsibleParties(responsiblePartiesData: ResponsiblePartiesLookup): RecieveResponsibleParties {
    return {
        type: RECIEVE_RESPONSIBLEPARTIES,
        data: responsiblePartiesData
    };
}

export const reducer: Reducer<ResponsiblePartiesState> = (state: ResponsiblePartiesState, action: KnownAction): ResponsiblePartiesState => {
    switch (action.type) {
        case RECIEVE_RESPONSIBLEPARTIES:
            return {
                responsiblePartiesLookup: action.data
            };
    }
    return state || unloadedState;
};