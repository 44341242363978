import * as Redux from 'redux';

// Define the model object.
export interface TenantSettings {
    region: string;
    industry: string;
}

// Define the strings that will be used when dispatching the actions that affect this model.
export const TENANTREGION_CHANGED_ACTION = 'TENANTREGION_CHANGED_ACTION';
export type TENANTREGION_CHANGED_ACTION = typeof TENANTREGION_CHANGED_ACTION;
export const TENANTINDUSTRY_CHANGED_ACTION = 'TENANTINDUSTRY_CHANGED_ACTION';
export type TENANTINDUSTRY_CHANGED_ACTION = typeof TENANTINDUSTRY_CHANGED_ACTION;

// For each action, define a strongly-typed interface which includes the action-specific properties.
export interface TenantRegionChangedAction {
    type: TENANTREGION_CHANGED_ACTION;
    selectedValueId: string;
}
export interface TenantIndustryChangedAction {
    type: TENANTINDUSTRY_CHANGED_ACTION;
    selectedValueId: string;
}

// Define the merged set of all the strongly-typed actions that can affect this model.
export type TenantSettingsAction = TenantRegionChangedAction | TenantIndustryChangedAction;

// Define helper functions that return strongly-typed routable action instances that inject the action-specific properties into the routable objects.
export function getTenantRegionChangedAction(selectedValueId: string): TenantRegionChangedAction {
    return {
        type: TENANTREGION_CHANGED_ACTION,
        selectedValueId: selectedValueId,
    };
}
export function getTenantIndustryChangedAction(selectedValueId: string): TenantIndustryChangedAction {
    return {
        type: TENANTINDUSTRY_CHANGED_ACTION,
        selectedValueId: selectedValueId,
    };
}

export const DefaultSettings: TenantSettings = {
    region: '',
    industry: '',
};

// Define the reducer that performs the model changes required for all of the actions that can affect this model.
export const reducer: Redux.Reducer<TenantSettings> =
    (oldState: TenantSettings, action: Redux.Action): TenantSettings => {
        let newState: TenantSettings;
        switch (action.type) {

            case TENANTREGION_CHANGED_ACTION:
                let tenantRegionChangedAction = action as TenantRegionChangedAction;
                newState = { ...oldState, region: tenantRegionChangedAction.selectedValueId };
                break;

            case TENANTINDUSTRY_CHANGED_ACTION:
                let tenantIndustryChangedAction = action as TenantIndustryChangedAction;
                newState = { ...oldState, industry: tenantIndustryChangedAction.selectedValueId };
                break;

            default:
                newState = (oldState === undefined) ? DefaultSettings : oldState;
        }
        return newState;
    };