import { DefaultButton, PrimaryButton,ComboBox, IComboBoxOption, Checkbox, IComboBox } from 'office-ui-fabric-react/lib/';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { DirectionalHint, TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import { getAbbrivatedHeading, getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { getAlignOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { CardPanelPropertiesData } from '../../Models/PageInfrastructure';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { deleteOnePanelFromCollection, moveSelectedColumn, getCardsColumn } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { isEmptyString, renderLink } from '../../../App/Utilities/RenderUtilities';
import { CSSProperties } from 'react';

import './CardPanel.css';

export class CardPanel extends BasePanel<CardPanelPropertiesData> {
    
    private alignOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.AlignLabel), itemType: SelectableOptionMenuItemType.Header }];
    private tileSizeOptions: IComboBoxOption[] = [
        { key: 'single', text: Intl.Get(LocIds.PageAndPanels.SmallTileOption), itemType: SelectableOptionMenuItemType.Normal },
        { key: 'extrasmall', text: Intl.Get(LocIds.PageAndPanels.ExtraSmallTileOption), itemType: SelectableOptionMenuItemType.Normal },
        { key: 'textOnlyTall', text: Intl.Get(LocIds.PageAndPanels.TextOnlyTallTileOption), itemType: SelectableOptionMenuItemType.Normal }];

    constructor(props: BasicPanelProperties<CardPanelPropertiesData>) {
        super(props);
        this.alignOptions = this.alignOptions.concat(getAlignOptions());
    }

    // tslint:disable-next-line:no-any
    private routerLink = (props: any) => {
        const target = props.href.match(/^(https?:)?\/\//) ? '_blank' : '_self';
        return <Link target={target} className="c-hyperlink" to={props.href}>{props.children}</Link>;
    }

    private getTextAlignClassName(textAlign: string) {
        let textClassName = '';
        switch ( textAlign) {
            case 'Left':
                textClassName = 'c-card-align-left';
                break;
            case 'Center':
                textClassName = 'c-card-align-center';
                break;
            case 'Right':
                textClassName = 'c-card-align-right';
                break;
            case 'None': //roll this through to default to blank.
            default:
                textClassName = '';
        }

        return textClassName;
    }

    private getActualPanel = (): JSX.Element | undefined => {
        const { panelHeading, panelSubheading, panelCallToActionLabel, panelCallToActionUrl } = this.state.panelData;
        let styleProps: CSSProperties = this.state.panelData.additionalTopMargin ? {marginTop: '24px'} : {};
        const cardCountInOneRow = this.state.panelData.tileSize === this.tileSizeOptions[1].key ? 6 : 5;
        let cardInRows = [];

        if (this.state.panelData.columns) {
            let cardInOneRow = new Array(0);
            this.state.panelData.columns.forEach((element, index) => {
                if (index % cardCountInOneRow == 0) {
                    cardInOneRow = new Array(0);
                }
                cardInOneRow.push(element);
                if (cardInOneRow.length == cardCountInOneRow) {
                    // add row to the array cardInRows if row is full. 
                    cardInRows.push(cardInOneRow);
                }
            });
            if (cardInOneRow.length > 0 && cardInOneRow.length < cardCountInOneRow) {
                // add the last row to the cardInRows array.
                // if the row has elements but is not full, it was not added to the array above.
                cardInRows.push(cardInOneRow);
            }
        }
        
        return (
            <div
                className="card-panel"
                data-grid="col-12"
                data-panel-type="Card Collection"
                style={styleProps}
            >
                {panelHeading && (
                    <div
                        className="m-banner card-panel__header"
                        data-grid="col-12"
                    >
                        <h2
                            aria-hidden="true"
                            className={`clearfix ${this.getTextAlignClassName(this.state.panelData.textAlignment ? this.state.panelData.textAlignment : 'None')}`}>
                                {getLocalizedString(panelHeading)}
                        </h2>
                        <span className="sr-only">{getAbbrivatedHeading(panelHeading)}</span>
                        {panelSubheading &&
                            <ReactMarkdown
                                renderers={{link: this.routerLink}}
                                className={`c-paragraph ${this.getTextAlignClassName(this.state.panelData.textAlignment ? this.state.panelData.textAlignment : 'None')}`}
                                source={getLocalizedString(panelSubheading)}
                            />
                        }
                    </div>
                )}

                {this.state.panelData.columns &&
                    <div className='card-panel'>
                    {cardInRows.map((column, index) => 
                        <div className={`row row-cols-1 row-cols-sm-2 row-cols-lg-${ cardCountInOneRow}`} key={"row" + index}>
                            {column.map((data, index2) =>
                                <div className="col" key={"row" + index2}>
                                    <div
                                        className={`h-100 c-card f-${ this.state.panelData.tileSize || 'single'}${this.state.panelData.panelShowBorder ? ' c-card-border' : ''}`}
                                        key={index + '_' + index2}
                                        onClick={() => { if (this.state.showEditor && this.panelValidator.validate()) { this.setState({ selectedIndex: index * cardCountInOneRow + index2 }); } }}
                                        style={this.state.selectedIndex === (index * cardCountInOneRow + index2) && this.state.showEditor ? { border: 'solid 1px #778899' } : {}}
                                    >
                                            <section className="">
                                                {data.imageId && !isEmptyString(data.imageId) && (
                                                    <picture className="c-image">
                                                        <img
                                                            className="lazyloaded"
                                                            src={imageHelper.getImageUrl(data.imageId)}
                                                            data-src={imageHelper.getImageUrl(data.imageId)}
                                                            alt={getLocalizedString(data.heading)}
                                                        />
                                                    </picture>
                                                )}

                                            <TooltipHost
                                                content={getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                                calloutProps={{ isBeakVisible: false, gapSpace: 0 }}
                                                tooltipProps={{ directionalHint: DirectionalHint.topCenter }}
                                            >
                                                <h3 className="c-heading-5 f-lean">
                                                    {/* if there's a URL then render as a link, otherwise just show the label in the <h3> */}
                                                    {!isEmptyString(data.headingUrl)
                                                        ?
                                                        renderLink(data.headingUrl, getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder),
                                                            'c-hyperlink',
                                                            ((index2 === 0 ? getAbbrivatedHeading(panelHeading) : '') + getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)))
                                                        :
                                                        (getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder))
                                                    }
                                                </h3>
                                            </TooltipHost>

                                            {data.content !== undefined && !isEmptyString(getLocalizedString(data.content)) &&
                                                <ReactMarkdown className="c-paragraph-1" source={getLocalizedString(data.content)} />
                                            }

                                            {!isEmptyString(data.callToActionUrl) &&
                                                renderLink(data.callToActionUrl, getLocalizedString(data.callToActionLabel), 'c-call-to-action c-glyph f-lightweight', getLocalizedString(panelHeading) + getLocalizedString(data.callToActionLabel))
                                            }
                                            </section>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }

                {((panelCallToActionLabel !== undefined) && !isEmptyString(getLocalizedString(panelCallToActionLabel))) && (
                    <div
                        className={`m-banner card-panel__footer ${this.getTextAlignClassName(this.state.panelData.textAlignment ? this.state.panelData.textAlignment : 'None')}`}
                        data-grid="col-12"
                    >
                        {renderLink(panelCallToActionUrl, getLocalizedString(panelCallToActionLabel), '', getLocalizedString(panelHeading) + getLocalizedString(panelCallToActionLabel))}
                    </div>
                )}
            </div>
        );
    }

    private onAddColumn = () => {
        if (this.state.panelData.columns) {
            this.state.panelData.columns.push(getCardsColumn());
            this.setState({
                panelData: this.state.panelData,
                selectedIndex: this.state.panelData.columns.length - 1
            });
        }
    }

    private onDeleteColumn = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            const panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    private onMoveLeft = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    private onMoveRight = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    public render() {
        const { columns } = this.state.panelData;
        const numberOfColumns = columns ? columns.length : 0;

        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}}>
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: this.state.selectedIndex ? this.state.selectedIndex : 0 }))}
                        
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor && this.state.panelData.columns &&
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12" >
                                        <label className="ms-Label x-hidden-focus">Image</label>
                                        <p>
                                            <small>
                                                <strong>Please use an image that is 400 x 400px</strong><br />
                                                This image will be shown centered at the top of the column.
                                            </small>
                                        </p>

                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length && this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal.substr(0, Constants.PANEL_HEADING_MAX));
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns[this.state.selectedIndex].headingUrl}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].headingUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        
                                        <div data-grid="col-12">
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={this.state.panelData.columns ? getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].content) : ''}
                                                onChange={(newVal) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'content', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                required={false}
                                                validator={this.panelValidator}
                                                selectedIndex={this.state.selectedIndex}
                                            />
                                        </div>
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].callToActionLabel)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'callToActionLabel', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns[this.state.selectedIndex].callToActionUrl ? this.state.panelData.columns[this.state.selectedIndex].callToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].callToActionUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />

                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <Checkbox
                                                id="chkCardBorder"
                                                label={Intl.Get(LocIds.PageAndPanels.ShowBorderLabel)}
                                                aria-label={Intl.Get(LocIds.PageAndPanels.ShowBorderLabel)}
                                                checked={this.state.panelData.panelShowBorder}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, panelShowBorder: isChecked }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                id="ddlTextAlign"
                                                defaultSelectedKey={this.state.panelData.textAlignment ? this.state.panelData.textAlignment  : 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.TextAlignLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.TextAlignLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.alignOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.textAlignment = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>                                            
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                        <Checkbox
                                                id="chkAdditionalTopMargin"
                                                label={Intl.Get(LocIds.PageAndPanels.AdditionalTopMarginLabel)}
                                                aria-label={Intl.Get(LocIds.PageAndPanels.AdditionalTopMarginLabel)}
                                                checked={this.state.panelData.additionalTopMargin}
                                                onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                    this.setState({ panelData: { ...this.state.panelData, additionalTopMargin: isChecked }});
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                id="ddlTileSize"
                                                defaultSelectedKey={this.state.panelData.tileSize || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.TileSizeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.TileSizeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.tileSizeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.tileSize = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>                                            
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelHeading ? getLocalizedString(this.state.panelData.panelHeading) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelHeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />

                                            <MarkDownEditor
                                                value={this.state.panelData.panelSubheading ? getLocalizedString(this.state.panelData.panelSubheading) : ''}
                                                onChange={(newVal) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelSubheading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelSubHeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                            />
                                        </div>
                                        
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelCallToActionLabel ? getLocalizedString(this.state.panelData.panelCallToActionLabel) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelCallToActionLabel', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelCallToActionUrl ? this.state.panelData.panelCallToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.state.panelData.panelCallToActionUrl = newVal;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddColumnLabel)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeleteColumnLabel)}
                                        disabled={numberOfColumns <= 1}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}