import { IComboBox, SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { ComboBox, DefaultButton, IComboBoxOption, PrimaryButton } from 'office-ui-fabric-react/lib/';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import * as React from 'react';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { MosaicPanelPropertiesData } from '../../Models/PageInfrastructure';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { deleteOnePanelFromCollection,
         getBackgroundColorOptions,
         getBackgroundMaskOptions,
         getMosaicColumn,
         getMosaicPatternOptions,
         getSimpleThemeOptions,
         moveSelectedColumn
       } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { renderLink } from '../../Utilities/RenderUtilities';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import './MosaicPanel.css';
import * as MosaicPatterns from './MosaicPatterns';

export class MosaicPanel extends BasePanel<MosaicPanelPropertiesData> {
    private static MAX_COLUMN = 9; // pattern 3 has 9 items
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private patternOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.MosaicPatternLabel), itemType: SelectableOptionMenuItemType.Header }];
    private backgroundMaskOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.BackgroundMaskLabel), itemType: SelectableOptionMenuItemType.Header }];
    private backgroundColorOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.BackgroundMaskLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<MosaicPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));
        this.patternOptions = this.patternOptions.concat(getMosaicPatternOptions());
        this.backgroundMaskOptions = this.backgroundMaskOptions.concat(getBackgroundMaskOptions());
        this.backgroundColorOptions = this.backgroundColorOptions.concat(getBackgroundColorOptions());

        if (!this.props.panel.panelInfo.columns) {
            this.props.panel.panelInfo.columns = [];
        }
    }

    private getActualPanel = () => {
        const { panelData, selectedIndex } = this.state;
        let renderer: (panelData: MosaicPanelPropertiesData, onEditorClick: (index: number) => void, selectedIndex: number, showEditor: boolean) => JSX.Element | undefined;

        switch (panelData.pattern) {
            case 'pattern-1':
                renderer = MosaicPatterns.one;
                break;
            case 'pattern-2':
                renderer = MosaicPatterns.two;
                break;
            default:
                renderer = () => <div />;
                break;
        }

        const mosaic = renderer.apply(this, [
            panelData,
            (index: number) => { if (this.state.showEditor && this.panelValidator.validate()) { this.setState({ selectedIndex: index }); } },
            selectedIndex,
            this.state.showEditor
        ]);

        return ([
            (panelData.panelHeading && (getLocalizedString(panelData.panelHeading) || '').length > 0)  && (
                <div key="heading-mosaic" className="m-area-heading">
                    <h2 className="c-heading-2 f-lean">
                    {panelData.panelHeadingUrl ? (
                        renderLink(
                            panelData.panelHeadingUrl,
                            getLocalizedString(panelData.panelHeading),
                            'c-hyperlink'
                        )
                    ) : (
                        getLocalizedString(panelData.panelHeading)
                    )}
                    </h2>
                </div>
            ),
            (
                <div key="main-mosaic" className="m-mosaic">
                    {mosaic}
                </div>
            ),
            panelData.panelCallToAction && (
                <div key="footer-mosaic" className="m-banner">
                    {panelData.panelCallToActionUrl ? (
                        renderLink(
                            panelData.panelCallToActionUrl,
                            getLocalizedString(panelData.panelCallToAction),
                            'c-call-to-action c-glyph f-lightweight'
                        )
                    ) : (
                        getLocalizedString(panelData.panelCallToAction)
                    )}
                </div>
            )
        ]);
    }

    private onAddColumn = () => {
        if (!this.state.panelData.columns || (this.state.panelData.columns.length >= MosaicPanel.MAX_COLUMN || !this.panelValidator.validate())) {
            return;
        }
        this.state.panelData.columns.push(getMosaicColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    private onDeleteColumn = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            const panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    private onMoveLeft = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    private onMoveRight = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            const panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    public render() {
        return (
            <div className="stp_mosaic" data-panel-type="mosaic">
                {this.props.isInEditMode ?
                    <div>
                       <div className="row" style={{marginLeft: '0px'}}>
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor && this.state.panelData.columns ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.columns[this.state.selectedIndex].theme || 'Light'}
                                                text={this.state.panelData.columns[this.state.selectedIndex].theme || 'Light'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].theme = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.columns[this.state.selectedIndex].backgroundMask || 'None'}
                                                text={this.state.panelData.columns[this.state.selectedIndex].backgroundMask || 'None'}
                                                label={'Background Mask:'}
                                                ariaLabel={'Background Mask:'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.backgroundMaskOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].backgroundMask = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.columns[this.state.selectedIndex].backgroundColor || 'None'}
                                                text={this.state.panelData.columns[this.state.selectedIndex].backgroundColor || 'None'}
                                                label={'Background Color:'}
                                                ariaLabel={'Background Color:'}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.backgroundColorOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].backgroundColor = item.key.toString();
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextField
                                                value={this.state.panelData.columns ? (this.state.panelData.columns[this.state.selectedIndex].imageTitle || '') : ''}
                                                disabled={true}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length && this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].imageId = images[0].url;
                                                        this.state.panelData.columns[this.state.selectedIndex].imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? (getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].heading) || '') : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                        }
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'heading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.columns ? (this.state.panelData.columns[this.state.selectedIndex].headingUrl || '') : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        this.state.panelData.columns[this.state.selectedIndex].headingUrl = newVal;
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                multiline={true}
                                                value={this.state.panelData.columns ? (getLocalizedString(this.state.panelData.columns[this.state.selectedIndex].subHeading) || '') : ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (this.state.panelData.columns) {
                                                        if (newVal.length > Constants.PANEL_DESCRIPTION_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX);
                                                        }
                                                        setDefaultLocalizedString(this.state.panelData.columns[this.state.selectedIndex], 'subHeading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.pattern || 'pattern-1'}
                                                text={this.state.panelData.pattern || 'pattern-1'}
                                                label={Intl.Get(LocIds.PageAndPanels.MosaicPatternLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.MosaicPatternLabel)}
                                                allowFreeform={false}
                                                autoComplete={'on'}
                                                options={this.patternOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.pattern = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.panelHeading) || ''} 
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'panelHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelHeadingLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelHeadingUrl || ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.setState({ panelData: { ...this.state.panelData, panelHeadingUrl: newVal }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingUrlLabel)}
                                                isPage={true}
                                                url={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.panelCallToAction) || ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelCallToAction', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelCallToActionUrl || ''}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.setState({ panelData: { ...this.state.panelData, panelCallToActionUrl: newVal }});
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.CallToActionUrlLabel)}
                                                isPage={true}
                                                url={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddPanelLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length >= MosaicPanel.MAX_COLUMN)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeletePanelLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronLeft' }} text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)} onClick={() => this.onMoveLeft()} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronRight' }} text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)} onClick={() => this.onMoveRight()} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}