import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton, IconButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString, makeLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { StructuredListPanelPropertiesData, StructuredItemPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { getStructuredListThemeOptions, getThemeClassName } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { ComboBox, IComboBoxOption, Checkbox, IComboBox } from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import Constants from '../../Utilities/Constants';
import './StructuredListPanel.css'; 

export class StructuredListPanel extends BasePanel<StructuredListPanelPropertiesData> {
    private itemBackup: StructuredItemPropertiesData;
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<StructuredListPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getStructuredListThemeOptions(true));
        this.itemBackup = {};
    }

    getActualPanel() {
        return (
            <div 
                data-grid="col-12"
                className={`m-structured-list ${getThemeClassName(Theme[this.state.panelData.theme || 'None'])}`}
                data-panel-type="Structured List"
            >
                <div data-grid="col-12" className="c-structured-list">
                    <ul className="f-column">
                        {this.state.panelData.items ? this.state.panelData.items.map((item, index) =>
                            <li
                                className={`f-row ${item.useImage !== undefined && item.useImage ? "c-structured-list__image" : "c-structured-list__term"}`}
                                key={item.toString().concat(index.toString())}
                            >
                                <div data-grid='col-2 stack-2'>
                                {item.useImage === null || (item.useImage !== undefined && item.useImage) ? 
                                    <img 
                                        className="c-image" 
                                        src={imageHelper.getImageUrl(item.imageId)}
                                        data-src={imageHelper.getImageUrl(item.imageId)}
                                        alt={item.imageTitle}
                                    />
                                :
                                    <h3 className="c-heading-1 f-lean">{getLocalizedString(item.columnDescription)}</h3>
                                }
                                </div>
                                <div data-grid="col-10">
                                    <p className="c-heading-5">{getLocalizedString(item.description) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}</p>
                                </div>
                            </li>)
                            : null
                        }
                    </ul>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div className="row" style={{marginLeft: '0px'}}>
                    {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                    <div className="col">
                        {this.getActualPanel()}
                            {this.state.showEditor ?
                                <div data-grid="col-12" className="editPanel">
                                    <div data-grid="col-12">
                                        <label style={{ marginRight: 10 }}>Items:</label>
                                        <IconButton
                                            className="editPanel__add-item"
                                            onClick={() => {
                                                if (this.state.panelData.items && this.panelValidator.validate()) {
                                                    var newItem: StructuredItemPropertiesData = { description: makeLocalizedString(''), imageId: '/Images/Panel-Infrastructure/Missing-Image.png', useImage: true };
                                                    this.state.panelData.items.push(newItem);
                                                    this.setState({ selectedLinkIndex: this.state.panelData.items.length - 1 });
                                                    this.itemBackup = getDeepCopy(newItem);
                                                }
                                            }}
                                            iconProps={{ iconName: 'Add' }}
                                        />
                                    </div>
                                    {this.state.panelData.items ?
                                            this.state.panelData.items.map((item, itemIndex) =>
                                                <div className="ms-Grid-row top-border edit-row edit-row-structure-item" key={item.toString().concat(itemIndex.toString())}>
                                                    {itemIndex !== this.state.selectedLinkIndex ?
                                                        <div className="ms-Grid-row">
                                                            <div data-grid="col-3" style={{ paddingRight: 10 }}>
                                                            {item.useImage === null || (item.useImage !== undefined && item.useImage) ? 
                                                                <img 
                                                                    className="c-image" 
                                                                    src={imageHelper.getImageUrl(item.imageId)}
                                                                    data-src={imageHelper.getImageUrl(item.imageId)}
                                                                    alt={item.imageTitle}
                                                                />
                                                            :
                                                                <h3 className="c-heading-2 f-lean">{getLocalizedString(item.columnDescription)}</h3>
                                                            }
                                                            </div>
                                                            <div data-grid="col-5" style={{ paddingRight: 10 }}>
                                                                <p className="c-paragraph-2">{getLocalizedString(item.description) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}</p>
                                                            </div>
                                                            <div data-grid="col-3" className="edit-buttons">
                                                                <PrimaryButton
                                                                    className="editPanel__edit-item"
                                                                    onClick={() => {
                                                                        if (!this.panelValidator.validate()) {
                                                                            return;
                                                                        }
                                                                        this.setState({ selectedLinkIndex: itemIndex });
                                                                        this.itemBackup = getDeepCopy(item);
                                                                    }}
                                                                    iconProps={{ iconName: 'Edit'}}
                                                                />
                                                                <DefaultButton
                                                                    disabled={itemIndex === 0}
                                                                    className="editPanel__move-item-up"
                                                                    onClick={() => {
                                                                        if (itemIndex === 0) { return; }
                                                                        if (this.state.panelData.items && this.panelValidator.validate()) {
                                                                            const temp = { ...this.state.panelData.items[itemIndex] };
                                                                            this.state.panelData.items[itemIndex] = { ...this.state.panelData.items[itemIndex - 1] };
                                                                            this.state.panelData.items[itemIndex - 1] = temp;
                                                                            this.setState({ panelData: this.state.panelData });
                                                                        }
                                                                    }}
                                                                    iconProps={{ iconName: 'ChevronUp' }}
                                                                />
                                                                <DefaultButton
                                                                    disabled={this.state.panelData.items && itemIndex >= this.state.panelData.items.length - 1}
                                                                    className="editPanel__move-item-down"
                                                                    onClick={() => {
                                                                        if (this.state.panelData.items && itemIndex >= this.state.panelData.items.length - 1) { return; }
                                                                        if (this.state.panelData.items && this.panelValidator.validate()) {
                                                                            const temp = { ...this.state.panelData.items[itemIndex] };
                                                                            this.state.panelData.items[itemIndex] = { ...this.state.panelData.items[itemIndex + 1] };
                                                                            this.state.panelData.items[itemIndex + 1] = temp;
                                                                            this.setState({ panelData: this.state.panelData });
                                                                        }
                                                                    }}
                                                                    iconProps={{ iconName: 'ChevronDown'}}
                                                                />
                                                                <DefaultButton
                                                                    className="editPanel__delete-item"
                                                                    onClick={() => {
                                                                        if (this.state.panelData.items) {
                                                                            this.state.panelData.items.splice(itemIndex, 1);
                                                                            this.setState({ selectedLinkIndex: -1 });
                                                                        }
                                                                    }}
                                                                    iconProps={{ iconName: 'RecycleBin' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div className="ms-Grid-row useImage-checkbox-div">
                                                                <Checkbox
                                                                    checked={item.useImage || false}
                                                                    onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                                        item.useImage = isChecked;
                                                                        this.setState({ panelData: this.state.panelData });
                                                                    }}
                                                                    label={Intl.Get(LocIds.PageAndPanels.UseImageLabel)}
                                                                    ariaLabel={Intl.Get(LocIds.PageAndPanels.UseImageLabel)}
                                                                />
                                                            </div>
                                                            {item.useImage === null || (item.useImage !== undefined && item.useImage) ? 
                                                                <div className="ms-Grid-row">
                                                                    <div data-grid="col-5">
                                                                        <TextFieldWrapper
                                                                            value={item.imageTitle || ''}
                                                                            label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                                            disabled={true}
                                                                            required={true}
                                                                            validator={this.panelValidator}
                                                                        />
                                                                    </div>
                                                                    <div data-grid="col-3" style={{ padding: '29px 0 0 10px' }}>
                                                                        <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                                                        <ImagePickerDialog
                                                                            hidden={!this.state.showImagePicker}
                                                                            onImageSelection={images => {
                                                                                if (images && images.length) {
                                                                                    item.imageId = images[0].url;
                                                                                    item.imageTitle = images[0].title;
                                                                                    this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                                                } else {
                                                                                    this.setState({ showImagePicker: false });
                                                                                }
                                                                            }}
                                                                            onDismiss={() => this.setState({ showImagePicker: false })}
                                                                        />
                                                                    </div>
                                                                    <div data-grid="col-3" style={{ paddingTop: 30 }}>
                                                                        <PrimaryButton
                                                                            onClick={() => {
                                                                                if (!this.panelValidator.validate()) {
                                                                                    return;
                                                                                }
                                                                                this.setState({ panelData: this.state.panelData, selectedLinkIndex: -1 });
                                                                                this.itemBackup = {};
                                                                            }}
                                                                            iconProps={{ iconName: 'Accept' }}

                                                                        />
                                                                        <DefaultButton
                                                                            onClick={() => {
                                                                                item.imageId = this.itemBackup.imageId;
                                                                                item.description = this.itemBackup.description;

                                                                                this.setState({ panelData: this.state.panelData, selectedLinkIndex: -1 });
                                                                                this.itemBackup = {};
                                                                            }}
                                                                            iconProps={{ iconName: 'Cancel'}}

                                                                        />
                                                                    </div>
                                                                </div>
                                                            : 
                                                                <div className="ms-Grid-row">
                                                                    <div data-grid="col-12">
                                                                        <TextFieldWrapper
                                                                            value={getLocalizedString(item.columnDescription)}
                                                                            onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                                                if (newVal.length > Constants.PANEL_TITLE_MAX) {
                                                                                    newVal = newVal.substr(0, Constants.PANEL_TITLE_MAX);
                                                                                }
                                                                                setDefaultLocalizedString(item, 'columnDescription', newVal);
                                                                                this.setState({ panelData: this.state.panelData });
                                                                            }}
                                                                            multiline={false}
                                                                            label={Intl.Get(LocIds.PageAndPanels.TermLabel)}
                                                                            required={false}
                                                                            validator={this.panelValidator}
                                                                            placeholder={Intl.Get(LocIds.PageAndPanels.TermLabel)}
                                                                        />  
                                                                    </div>
                                                                </div>
                                                            }
                                                            
                                                            <div className="ms-Grid-row">
                                                                <div data-grid="col-5">
                                                                    <TextFieldWrapper
                                                                        value={getLocalizedString(item.description)}
                                                                        onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                                            if (newVal.length > Constants.PANEL_DESCRIPTION_MAX) {
                                                                                newVal = newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX);
                                                                            }
                                                                            setDefaultLocalizedString(item, 'description', newVal);
                                                                            this.setState({ panelData: this.state.panelData });
                                                                        }}
                                                                        multiline={true}
                                                                        label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                                        required={true}
                                                                        validator={this.panelValidator}
                                                                        placeholder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>)
                                            : null
                                        }
                                        <div style={{ marginBottom: '6px' }} data-grid="col-12">
                                            <div data-grid="col-5">
                                                <ComboBox
                                                    defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                    text={this.state.panelData.theme || 'None'}
                                                    label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                    ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                    allowFreeform={true}
                                                    autoComplete={'on'}
                                                    options={this.themeOptions}
                                                    onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                        this.state.panelData.theme = item.key.toString();
                                                        this.setState({ panelData: this.state.panelData });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div data-grid="col-12">
                                            <div data-grid="col-5">
                                                <PrimaryButton
                                                    onClick={() => {
                                                        this.onSave();
                                                    }}
                                                    text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                                />
                                                <DefaultButton
                                                    onClick={() => {
                                                        this.onDiscardChange();
                                                    }}
                                                    text={Intl.Get(LocIds.PageAndPanels.UndoChangesLabel)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    : this.getActualPanel()
                }
            </div>
                        );
    }
}