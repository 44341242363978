/*
Wraps common browser global.window and global.document object operations to avoid non-browser environment failures
*/

export const getCookieValue = (cname: string) => {
    try {
        const name = cname + '=';
        if (document && document.cookie) {
            const cookies = decodeURIComponent(document.cookie).split(';');
            for (let c of cookies) {
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }
        }
    } catch {
        // this is a node testing environment. do nothing
    }              
    return '';
};

export const setCookieValue = (cname: string, cvalue: string, expiresOnTime: Date) => {
    try {
        if (document && document !== null) {
            document.cookie = cname + '=' + cvalue + '; expires=' + expiresOnTime.toUTCString() + '; path=/;';
        }        
    } catch {
        // this is a node testing environment. do nothing
    }
};

export const getWindowLocationHref = () => getWindowLocationField('href') || '';
export const getWindowLocationPathname = () => getWindowLocationField('pathname') || '';
export const getWindowLocationOrigin = () => getWindowLocationField('origin') || '';
export const getWindowLocationHostname = () => getWindowLocationField('hostname') || '';

export const getWindowLocationField = (fieldName: string) => {
    try {
        if (window && window.location) {
            return window.location[fieldName] as string;
        }
    } catch {
        // this is a node testing environment. do nothing
        return undefined;
    }        
    return '';
};