import { Reducer } from 'redux';

// STATE - This defines the type of data maintained in the Redux store.

export type Status = 'Init' | 'Loading' | 'Loaded' | 'Error';

export interface LocalizedStringsState {
    // Values that come from the web API
    cultureName: string;
    localizedStrings: {};
    
    // Other values.
    status: Status;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface LocalizedStringsLoading {
    type: 'LOCALIZED_STRINGS_LOADING';
}

interface LocalizedStringsChanged {
    type: 'LOCALIZED_STRINGS_CHANGED';
    cultureName: string;
    localizedStrings: {};
}

interface LocalizedStringsError {
    type: 'LOCALIZED_STRINGS_ERROR';
}

export type LocalizedStringsActions = LocalizedStringsLoading | LocalizedStringsChanged | LocalizedStringsError;

export function getLocalizedStringsLoadingAction(): LocalizedStringsActions {
    return {
        type: 'LOCALIZED_STRINGS_LOADING'
    };
}

export function getLocalizedStringsChangedAction(cultureName: string, localizedStrings: {}): LocalizedStringsActions {
    return {
        type: 'LOCALIZED_STRINGS_CHANGED',
        cultureName: cultureName,
        localizedStrings: localizedStrings
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<LocalizedStringsState> = (state: LocalizedStringsState, action: LocalizedStringsActions) => {
    let newState: LocalizedStringsState;
    switch (action.type) {
        case 'LOCALIZED_STRINGS_LOADING':
            newState = {
                ...state,
                status: 'Loading',
                cultureName: '',
                localizedStrings: {},
             };
            break;
        case 'LOCALIZED_STRINGS_CHANGED':
            newState = {
                ...state,
                status: 'Loaded',
                cultureName: action.cultureName,
                localizedStrings: action.localizedStrings,
             };
            break;
        case 'LOCALIZED_STRINGS_ERROR':
        default:
            newState = (state === undefined) 
            ? {
                status: 'Init',
                cultureName: '',
                localizedStrings: {},
            }
            : state;
    }

    return newState;
};
