import * as React from 'react';
import { SelectedVocabulary } from '../../../App/Models/Vocabulary';
import { Intl } from '../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';

export interface FilterChoiceSummaryProps {
    className?: string;
    choice?: string;
    text: string;
    filter?: SelectedVocabulary;
    onRemove: (choice?: string, text?: string, filter?: SelectedVocabulary) => void;
}

export const FilterChoiceSummary: React.SFC<FilterChoiceSummaryProps> = ({className, choice, text, filter, onRemove}) => {
    function _handleClick(ev: React.MouseEvent<HTMLElement>) {
        ev.preventDefault();

        onRemove(choice, text, filter);
    }
    var filterChoiceLabel = Intl.Get(LocIds.PageAndPanels.RemoveFilterChoice, { text });

    return (
        <li className="c-choice-summary">
            <a className={`c-action-trigger c-glyph glyph-cancel ${className}`} href="#" title = {filterChoiceLabel} aria-label={filterChoiceLabel} role="button" onClick={_handleClick}>
                <span className="x-screen-reader">{Intl.Get(LocIds.PageAndPanels.RemoveFilterChoice, { text })}</span>
            </a>
            <span>{text}</span>
        </li>
    );
};

export default FilterChoiceSummary;
