import * as Redux from 'redux';
import { ErrorResponse } from '../Services/Models';

export interface NotificationSummary {
    loaded: boolean;
    pending: boolean;
    totalDocumentsUpdated?: number;
    totalDocumentsSubscribed?: number;
    error?: string;
}

export const initialState: NotificationSummary = {
    loaded: false,
    pending: false
};

// Define the action type strings
export const NOTIFICATION_SUMMARY_REQUEST = 'NOTIFICATION_SUMMARY_REQUEST';
export type NOTIFICATION_SUMMARY_REQUEST = typeof NOTIFICATION_SUMMARY_REQUEST;

export const NOTIFICATION_SUMMARY_RECEIVED = 'NOTIFICATION_SUMMARY_RECEIVED';
export type NOTIFICATION_SUMMARY_RECEIVED = typeof NOTIFICATION_SUMMARY_RECEIVED;

export const NOTIFICATION_SUMMARY_ERROR = 'NOTIFICATION_SUMMARY_ERROR';
export type NOTIFICATION_SUMMARY_ERROR = typeof NOTIFICATION_SUMMARY_ERROR;

// For each action, define a strongly-typed interface which includes the action-specific properties.
export interface NotificationSummaryRequestAction {
    type: NOTIFICATION_SUMMARY_REQUEST;
}

export interface NotificationSummaryReceivedAction {
    type: NOTIFICATION_SUMMARY_RECEIVED;
    totalDocumentsUpdated?: number;
    totalDocumentsSubscribed?: number;
}

export interface NotificationSummaryErrorAction {
    type: NOTIFICATION_SUMMARY_ERROR;
    error: string;
}

// Define the merged set of all the strongly-typed actions that can affect this model.
export type NotificationSummaryAction =
    NotificationSummaryRequestAction | NotificationSummaryReceivedAction | NotificationSummaryErrorAction;

// Define helper functions that return strongly-typed routable action instances that inject the action-specific properties into the routable objects.
export function requestNotificationSummary(): NotificationSummaryAction {
    return {
        type: NOTIFICATION_SUMMARY_REQUEST
    };
}

export function receivedNotificationSummary(totalDocumentsUpdated?: number, totalDocumentsSubscribed?: number): NotificationSummaryAction {
    return {
        totalDocumentsUpdated,
        totalDocumentsSubscribed,
        type: NOTIFICATION_SUMMARY_RECEIVED
    };
}

export function errorNotificationSummary(error: ErrorResponse): NotificationSummaryAction {
    return {
        error: error.message,
        type: NOTIFICATION_SUMMARY_ERROR
    };
}

// Define the reducer that performs the model changes required for all of the actions that can affect this model.
export const reducer: Redux.Reducer<NotificationSummary> =
    (oldState: NotificationSummary, action: NotificationSummaryAction): NotificationSummary => {
        switch (action.type) {
            case NOTIFICATION_SUMMARY_REQUEST:
                return {
                    ...oldState,
                    loaded: false,
                    pending: true
                };

            case NOTIFICATION_SUMMARY_RECEIVED:
                return {
                    ...oldState,
                    loaded: true,
                    pending: false,
                    totalDocumentsUpdated: action.totalDocumentsUpdated,
                    totalDocumentsSubscribed: action.totalDocumentsUpdated
                };

            case NOTIFICATION_SUMMARY_ERROR:
                return {
                    ...oldState,
                    totalDocumentsUpdated: undefined,
                    totalDocumentsSubscribed: undefined,
                    error: action.error,
                    loaded: false,
                    pending: false
                };

            default:
                return {
                    ...initialState,
                    ...oldState
                };
        }
    };
