import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { useEffect, useState, useRef } from 'react';
import { Loading } from 'react-simple-chatbot';
import { ChatBotStepEnum } from './types';
import { skApiServiceClient } from '../../../Services/SKApiService';
import { ErrorResponse, SKAiResponse } from 'src/App/Services/Models';
import { CitationCard } from './CitationCard';
import { Intl } from '../../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { SKAiSource } from 'src/App/Services/Models/SKAi/SKAiSource';
import './ChatWindow.css'
import { SKAiSourceType } from 'src/App/Services/Models/SKAi/SKAiSourceType';
import { CopilotFeedback } from '../../Feedback/CopilotOfficeFeedback';

import { store } from 'src/Store';
import { addConversationHistoryEntry } from 'src/App/Models/ChatBot/ConversationHistory';

const gfm = require('remark-gfm');

interface ChatWindowProps {
    conversationId: string,
    previousStep?: { value: string, id: string };
    steps?: Record<ChatBotStepEnum, { value: string }>;
    triggerNextStep?: () => {},
}

interface QuestionAndAnswer {
    question: string,
    answer: string,
    sources: Array<SKAiSource>
}

export const ChatWindow: React.FC<ChatWindowProps> = (props: ChatWindowProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [answer, setAnswer] = useState<string>("");
    const [promptData, setPromptData] = useState<string>("");
    const [sources, setSources] = useState<SKAiSource[]>([]);
    const previousQuery = props.previousStep && props.previousStep.id === ChatBotStepEnum.UserInput ? props.previousStep.value : undefined;

    const answerQuestion = (response: SKAiResponse) => {

        setAnswer(response.answer || "");
        setPromptData(response.promptData || "");
        setSources(response.sourceMapping || []);
        setLoading(false);

        const questionAndAnswer : QuestionAndAnswer = {
             question: previousQuery ? previousQuery : "",
             answer: response.answer ? response.answer : "",
             sources: response.sourceMapping && response.sourceMapping.length > 0 ? response.sourceMapping : []
        }

        /*
            Update the redux store's model for ConversationHistory so if the user wants to share the conversation history via feedback, we have access to it.
            There were problems trying to get the UseState() working in this function that gets called in UseEffect() to maintain a collection of conversation history,
            so we use the store instead.
        */ 
        store.dispatch(addConversationHistoryEntry(questionAndAnswer))
        
    }

    const handleErrorResponse = (response: ErrorResponse) => 
    {
        // TODO: Add this to conversation history? Or handle it here otherwise?
    }

    useEffect(() => {
        if (previousQuery)
        {
            setLoading(true);
            skApiServiceClient.AskQuestion(previousQuery, props.conversationId, answerQuestion, handleErrorResponse);
        }
    }, [previousQuery]);

    const sourcesJsx = Array.isArray(sources) && sources.length > 0 ? (
        <div className="chatWindow__sourcesWrapper">
            <p className="chatWindow__sources">{Intl.Get(LocIds.SKAiChatBot.Sources)}</p>
            {sources.map(d => 
                <CitationCard 
                    title={d.title ? d.title : d.pageUrl}
                    link={d.sourceType == SKAiSourceType.Document ? 
                        window.location.protocol + "//" + window.location.host + "/DocumentPage/" + d.documentId 
                        : window.location.protocol + "//" + window.location.host + "/" + "viewpage" + "/" + d.pageUrl}
                    description={d.description}
                    docExtension={d.docExtension}
                    citationType={d.sourceType}
                />)}
        </div>
    ): null; 

    const resultJsx = (
        <div title={promptData}>
            <ReactMarkdown className="chatWindow__answer" plugins={[gfm]} source={answer}/>                                  
            {sourcesJsx}
            <CopilotFeedback conversationId={props.conversationId}/>
        </div>
    )

    return <div>{ loading ? <Loading /> : resultJsx }</div>
}