import * as React from 'react';
import { HeaderPanelPropertiesData } from '../../Models/PageInfrastructure';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { getThemeClassName, getNeutralThemeOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { PrimaryButton, DefaultButton, Toggle, ComboBox, IComboBoxOption, IComboBox } from 'office-ui-fabric-react/lib/';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import Constants from '../../Utilities/Constants';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import * as ReactMarkdown from 'react-markdown';

export class HeaderPanel extends BasePanel<HeaderPanelPropertiesData> {
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<HeaderPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getNeutralThemeOptions());
    }

    getActualPanel() {
        return (
            <div data-panel-type="Header">
                <section className={'m-typographic-intro f-transparent ' + getThemeClassName(Theme[this.state.panelData.theme || 'Accent'])} style={this.state.panelData.isCompactMode ? { height: '250px' } : { height: '364px' }} >
                    <div>
                        <div>
                            <div className={'c-heading'}>{getLocalizedString(this.state.panelData.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</div>
                            <ReactMarkdown className={'c-subheading-4'} source={getLocalizedString(this.state.panelData.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)} skipHtml={true}/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div className="row" style={{marginLeft: '0px'}}>
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                        <div className="col">
                            {this.getActualPanel()}
                            {this.state.showEditor ?
                                <div className="editPanel">
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'Accent'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                            <Toggle
                                                checked={this.state.panelData.isCompactMode || false}
                                                label={Intl.Get(LocIds.PageAndPanels.HeaderCompactModeToggle)}
                                                onAriaLabel={Intl.Get(LocIds.PageAndPanels.HeaderCompactModeToggleOn)}
                                                offAriaLabel={Intl.Get(LocIds.PageAndPanels.HeaderCompactModeToggleOff)}
                                                onText={Intl.Get(LocIds.PageAndPanels.HeaderCompactModeToggleOn)}
                                                offText={Intl.Get(LocIds.PageAndPanels.HeaderCompactModeToggleOff)}
                                                onChanged={(value) => {
                                                    this.state.panelData.isCompactMode = value;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                            <TextFieldWrapper
                                                value={getLocalizedString(this.state.panelData.heading)}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={getLocalizedString(this.state.panelData.subHeading)}
                                                onChange={(newVal) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'subHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                required={true}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '6px' }} />
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                </div>
                                :
                                null}
                        </div>
                    </div > :
                    this.getActualPanel()
                }
            </div>
        );
    }
}