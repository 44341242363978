import { store } from '../../Store';

export class AuthorizationScope {
    static Pages = '/Pages';
    static Vocabularies = '/Vocabularies';
    static Documents = '/Documents';
    static Images = '/Images';
    static Links = '/Links';
    static CustomerSettings = '/CustomerSettings';
    static ADTenantManagement = '/ADTenantManagement';
    static ApplicationConfiguration = '/ApplicationConfiguration';
    static GdprBreachNotification = '/GDPR/BreachNotification';
    static AnyRoles = '/Settings/AnyRoles';
    static AdminRoles = '/Settings/AdminRoles';
    static NonAdminRoles = '/Settings/NonAdminRoles';
    static SSGlobalSwitch = '/Settings/SSGlobalSwitch';
    static UserPrivacy = '/Settings/UserPrivacy';
    static ComplianceManagerControls = '/ComplianceManager/Microsoft';
    static Series = '/Series';
}

// This class describes the Actions dimension of rights that are supported by the application.
export class AuthorizationActions {
    static Any: string = 'all';
    static Create: string = 'create';
    static Read: string = 'read';
    static Modify: string = 'modify';
    static Archive: string = 'archive';
    static Delete: string = 'delete';
    static Publish: string = 'publish';
    static Approve: string = 'approve';
    static Assess: string = 'assess';
}

// This class describes the Cloud Services dimension of rights that are supported by the application.
export class AuthorizationCloudServices {
    static Any: string = 'all';
    static Office365: string = 'office';
    static Azure: string = 'azure';
    static Dynamics365: string = 'dynamics';
}

// This interface combines each of the rights dimensions into a single structure.
export interface AuthorizationRights {
    actions?: string[];
    cloudServices?: string[];
}

// This interface describes the combination of a scope and a set of rights dimensions.
export interface ScopedAuthorizationRights {
    scope: string;
    rights: AuthorizationRights;
}

export enum FlightMembership {
    Any,
    All
}

// This interface is used to describe all of the authorization information for a user.
export class AuthorizationInfo {

    // This data structure contains the effective rights that the current user has for each scope where it changes. For example, let's take a
    // look at the following configuration:
    //      [
    //          { scope: '/', rights: {actions: ['read'], cloudServices: ['*']} },
    //          { scope: '/certifications/controlFamilies/controls', rights: {actions:['*'], cloudServices: ['azure']} },
    //      ]
    // This configuration does not say that the user has no rights for '/documents' or '/certifications'. Instead, this configuration says that
    // the user has the Read action for all cloud services at '/documents' because of the rights that are inherited from '/'. This configuration
    // also says that the user also inherits the same rights for '/certifications' and '/certifications/controlFamilies'. It is not until we go down
    // to '/certifications/controlFamilies/controls' that the user's rights are different. So the list of effective rights is really a list of scopes
    // where the user's rights are different from those of the parent node in the scope tree.
    // private static singleton?: AuthorizationInfo;
    // private effectiveScopedRights: ScopedAuthorizationRights[];
    // private authorizationHelper: AuthorizationInfoFactory;

    // This function will determine, for a single rights dimension, whether or not the effective rights contains all of the required rights.
    static effectiveRightsDimensionContainsRequiredRights(effectiveRights: string[] | undefined, requiredRights: string[] | undefined): boolean {
        // If there are no required rights then, by definition, the answer is "yes".
        if ((requiredRights === undefined) || (requiredRights.length < 0)) {
            return true;
        }

        // If there are no effective rights then, by definition, the answer is "no".
        if ((effectiveRights === undefined) || (effectiveRights.length <= 0)) {
            return false;
        }

        // If we've made it to here, then there is at least one required right and one effective right. If the effective rights
        // includes '*' then the user has all of the effective rights so there won't be a need to iterate through the required rights.
        // i.e User has 'All' permissions on this scope
        if (effectiveRights.indexOf(AuthorizationActions.Any) >= 0) {
            return true;
        }

        // The effective rights does not include '*' so we need to confirm that each required right is contained within the effective rights.
        for (let requiredRight of requiredRights) {
            // Check and see if the effective rights contains this require right.
            if (effectiveRights.indexOf(requiredRight) < 0) {
                // It does not.
                return false;
            }
        }

        // If we've made it to here then all of the required rights were found within the effective rights.
        return true;
    }

    // This function will determine the effective rights that the user has for the specified scope.
    static getEffectiveRightsForScope(scope: string): AuthorizationRights {
        // Go through the list of effective rights and find the entries with the longest matching scope. This will either be a parent node of the
        // requested scope or it will be an exact match for the requested scope. Any children of the requeste scope will be ignored.
        let closestMatchingScopedRights: ScopedAuthorizationRights | null = null;

        let allEffectiveScopedRights = AuthorizationInfo.getEffectivePermissions();
        if (allEffectiveScopedRights !== undefined) {
            for (let scopedRights of allEffectiveScopedRights) {
                // If the scope we're on starts with the scope we are looking
                //  AND we either haven't found any matching scopes yet or the closest match we've found so far is shorter than the match we're on
                //  AND the match we're on is not longer than the match we're looking for (ignore the child nodes)
                // then the scope that we're on is the best match that we've found so far.
                if (scope.startsWith(scopedRights.scope) &&
                    ((closestMatchingScopedRights === null) || (closestMatchingScopedRights.scope.length < scopedRights.scope.length)) &&
                    (scopedRights.scope.length <= scope.length)) {
                    closestMatchingScopedRights = scopedRights;
                }
            }
        }

        // If we never found a matching scope then the user doesn't have any rights on the specified scope.
        if (closestMatchingScopedRights === null) {
            return {
                actions: [],
                cloudServices: [],
            };
        }

        // If we've made it to here, then we've found a matching scope that is either a parent node we are inherting from
        // or the exact scope we were looking for.
        return closestMatchingScopedRights.rights;
    }

    // This function will determine whether or not user has all of the rights on the specified scope.
    public static isAuthorized(scope: string, rights: AuthorizationRights): boolean {
        // Get the effective rights that the user has on the specified scope.
        let effectiveRights: AuthorizationRights = AuthorizationInfo.getEffectiveRightsForScope(scope); // This will never return null.
        // There are several ways that the caller can indicate that they don't care about checking for a particular rights dimsions. They can
        // pass in undefined, an empty array, or an array that contains the value '*'. For each dimension, use a helper method to determine
        // if the effective rights for that dimension include the required rights for that same dimension.
        // tslint:disable-next-line:forin
        for (let dimensionName in effectiveRights) {
            let effectiveDimensionRights: string[] | undefined = (effectiveRights.hasOwnProperty(dimensionName)) ? effectiveRights[dimensionName] : undefined;
            let requiredDimensionRights: string[] | undefined = (rights.hasOwnProperty(dimensionName)) ? rights[dimensionName] : undefined;
            if (!AuthorizationInfo.effectiveRightsDimensionContainsRequiredRights(effectiveDimensionRights, requiredDimensionRights)) {
                // The effective rights is missing one or more of the required rights for this dimension.
                return false;
            }
        }
        // If we've made it to here then the user has all of the required rights in each dimension.
        return true;
    }

    // Determines whether or not this authorization info includes any of the rights on the scope.
    public static isAuthorizedForAny(scope: string, rights: AuthorizationRights[]): boolean {
        // Iterate through each of the scoped rights sets and determine if the user is authorized for any of them.
        for (let requiredRights of rights) {
            if (AuthorizationInfo.isAuthorized(scope, requiredRights)) {
                // This permission is not authorized on the scope.
                return true;
            }
        }

        // If we've made it to here, then the user wasn't authorized for any of the rights sets.
        return false;
    }

    // Ensures that this authorization info includes any of the specified rights on the specified scope. If it does not, an exception will be thrown.
    public static ensureIsAuthorizedOnAny(scope: string, rights: AuthorizationRights[]): void {
        if (!AuthorizationInfo.isAuthorizedForAny(scope, rights)) {
            throw new Error('Not authorized.');
        }
    }

    public static getEffectivePermissions() {
        let allEffectiveScopedRights = store.getState().applicationContext.userInfo.scopedAuthorizationRights;
        return allEffectiveScopedRights;
    }

    public static getFlights() {
        let flights = store.getState().applicationContext.userInfo.flights;
        return flights;
    }

    public static isIncludedInRequiredFlights(requiredFlights?: string[], flightMembership?: FlightMembership): boolean {

        // If there are no required flights then, by definition, the user has all of the required flights.
        if ((requiredFlights === undefined) ||  (requiredFlights.length === 0)) {
            return true;
        }

        // There is at least one required flight so we need to actually do the check. First, get the complete set of flights
        // that the user is a member of.
        var flightsTheUserIsAMemberOf = AuthorizationInfo.getFlights();

        // Figure out if we are in "Any" or "All" mode.
        let membershipRequirement: FlightMembership = (flightMembership === undefined)
            ? FlightMembership.All
            : flightMembership;

        if (membershipRequirement === FlightMembership.Any) {
            // We are in "Any" mode so loop through the required flights and return as soon as we find one that we have.
            for (let requiredFlight of requiredFlights) {
                let userHasThisRequiredFlight = flightsTheUserIsAMemberOf.some( 
                    (flightTheUserIsIn: string) => flightTheUserIsIn.toLocaleLowerCase() === requiredFlight.toLocaleLowerCase());
                if (userHasThisRequiredFlight) {
                    return true;
                }
            }

            // If we've made it to here, then the user doesn't have any of the flights.
            return false;
        }

        // We are in "All" mode so loop through the required flights and return as soon as we find one that we don't have.
        for (let requiredFlight of requiredFlights) {
            let userHasThisRequiredFlight = flightsTheUserIsAMemberOf.some(
                (flightTheUserIsIn: string) => flightTheUserIsIn.toLocaleLowerCase() === requiredFlight.toLocaleLowerCase());
            if (!userHasThisRequiredFlight) {
                return false;
            }
        }

        // If we've made it to here, then the user has all of the flights.
        return true;
    }

}