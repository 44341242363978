import { Reducer } from 'redux';
import { AssessmentFilterV3 } from '../../Models/ComplianceManagerV3/AssessmentFilterV3';
export const RENDER_ASSESSMENT_FILTER_SELECTIONV3 = 'RENDER_ASSESSMENT_FILTER_SELECTIONV3';

export interface FilterAction {
    type: typeof RENDER_ASSESSMENT_FILTER_SELECTIONV3;   
    data: AssessmentFilterV3; 
}

export function getDefaultState(): AssessmentFilterV3 {
    return {
        isShowAssessmentTileView: true,
        isShowArchivedRecord: false,
        selectedDimensions: [],
        selectedIndices: []
    };
} 

// Returns redux action to be used to for setting the assessment filter in the store
export function getUpdateAssessmentFilterAction(data: AssessmentFilterV3): FilterAction  {
    return {
        type: RENDER_ASSESSMENT_FILTER_SELECTIONV3,
        data: data,
    };
}

// Returns redux action to be used for clearing the assessment filter in the store
export function getClearAssessmentFilterAction(): FilterAction {
    return getUpdateAssessmentFilterAction(getDefaultState());
}

export const reducer: Reducer<AssessmentFilterV3, FilterAction> = (state: AssessmentFilterV3, action: FilterAction): AssessmentFilterV3 => {
    switch (action.type) {
        case RENDER_ASSESSMENT_FILTER_SELECTIONV3:
            return action.data;
        default:
            return state || getDefaultState();
    }    
};