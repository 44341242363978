import { Helmet } from "react-helmet"
import * as React from 'react';
interface HelmetWrapperProps {
    title: string;
    description?: string;
}
export const HelmetWrapper = (props: HelmetWrapperProps) => {
    const { title, description } = props;
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    );
}