import * as React from 'react';
import { LocalizationIds as LocIds } from '../../App/../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../App/Services/GlobalizationService';
import { Dialog, DialogFooter, DialogType, DefaultButton, Label } from 'office-ui-fabric-react';

interface NoAccessDialogState {
    isOpen: boolean;
    showPageMessage: boolean;
}

interface NoAccessDialogProps {
    title?: string;
    message?: string;
    subtext?: string;
    errorCallBack?: () => void;
}

export class NoAccessErrorDialog extends React.Component<NoAccessDialogProps, NoAccessDialogState>  {
    constructor(props: NoAccessDialogProps) {
        super(props);
        this.state = {
            isOpen: true,
            showPageMessage: false
        };
    }

    render() {
        return <>
            <Dialog
                hidden={!this.state.isOpen}
                containerClassName="ImageMgmtDialogs"
                className={'stp-session-'}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: this.props.title || Intl.Get(LocIds.ErrorPage.NotAuthorizedHeader),
                    subText: this.props.subtext || '',
                    onDismiss: () => { }
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: 'ms-dialogMainOverride'
                }}
            >
                <p>
                    <Label>{this.props.message || Intl.Get(LocIds.ErrorPage.NotExistOrNoPermission)}</Label>
                </p>
                <DialogFooter>
                    <div>
                        <DefaultButton
                            text='OK'
                            onClick={this.props.errorCallBack || this.closeDialog.bind(this)}
                        />
                    </div>
                </DialogFooter>
            </Dialog>
            { this.state.showPageMessage ? <div className="headingText">{this.props.message || Intl.Get(LocIds.ErrorPage.NotExistOrNoPermission)}</div> : null }
        </>;
    }

    closeDialog() {
        this.setState({ isOpen: false, showPageMessage: true });
    }
}