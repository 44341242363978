import { Reducer } from "react";
import { ScopePermissions } from "../../Common/Models/ScopesPermissions";

export interface ScopePermissionsState {
    canViewComplianceManagerV3Master: boolean;
    canModifyComplianceManagerV3Master: boolean;
    canModifyComplianceManagerV3Customer: boolean;
    canViewComplianceManagerV3Customer: boolean;
    canViewTestData: boolean;
    canModifyTestData: boolean;
    canViewTenantData: boolean;
    canModifyTenantData: boolean;
    canViewEvidenceData: boolean;
    canModifyEvidenceData: boolean;
    isGlobalAdmin: boolean;
}

interface ScopePermissionAdded {
    type: 'SCOPE_PERMISSION_ADDED',
    scopePermissions: ScopePermissions
}

export type ScopePermissionContextActions = ScopePermissionAdded;
export function addScopePermission(scopePermissions: ScopePermissions): ScopePermissionAdded {
    return {
        type: 'SCOPE_PERMISSION_ADDED',
        scopePermissions: scopePermissions
    };
}

export const reducer: Reducer<ScopePermissionsState, ScopePermissionContextActions> = (state: ScopePermissionsState, action: ScopePermissionContextActions) => {
    let newState: ScopePermissionsState = { ...state };
    switch (action.type) {
        case 'SCOPE_PERMISSION_ADDED':
            newState = {
                ...state,
                canViewComplianceManagerV3Master: action.scopePermissions.canViewComplianceManagerV3Master,
                canModifyComplianceManagerV3Master: action.scopePermissions.canModifyComplianceManagerV3Master,
                canModifyComplianceManagerV3Customer: action.scopePermissions.canModifyComplianceManagerV3Customer,
                canViewComplianceManagerV3Customer: action.scopePermissions.canViewComplianceManagerV3Customer,
                canViewTestData: action.scopePermissions.canViewTestData,
                canModifyTestData: action.scopePermissions.canModifyTestData,
                canViewTenantData: action.scopePermissions.canViewTenantData,
                canModifyTenantData: action.scopePermissions.canModifyTenantData,
                canViewEvidenceData: action.scopePermissions.canViewEvidenceData,
                canModifyEvidenceData: action.scopePermissions.canModifyEvidenceData,
                isGlobalAdmin: action.scopePermissions.isGlobalAdmin
            };
            break;
        default:
            break;
    }
    return newState;
};