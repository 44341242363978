import './ChatBotContainer.css';

import { Icon, Panel, PanelType } from 'office-ui-fabric-react';
import * as React from 'react';
import { useState } from 'react';
import ChatBot from 'react-simple-chatbot';
import { Intl } from '../../../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { ChatWindow } from './ChatWindow';
import { ChatBotStepEnum } from './types';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { ChatWindowHeader } from './ChatWindowHeader';
import { store } from 'src/Store';
import { addConversationHistoryEntry, setConverstationId as setChatbotConversationId, clearConversationHistory } from 'src/App/Models/ChatBot/ConversationHistory';

const chatBotWidth = "1200px";

// tslint:disable-next-line:no-var-requires
var uuid = require('uuid');

// TODO: can be moved to startup place
initializeFileTypeIcons();

export const ChatBotContainer: React.FC = () => {
    const [isChatBotOpen, setIsChatBotOpen] = useState(false);
    const [conversationId, setConversationId] = useState('');

    const toggleChatBot = () => {
        if (isChatBotOpen) {
            // Closing, so nullify the conversation Id. 
            setConversationId('');
            // when we close, we also want to clear the conversation history data. This clears both the history and the stored conversation id back to the initial blank state.
            store.dispatch(clearConversationHistory())
        } else {
            var newGuid = uuid.v1();
            setConversationId(newGuid);
            
            // store the conversation id in the store for later user (currently we just use it to write it to OCV if the user consents)
            store.dispatch(setChatbotConversationId(newGuid));

        }
        setIsChatBotOpen(!isChatBotOpen);
    }

    const steps = [
        {
            id: ChatBotStepEnum.FirstPrompt,
            message: Intl.Get(LocIds.SKAiChatBot.Prompt),
            trigger: ChatBotStepEnum.UserInput,
        },
        {
            id: ChatBotStepEnum.UserInput,
            user: true,
            trigger: ChatBotStepEnum.SKAiResponse,
        },
        {
            id: ChatBotStepEnum.SKAiResponse,
            component: <ChatWindow conversationId={conversationId}/>,
            trigger: ChatBotStepEnum.UserInput,
            asMessage: true
        },
    ]

    return (
        <>
        <div className='chatBot__wrapper'>
            <Icon className="chatBot__icon" iconName="ChatBot" onClick={toggleChatBot} />
        </div>
        <Panel
            className="chatBot__panel"
            isOpen={isChatBotOpen}
            isBlocking={false}
            type={PanelType.custom}
            customWidth={chatBotWidth}
            onDismiss={toggleChatBot}
            closeButtonAriaLabel="Close"
            onRenderNavigation={() => null}
        >
            <ChatBot
                conversationId={conversationId}
                steps={steps}
                height="100%"
                width={chatBotWidth}
                headerComponent={<ChatWindowHeader title="Service Trust Portal Chatbot" onDismiss={toggleChatBot}/>}/>
        </Panel>
        </>
    )
}