import { Icon as FabricIcon, IIconProps } from 'office-ui-fabric-react/lib/Icon';
import * as React from 'react';
import './Icon.css';

const Lock = (
    <svg 
        version="1.0" 
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 19 20" 
        enableBackground="new 0 0 19 20"
        xmlSpace="preserve"
    >
        <rect x="1.5454" y="7.0479" fill="none" stroke="#0067B8" strokeMiterlimit="10" width="16" height="11.5714"/>
        <path fill="none" stroke="#0067B8" strokeMiterlimit="10" d="M4.8787,7.0479v-2.191c0-2.3403,2.0893-4.2376,4.6667-4.2376s4.6667,1.8972,4.6667,4.2376v2.191"/>
    </svg>);

interface LocalIcons {
    Lock: JSX.Element;
}

const localIcons: LocalIcons = {
    Lock,
};

export class Icon extends React.Component<IIconProps> {
    public render() {
        const { iconName, className, ...rest } = this.props;
        if (iconName === undefined || localIcons[iconName] === undefined) {
            return <FabricIcon iconName={iconName} className={className} {...rest} />;
        } else {
            return (
                <i role="presentation" aria-hidden="true" className={`ms-Icon svg-icon ${className || ''}`} {...rest}>
                    {localIcons[iconName]}
                </i>
            );
        }
    }
}