import { Reducer, AnyAction } from 'redux';
import { ActionItemFilter } from '../../Models/ComplianceManagerV3';

export const ACTIONITEM_SET_FILTER_CMV3 = 'ACTIONITEM_SET_FILTER_CMV3';

export interface SaveActionItemFilterAction extends AnyAction {
    type: typeof ACTIONITEM_SET_FILTER_CMV3;
    data: ActionItemFilter;
}

// gets new instance of the ActionItemFilter redux action object to be used for saving ActionItemFilter object into the store
export function getSaveActionItemFilterAction(filter: ActionItemFilter): SaveActionItemFilterAction {
    return {
        type: ACTIONITEM_SET_FILTER_CMV3,
        data: filter,
    };
}

// gets new instance of the ActionItemFilter redux action object to be used for clearing ActionItemFilter object from the store
export const getClearActionItemFilterAction: () => SaveActionItemFilterAction = () => getSaveActionItemFilterAction(getUnloadedState());

// gets unloaded state for ActionItemFilter
export function getUnloadedState(): ActionItemFilter {
    return {
        actionTypes: [],
        assignedTo: null,
        assessmentId: '',
    };
}

// defines reducer function setting ActionItemFilter to be used with redux store
export const reducer: Reducer<ActionItemFilter> = (state: ActionItemFilter, action: SaveActionItemFilterAction): ActionItemFilter => {
    switch (action.type) {
        case ACTIONITEM_SET_FILTER_CMV3:
            return action.data;
        default:
            return state || getUnloadedState();
    }
};