import { Reducer } from 'redux';
import { GdprIncident } from '../Models/index';
import { GdprIncidentComment } from '../Services/Models';
import { BreachNotificationIncidentViewPanelModel } from '../Components/Gdpr/BreachNotificationIncidentViewPanel';
import { BreachNotificationIncidentEditorPanelModel, EditorType } from '../Components/Gdpr/BreachNotificationIncidentEditorPanel';

export type Status = 'Init' | 'Requesting' | 'Success' | 'Success.NeedReload'| 'Error';
export type Page = 'IncidentList' | 'IncidentPanel';

const BREACH_NOTIFICATION_REQUESTING = 'BREACH_NOTIFICATION_REQUESTING';
const BREACH_NOTIFICATION_REQUEST_SUCCEED = 'BREACH_NOTIFICATION_REQUEST_SUCCEED';
const BREACH_NOTIFICATION_REQUEST_ERROR = 'BREACH_NOTIFICATION_REQUEST_ERROR';
const BREACH_NOTIFICATION_LIST_INCIDENT_SUCCEED = 'BREACH_NOTIFICATION_LIST_INCIDENT_SUCCEED';
const BREACH_NOTIFICATION_LIST_MORE_INCIDENT_SUCCEED = 'BREACH_NOTIFICATION_LIST_MORE_INCIDENT_SUCCEED';
const BREACH_NOTIFICATION_DISMISS_MESSAGE_BAR = 'BREACH_NOTIFICATION_DISMISS_MESSAGE_BAR';
const BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_VIEW_PANEL = 'BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_VIEW_PANEL';
const BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_EDITOR_PANEL = 'BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_EDITOR_PANEL';
const BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_LIST = 'BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_LIST';
const BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_REQUESTING = 'BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_REQUESTING';
const BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_SUCCEED = 'BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_SUCCEED';
const BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_ERROR = 'BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_ERROR';
const BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_REQUESTING = 'BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_REQUESTING';
const BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_SUCCEED = 'BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_SUCCEED';
const BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_ERROR = 'BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_ERROR';
const BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_REQUESTING = 'BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_REQUESTING';
const BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_SUCCEED = 'BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_SUCCEED';
const BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_ERROR = 'BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_ERROR';
const BREACH_NOTIFICATION_SWITCH_TO_HISTORY_PANEL = 'BREACH_NOTIFICATION_SWITCH_TO_HISTORY_PANEL';
const BREACH_NOTIFICATION_SORT_INCIDENTS = 'BREACH_NOTIFICATION_SORT_INCIDENTS';

export interface BreachNotificationState {
    status: Status;
    page: Page;
    searchBoxText: string | null;
    incidents: GdprIncident[];
    marker: string;
    message: string | null;
    incidentPanel: BreachNotificationIncidentViewPanelModel | BreachNotificationIncidentEditorPanelModel | null;
}

const unloadedState: BreachNotificationState = {
    status: 'Init',
    page: 'IncidentList',
    searchBoxText: null,
    incidents: [],
    marker: '',
    message: null,
    incidentPanel: null
} as BreachNotificationState;

interface BreachNotificationRequesting {
    type: typeof BREACH_NOTIFICATION_REQUESTING;
}

interface BreachNotificationRequestSucceed {
    type: typeof BREACH_NOTIFICATION_REQUEST_SUCCEED;
    message: string | null;
    needReload: boolean;
}

interface BreachNotificationRequestError {
    type: typeof BREACH_NOTIFICATION_REQUEST_ERROR;
    message: string | null;
}

interface BreachNotificationListIncidentSucceed {
    type: typeof BREACH_NOTIFICATION_LIST_INCIDENT_SUCCEED;
    data: GdprIncident[];
    marker: string;
    searchBoxText: string | null;
}

interface BreachNotificationListIncidentCommentsRequesting {
    type: typeof BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_REQUESTING;
}

interface BreachNotificationListIncidentCommentsSucceed {
    type: typeof BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_SUCCEED;
    data: GdprIncidentComment[];
}

interface BreachNotificationListIncidentCommentsError {
    type: typeof BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_ERROR;
}

interface BreachNotificationGetIncidentHistoryVersionRequesting {
    type: typeof BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_REQUESTING;
    historyVersionId: string;
}

interface BreachNotificationGetIncidentHistoryVersionSucceed {
    type: typeof BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_SUCCEED;
    data: GdprIncident;
}

interface BreachNotificationGetIncidentHistoryVersionError {
    type: typeof BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_ERROR;
}

interface BreachNotificationDownloadNotifyingCsvFileRequesting {
    type: typeof BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_REQUESTING;
}

interface BreachNotificationDownloadNotifyingCsvFileSucceed {
    type: typeof BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_SUCCEED;
}

interface BreachNotificationDownloadNotifyingCsvFileError {
    type: typeof BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_ERROR;
    message: string;
}

interface BreachNotificationListMoreIncidentSucceed {
    type: typeof BREACH_NOTIFICATION_LIST_MORE_INCIDENT_SUCCEED;
    data: GdprIncident[];
    marker: string;
}

interface BreachNotificationDismissMessageBar {
    type: typeof BREACH_NOTIFICATION_DISMISS_MESSAGE_BAR;
}

interface BreachNotificationSwitchToIncidentList {
    type: typeof BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_LIST;
}

interface BreachNotificationSwitchToHistoryPanel {
    type: typeof BREACH_NOTIFICATION_SWITCH_TO_HISTORY_PANEL;
    historyVersionId: string;
}

interface BreachNotificationSwitchToIncidentViewPanel {
    type: typeof BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_VIEW_PANEL;
    index: number;
}

interface BreachNotificationSwitchToIncidentEditorPanel {
    type: typeof BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_EDITOR_PANEL;
    editor: EditorType;
    model: GdprIncident | null;
}

interface BreachNotificationSortIncidents {
    type: typeof BREACH_NOTIFICATION_SORT_INCIDENTS;
    fieldName: string;
    compareMethod: (fieldName: string, a: GdprIncident, b: GdprIncident) => -1 | 0 | 1;
}

export type BreachNotificationActions = BreachNotificationRequesting | 
    BreachNotificationRequestSucceed |
    BreachNotificationRequestError |
    BreachNotificationListIncidentSucceed |
    BreachNotificationListMoreIncidentSucceed |
    BreachNotificationDismissMessageBar |
    BreachNotificationSwitchToIncidentList |
    BreachNotificationSwitchToIncidentViewPanel |
    BreachNotificationSwitchToIncidentEditorPanel |
    BreachNotificationSwitchToHistoryPanel |
    BreachNotificationSortIncidents |
    BreachNotificationListIncidentCommentsRequesting |
    BreachNotificationListIncidentCommentsSucceed |
    BreachNotificationListIncidentCommentsError |
    BreachNotificationGetIncidentHistoryVersionError |
    BreachNotificationGetIncidentHistoryVersionSucceed |
    BreachNotificationGetIncidentHistoryVersionRequesting |
    BreachNotificationDownloadNotifyingCsvFileError |
    BreachNotificationDownloadNotifyingCsvFileSucceed |
    BreachNotificationDownloadNotifyingCsvFileRequesting;

export var BreachNotificationActionList = [
    BREACH_NOTIFICATION_REQUESTING,
    BREACH_NOTIFICATION_REQUEST_SUCCEED,
    BREACH_NOTIFICATION_REQUEST_ERROR,
    BREACH_NOTIFICATION_LIST_INCIDENT_SUCCEED,
    BREACH_NOTIFICATION_LIST_MORE_INCIDENT_SUCCEED,
    BREACH_NOTIFICATION_DISMISS_MESSAGE_BAR,
    BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_VIEW_PANEL,
    BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_EDITOR_PANEL,
    BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_LIST,
    BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_REQUESTING,
    BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_SUCCEED,
    BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_ERROR,
    BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_REQUESTING,
    BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_SUCCEED,
    BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_ERROR,
    BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_REQUESTING,
    BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_SUCCEED,
    BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_ERROR,
    BREACH_NOTIFICATION_SWITCH_TO_HISTORY_PANEL,
    BREACH_NOTIFICATION_SORT_INCIDENTS];

export const reducer: Reducer<BreachNotificationState> = (state: BreachNotificationState, action: BreachNotificationActions) => {
    let next = state ? JSON.parse(JSON.stringify(state)) : unloadedState;
    switch (action.type) {
        case BREACH_NOTIFICATION_REQUESTING:
            next.status = 'Requesting';
            return next;

        case BREACH_NOTIFICATION_REQUEST_SUCCEED:
            if (action.needReload === true) {
                next.status = 'Success.NeedReload';
            } else {
                next.status = 'Success';
            }
            next.message = action.message == null ? next.message : action.message;
            return next;

        case BREACH_NOTIFICATION_REQUEST_ERROR:
            next.status = 'Error';
            next.message = action.message == null ? next.message : action.message;
            return next;

        case BREACH_NOTIFICATION_LIST_INCIDENT_SUCCEED:
            next.status = 'Success';
            next.page = 'IncidentList';
            next.incidents = action.data;
            next.marker = action.marker;
            next.searchBoxText = action.searchBoxText;
            return next;

        case BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_REQUESTING:
            if (next.incidentPanel == null ||
                next.incidentPanel.comments == null) {
                break;
            }

            next.incidentPanel.comments.state = 'Requesting';
            return next;

        case BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_SUCCEED:
            if (next.incidentPanel == null ||
                next.incidentPanel.comments == null) {
                break;
            }

            next.incidentPanel.comments.state = 'Success';
            next.incidentPanel.comments.data = action.data;
            return next;

        case BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_ERROR:
            if (next.incidentPanel == null ||
                next.incidentPanel.comments == null) {
                break;
            }

            next.incidentPanel.comments.state = 'Error';
            return next;

        case BREACH_NOTIFICATION_LIST_MORE_INCIDENT_SUCCEED:
            next.status = 'Success';
            next.incidents = next.incidents.concat(action.data);
            next.marker = action.marker;
            return next;

        case BREACH_NOTIFICATION_DISMISS_MESSAGE_BAR:
            next.message = null;
            return next;

        case BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_LIST:
            next.page = 'IncidentList';
            next.incidentPanel = null;
            return next;

        case BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_EDITOR_PANEL:
            next.page = 'IncidentEditorPanel';
            let length = action.model == null ? 0 : action.model.impactedCustomersList.length;
            next.incidentPanel = {
                type: action.editor,
                model: action.model,
                impactedCustomersList: {
                    downloadStatus: Array(length).fill('Success')
                },
                csvFileUploaderDialogProps: {
                    status: 'Init',
                    message: null
                }
            } as BreachNotificationIncidentEditorPanelModel;
            return next;

        case BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_VIEW_PANEL:
            next.page = 'IncidentViewPanel';
            if (next.incidentPanel != null) {
                next.incidentPanel.model = next.incidents[action.index];
                next.incidentPanel.index = action.index;
                next.incidentPanel.page = 'Current';
                next.incidentPanel.notifyingButtonStatus = {
                    status: 'Init',
                    message: '',
                };
                if (next.incidentPanel.comments == null || next.incidentPanel.comments.state !== 'Success') {
                    next.incidentPanel.comments = {
                        state: 'Init',
                        data: []
                    };
                }
                if (next.incidentPanel.historyPanel == null || next.incidentPanel.historyPanel.status !== 'Success') {
                    next.incidentPanel.historyPanel = {
                        status: 'Init',
                        historyVersionId: null,
                        model: null
                    };
                }
            } else {
                next.incidentPanel = {
                    model: next.incidents[action.index],
                    index: action.index,
                    page: 'Current',
                    notifyingButtonStatus: {
                        status: 'Init',
                        message: '',
                    },
                    historyPanel: {
                        status: 'Init',
                        historyVersionId: null,
                        model: null
                    },
                    comments: {
                        state: 'Init',
                        data: []
                    }
                } as BreachNotificationIncidentViewPanelModel;
            }

            return next;

        case BREACH_NOTIFICATION_SWITCH_TO_HISTORY_PANEL:
            if (next.incidentPanel == null ||
                next.incidentPanel.historyPanel == null) {
                break;
            }

            next.page = 'IncidentViewPanel';
            next.incidentPanel.page = 'History';
            if (next.incidentPanel.historyPanel.historyVersionId !== action.historyVersionId ||
                next.incidentPanel.historyPanel.status !== 'Success') {
                next.incidentPanel.historyPanel = {
                    status: 'Init',
                    historyVersionId: action.historyVersionId,
                    model: null
                };
            }

            return next;

        case BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_ERROR:
            if (next.incidentPanel == null ||
                next.incidentPanel.historyPanel == null) {
                break;
            }

            next.incidentPanel.historyPanel.status = 'Error';
            return next;

        case BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_REQUESTING:
            if (next.incidentPanel == null ||
                next.incidentPanel.historyPanel == null) {
                break;
            }

            next.incidentPanel.historyPanel.status = 'Requesting';
            return next;

        case BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_SUCCEED:
            if (next.incidentPanel == null ||
                next.incidentPanel.historyPanel == null) {
                break;
            }

            next.incidentPanel.historyPanel.status = 'Success';
            next.incidentPanel.historyPanel.model = action.data;
            return next;

        case BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_ERROR:
            if (next.incidentPanel == null ||
                next.incidentPanel.notifyingButtonStatus == null) {
                break;
            }

            next.incidentPanel.notifyingButtonStatus.status = 'Error';
            next.incidentPanel.notifyingButtonStatus.message = action.message;
            return next;

        case BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_REQUESTING:
            if (next.incidentPanel == null ||
                next.incidentPanel.notifyingButtonStatus == null) {
                break;
            }

            next.incidentPanel.notifyingButtonStatus.status = 'Requesting';
            return next;

        case BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_SUCCEED:
            if (next.incidentPanel == null ||
                next.incidentPanel.notifyingButtonStatus == null) {
                break;
            }

            next.incidentPanel.notifyingButtonStatus.status = 'Success';
            return next;

        case BREACH_NOTIFICATION_SORT_INCIDENTS:
            if (next.incidents.length <= 1) {
                return next;
            }
            let currentOrder = 1;
            for (var i = 0; i < next.incidents.length; i++) {
                let order = action.compareMethod(action.fieldName, next.incidents[0], next.incidents[i]);
                if (order !== 0) {
                    currentOrder = order;
                    break;
                }
            }
            next.incidents.sort((a: GdprIncident, b: GdprIncident) => action.compareMethod(action.fieldName, a, b) * (currentOrder));
            return next;

        default:
    }

    return state || unloadedState;
};

export function requestIncident(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_REQUESTING,
    };
}

export function requestIncidentSucceed(message: string, needReload: boolean): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_REQUEST_SUCCEED,
        message: message,
        needReload: needReload,
    };
}

export function requestIncidentError(message: string): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_REQUEST_ERROR,
        message: message,
    };
}

export function listIncidentsSucceed(incidents: GdprIncident[], marker: string, searchBoxText: string | null): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_INCIDENT_SUCCEED,
        data: incidents,
        marker: marker,
        searchBoxText: searchBoxText,
    };
}

export function listMoreIncidentsSucceed(incidents: GdprIncident[], marker: string): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_MORE_INCIDENT_SUCCEED,
        data: incidents,
        marker: marker,
    };
}

export function dismissMessageBar(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_DISMISS_MESSAGE_BAR,
    };
}

export function switchToIncidentViewPanel(index: number): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_VIEW_PANEL,
        index: index
    };
}

export function switchToIncidentEditorPanel(editor: EditorType, model: GdprIncident | null): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_EDITOR_PANEL,
        editor: editor,
        model: model
    };
}

export function switchToIncidentList(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_SWITCH_TO_INCIDENT_LIST,
    };
}

export function switchToHistoryPanel(historyVersionId: string): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_SWITCH_TO_HISTORY_PANEL,
        historyVersionId: historyVersionId
    };
}

export function listIncidentsComments(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_REQUESTING,
    };
}

export function listIncidentsCommentsSucceed(comments: GdprIncidentComment[]): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_SUCCEED,
        data: comments,
    };
}

export function listIncidentsCommentsError(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_LIST_INCIDENT_COMMENTS_ERROR,
    };
}

export function getIncidentHistoryVersion(historyVersionId: string): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_REQUESTING,
        historyVersionId: historyVersionId,
    };
}

export function getIncidentHistoryVersionSucceed(data: GdprIncident): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_SUCCEED,
        data: data,
    };
}

export function getIncidentHistoryVersionError(message: string): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_GET_INCIDENT_HISTORY_VERSION_ERROR,
    };
}
export function DownloadNotifyingCsvFile(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_REQUESTING,
    };
}

export function DownloadNotifyingCsvFileSucceed(): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_SUCCEED,
    };
}

export function DownloadNotifyingCsvFileError(message: string): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_DOWNLOAD_NOTIFYING_CSV_FILE_ERROR,
        message: message
    };
}

export function SortIncidents(fieldName: string, compareMethod: (fieldName: string, a: GdprIncident, b: GdprIncident) => -1 | 0 | 1): BreachNotificationActions {
    return {
        type: BREACH_NOTIFICATION_SORT_INCIDENTS,
        fieldName: fieldName,
        compareMethod: compareMethod,
    };
}