import { store } from '../../Store';
import * as AuthenticationReducer from '../../App/Reducers/Authentication';
import { Diagnostics } from './Diagnostics';
import { isSovereignCloudInstance } from './ApplicationContextHelper';
import { ADAuthenticationHelper, User, AuthData } from './ADAuthenticationHelper';
import { loadApplicationContext } from '../../indexHtmlFunctions';

export class ADAuthenticate {    
    private static instance: ADAuthenticate;
    public user: User;
    public blockMsaUser: boolean;
    private loginInProgress: boolean;
    private apiUrl: string;
    private config = {
        tenant: 'organizations',
        responseType: 'code',
        responseMode: 'query',
        locationHref: '',
        postLogoutRedirectUri: '',
    };
    private authHelper: ADAuthenticationHelper;

    public static Current(): ADAuthenticate {
        if (!this.instance) {
            this.instance = new ADAuthenticate();
            this.instance.authHelper = new ADAuthenticationHelper(undefined);
        }
        return this.instance;
    }

    /**
     * If user object exists, returns it.
     * @returns {User} user object
     */
    public getCachedUser(): User {
        return this.user;
    }

    /**
     * Check whether user ia authorized based on the user ,token and username
     * @returns true if the user and token exist
     */
    public isSignedIn(): boolean {
        if (!this.user) {
            return false;
        }
        return !this.authHelper.isEmpty(this.user.userName) 
            && this.user.authData !== undefined 
            && this.user.authData.token.length > 0;
    }

    public login(apiUrl: string, itar?: boolean): void {
        if (this.authHelper.isEmpty(apiUrl)) {
            return;
        }
        this.apiUrl = apiUrl;
        if (this.loginInProgress) {
            return;
        }
        this.loginInProgress = true;
        this.config.locationHref = window.location.href;
        window.location.replace(this.getNavigateUrl(true, itar));
    }

    public performAutoSignForAltSecId(apiUrl: string) {
        // if tenant id is provided in url and not logged in try to login
        let tenantid = this.getParameterByName('tenantid', window.location.href);
        if (tenantid) {
            this.login(apiUrl);
        }
    }

    /**
     * Redirects user to logout endpoint.
     * After logout, it will redirect to postLogoutRedirectUri if added as a property on the config object.
     */
    public logOut(postLogoutUrl?: string): void {
        if (this.isSignedIn()) {

            let isItarUser = this.user.authData && this.user.authData.itarUser;

            if (typeof postLogoutUrl !== 'undefined' && postLogoutUrl && postLogoutUrl.length > 0) {
                this.config.postLogoutRedirectUri = postLogoutUrl;
            }

            this.writeAuthUser(null); // clear user
            window.location.replace(this.getNavigateUrl(false, isItarUser));
        }
    }

    public clearAuthUser(): void {
        this.writeAuthUser(null, true);
    }

    public isMSAUser(): boolean {
        return this.user && this.user.userName && this.user.userName.indexOf('live.com') > -1 ? true : false;
    }
    
    /**
     * Processes the redirect call from the middle-tier server.
     * 
     */
    public initializeADAuthenticateUsingToken() {
        // Check the query string for the token parameter. If it is there, then decode it and update this object with the
        // info from the token.
        let query = window.location.href.match(/\?token=(.*)&blockMsaUser=(.*)/);
        let tokenToUse: string = '';        
        if (query && query.length === 3) {
            let basePath = window.location.href;
            basePath = basePath.replace(/(token=)[^\&]*&blockMsaUser=[^\&]*/, '');
            if (basePath.endsWith('?')) {
                basePath = basePath.substring(0, basePath.length - 1);
            }
            // The token parameter is in the query string.
            Diagnostics.writeLine('initializeADAuthenticateUsingToken: Found token parameter in the query string.');
            let token: string = this.setAuthenticationProperties(query[1], query[2].toLowerCase() == 'true');            
            Diagnostics.writeLine('initializeADAuthenticateUsingToken: Token value from query string: ' + token);

            window.history.pushState(null, '', basePath);
            // The token is ready to be cached.
            if (!this.authHelper.isEmpty(token)) {
                // The token expiration and cache expiration are set to the same time.
                Diagnostics.writeLine('initializeADAuthenticateUsingToken: The token parameter value is not empty.');
                tokenToUse = token;                
            }
        } else {
            // The token parameter is in not in the query string. Try and get it from the token storage.
            Diagnostics.writeLine('initializeADAuthenticateUsingToken: Checking for cached token in storage ...');
            let userObj = this.readStoredAuthUser(); // Read user object from the store
            // ask WebAPI to pre-validate the token to make sure that it is a valid STP token and that it has not expired yet
            if (userObj !== null && userObj.authData !== undefined) {
                tokenToUse = userObj.authData.token;                
            }
            //         
            Diagnostics.writeLine('initializeADAuthenticateUsingToken: Cached token found in storage: ' + tokenToUse);
        }

        if (tokenToUse) {
            Diagnostics.writeLine('initializeADAuthenticateUsingToken: Updating  authorization context in store using token: ' + tokenToUse);                      
            store.dispatch(AuthenticationReducer.updateAuthorizationContextAction(this, tokenToUse));            
        } else {
            Diagnostics.writeLine('initializeADAuthenticateUsingToken: No token was found. Staying anonymous.');
        }
    }
    
    public RefreshAuthData(): void {
        let userObj = this.readStoredAuthUser(); // Read user object from the store
        if (userObj && userObj.authData && userObj.authData.token.length > 0) {
            // Sign in user i
            if (this.user && this.user.authData &&
                    userObj.authData.userName === this.user.authData.userName &&
                        parseInt(this.user.authData.expiresOn, 10) < parseInt(userObj.authData.expiresOn, 10)) {
                this.writeAuthUser(userObj, true);                         
            }
        } else if (!userObj && !this.isEmptyUser) {
            this.writeAuthUser(null, true);
        }
    }
    
    // Decodes given jwt lite payload and set various field values of the user authentication field.
    // We read needed values from certain claims of the decoded Jwt lite.
    // tslint:disable-next-line:no-any
    public setAuthenticationProperties(token: string, blockMsaUser: boolean): any {
        this.blockMsaUser = blockMsaUser;
        if (blockMsaUser) {
            store.dispatch(AuthenticationReducer.updateAuthorizationContextAction(this, ''));
            return token;
        }

        if (!token || token.length === 0) {
            return token;
        }
        if (token[token.length-1]==='#') {
            token = token.slice(0, -1);
        }
        let userObj = this.authHelper.parseStpJwtLite(token);
        if (userObj !== null) {
            this.writeAuthUser(userObj); // Sync value of the user object with local storage             
            Diagnostics.writeLine('setAuthenticationProperties: The token was decoded and the singleton was updated: userName=' + this.user.userName);
        }        
        return token;
    }

    private constructor() {}

    private writeAuthUser(user: User | null, isRefreshingState?: boolean) {
        let tokenToUse = '';
        let needToDispatchReduxAction: boolean | undefined = true;
        if (user !== null && user.authData) {
            if (this.user && this.user.authData) {
                needToDispatchReduxAction = this.user.authData.userName === user.authData.userName;
            }
            this.user = user;
            this.authHelper.writeAuthData(user.authData);
            if (user.authData) {
                tokenToUse = user.authData.token;
            }
        } else {
            needToDispatchReduxAction = this.user && this.user.authData && this.user.authData.token.length > 0;
            this.user = this.authHelper.createEmptyUser();
            this.authHelper.clearAuthData();
        }
        if (needToDispatchReduxAction) {
            store.dispatch(AuthenticationReducer.updateAuthorizationContextAction(this, tokenToUse));
            // reload application context if needed
            if (isRefreshingState) {
                const appCont = store.getState().applicationContext;
                if (!appCont || !appCont.userInfo || appCont.userInfo.principalName !== this.user.userName) {
                    loadApplicationContext();
                }
            }            
        }        
    }

    private readStoredAuthUser(): User | null {
        let userObj = this.authHelper.readAuthData();
        if (userObj !== null && this.isEmptyUser) {            
            this.user = userObj;
            store.dispatch(AuthenticationReducer.updateAuthorizationContextAction(this, userObj.authData ? userObj.authData.token : ''));
            loadApplicationContext();
        }
        return userObj;
    }

    private get isEmptyUser(): boolean {
        return (!this.user || this.authHelper.isEmpty(this.user.userName));
    }

    public getNavigateUrlForMeControl(login: boolean = true, itar?: boolean) {
        var apiUrl = store.getState().environmentSettings.webApiEndpointUrl;
        if (this.authHelper.isEmpty(apiUrl)) {
            return '';
        }
        this.apiUrl = apiUrl;
        this.config.locationHref = window.location.href;
        return this.getNavigateUrl(login, itar);
    }
    
    private getNavigateUrl(login: boolean = true, itar?: boolean) {
        let tenantid = this.getParameterByName('tenantid', this.config.locationHref);
        let appLoginUrl: string = store.getState().applicationContext.applicationLoginUrl;
        if (itar) {
            appLoginUrl = "https://login.microsoftonline.us";
        }
        if (tenantid) {
            this.config.tenant = tenantid;
        }
        // This is a sign-in operation. Return Azure sign-in url
        if (login) {
            let appClientId: string = store.getState().applicationContext.applicationClientId;
            let appGraphUrl: string = store.getState().applicationContext.applicationGraphUrl;
            let appScopes: string = store.getState().applicationContext.applicationScopes;

            if (!appScopes) {
                appScopes = `${appGraphUrl}/.default`;
            }

            var str = [];
            let redirectUri = this.apiUrl + '/api/login';
            if (itar) {
                appGraphUrl = "https://graph.microsoft.us";
                redirectUri += "/itar"
            }

            str.push('?client_id=' + encodeURIComponent(appClientId));
            if (store.getState().authentication.blockMsaUser) {
                str.push('prompt=select_account')
            }
            str.push('response_type=' + this.config.responseType);
            str.push('redirect_uri=' + encodeURIComponent(redirectUri));
            // str.push('resource=' + encodeURIComponent(appGraphUrl));
            str.push('scope=' + encodeURIComponent(appScopes));
            str.push('response_mode=' + this.config.responseMode);
            str.push('state=' + btoa(encodeURIComponent(this.config.locationHref)));

            if (isSovereignCloudInstance()) {
                str.push('msafed=' + 0);
            } else if (tenantid) {
                str.push('msafed=' + 1);
            }

            return appLoginUrl + '/' + this.config.tenant + '/oauth2/v2.0/authorize/' + str.join('&');
            // This is sign-out operation. Return Azure sign-out url
        } else {
            if (this.authHelper.isEmpty(this.config.postLogoutRedirectUri)) {
                this.config.postLogoutRedirectUri = window.location.href.split('?')[0].split('#')[0];
            }

            return appLoginUrl + '/' + this.config.tenant + '/oauth2/v2.0/logout?post_logout_redirect_uri=' +
                encodeURIComponent(this.config.postLogoutRedirectUri);
        }
    }

    private getParameterByName(name: string, url: string) {

        let hashes = url.slice(url.indexOf('?') + 1).split('&');
        let params = {};
        hashes.map(hash => {
            let [key, val] = hash.split('=');
            params[key] = decodeURIComponent(val);
        });
        return params[name];
    }
}

export const authContext = ADAuthenticate.Current();

// Returns ADAuthenticate context object that can be used for authentication context testing.
export function createMockContext(): ADAuthenticate {
    const fixedNowDate = new Date();
    const authTokenMock = 'ERHBVISDHFBVIFOWHFOVOEFVBOWEBHOU347SDBCJURHYFH';
    const userNAME = 'foo@foo.com';
    const userShortName = 'foo';
    const sessionEndWarningAt = fixedNowDate.getTime() / 1000; 
    const expirationTimeInSeconds = sessionEndWarningAt + 300; // current session jwt lite will expire in 5 minutes
    const issuedAtTimeInSeconds = sessionEndWarningAt  - 600; // current session jwt issued 10 minutes ago
    const sessionBeginAtTimeInSeconds = sessionEndWarningAt  - 1200; // user session begun 20 minutes ago
    const sessionEolAt = sessionBeginAtTimeInSeconds  + 86400; // user session end of life in 24 hours from the start
    const sessionEolWarningAt = sessionEolAt - 600; // user session end of life warning 10 minutes before 24 hours mark
    let authorizationContext: ADAuthenticate = authContext;
    authorizationContext.user = {
        userName: userNAME,
        userShortName: userShortName,        
        authData: {
            tokenType: 'Bearer',
            expiresOn: expirationTimeInSeconds.toString(),
            issuedAt: issuedAtTimeInSeconds.toString(),
            token: authTokenMock,
            userName: userNAME,
            userShortName: userShortName,
            sessionBeginAt: sessionBeginAtTimeInSeconds.toString(),
            sessionEndWarningAt: (sessionEndWarningAt - 20).toString(),
            sessionEolAt: sessionEolAt.toString(),
            sessionEolWarningAt: sessionEolWarningAt.toString(),
            localTimeDiscrepancyMilliseconds: 0,
            itarUser: false
        } as AuthData
    } as User;
    return authorizationContext;
}