import * as React from 'react';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { CommandBarButtonFactory, ButtonTypes } from '../../../Common/Utilities/CommanBarButtonFactory';
import { IContextualMenuItem, ContextualMenuItemType } from 'office-ui-fabric-react/lib/ContextualMenu';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { VocabularyServiceClient } from '../../Services/VocabularyService';
import { getLocalizedValue, getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { PanelData, DocumentPanelPropertiesData } from '../../Models/PageInfrastructure';
import { DocumentVersionInfo } from '../../Models';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
import { DocumentServiceClient } from '../../Services/DocumentVersionInfo';
import { VocabularySelection, SelectedVocabulary, VocabularyDefinition } from '../../Models/Vocabulary';
import Collapsible from '../../../Common/Components/Collapsible';
import { store } from '../../../Store';
import { MultiSelectContextualMenu } from '../../../Common/Components/MultiSelectContextualMenu';
import { saveVocabulary } from '../../Reducers/VocabulariesManagement';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Validator } from '../../../Common/Utilities/Validator';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import Constants from '../../Utilities/Constants';
import * as ReactMarkdown from 'react-markdown';
import { LicenseAgreementPage } from '../../Components/Licensing/LicenseAgreementPage';
import { downloadFile, getDocumentDetailLink, getDownloadDocumentLink } from '../../Utilities/DocumentHelper';
import './DocumentsPanel.css';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { isExternalLink, getExternalLink } from '../../Utilities/DocumentHelper';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

interface DocumentsPanelState {
    index: number;
    showEditor: boolean;
    panelData: PanelData<DocumentPanelPropertiesData>;
    panelId: string;
    documents: DocumentVersionInfo[];
    showAgreement: boolean;
    mustSelectTab: string;
    loadDocumentFinished: boolean;
    documentsToBeDownloaded: string[];
    orderBy: { name: string, asc: boolean }; // title, description, date; true asc false desc
    showWarningDialog: boolean;
    vocabularies: VocabularyDefinition[];
}

export interface GroupedData {
    choiceId: string;
    key: string;
    children: GroupedData[];
    documents: DocumentVersionInfo[];
    isSelected: boolean;
}

// TODO: This component need to be refactored
export class DocumentsPanel extends React.Component<BasicPanelProperties<DocumentPanelPropertiesData>, DocumentsPanelState> {
    private backup: DocumentPanelPropertiesData;
    private modifyButton: IContextualMenuItem;
    private saveButton: IContextualMenuItem;
    private discardButton: IContextualMenuItem;
    private deletePanelButton: IContextualMenuItem;
    private movePanelUpButton: IContextualMenuItem;
    private movePanelDownButton: IContextualMenuItem;
    private panelValidator: Validator = new Validator();
    private documentToBeDownload?: DocumentVersionInfo;

    // Related with pivot tabs slide left and right
    private tabsContainer: HTMLElement;
    private pivotContainer: HTMLElement;
    private tabTags: HTMLAnchorElement[];
    private prevButton: HTMLButtonElement;
    private nextButton: HTMLButtonElement;
    private hiddenWidth: number;
    private leftMostTabIndex: number;

    filteredDocuments(initialDocs: DocumentVersionInfo[], filteringInformation: SelectedVocabulary[] | undefined): DocumentVersionInfo[] {
        let ret: DocumentVersionInfo[] = [];
        if (!filteringInformation || filteringInformation.length === 0) {
            return initialDocs;
        }
        filteringInformation.forEach(filter => {
            if (!filter.choice) {
                return;
            }
            let temp: DocumentVersionInfo[] = [];
            filter.choice.forEach(choice => {
                this.getFilteredDocuments(
                    ret.length <= 0 ? initialDocs : ret,
                    { vocabulary: filter.vocabulary, choice: [choice], choiceDisplay: [], shouldExcludeValueOfAll: filter.shouldExcludeValueOfAll }).forEach(t => {
                    if (temp.indexOf(t) < 0) {
                        temp.push(t);
                    }
                });
            });
            ret = temp;
        });
        return ret;
    }

    getFilteredDocuments(docs: DocumentVersionInfo[], filter: SelectedVocabulary): DocumentVersionInfo[] {
        let ret: DocumentVersionInfo[] = [];
        docs.forEach(doc => {
            if (filter.vocabulary && filter.choice) {
                let choices = this.getChoicesFromDocument(filter.vocabulary, doc);
                if (choices && choices.vocabularyValueIds.indexOf(filter.choice[0]) >= 0) {
                    ret.push(doc);
                }
            }
        });
        return ret;
    }

    getChoicesFromDocument(vocabularyName: string, doc: DocumentVersionInfo): VocabularySelection | undefined {
        var choiceSelections = doc.vocabularySelections.find(sel => sel.vocabularyName.toLowerCase() === vocabularyName.toLowerCase());
        if (choiceSelections && choiceSelections.vocabularyValueIds.length === 1 && choiceSelections.vocabularyValueIds[0] === 'All' && this.state.vocabularies) {
            var voca = this.state.vocabularies.find(v => v.name === vocabularyName);
            if (voca) {
                var allChoiseSelections = voca.choices.map(c => c.id);
                return { vocabularyName: vocabularyName, vocabularyValueIds: allChoiseSelections };
            } else {
                return undefined;
            }
        }
        return choiceSelections;
    }

    groupDocuments(docs: DocumentVersionInfo[], groupingInformation: SelectedVocabulary[] | undefined, filteringInformation: SelectedVocabulary[] | undefined, mode: boolean): GroupedData[] {
        let keys: string[] = [];
        let ret: GroupedData[] = [];
        if (groupingInformation && groupingInformation.length > 0) {
            groupingInformation.forEach(groupInfo => keys.push(groupInfo.vocabulary || ''));
            keys.forEach(groupKey => {
                if (ret.length <= 0) {
                    let temp = this.getGroupedDocs(docs, groupKey, mode);
                    temp.forEach((value, subGroupKey) => {
                        let disPlayChoice = this.getChoiseDisplayValue(groupKey, subGroupKey, false);
                        if (disPlayChoice && filteringInformation && filteringInformation.length) {
                            var filterVoca = filteringInformation.find(f => f.vocabulary === groupKey);
                            var filterChoise = filterVoca ? filterVoca.choice.find(c => c === subGroupKey) : null;
                            if (filterVoca && !filterChoise) {
                                return;
                            }
                        }
                        // After delete the values of vocabulary, document still hold that value.
                        // But that value should not be shown as tab
                        if (disPlayChoice) {
                            ret.push({ key: disPlayChoice, choiceId: subGroupKey, children: [], documents: value, isSelected: false });
                        }
                    });
                } else {
                    ret.forEach(mainGroup => {
                        let temp = this.getGroupedDocs(mainGroup.documents, groupKey, mode);
                        temp.forEach((value, subGroupKey) => {
                            if (filteringInformation && filteringInformation.length) {
                                var filterVoca = filteringInformation.find(f => f.vocabulary === groupKey);
                                var filterChoise = filterVoca ? filterVoca.choice.find(c => c === subGroupKey) : null;
                                if (filterVoca && !filterChoise) {
                                    return;
                                }
                            }
                            mainGroup.children.push({ key: subGroupKey, choiceId: '', children: [], documents: value, isSelected: false });
                        });
                    });
                }
            });
        } else {
            ret.push({ key: '', choiceId: '', children: [], documents: docs, isSelected: false });
        }
        return ret;
    }

    getGroupedDocs(docs: DocumentVersionInfo[], vocabularyName: string, mode: boolean): Map<string, DocumentVersionInfo[]> {
        let ret: Map<string, DocumentVersionInfo[]> = new Map();
        docs.forEach(doc => {
            let choices = this.getChoicesFromDocument(vocabularyName, doc);
            if (choices && choices.vocabularyValueIds && choices.vocabularyValueIds.length > 0) {
                choices.vocabularyValueIds.forEach(choiceId => {
                    var choiceDisplay = choiceId;
                    if (ret.has(choiceDisplay)) {
                        let temp = ret.get(choiceDisplay);
                        if (temp) {
                            ret.set(choiceDisplay, [...temp, doc]);
                        }
                    } else {
                        ret.set(choiceDisplay, [doc]);
                    }
                });
            }
        });
        return ret;
    }

    getChoiseDisplayValue(vocabularyName: string, choiceId: string, mode: boolean) {
        let vocab = this.state.vocabularies.find(v => v.name === vocabularyName);
        if (vocab) {
            let choice = vocab.choices.find(c => c.id === choiceId);
            // Only if that value appear in that vocabulary we return the value
            // Else we return an empty string means the value should not be shown as tab
            return choice ? choice.displayText && choice.displayText.lv ? choice.displayText.lv : choice.displayValues[0].displayValue : '';
        }
        return '';
    }

    constructor(props: BasicPanelProperties<DocumentPanelPropertiesData>) {
        super(props);
        this.saveButton = CommandBarButtonFactory.GetButton(ButtonTypes.Save, () => { this.saveChange(); });
        this.discardButton = CommandBarButtonFactory.GetButton(ButtonTypes.RollBack, () => this.revertChanges(), Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel));
        this.modifyButton = CommandBarButtonFactory.GetButton(ButtonTypes.Edit, () => { 
            this.resetButtons(true);
            this.setState({ showEditor: true }); 
        });
        this.deletePanelButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.delete(this.state.panelData), Intl.Get(LocIds.PageAndPanels.DeleteButtonLabel));
        this.movePanelUpButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveUp, () => this.props.moveUp(this.state.panelData), Intl.Get(LocIds.PageAndPanels.MoveUpPanelButtonDescription));
        this.movePanelDownButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveDown, () => this.props.moveDown(this.state.panelData), Intl.Get(LocIds.PageAndPanels.MoveDownPanelButtonDescription));
        this.resetButtons(false);
        this.documentToBeDownload = undefined;
        this.state = {
            showEditor: false,
            panelData: this.props.panel,
            documents: [],
            index: 0,
            panelId: this.props.panel.id,
            showAgreement: false,
            mustSelectTab: this.getSelectTabInParametters(),
            loadDocumentFinished: false,
            documentsToBeDownloaded: [] as string[],
            orderBy: { name: 'date', asc: false },
            showWarningDialog: false,
            vocabularies: [] as VocabularyDefinition[]
        };
        this.backup = getDeepCopy(this.props.panel.panelInfo);

        // Related with pivot tabs slide left and right
        this.hiddenWidth = 0;
        this.leftMostTabIndex = 0;

        store.subscribe(() => {
            var storeVocabulary = store.getState().vocabulariesManagement;
            if (storeVocabulary.status === 'Init') {
                store.dispatch({ type: 'VOCABULARIES_FETCHING' });
                VocabularyServiceClient.listVocabularies(
                    () => { return; },
                    (r) => {
                        store.dispatch(saveVocabulary(r.data));
                    },
                    () => { return; });
            } else if (storeVocabulary.status === 'Finished') {
                this.setState({ vocabularies: storeVocabulary.vocabularyDefinitions });
            }
        });
    }

    componentDidUpdate() {
        this.updateTabScrollButtons();
    }

    UNSAFE_componentWillReceiveProps(nextProps: BasicPanelProperties<DocumentPanelPropertiesData>) {
        this.setState({
            panelData: nextProps.panel,
            index: 0,
            panelId: nextProps.panel.id
        });
        this.backup = getDeepCopy(nextProps.panel.panelInfo);
    }

    componentDidMount() {
        this.getDocuments();
        window.addEventListener('resize', () => this.updateTabScrollButtons());
    }

    loadScrollButton() {
        if (this.prevButton) {
            this.prevButton.style.display = 'none';
        }
        if (this.tabsContainer) {
            this.tabsContainer.style.paddingLeft = '0px';
        }
        if (this.pivotContainer && this.tabsContainer) {
            if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                this.nextButton.style.display = 'none';
            } else {
                this.nextButton.style.display = 'inline-block';
            }
        }
    }

    updateTabScrollButtons() {
        if (this.nextButton) {
            this.nextButton.style.display = (this.leftMostTabIndex === 0) ? 'none' : 'inline-block';
        }
        if (this.tabsContainer) {
            this.nextButton.style.display = (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) ? 'none' : 'inline-block';
        }
    }

    getDocuments() {
        this.setState({loadDocumentFinished: false});
        DocumentServiceClient.getPanelDocuments(
            this.props.panel,
            (response) => {
                this.setState({ documents: response.data as DocumentVersionInfo[], loadDocumentFinished: true });
                this.loadScrollButton();
            },
            () => this.setState({ loadDocumentFinished: true })
        );
    }

    getDate(reportDate: string, whenApproved: string) {
        var defaultDate = new Date('01/01/0001');
        if (new Date(reportDate).getTime() > defaultDate.getTime()) {
            return new Date(reportDate);
        }
        return new Date(whenApproved);
    }

    sortDoc(d1: DocumentVersionInfo, d2: DocumentVersionInfo) {
        if (this.state.orderBy.name === 'title') {
            let title1 = getLocalizedString(d1.title);
            let title2 = getLocalizedString(d2.title);
            return this.state.orderBy.asc ?
                (title1 < title2 ? -1 : title1 > title2 ? 1 : 0) :
                (title1 > title2 ? -1 : title1 < title2 ? 1 : 0);
        } else if (this.state.orderBy.name === 'description') {
            let des1 = getLocalizedString(d1.description);
            let des2 = getLocalizedString(d2.description);
            return this.state.orderBy.asc ?
                (des1 < des2 ? -1 : des1 > des2 ? 1 : 0) :
                (des1 > des2 ? -1 : des1 < des2 ? 1 : 0);
        } else {
            let time1 = this.getDate(d1.reportDate, d1.whenApprovedOrRejected).getTime();
            let time2 = this.getDate(d2.reportDate, d2.whenApprovedOrRejected).getTime();
            return this.state.orderBy.asc ? time1 - time2 : time2 - time1;
        }
    }
    getActualPanel() {
        // While fetching the docs, show the spinner, but it will still show sidebar editor when in edit mode
        if (!this.state.loadDocumentFinished) {
            return (
                <div style={{ paddingTop: '35px' }}>
                    <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingDocumentsLabel)} ariaLive="assertive" />
                </div>
            );
        }
        let filteredArray = this.filteredDocuments(this.state.documents, this.state.panelData.panelInfo.filteringInfo);
        let groupedData = this.groupDocuments(filteredArray, this.state.panelData.panelInfo.groupingInfo, this.state.panelData.panelInfo.filteringInfo, this.props.isInEditMode);
        let tabValue = -1;
        if (groupedData.length) {
            groupedData.sort((a, b) => a.key.localeCompare(b.key));
            if (this.state.panelData.panelInfo.firstTabName) {
                let index = groupedData.findIndex(g => g.choiceId === this.state.panelData.panelInfo.firstTabName);
                if (index !== -1) {
                    let firstTab = groupedData[index];
                    groupedData.splice(index, 1);
                    groupedData.unshift(firstTab);
                }
            }
            var isHaveSlectTab = false;
            groupedData.forEach((x, i) => {
                x.documents.sort((d1, d2) => this.sortDoc(d1, d2));
                if (this.state.mustSelectTab) {
                    x.isSelected = (this.state.mustSelectTab === x.choiceId);
                } else {
                    x.isSelected = (i === this.state.index);
                }
                if (x.isSelected) {
                    isHaveSlectTab = true;
                }
            });
            if (!isHaveSlectTab) {
                groupedData[0].isSelected = true;
            }
        }

        var hidePanel = false;
        var noResult = filteredArray.length === 0;
        if (this.state.panelData.panelInfo.hidePanelIfNoResult && noResult && !this.props.isInEditMode) {
            hidePanel = true;
        }
        if (hidePanel) {
            return <div />;
        }
        return (
            <div data-panel-type="Documents">
                <div>
                    <h2 className="c-heading-2">{getLocalizedString(this.state.panelData.panelInfo.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h2>
                    <ReactMarkdown className="c-paragraph-1" source={getLocalizedString(this.state.panelData.panelInfo.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)} skipHtml={true}/>
                </div>
                {groupedData.findIndex(x => x.key === '') === -1 ?
                    this.state.panelData.panelInfo.isInViewMode ?
                        <div style={{ marginTop: '12px' }}>
                            <div className="c-pivot" style={{ overflow: 'hidden' }} ref={(pivotContainer: HTMLDivElement) => this.pivotContainer = pivotContainer} >
                                <button
                                    className="tabscroll tabscroll-prev"
                                    aria-label="Show prev"
                                    ref={(prev: HTMLButtonElement) => this.prevButton = prev}
                                    onClick={() => {
                                        if (this.leftMostTabIndex > 0) {
                                            this.hiddenWidth -= (this.tabTags[--this.leftMostTabIndex].offsetWidth + 24);
                                            if (this.hiddenWidth < 0) {
                                                this.hiddenWidth = 0;
                                            }
                                            this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';
                                            if (this.leftMostTabIndex === 0) {
                                                this.prevButton.style.display = 'none';
                                                this.tabsContainer.style.paddingLeft = '0px';
                                            } else {
                                                this.tabsContainer.style.paddingLeft = '40px';
                                            }
                                            if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                                this.nextButton.style.display = 'none';
                                            } else {
                                                this.nextButton.style.display = 'inline-block';
                                            }
                                        }
                                    }}
                                />
                                <header role="tablist" ref={(tabsContainer: HTMLElement) => this.tabsContainer = tabsContainer} className="tabsContainer tabAnimation">
                                    {groupedData.map((data, index) =>
                                        <a
                                            key={index}
                                            onClick={() => this.setState({ index: index, mustSelectTab: '', orderBy: { name: 'date', asc: false } })}
                                            role="tab"
                                            tabIndex={tabValue + 1}
                                            className={data.isSelected ? 'f-active' : ''}
                                            aria-selected={data.isSelected ? 'true' : 'false'}
                                            aria-controls={data.key.replace(/\s/g, '_')}
                                            ref={(tabTag: HTMLAnchorElement) => this.tabTags[index] = tabTag}
                                        >
                                            {data.key}
                                        </a>)
                                    }
                                </header>
                                <button
                                    className="tabscroll tabscroll-next"
                                    aria-label="Show next"
                                    ref={(next: HTMLButtonElement) => this.nextButton = next}
                                    onClick={() => {
                                        if (this.leftMostTabIndex < this.tabTags.length - 1) {
                                            this.prevButton.style.display = 'inline-block';
                                            this.tabsContainer.style.paddingLeft = '40px';

                                            this.hiddenWidth += (this.tabTags[this.leftMostTabIndex++].offsetWidth + 24);
                                            this.tabsContainer.style.transform = 'translateX(' + -this.hiddenWidth + 'px)';

                                            if (this.hiddenWidth + this.pivotContainer.offsetWidth >= this.tabsContainer.offsetWidth) {
                                                this.nextButton.style.display = 'none';
                                            } else {
                                                this.nextButton.style.display = 'inline-block';
                                            }
                                        }
                                    }}
                                />

                                {groupedData.map((data, index) =>
                                    <section key={index} id={data.key.replace(/\s/g, '_')} role="tabpanel" aria-hidden={data.isSelected ? 'false' : 'true'} style={{ clear: 'both' }}>
                                        {data.children.length > 0 ?
                                            data.children.map((row, i) =>
                                                <div key={row.key.concat(i.toString())} style={{ marginLeft: '12px' }}>
                                                    <Collapsible
                                                        trigger={<div>{row.key}<span className={'customCounterDocs'}>{row.documents.length.toString().concat(' Document').concat(row.documents.length > 1 ? 's' : '')}</span></div>}
                                                        classParentString="fdCollapsible"
                                                    >
                                                        <div className="c-table f-divided clearBoth" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending">
                                                            <table data-f-sort="true">
                                                                {this.getTableHeader(data.key + index.toString())}
                                                                <tbody>{row.documents.map((documentVersion, rowIndex) => this.getTableRow(documentVersion, rowIndex, data))}</tbody>
                                                            </table>
                                                        </div>
                                                    </Collapsible>
                                                </div>
                                            )
                                            :
                                            <div className="c-table f-divided clearBoth" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending">
                                                <table data-f-sort="true">
                                                    {this.getTableHeader(data.key + index.toString())}
                                                    <tbody>{data.documents.map((documentVersion, rowIndex) => this.getTableRow(documentVersion, rowIndex, data))}</tbody>
                                                </table>
                                            </div>
                                        }
                                    </section>)}
                            </div>
                        </div>
                        : groupedData.map((data, index) =>
                            <div key={data.key.concat(index.toString())}>
                                <Collapsible
                                    trigger={<div>{data.key}<span className={'customCounterDocs'}>{data.documents.length.toString().concat(' Document').concat(data.documents.length > 1 ? 's' : '')}</span></div>}
                                    classParentString="fdCollapsible"
                                >
                                    {data.children.length > 0 ?
                                        data.children.map((row, i) =>
                                            <div key={row.key.concat(i.toString())} style={{ marginLeft: '12px' }}>
                                                <Collapsible
                                                    trigger={<div>{row.key}<span className={'customCounterDocs'}>{row.documents.length.toString().concat(' Document').concat(row.documents.length > 1 ? 's' : '')}</span></div>}
                                                    classParentString="fdCollapsible"
                                                >
                                                    <div className="c-table f-divided" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending">
                                                        <table data-f-sort="true">
                                                            {this.getTableHeader(data.key + index.toString())}
                                                            <tbody>{row.documents.map((documentVersion, index0) => this.getTableRow(documentVersion, index0))}</tbody>
                                                        </table>
                                                    </div>
                                                </Collapsible>
                                            </div>
                                        )
                                        :
                                        <div className="c-table f-divided" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending">
                                            <table data-f-sort="true">
                                                {this.getTableHeader(data.key + index.toString())}
                                                <tbody>{data.documents.map((documentVersion, index1) => this.getTableRow(documentVersion, index1))}</tbody>
                                            </table>
                                        </div>
                                    }
                                </Collapsible>
                            </div>)
                    :
                    <div className="c-table f-divided" data-f-loc-ascending="Sorted by {0} - ascending" data-f-loc-descending="Sorted by {0} - descending">
                        <table data-f-sort="true">
                            {this.getTableHeader(groupedData[0].key)}
                            <tbody>{groupedData[0].documents.map((documentVersion, documentIndex) => this.getTableRow(documentVersion, documentIndex))}</tbody>
                        </table>
                    </div>
                }
            </div>
        );
    }

    sortButtonOnClick(newName: string) {
        if (newName !== this.state.orderBy.name) {
            this.state.orderBy.asc = newName === 'date' ? false : true;
        } else {
            this.state.orderBy.asc = !this.state.orderBy.asc;
        }
        this.state.orderBy.name = newName;
        this.setState({
            orderBy: this.state.orderBy
        });
    }
    getTableHeader(id: string) {
        return (
            <thead>
                <tr>
                    <th id={'documentTitleColumnHeader' + id} scope="col" className="f-sortable" colSpan={1} aria-sort="none" style={{ width: '30%' }}>
                        <button style={{ fontSize: '18px' }} onClick={() => this.sortButtonOnClick('title')}>
                            {Intl.Get(LocIds.PageAndPanels.DocumentLabel)}
                        </button>
                    </th>
                    <th id={'documentDescriptionColumnHeader' + id} scope="col" className="f-sortable" colSpan={1} aria-sort="none">
                        <button style={{ fontSize: '18px' }} onClick={() => this.sortButtonOnClick('description')}>{Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}</button>
                    </th>
                    <th id={'documentReportDateColumnHeader' + id} scope="col" className="f-sortable" colSpan={1} aria-sort="none">
                        <button id={'sortByReportDate' + id} style={{ fontSize: '18px', width: '100px', textAlign: 'left' }} onClick={() => this.sortButtonOnClick('date')}>{Intl.Get(LocIds.PageAndPanels.ReportDateLabel)}</button>
                    </th>
                </tr>
            </thead>
        );
    }

    getTableRow(documentVersion: DocumentVersionInfo, i: number, groupData?: GroupedData) {
        let docTabId = '';
        if (groupData) {
            docTabId = this.props.panel.id + '_' + groupData.choiceId;
        }

        var parsed = new Date(this.getDate(documentVersion.reportDate, documentVersion.whenApprovedOrRejected));
        var month = parsed.getMonth() + 1;
        var date = parsed.getDate();
        var localTime = parsed.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (date < 10 ? '0' : '') + date;
        // Used to determin wether showing the NEW badge
        var thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        const isNewUI = store.getState().applicationContext.userInfo.flights.indexOf('NewUIDesign') > -1;
        return (
            <tr key={documentVersion.id.concat(i.toString())}>
                <td style={{ verticalAlign: 'top' }}>
                    <i
                        className={
                            isExternalLink(documentVersion.contentHash) ? 'ms-Icon fileextensionIcon ms-Icon--Globe'
                                : documentVersion.fileExtension === '.pdf' ? 'ms-Icon fileextensionIcon ms-Icon--PDF fileextensionPDF'
                                    : documentVersion.fileExtension === '.doc' || documentVersion.fileExtension === '.docx' ? 'ms-Icon fileextensionIcon ms-Icon--WordDocument fileextensionWord'
                                        : documentVersion.fileExtension === '.xls' || documentVersion.fileExtension === '.xlsx' ? 'ms-Icon fileextensionIcon ms-Icon--ExcelDocument fileextensionExcel'
                                            : documentVersion.fileExtension === '.ppt' || documentVersion.fileExtension === '.pptx' ? 'ms-Icon fileextensionIcon ms-Icon--PowerPointDocument fileextensionPPT'
                                                : 'ms-Icon fileextensionIcon ms-Icon--TextDocument'
                        }
                        aria-hidden="true"
                    />
                    {this.state.documentsToBeDownloaded.indexOf(documentVersion.id) !== -1 ?
                        <Spinner size={SpinnerSize.small} ariaLive="assertive" className="docDownloadSpinner" /> : null}
                    {
                        isExternalLink(documentVersion.contentHash) ?
                            <a
                                href={getExternalLink(documentVersion.contentHash)}
                                className="c-hyperlink fileName"
                                target="_blank"
                            >
                                {getLocalizedString(documentVersion.title)}
                                {parsed > thirtyDaysAgo && <strong className="c-badge f-small f-highlight newBadge">NEW</strong>}
                            </a>
                            :
                            <a
                                href={isNewUI ? getDocumentDetailLink(documentVersion.documentId) : getDownloadDocumentLink(documentVersion.documentId, this.props.parentId)}
                                onClick={(e) => {
                                    if (!isNewUI) {
                                        e.preventDefault();
                                        this.downloadDocument(documentVersion);
                                    }
                                }}
                                className="c-hyperlink fileName"
                            >
                                {getLocalizedString(documentVersion.title)}
                                {parsed > thirtyDaysAgo && <strong className="c-badge f-small f-highlight newBadge">NEW</strong>}
                            </a>
                    }
                </td>
                <td style={{ verticalAlign: 'top' }}>
                    <p className="sortFilter__document-description">{getLocalizedString(documentVersion.description)}</p>
                </td>
                <td style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}><p className="sortFilter__document-description">{localTime}</p></td>
            </tr>
        );
    }

    getSelectTabInParametters(): string {
        var response = '';
        var parametters = window.location.search;
        if (parametters) {
            parametters = parametters.substr(1);
            var params = parametters.split('&');
            params.forEach(p => {
                var contents = p.split('=');
                if (contents.length === 2) {
                    if (contents[0] === 'docTab') {
                        let values = decodeURIComponent(contents[1]).split('_');
                        if (values.length >= 2) {
                            if (values[0] === this.props.panel.id) {
                                response = values[1];
                                for (var i = 2; i < values.length; i++) {
                                    response = response + ' ' + values[i];
                                }
                            }
                        }
                    }
                }
            });
        }
        return response;
    }

    saveChange() {
        if (!this.panelValidator.validate()) {
            return;
        }
        this.backup = getDeepCopy(this.state.panelData.panelInfo);
        this.props.panel.panelInfo = getDeepCopy(this.backup);
        this.props.makeDirty();
        this.setState({ panelData: this.props.panel, showEditor: false });
        this.getDocuments();
        this.resetButtons(false);
    }

    revertChanges() {
        this.props.panel.panelInfo = getDeepCopy(this.backup);
        this.setState({ panelData: this.props.panel, showEditor: false });
        this.resetButtons(false);
    }

    resetButtons(showEditor: boolean) {
        this.modifyButton.disabled = showEditor;
        this.saveButton.disabled = !showEditor;
        this.discardButton.disabled = !showEditor;
    }

    saveGrouping(ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) {
        let info: SelectedVocabulary = this.getVocabularySelection(item);
        if (this.state.panelData) {
            if (!this.state.panelData.panelInfo.groupingInfo) {
                this.state.panelData.panelInfo.groupingInfo = [];
            }
            if (this.state.panelData.panelInfo.groupingInfo.findIndex(groupinfo => groupinfo.vocabulary === info.vocabulary) < 0) {
                this.state.panelData.panelInfo.groupingInfo.push(info);
            } else {
                this.state.panelData.panelInfo.groupingInfo = this.state.panelData.panelInfo.groupingInfo.filter(groupInfo => groupInfo.vocabulary !== info.vocabulary);
            }
            this.setState({ panelData: this.state.panelData });
        }
    }

    saveFiltering(ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) {
        let info: SelectedVocabulary = this.getVocabularySelection(item);
        var selectedVocabulary = this.state.vocabularies.find(v => v.name === info.vocabulary);
        if (selectedVocabulary) {
            var choise = selectedVocabulary.choices.find(c => c.id === item.key);
            if (choise) {
                info.choiceDisplay = [getLocalizedValue(choise.displayValues, false)];
            }
        }
        if (this.state.panelData) {
            if (!this.state.panelData.panelInfo.filteringInfo) {
                this.state.panelData.panelInfo.filteringInfo = [];
            }
            let index = this.state.panelData.panelInfo.filteringInfo.findIndex(filter => filter.vocabulary === info.vocabulary);
            if (index === -1) {
                this.state.panelData.panelInfo.filteringInfo.push(info);
            } else {
                let i = this.state.panelData.panelInfo.filteringInfo[index].choice.findIndex(filterChoice => filterChoice === info.choice[0]);
                if (i === -1) {
                    this.state.panelData.panelInfo.filteringInfo[index].choice.push(info.choice[0]);
                    this.state.panelData.panelInfo.filteringInfo[index].choiceDisplay.push(info.choiceDisplay[0]);
                } else {
                    let choice = this.state.panelData.panelInfo.filteringInfo[index].choice.filter(filterChoice => filterChoice !== info.choice[0]);
                    this.state.panelData.panelInfo.filteringInfo[index].choice = choice;
                    let displayChoice = this.state.panelData.panelInfo.filteringInfo[index].choiceDisplay.filter(filterChoice => filterChoice !== info.choiceDisplay[0]);
                    this.state.panelData.panelInfo.filteringInfo[index].choiceDisplay = displayChoice;
                    if (this.state.panelData.panelInfo.filteringInfo[index].choice.length <= 0) {
                        let filter = this.state.panelData.panelInfo.filteringInfo.filter(filterChoice => filterChoice.vocabulary !== info.vocabulary);
                        this.state.panelData.panelInfo.filteringInfo = filter;
                    }
                }
            }
            this.setState({ panelData: this.state.panelData });
        }
    }

    getVocabularySelection(item: IContextualMenuItem): SelectedVocabulary {
        let info: SelectedVocabulary = { choice: [], choiceDisplay: [], vocabulary: '', shouldExcludeValueOfAll: true };
        info.vocabulary = item.name ? item.name : '';
        if (info.choice) {
            info.choice.push(item.key);
        }
        if (info.choiceDisplay) {
            info.choiceDisplay.push(item.name ? item.name : '');
        }
        return info;
    }

    deleteItem(input: SelectedVocabulary[], index: number): SelectedVocabulary[] {
        let array: SelectedVocabulary[] = [];
        input.forEach((data, i) => {
            if (i !== index) {
                array.push(data);
            }
        });
        return array;
    }

    getFilterChoiceDisplayText(input: SelectedVocabulary): string {
        let ret: string = '';
        if (input.choiceDisplay) {
            input.choiceDisplay.forEach((x, i) => ret = ret.concat(('\'').concat(x).concat('\'').concat(i < input.choiceDisplay.length - 1 ? ' or ' : '')));
        }
        return ret;
    }

    togglePivot() {
        if (this.state.panelData.panelInfo.isInViewMode !== undefined) {
            this.state.panelData.panelInfo.isInViewMode = !this.state.panelData.panelInfo.isInViewMode;
        } else {
            this.state.panelData.panelInfo.isInViewMode = true;
        }
        this.setState({ panelData: this.state.panelData });
    }

    renderConfirmationDialog() {
        return (
            <Dialog
                hidden={!this.state.showWarningDialog}
                onDismiss={() => this.setState({ showWarningDialog: false })}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: Intl.Get(LocIds.DocumentManagementPage.DocumentDownloadWarningHeader),
                    subText: Intl.Get(LocIds.DocumentManagementPage.DocumentDownloadWarningDescription),
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: 'ms-dialogMainOverride'
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => this.setState({ showWarningDialog: false })} text="Confirm" />
                </DialogFooter>
            </Dialog>
        );
    }

    downloadDocument(document: DocumentVersionInfo, docTabId?: string) {
        var fileName = document.title.lv + document.fileExtension;
        if (document.isPublic) {
            let index = this.state.documentsToBeDownloaded.indexOf(document.id);
            if (index === -1) {
                this.state.documentsToBeDownloaded.push(document.id);
            } else {
                return;
            }
            this.setState({
                documentsToBeDownloaded: this.state.documentsToBeDownloaded,
                showWarningDialog: document.isSpecialDocument
            });
            downloadFile(document.documentId, fileName, () => {
                let indexToBeDeleted = this.state.documentsToBeDownloaded.indexOf(document.id);
                if (indexToBeDeleted > -1) {
                    this.state.documentsToBeDownloaded.splice(indexToBeDeleted, 1);
                    this.setState({ documentsToBeDownloaded: this.state.documentsToBeDownloaded });
                }
            });
            return;
        }
        var hasPermissionToDownload = store.getState().authentication.isSignedIn && store.getState().applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement;
        if (!hasPermissionToDownload) {
            window.history.pushState(null, '', getDownloadDocumentLink(document.documentId, this.props.parentId, docTabId));
            this.documentToBeDownload = document;
            this.setState({ showAgreement: true });
        } else {
            let index = this.state.documentsToBeDownloaded.indexOf(document.id);
            if (index === -1) {
                this.state.documentsToBeDownloaded.push(document.id);
            } else {
                return;
            }
            this.setState({
                documentsToBeDownloaded: this.state.documentsToBeDownloaded,
                showWarningDialog: document.isSpecialDocument
            });
            downloadFile(document.documentId, fileName, () => {
                let indexToBeDeleted = this.state.documentsToBeDownloaded.indexOf(document.id);
                if (indexToBeDeleted > -1) {
                    this.state.documentsToBeDownloaded.splice(indexToBeDeleted, 1);
                    this.setState({ documentsToBeDownloaded: this.state.documentsToBeDownloaded });
                }
            });
        }
    }

    trace(value: string) {
        if (window && window.console && window.console.log) {
            window.console.log(value);
        }
    }

    render() {
        this.tabTags = [];
        let groupMenuItems: IContextualMenuItem[] = [];
        let filterMenuItems: IContextualMenuItem[] = [];

        if (!this.state.vocabularies) {
            return <div />;
        }
        this.state.vocabularies.forEach(element => {
            groupMenuItems.push(
                {
                    key: element.id,
                    name: element.name,
                    canCheck: true,
                    isChecked: this.state.panelData.panelInfo.groupingInfo && this.state.panelData.panelInfo.groupingInfo.findIndex(x => x.vocabulary === element.name) !== -1 ? true : false,
                    onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => this.saveGrouping(ev, item)
                });
            filterMenuItems.push(
                {
                    key: element.id,
                    name: element.name,
                    itemType: ContextualMenuItemType.Header
                });
            element.choices.forEach(x => {
                let flag: boolean = false;
                if (this.state.panelData.panelInfo.filteringInfo) {
                    let index = this.state.panelData.panelInfo.filteringInfo.findIndex(y => y.vocabulary === element.name);
                    if (index !== -1) {
                        if (this.state.panelData.panelInfo.filteringInfo[index].choice.findIndex(y => y === x.id) !== -1) {
                            flag = true;
                        }
                    }
                }
                filterMenuItems.push(
                    {
                        key: x.id,
                        name: getLocalizedValue(x.displayValues, true),
                        canCheck: true,
                        isChecked: flag,
                        onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => this.saveFiltering(ev, { ...item, name: element.name })
                    });
            });
        });
        let values = [] as { key: string, text: string }[];
        if (this.state.panelData.panelInfo.groupingInfo && this.state.panelData.panelInfo.groupingInfo.length > 0) {
            var firstGroup = this.state.panelData.panelInfo.groupingInfo[0].vocabulary;
            var voca = this.state.vocabularies.find(v => v.name === firstGroup);
            if (voca) {
                voca.choices.map((item) => values.push({ key: item.id, text: item.displayText && item.displayText.lv ? item.displayText.lv : item.displayValues[0].displayValue }));
            }
        }

        return (
            <div data-grid="col-12" >
                {this.props.isInEditMode ? <CommandBar items={[this.modifyButton, this.saveButton, this.discardButton]} farItems={[this.deletePanelButton, this.movePanelUpButton, this.movePanelDownButton]} /> : null}
                <div data-grid="col-12" >
                    {this.getActualPanel()}
                </div >
                {this.state.showAgreement &&
                    <LicenseAgreementPage
                        onAgree={() => {
                            this.setState({ showAgreement: false });
                            var document = this.documentToBeDownload;
                            if (document) {
                                this.documentToBeDownload = undefined;
                                this.downloadDocument(document);
                            }
                        }}
                        onDismiss={() => {
                            this.documentToBeDownload = undefined;
                            this.setState({ showAgreement: false });
                        }}
                        callBack={() => {
                            this.documentToBeDownload = undefined;
                            this.setState({ showAgreement: false });
                        }}
                    />}
                {this.state.showWarningDialog && this.renderConfirmationDialog()}
                {this.props.isInEditMode && this.state.showEditor ?
                    <div data-grid="col-12" className="editPanel">
                        <div data-grid="col-5">
                            <TextFieldWrapper
                                value={this.state.panelData.panelInfo.heading ? getLocalizedString(this.state.panelData.panelInfo.heading) : ''}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                    if (this.state.panelData.panelInfo.heading) {
                                        if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                            newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                        }
                                        setDefaultLocalizedString(this.state.panelData.panelInfo, 'heading', newVal);
                                    }
                                    this.setState({ panelData: this.state.panelData });
                                }}
                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                required={true}
                                validator={this.panelValidator}
                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                            />
                            <MarkDownEditor
                                value={this.state.panelData.panelInfo.subHeading ? getLocalizedString(this.state.panelData.panelInfo.subHeading) : ''}
                                onChange={(newVal) => {
                                    if (this.state.panelData.panelInfo.subHeading) {
                                        setDefaultLocalizedString(this.state.panelData.panelInfo, 'subHeading', newVal);
                                    }
                                    this.setState({ panelData: this.state.panelData });
                                }}
                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                required={true}
                                validator={this.panelValidator}
                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                            />
                            <div data-grid="col-12">
                                <div className="group-label">
                                    <span className="c-subheading-6">{Intl.Get(LocIds.PageAndPanels.GroupByPrimarySecondaryLabel)}</span>
                                </div>
                                {this.state.panelData.panelInfo.groupingInfo && this.state.panelData.panelInfo.groupingInfo.length < 2 ?
                                    <MultiSelectContextualMenu
                                        text={Intl.Get(LocIds.PageAndPanels.SelectAGroupLabel)}
                                        menuItems={groupMenuItems}
                                        style={{ display: 'block', float: 'left', margin: '0px', padding: '0px' }}
                                    />
                                    : null}
                            </div>
                            {this.state.panelData.panelInfo.groupingInfo ?
                                <div data-grid="col-12">
                                    <ul className="c-group f-wrap-items">
                                        {
                                            this.state.panelData.panelInfo.groupingInfo.map((data, index) =>
                                                <li className="c-choice-summary" key={index} style={{ marginTop: '0px' }}>
                                                    <button
                                                        className="c-action-trigger c-glyph glyph-cancel"
                                                        aria-label="Remove"
                                                        onClick={() => {
                                                            if (this.state.panelData.panelInfo.groupingInfo) {
                                                                this.state.panelData.panelInfo.groupingInfo = this.deleteItem(this.state.panelData.panelInfo.groupingInfo, index);
                                                            }
                                                            this.setState({ panelData: this.state.panelData });
                                                        }}
                                                    />
                                                    <span>{data.vocabulary ? data.vocabulary : ''}</span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div> : null}
                            <div data-grid="col-12">
                                <Toggle
                                    checked={this.state.panelData.panelInfo.isInViewMode === undefined ? false : this.state.panelData.panelInfo.isInViewMode}
                                    label={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggle)}
                                    onAriaLabel={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOn)}
                                    offAriaLabel={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOff)}
                                    onText={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOn)}
                                    offText={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOff)}
                                    onChanged={(value) => this.togglePivot()}
                                />
                            </div>
                            <div data-grid="col-12">
                                <div className="group-label">
                                    <span className="c-subheading-6">First tab of the Primary Group</span>
                                </div>
                                <Dropdown
                                    isDisabled={!this.state.panelData.panelInfo.groupingInfo || !this.state.panelData.panelInfo.groupingInfo.length}
                                    options={values}
                                    defaultSelectedKey={this.state.panelData.panelInfo.firstTabName ? this.state.panelData.panelInfo.firstTabName : undefined}
                                    onChanged={(option) => {
                                        let panelData = this.state.panelData;
                                        panelData.panelInfo.firstTabName = option.key.toString();
                                        this.setState({ panelData: panelData });
                                    }}
                                />
                            </div>
                            <div data-grid="col-12">
                                <div className="group-label">
                                    <span className="c-subheading-6">Filter documents by properties:</span>
                                </div>
                                <MultiSelectContextualMenu text={'Select a Filter'} menuItems={filterMenuItems} style={{ display: 'block', float: 'left', margin: '0px', padding: '0px' }} />
                            </div>
                            {this.state.panelData.panelInfo.filteringInfo &&
                                <div>
                                {
                                    this.state.panelData.panelInfo.filteringInfo.map((data, index) =>
                                        <div key={index}>
                                            {index > 0 ? <label>And</label> : ''}
                                            <ul className="c-group f-wrap-items">
                                                <li className="c-choice-summary" style={{ marginTop: '0px' }}>
                                                    <button
                                                        className="c-action-trigger c-glyph glyph-cancel"
                                                        aria-label="Remove"
                                                        onClick={() => {
                                                            if (this.state.panelData.panelInfo.filteringInfo) {
                                                                this.state.panelData.panelInfo.filteringInfo = this.deleteItem(this.state.panelData.panelInfo.filteringInfo, index);
                                                            }
                                                            this.setState({ panelData: this.state.panelData });
                                                        }}
                                                    />
                                                    <span>{`${data.vocabulary || ''} = ${this.getFilterChoiceDisplayText(data)}`}</span>
                                                    <div style={{paddingLeft: '10px'}}>
                                                        <Checkbox
                                                            label="Hide documents tagged with value [All]"
                                                            checked={this.state.panelData 
                                                                && this.state.panelData.panelInfo 
                                                                && this.state.panelData.panelInfo.filteringInfo
                                                                && index >= 0 && index < this.state.panelData.panelInfo.filteringInfo.length
                                                                && this.state.panelData.panelInfo.filteringInfo[index].shouldExcludeValueOfAll
                                                            }
                                                            onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                                this.setState((prevState) => {
                                                                    let newState = {...prevState.panelData};
                                                                    if (newState.panelInfo.filteringInfo) {
                                                                        newState.panelInfo.filteringInfo[index].shouldExcludeValueOfAll = isChecked;
                                                                    }
                                                                    return { panelData: newState };
                                                                });
                                                            }}
                                                            ariaDescribedBy={'shouldExcludeValueOfAll'}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }
                                </div>
                            }
                            <div data-grid="col-12">
                                <Toggle
                                    checked={this.state.panelData.panelInfo.hidePanelIfNoResult || false}
                                    label={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggle)}
                                    onAriaLabel={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOn)}
                                    offAriaLabel={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOff)}
                                    onText={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOn)}
                                    offText={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOff)}
                                    onChanged={(value) => {
                                        this.state.panelData.panelInfo.hidePanelIfNoResult = value;
                                        this.setState({ panelData: this.state.panelData });
                                    }}
                                />
                            </div>
                        </div>
                        <div data-grid="col-5" />
                    </div>
                    : null}
            </div >
        );
    }
}