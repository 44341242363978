import * as React from 'react';

export interface StatusAlertProps {
    title: string;
    type: 'error' | 'warning';
    onCloseClick: () => void;
}

export default class StatusAlert extends React.Component<StatusAlertProps> {
    render() {
        var alertType = 'f-error';

        switch (this.props.type) {
            case 'error':
                alertType = 'f-error';
                break;
            case 'warning':
                alertType = 'f-warning';
                break;
            default:
        }

        if (this.props.title) {
            return (
                <div className={'m-alert ' + alertType} role="alert">
                    <button 
                        className="c-action-trigger c-glyph glyph-cancel" 
                        aria-label="Close" 
                        onClick={() => this.props.onCloseClick()} 
                        style={{paddingRight: 10}}
                    />
                    <div>
                        <div className="c-glyph glyph-incident-triangle" aria-label="Error message" />
                        <p className="c-paragraph">
                            {this.props.title}
                        </p>
                    </div>
                </div>
            );
        } 
        
        return null;
    }
}
