export interface DocumentDownloadHistoryMetadata {
    title: string,
    whenDownloaded: string,
    documentVersionStatus: DocumentVersionStatus,
    documentId: string
    fileExtension: string
}

export enum DocumentVersionStatus
{
    Live,
    HasNewerVersion,
    Deleted
}