import * as React from 'react';
import { StatusMessage } from '../Components/StatusMessage';
import { store } from '../../Store';
import * as StatusMessageReducer from '../Reducers/StatusMessageReducer';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';
import { ApplicationContextFactory, ApplicationContext } from '../../Common/Utilities/ApplicationContext';
import * as ApplicationContextReducer from '../Reducers/ApplicationContext';
import { EnvironmentSettingsFactory, DefaultEnvironmentSettingsFactory } from '../../Common/Utilities/EnvironmentSettings';
import { EnvironmentSettings } from '../Models/EnvironmentSettings';
import * as EnvironmentSettingsReducer from '../Reducers/EnvironmentSettings';
import * as LocalizedStringsReducer from '../Reducers/LocalizedStrings';
import { LocalizedStringsFactory } from '../../Common/Utilities/LocalizedStrings';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Diagnostics } from '../../Common/Utilities/Diagnostics';
import { Status } from '../Reducers/Status';
import { LicenseAgreementPage } from '../Components/Licensing/LicenseAgreementPage';
import { downloadFile } from '../Utilities/DocumentHelper';
import { DocumentServiceClient } from '../Services/DocumentVersionInfo';
import { DocumentVersionInfo } from '../Models';
import { authContext } from '../../Common/Utilities/ADAuthenticate';
import { Dialog } from 'office-ui-fabric-react';
import { TelemetryClient } from '../../Common/Utilities/Telemetry';
import TopOfPageComponents from '../Components/TopOfPageComponents';
import Routes from '../Components/Routes';
import { pageServicesV2Client } from '../Services/PageServicesV2';
import { UhfInfo } from '../Models/PageInfrastructure/UhfInfo';
import { getAdminCode, getCookie, loadMeControl, setInnerHtml } from 'src/Common/Utilities/UHFHelper';

// tslint:disable no-var-requires
const intl = require('react-intl-universal');
export const MeControlContainerId = 'meControl';
export const MeControlApiGeneration = 'GEN2';

let telemetry = TelemetryClient;

const mainContentTopMargin = {
    marginTop: '8px'
};

// tslint:disable-next-line:no-any
declare const window: any;

export interface PageLayoutModel {
    location?: Location;
    environmentSettingsFactory?: EnvironmentSettingsFactory;
    applicationContextFactory?: ApplicationContextFactory;
    localizedStringsFactory?: LocalizedStringsFactory;
    environmentSettingsStatus?: Status;
    applicationContextStatus?: Status;
    localizedStringsStatus?: Status;
    applicationContextCulture?: string;
    localizedStringsCulture?: string;
    applicationContextAppInsightId?: string;
    complianceMgrShowCondition?: boolean;
    isNextGenUIEnabled: boolean;
    isAnalyticsCookiesConsented: boolean;
}

interface PageLayoutState {
    hasLoadedEnoughStateToShowTheApp: boolean;
    showAgreement: boolean;
    showMSADialog: boolean;
    searchKeyword: string;
    showErrorDialog: boolean;
}

interface CommandInfo {
    command: string;
    downloadType: string;
    downloadId: string;
    post_logout_redirect_uri: string;
}

export default class PageLayoutCurrent extends React.Component<PageLayoutModel, PageLayoutState> {
    private previousPaths: string[] = [];
    private documentToDownload?: DocumentVersionInfo;

    private topNavDiv: HTMLDivElement;
    private bodyDiv: HTMLDivElement;
    private contentDiv: HTMLDivElement;
    private footerDiv: HTMLDivElement;

    private currentLocale: string;

    constructor(props: PageLayoutModel) {
        super(props);
        window.stpPageLayoutComponent = this;
        this.state = {
            hasLoadedEnoughStateToShowTheApp: false,
            showAgreement: false,
            searchKeyword: '',
            showMSADialog: false,
            showErrorDialog: false
        };
        this.documentToDownload = undefined;
        
        this.getUHFContent();

        Diagnostics.writeLine('PageLayout.constructor was called');

        // If the user just signed in, then this app will have just been loaded via a redirect from our web API and there will be a JWT in the query
        // string. If that is the case, then we need to process the JWT. There is a helper method that will detect this situation and process the JWT
        // for us.
        store.getState().authentication.context.initializeADAuthenticateUsingToken();

        Diagnostics.writeLine('PageLayout.constructor is exiting');
    }

    componentDidUpdate(prevProps: PageLayoutModel) {
        if (this.props.location !== prevProps.location) {
            if (prevProps.location && this.props.location) {
                let historyLength = this.previousPaths.length;
                if (historyLength === 0 || this.previousPaths[historyLength - 1] !== this.props.location.pathname) {
                    this.previousPaths.push(this.props.location.pathname);
                }
            }

            if (this.props.location) {
                store.dispatch(StatusMessageReducer.getClearAllAction());
            }
        }

        if (this.props.isAnalyticsCookiesConsented !== prevProps.isAnalyticsCookiesConsented) {
            TelemetryClient.UpdateCookieConfig(this.props.isAnalyticsCookiesConsented);
        }

        this.adjustComponentHeight(false);
    }

    componentDidMount() {
        this.getPageSettings(this.props);

        window.addEventListener('resize', () => {
            this.adjustComponentHeight(true);
        });
    }

    adjustComponentHeight(resize: boolean) {
        if (this.topNavDiv && this.footerDiv && this.contentDiv) {
            let newHeight = window.innerHeight - this.topNavDiv.clientHeight - this.footerDiv.clientHeight * 0.26;
            this.bodyDiv.style.verticalAlign = 'middle';
            if (newHeight > this.contentDiv.clientHeight) {
                this.bodyDiv.style.minHeight = newHeight.toString() + 'px';
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: PageLayoutModel) {
        this.getPageSettings(nextProps);
        var userLocale = store.getState().applicationContext.cultureName;

        if (userLocale) {
            window.OfficeBrowserFeedback = window.OfficeBrowserFeedback || {};
            window.OfficeBrowserFeedback.initOptions = {
                appId: 2153,
                stylesUrl: '',
                intlUrl: '/intl/',
                locale: userLocale,
                // intlFilename is an optional property for using a custom filename for the internationalized strings, the default filename will be used if it is not specified
                intlFilename: 'officebrowserfeedbackstrings.js', // (optional) Here the default filename is used for this field, simply to demonstrate the syntax for this property
                environment: 0, // 0 - Prod, 1 - Int
                primaryColour: '#008272', // Replace by a colour which goes with your website.
                secondaryColour: '#004B50', // Replace by a colour which goes with your website.
                userEmail: '',
                sessionId: '10000000-0000-0000-0000-000000000000', // (optional) If specified, the value needs to be a valid GUID
                build: '123456789', // TODO: Should get replaced with the current version of the app
                // userVoice is optional, basic form will be displayed if not specified.
                userVoice: {
                    url: 'https://aka.ms/sec_uv',
                    termsOfServiceUrl: 'http://go.microsoft.com/fwlink/?LinkID=786896',
                    privacyPolicyUrl: 'http://go.microsoft.com/fwlink/?LinkID=786895'
                }
            };
        }
    }

    checkDownloadFile() {
        var downloadInfo = this.getUrlParametters();
        if (!downloadInfo || downloadInfo.command !== 'Download') {
            return;
        }

        if (downloadInfo.downloadType === 'Document' && downloadInfo.downloadId) {
            if (!this.documentToDownload || this.documentToDownload.documentId !== downloadInfo.downloadId) {
                DocumentServiceClient.getLiveDocument(
                    downloadInfo.downloadId,
                    (r) => {
                        var data = r.data as DocumentVersionInfo;
                        if (data && data.documentId === downloadInfo.downloadId) {
                            this.documentToDownload = data;
                            this.checkDownloadFile();
                        } else {
                            if (!store.getState().authentication.isSignedIn) {
                                this.setState({ showAgreement: true });
                            } else {
                                if (authContext.isMSAUser()) {
                                    this.setState({ showMSADialog: true });
                                } else {
                                    alert(Intl.Get(LocIds.PageAndPanels.ErrorDownloadMessage));
                                }
                            }
                        }
                        return;
                    },
                    () => { return; });

                return;
            }
            var fileName = this.documentToDownload.title.lv + this.documentToDownload.fileExtension;
            if (this.documentToDownload.isPublic) {
                downloadFile(this.documentToDownload.documentId, fileName, () => { return; });
                this.documentToDownload = undefined;
                return;
            }

            var hasPermissionToDownload = store.getState().authentication.isSignedIn && store.getState().applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement;
            if (!hasPermissionToDownload) {
                this.setState({ showAgreement: true });
            } else {
                downloadFile(downloadInfo.downloadId, fileName, () => { return; });
                this.documentToDownload = undefined;
            }
        }
    }

    getUrlParametters() {
        var downloadInfo: CommandInfo = {
            command: '',
            downloadId: '',
            downloadType: '',
            post_logout_redirect_uri: ''
        };
        var parametters = window.location.search;
        if (parametters) {
            parametters = parametters.substr(1);
            var params = parametters.split('&');
            params.forEach((p: string) => {
                var contents = p.split('=');
                if (contents.length === 2) {
                    downloadInfo[contents[0]] = contents[1];
                }
            });
        }
        return downloadInfo;
    }

    getPageSettings(props: PageLayoutModel) {
        Diagnostics.writeLine('Starting store change notification processing ...');

        // We can't show any UI until we know several things: the environment settings, the application context, and the localized strings
        // that should be used. All three of these things are different web requests. Unfortunately, information from the results of the first
        // request is required before we can start the second request. And information in the results of the second request is needed before
        // we can start the third request. This block of code incrementally makes each of these requests to load all of the information that
        // is necessary to show the app.
        Diagnostics.writeLine('1a - Determining what to do based on the environment settings status: ' + props.environmentSettingsStatus + ' ...');
        if (props.environmentSettingsStatus === 'Init') {
            // The environment settings haven't been loaded yet so we need to start the request that will load it. Update the store with a
            // change that shows that the environment settings are now in the process of being loaded.
            Diagnostics.writeLine('1b - Starting environment settings request. Updating the store to \'Loading\' ...');
            store.dispatch(EnvironmentSettingsReducer.getEnviromentSettingsLoadingAction());

            // If an environment settings factory was provided in the component's props then use it. Otherwise, use the default factory.
            let environmentSettingsFactoryToUse: EnvironmentSettingsFactory = (props && props.environmentSettingsFactory)
                ? props.environmentSettingsFactory
                : new DefaultEnvironmentSettingsFactory();

            // Start the environment settings request.
            environmentSettingsFactoryToUse.startRequestToGetEnvironmentSettings(
                // onSuccess
                (environmentSettings: EnvironmentSettings) => {
                    Diagnostics.writeLine('1d - The environment settings request succeeded. Updating the store to \'Loaded\' ...');

                    // Now that we've gotten the environment settings, we can stick them in the store and raise the EnvironmentSettingsChanged
                    // action. There is code below that will notice this change and initate the application context request.
                    store.dispatch(EnvironmentSettingsReducer.getEnviromentSettingChangedAction(environmentSettings));
                },
                // onFailed
                () => {
                    // TODO: replace this
                    alert('The request to load the environment settings has failed.');
                }
            );
        } else if (props.environmentSettingsStatus === 'Loading') {
            Diagnostics.writeLine('1c - The environment settings are already loading.');
        } else if (props.environmentSettingsStatus === 'Loaded') {
            Diagnostics.writeLine('1z - The environment settings have been loaded.');
        } else {
            Diagnostics.writeLine('1r - The environment settings store state was unknown.');
        }

        // If the environment settings have been loaded but the application context request hasn't been started yet then it is now time to start it.
        Diagnostics.writeLine('2a - Determining what to do based on the application context status: ' + props.applicationContextStatus + ' ...');
        if (props.applicationContextStatus === 'Init') {

            if (props.environmentSettingsStatus === 'Loaded') {

                // The application context hasn't been loaded yet but the environment settings have been so we can now start the request to get the
                // application context. Update the store with a change that shows that the application context is now in the process of being loaded.
                Diagnostics.writeLine('2b - Starting application context request. Updating the store to \'Loading\' ...');
                store.dispatch(ApplicationContextReducer.getApplicationContextLoadingAction());

            } else {
                Diagnostics.writeLine('2x - Can\'t start loading application context because one or more of the prerequisites haven\'t been loaded yet.');
            }
        } else if (props.applicationContextStatus === 'Loading') {
            Diagnostics.writeLine('2c - The application context is already loading.');
        } else if (props.applicationContextStatus === 'Loaded') {
            Diagnostics.writeLine('2z - The application context has been loaded.');
        } else {
            Diagnostics.writeLine('2r - The application context store state was unknown.');
        }

        // It is possible that the language of the currently loaded UI strings may not match the language of the app. Once scenario where this can happen
        // is when the application is still in the process of loading it's initial state. The application context response will have been received specifying the
        // language that the app is supposed to use but the app has yet to load the localized strings. The other scenario were this can happen is when the
        // user has chosen to change the language on the fly. In this case, the store will have been updated with the requested language but the actual
        // strings haven't been loaded yet. In either case, we need to start an XHR request to get the UI strings in the current app language.
        Diagnostics.writeLine('3a - Determining what to do based on the localized strings status: ' + props.localizedStringsStatus + ' ...');
        if ((props.localizedStringsStatus === 'Init') ||
            ((props.localizedStringsStatus === 'Loaded') && (props.localizedStringsCulture !== this.currentLocale))) {

            if ((props.environmentSettingsStatus === 'Loaded') &&
                (props.applicationContextStatus === 'Loaded')) {
                // The localized strings either haven't been loaded yet or the culture name was changed. Update the store with a change that shows that the
                // localized strings are now in the process of being loaded.
                Diagnostics.writeLine('3b - Starting localized strings request. Updating the store to \'Loading\' ...');
                store.dispatch(LocalizedStringsReducer.getLocalizedStringsLoadingAction());
            } else {
                Diagnostics.writeLine('3x - Can\'t start loading localized strings because one or more of the prerequisites haven\'t been loaded yet.');
            }
        } else if (props.localizedStringsStatus === 'Loading') {
            Diagnostics.writeLine('3c - The localized strings is already loading.');
        } else if (props.localizedStringsStatus === 'Loaded') {
            Diagnostics.writeLine('3z - The localized strings has been loaded.');
        } else {
            Diagnostics.writeLine('3r - The localized strings store state was unknown.');
        }

        // If we have loaded everything that is necessary then we can show the app.
        Diagnostics.writeLine('4a - Determining what to show based on the store state ...');
        if ((props.environmentSettingsStatus === 'Loaded') &&
            (props.applicationContextStatus === 'Loaded') &&
            (props.localizedStringsStatus === 'Loaded') &&
            (props.localizedStringsCulture === this.currentLocale)) {
            Diagnostics.writeLine('4z - Showing the app because all of the prerequisites have been loaded.');
            this.setState({
                hasLoadedEnoughStateToShowTheApp: true,
            });
        } else {
            Diagnostics.writeLine('4x - Not showing the app because one or more of the prerequisites haven\'t been loaded yet.');
        }

        Diagnostics.writeLine('Exiting store change notification processor.');
    }

    setApplicationContext(applicationContext: ApplicationContext): void {
        if (applicationContext == null) {
            return;
        }

        Diagnostics.writeLine('2d - The application context request succeeded. Updating the store to \'Loaded\' ...');

        // Pass this applicationContext.appInsightsId to Initialize
        TelemetryClient.Initialize(applicationContext.appInsightsId, this.props.isAnalyticsCookiesConsented);

        telemetry.trackEvent(
            'WebUI.ApplicationContext.Culture',
            {
                ApplicationCulture: applicationContext.cultureName
            }
        );

        // Now that we've gotten the application context, we can stick it in the store and raise the ApplicationContextChanged
        // action. There is code below that will notice this change and initiate the localized strings request.
        store.dispatch(ApplicationContextReducer.getApplicationContextChangedAction(applicationContext));
        if (applicationContext.userInfo.flights.indexOf('NextGenUI') === -1) {
            this.checkDownloadFile();
        }
    }

    setLocalizedStrings(cultureName: string, localizedStrings: {}) {
        Diagnostics.writeLine('3d - The localized strings request succeeded. Resetting the Intl library and updating the store to \'Loaded\' ...');

        // Now that we've gotten the localized strings, we can update the Intl library with the new strings.
        intl.init({
            currentLocale: cultureName,
            locales: {
                [cultureName]: localizedStrings
            }
        });
        this.currentLocale = cultureName;
        // Update the store to show that the localized strings have now been loaded.
        store.dispatch(LocalizedStringsReducer.getLocalizedStringsChangedAction(cultureName, localizedStrings));
    }

    // tslint:disable-next-line:no-any
    goToAnchor(event: any) {
        event.preventDefault();
        let content = document.getElementById('mainContent');
        if (content !== null) {
            event.currentTarget.hidden = true;
            window.scrollTo(0, content.offsetTop);
            if (window.history.replaceState) {
                window.history.replaceState('', '', window.location.pathname + '#mainContent');
            }
            event.currentTarget.hidden = false;
            content.focus();
        }
    }

    render() {
        let MSAText: string = Intl.Get(LocIds.PageAndPanels.ErrorDownloadMessageForMSA);
        let parsedMSAText = MSAText.split('**');
        return (
            <div className="pageBody">
                <TopOfPageComponents />
                {this.state.hasLoadedEnoughStateToShowTheApp ?
                    <div>
                        {
                            this.state.showMSADialog &&
                            <Dialog
                                isOpen={true}
                                onDismiss={() => this.setState({ showMSADialog: false })}
                                title={'Sign up for a free trial'}
                                isBlocking={false}
                            >
                                <p className="c-paragraph" style={{ display: 'inline' }}>
                                    {parsedMSAText[0]}
                                    <a href="https://aka.ms/Nmv3bd" style={{ color: '#106ebe', textDecoration: 'underline' }} target="_blank">{parsedMSAText[1]}</a>
                                    {parsedMSAText[2]}
                                </p>
                            </Dialog>
                        }
                        <a
                            className="m-skip-to-main"
                            tabIndex={0}
                            onClick={(event) => this.goToAnchor(event)}
                            onKeyPress={(event) => { if (event.key === 'Enter') { this.goToAnchor(event); } }}
                            style={{ cursor: 'pointer' }}
                        >{Intl.Get(LocIds.PageLayout.SkipToMainContent)}
                        </a>

                        <div ref={(div: HTMLDivElement) => this.topNavDiv = div} id="topNavDiv" className="topNavDiv" />                            
                        {this.state.showAgreement &&
                            <LicenseAgreementPage
                                onAgree={() => {
                                    this.checkDownloadFile();
                                    this.setState({ showAgreement: false });
                                    return;
                                }}
                                onDismiss={() => {
                                    this.setState({ showAgreement: false });
                                    this.documentToDownload = undefined;
                                }}
                            />}
                        <div ref={(div: HTMLDivElement) => this.bodyDiv = div}>
                            <div data-grid="container" className="context-control" ref={(div: HTMLDivElement) => this.contentDiv = div}>
                                <StatusMessage id={StatusMessageReducer.COMMON_STATUS_NAME} />
                                <a id="mainContent" tabIndex={-1} aria-hidden={true} />
                                <main data-grid="col-12" style={mainContentTopMargin}>
                                    <Routes
                                        previousPaths={this.previousPaths}
                                        searchKeyword={this.state.searchKeyword}
                                        post_logout_redirect_uri={this.getUrlParametters().post_logout_redirect_uri}
                                        isNextGenUIEnabled={this.props.isNextGenUIEnabled}
                                    />
                                </main>
                            </div>
                        </div>
                        <div>
                            {this.getComplianceManagerDisclaimer()}
                        </div>
                        <div ref={(div: HTMLDivElement) => this.footerDiv = div} />
                    </div>
                    :
                    <div>
                        <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" className="loading-panel-full" />
                    </div>
                }
            </div>
        );
    }

    getComplianceManagerDisclaimer() {
        var path = window.location.pathname;
        let complianceMgrShowCondition: boolean = store.getState().applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement
            && store.getState().authentication.isSignedIn;
        return (complianceMgrShowCondition && path && path.match('(/ComplianceManager).*')) ?
            <div className="clearBoth"><div className="ComplianceMgrDisclaimer">{Intl.Get(LocIds.ComplianceManagerDashboard.Disclaimer)}</div></div> : null;
    }

    getUHFContent(): void {
        var uiSelectedCulture = getCookie('lan');

        pageServicesV2Client.getUHFContent(
            uiSelectedCulture, getAdminCode(),
            (response) => {
                var uhf = response.data as UhfInfo;

                var cssIncludes = document.createElement('div');
                cssIncludes.innerHTML = uhf.cssIncludes;
                if ( cssIncludes.innerHTML !== 'undefined') {
                    document.getElementsByTagName('head')[0].appendChild(cssIncludes);
                }

                uhf.javaScriptIncludes.forEach((element: string) => {
                    var jsScript = document.createElement('script');
                    jsScript.setAttribute('async', 'async');
                    jsScript.setAttribute('src', element);
                    document.getElementsByTagName('body')[0].appendChild(jsScript);
                    
                });

                setInnerHtml(this.topNavDiv, uhf.headerHtml);
                setInnerHtml(this.footerDiv, uhf.footerHtml);

                if (window.MeControl) {
                    loadMeControl();
                } else {
                    window.onMeControlReadyToLoad = loadMeControl;
                }
            },
            () => {}
        );
    }
}