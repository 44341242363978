import * as React from 'react';
import { IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import { IBasePickerSuggestionsProps, IBasePicker } from 'office-ui-fabric-react/lib/Pickers';
import { NormalPeoplePicker } from 'office-ui-fabric-react/lib/Pickers';
import { assign } from 'office-ui-fabric-react/lib/Utilities';
import { ComplianceDashboardServiceClient } from '../../App/Services/ComplianceDashboard';
import { ErrorResponse } from '../../App/Services/Models/ErrorResponse';
import { UserPersona } from '../../App/Models/ComplianceDashboard/UserPersona';

const suggestionProps: IBasePickerSuggestionsProps = {
    noResultsFoundText: 'No results found',
    loadingText: 'Loading'
};

const limitedSearchSuggestionProps: IBasePickerSuggestionsProps = assign(suggestionProps);

export interface PeoplePickerCompactProps {
    className?: string;
    onChange?: (v: UserPersona | null) => void;
    itemLimit?: number;
    onChangeMany?: (v: UserPersona[]) => void;
}

export default class PeoplePickerCompact extends React.Component<PeoplePickerCompactProps> {
    picker: IBasePicker<IPersonaProps>;

    constructor(props: PeoplePickerCompactProps) {
        super(props);
        this._returnMostRecentlyUsed.bind(this);
        this._onFilterChangedWithLimit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        return (
            <div>
                <NormalPeoplePicker
                    componentRef={(o: IBasePicker<IPersonaProps>) => { this.picker = o; }}
                    itemLimit={this.props.itemLimit ? this.props.itemLimit : 1}
                    onResolveSuggestions={(filterText: string, currentPersonas: IPersonaProps[]) => this._onFilterChangedWithLimit(filterText, currentPersonas)}
                    onEmptyInputFocus={(currentPersonas: IPersonaProps[]) => this._returnMostRecentlyUsed(currentPersonas)}
                    pickerSuggestionsProps={limitedSearchSuggestionProps}
                    onChange={this.onChange}
                    className={'ms-PeoplePicker'}
                    key={'normal'}
                    inputProps={ 
                        {
                      'aria-label': 'People Picker'
                        }
                     }
                />
            </div>
        );
    }

    _getTextFromItem(persona: IPersonaProps): string {
        return persona.secondaryText as string;
    }

    public focus() {
        this.picker.focus();
    }

    private onChange(v: UserPersona[]) {
        if (this.props.onChange && !this.props.itemLimit) {
            this.props.onChange(v && v.length > 0 ? v[0] : null);
        }
        if (this.props.onChangeMany && this.props.itemLimit) {
            this.props.onChangeMany(v);
        }
    }

    private _onFilterChangedWithLimit(filterText: string, currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> {

        return new Promise((resolve, reject) => {
            ComplianceDashboardServiceClient.getFilteredUsers(
                filterText,
                () => null,
                (response: UserPersona[]) => {
                    response = this.setPersonaDetails(response);
                    resolve(response);
                },
                (response: ErrorResponse) => {
                    reject(response);
                }
            );
        });
    }

    private _returnMostRecentlyUsed(currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> {
        return new Promise((resolve, reject) => {
            ComplianceDashboardServiceClient.getFilteredUsers(
                '',
                () => null,
                (response: UserPersona[]) => {
                    response = this.setPersonaDetails(response);
                    resolve(response);
                },
                (response: ErrorResponse) => {
                    reject(response);
                }
            );
        });
    }

    private setPersonaDetails(personas: UserPersona[]): UserPersona[] {
        personas.map((p, i) => {
            p.secondaryText = p.email;
        });

        return personas;
    }
}
