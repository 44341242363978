import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../App/Services/GlobalizationService';

interface SimpleLongTextComponentProps {
    characterLength?: number;
    text: string;
    showFlag: boolean;
    renderers?: {
        [nodeType: string]: React.ReactType;
    };
    onChange?: (newValue: string) => void;
}

interface SimpleLongTextComponentState {
    displayContent?: boolean;
    anchorLabel?: string;
}

export class SimpleLongText extends React.Component<SimpleLongTextComponentProps, SimpleLongTextComponentState> {
    constructor(props: SimpleLongTextComponentProps) {
        super(props);
        this.state = { displayContent: !this.props.showFlag, anchorLabel: Intl.Get(LocIds.ComplianceManager.ReadMore) };
    }

    handleReadMore() {
        let newDisplayFlag = !this.state.displayContent;
        let displayText = newDisplayFlag ? Intl.Get(LocIds.ComplianceManager.ReadLess) : Intl.Get(LocIds.ComplianceManager.ReadMore);
        this.setState({ displayContent: newDisplayFlag, anchorLabel: displayText });
    }

    render() {
        let summary = this.props.text ? (this.props.characterLength && this.props.text.length > this.props.characterLength) ? this.props.text.substring(0, this.props.characterLength) + '...' : '' : '';
        let text = this.props.text ? this.props.text : '';

        return (
            <span className="longTextContent">
                    <div>
                        <section >
                            <ReactMarkdown
                                source={this.state.displayContent ? text : summary}
                                renderers={this.props.renderers ? this.props.renderers : {}}
                                escapeHtml={false}
                            />
                        </section>

                        {this.props.showFlag && this.props.characterLength && this.props.text.length >= this.props.characterLength ?
                            <a href="#/" className="EllipsisReadMore" style={{color: '#0078D7'}} onClick={(e) => { e.preventDefault(); this.handleReadMore(); }}>
                                {this.state.anchorLabel}
                            </a>
                            : null}
                    </div>
            </span>
        );
    }
}