import { StoreState } from '../../../Store';
import { connect } from 'react-redux';
import PrivacyManagerPage, { PrivacyManagerProps, PrivacyManagerPrivacyOperation, getStateTextFromState, StatusFilter } from '../../Pages/PrivacyPortal/PrivacyManagerPage';
import { IPersonaProps } from 'office-ui-fabric-react';

function mapStateToProps(state: StoreState): PrivacyManagerProps {
    let props: PrivacyManagerProps = {
        privacyOperations: [] as PrivacyManagerPrivacyOperation[],
        exportSuccessMessage:  state.privacyPortalPrivacyManager.exportSuccessMessage,
        pendingCount: state.privacyPortalPrivacyManager.privacyOperations.filter((item) => item.State === StatusFilter.Pending).length,
        completedCount: state.privacyPortalPrivacyManager.privacyOperations.filter((item) => item.State === StatusFilter.Completed).length,
        failedCount: state.privacyPortalPrivacyManager.privacyOperations.filter((item) => item.State === StatusFilter.Failed).length,
        isLoading: state.privacyPortalPrivacyManager.status === 'Requesting'
    };

    for (let privacyOperation of state.privacyPortalPrivacyManager.privacyOperations) {
        let userInfo: IPersonaProps = {} as IPersonaProps;
        let currentState = privacyOperation;
        if (currentState.User) {
            let imageUri: string = currentState.User.image && currentState.User.image.length ? currentState.User.image : '';
            let secondaryText: string = currentState.User.mail && currentState.User.mail.length ? currentState.User.mail : currentState.User.userPrincipalName;
            userInfo = { imageUrl: imageUri, primaryText: currentState.User.displayName, secondaryText: secondaryText };
        }
        let po: PrivacyManagerPrivacyOperation = {
            privacyOperation: privacyOperation,
            user: userInfo,
            exportedFiles: undefined
        };

        let stateText = getStateTextFromState(privacyOperation.State);
        privacyOperation.StateText = stateText ? stateText : '';
        props.privacyOperations.push(po);
    }

    return props;
}

export default connect(mapStateToProps)(PrivacyManagerPage);
