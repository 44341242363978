import * as React from 'react';
import { store } from '../../../Store';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import * as Licensing from '../../Reducers/LicenseManagement';
import * as authHelper from '../../../Common/Utilities/AuthenticationHelper';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';

export interface SignInSignupDialogProps {
    hidden: boolean;
    title: string;
    callBack?: () => void;
    onCancel?: () => void;
    onDismiss?: () => void;
}

export class SignInSignupDialog extends React.Component<SignInSignupDialogProps, Licensing.LicenseManagementState> {
    constructor(props: SignInSignupDialogProps) {
        super(props);
        this.state = store.getState().licenseManagement;
    }

    public render(): JSX.Element {
        var itarEnabled = store.getState().applicationContext.isItarEnabled;
        return (
            <div>
                <Dialog
                    containerClassName="downloadDocumentDialog"
                    hidden={this.props.hidden || this.state.signInProgress}
                    dialogContentProps={{
                        type: DialogType.normal,
                    }}
                    modalProps={{
                        isBlocking: true,
                    }}
                    onDismiss={()=>{if(this.props.onDismiss){this.props.onDismiss()}}}
                >
                <div role="document">
                    <h6 className="signInSignUptitle c-heading-6">
                        {this.props.title}
                    </h6>
                    <div className="f-dialog-scroll">
                        <hr className="c-divider" />
                        <h5 className="c-heading-5 header">
                            {Intl.Get(LocIds.LicenseAgreementPage.SignInSignUpDialogSignInHeader)}
                        </h5>
                        <p className="c-paragraph paragraph">
                            {Intl.Get(LocIds.LicenseAgreementPage.SignInSignUpDialogSignInParagraph)}
                        </p>
                        <div className="buttonDiv">
                            <a 
                                className="c-call-to-action c-glyph" 
                                aria-label={Intl.Get(LocIds.LicenseAgreementPage.SignInButtonAriaLabel)} 
                                onClick={() => {
                                    Licensing.signInStarted();
                                    authHelper.login(store.getState().authentication.context, false);
                                 }} 
                                href="#"
                            ><span>{Intl.Get(LocIds.LicenseAgreementPage.SignInButtonLabel)}</span>
                            </a>
                            {itarEnabled &&
                            <a 
                                className="c-call-to-action c-glyph" 
                                aria-label={Intl.Get(LocIds.LicenseAgreementPage.SignInItarButtonAriaLabel)} 
                                onClick={() => {
                                    Licensing.signInStarted();
                                    authHelper.login(store.getState().authentication.context, true);
                                 }} 
                                href="#"
                            ><span>{Intl.Get(LocIds.LicenseAgreementPage.SignInItarButtonLabel)}</span>
                            </a>}
                        </div>
                        <hr className="c-divider" />
                        <h5 className="c-heading-5 header">
                            {Intl.Get(LocIds.LicenseAgreementPage.SignInSignUpDialogSignUpHeader)}
                        </h5>
                        <p className="c-paragraph paragraph">
                            {Intl.Get(LocIds.LicenseAgreementPage.SignInSignUpDialogSignUpParagraph1)}
                        </p>
                        <p className="c-paragraph paragraph">
                            {Intl.Get(LocIds.LicenseAgreementPage.SignInSignUpDialogSignUpParagraph2)}
                        </p>
                        <div className="buttonDiv">
                            <a 
                                className="c-call-to-action c-glyph" 
                                aria-label={Intl.Get(LocIds.LicenseAgreementPage.SignUpButtonAriaLabel)} 
                                target="_blank"
                                href="https://go.microsoft.com/fwlink/p/?LinkID=698279"
                            ><span>{Intl.Get(LocIds.LicenseAgreementPage.SignUpButtonLabel)}</span>
                            </a>
                        </div>
                        <hr className="c-divider" />
                    </div>
                </div>                
                    <PrimaryButton
                        className="downloadDocCancel"
                        onClick={() => 
                            { 
                                if(this.props.onCancel)
                                {
                                    this.props.onCancel();
                                }
                                else
                                {
                                    Licensing.signInCancelled(); 
                                    if (this.props.callBack) {
                                            this.props.callBack();
                                    } 
                                }
                            }} 
                        text={Intl.Get(LocIds.LicenseAgreementPage.CancelButtonLabel)} 
                    />
                </Dialog>
                </div>
            );
    }
}
