import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { ContextualMenuItemType, IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import { MultiSelectContextualMenu } from '../../../Common/Components/MultiSelectContextualMenu';
import { ButtonTypes, CommandBarButtonFactory } from '../../../Common/Utilities/CommanBarButtonFactory';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { getLocalizedString, getLocalizedValue, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { Validator } from '../../../Common/Utilities/Validator';
import { DocumentVersionInfo } from '../../Models';
import { PanelData, SortFilterDocumentPanelPropertiesData, PageMetadata } from '../../Models/PageInfrastructure';
import { SelectedVocabulary, VocabularyDefinition } from '../../Models/Vocabulary';
import { DocumentServiceClient } from '../../Services/DocumentVersionInfo';
import { Intl } from '../../Services/GlobalizationService';
import { VocabularyServiceClient } from '../../Services/VocabularyService';
import Constants from '../../Utilities/Constants';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
import { BasicPanelProperties } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import AdvancedDocumentTable from '../../Containers/AdvancedDocumentTable';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { pageServicesV2Client } from '../../Services/PageServicesV2';
import { isEmptyString, RichTextLink, renderLink } from '../../../App/Utilities/RenderUtilities';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { getFilteredItems } from '../../Utilities/DocumentHelper';
import './SortFilterDocumentPanel.css';
import TableItem from '../../../App/Models/TableItem';
import TextFieldWrapperNumeric from '../TextFieldWrapperNumeric';
import * as authHelper from '../../../Common/Utilities/AuthenticationHelper';
import { store } from 'src/Store';
import { Link } from 'office-ui-fabric-react';

export interface SortFilterDocumentPanelState {
    clientFilterOptions: IDropdownOption[];
    index: number;
    mustSelectTab: string;
    panelData: PanelData<SortFilterDocumentPanelPropertiesData>;
    panelId: string;
    showEditor: boolean;
}

export interface SortFilterDocumentPanelRedux {
    documentsPending: boolean;
    pagesPending: boolean;
    vocabulariesPending: boolean;
    documentsLoaded: boolean;
    pagesLoaded: boolean;
    vocabulariesLoaded: boolean;
    documents?: DocumentVersionInfo[];
    pages?: PageMetadata[];
    vocabularies?: VocabularyDefinition[];
}

export interface SortFilterDocumentPanelActions {
    fetchVocabularies: () => void;
    saveVocabularies: (vocabularies?: VocabularyDefinition[]) => void;
    requestPanelDocuments: () => void;
    receivedPanelDocuments: (documents?: DocumentVersionInfo[]) => void;
    requestPanelPages: () => void;
    receivedPanelPages: (pages?: PageMetadata[]) => void;
}

export type SortFilterDocumentPanelModel = BasicPanelProperties<SortFilterDocumentPanelPropertiesData> & SortFilterDocumentPanelRedux;

export type SortFilterDocumentPanelProps = SortFilterDocumentPanelModel & SortFilterDocumentPanelActions;

export function getSelectedTabInParameters(queryString: string, panelId: string): string {
    let response = '';

    if (!queryString) { return response; }

    queryString = queryString.substr(1);
    const params = queryString.split('&');
    params.forEach((p) => {
        const contents = p.split('=');
        if (contents.length === 2) {
            if (contents[0] === 'docTab') {
                const values = decodeURIComponent(contents[1]).split('_');
                if (values.length >= 2) {
                    if (values[0] === panelId) {
                        response = values[1];
                        for (let i = 2; i < values.length; i++) {
                            response = response + ' ' + values[i];
                        }
                    }
                }
            }
        }
    });

    return response;
}

export class SortFilterDocumentPanel extends React.Component<
    SortFilterDocumentPanelProps,
    SortFilterDocumentPanelState
> {
    public filterMenuItems: IContextualMenuItem[];
    private groupMenuItems: IContextualMenuItem[] = [];

    private backup: SortFilterDocumentPanelPropertiesData;
    private modifyButton: IContextualMenuItem;
    private saveButton: IContextualMenuItem;
    private discardButton: IContextualMenuItem;
    private deletePanelButton: IContextualMenuItem;
    private movePanelUpButton: IContextualMenuItem;
    private movePanelDownButton: IContextualMenuItem;
    private panelValidator: Validator = new Validator();

    private requestingDocuments: boolean;
    private requestingVocabularies: boolean;
    private requestingPages: boolean;

    constructor(props: SortFilterDocumentPanelProps) {
        super(props);

        this.saveButton = CommandBarButtonFactory.GetButton(ButtonTypes.Save, () => { this.saveChange(); });
        this.discardButton = CommandBarButtonFactory.GetButton(ButtonTypes.RollBack, () => this.revertChanges(), Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel));
        this.modifyButton = CommandBarButtonFactory.GetButton(ButtonTypes.Edit, () => {
            this.resetButtons(true);
            this.setState({ showEditor: true });
        });
        this.deletePanelButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.delete(this.state.panelData), Intl.Get(LocIds.PageAndPanels.DeleteButtonLabel));
        this.movePanelUpButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveUp, () => this.props.moveUp(this.state.panelData), Intl.Get(LocIds.PageAndPanels.MoveUpPanelButtonDescription));
        this.movePanelDownButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveDown, () => this.props.moveDown(this.state.panelData), Intl.Get(LocIds.PageAndPanels.MoveDownPanelButtonDescription));
        this.resetButtons(false);

        this.groupMenuItems = [];
        this.filterMenuItems = [];

        this.state = {
            clientFilterOptions: [],
            index: 0,
            mustSelectTab: getSelectedTabInParameters(window.location.search, props.panel.id),
            panelData: this.props.panel,
            panelId: this.props.panel.id,
            showEditor: false,
        };
        this.backup = getDeepCopy(this.props.panel.panelInfo);
    }

    public UNSAFE_componentWillReceiveProps = (nextProps: SortFilterDocumentPanelProps) => {
        let newState = {
            index: 0,
            panelData: nextProps.panel,
            panelId: nextProps.panel.id,
            clientFilterOptions: [ ...this.state.clientFilterOptions ]
        };
        this.backup = getDeepCopy(nextProps.panel.panelInfo);

        if (JSON.stringify(nextProps) !== JSON.stringify(this.props.vocabularies)) {
            newState = {
                ...newState,
                clientFilterOptions: (nextProps.vocabularies || []).map((vocab): IDropdownOption => {
                    return {
                        key: vocab.name,
                        text: vocab.name,
                        data: { ...vocab },
                        disabled: false
                    };
                })
            };
        }

        this.setState(newState);
    }

    public componentDidMount() {
        this.getDocuments();
        this.getPages();
        this.getVocabularies();
    }

    public render() {

        let { groupingInfo, filteringInfo } = this.state.panelData.panelInfo;
        const { showEditor } = this.state;
        const { isInEditMode, vocabularies } = this.props;

        if (!vocabularies) { return <div />; }

        if (isInEditMode) {
            this.groupMenuItems = [];
            // rebuild filtermenuitems
            this.filterMenuItems = [];
            vocabularies.forEach((element) => {
                this.groupMenuItems.push(
                    {
                        key: element.id,
                        name: element.name,
                        canCheck: true,
                        isChecked: groupingInfo && groupingInfo.findIndex(x => x.vocabulary === element.name) !== -1 ? true : false,
                        onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => this.saveGrouping(ev, item)
                    });
                this.filterMenuItems.push({
                    itemType: ContextualMenuItemType.Header,
                    key: element.id,
                    name: element.name,
                });

                element.choices.forEach((x) => {
                    let flag: boolean = false;

                    if (filteringInfo) {
                        const index = filteringInfo.findIndex((y) => y.vocabulary === element.name);
                        if (index !== -1) {
                            if (filteringInfo[index].choice.findIndex((y) => y === x.id) !== -1) {
                                flag = true;
                            }
                        }
                    }

                    this.filterMenuItems.push({
                        canCheck: true,
                        isChecked: flag,
                        key: x.id,
                        name: getLocalizedValue(x.displayValues, true),
                        onClick: (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => this.saveFiltering(ev, { ...item, name: element.name })
                    });
                });
            });
        }

        let values = [] as { key: string, text: string }[];
        if (this.state.panelData.panelInfo.groupingInfo && this.state.panelData.panelInfo.groupingInfo.length > 0) {
            var firstGroup = this.state.panelData.panelInfo.groupingInfo[0].vocabulary;
            var voca = this.props.vocabularies && this.props.vocabularies.find(v => v.name === firstGroup);
            if (voca) {
                voca.choices.map((item) => values.push({ key: item.id, text: item.displayText && item.displayText.lv ? item.displayText.lv : item.displayValues[0].displayValue }));
            }
        }

        return (
            <div data-grid="col-12" >
                {isInEditMode && (
                    <CommandBar
                        items={[this.modifyButton, this.saveButton, this.discardButton]}
                        farItems={[this.deletePanelButton, this.movePanelUpButton, this.movePanelDownButton]}
                    />
                )}

                <div data-grid="col-12">
                    {this.getActualPanel()}
                </div>

                {(isInEditMode && showEditor) && (
                    <div data-grid="col-12" className="editPanel">
                        <div data-grid="col-5">
                            <div key="input-heading" className="editPanel__control-group">
                                <TextFieldWrapper
                                    value={this.state.panelData.panelInfo.heading ? getLocalizedString(this.state.panelData.panelInfo.heading) || '' : ''}
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                        setDefaultLocalizedString(this.state.panelData.panelInfo, 'heading', newVal.substr(0, Constants.PANEL_HEADING_MAX));
                                        this.setState({ panelData: this.state.panelData });
                                    }}
                                    label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                    required={false}
                                    validator={this.panelValidator}
                                    placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                />
                            </div>

                            <div key="input-subheading" className="editPanel__control-group">
                                <MarkDownEditor
                                    value={this.state.panelData.panelInfo.subHeading ? getLocalizedString(this.state.panelData.panelInfo.subHeading) || '' : ''}
                                    onChange={(newVal) => {
                                        setDefaultLocalizedString(this.state.panelData.panelInfo, 'subHeading', newVal);
                                        this.setState({ panelData: this.state.panelData });
                                    }}
                                    label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                    required={false}
                                    validator={this.panelValidator}
                                    placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                    maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                />
                            </div>
                            <div key="input-hide-description" className="editPanel__control-group">
                                <Checkbox
                                    label={Intl.Get(LocIds.PageAndPanels.HideDescriptionColumn)}
                                    checked={this.state.panelData &&
                                        this.state.panelData.panelInfo &&
                                        this.state.panelData.panelInfo.hideDescription}
                                    onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                        this.setState({
                                            panelData: {
                                                ...this.state.panelData,
                                                panelInfo: {
                                                    ...this.state.panelData.panelInfo,
                                                    hideDescription: isChecked
                                                }
                                            }
                                        });
                                    }}
                                    ariaDescribedBy={'hideDescriptionColumn'}
                                />
                            </div>

                            <div key="input-hide-date" className="editPanel__control-group">
                                <Checkbox
                                    label={Intl.Get(LocIds.PageAndPanels.HideDateColumn)}
                                    checked={this.state.panelData &&
                                        this.state.panelData.panelInfo &&
                                        this.state.panelData.panelInfo.hideDate}
                                    onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                        this.setState({
                                            panelData: {
                                                ...this.state.panelData,
                                                panelInfo: {
                                                    ...this.state.panelData.panelInfo,
                                                    hideDate: isChecked
                                                }
                                            }
                                        });
                                    }}
                                    ariaDescribedBy={'hideDateColumn'}
                                />
                            </div>

                            <div key="input-hide-date-filters" className="editPanel__control-group">
                                <Checkbox
                                    label={Intl.Get(LocIds.PageAndPanels.HideDateFilter)}
                                    checked={this.state.panelData &&
                                        this.state.panelData.panelInfo &&
                                        this.state.panelData.panelInfo.hideDatePicker}
                                    onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                        this.setState({
                                            panelData: {
                                                ...this.state.panelData,
                                                panelInfo: {
                                                    ...this.state.panelData.panelInfo,
                                                    hideDatePicker: isChecked
                                                }
                                            }
                                        });
                                    }}
                                    ariaDescribedBy={'hideDatePicker'}
                                />
                            </div>

                            <div key="input-hide-doctype" className="editPanel__control-group">
                                <Checkbox
                                    label={Intl.Get(LocIds.PageAndPanels.HideDocumentTypeFilter)}
                                    checked={this.state.panelData &&
                                        this.state.panelData.panelInfo &&
                                        this.state.panelData.panelInfo.hideDocType}
                                    onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                        this.setState({
                                            panelData: {
                                                ...this.state.panelData,
                                                panelInfo: {
                                                    ...this.state.panelData.panelInfo,
                                                    hideDocType: isChecked
                                                }
                                            }
                                        });
                                    }}
                                    ariaDescribedBy={'hideDocType'}
                                />
                            </div>

                            <div data-grid="col-12" >
                                <div data-grid="col-5">
                                    <TextFieldWrapper
                                        value={this.state.panelData.panelInfo.callToAction ? getLocalizedString(this.state.panelData.panelInfo.callToAction) : ''}
                                        onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                            setDefaultLocalizedString(this.state.panelData.panelInfo, 'callToAction', newVal);
                                            this.setState({ panelData: this.state.panelData });
                                        }}
                                        label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionLabel)}
                                        required={false}
                                        validator={this.panelValidator}
                                    />
                                    <TextFieldWrapper
                                        value={this.state.panelData.panelInfo.callToActionUrl ? this.state.panelData.panelInfo.callToActionUrl : ''}
                                        onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                            this.state.panelData.panelInfo.callToActionUrl = newVal;
                                            this.setState({ panelData: this.state.panelData });
                                        }}
                                        label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionUrlLabel)}
                                        url={true}
                                        isPage={true}
                                        required={false}
                                        validator={this.panelValidator}
                                    />
                                </div>
                            </div>
                            <div data-grid="col-12">
                                <div className="group-label">
                                    <span className="c-subheading-6">{Intl.Get(LocIds.PageAndPanels.GroupByPrimarySecondaryLabel)}</span>
                                </div>
                                {this.state.panelData.panelInfo.groupingInfo && this.state.panelData.panelInfo.groupingInfo.length < 2 &&
                                    <MultiSelectContextualMenu
                                        text={Intl.Get(LocIds.PageAndPanels.SelectAGroupLabel)}
                                        menuItems={this.groupMenuItems}
                                        style={{ display: 'block', float: 'left', margin: '0px', padding: '0px' }}
                                    />
                                }
                            </div>
                            {this.state.panelData.panelInfo.groupingInfo &&
                                <div data-grid="col-12">
                                    <ul className="c-group f-wrap-items">
                                        {
                                            this.state.panelData.panelInfo.groupingInfo.map((data, index) =>
                                                <li className="c-choice-summary" key={index} style={{ marginTop: '0px' }}>
                                                    <button
                                                        className="c-action-trigger c-glyph glyph-cancel"
                                                        aria-label="Remove"
                                                        onClick={() => {
                                                            if (this.state.panelData.panelInfo.groupingInfo) {
                                                                this.state.panelData.panelInfo.groupingInfo = this.deleteItem(this.state.panelData.panelInfo.groupingInfo, index);
                                                            }
                                                            this.setState({ panelData: this.state.panelData });
                                                        }}
                                                    />
                                                    <span>{data.vocabulary ? data.vocabulary : ''}</span>
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            }
                            <div data-grid="col-12">
                                <Toggle
                                    checked={this.state.panelData.panelInfo.isInViewMode === undefined ? false : this.state.panelData.panelInfo.isInViewMode}
                                    label={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggle)}
                                    onAriaLabel={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOn)}
                                    offAriaLabel={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOff)}
                                    onText={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOn)}
                                    offText={Intl.Get(LocIds.PageAndPanels.UsePrimaryGroupasPivotViewToggleOff)}
                                    onChanged={(value) => this.togglePivot()}
                                />
                            </div>
                            <div data-grid="col-12">
                                <div className="group-label">
                                    <span className="c-subheading-6">First tab of the Primary Group</span>
                                </div>
                                <Dropdown
                                    isDisabled={!this.state.panelData.panelInfo.groupingInfo || !this.state.panelData.panelInfo.groupingInfo.length}
                                    options={values}
                                    defaultSelectedKey={this.state.panelData.panelInfo.firstTabName ? this.state.panelData.panelInfo.firstTabName : undefined}
                                    onChanged={(option) => {
                                        let panelData = this.state.panelData;
                                        panelData.panelInfo.firstTabName = option.key.toString();
                                        this.setState({ panelData: panelData });
                                    }}
                                />
                            </div>                            
                            <div data-grid="col-12" className="editPanel__control-group">
                                <div className="group-label">
                                    <span className="c-subheading-6">Filter documents by properties:</span>
                                </div>

                                <MultiSelectContextualMenu
                                    text={'Select a Filter'}
                                    menuItems={this.filterMenuItems}
                                    style={{ display: 'block', float: 'left', margin: '0px', padding: '0px' }}
                                />
                            </div>
                            {filteringInfo &&
                                <div>
                                {
                                    filteringInfo.map((data, index) =>
                                        <div key={index}>
                                            {index > 0 ? <label>And</label> : ''}
                                            <ul className="c-group f-wrap-items">
                                                <li className="c-choice-summary" style={{ marginTop: '0px' }}>
                                                    <button
                                                        className="c-action-trigger c-glyph glyph-cancel"
                                                        aria-label="Remove"
                                                        onClick={() => {
                                                            let newFilteringInfo = filteringInfo ?  this.deleteItem(filteringInfo.slice(), index) : [];
                                                            this.state.panelData.panelInfo.filteringInfo = newFilteringInfo;
                                                            this.setState({ panelData:  this.state.panelData });
                                                        }}
                                                    />

                                                    <span>{`${data.vocabulary || ''} = ${this.getFilterChoiceDisplayText(data)}`}</span>

                                                    <div style={{paddingLeft: '10px'}}>
                                                        <Checkbox
                                                            label="Hide documents tagged with value [All]"
                                                            checked={this.state.panelData
                                                                && this.state.panelData.panelInfo
                                                                && filteringInfo
                                                                && index >= 0 && index < filteringInfo.length
                                                                && filteringInfo[index].shouldExcludeValueOfAll
                                                            }
                                                            onChange={(ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
                                                                const newState = this.state.panelData.panelInfo.filteringInfo ? this.state.panelData.panelInfo.filteringInfo.slice() : [] ;
                                                                if (newState) {
                                                                    newState[index].shouldExcludeValueOfAll = isChecked;
                                                                }
                                                                this.state.panelData.panelInfo.filteringInfo = newState;
                                                                this.setState({ panelData: this.state.panelData });
                                                            }}
                                                            ariaDescribedBy={'shouldExcludeValueOfAll'}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }
                                </div>
                            }
                            <div data-grid="col-12">
                                <Toggle
                                    checked={this.state.panelData.panelInfo.hidePanelIfNoResult || false}
                                    label={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggle)}
                                    onAriaLabel={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOn)}
                                    offAriaLabel={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOff)}
                                    onText={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOn)}
                                    offText={Intl.Get(LocIds.PageAndPanels.IfNoDocumentsAreReturnedToggleOff)}
                                    onChanged={(value) => {
                                        this.state.panelData.panelInfo.hidePanelIfNoResult = value;
                                        this.setState({ panelData: this.state.panelData });
                                    }}
                                />
                            </div>
                            {vocabularies && (
                                <Dropdown
                                    placeHolder={name}
                                    label="Show Client-Side Filters"
                                    selectedKeys={this.getSelectedClientFilters()}
                                    onChanged={this.onChangeClientFilters}
                                    multiSelect={true}
                                    options={this.state.clientFilterOptions}
                                />
                            )}

                            <div data-grid="col-12">
                                <TextFieldWrapperNumeric
                                    numericValue={this.state.panelData.panelInfo.maxDownloads || Constants.DOCUMENT_DOWNLOAD_MAX}
                                    onChangedNumeric={(newVal: number) => {
                                        this.state.panelData.panelInfo.maxDownloads = newVal;
                                        this.setState({ panelData: this.state.panelData });
                                    }}
                                    label={Intl.Get(LocIds.PageAndPanels.MaxDownloadsLabel)}
                                    required={true}
                                    validator={this.panelValidator}
                                />
                            </div> 
                        </div>
                        <div data-grid="col-5" />
                    </div>
                )}
            </div >
        );
    }

    private getSelectedClientFilters = (): string[] => {
        const { panelData: { panelInfo: { clientFilters }}} = this.state;
        if (!clientFilters) { return []; }

        // "vocabulary" on a SelectedVocabulary is the vocabulary name
        return clientFilters.map((item: SelectedVocabulary) => item.vocabulary );
    }

    private onChangeClientFilters = (item: IDropdownOption, index: number): void => {
        // This is to ensure that the state is updated and the Advanced Document Panel is re-rendered.
        const clientFilters = this.state.panelData.panelInfo.clientFilters ? this.state.panelData.panelInfo.clientFilters.slice() : [];
        let newChoices = clientFilters ? [ ...clientFilters ] : [];

        const found = (
            clientFilters &&
            undefined !== clientFilters.find((filter) => filter.vocabulary === item.data.name)
        );

        if (item.selected) {
            if (!found) {
                newChoices.push({
                    vocabulary: item.data.name,
                    choice: [],
                    choiceDisplay: [],
                    shouldExcludeValueOfAll: false
                });
            }
        } else {
            if (found) {
                newChoices = newChoices.filter((c) => c.vocabulary !== item.data.name);
            }
        }
        this.state.panelData.panelInfo.clientFilters = newChoices;
        this.setState({ panelData: this.state.panelData });

        return;
    }

    private getVocabularies = () => {
        if (
            true === this.requestingVocabularies ||
            true === this.props.vocabulariesPending ||
            true === this.props.vocabulariesLoaded
        ) { return; } // take no operation when we're already loading vocabularies or they're done

        this.requestingVocabularies = true;
        this.props.fetchVocabularies();

        VocabularyServiceClient.listVocabularies(
            () => undefined,
            (r) => {
                this.requestingVocabularies = false;
                this.props.saveVocabularies(r.data);
            },
            () => { this.requestingVocabularies = false; }
        );
    }

    private getDocuments = () => {
        if (
            true === this.requestingDocuments 
            // TODO: Remove these lines also from props, container, redux store, etc.
            // ||
            // true === this.props.documentsPending ||
            // true === this.props.documentsLoaded
        ) { return; } // take no operation when we're already loadingDocument

        this.requestingDocuments = true;
        this.props.requestPanelDocuments();

        DocumentServiceClient.getPanelDocuments(
            this.props.panel,
            (response) => {
                this.requestingDocuments = false;
                this.props.receivedPanelDocuments(response.data as DocumentVersionInfo[]);
            },
            (error) => { this.requestingDocuments = false; }
        );
    }

    private getPages = () => {
        if (
            true === this.requestingPages 
            // TODO: Remove these lines also from props, container, redux store, etc.
            // ||
            // true === this.props.pagesPending ||
            // true === this.props.pagesLoaded
        ) { return; }

        this.requestingPages = true;
        this.props.requestPanelPages();

        pageServicesV2Client.getFilteredPagesMetadata(
            [], // no filters
            (response) => {
                let pages: PageMetadata[] = [];
                if ('2' === response.status.toString().substr(0, 1)) {
                    pages = response.data;
                }

                this.requestingPages = false;
                this.props.receivedPanelPages(pages);
            },
            () => { this.requestingPages = false; }
        );
    }

    private getActualPanel = () => {

        const { 
            heading,
            subHeading,
            clientFilters,
            groupingInfo,
            filteringInfo,
            hideDescription,
            hideDate,
            hideDatePicker,
            hideDocType,
            callToAction,
            callToActionUrl,
            hidePanelIfNoResult,
            maxDownloads
        } = this.state.panelData.panelInfo;

        const { vocabularies, documents, pages, pagesLoaded, documentsLoaded, vocabulariesLoaded, isInEditMode, parentId } = this.props;

        // While fetching the docs, show the spinner, but it will still show sidebar editor when in edit mode
        if (true !== pagesLoaded || true !== documentsLoaded || true !== vocabulariesLoaded) {
            return (
                <div style={{ paddingTop: '35px' }}>
                    <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingDocumentsLabel)} ariaLive="assertive" />
                </div>
            );
        }
 
        let items = documents && documents.map((doc) => TableItem.fromDocument(doc));
        if (items && pages) {
            items = items.concat(pages && pages.map((page) => TableItem.fromPage(page)));
        }

        let filteredItems = getFilteredItems(items !== undefined ? items : [] as TableItem[], filteringInfo, this.props.vocabularies);

        // Do not show the panel if no result to display and hidePanelIfNoResult is true.
        var noResult = filteredItems.length === 0;
        if (hidePanelIfNoResult && noResult && !isInEditMode) {
            return <div />;
        }
        if (noResult && !isInEditMode) {
            const onClickSignIn = (e) => {
                if (e.target.tagName === 'A') {
                    authHelper.login(store.getState().authentication.context, false);
                }
            }
            let isUserSignedIn = store.getState().authentication.isSignedIn;
            return isUserSignedIn
                ? <h3 className='no_doc_message'>{Intl.Get(LocIds.PageAndPanels.NoDocumentsVNext)}</h3>
                : <h3 className='no_doc_message'>
                    {Intl.Get(LocIds.PageAndPanels.NoDocumentsVNext)}
                    <span onClick={onClickSignIn} className='no_doc_message_link'>
                        {Intl.GetHTML(LocIds.PageAndPanels.NoDocumentsVNextSignIn)}
                    </span>
                  </h3>
        }
        
        return (
            <div data-panel-type="Advanced Documents"
                style={{ padding: '24px 12px 0' }}
                >
                {heading && !isEmptyString(getLocalizedString(heading)) &&
                    <div className="stp_advanced-docs-header">
                        <h2 className="c-heading-2 clearfix">{getLocalizedString(heading)}</h2>
                        { subHeading && !isEmptyString(getLocalizedString(subHeading)) && 
                            <ReactMarkdown 
                                className="c-paragraph-1"
                                source={subHeading ? getLocalizedString(subHeading) || '' : ''}
                                renderers={{ link: RichTextLink }}
                                skipHtml={true}
                            />
                        }
                    </div>
                }
                {(!!vocabularies && !!documents && !!pages) && (
                    <AdvancedDocumentTable
                        caption= {getLocalizedString(heading)}
                        parentId={parentId}
                        panelId={this.props.panel.id}
                        groupingInfo={groupingInfo}
                        filteringInfo={filteringInfo}
                        clientFilters={clientFilters}
                        vocabularies={vocabularies}
                        documents={documents}
                        pages={pages}
                        hideDescription={hideDescription || false}
                        hideDate={hideDate || false}
                        hideDatePicker={hideDatePicker || false}
                        hideDocType={true}
                        hideSettings={true}
                        hideRemoveFromLibrary={true}
                        maxDownloads={maxDownloads || Constants.DOCUMENT_DOWNLOAD_MAX}
                        isInViewMode={this.props.panel.panelInfo.isInViewMode}
                        firstTabName={this.props.panel.panelInfo.firstTabName}
                    />
                )}

                {!isEmptyString(getLocalizedString(callToAction)) && (
                    <div
                        data-grid="col-12"
                        style={{ paddingTop: '22px' }}
                    >
                        {renderLink(callToActionUrl, getLocalizedString(callToAction), 'c-call-to-action c-glyph f-lightweight')}
                    </div>
                )}
            </div>
        );
    }

    private saveChange = () => {
        if (!this.panelValidator.validate()) {
            return;
        }
        this.backup = getDeepCopy(this.state.panelData.panelInfo);
        this.props.panel.panelInfo = getDeepCopy(this.backup);
        this.props.makeDirty();
        this.setState({ panelData: this.props.panel, showEditor: false });
        this.getDocuments();
        this.resetButtons(false);
    }

    private revertChanges = () => {
        this.props.panel.panelInfo = getDeepCopy(this.backup);
        this.setState({ panelData: this.props.panel, showEditor: false });
        this.resetButtons(false);
    }

    private resetButtons = (showEditor: boolean) => {
        this.modifyButton.disabled = showEditor;
        this.saveButton.disabled = !showEditor;
        this.discardButton.disabled = !showEditor;
    }

    private saveGrouping(ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) {
        if (!this.state.panelData) { return; }

        let info: SelectedVocabulary = this.getVocabularySelection(item);

        // This is to ensure that the state is updated and the Advanced Document Panel is re-rendered.
        let groupingInfo: SelectedVocabulary[] = this.state.panelData.panelInfo.groupingInfo ? this.state.panelData.panelInfo.groupingInfo.slice() : [];

        if (groupingInfo.findIndex(groupinfo => groupinfo.vocabulary === info.vocabulary) < 0) {
            groupingInfo.push(info);
        } else {
            groupingInfo.filter(groupInfo => groupInfo.vocabulary !== info.vocabulary);
        }

        this.state.panelData.panelInfo.groupingInfo = groupingInfo;
        this.setState({ panelData: this.state.panelData });
    }

    private saveFiltering = (ev: React.MouseEvent<HTMLButtonElement>, item: IContextualMenuItem) => {
        if (!this.state.panelData) { return; }

        // This is to ensure that the state is updated and the Advanced Document Panel is re-rendered.
        let filteringInfo = this.state.panelData.panelInfo.filteringInfo ? this.state.panelData.panelInfo.filteringInfo.slice() : [];

        const info: SelectedVocabulary = this.getVocabularySelection(item, true);
        const selectedVocabulary = (this.props.vocabularies || []).find((v) => v.name === info.vocabulary);

        if (selectedVocabulary) {
            const choice = selectedVocabulary.choices.find((c) => c.id === item.key);
            if (choice) {
                info.choiceDisplay = [getLocalizedValue(choice.displayValues, false)];
        }
        }

        const index = filteringInfo.findIndex((filter) => filter.vocabulary === info.vocabulary);

        if (index === -1) {
            // add a new filter.
            filteringInfo.push(info);
        } else {
            // update an existing filter with new terms
            const i = filteringInfo[index].choice.findIndex((filterChoice) => filterChoice === info.choice[0]);
            if (i === -1) {
                filteringInfo[index].choice.push(info.choice[0]);
                filteringInfo[index].choiceDisplay.push(info.choiceDisplay[0]);
            } else {
                const choice = filteringInfo[index].choice.filter((filterChoice) => filterChoice !== info.choice[0]);
                filteringInfo[index].choice = choice;
                const displayChoice = filteringInfo[index].choiceDisplay.filter((filterChoice) => filterChoice !== info.choiceDisplay[0]);
                filteringInfo[index].choiceDisplay = displayChoice;
                if (filteringInfo[index].choice.length <= 0) {
                    const filter = filteringInfo.filter((filterChoice) => filterChoice.vocabulary !== info.vocabulary);
                    filteringInfo = filter;
                }
            }
        }

        this.state.panelData.panelInfo.filteringInfo = filteringInfo;
        this.setState({ panelData: this.state.panelData });
    }

    private getVocabularySelection(item: IContextualMenuItem, shouldExcludeValueOfAll: boolean = false): SelectedVocabulary {
        const info: SelectedVocabulary = { choice: [], choiceDisplay: [], vocabulary: '', shouldExcludeValueOfAll: shouldExcludeValueOfAll };
        info.vocabulary = item.name ? item.name : '';
        if (info.choice) {
            info.choice.push(item.key);
        }
        if (info.choiceDisplay) {
            info.choiceDisplay.push(item.name ? item.name : '');
        }
        return info;
    }

    private deleteItem(input: SelectedVocabulary[] | undefined, index: number): SelectedVocabulary[] {
        const array: SelectedVocabulary[] = [];
        if (!input) { return array; }

        input.forEach((data, i) => {
            if (i !== index) {
                array.push(data);
            }
        });
        return array;
    }

    private getFilterChoiceDisplayText(input: SelectedVocabulary): string {
        let ret: string = '';
        if (input.choiceDisplay) {
            input.choiceDisplay.forEach((x, i) => ret = ret.concat(('\'').concat(x).concat('\'').concat(i < input.choiceDisplay.length - 1 ? ' or ' : '')));
        }
        return ret;
    }

    private togglePivot() {
        if (this.state.panelData.panelInfo.isInViewMode !== undefined) {
            this.state.panelData.panelInfo.isInViewMode = !this.state.panelData.panelInfo.isInViewMode;
        } else {
            this.state.panelData.panelInfo.isInViewMode = true;
        }
        this.setState({ panelData: this.state.panelData });
    }
}

export default SortFilterDocumentPanel;