import { CustomerImplementationPriority } from './CustomerImplementationPriority';
import { ImplementationStatus } from './ImplementationStatus';
import { FrameworkControlAttachment } from './FrameworkControlAttachment';
import { UserPersona } from './UserPersona';

export class FrameworkControlAssignment {
    public attachments: FrameworkControlAttachment[];
    public frameworkControlId: string;
    public generalNotes: string;
    public implementationNotes: string;
    public implementationStatus: ImplementationStatus;
    public priority: CustomerImplementationPriority | null;
    public isEmailRequested: boolean;
    public whenAssigned: Date;
    public whenDue: Date;
    public whenEmailSent: Date;
    public whoAssigned: string;
    public whoAssignedTo: UserPersona | null;
    public whoTested: string;
    public whenImplemented: Date;    
    public previousImplementationStatus: ImplementationStatus;
}
