import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton, SelectableOptionMenuItemType, ComboBox, IComboBoxOption, IComboBox } from 'office-ui-fabric-react/lib/';
import { getLocalizedString, setDefaultLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { RichHeadingPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { getThemeOptions, getThemeClassName } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { ImagePickerDialog } from '../../Pages/Admin/ImageManager/ImagePickerDialog';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import * as imageHelper from '../../../Common/Utilities/ImageHelper';
import Constants from '../../Utilities/Constants';

export class RichHeadingPanel extends BasePanel<RichHeadingPanelPropertiesData> {
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<RichHeadingPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getThemeOptions());
    }

    getActualPanel() {
        document.title = getLocalizedString(this.state.panelData.heading);

        return (
            <div data-panel-type="Rich Heading">
                <div style={{ marginTop: 0 }} className={getThemeClassName(Theme[this.state.panelData.theme || 'DarkImage'])} data-grid="col-12">
                    {!this.state.panelData.theme || this.state.panelData.theme === 'DarkImage' ?
                        <picture className="c-image">
                            <img
                                aria-hidden={true}
                                className="lazyloaded" 
                                src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                data-src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                alt={this.state.panelData.imageTitle} 
                            />
                        </picture>
                        :
                        <div className="c-logo">
                            <img
                                aria-hidden={true}
                                style={{ width: '200px' }}
                                className="lazyloaded" 
                                src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                data-src={imageHelper.getImageUrl(this.state.panelData.imageId)}
                                alt={this.state.panelData.imageTitle} 
                            />
                        </div>
                    }
                    <div>
                        <div role="heading" aria-level={1} className="c-heading">{getLocalizedString(this.state.panelData.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</div>
                        <p className="c-paragraph-3">{getLocalizedString(this.state.panelData.subHeading) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}</p>
                    </div>
                </div>
            </div >
        );
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode ?
                    <div className="row" style={{marginLeft: '0px'}}>
                        {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                    <div className="col">
                        {this.getActualPanel()}                    
                            {this.state.showEditor ?
                                <div className="editPanel">
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'DarkImage'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={true}
                                                disabled={true}
                                                value={this.state.panelData.imageTitle || ''}
                                                label={Intl.Get(LocIds.PageAndPanels.ImageLabel)}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                        <div data-grid="col-2" style={{ padding: '29px 0 0 10px' }}>
                                            <PrimaryButton onClick={() => this.onImagePicker()} text={Intl.Get(LocIds.PageAndPanels.ChooseImageLabel)} />
                                            <ImagePickerDialog
                                                hidden={!this.state.showImagePicker}
                                                onImageSelection={(images) => {
                                                    if (images && images.length) {
                                                        this.state.panelData.imageId = images[0].url;
                                                        this.state.panelData.imageTitle = images[0].title;
                                                        this.setState({ panelData: this.state.panelData, showImagePicker: false });
                                                    } else {
                                                        this.setState({ showImagePicker: false });
                                                    }
                                                }}
                                                onDismiss={() => this.setState({ showImagePicker: false })}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={true}
                                                value={getLocalizedString(this.state.panelData.heading)}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'heading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                required={true}
                                                multiline={true}
                                                value={getLocalizedString(this.state.panelData.subHeading)}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (newVal.length > Constants.PANEL_DESCRIPTION_MAX) {
                                                        newVal = newVal.substr(0, Constants.PANEL_DESCRIPTION_MAX);
                                                    }
                                                    setDefaultLocalizedString(this.state.panelData, 'subHeading', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '6px' }} />
                                    <PrimaryButton
                                        onClick={() => {
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                </div>
                                :
                                null}
                        </div>
                    </div>
                    :
                    this.getActualPanel()
                }
            </div>
        );
    }
}
