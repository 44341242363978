import './DocumentTypeDropdown.css';

import * as React from 'react';

import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react/lib/Dropdown';

import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';

// Define the properties that this component accepts as input and drives its behavior.
export interface DocumentDateDropdownModel {
    dateRange: number;
}

// Define the events that this component can raise.
export interface DocumentDateDropdownEvents {
    onSelectionChanged?: (selectedChoice: number) => void;
}

export interface DocumentDateDropdownState {
    options: IDropdownOption[];
}

// Define the merged set of properties and events that are exposed by this component.
export type DocumentDateDropdownProps = DocumentDateDropdownModel & DocumentDateDropdownEvents & IDropdownProps;

export class DocumentDateDropdown extends React.Component<
    DocumentDateDropdownProps,
    DocumentDateDropdownState
> {
    constructor(props: DocumentDateDropdownProps) {
        super(props);

        this.state = {
            options: []
        };
    }

    public onChangeMultiSelect = (item: IDropdownOption, index: number): void => {
        if (this.props.onSelectionChanged) { this.props.onSelectionChanged(Number.parseInt(item.key.toString()) || 0); }
    }

    render() {
        const { dateRange, ...props } = this.props;

        return (
            <Dropdown
                placeHolder={Intl.Get(LocIds.PageAndPanels.DocumentDatesLabel)}
                label=""
                selectedKey={dateRange}
                ariaLabel={Intl.Get(LocIds.PageAndPanels.DocumentDatesLabel)}
                title={Intl.Get(LocIds.PageAndPanels.DocumentDatesLabel)}
                onChanged={this.onChangeMultiSelect}
                onRenderPlaceHolder={this._onRenderPlaceHolder}
                onRenderTitle={this._onRenderTitle}
                onRenderOption={this._onRenderOption}
                multiSelect={false}
                role="combobox"
                {...props}
            />
        );
    }

    private _onRenderOption = (option: IDropdownOption): JSX.Element => {
        return (
            <div className="dropdownExample-option">
                <span>{option.text}</span>
            </div>
        );
    }

    private _onRenderPlaceHolder = (props: IDropdownProps): JSX.Element => {
        return this._renderName();
    }

    private _onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        return this._renderName();
    }

    private _renderName = (): JSX.Element => {
        return (
            <div className="dropdown-documentType">
                <span>{Intl.Get(LocIds.PageAndPanels.DocumentDatesLabel)}</span>
            </div>
        );
    }
}

export default DocumentDateDropdown;
