import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { PageMetadata, PanelData } from '../../../Models/PageInfrastructure';
import { switchCultureToEnglish, Page, PageStatus, PageRedirectType } from '../../../Utilities/PageInfrastructure/PagesHelper';
import { PrimaryButton, DefaultButton, DialogFooter, Link, Dialog, DialogType } from 'office-ui-fabric-react/lib/';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import { ConfirmationDialog } from '../../../Components/ModelEditor/ConfirmationDialog';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PanelSelectionHelperFactory } from '../../../Utilities/PageInfrastructure/PanelsHelper';
import { PanelPropertiesDataBase } from '../../../Models/PageInfrastructure/PanelPropertiesData/';
import { PanelCollection } from '../../../Components/PageInfrastructure/PanelsCollection';
import { CommandBarButtonFactory, ButtonTypes, getEditorConfirmationDialogText } from '../../../../Common/Utilities/CommanBarButtonFactory';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { pageServicesV2Client } from '../../../Services/PageServicesV2';
import { AuthorizationInfo, AuthorizationActions, AuthorizationScope, FlightMembership } from '../../../../Common/Utilities/AuthorizationInfo';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { LocalizationIds as LocIds } from '../../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../../Services/GlobalizationService';
import { AvailableCulture } from '../../../Models/Settings/AvailableCulture';
import { PermissionsSelector } from '../../../Components/PermissionsSelector';
import { stringToDateTime } from '../../../../Common/Utilities/DateHelper';
import { ErrorPage } from '../../../Pages/ErrorPage';
import NonlocalizableStringConstants from '../../../../Common/Utilities/NonLocalizableStringConstants';
import './DynamicPage.css';
import { TelemetryClient } from '../../../../Common/Utilities/Telemetry';
import { VocabularyDefinition } from '../../../Models/Vocabulary';
import { store } from '../../../../Store';
import { VocabularyServiceClient } from '../../../Services/VocabularyService';
import { saveVocabulary } from '../../../Reducers/VocabulariesManagement';
import * as StatusMessageReducer from '../../../Reducers/StatusMessageReducer';
import { getDeepCopy } from '../../../Utilities/DeepCopyHelper';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { ModelWithVocabularySelectionsEditor, StpContentType } from '../../../Components/ModelWithVocabularySelectionsEditor';
import { getLocalizedString, setDefaultLocalizedString } from '../../../../Common/Utilities/LocalizationHelper';
import { TextFieldWrapper } from '../../../Components/TextFieldWrapper';
import { Validator } from '../../../../Common/Utilities/Validator';
import { HelmetWrapper } from 'src/App/Components/HelmetWrapper';
import { userInfo } from 'os';

let LabelText = NonlocalizableStringConstants;
let telemetry = TelemetryClient;

// If isInEditMode is true, then pageId and id will has value: only admins can access this component
// Else only pageUrl will has value: normal users can access this component
export interface DynamicPageProps {
    pageUrl?: string;
    pageId?: string;
    isInEditMode: boolean;
    userName?: string;
    availableCulture?: AvailableCulture[];
    isNextGenUIEnabled?: boolean;
}

interface DynamicPageState {
    page: Page;
    pageVersions: PageMetadata[];
    vocabularyDefinitions: VocabularyDefinition[];
    isLoadingPageData: boolean;
    isDirty: boolean;
    isEditing: boolean;
    // Present the current text in required flight text box, avoid joining all the values with comman all the time
    flightValue: string;
    confirmationDialogType: ButtonTypes;
    pageRedirectType: PageRedirectType;
    isValid: boolean;
}

export class DynamicPage extends React.Component<DynamicPageProps, DynamicPageState> {
    private _isMounted: boolean;
    private editButton: IContextualMenuItem;
    private undoButton: IContextualMenuItem;
    private saveButton: IContextualMenuItem;
    private sendForApprovalButton: IContextualMenuItem;
    private approvePublishButton: IContextualMenuItem;
    private rejectPublishButton: IContextualMenuItem;
    private forcePublishButton: IContextualMenuItem;
    private viewHistoryButton: IContextualMenuItem;
    private restoreButton: IContextualMenuItem;
    private manageVocabularyButton: IContextualMenuItem;
    private exitButton: IContextualMenuItem;
    private uiValidator: Validator = new Validator();

    private confirmationDialogText: Array<{ title: string, subText: string }>;
    private pageCache: Page;
    private pageToRestore: Page | undefined;

    constructor(props: DynamicPageProps) {
        super(props);
        if (this.props.isInEditMode || !this.props.pageUrl) {
            switchCultureToEnglish();
        }
        this.scrollToPageTop();
        this.confirmationDialogText = getEditorConfirmationDialogText();
        this.initializeCommandBarButton();
        this.state = {
            page: Page.getNewPage(),
            isLoadingPageData: false,
            pageVersions: [],
            vocabularyDefinitions: [],
            isDirty: false,
            isEditing: false,
            flightValue: '',
            confirmationDialogType: ButtonTypes.None,
            pageRedirectType: PageRedirectType.None,
            isValid: true,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps: DynamicPageProps) {
        // When user access the page by url and the url changes, page should refresh
        if (!newProps.isInEditMode && newProps.pageUrl && newProps.pageUrl !== this.props.pageUrl) {
            telemetry.trackPageView(newProps.pageUrl);
            this.scrollToPageTop();
            this.getPublicPageByUrlName(newProps.pageUrl);
        }
    }

    render() {
        const errorInfo = store.getState().statusMessageManagement.errorInfo;

        // When user tries to view page without the required vocabulary, getPage will return null
        if (!this.state.isEditing && this.state.page === null) {
            return <ErrorPage />;
        }

        // If URL points to non existent page return to homepage
        if (+errorInfo.statusCode === 400) {
            return <Redirect to="/" />
        }

        return (
            <div>
                {this.state.isLoadingPageData && <Spinner className="loading-panel-full" size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingLabel)} ariaLive="assertive" />}
                {this.renderMessageBar()}
                {this.renderPageEditor()}
                {this.renderActualPage()}
                {this.renderTaggingPanel()}
            </div>
        );
    }

    componentDidMount() {
        this._isMounted = true;
        // TODO: Avoid call store in this component.
        // Vocabulary may need to be loaded globally in one place after application context
        store.subscribe(() => {
            const state = store.getState();
            const storeVocabulary = state.vocabulariesManagement;

            if (storeVocabulary.status === 'Init') {
                store.dispatch({ type: 'VOCABULARIES_FETCHING' });
                VocabularyServiceClient.listVocabularies(
                    () => { return; },
                    (r) => {
                        store.dispatch(saveVocabulary(r.data));
                    },
                    () => { return; });
            } else if (storeVocabulary.status === 'Finished' && this._isMounted) {
                this.setState({ vocabularyDefinitions: storeVocabulary.vocabularyDefinitions });
            }
        });

        if (this.props.pageUrl) {
            telemetry.trackPageView(this.props.pageUrl);
        } else if (this.props.pageId) {
            telemetry.trackPageView(this.props.pageId);
        }

        if (this.props.isInEditMode || this.props.pageId) {
            this.loadPagesData();
        } else {
            this.getPublicPageByUrlName(this.props.pageUrl);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillMount() {
        this.setState({ isValid: this.uiValidator.validate() });
    }

    private loadPagesData(): void {
        if (this.props.isInEditMode) {
            this.getVNextVersionByPageId();
            this.getPageHistoryVersions();
        } else {
            this.getPublicPageByPageId();
        }
    }

    private initializeCommandBarButton(): void {
        this.editButton = CommandBarButtonFactory.GetButton(ButtonTypes.Edit, () => this.onEdit(), LabelText.STANDARD_BUTTONS_EDIT);
        this.undoButton = CommandBarButtonFactory.GetButton(ButtonTypes.Undo, () => this.showConfirmationDialog(ButtonTypes.Undo), LabelText.STANDARD_BUTTONS_UNDO);
        this.saveButton = CommandBarButtonFactory.GetButton(ButtonTypes.Save, () => this.onSave(), LabelText.STANDARD_BUTTONS_SAVE);
        this.sendForApprovalButton = CommandBarButtonFactory.GetButton(ButtonTypes.Publish, () => this.showConfirmationDialog(ButtonTypes.Publish), LabelText.STANDARD_BUTTONS_SENDFORAPPROVAL);
        this.approvePublishButton = CommandBarButtonFactory.GetButton(ButtonTypes.ApprovePublish, () => this.showConfirmationDialog(ButtonTypes.ApprovePublish), LabelText.STANDARD_BUTTONS_APPROVEPUBLISH);
        this.rejectPublishButton = CommandBarButtonFactory.GetButton(ButtonTypes.RejectPublish, () => this.showConfirmationDialog(ButtonTypes.RejectPublish), LabelText.STANDARD_BUTTONS_REJECTPUBLISH);
        this.forcePublishButton = CommandBarButtonFactory.GetButton(ButtonTypes.ForcePublish, () => this.showConfirmationDialog(ButtonTypes.ForcePublish), LabelText.STANDARD_BUTTONS_FORCEPUBLISH);
        this.viewHistoryButton = CommandBarButtonFactory.GetButton(ButtonTypes.History, () => null, LabelText.STANDARD_BUTTONS_HISTORY);
        this.restoreButton = CommandBarButtonFactory.GetButton(ButtonTypes.RollBack, () => this.showConfirmationDialog(ButtonTypes.RollBack), LabelText.STANDARD_BUTTONS_RESTORE);
        this.manageVocabularyButton = CommandBarButtonFactory.GetButton(ButtonTypes.Tagging, () => this.showPageTaggingPanel(), LabelText.STANDARD_BUTTONS_TAGGING);
        this.exitButton = CommandBarButtonFactory.GetButton(ButtonTypes.Exit, () => this.onExit(true), LabelText.STANDARD_BUTTONS_EXIT);
        this.setDefaultCommandBarButtonStatus();
    }

    private setDefaultCommandBarButtonStatus(): void {
        this.getCommandBarButtons().forEach(button => button.disabled = true);
    }

    private updateCommanBarButtonStatus(): void {
        let hasEditPermission = AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.Pages, [{ actions: [AuthorizationActions.Modify] }]);
        let hasApprovePermission = AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.Pages, [{ actions: [AuthorizationActions.Approve] }]);
        this.editButton.disabled = !hasEditPermission || !this.props.isInEditMode || this.state.isEditing;
        this.undoButton.disabled = !hasEditPermission || !this.state.isDirty;
        this.saveButton.disabled = !hasEditPermission || !this.state.isDirty || !this.state.isValid || !this.verifyInput();
        this.sendForApprovalButton.disabled = !hasEditPermission || this.state.isDirty || this.state.page.status !== PageStatus.Saved || this.state.page.deletePending || this.state.pageVersions.findIndex(p => p.id === this.state.page.id) < 0;
        this.approvePublishButton.disabled = !hasApprovePermission || this.state.isDirty || this.state.page.status !== PageStatus.PendingApproval || this.state.page.whoLastModified === this.props.userName;
        this.rejectPublishButton.disabled = !hasApprovePermission || this.state.isDirty || this.state.page.status !== PageStatus.PendingApproval || this.state.page.whoLastModified === this.props.userName;
        this.forcePublishButton.disabled = !hasApprovePermission || this.state.isDirty || this.state.page.status !== PageStatus.PendingLocalization || this.state.page.whoLastModified === this.props.userName;
        this.viewHistoryButton.disabled = this.state.isEditing || this.state.pageVersions.length <= 0;
        this.viewHistoryButton.subMenuProps = this.getPageHistorySubMenu();
        this.restoreButton.disabled = this.state.isEditing || this.state.page.status !== PageStatus.Live && this.state.page.status !== PageStatus.History;
        this.manageVocabularyButton.disabled = !this.state.isEditing;
        this.exitButton.disabled = !this.state.isEditing;
    }

    private renderTaggingPanel(): JSX.Element | boolean {
        return (this.state.confirmationDialogType === ButtonTypes.Tagging) && (
            <Panel
                onDismiss={() => this.cancelTagging()}
                isOpen={true}
                type={PanelType.custom}
                customWidth="800px"
                headerText={'Tagging...'}
            >
                <div style={{ overflowY: 'scroll' }}>
                    <div className="ms-Grid">
                        <ModelWithVocabularySelectionsEditor
                            model={this.state.page}
                            stpContentType={StpContentType.Page}
                            isPublicContent={false}
                            onChange={() => this.makeDirty()}
                            displayAny={true}
                            vocabularies={this.state.vocabularyDefinitions}
                        />
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => this.setState({ confirmationDialogType: ButtonTypes.None })}
                        text={Intl.Get(LocIds.DocumentManagmentPage.SaveButtonLabel)}
                        disabled={!this.state.isDirty}
                    />
                    <DefaultButton name={Intl.Get(LocIds.DocumentManagmentPage.CancelButtonLabel)} onClick={() => this.cancelTagging()} text={Intl.Get(LocIds.DocumentManagmentPage.CancelButtonLabel)} />
                </DialogFooter>
            </Panel>
        );
    }

    // The page editor(edit mode) is only available for admin user, so strings don't need to be localized based on the design 
    private renderPageEditor(): JSX.Element | boolean {
        this.updateCommanBarButtonStatus();
        return this.props.isInEditMode && (
            <section className="col-12 pad-12x">
                {this.renderRedirectComponent()}
                <CommandBar items={this.getCommandBarButtons()} farItems={this.getCommandBarFarButtons()} />
                {this.renderConfirmationDialog()}
                {this.state.isEditing && <div className="pageInfoEditor">
                    <div className="row">
                        <div className="col-3">
                            <TextFieldWrapper
                                label={Intl.Get(LocIds.HomePage.TitleColumnLabel)}
                                value={getLocalizedString(this.state.page.title)}
                                required={true}
                                placeholder={Intl.Get(LocIds.HomePage.TitlePlaceHolder)}
                                ariaLabel={Intl.Get(LocIds.HomePage.TitlePlaceHolder)}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                    this.onTitleChanged(newText);
                                }}
                                validator={this.uiValidator}
                            />
                        </div>
                        <div className="col-6">
                            <TextFieldWrapper
                                label={Intl.Get(LocIds.HomePage.DescriptionColumnLabel)}
                                value={getLocalizedString(this.state.page.description)}
                                required={true}
                                placeholder={Intl.Get(LocIds.HomePage.DescriptionPlaceHolder)}
                                ariaLabel={Intl.Get(LocIds.HomePage.DescriptionPlaceHolder)}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                    this.onDescriptionChanged(newText);
                                }}
                                validator={this.uiValidator}
                            />
                        </div>
                        <div className="col-3">
                            <TextFieldWrapper
                                label={Intl.Get(LocIds.HomePage.OwnerColumnLabel)}
                                value={this.state.page.owner}
                                required={true}
                                fieldType='email'
                                placeholder={Intl.Get(LocIds.HomePage.OwnerPlaceHolder)}
                                ariaLabel={Intl.Get(LocIds.HomePage.OwnerPlaceHolder)}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                    this.onOwnerChanged(newText);
                                }}
                                validator={this.uiValidator}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <TextField
                                value={this.state.page.urlName || ''}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => this.onUrlChanged(newVal)}
                                label="URL Name"
                                disabled={this.state.pageVersions.findIndex(p => p.status === PageStatus.Live) >= 0}
                                errorMessage={this.state.pageVersions.findIndex(p => p.status === PageStatus.Live) >= 0 ? 'Url Name of page with public version can not be modified' : ''}
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                value={this.state.flightValue}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => this.onRequiredFlightChanged(newVal)}
                                label="Required Flights"
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                value={this.state.page.scope || ''}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => this.onPageStringPropertyChanged('scope', newVal)}
                                label="Scope"
                                onGetErrorMessage={(value) => {
                                    let defaultScope = this.state.page.scope !== null ? this.state.page.scope : '';
                                    let hasScope: boolean = (defaultScope.trim() !== '');
                                    let permissionCount = this.state.page.requiredPermissions && this.state.page.requiredPermissions.actions ? this.state.page.requiredPermissions.actions.length : 0;
                                    let scopeValid: boolean = hasScope && permissionCount > 0 || !hasScope && permissionCount === 0;
                                    return scopeValid ? '' : permissionCount > 0 ? 'If scope is empty, permissions should be empty as well.' : 'At least one permission should be selected.';
                                }}
                            />
                        </div>
                        <div className="col-3">
                            <PermissionsSelector
                                selectedPermissions={this.state.page.requiredPermissions && this.state.page.requiredPermissions.actions ? this.state.page.requiredPermissions.actions : undefined}
                                onPermissionsSelectionChanged={(selectedPermissions) => this.onPermissionChanged(selectedPermissions)}
                            />
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-3">
                            <TextFieldWrapper
                                label="Search Key Word"
                                value={getLocalizedString(this.state.page.keyword)}
                                placeholder="Search Key Word"
                                ariaLabel="Search Key Word"
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newText: string) => {
                                    this.onKeyWordChanged(newText);
                                }}
                                validator={this.uiValidator}
                            />
                        </div>
                        <div className="col-9">
                            <TextField
                                value={this.state.page.redirectFrom ? this.state.page.redirectFrom.join(',') : ''}
                                onChanged={(newVal) => this.onRedirectFromChanged(newVal)}
                                label="Redirect From"
                                placeholder="Page urls that redirect to this page."
                            />
                        </div>
                    </div>
                </div>}
            </section>
        );
    }

    private renderRedirectComponent(): JSX.Element | boolean {
        let redirectUrl: string = this.state.pageRedirectType === PageRedirectType.PageList
            ? '/Pages/All'
            : `/AdminPage/EditPage/${this.state.page.pageId}`;
        return this.props.isInEditMode && this.state.pageRedirectType !== PageRedirectType.None && (
            <Redirect to={redirectUrl} />
        );
    }

    private renderActualPage(): JSX.Element {
        var pageTitle =
            (this.state.page.title && this.state.page.title.lv) === undefined || (this.state.page.title && this.state.page.title.lv) === null
                ? Intl.Get(LocIds.DocumentManagmentPage.NewPageTitle)
                : (this.state.page.title && this.state.page.title.lv);
        var pageKeyword = this.state.page.keyword && this.state.page.keyword.lv;
        if (!pageKeyword) {
            pageKeyword = this.state.page.description && this.state.page.description.lv;
        }
        return (
            <section className="col-12 pad-12x">
                {!this.state.isLoadingPageData &&
                    <HelmetWrapper title={pageTitle} description={pageKeyword} />
                }
                {AuthorizationInfo.isAuthorizedForAny(AuthorizationScope.AnyRoles, [{ actions: [AuthorizationActions.Read] }])
                    && AuthorizationInfo.isIncludedInRequiredFlights(['ShowAdminPageRemovalMessage'], FlightMembership.Any)
                    &&
                    (<MessageBar messageBarType={MessageBarType.warning}>
                        <div className="adminPageRemovalMessage">
                            {Intl.Get(LocIds.PageAndPanels.AdminPageRemoved)}
                            <Link href='https://learn.microsoft.com/en-us/microsoft-365/compliance/get-started-with-service-trust-portal?view=o365-worldwide#restricted-documents'>{Intl.Get(LocIds.PageAndPanels.AdminPageRemovedLearnMore)}.</Link>
                        </div>
                    </MessageBar>)
                }
                <div {...(this.props.isNextGenUIEnabled ? { 'data-grid': 'col-12' } : {})}>
                    {!this.state.isLoadingPageData &&
                        <PanelCollection
                            panels={this.state.page.panels}
                            deletePanel={(p) => this.deletedPanel(p)}
                            isInEditMode={this.state.isEditing}
                            addNewPanel={(type) => this.addNewPanel(type)}
                            makeDirty={() => this.makeDirty()}
                            movePanelUp={(p) => this.movePanelUp(p)}
                            movePanelDown={(p) => this.movePanelDown(p)}
                            updateFlightAndPermission={(p) => this.updateFlightAndPermission(p)}
                            idOfPage={this.state.page.id}
                            pageEditorView={this.props.isInEditMode}
                            parentId=""
                            hasInPageNav={false}
                            title={pageTitle}
                        />
                    }
                </div>
            </section>
        );
    }

    private renderConfirmationDialog(): JSX.Element {
        return (
            <ConfirmationDialog
                hidden={this.state.confirmationDialogType === ButtonTypes.None || this.state.confirmationDialogType === ButtonTypes.Tagging}
                title={this.confirmationDialogText[this.state.confirmationDialogType].title}
                subText={this.confirmationDialogText[this.state.confirmationDialogType].subText}
                onConfirmed={() => {
                    this.onConfirm(this.state.confirmationDialogType);
                    this.setState({ confirmationDialogType: ButtonTypes.None });
                }}
                onCancel={() => this.setState({ confirmationDialogType: ButtonTypes.None })}
            />
        );
    }

    private showConfirmationDialog(confirmationDialogType: ButtonTypes): void {
        this.setState({ confirmationDialogType: confirmationDialogType });
    }

    private renderMessageBar(): JSX.Element | '' {
        let message = '';
        if (this.props.isInEditMode) {
            if (!this.props.pageId && !this.props.pageUrl) {
                message = 'Welcome to your new empty page.';
            } else if (this.state.page.deletePending) {
                message = `${this.state.page.status === PageStatus.Live ? 'All the version of this page is going to be deleted.'
                    : 'The vNext of this page is going to be deleted.'} Click Reject to cancel this deletion, or Approve this deletion in pages list.`;
            }
        }
        return message && (<MessageBar className="explantaion">{message}</MessageBar>);
    }

    private onConfirm(operation: ButtonTypes): void {
        switch (operation) {
            case ButtonTypes.Publish:
                this.onPublishPages();
                break;
            case ButtonTypes.ApprovePublish:
                this.onApprovePages();
                break;
            case ButtonTypes.RejectPublish:
                this.onRejectPages();
                break;
            case ButtonTypes.ForcePublish:
                this.onForcePublishPages();
                break;
            case ButtonTypes.Undo:
                this.onUndo();
                break;
            case ButtonTypes.RollBack:
                this.onRestore();
                break;
            case ButtonTypes.Exit:
                this.onExit();
                break;
            case ButtonTypes.Return:
                this.onReturn();
                break;
            default:
                break;
        }
    }

    private loadingSpinnerSwitch(operation: 'On' | 'Off'): void {
        this.setState({ isLoadingPageData: operation === 'On' });
    }

    private scrollToPageTop(): void {
        window.scrollTo(0, 0);
    }

    private verifyInput(): boolean {
        const editPage = this.state.page;
        let hasScope: boolean = (editPage.scope !== '' && editPage.scope != null);
        let permissionCount = editPage.requiredPermissions && editPage.requiredPermissions.actions ? editPage.requiredPermissions.actions.length : 0;
        let scopeValid: boolean = hasScope && permissionCount > 0 || !hasScope && permissionCount === 0;
        return scopeValid;
    }

    private onUrlChanged(value: string): void {
        value = value.replace(/[^a-zA-Z0-9]*/g, '');
        this.onPageStringPropertyChanged('urlName', value);
    }

    private onPageStringPropertyChanged(name: string, value: string): void {
        this.setState((prevState) => {
            let newState = { ...prevState.page } as Page;
            newState[name] = value;
            return { page: newState, isDirty: true };
        });
    }

    private onRequiredFlightChanged(value: string): void {
        this.setState((prevState) => {
            let newState = { ...prevState.page } as Page;
            // If value is empty, we store an empty array, because an empty string '' will be recognized as one flight.
            newState.requiredFlights = value ? value.split(',') : [];
            return { page: newState, flightValue: value, isDirty: true };
        });
    }

    private onPermissionChanged(newPermissions: string[]): void {
        this.setState((prevState) => {
            let newState = { ...prevState.page } as Page;
            if (!newState.requiredPermissions) {
                newState.requiredPermissions = { actions: [] as string[] };
            }
            newState.requiredPermissions.actions = newPermissions;
            return { page: newState, isDirty: true };
        });
    }

    private onTitleChanged(newTitle: string): void {
        this.onLocalizedPropChanged('title', newTitle);
    }

    private onKeyWordChanged(newKeyword: string): void {
        this.onLocalizedPropChanged('keyword', newKeyword);
    }

    private onRedirectFromChanged(value: string): void {
        this.setState((prevState) => {
            let newState = { ...prevState.page } as Page;
            newState.redirectFrom = value ? value.split(',') : [];
            return { page: newState, isDirty: true };
        });
    }

    private onDescriptionChanged(newDescription: string): void {
        this.onLocalizedPropChanged('description', newDescription);
    }

    private onOwnerChanged(newOwner: string): void {
        this.onPageStringPropertyChanged('owner', newOwner);
    }

    private onLocalizedPropChanged(field: string, newValue: string): void {
        this.setState((prevState) => {
            let newState = { ...prevState.page } as Page;
            setDefaultLocalizedString(newState, field, newValue);
            return { page: newState, isDirty: true };
        });
    }

    private makeDirty(): void {
        if (!this.state.isDirty) {
            this.setState({ isDirty: true });
        }
    }

    private addNewPanel(type: string): void {
        this.setState((prevState) => {
            let newState = { ...prevState.page } as Page;
            newState.panels.push(PanelSelectionHelperFactory.getPanel(type));
            return { page: newState, isDirty: true };
        });
    }

    private deletedPanel(panel: PanelData<PanelPropertiesDataBase>): void {
        let index = this.state.page.panels.indexOf(panel);
        if (index > -1) {
            this.setState((prevState) => {
                let newState = { ...prevState.page } as Page;
                newState.panels.splice(index, 1);
                return { page: newState, isDirty: true };
            });
        }
    }

    private movePanelUp(panel: PanelData<PanelPropertiesDataBase>): void {
        let index = this.state.page.panels.indexOf(panel);
        this.swapPanels(index, index - 1, this.state.page.panels.length);
    }

    private movePanelDown(panel: PanelData<PanelPropertiesDataBase>): void {
        let index = this.state.page.panels.indexOf(panel);
        if (index < this.state.page.panels.length - 1) {
            this.swapPanels(index, index + 1, this.state.page.panels.length);
        }
    }

    private updateFlightAndPermission(panel: PanelData<PanelPropertiesDataBase>): void {
        let index = this.state.page.panels.findIndex(p => p.id === panel.id);
        if (index > -1) {
            this.setState((prevState) => {
                let newState = { ...prevState.page } as Page;
                newState.panels[index] = panel;
                return { page: newState, isDirty: true };
            });
        }
    }

    private swapPanels(index1: number, index2: number, length: number): void {
        if (index1 > -1 && index2 > -1 && index1 < length && index2 < length) {
            this.setState((prevState) => {
                let newState = { ...prevState.page } as Page;
                let tempPanel = newState.panels[index1];
                newState.panels[index1] = newState.panels[index2];
                newState.panels[index2] = tempPanel;
                return { page: newState, isDirty: true };
            });
        }
    }

    uploadPerfMetrics() {
        let measures = performance.getEntriesByType('measure');
        let uiPerfMeasures: { [name: string]: number } = {};
        measures.forEach(element => {
            uiPerfMeasures[element.name] = element.duration;
        });

        telemetry.trackEvent(
            'WebUIPerfTelemetry',
            {
                pageUrl: this.props.pageUrl ? this.props.pageUrl : ''
            },
            uiPerfMeasures
        );
        // Clean up the stored marks and measures.
        performance.clearMarks();
        performance.clearMeasures();
    }

    // When the user see the page without localization or only partial localization,
    // show a banner on top of the page indicating some content is still pending localization
    private updateLocalizationBanner(page: Page): void {
        if (page.showFallbackLanguageMessage) {
            store.dispatch(
                StatusMessageReducer.getShowBannerAction(Intl.Get(LocIds.HomePage.FallbackLangMsg), StatusMessageReducer.COMMON_STATUS_NAME)
            );
        } else {
            store.dispatch(StatusMessageReducer.getClearAllAction());
        }
    }

    private onEdit(): void {
        this.setState({ isEditing: true });
    }

    private onUndo(): void {
        this.setState({ page: getDeepCopy(this.pageCache) as Page, isDirty: false });
    }

    private onReturn(): void {
        this.setState({ pageRedirectType: PageRedirectType.PageList });
    }

    private onExit(showConfirmation: boolean = false): void {
        if (!showConfirmation || !this.state.isDirty) {
            this.setState({ isEditing: false, page: this.pageCache, isDirty: false });
        } else {
            this.setState({ confirmationDialogType: ButtonTypes.Exit });
        }
    }

    private onRestore(): void {
        this.setState((prevState) => {
            let page = prevState.page;
            // When restore from a history version, build the previousId for partial localization
            page.previousId = page.id;
            page.id = this.pageCache.id;
            return { page: page, isDirty: true, isEditing: true };
        });
    }

    private getCommandBarFarButtons(): IContextualMenuItem[] {
        return [CommandBarButtonFactory.GetButton(ButtonTypes.Return, () => this.showConfirmationDialog(ButtonTypes.Return), LabelText.STANDARD_BUTTONS_RETURN)];
    }

    private getCommandBarButtons(): IContextualMenuItem[] {
        return [
            this.editButton,
            this.undoButton,
            this.saveButton,
            this.sendForApprovalButton,
            this.approvePublishButton,
            this.rejectPublishButton,
            this.forcePublishButton,
            this.viewHistoryButton,
            this.restoreButton,
            this.manageVocabularyButton,
            this.exitButton];
    }

    private getPublicPageByUrlName(pageUrl?: string): void {
        if (pageUrl) {
            performance.mark('pageRender start');
            this.loadingSpinnerSwitch('On');
            pageServicesV2Client.getPublicPageByUrlName(
                pageUrl,
                (response) => {
                    let page = response.data as Page;
                    this.setState({ page: page });
                    this.updateLocalizationBanner(page);
                    performance.mark('pageRender done');
                    performance.measure('pageRenderedInMs', 'pageRender start', 'pageRender done');
                    this.loadingSpinnerSwitch('Off');
                    this.uploadPerfMetrics();
                },
                () => this.loadingSpinnerSwitch('Off')
            );
        }
    }

    private getVNextVersionByPageId(): void {
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.getVNextVersionByPageId(
            this.props.pageId || this.state.page.pageId,
            (response) => {
                this.setDefaultCommandBarButtonStatus();
                this.pageCache = getDeepCopy(response.data) as Page;
                this.editButton.disabled = false;
                this.loadingSpinnerSwitch('Off');
                this.setState({ page: response.data as Page });
            },
            () => this.loadingSpinnerSwitch('Off')
        );
    }

    private getPublicPageByPageId(): void {
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.getPublicPageByPageId(
            this.props.pageId || this.state.page.pageId,
            (response) => {
                this.setDefaultCommandBarButtonStatus();
                this.pageCache = getDeepCopy(response.data) as Page;
                this.editButton.disabled = false;
                this.setState({ page: response.data as Page });
                this.loadingSpinnerSwitch('Off');
            },
            () => this.loadingSpinnerSwitch('Off')
        );
    }

    private onSave(): void {
        if (!this.uiValidator.validate()) {
            return;
        }
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.updatePage(
            this.state.page,
            (response) => {
                this.loadPagesData();
                this.setState({ isDirty: false });
            },
            () => this.loadingSpinnerSwitch('Off')
        );
    }

    private onPublishPages(): void {
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.publishPages(
            [this.state.page.id],
            (response) => {
                this.loadPagesData();
                this.setState({ isDirty: false });
            },
            () => this.loadingSpinnerSwitch('Off')
        );
    }

    private onApprovePages(): void {
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.approvePages(
            [this.state.page.id],
            (response) => {
                this.loadPagesData();
                this.setState({ isDirty: false });
            },
            () => this.loadingSpinnerSwitch('Off')
        );
    }

    private onForcePublishPages(): void {
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.forcePublishPages(
            [this.state.page.id],
            (response) => {
                this.loadPagesData();
                this.setState({ isDirty: false });
            },
            () => this.loadingSpinnerSwitch('Off')
        );
    }

    private onRejectPages(): void {
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.rejectPages(
            [this.state.page.id],
            (response) => {
                this.loadPagesData();
                this.setState({ isDirty: false });
            },
            () => this.loadingSpinnerSwitch('Off')
        );
    }

    private getPageHistoryVersions(): void {
        let pageId = this.props.pageId || this.state.page.pageId;
        if (pageId) {
            pageServicesV2Client.getPageVersionsMetadataByPageId(
                pageId,
                (response) => {
                    this.setState({ pageVersions: response.data });
                },
                () => null
            );
        }
    }

    private getPageHistorySubMenu() {
        return {
            items: this.state.pageVersions.map(p => {
                return {
                    key: p.id,
                    name: `${stringToDateTime(p.whenLastModified)} (${p.status})`,
                    iconProps: { className: p.id === this.state.page.id ? 'ms-Icon ms-Icon--Accept' : '' },
                    disabled: p.id === this.state.page.id,
                    onClick: () => this.getPageVersion(p.id)
                } as IContextualMenuItem;
            })
        };
    }

    private getPageVersion(id: string): void {
        this.loadingSpinnerSwitch('On');
        pageServicesV2Client.getPageVersionById(
            id,
            (response) => {
                this.setState({ page: response.data });
                this.loadingSpinnerSwitch('Off');
            },
            () => null
        );
    }

    private showPageTaggingPanel(): void {
        this.pageToRestore = this.state.page;
        this.setState({ page: getDeepCopy(this.state.page), confirmationDialogType: ButtonTypes.Tagging });
    }

    private cancelTagging(): void {
        if (this.pageToRestore) {
            this.setState({ page: this.pageToRestore, confirmationDialogType: ButtonTypes.None });
            this.pageToRestore = undefined;
        }
    }
}