import { FrameworkStatus } from './FrameworkStatus';
import { CloudService } from './CloudService';
import { Framework } from './Framework';
import { PersonaDetails } from './PersonaDetails';

export class ReviewDashboardTile {
    public id: string;
    public cloudService: CloudService;
    public collaborators: Map<string, PersonaDetails>;
    public customerControls: number;
    public completedCustomerControls: number;
    public completedMicrosoftControls: number;
    public framework: Framework[];
    public isArchived: boolean;
    public isFiltered: boolean;
    public microsoftControls: number;
    public status: FrameworkStatus;
    public tileName: string;
    public whenCreated: Date;
    public whenLastUpdated: Date;
    public whoCreated: string;
}
