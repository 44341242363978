import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString, makeLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { LinkListPropertiesData, ResourceItemPropertiesData, PageMetadata } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { deleteOnePanelFromCollection, moveSelectedColumn, getContentRichBlockColumn, getThemeClassName, getSimpleThemeOptions, getLinkOptions, GetPagePublicUrl } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { ComboBox, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { store } from '../../../Store';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { BasePanel } from './BasePanel';
import { Validator } from '../../../Common/Utilities/Validator';
import { PagesDropdownListBox } from '../../Components/PagesDropdownListBox';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { Link } from 'react-router-dom';
import { getDeepCopy } from '../../Utilities/DeepCopyHelper';
import Constants from '../../Utilities/Constants';

export class LinkListPanel extends BasePanel<LinkListPropertiesData> {
    private static MAX_COLUMN = 3;
    private static pages: PageMetadata[];
    private component: HTMLDivElement | null;
    private linkBackup: ResourceItemPropertiesData;
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];
    private linkOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.LinkTypesLabel), itemType: SelectableOptionMenuItemType.Header }];
    private linkValidator: Validator = new Validator();

    constructor(props: BasicPanelProperties<LinkListPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));
        this.linkOptions = this.linkOptions.concat(getLinkOptions());

        if (!this.props.panel.panelInfo.columns) {
            this.props.panel.panelInfo.columns = [];
        }
        this.linkBackup = {};

        if (!LinkListPanel.pages) {
            LinkListPanel.pages = store.getState().pagesManagement.pagesMetadata;
        }
    }

    getActualPanel() {
        let grid: string = this.state.panelData && this.state.panelData.columns && this.state.panelData.columns.length ? 'col-'.concat((12 / this.state.panelData.columns.length).toString()) : '';
        return (
            <div
                style={{ paddingBottom: 24 }}
                className={'m-rich-content-block ' + getThemeClassName(Theme[this.state.panelData.theme || 'None'])}
                data-grid="col-12 pad-6x stack-3"
                ref={comp => this.component = comp}
                data-panel-type="Link List"
            >
                {this.state.panelData.columns ?
                    this.state.panelData.columns.map((data, index) =>
                        <div
                            key={data.toString().concat(index.toString())}
                            data-grid={grid}
                            onClick={() => { if (this.state.showEditor && this.panelValidator.validate() && this.linkValidator.validate()) { this.setState({ selectedIndex: index, selectedLinkIndex: -1 }); } }}
                            style={this.state.selectedIndex === index && this.state.showEditor ? { border: 'solid 1px #778899', backgroundColor: this.state.selectedIndex === index ? '#f4f4f4' : '', textAlign: 'center', marginBottom: 40 } : {}}
                        >
                            <h3 className="c-heading-3 m-banner">{getLocalizedString(data.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}</h3>
                            {data.links ?
                                <ul className="c-list f-bare m-banner" style={{ fontSize: 20 }}>
                                    {data.links.map((link, index2) =>
                                        <li key={link.toString().concat(index2.toString())} style={{ margin: '5px 0', textAlign: 'center' }}>
                                            {link.type === 'Page' ?
                                                <Link to={GetPagePublicUrl(link.url)} className="c-hyperlink">{getLocalizedString(link.title) || Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}
                                                </Link> :
                                                <a href={link.url} className="c-hyperlink" style={{ textDecoration: 'none' }} target="_blank">{getLocalizedString(link.title) || Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}</a>
                                            }
                                        </li>)
                                    }
                                </ul>
                                : null
                            }
                        </div>
                    )
                    :
                    null
                }
            </div>
        );
    }

    onAddColumn() {
        if (!this.state.panelData.columns || (this.state.panelData.columns.length >= LinkListPanel.MAX_COLUMN) || !this.panelValidator.validate() || !this.linkValidator.validate()) {
            return;
        }
        this.state.panelData.columns.push(getContentRichBlockColumn());
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    onDeleteColumn() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            var panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    onMoveLeft() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    onMoveRight() {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    render() {
        var selectedColumns = this.state.panelData.columns && this.state.selectedIndex >= 0 && this.state.panelData.columns.length > this.state.selectedIndex ?
            this.state.panelData.columns[this.state.selectedIndex] : null;
        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}}>
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor && selectedColumns ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={getLocalizedString(selectedColumns.heading)}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    if (selectedColumns) {
                                                        if (newVal.length > Constants.PANEL_HEADING_MAX) {
                                                            newVal = newVal.substr(0, Constants.PANEL_HEADING_MAX);
                                                        }
                                                        setDefaultLocalizedString(selectedColumns, 'heading', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label style={{ marginRight: 10 }}>Links:</label>
                                        <PrimaryButton
                                            onClick={() => {
                                                if (selectedColumns && selectedColumns.links) {
                                                    if (!this.linkValidator.validate()) {
                                                        return;
                                                    }
                                                    var link = { title: makeLocalizedString(''), type: 'Page', url: '' };
                                                    selectedColumns.links.push(link);
                                                    this.setState({ selectedLinkIndex: selectedColumns.links.length - 1 });
                                                    this.linkBackup = getDeepCopy(link);
                                                }
                                            }}
                                            iconProps={{ iconName: 'Add' }}
                                        />
                                    </div>
                                    <div data-grid="col-12">
                                        {selectedColumns.links ?
                                            selectedColumns.links.map((link, linkIndex) =>
                                                <div key={link.toString().concat(linkIndex.toString())}>
                                                    {linkIndex !== this.state.selectedLinkIndex ?
                                                        <div data-grid="col-12" className="edit-row top-border">
                                                            <div data-grid="col-3" style={{ paddingRight: 10 }}>
                                                                <a className="c-hyperlink">{getLocalizedString(link.title) || Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}</a>
                                                            </div>
                                                            <div data-grid="col-3" style={{ paddingRight: 10 }}>
                                                                <a className="c-hyperlink">{(!link.type || link.type === 'Page') && link.url ? GetPagePublicUrl(link.url) : link.url}</a>
                                                            </div>
                                                            <div data-grid="col-3" />
                                                            <div data-grid="col-3" className="edit-buttons">
                                                                <PrimaryButton
                                                                    onClick={() => {
                                                                        if (!this.linkValidator.validate()) {
                                                                            return;
                                                                        }
                                                                        this.setState({ selectedLinkIndex: linkIndex });
                                                                        this.linkBackup = getDeepCopy(link);
                                                                    }}
                                                                    iconProps={{ iconName: 'Edit' }}

                                                                />
                                                                <DefaultButton
                                                                    disabled={linkIndex === 0}
                                                                    onClick={() => {
                                                                        if (selectedColumns && selectedColumns.links && this.linkValidator.validate()) {
                                                                            var temp = selectedColumns.links[linkIndex];
                                                                            selectedColumns.links[linkIndex] = selectedColumns.links[linkIndex - 1];
                                                                            selectedColumns.links[linkIndex - 1] = temp;
                                                                            this.setState({ panelData: this.state.panelData });
                                                                        }
                                                                    }}
                                                                    iconProps={{ iconName: 'ChevronUp' }}
                                                                />
                                                                <DefaultButton
                                                                    disabled={selectedColumns != null && selectedColumns.links && linkIndex >= selectedColumns.links.length - 1}
                                                                    onClick={() => {
                                                                        if (selectedColumns && selectedColumns.links && this.linkValidator.validate()) {
                                                                            var temp = selectedColumns.links[linkIndex];
                                                                            selectedColumns.links[linkIndex] = selectedColumns.links[linkIndex + 1];
                                                                            selectedColumns.links[linkIndex + 1] = temp;
                                                                            this.setState({ panelData: this.state.panelData });
                                                                        }
                                                                    }}
                                                                    iconProps={{ iconName: 'ChevronDown' }}
                                                                />
                                                                <DefaultButton
                                                                    onClick={() => {
                                                                        if (selectedColumns && selectedColumns.links) {
                                                                            selectedColumns.links.splice(linkIndex, 1);
                                                                            this.setState({ selectedLinkIndex: -1 });
                                                                        }
                                                                    }}
                                                                    iconProps={{ iconName: 'RecycleBin' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div data-grid="col-12" className="edit-panel top-border">
                                                            <div data-grid="col-3" style={{ paddingRight: 10 }}>
                                                                <TextFieldWrapper
                                                                    value={getLocalizedString(link.title)}
                                                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                                        if (newVal.length > Constants.PANEL_TITLE_MAX) {
                                                                            newVal = newVal.substr(0, Constants.PANEL_TITLE_MAX);
                                                                        }
                                                                        setDefaultLocalizedString(link, 'title', newVal);
                                                                        this.setState({ panelData: this.state.panelData });
                                                                    }}
                                                                    label={Intl.Get(LocIds.PageAndPanels.TitleLabel)}
                                                                    required={true}
                                                                    validator={this.linkValidator}
                                                                    placeholder={Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)}
                                                                />
                                                            </div>
                                                            <div data-grid="col-3" style={{ paddingRight: 10 }}>
                                                                <ComboBox
                                                                    defaultSelectedKey={link.type || 'Page'}
                                                                    label={Intl.Get(LocIds.PageAndPanels.LinkTypesLabel)}
                                                                    ariaLabel={Intl.Get(LocIds.PageAndPanels.LinkTypesLabel)}
                                                                    allowFreeform={true}
                                                                    autoComplete={'on'}
                                                                    options={this.linkOptions}
                                                                    onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                                        if (!item) {
                                                                            return;
                                                                        }
                                                                        link.type = item.key.toString();
                                                                        this.setState({ panelData: this.state.panelData });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div data-grid="col-3" style={{ paddingRight: 10 }}>
                                                                {!link.type || link.type === 'Page' ?
                                                                    <PagesDropdownListBox
                                                                        defaultSelectedKey={link.url}
                                                                        label={Intl.Get(LocIds.PageAndPanels.SelectPageLabel)}
                                                                        ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectPageLabel)}
                                                                        allowFreeform={true}
                                                                        autoComplete={'on'}
                                                                        onChange={(item: IComboBoxOption) => {
                                                                            link.url = item.key.toString();
                                                                        }}
                                                                    />
                                                                    :
                                                                    <TextFieldWrapper
                                                                        value={link.url}
                                                                        onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                                            link.url = newVal;
                                                                            this.setState({ panelData: this.state.panelData });
                                                                        }}
                                                                        label={Intl.Get(LocIds.PageAndPanels.UrlLabel)}
                                                                        required={true}
                                                                        validator={this.linkValidator}
                                                                    />
                                                                }
                                                            </div>
                                                            <div data-grid="col-3" style={{ paddingTop: 30 }}>
                                                                <PrimaryButton
                                                                    onClick={() => {
                                                                        if (!this.linkValidator.validate()) {
                                                                            return;
                                                                        }
                                                                        this.setState({ selectedLinkIndex: -1 });
                                                                        this.setState({ panelData: this.state.panelData });
                                                                        this.linkBackup = {};
                                                                    }}
                                                                    iconProps={{ iconName: 'Accept' }}
                                                                />
                                                                <DefaultButton
                                                                    onClick={() => {
                                                                        link.url = this.linkBackup.url;
                                                                        link.type = this.linkBackup.type;
                                                                        link.title = this.linkBackup.title;

                                                                        this.setState({ selectedLinkIndex: -1 });
                                                                        this.setState({ panelData: this.state.panelData });
                                                                        this.linkBackup = {};
                                                                    }}
                                                                    iconProps={{ iconName: 'Cancel' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>)
                                            : null
                                        }
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div className="top-border" data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <PrimaryButton
                                        onClick={() => {
                                            if (!this.linkValidator.validate()) {
                                                return;
                                            }
                                            this.onSave();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => {
                                            this.onDiscardChange();
                                        }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddColumnLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length >= LinkListPanel.MAX_COLUMN)}
                                        onClick={() => this.onAddColumn()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeleteColumnLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={() => this.onDeleteColumn()}
                                    />
                                    <DefaultButton
                                        text={Intl.Get(LocIds.PageAndPanels.MoveLeftButtonLabel)}
                                        iconProps={{ iconName: 'ChevronLeft' }}
                                        onClick={() => this.onMoveLeft()}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'ChevronRight' }}
                                        text={Intl.Get(LocIds.PageAndPanels.MoveRightButtonLabel)}
                                        onClick={() => this.onMoveRight()}
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}