// Defines component to be used to present STP user authentication session management notifications.
import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { AuthenticationSessionController, AuthenticationSessionModel,
         AuthenticationSessionState, AuthenticationSessionDialogMode } from '../../Common/Utilities/AuthenticationSessionController';

export class AuthenticationSessionDialog extends React.Component<AuthenticationSessionModel, AuthenticationSessionState> {
    private readonly authSession: AuthenticationSessionController;
    constructor(props: AuthenticationSessionModel) {
        super(props);
        this.getDialogButtonContents = this.getDialogButtonContents.bind(this);
        this.getDialogInstructionContents = this.getDialogInstructionContents.bind(this);
        if (props === null || props.authSession === undefined || props.authSession === null) {
            this.authSession = new AuthenticationSessionController((newState) => this.setDialogState(newState));
        } else {
            this.authSession = props.authSession;
            this.authSession.dialogStateUpdater = (newState) => this.setDialogState(newState);
        }     
        this.state = {
            currentDialogMode: AuthenticationSessionDialogMode.NoDialog,
            dialogArgs: this.authSession.getDefaultState(),
            isSpinnerVisible: false
        };
    }

    render() {                
        if (this.state.currentDialogMode === AuthenticationSessionDialogMode.NoDialog) {
            // Dialog is invisible in
            return null;
        }
        return (
            <Dialog
                    hidden={false}
                    containerClassName="ImageMgmtDialogs"
                    className={'stp-session-' + this.state.currentDialogMode}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: this.state.dialogArgs.title,
                        subText: this.state.dialogArgs.expirationMessage,
                        onDismiss: () => this.doContinue()
                    }}
                    modalProps={{
                        isBlocking: true,
                        containerClassName: 'ms-dialogMainOverride'
                    }}
            >                
                {this.state.isSpinnerVisible && <Spinner className="inlineSpinner" />}
                {this.getDialogInstructionContents()}
                <DialogFooter>
                    {this.getDialogButtonContents()}
                </DialogFooter>
            </Dialog>
                );
    }

    componentDidMount(): void {
        this.authSession.startTimer();
    }

    componentWillUnmount(): void {
        this.authSession.dispose();
    }

    private setDialogState(newState: AuthenticationSessionState): boolean {
        if (this.state === undefined || 
            this.state.currentDialogMode !== newState.currentDialogMode || 
            this.state.dialogArgs.timeElements.secondsBeforeExpiration 
                !== newState.dialogArgs.timeElements.secondsBeforeExpiration) {
            this.setState(newState);
            return true;
        }
        return false;
    }

    private doContinue() {
        this.setState({ isSpinnerVisible: true });
        this.authSession.processContinueAction();
    }

    private doLogout() {
        this.setState({ isSpinnerVisible: true });
        this.authSession.processLogoutAction();
    }   

    private getDialogInstructionContents(): JSX.Element | null {
        if (this.state.dialogArgs.instructionMessage !== undefined) {
                return (                    
                    <p>
                        <Label>{this.state.dialogArgs.instructionMessage}</Label>
                    </p>                    
                );
        } else {
                return null;
        }
    }    

    private getDialogButtonContents(): JSX.Element | null {
        if (this.state.currentDialogMode === AuthenticationSessionDialogMode.SessionAboutToExpireWarning ||
                this.state.currentDialogMode === AuthenticationSessionDialogMode.SessionLifeAboutToEndWarning ) {
            return (
                <div>
                    <DefaultButton
                        text={this.state.dialogArgs.continueButtonLabel}
                        onClick={() => this.doContinue()}
                    />
                    <PrimaryButton
                        text={this.state.dialogArgs.logoutButtonLabel}
                        onClick={() => this.doLogout()}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <DefaultButton
                        text={this.state.dialogArgs.logoutButtonLabel}
                        onClick={() => this.doContinue()}
                    />
                </div>
            );
        }        
    }    
}
