import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
// tslint:disable-next-line:no-var-requires
const intl = require('react-intl-universal');

class GlobalizationService {
    Get(stringId: string, variables: {} = {}) {
        let result = intl.get(stringId, variables);
        return result;
    }
    GetHTML(stringId: string, variables: {} = {}) {
        let result = intl.getHTML(stringId, variables);
        return result;
    }
    toLocalDate(date: Date) {
        let str = intl.get(LocIds.Utility.Date, {d: date});
        return str;
    }
}

export let Intl = new GlobalizationService();