import * as React from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import {
    DetailsList, DetailsListLayoutMode, SelectionMode, Selection, IColumn, PrimaryButton, DefaultButton,
    Dialog, DialogType, DialogFooter, IContextualMenuItem, Dropdown, IDropdownOption
} from 'office-ui-fabric-react/lib/';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ADUserManagementService } from 'src/App/Services/ADTenantManagementService';
import { ButtonTypes, CommandBarButtonFactory } from 'src/Common/Utilities/CommanBarButtonFactory';
import { EditUserDialog } from './EditUserDialog';
import { ADTenantInfo } from 'src/App/Models/ADTenantManagement/ADTenantInfo';
import { ADGroupInfo } from 'src/App/Models/ADTenantManagement/ADGroupInfo';
import { ADUserInfo } from 'src/App/Models/ADTenantManagement/ADUserInfo';
import { ErrorResponse } from '../../../Services/Models';

export enum NewUserType {
    Member,
    Guest
}

interface ADTenantManagementPageProps { 
}

interface ADTenantManagementPageState {
    status: 'loading' | 'loaded' | 'confirming';
    adTenantOptions:  IDropdownOption[];
    selectedTenant: ADTenantInfo | null;
    tenantGroups: ADGroupInfo[];
    rawUsers: ADUserInfo[];
    isUserSelected: boolean;
    showEditDialog: boolean;
    isAddingNewUser: boolean;
    newUserType: NewUserType | null;
}

export class ADTenantManagementPage extends React.Component<ADTenantManagementPageProps, ADTenantManagementPageState> {
    private Api: ADUserManagementService;
    private addButton: IContextualMenuItem;
    private inviteButton: IContextualMenuItem;
    private editButton: IContextualMenuItem;
    private deleteButton: IContextualMenuItem;
    private refreshButton: IContextualMenuItem;
    private selection: Selection;
    private columns: IColumn[] = [
        {
            key: 'DisplayName',
            name: 'Display name',
            fieldName: 'displayName',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: ADUserInfo) => {
                return (
                    <div>
                        { item.displayName }
                    </div>
                );
            }
        },
        {
            key: 'Email',
            name: 'Email',
            fieldName: 'email',
            minWidth: 150,
            maxWidth: 400,
            isResizable: true,
            data: 'string',
            onRender: (item: ADUserInfo) => {
                return (
                    <div>
                        { item.email }
                    </div>
                );
            }
        },
        {
            key: 'CompanyName',
            name: 'Company name',
            fieldName: 'companyName',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: ADUserInfo) => {
                return (
                    <div>
                        { item.companyName }
                    </div>
                );
            }
        },
        {
            key: 'UserType',
            name: 'User type',
            fieldName: 'userType',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            data: 'string',
            onRender: (item: ADUserInfo) => {
                return (
                    <div>
                        { item.userType }
                    </div>
                );
            }
        },
        {
            key: 'ExternalUserState',
            name: 'External User State',
            fieldName: 'externalUserState',
            minWidth: 50,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: ADUserInfo) => {
                return (
                    <div>
                        { item.externalUserState }
                    </div>
                );
            }
        },
        {
            key: 'ADGroups',
            name: 'Azure AD Groups',
            fieldName: 'adGroups',
            minWidth: 50,
            isResizable: true,
            data: 'string',
            onRender: (item: ADUserInfo) => {
                return (
                    <div>
                        { this.getADGroupNamesAsString(item.adGroups) }
                    </div>
                );
            }
        },
        {
            key: 'NDA',
            name: 'NDA',
            fieldName: 'nda',
            minWidth: 50,
            isResizable: true,
            data: 'string',
            onRender: (item: ADUserInfo) => {
                return (
                    <div>
                        {item.userHasAgreedToCurrentLicenseAgreement && <i style={{ fontSize: 20 }} className="ms-Icon ms-Icon--CheckMark" aria-hidden="true" />}
                    </div>
                );
            }
        }
    ]

    private getADGroupNamesAsString(groupIds: string[]): string {
        if (this.state.tenantGroups === undefined) {
            return '';
        }
        let adGroupNames: string[] = [];
        groupIds.forEach(groupId => {
            let adGroup = this.state.tenantGroups.find(g => g.id == groupId);
            if (adGroup) {
                adGroupNames.push(adGroup.displayName);
            }
        })
        return adGroupNames.join(', ');
    }

    public constructor(props: ADTenantManagementPageProps) {
        super(props);
        this.Api = new ADUserManagementService();
        this.selection = new Selection({
            onSelectionChanged: () => this.onSelectionChange()
        });
        this.state = {
            status: 'loading',
            adTenantOptions: [] as IDropdownOption[],
            selectedTenant: null,
            tenantGroups: [] as ADGroupInfo[],
            rawUsers: [] as ADUserInfo[],
            isUserSelected: false,
            showEditDialog: false,
            isAddingNewUser: false,
            newUserType: null
        };

        this.addButton = CommandBarButtonFactory.GetButton(ButtonTypes.Add, () => this.onAdd(), 'Create new user');
        this.inviteButton = CommandBarButtonFactory.GetButton(ButtonTypes.Add, () => this.onInvite(), 'Invite external user');
        this.editButton = CommandBarButtonFactory.GetButton(ButtonTypes.Edit, () => this.onEdit(), 'Edit');
        this.deleteButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.onDelete(), 'Delete');
        this.refreshButton = CommandBarButtonFactory.GetButton(ButtonTypes.Refresh, () => this.onRefresh(), 'Refresh');

        this.disableAllActionButtons();
    }

    public componentDidMount() {
        this.setState({ status: 'loading' });
        this.Api.getTenants(adTenantInfos => {
            if (adTenantInfos != null) {
                // create list of whitelisted tenants for Dropdown control
                const options = adTenantInfos.map (tenant => {
                    const option: IDropdownOption = { key: tenant.id, text: tenant.domainName };
                    return option;
                });

                this.setState({ adTenantOptions: options, status: 'loaded' });
            }
        },
        (errorResponse: ErrorResponse)=> {
            this.setState({ status: 'loaded' });
        } );
        
        this.disableAllActionButtons();
    }

    private loadGroupsAndUsersData(tenant: ADTenantInfo | null) {
        if (!tenant|| tenant == null) {
            this.setState({ rawUsers: [] });
            this.disableAllActionButtons();
            return;
        }

        this.setState({ status: 'loading' });
        this.Api.getTenantGroups(tenant.id, (adGroups) => {
            this.setState({ tenantGroups: adGroups });

            this.Api.getAllTenantUsers(tenant.id, (adUsers) => {
                if (adUsers) {
                    this.setState({ tenantGroups: adGroups, rawUsers: adUsers, status: 'loaded' });
                    
                    this.addButton.disabled = false;
                    this.inviteButton.disabled = false;
                    this.editButton.disabled = true;
                    this.deleteButton.disabled = true;
                    this.selection.setAllSelected(false);
                    return;
                }
            },
            (errorResponse: ErrorResponse)=> {
                this.setState({ rawUsers: [], status: 'loaded' });
            } );
        },
        (errorResponse: ErrorResponse)=> {
            this.setState({ tenantGroups: [], rawUsers: [], status: 'loaded' });
        } );

        this.disableAllActionButtons();
    }

    private loadUsersData(tenant: ADTenantInfo | null) {
        if (!tenant|| tenant == null) {
            this.setState({ rawUsers: [] });
            this.disableAllActionButtons();
            return;
        }

        this.setState({ status: 'loading' });
        this.Api.getAllTenantUsers(tenant.id, (adUsers) => {
            if (adUsers) {
                this.setState({ rawUsers: adUsers, status: 'loaded' });
                
                this.addButton.disabled = false;
                this.inviteButton.disabled = false;
                this.editButton.disabled = true;
                this.deleteButton.disabled = true;
                this.selection.setAllSelected(false);
                return;
            }
        },
        (errorResponse: ErrorResponse)=> {
            this.setState({ rawUsers: [], status: 'loaded' });
        } );

        this.disableAllActionButtons();
    }

    private disableAllActionButtons() {
        this.addButton.disabled = true;
        this.inviteButton.disabled = true;
        this.editButton.disabled = true;
        this.deleteButton.disabled = true;
        this.selection.setAllSelected(false);
    }

    public render() {
        if (this.state.status === 'loading') {
            return (
                <Spinner size = { SpinnerSize.large } label = "Initializing..." ariaLive = "assertive" />
            );
        } else {
            return (
                <div>
                    { /* Dropdow with list of whitelisted tenants */ }
                    <div className = "row-cols-2">
                        <Dropdown
                            options = { this.state.adTenantOptions }
                            placeholder = "Select a tenant"
                            onChange = { (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => this.tenantsDropdownChanged(option) }
                            selectedKey = { this.state.selectedTenant ? this.state.selectedTenant.id : '' }
                        />
                    </div>
                    <br />
                    { /* Command bar with the action buttons */ }
                    <CommandBar items = { [/*this.addButton,*/ this.inviteButton, this.editButton, this.deleteButton, this.refreshButton] } />
                    <hr className = "c-divider f-pad-vertical-3x" />
                    { /* Edit user dialog */ }
                    <div className="m-content-placement" data-grid="col-12">
                        { this.state.showEditDialog && this.state.selectedTenant ?
                            <EditUserDialog
                                onDismiss = {() => this.setState({ showEditDialog: false, newUserType: null })}
                                onSave = {() => { this.setState({ showEditDialog: false, newUserType: null }); this.loadUsersData(this.state.selectedTenant); }}
                                adTenant = { this.state.selectedTenant }
                                tenantADGroups = { this.state.tenantGroups }
                                selectedUser = { this.getSelectedUser() }
                                isAddingNewUser = { this.state.isAddingNewUser }
                                newUserType = { this.state.newUserType }
                            />
                            : null }
                    </div>
                    <div>
                        { /* Delete User dialog */ }
                        <Dialog
                            hidden = { this.state.status !== 'confirming' }
                            onDismiss = { () => { this.setState({ status: 'loaded' }); }}
                            dialogContentProps = {{
                                type: DialogType.largeHeader,
                                title: 'Delete User',
                                subText: 'Confirm the deletion of selected user'
                            }}
                            modalProps = {{
                                titleAriaId: 'Delete User',
                                subtitleAriaId: 'Confirm the deletion of selected user',
                                isBlocking: true,
                                containerClassName: 'ms-dialogMainOverride'
                            }}
                        >
                            <DialogFooter>
                                <PrimaryButton onClick = { () => this.deleteCustomer() } text = "Confirm" />
                                <DefaultButton onClick = { () => this.setState({ status: 'loaded' }) } text = "Cancel" />
                            </DialogFooter>
                        </Dialog>
                        { /* List of the users */ }
                        <section data-grid = "col-12 pad-12x">
                            <DetailsList
                                items = { this.state.rawUsers }
                                columns = { this.columns }
                                selectionMode = { SelectionMode.single }
                                layoutMode = { DetailsListLayoutMode.justified }
                                isHeaderVisible = { true }
                                selection = { this.selection }
                                selectionPreservedOnEmptyClick = { true }
                                setKey = { 'set' }
                                onShouldVirtualize = { () => false }
                            />
                        </section>
                    </div>
                </div>
            )
        }
    }

    private getSelectedUser(): ADUserInfo {
        return this.selection.getSelection()[0] as ADUserInfo;
    }

    private onSelectionChange() {
        var user = this.getSelectedUser();
        this.setState({ isUserSelected: true });
        if (user) {
            this.editButton.disabled = false;
            this.deleteButton.disabled = false;
        } else {
            this.editButton.disabled = true;
            this.deleteButton.disabled = true;
        }
        this.addButton.disabled = false;
        this.inviteButton.disabled = false;
    }

    private onAdd() {
        this.setState({ showEditDialog: true, isAddingNewUser: true, newUserType: NewUserType.Member });
    }

    private onInvite() {
        this.setState({ showEditDialog: true, isAddingNewUser: true, newUserType: NewUserType.Guest });
    }

    private onEdit() {
        this.setState({ showEditDialog: true, isAddingNewUser: false, newUserType: null });
    }

    private onDelete() {
        this.setState({ status: 'confirming' });
    }

    private onRefresh() {
        this.loadUsersData(this.state.selectedTenant);
    }

    private deleteCustomer() {
        if (this.state.isUserSelected && this.state.selectedTenant != null) {
            this.Api.deleteTenantUser(this.state.selectedTenant.id, this.getSelectedUser().id, 
            () => {
                this.loadUsersData(this.state.selectedTenant);
            });
        }
    }

    private tenantsDropdownChanged (option?: IDropdownOption) {
        if (option) {
            var newSelectedTenant = { id: option.key.toString(), domainName: option.text };
            this.setState({ selectedTenant: newSelectedTenant });
            this.loadGroupsAndUsersData(newSelectedTenant);
        }
    }
}