import * as React from 'react';
import { BasicPanelProperties, getEditableSideBarCol } from '../../Utilities/PageInfrastructure/EditablePanelHelper';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { getLocalizedString, setDefaultLocalizedString, makeLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { FAQPanelPropertiesData } from '../../Models/PageInfrastructure';
import { Theme } from '../../Models/PageInfrastructure/PanelColumnPropertiesData';
import { deleteOnePanelFromCollection, moveSelectedColumn, getThemeClassName, getSimpleThemeOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { ComboBox, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { SelectableOptionMenuItemType } from 'office-ui-fabric-react';
import { BasePanel } from './BasePanel';
import { MarkDownEditor } from '../../../Common/Components/MarkDownEditor';
import Constants from '../../Utilities/Constants';
import * as ReactMarkdown from 'react-markdown';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import TextFieldWrapper from '../TextFieldWrapper';
import './FAQPanel.css';
import { TextFieldWrapperNumeric } from '../TextFieldWrapperNumeric';
import { isEmptyString, RichTextLink, renderLink } from '../../../App/Utilities/RenderUtilities';

export class FAQPanel extends BasePanel<FAQPanelPropertiesData> {
    private themeOptions: IComboBoxOption[] = [{ key: 'Header', text: Intl.Get(LocIds.PageAndPanels.ThemeLabel), itemType: SelectableOptionMenuItemType.Header }];

    constructor(props: BasicPanelProperties<FAQPanelPropertiesData>) {
        super(props);
        this.themeOptions = this.themeOptions.concat(getSimpleThemeOptions(true));

        if (!this.props.panel.panelInfo.columns) {
            this.props.panel.panelInfo.columns = [];
        }

        this.state = {
            ...this.state,
            panelData: {
                ...this.state.panelData,
                expandedTabs: {},
            }
        };
    }

    componentDidMount() {
        this.setState({selectedIndex: -1}); // the initial selected index must be -1 so all drawers are collapsed.
    }

    selectTab(index: number) {
        var selectedIndex = this.state.selectedIndex;
        if (!this.panelValidator.validate()) {
            return;
        }

        if (selectedIndex === index && this.props.isInEditMode) {
            return;
        } else if (selectedIndex === index) {
            selectedIndex = -1;
        } else {
            selectedIndex = index;
        }
        this.setState({ selectedIndex: selectedIndex });
    }

    private toggleTabVisibility = (index: number) => {
        const expandedTabs = {
            ...this.state.panelData.expandedTabs
        };

        this.setState({
            panelData: {
                ...this.state.panelData,
                expandedTabs: {
                    [index]: !(expandedTabs[index] === true),
                }
            }
        });
    }

    getActualPanel() {
        const { panelCallToActionLabel, panelCallToActionUrl } = this.state.panelData;
        const expandedTabs = this.state.panelData.expandedTabs || {};

        return (
            <div
                data-grid="col-12"
                className={'faq-panel ' + getThemeClassName(Theme[this.state.panelData.theme || 'None'])}
                data-panel-type="FAQ"
            >
                {((this.state.panelData.heading && this.state.panelData.heading.lv) || (this.state.panelData.subHeading && this.state.panelData.subHeading.lv)) &&
                    <div className="faq-heading">
                        {this.state.panelData.heading && this.state.panelData.heading.lv && (
                            <h2 className="c-heading-2 x-hidden-focus">
                                {getLocalizedString(this.state.panelData.heading) || Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                            </h2>
                        )}
                        {this.state.panelData.subHeading && this.state.panelData.subHeading.lv &&
                            <ReactMarkdown
                                className="c-subheading"
                                source={getLocalizedString(this.state.panelData.subHeading)}
                                renderers={{ link: RichTextLink }}
                                skipHtml={true}
                            />
                        }
                    </div> 
                }
                {this.state.panelData.columns ? this.state.panelData.columns.map((data, index) =>
                    <div 
                        key={index}
                        className={'c-drawer f-divider' + (index === this.state.selectedIndex && this.state.showEditor ? ' selected-view' : '')} 
                        hidden={!this.state.panelData.isExpanded && this.state.panelData.initialColumnCount !== undefined && this.state.panelData.initialColumnCount > 0 && (index + 1 > this.state.panelData.initialColumnCount )}
                    >
                        <button
                            className="c-glyph"
                            aria-expanded={index === this.state.selectedIndex}
                            aria-controls={`faq-${this.state.panelData.id}-section-${index}`}
                            id={`faq-${this.state.panelData.id}-section-${index}-label`}
                            onClick={() => {
                                this.toggleTabVisibility(index);

                                if (!this.panelValidator.validate()) {
                                    return;
                                }
                                this.selectTab(index);
                            }}
                        >
                            <ReactMarkdown className="c-paragraph-1 f-lean" source={getLocalizedString(data.name) || Intl.Get(LocIds.PageAndPanels.TitlePlaceHolder)} />
                        </button>
                        <div
                            hidden={expandedTabs[index] !== true && this.state.showEditor !== true}
                            className="faq-content-panel"
                            id={`faq-${this.state.panelData.id}-section-${index}`}
                            aria-labelledby={`faq-${this.state.panelData.id}-section-${index}-label`}
                        >
                            <ReactMarkdown
                                className="c-paragraph-3"
                                source={getLocalizedString(data.content) || Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                renderers={{ link: RichTextLink, text: this.textRenderer }}
                                skipHtml={true}
                            />
                        </div>
                    </div>
                    )
                : null
                }
                {this.state.panelData.initialColumnCount > 0 && this.state.panelData.columns && (this.state.panelData.columns.length > this.state.panelData.initialColumnCount) &&
                    <button className="c-button f-lightweight" onClick={() => this.expandButtonClick()} >
                        {this.state.panelData.isExpanded ? Intl.Get(LocIds.PageAndPanels.FAQCollapse) : Intl.Get(LocIds.PageAndPanels.FAQExpand)}
                    </button>
                }
                {!isEmptyString(getLocalizedString(panelCallToActionLabel)) && (
                    <div
                        className="m-banner"
                        data-grid="col-12"
                        style={{ textAlign: 'left', paddingLeft: '0', paddingTop: '22px' }}
                    >
                        {renderLink(panelCallToActionUrl, getLocalizedString(panelCallToActionLabel), 'c-call-to-action c-glyph f-lightweight')}
                    </div>
                )}
            </div>
        );
    }

    /**
     * The curly braces in the 'What will the data look like?' section are not being read by the screen reader in scan mode.
     * This method creates a visually hidden version of the string that includes the braces spelled out for the screen reader.
     * The hidden strings are positioned to make sure the visual focus rendered by the screen reader is in the right position.
     */
    textRenderer = (args: { children: string }) => {
        let screenReaderLabel = '';
        let topPosition = '';

        if (args.children.includes('{')) {
            screenReaderLabel = `${Intl.Get(LocIds.PageAndPanels.LeftCurlyBraceAriaLabel)} ${args.children}`;
            topPosition = '38px';
        }

        if (args.children.includes('}')) {
            screenReaderLabel = `${args.children} ${Intl.Get(LocIds.PageAndPanels.RightCurlyBraceAriaLabel)}`;
            topPosition = '104px';
        }

        const position = {
            position: 'absolute',
            top: topPosition
        } as React.CSSProperties;

        if (screenReaderLabel === '') {
            return <>{args.children}</>;
        } else {
            return (
                <>
                    <div aria-hidden={true}>{args.children}</div>
                    <div className="hidden-text" style={position}>{screenReaderLabel}</div>
                </>
            );
        }
    }

    onAddColumn = () => {
        if (!this.state.panelData.columns || !this.panelValidator.validate()) {
            return;
        }
        this.state.panelData.columns.push({ name: makeLocalizedString(''), content: makeLocalizedString('') });
        this.setState({
            panelData: this.state.panelData,
            selectedIndex: this.state.panelData.columns.length - 1
        });
    }

    onDeleteColumn = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1)) {
            var panel = this.state.panelData;
            deleteOnePanelFromCollection(panel, this.state.selectedIndex);
            this.setState({ panelData: panel, selectedIndex: 0 });
        }
    }

    onMoveLeft = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex > 0)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, true);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex - 1 });
        }
    }

    onMoveRight = () => {
        if (this.state.panelData.columns && (this.state.panelData.columns.length > 1) && (this.state.selectedIndex < this.state.panelData.columns.length - 1)) {
            var panel = this.state.panelData;
            moveSelectedColumn(panel, this.state.selectedIndex, false);
            this.setState({ panelData: panel, selectedIndex: this.state.selectedIndex + 1 });
        }
    }

    expandButtonClick = () => {
        this.setState({
            panelData: {
                ...this.state.panelData,
                isExpanded: !this.state.panelData.isExpanded,
                expandedTabs: this.state.panelData.isExpanded !== true ? this.state.panelData.expandedTabs : {},
            },
        });
    }

    public render() {
        var selectedColumn = this.state.panelData.columns && this.state.selectedIndex >= 0 && this.state.selectedIndex < this.state.panelData.columns.length ?
            this.state.panelData.columns[this.state.selectedIndex] :
            null;
        return (
            <div>
                {this.props.isInEditMode ?
                    <div>
                        <div className="row" style={{marginLeft: '0px'}}>
                            {getEditableSideBarCol(this.props, () => this.setState({ showEditor: true, selectedIndex: 0 }))}
                            <div className="col">
                                {this.getActualPanel()}
                            </div>
                        </div>
                        {this.state.showEditor ?
                            <div data-grid="col-12" >
                                <div data-grid="col-1" />
                                <div data-grid="col-11" className="editPanel">
                                    <div data-grid="col-12">
                                        <div data-grid="col-5">
                                        <TextFieldWrapper
                                            value={getLocalizedString(this.state.panelData.heading)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                setDefaultLocalizedString(this.state.panelData, 'heading', newVal.substr(0, Constants.PANEL_HEADING_MAX));
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            placeholder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            label={Intl.Get(LocIds.PageAndPanels.HeadingLabel)}
                                            required={false}
                                            validator={this.panelValidator}
                                        />
                                        <MarkDownEditor
                                            showCommandButton={false}
                                            value={this.state.panelData.subHeading ? getLocalizedString(this.state.panelData.subHeading) : ''}
                                            onChange={(newVal) => {
                                                if (selectedColumn) {
                                                    setDefaultLocalizedString(this.state.panelData, 'subHeading', newVal);
                                                }
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            label={Intl.Get(LocIds.PageAndPanels.PanelSubHeadingLabel)}
                                            required={false}
                                            validator={this.panelValidator}
                                            maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                            placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                        />
                                        <TextFieldWrapperNumeric
                                            numericValue={this.state.panelData.initialColumnCount}
                                            onChangedNumeric={(newVal: number) => {
                                                this.state.panelData.initialColumnCount = newVal;
                                                this.setState({ panelData: this.state.panelData });
                                            }}
                                            label={Intl.Get(LocIds.PageAndPanels.DrawerCountLabel)}
                                            required={false}
                                            validator={this.panelValidator}
                                        />
                                        </div>
                                    </div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={selectedColumn ? getLocalizedString(selectedColumn.name) : ''}
                                                onChange={(newVal) => {
                                                    if (selectedColumn) {
                                                        setDefaultLocalizedString(selectedColumn, 'name', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.TitleLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                selectedIndex={this.state.selectedIndex}
                                                maxCharacter={Constants.PANEL_HEADING_MAX}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.HeadingPlaceHolder)}
                                            />
                                            <MarkDownEditor
                                                showCommandButton={false}
                                                value={selectedColumn ? getLocalizedString(selectedColumn.content) : ''}
                                                onChange={(newVal) => {
                                                    if (selectedColumn) {
                                                        setDefaultLocalizedString(selectedColumn, 'content', newVal);
                                                    }
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.DescriptionLabel)}
                                                required={true}
                                                validator={this.panelValidator}
                                                selectedIndex={this.state.selectedIndex}
                                                maxCharacter={Constants.PANEL_DESCRIPTION_MAX}
                                                placeHolder={Intl.Get(LocIds.PageAndPanels.DescriptionPlaceHolder)}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <ComboBox
                                                defaultSelectedKey={this.state.panelData.theme || 'None'}
                                                text={this.state.panelData.theme || 'None'}
                                                label={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                ariaLabel={Intl.Get(LocIds.PageAndPanels.SelectThemeLabel)}
                                                allowFreeform={true}
                                                autoComplete={'on'}
                                                options={this.themeOptions}
                                                onChange={(event: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                                    this.state.panelData.theme = item.key.toString();
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: '12px' }}>&nbsp;</div>
                                    <div data-grid="col-12" >
                                        <div data-grid="col-5">
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelCallToActionLabel ? getLocalizedString(this.state.panelData.panelCallToActionLabel) : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    setDefaultLocalizedString(this.state.panelData, 'panelCallToActionLabel', newVal);
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionLabel)}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                            <TextFieldWrapper
                                                value={this.state.panelData.panelCallToActionUrl ? this.state.panelData.panelCallToActionUrl : ''}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    this.state.panelData.panelCallToActionUrl = newVal;
                                                    this.setState({ panelData: this.state.panelData });
                                                }}
                                                label={Intl.Get(LocIds.PageAndPanels.PanelCallToActionUrlLabel)}
                                                url={true}
                                                isPage={true}
                                                required={false}
                                                validator={this.panelValidator}
                                            />
                                        </div>
                                    </div>
                                    <PrimaryButton
                                        onClick={() => { this.onSave(); }}
                                        text={Intl.Get(LocIds.PageAndPanels.SaveButtonLabel)}
                                    />
                                    <DefaultButton
                                        onClick={() => { this.onDiscardChange(); }}
                                        text={Intl.Get(LocIds.PageAndPanels.DiscardChangesButtonLabel)}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'Add' }}
                                        text={Intl.Get(LocIds.PageAndPanels.AddColumnLabel)}
                                        onClick={this.onAddColumn}
                                    />
                                    <DefaultButton
                                        iconProps={{ iconName: 'RecycleBin' }}
                                        text={Intl.Get(LocIds.PageAndPanels.DeleteColumnLabel)}
                                        disabled={this.state.panelData.columns && (this.state.panelData.columns.length <= 1)}
                                        onClick={this.onDeleteColumn}
                                    />
                                    <DefaultButton iconProps={{ iconName: 'ChevronUp' }} text={Intl.Get(LocIds.PageAndPanels.MoveUpButtonLabel)} onClick={this.onMoveLeft} />
                                    <DefaultButton iconProps={{ iconName: 'ChevronDown' }} text={Intl.Get(LocIds.PageAndPanels.MoveDownButtonLabel)} onClick={this.onMoveRight} />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                    : this.getActualPanel()
                }
            </div>
        );
    }
}