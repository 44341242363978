import * as moment from 'moment';
import { DocumentCard, DocumentCardDetails, DocumentCardPreview, DocumentCardType, getTheme, IDocumentCardPreviewProps, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import * as React from 'react';
import { connect } from 'react-redux';
import LicenseAgreementPage from 'src/App/Components/Licensing/LicenseAgreementPage';
import { HeroItemPanel } from 'src/App/Components/PageInfrastructure/HeroItemPanel';
import DocumentSeriesVersionsFlyout from 'src/App/Components/SeriesVersionsFlyout';
import { DocumentVersionInfo } from 'src/App/Models/DocumentVersionInfo';
import { DocumentType } from 'src/App/Models/NotificationSettings/DocumentType';
import { DocumentServiceClient } from 'src/App/Services/DocumentVersionInfo';
import { Intl } from 'src/App/Services/GlobalizationService';
import { pageServicesV2Client } from 'src/App/Services/PageServicesV2';
import { downloadFile } from 'src/App/Utilities/DocumentHelper';
import { Page } from 'src/App/Utilities/PageInfrastructure/PagesHelper';
import { PanelSelectionHelperFactory } from 'src/App/Utilities/PageInfrastructure/PanelsHelper';
import { LocalizationIds } from 'src/Common/Utilities/Globalization/IntlEnum';
import { StoreState } from 'src/Store';
import './DocumentDetailPage.css';

export interface DocumentDetailPageProps {
    documentId: string;
    userName?: string;
    isSignedIn: boolean;
    hasAgreedToCurrentLicenseAgreement: boolean;
}

interface DocumentDetailPageState {
    page: Page;
    enableDownload: boolean;
    showAgreement: boolean;
    documentIsPublic: boolean;
    docInfoLoaded: boolean;
    pagePanelLoaded: boolean;
    docNotFound: boolean;
    currentDoc?: DocumentVersionInfo;
    showSeriesVersionsFlyout: boolean;
}

const mapStateToProps = (state: StoreState) => {
    return {
        isSignedIn: state.authentication.isSignedIn,
        hasAgreedToCurrentLicenseAgreement: state.applicationContext.userInfo.hasAgreedToCurrentLicenseAgreement
    };
}

class DocumentDetailPageC extends React.Component<DocumentDetailPageProps & ReturnType<typeof mapStateToProps>, DocumentDetailPageState> {
    private documentId: string;
    private isSignedIn: boolean;

    constructor(props: DocumentDetailPageProps) {
        super(props);
        this.scrollToPageTop();
        this.documentId = props.documentId;
        this.isSignedIn = props.isSignedIn;
        this.state = {
            page: Page.getNewPage(),
            enableDownload: true,
            showAgreement: false,
            documentIsPublic: true,
            docInfoLoaded: false,
            pagePanelLoaded: false,
            docNotFound: false,
            showSeriesVersionsFlyout: false
        };
    }

    componentDidMount() {
        this.getDocInfoData();
        this.getPublicPageByUrlName();
    }

    componentDidUpdate(prevProps: DocumentDetailPageProps) {
        if (!prevProps.hasAgreedToCurrentLicenseAgreement && this.props.hasAgreedToCurrentLicenseAgreement) {
            if (!this.state.enableDownload) {
                this.downloadDocument();
            }
        }
    }

    render() {
        return (
            <div>
                {this.renderActualPage()}
            </div>
        );
    }
    

    private renderActualPage(): JSX.Element {
        const theme = getTheme();
        const { palette, fonts } = theme;
        const previewPropsDownloadIcon: IDocumentCardPreviewProps = {
            previewImages: [
              {
                previewIconProps: {
                  iconName: 'Download',
                  styles: { root: { fontSize: fonts.superLarge.fontSize, color: palette.black } },
                },
                width: 144,
              },
            ]
          };
        const previewPropsVersionIcon: IDocumentCardPreviewProps = {
            previewImages: [
              {
                previewIconProps: {
                  iconName: 'History',
                  styles: { root: { fontSize: fonts.superLarge.fontSize, color: palette.black } },
                },
                width: 144,
              },
            ]
          };
        const heroPanelInfo = this.state.page.panels.filter(p => p.type === PanelSelectionHelperFactory.HERO_ITEM_PANEL);
        return (
            !this.state.docInfoLoaded || !this.state.pagePanelLoaded ? 
            <div>
                <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" className="loading-panel-full" />
            </div>
            :
            <div>
                {heroPanelInfo.length === 1 && 
                    <HeroItemPanel 
                        idOfPage={this.state.page.pageId}
                        panel={heroPanelInfo[0]}
                    />
                }
                {this.state.docNotFound || this.state.currentDoc == null ? 
                    this.getDocumentIsNotAvailableInfo()
                    :
                    <section className="documentdetail-panel" >
                        <h4 className="document-title">{this.state.currentDoc.title.lv}</h4>
                        <span>{this.state.currentDoc.description.lv}</span>
                        <div className="download-area">
                            {this.state.enableDownload ? <DocumentCard type={ DocumentCardType.compact } onClick={this.downloadDocument.bind(this)}>
                                <DocumentCardPreview { ...previewPropsDownloadIcon } />
                                <DocumentCardDetails>
                                    <div className='download-detail'>                                
                                        <p>Download</p>
                                        <p>Last updated on {moment(this.state.currentDoc.whenLastModified).format("YYYY-MM-DD")}</p>
                                    </div>
                                    <i
                                        className={
                                            this.state.currentDoc.fileExtension === '.pdf' ? 'ms-Icon fileextensionIcon ms-Icon--PDF fileextensionPDF'
                                                : this.state.currentDoc.fileExtension === '.doc' || this.state.currentDoc.fileExtension === '.docx' ? 'ms-Icon fileextensionIcon ms-Icon--WordDocument fileextensionWord'
                                                    : this.state.currentDoc.fileExtension === '.xls' || this.state.currentDoc.fileExtension === '.xlsx' ? 'ms-Icon fileextensionIcon ms-Icon--ExcelDocument fileextensionExcel'
                                                        : this.state.currentDoc.fileExtension === '.ppt' || this.state.currentDoc.fileExtension === '.pptx' ? 'ms-Icon fileextensionIcon ms-Icon--PowerPointDocument fileextensionPPT'
                                                            : 'ms-Icon fileextensionIcon ms-Icon--TextDocument'
                                        }
                                        aria-hidden="true"
                                        style={{ fontSize: '50px', margin: 'auto' }}
                                    />
                                </DocumentCardDetails>
                            </DocumentCard>
                            :
                            <DocumentCard type={ DocumentCardType.compact }>
                                <div className='download-spinner'>
                                    <Spinner size={SpinnerSize.large} />
                                </div>
                            </DocumentCard>}
                            {this.state.currentDoc.documentType == DocumentType.Series && <DocumentCard type={ DocumentCardType.compact } onClick={()=>this.setState({showSeriesVersionsFlyout: true})}>
                            <DocumentCardPreview { ...previewPropsVersionIcon } />
                                <DocumentCardDetails>
                                    <div className='docCardContent'>{Intl.Get(LocalizationIds.PageAndPanels.ViewAllVersions)}</div>
                                </DocumentCardDetails>
                            </DocumentCard>}
                        </div>
                    </section>
                }
                {this.state.showAgreement &&
                    <LicenseAgreementPage
                        onAgree={() => {
                            this.setState({ showAgreement: false });
                        }}
                        onDismiss={() => {
                            this.setState({ showAgreement: false, enableDownload: true });
                        }}
                        callBack={() => {
                            this.setState({ showAgreement: false });
                        }}
                    />}
                    {this.state.showSeriesVersionsFlyout &&
                        <DocumentSeriesVersionsFlyout
                            seriesId={this.state.currentDoc.seriesId}
                            onDismissPanel={()=>this.setState({showSeriesVersionsFlyout: false})}
                            isSignedIn={this.props.isSignedIn}
                            hasAgreedToCurrentLicenseAgreement={this.props.hasAgreedToCurrentLicenseAgreement}
                        />}
            </div>
        );
    }

    private getDocumentIsNotAvailableInfo() {
        if (this.state.docNotFound) {
            return (<h3 className="documentnotfound-title">{Intl.Get(LocalizationIds.PageAndPanels.DocumentNotFound)}</h3>);
        }
        if (!this.state.documentIsPublic && this.isSignedIn) {
            return (<h3 className="documentnotfound-title">{Intl.Get(LocalizationIds.PageAndPanels.RestrictedDocument)}</h3>);
        }
        return (<></>);
    }

    private scrollToPageTop(): void {
        window.scrollTo(0, 0);
    }

    downloadDocument() {
        this.setState({enableDownload: false});

        const hasPermissionToDownload =
            this.state.documentIsPublic ||
            (this.isSignedIn && this.props.hasAgreedToCurrentLicenseAgreement);

        // user doesn't have permission so we'll have them login and/or do the agreement
        if (!hasPermissionToDownload) {
            this.setState({ showAgreement: true });
            return;
        }

        if(this.state.currentDoc != null){
            var fileName = this.state.currentDoc.title.lv + this.state.currentDoc.fileExtension;
            downloadFile(this.documentId, fileName, () => {
                this.setState({enableDownload: true});
            }, this.state.currentDoc.id);
        }
    }

    private getDocInfoData(): void {
        this.setState({docInfoLoaded: false, currentDoc: undefined, docNotFound: false, documentIsDeleted: false})
        DocumentServiceClient.GetLiveDocumentIncludingOldSeriesDoc(
            this.documentId,
            (r) => {
                const currentDoc = r.data as DocumentVersionInfo;
                this.setState({
                    currentDoc: currentDoc,
                    docInfoLoaded: true,
                    documentIsPublic: currentDoc && currentDoc.isPublic
                });
            },
            (r) => {
                if (r.code === 401){
                    this.setState({showAgreement: true, documentIsPublic: false});
                }
                else if (r.code === 400 || r.code === 404 || r.code === 406){
                    this.setState({docNotFound: true});
                }
                this.setState({
                    docInfoLoaded: true
                });
            },
            undefined,
            undefined,
            false,
            new Array(400, 401, 404, 406)
        );
    }

    private getPublicPageByUrlName(): void {
        this.setState({pagePanelLoaded: false})
        performance.mark('pageRender start');
         pageServicesV2Client.getPublicPageByUrlName(
            'DocDetailPage',
            (response) => {
                this.setState({pagePanelLoaded: true})
                let page = response.data as Page;
                this.setState({ page: page });
                performance.mark('pageRender done');
                performance.measure('pageRenderedInMs', 'pageRender start', 'pageRender done');
            },
            () => { this.setState({pagePanelLoaded: true}) }
        );
    }
}

export const DocumentDetailPage = connect(mapStateToProps)(DocumentDetailPageC);