import { setCookieValue, getCookieValue } from '../../Common/Utilities/BrowserGlobals';

// Storage item
export interface StpStorageItem {
    // expiration date
    expiresOn: Date;
    // any text-based data
    data: string;
    // any numeric data. Optional
    dataInt?: number;
}

interface StpStorageItemPrivate {    
    expiresOnTime: number;
    data: string;
    dataInt: string;
}

// Defines members required to access a token storage
export interface StpStorageAccessor {
    getItem: (key: string, checkExpiration: boolean) => StpStorageItem | null;
    setItem: (key: string, value: StpStorageItem) => void;
    removeItem: (key: string) => void;
    usesLegacyStorage(): boolean;
}

interface PrivateStorageAccessor {
    getItem: (key: string) => StpStorageItemPrivate | null;
    setItem: (key: string, value: StpStorageItemPrivate) => void;
    removeItem: (key: string) => void;
}

class LocalStorageAccessor implements PrivateStorageAccessor {
    // Reads local storage item and parses it into an Stp storage item for a given key
    getItem(key: string): StpStorageItemPrivate | null {
        try {
            let localValue = window.localStorage.getItem(key);
            if (localValue) {
                return JSON.parse(localValue) as StpStorageItemPrivate;
            }
        } catch {
            // ignore. Invalid local storage format
        }
        return null;
    }    
    // Saves data into a local storage item
    setItem(key: string, value: StpStorageItemPrivate): void {        
        window.localStorage.setItem(key, JSON.stringify(value));
    }    
    // removes cookie for a given key
    removeItem(key: string): void {
        window.localStorage.removeItem(key);
    }
}

class CookieStorageAccessor implements PrivateStorageAccessor {
    private readonly cookieDelimiter = '|-|';
    // Reads cookie and parses it into an Stp storage item for a given key
    getItem(key: string): StpStorageItemPrivate | null {
        let cookieFields = this.getCookieFields(key);
        if (cookieFields !== null && cookieFields.length >= 3) {
            return {
                expiresOnTime: parseInt(cookieFields[0], 10),
                dataInt: cookieFields[1],
                data: cookieFields[2]                
            } as StpStorageItemPrivate;
        }
        return null;
    }
    
    // Saves data into a cookie
    setItem(key: string, value: StpStorageItemPrivate): void {        
        let cookieData: string[] = [value.expiresOnTime.toString(), value.dataInt, value.data];
        this.setCookieFields(key, cookieData, value.expiresOnTime);
    }

    // clears the cookie by passing in Date(1970, 0, 1, 0, 0, 0)
    removeItem(key: string): void {
        this.setCookieFields(key, null, (new Date(1970, 0, 1, 0, 0, 0)).getTime());
    }

    // sets cookie field values as a string
    private setCookieFields(key: string, cookieFields: string[] | null, expiresOnTime: number): void {        
        let cookieString = '';
        if (cookieFields !== null) {
            cookieString = cookieFields.join(this.cookieDelimiter);
        }
        setCookieValue(key, cookieString, new Date(expiresOnTime));        
    }

    // reads cookie field values string separated by this.cookieDelimiter and returns it as a string array
    private getCookieFields(key: string): string[] | null {
        const cookieVal = getCookieValue(key);
        if (cookieVal && cookieVal !== null && cookieVal.length > 0) {
            return cookieVal.split(this.cookieDelimiter);
        }
        return null;
    }
}

// Implements StpStorageAccessor to access browser's local storage
export class StpLocalStorageAccessor implements StpStorageAccessor {
    private useLegacyStorage: boolean = false;
    private localStorageSupported: boolean | undefined;
    private accessor: PrivateStorageAccessor;

    constructor(useLegacyStorage: boolean | undefined) {
        this.useLegacyStorage = useLegacyStorage === true;
        if (!this.usesLegacyStorage() && this.isLocalStorageSupported) {
            // wire up local storage callbacks
            this.accessor = new LocalStorageAccessor();            
        } else {
            // wire up cookie base fallback callbacks
            this.accessor = new CookieStorageAccessor();
        }
    }
    usesLegacyStorage(): boolean {
        return this.useLegacyStorage;
    }

    // returns storage item for a given key.
    // if checkExpiration = true then we will only resturn an item that hasn't expired yet
    getItem(key: string, checkExpiration: boolean): StpStorageItem | null {
        if (key && key.length > 0) {
            let valueItem = this.accessor.getItem(key);            
            if ( valueItem && (!checkExpiration || (new Date()).getTime() <= valueItem.expiresOnTime)) {
                return this.privateToPublicItem(valueItem);
            }
        }
        return null;
    }

    // sets value for a given key and storage item
    setItem(key: string, value: StpStorageItem): void {
        if (key  && value) {
            // Only update local storage in case we have newer token
            const newItem = this.publicToPrivateItem(value);            
            const existingItem = this.accessor.getItem(key);
            let needUpdate = !existingItem || (existingItem == null);
            if (existingItem) {                
                needUpdate = existingItem.expiresOnTime < newItem.expiresOnTime;                
            }
            if (needUpdate) {
                this.accessor.setItem(key, newItem);                
            }
        }
    }

    // removes storage item given item key
    removeItem(key: string): void {
        if (key && key.length > 0) {
            this.accessor.removeItem(key); 
        }    
    }

    // Converts private storage item into public storage item
    private privateToPublicItem(value: StpStorageItemPrivate): StpStorageItem {
        return {
            expiresOn: new Date(value.expiresOnTime),
            data: value.data,
            dataInt: (value.dataInt === 'na') ? undefined : parseInt(value.dataInt, 10),
        } as StpStorageItem;
    }
    
    // Converts public storage item into private storage item
    private publicToPrivateItem(value: StpStorageItem): StpStorageItemPrivate {
        return {
            expiresOnTime: value.expiresOn.getTime(),            
            data: value.data,
            dataInt: (value.dataInt === undefined) ? 'na' : value.dataInt.toString(),
        } as StpStorageItemPrivate;
    }

    // Checks if current browser implementation supports window.localStorage
    private get isLocalStorageSupported(): boolean {
        if (this.localStorageSupported === undefined) {
            try {
                if (window.localStorage) {
                    const stpLocalStorageTestKey: string = 'stp-local-storage-test';
                    const stpLocalStorageTestValue: string = 'stp-local-storage';
                    window.localStorage.setItem(stpLocalStorageTestKey, stpLocalStorageTestValue);
                    this.localStorageSupported  = window.localStorage.getItem(stpLocalStorageTestKey) === stpLocalStorageTestValue;
                    window.localStorage.removeItem(stpLocalStorageTestKey);
                } else {
                    this.localStorageSupported = false;
                }
                
            } catch {
                this.localStorageSupported = false; 
            }
        }
        return this.localStorageSupported === true;
    }
}