import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';
import { default as axios, AxiosResponse } from 'axios';
import * as FileSaver from 'file-saver';
import { store } from '../../Store';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../Services/GlobalizationService';
import { SelectedVocabulary, VocabularyDefinition, VocabularySelection } from '../Models/Vocabulary';
import { ModelWithVocabularySelections } from '../Models/ModelWithVocabularySelections';
import TableItem from '../Models/TableItem';
import { getWindowLocationOrigin, getWindowLocationPathname } from '../../Common/Utilities/BrowserGlobals';
import { DocumentType } from '../Models/NotificationSettings/DocumentType';

export function getTableItemId(tableItem: TableItem): string {
    return tableItem.documentType === DocumentType.Series ? tableItem.seriesId : tableItem.documentId;
}

export function getDocumentTypeAriaLabel(fileExtension: string | undefined): string {
    if (fileExtension) {
        switch (fileExtension) {
            case '.docx':
            case '.doc':
                return 'Microsoft Word';
            case '.xlsx':
            case '.xls':
                return 'Microsoft Excel';
            case '.pptx':
            case '.ppt':
                return 'Microsoft PowerPoint';
            default:
                return fileExtension.toLocaleUpperCase();
        }
    }

    return '';
}

export function getFabricIconByExtension(ext: string | undefined): string {
    return ext === '.pdf' ? 'ms-Icon--PDF'
        : ext === '.doc' || ext === '.docx' ? 'ms-Icon--WordDocument'
            : ext === '.xls' || ext === '.xlsx' ? 'ms-Icon--ExcelDocument'
                : ext === '.ppt' || ext === '.pptx' ? 'ms-Icon--PowerPointDocument'
                    : 'ms-Icon--TextDocument';
}

export function getDocumentDetailLink(documentId: string) {
    var url = getWindowLocationOrigin() + '/DocumentPage/' + documentId;
    return url;
}

export function getDownloadDocumentLink(documentId: string, parentId?: string, docTab?: string, filter?: string) {
    var url = getWindowLocationOrigin() + getWindowLocationPathname().replace('/Pages/', '/ViewPage/');
    url += '?command=Download&downloadType=Document&downloadId=' + documentId;
    if (parentId) {
        url +=  '&tab=' + encodeURIComponent(parentId);
    }
    if (docTab) {
        url +=  '&docTab=' + encodeURIComponent(docTab.replace(/\s/g, '_'));
    }
    if (filter) {
        url += ('&' + filter);
    }
    return url;
}

export function getApiDownloadLink(documentId: string, documentVersionId?: string) {
    if (documentVersionId) {
        return ServiceTrustClient.getWebApiEndpointUrl() + '/api/v2/downloadDocuments/' + documentId + '/' + documentVersionId;
    }
    return ServiceTrustClient.getWebApiEndpointUrl() + '/api/v2/downloadDocuments/' + documentId;
}

export function downloadFile(documentId: string, filename: string, setDownloadFinished: () => void, documentVersionId?: string) {
    var url = getApiDownloadLink(documentId, documentVersionId);
    var header = {};
    if (store.getState().authentication.idToken) {
        header = {
            'Authorization': store.getState().authentication.idToken,
        };
    }
    axios.request({
        responseType: 'arraybuffer',
        url: url,
        method: 'GET',
        headers: header,
        }).then(
            (result) => {
                doSaveFile(result, filename);
                setDownloadFinished();
                return;
            }, 
            () => {
                alert(Intl.Get(LocIds.PageAndPanels.ErrorDownloadMessage));
                setDownloadFinished();
            });
}

function doSaveFile(response: AxiosResponse, filename: string) {
    var blob = new Blob([response.data], { type: 'application/octet-stream;charset=utf-8' });
    FileSaver.saveAs(blob, filename);
}

export function isExternalLink(contentHash: string): boolean {
    return /^EXTERNAL,/.test(contentHash);
}

export function getExternalLink(contentHash: string): string {
    if (!isExternalLink(contentHash)) {
        return '';
    }
    var parsedContentHash = contentHash.split(',');
    return (parsedContentHash && parsedContentHash.length >= 2) ? parsedContentHash[1] : '';
}

/**
 * Returns all items that have the required vocabularies.
 *
 * @param items the items to be filtered.
 * @param requiredVocabularies the required vocabularies used for filtering.
 * @param vocabularyList the complete list of vocabulary that will be used for lookup. This usually comes from redux store.
 */
export function getFilteredItems(items: TableItem[], requiredVocabularies: SelectedVocabulary[] | undefined, vocabularyList: VocabularyDefinition[] | undefined): TableItem[] {
    let ret: TableItem[] = [];

    // Return all the items if there are no required vocabularies.
    if (!requiredVocabularies || requiredVocabularies.length === 0) {
        return items;
    }

    requiredVocabularies.forEach(vocab => {
        // Skip vocabulary if it has no child values.
        if (!vocab.choice) {
            return;
        }

        let temp: TableItem[] = [];

        vocab.choice.forEach(choice => {
            filterItemsByVocabulary(
                ret.length <= 0 ? items : ret,
                { vocabulary: vocab.vocabulary, choice: [choice], choiceDisplay: [], shouldExcludeValueOfAll: vocab.shouldExcludeValueOfAll },
                vocabularyList).forEach(t => {
                if (temp.indexOf(t) < 0) {
                    temp.push(t);
                }
            });
        });
        ret = temp;
    });
    return ret;
}

/**
 * Returns all the items that contains all the choices from the vocab paremeter.
 * @param items documents that to be filtered
 * @param vocab the required vocabularies used for filtering
 * @param vocabularyList the complete list of vocabulary that will be used for lookup. This usually comes from redux store.
 */
export function filterItemsByVocabulary(items: TableItem[], filter: SelectedVocabulary, vocabularyList: VocabularyDefinition[] | undefined): TableItem[] {
    // If filter is not valid, return the items
    if (!filter || (!filter.vocabulary && !filter.choice))
    {
        return items;
    }

    // If vocabulary not exists in vocabularies, return the items
    const vocabulary = vocabularyList && vocabularyList.find((v) => v.name === filter.vocabulary);

    if (!vocabulary) {
        return items;
    }

    return items.filter((item) => {
        // Get the choices from the item based on the vocabulary name.
        var choiceSelections = item.vocabularySelections.find(sel => sel.vocabularyName === vocabulary.name);

        if (choiceSelections && choiceSelections.vocabularyValueIds.length > 0)
        {   
            if (choiceSelections.vocabularyValueIds[0] === "All" && !filter.shouldExcludeValueOfAll) {
                return true;
            } else
            {
                return choiceSelections.vocabularyValueIds.some(val => filter.choice.indexOf(val) >= 0);
            }
        }

        return false;
    });
}

/**
 * Returns the vocabulary with all the choices from an item. If choice is equal to 'All', it returns all the choices from that vocabulary.
 * @param item the item we want to get the vocabulary from
 * @param vocabulary the selected vocabulary we want to get from the item
 * @param vocabularyList the complete list of vacabularies
 */
export function getVocabularyChoicesFromItem(item: ModelWithVocabularySelections, vocabulary: SelectedVocabulary, vocabularyList: VocabularyDefinition | undefined): VocabularySelection | undefined {
    var choiceSelections = item.vocabularySelections.find(sel => sel.vocabularyName === vocabulary.vocabulary);
    
    if (choiceSelections && choiceSelections.vocabularyValueIds.length === 1 && choiceSelections.vocabularyValueIds[0] === 'All' && vocabularyList && vocabularyList.name === vocabulary.vocabulary) {
        var allChoiceSelections = vocabularyList.choices.map(c => c.id);
        return { vocabularyName: vocabulary.vocabulary, vocabularyValueIds: allChoiceSelections };
    }
    return choiceSelections;
}