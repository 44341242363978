import * as React from 'react';
import { PanelSelectionHelperFactory, getMainPanelOptions } from '../../Utilities/PageInfrastructure/PanelsHelper';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { IContextualMenuItem } from 'office-ui-fabric-react/lib/ContextualMenu';
import { PanelCollection } from './PanelsCollection';
import { CommandBarButtonFactory, ButtonTypes } from '../../../Common/Utilities/CommanBarButtonFactory';
import { ViewData, PanelData } from '../../Models/PageInfrastructure';
import { PanelPropertiesDataBase } from '../../Models/PageInfrastructure/PanelPropertiesData/';
import { LocalizationIds as LocIds } from '../../../Common/Utilities/Globalization/IntlEnum';
import { Intl } from '../../Services/GlobalizationService';
import { TextFieldWrapper } from '../TextFieldWrapper';
import { getLocalizedString, makeLocalizedString } from '../../../Common/Utilities/LocalizationHelper';
import { Validator } from '../../../Common/Utilities/Validator';
import { LeftRightViewsPanelPropertiesData } from '../../../App/Models/PageInfrastructure/PanelPropertiesData/LeftRightViewsPanelPropertiesData';
import { ComboBox, IComboBox, IComboBoxOption } from 'office-ui-fabric-react';
import { InPageNavigationNode } from '../../../App/Models/InPageNavSettings/InPageNavigationNode';
import './LeftRightViewsCollections.css';

export enum ViewIndex {
    LEFT = 0,
    RIGHT = 1
}

export enum ViewString {
    LEFT = 'Left',
    RIGHT = 'Right'
}

export interface LeftRightViewsCollectionProps {
    views: ViewData[];
    panel: PanelData<LeftRightViewsPanelPropertiesData>;
    isInEditMode: boolean;
    addNewView: () => void;
    modifySelectedView: (index: number) => void;
    moveSelectedViewLeft: (index: number) => void;
    moveSelectedViewRight: (index: number) => void;
    deleteSelectedView: (index: number) => void;
    makeDirty: () => void;
    delete: () => void;
    moveUp: () => void;
    moveDown: () => void;
    idOfPage: string;
    parentId: string;
}

export interface ViewsCollectionState {
    views: ViewData[];
    inPageNavLinks: InPageNavigationNode[];
    selectedMainView: string;
}

export class LeftRightViewsCollection extends React.Component<LeftRightViewsCollectionProps, ViewsCollectionState> {
    private addViewButton: IContextualMenuItem;
    private modifyViewButton: IContextualMenuItem;
    private moveLeftViewButton: IContextualMenuItem;
    private moveRightViewButton: IContextualMenuItem;
    private deleteViewButton: IContextualMenuItem;
    private deletePanelButton: IContextualMenuItem;
    private movePanelUpButton: IContextualMenuItem;
    private movePanelDownButton: IContextualMenuItem;
    private selectedIndex: number;
    private mainPanelOptions: IComboBoxOption[];

    constructor(props: LeftRightViewsCollectionProps) {
        super(props);

        let inPageNav = props.panel.panelInfo !== null ? (props.panel.panelInfo.inPageNavLinks ? props.panel.panelInfo.inPageNavLinks : []) : [];

        this.mainPanelOptions =  getMainPanelOptions();
        this.state = {
            views: props.views ? props.views : [],
            inPageNavLinks: inPageNav,
            selectedMainView: props.panel.panelInfo.selectedMainView
        };
        this.selectedIndex = 0;
        this.addViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Add, () => this.props.addNewView(), Intl.Get(LocIds.PageAndPanels.AddViewButtonLabel));
        this.modifyViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Edit, () => this.props.modifySelectedView(this.selectedIndex));
        this.moveLeftViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveLeft, () => this.props.moveSelectedViewLeft(this.selectedIndex));
        this.moveRightViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveRight, () => this.props.moveSelectedViewRight(this.selectedIndex));
        this.deleteViewButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.deleteSelectedView(this.selectedIndex));
        this.deletePanelButton = CommandBarButtonFactory.GetButton(ButtonTypes.Delete, () => this.props.delete(), Intl.Get(LocIds.PageAndPanels.DeletePanelLabel), 'DeletePanel');
        this.movePanelUpButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveUp, () => this.props.moveUp(), Intl.Get(LocIds.PageAndPanels.MoveUpPanelButtonDescription));
        this.movePanelDownButton = CommandBarButtonFactory.GetButton(ButtonTypes.MoveDown, () => this.props.moveDown(), Intl.Get(LocIds.PageAndPanels.MoveDownPanelButtonDescription));
    }

    UNSAFE_componentWillReceiveProps(nextProps: LeftRightViewsCollectionProps) {
        if (nextProps.views && nextProps.views.length > 0) {
            let selectedItem = nextProps.views.find(x => x.isSelected);
            if (selectedItem) {
                this.selectedIndex = nextProps.views.indexOf(selectedItem);
            }
        }
        this.setState({ views: nextProps.views ? nextProps.views : [],
            // TODO: set this to the right thing
            inPageNavLinks: nextProps.panel.panelInfo.inPageNavLinks ?  nextProps.panel.panelInfo.inPageNavLinks : []
        });
    }

    selectTab(index: number) {
        if (this.selectedIndex === index) {
            return;
        }

        let views: ViewData[] = this.state.views;
        views.forEach((value: ViewData, i: number) => {
            value.isSelected = (i === index);
        });
        this.selectedIndex = index;
        this.setState({ views: views });
    }

    addNewPanel(selectedIndex: number, type: string) {
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        selectedView.panels.push(PanelSelectionHelperFactory.getPanel(type));
        this.props.makeDirty();
        this.selectTab(selectedIndex);
        this.setState({
            views: this.props.views,
            inPageNavLinks: [ ...this.state.inPageNavLinks, { title: makeLocalizedString(''), anchorName: `#in-page-nav-${this.state.inPageNavLinks.length}` }]
        });
    }

    deletedPanel(selectedIndex: number, panel: PanelData<PanelPropertiesDataBase>) {
        let selectedView: ViewData = this.props.views[this.selectedIndex];
        let index = selectedView.panels.indexOf(panel);
        let panels = selectedView.panels;
        selectedView.panels = [];
        for (let i = 0; i < panels.length; i++) {
            if (i !== index) {
                selectedView.panels.push(panels[i]);
            }
        }
        this.props.makeDirty();
        this.selectTab(selectedIndex);
        this.setState({views: this.props.views});
    }

    movePanelUp(selectedIndex: number, panel: PanelData<PanelPropertiesDataBase>) {
        let viewIndex = ViewIndex.LEFT;
        let searchIndex = this.props.views[viewIndex].panels.indexOf(panel);
        if (searchIndex < 0) {
            viewIndex = ViewIndex.RIGHT;
            searchIndex = this.props.views[viewIndex].panels.indexOf(panel);
        }

        if (searchIndex > 0) {
            this.swapPanels(searchIndex, searchIndex - 1, viewIndex);
            this.props.makeDirty();
        }
        this.selectTab(selectedIndex);
        this.setState({views: this.props.views});
    }

    movePanelDown(selectedIndex: number, panel: PanelData<PanelPropertiesDataBase>) {
        let viewIndex = ViewIndex.LEFT;
        let searchIndex = this.props.views[viewIndex].panels.indexOf(panel);
        if (searchIndex < 0) {
            viewIndex = ViewIndex.RIGHT;
            searchIndex = this.props.views[viewIndex].panels.indexOf(panel);
        }

        if (searchIndex < this.props.views[viewIndex].panels.length - 1) {
            this.swapPanels(searchIndex, searchIndex + 1, viewIndex);
            this.props.makeDirty();
        }
        this.selectTab(selectedIndex);
        this.setState({views: this.props.views});
    }

    swapPanels(panelIndex1: number, panelIndex2: number, viewIndex?: number) {
        const currentViewIndex = viewIndex === undefined ? this.selectedIndex : viewIndex;
        let selectedView: ViewData = this.props.views[currentViewIndex];
        let temp = selectedView.panels[panelIndex1];
        selectedView.panels[panelIndex1] = selectedView.panels[panelIndex2];
        selectedView.panels[panelIndex2] = temp;

        const { selectedMainView, inPageNavLinks } = this.state;
        let links = [ ...inPageNavLinks ];

        if ((currentViewIndex === ViewIndex.LEFT && selectedMainView === ViewString.LEFT) || (currentViewIndex === ViewIndex.RIGHT && selectedMainView === ViewString.RIGHT)) {
            // we are swapping panels on the current 'in page nav' view
            let label = links[panelIndex1] ? links[panelIndex1].title : makeLocalizedString('');
            links[panelIndex1].title = links[panelIndex2] ? links[panelIndex2].title : makeLocalizedString('');
            links[panelIndex2].title = label;
        }
        this.setState({ views: this.props.views, inPageNavLinks: links });
    }

    updateInPageNavListing(newVal: string) {
        if (newVal !== this.state.selectedMainView) {
            let viewIndex = newVal === ViewString.LEFT ? ViewIndex.LEFT : ViewIndex.RIGHT;
            const inPageNavLinks = [ ...this.state.inPageNavLinks ];
            // if it is not equal to the stored value then we need to update the listing to remove any indexes > the panel count.
            if (inPageNavLinks.length > this.state.views[viewIndex].panels.length) {
                inPageNavLinks.splice(this.state.views[viewIndex].panels.length, this.state.inPageNavLinks.length);
                this.setState({ inPageNavLinks });
            }
        }
    }

    isMainColumn(index: number, selectedMainView: string) {
        if ((selectedMainView === ViewString.LEFT && index === 0) || (selectedMainView === ViewString.RIGHT && index === 1)) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <div>
                {this.props.isInEditMode &&
                    <div data-grid="col-12">
                        <CommandBar
                            items={[this.addViewButton, this.modifyViewButton, this.moveLeftViewButton, this.moveRightViewButton, this.deleteViewButton]}
                            farItems={[this.deletePanelButton, this.movePanelUpButton, this.movePanelDownButton]}
                        />
                        <div data-grid="col-3">
                            <ComboBox
                                defaultSelectedKey={this.state.selectedMainView || ViewString.LEFT}
                                text={this.state.selectedMainView || ViewString.LEFT}
                                label={Intl.Get(LocIds.PageAndPanels.MainPanelLabel)}
                                ariaLabel={Intl.Get(LocIds.PageAndPanels.MainPanelLabel)}
                                allowFreeform={true}
                                autoComplete={'on'}
                                options={this.mainPanelOptions}
                                onChange={(ev: React.FormEvent<IComboBox>, item: IComboBoxOption) => {
                                    this.updateInPageNavListing(item.key.toString());
                                    this.props.panel.panelInfo.selectedMainView = item.key.toString();
                                    this.props.makeDirty();
                                    this.setState({ selectedMainView: item.key.toString() });
                                }}
                            />
                        </div>

                        {this.state.views.map((data: ViewData, viewIndex) =>
                            <div key={`navContainer-${viewIndex}`} data-grid="col-12" >
                                {(viewIndex === ViewIndex.LEFT && this.state.selectedMainView === ViewString.LEFT) || (viewIndex === ViewIndex.RIGHT && this.state.selectedMainView === ViewString.RIGHT) ?
                                    <div>
                                        {data.panels.map((panel, index) =>
                                            <TextFieldWrapper
                                                key={`linkeditor-${index}`}
                                                value={this.state.inPageNavLinks[index] !== undefined ? getLocalizedString(this.state.inPageNavLinks[index].title) : ''}
                                                validator={(new Validator())}
                                                onChange={(ev: React.ChangeEvent<HTMLInputElement>, newVal: string) => {
                                                    let newInPageNavLinks = [
                                                        ...this.state.inPageNavLinks
                                                    ];
                                                    let navNode = { title: makeLocalizedString(newVal), anchorName: `#in-page-nav-${index}` };
                                                    newInPageNavLinks[index] = navNode;
                                                     // fill any undefined with a default node.
                                                    for (let i = 0; i < newInPageNavLinks.length; i++) {
                                                        newInPageNavLinks[i] = newInPageNavLinks[i] === undefined ? { title: makeLocalizedString(''), anchorName: `#in-page-nav-${index}` } : newInPageNavLinks[i];
                                                     }
                                                    this.setState({ inPageNavLinks: newInPageNavLinks });
                                                    this.props.panel.panelInfo.inPageNavLinks = newInPageNavLinks;
                                                    this.props.makeDirty();
                                                }}
                                                placeholder="link text here"
                                                label={`Panel ${index + 1} Nav Link Text`}
                                            />
                                        )}
                                    </div> :
                                    null
                                }
                            </div>
                        )}
                    </div>
                }

                {this.state.views.length > 1 || this.props.isInEditMode ?
                    <div data-grid="col-12" data-panel-type="Left Right Columns">
                        {this.state.inPageNavLinks.length > 0 &&
                            <div  className="m-in-page-navigation">
                                 <nav className="c-in-page-navigation stp_left-right_navigation">
                                    <ul>
                                        {this.state.inPageNavLinks.map((link, linkIndex) => {let nodeDisplayText = getLocalizedString(link.title);
                                                                                             return (link !== undefined &&  nodeDisplayText !== '' && nodeDisplayText !== null && link.anchorName  === `#in-page-nav-${linkIndex}`) ?
                                                <li key={`navLink-${linkIndex}`}>
                                                    <a className="c-hyperlink stp_left-right_pivot-link" href={link.anchorName} aria-label="In-page navigation Navigation Link">{nodeDisplayText}</a>
                                                </li>
                                            : null; }
                                        )}
                                    </ul>
                                </nav>
                            </div>
                        }

                        {this.state.views.map((data: ViewData, index) =>
                            <div data-grid={'col-' + data.widthInGridUnits} key={index} className={`${index === this.selectedIndex && this.props.isInEditMode ? 'selected-view' : ''} ${this.isMainColumn(index, this.state.selectedMainView) ? 'panels-collection_main-column' : 'panels-collection_side-column'}`}>
                                <PanelCollection
                                    title=""
                                    panels={data.panels}
                                    selectView={() => this.selectTab(index)}
                                    deletePanel={(n) => this.deletedPanel(index, n)}
                                    isInEditMode={this.props.isInEditMode}
                                    addNewPanel={(type) => this.addNewPanel(index, type)}
                                    makeDirty={() => this.props.makeDirty()}
                                    movePanelUp={(n) => this.movePanelUp(index, n)}
                                    movePanelDown={(n) => this.movePanelDown(index, n)}
                                    idOfPage={this.props.idOfPage}
                                    parentId={this.props.parentId}
                                    hasInPageNav={(
                                        (index === ViewIndex.LEFT && this.state.selectedMainView === ViewString.LEFT) ||
                                        (index === ViewIndex.RIGHT && this.state.selectedMainView === ViewString.RIGHT)
                                    )}
                                />
                            </div>
                        )}
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}