import * as React from 'react';
import {
    DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, Link, Spinner, SpinnerSize, ICommandBarItemProps, CommandBar, ICommandBarStyles,
} from 'office-ui-fabric-react/lib/';
import { DocumentServiceClient } from '../Services/DocumentVersionInfo';
import { DocumentDownloadHistoryDetail } from '../Models/Documents/DocumentDownloadHistoryDetail';
import { Intl } from '../Services/GlobalizationService';
import { LocalizationIds as LocIds } from '../../Common/Utilities/Globalization/IntlEnum';
import { DocumentDownloadHistoryMetadata, DocumentVersionStatus } from '../Models/Documents/DocumentDownloadHistoryMetadata';
import { ErrorResponse } from '../Services/Models';
import { store } from 'src/Store';
import { DocumentsDownloadHistoryFilter } from '../Models/Documents/DocumentsDownloadHistoryFilter';
import * as $ from 'jquery';
import * as FileSaver from 'file-saver';
import LicenseAgreementPage from '../Components/Licensing/LicenseAgreementPage';
import { pageServicesV2Client } from '../Services/PageServicesV2';
import { HeroItemPanelPropertiesData } from '../Models/PageInfrastructure';
import { HeroItemPanel } from '../Components/PageInfrastructure/HeroItemPanel';
import { AreaHeadingPanel } from '../Components/PageInfrastructure/AreaHeadingPanel';
import { makeLocalizedString } from '../../Common/Utilities/LocalizationHelper';
import { PanelSelectionHelperFactory } from '../Utilities/PageInfrastructure/PanelsHelper';

interface DocumentDownloadHistoryPageProps { }

interface DocumentDownloadHistoryPageState {
    rawDocuments: DocumentDownloadHistoryDetail;
    isLoading: boolean,
    filter: DocumentsDownloadHistoryFilter;
    isLoadingMore: boolean;
    disableExportButton: boolean;
    showAgreement: boolean;
    heroItemPanelProps: HeroItemPanelPropertiesData;
}

export class DocumentDownloadHistoryPage extends React.Component<DocumentDownloadHistoryPageProps, DocumentDownloadHistoryPageState> {
    private _columns: IColumn[] = [
        {
            key: 'Title',
            name: Intl.Get(LocIds.DocumentDownloadHistoryPage.TitleColumnLabel),
            fieldName: 'title',
            minWidth: 100,
            isResizable: true,
            onRender: (item: DocumentDownloadHistoryMetadata) => {
                return (
                        <span>
                            <i
                                className={
                                    item.fileExtension === '.pdf' ? 'ms-Icon fileextensionIcon ms-Icon--PDF fileextensionPDF'
                                        : item.fileExtension === '.doc' || item.fileExtension === '.docx' ? 'ms-Icon fileextensionIcon ms-Icon--WordDocument fileextensionWord'
                                            : item.fileExtension === '.xls' || item.fileExtension === '.xlsx' ? 'ms-Icon fileextensionIcon ms-Icon--ExcelDocument fileextensionExcel'
                                                : item.fileExtension === '.ppt' || item.fileExtension === '.pptx' ? 'ms-Icon fileextensionIcon ms-Icon--PowerPointDocument fileextensionPPT'
                                                    : 'ms-Icon fileextensionIcon ms-Icon--TextDocument'
                                }
                                aria-hidden="true"
                            />
                            {item.documentVersionStatus != DocumentVersionStatus.Deleted && <Link href={window.location.protocol + "//" + window.location.host + "/DocumentPage/" + item.documentId}>{(item.title)}</Link>}
                            {item.documentVersionStatus == DocumentVersionStatus.Deleted && <div>{(item.title)}</div>}
                        </span>
                );
            }
        },
        {
            key: 'whenDownloaded',
            name: Intl.Get(LocIds.DocumentDownloadHistoryPage.DocumentDownloadTimeLabel),
            fieldName: 'whenDownloaded',
            minWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: DocumentDownloadHistoryMetadata) => {
                return (
                    <div>
                        {new Date(item.whenDownloaded).toLocaleDateString()}
                    </div>
                );
            }
        },
        {
            key: 'status',
            name: Intl.Get(LocIds.DocumentDownloadHistoryPage.DocumentStatusLabel),
            fieldName: 'status',
            minWidth: 300,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: DocumentDownloadHistoryMetadata) => {
                return (
                    <div>
                        {
                            item.documentVersionStatus == DocumentVersionStatus.Deleted ? Intl.Get(LocIds.DocumentDownloadHistoryPage.DocumentDeletedStatus) : 
                            item.documentVersionStatus == DocumentVersionStatus.HasNewerVersion ? Intl.Get(LocIds.DocumentDownloadHistoryPage.DocumentHasNewerVersionStatus)  :
                            Intl.Get(LocIds.DocumentDownloadHistoryPage.DocumentLiveStatus) 
                        }
                    </div>
                );
            }
        }
    ];

    constructor(props: DocumentDownloadHistoryPageProps) {
        super(props);
        this.state = {
            rawDocuments: { documentDownloadHistoryMetadata: [], continuationToken: null } as DocumentDownloadHistoryDetail,
            isLoading: true,
            isLoadingMore: false,
            filter: {
                pageSize: 150,
                continuationToken: null,
            },
            disableExportButton: false,
            showAgreement: !store.getState().authentication.isSignedIn,
            heroItemPanelProps: {}
        };
    }

    componentDidMount() {
        if (store.getState().authentication.isSignedIn) {
            this.getDocumentDownloadHistory();
        }
        window.addEventListener('scroll', () => this.onScroll(), false);
        document.title = Intl.Get(LocIds.DocumentDownloadHistoryPage.MainTitle);
        this.getHomePageBanner();
    }

    render() {
        const commandBarStyles: Partial<ICommandBarStyles> = { root: { height: 64, paddingTop: 20 } };
        const commandBarItems: ICommandBarItemProps[] = [
            {
                key: 'exportDownloadHistory',
                text: Intl.Get(LocIds.DocumentDownloadHistoryPage.ExportDownloadHistoryLable),
                onClick: () => this.exportDownloadHistory(),
                disabled: this.state.disableExportButton,
                iconProps: { iconName: 'Download' }
            }
        ];
        if (!store.getState().authentication.isSignedIn) {
            return (
            <div>
                {this.state.showAgreement &&
                <LicenseAgreementPage
                    onAgree={() => {
                        this.setState({showAgreement: false});
                    }}
                    onDismiss={() => {
                        this.setState({showAgreement: false});
                        window.location.assign('/');
                    }}
                    callBack={() => {
                        this.setState({showAgreement: false});
                    }}
                />}
            </div>);
        }
        if (this.state.isLoading) {
            return (
                <Spinner size={SpinnerSize.large} label={Intl.Get(LocIds.Spinner.LoadingDocumentsDownloadHistoryLabel)} ariaLive="assertive" className="loading-panel-full" />
            );
        }
        else{
            return (
                <div>
                    <section data-grid="col-12 pad-12x">
                        {this.renderLibraryHeader()}
                        <CommandBar
                            items={[]}
                            farItems={commandBarItems}
                            styles={commandBarStyles}
                        />
                        <DetailsList
                            items={this.state.rawDocuments.documentDownloadHistoryMetadata}
                            columns={this._columns}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selectionMode={SelectionMode.none}
                            setKey={'set'}
                            onShouldVirtualize={() => false}
                        />
                    </section>
                    <div id="ListBottom" style={{ clear: 'both' }} />
                    {this.state.isLoadingMore && <div style={{ paddingTop: '10px' }}>
                        <Spinner size={SpinnerSize.small} label={Intl.Get(LocIds.Spinner.LoadingDocumentsDownloadHistoryLabel)} ariaLive="assertive" />
                    </div>}
                </div>
            );
        }
    }

    private getHomePageBanner(): void {
        pageServicesV2Client.getHomePageBanner(
            (response) => {
                let heroItemPanel = response.data as HeroItemPanelPropertiesData;
                this.setState({ heroItemPanelProps: heroItemPanel });
            },
            () => {}
        );
    }

    private renderLibraryHeader() {
        return (
            <div>
                {this.state.heroItemPanelProps &&
                <HeroItemPanel panel={this.state.heroItemPanelProps} />}
                <AreaHeadingPanel
                    panel={
                            {
                                id: '',
                                panelInfo: {
                                    theme: 'Light',
                                    textAlign: 'left',
                                    heading: makeLocalizedString(Intl.Get(LocIds.DocumentDownloadHistoryPage.MainTitle)),
                                    subHeading: makeLocalizedString(' ')
                                },
                                type: PanelSelectionHelperFactory.AREAHEADING
                            }
                    }
                />
            </div>
        );
    }

    private getDocumentDownloadHistory(): void {
        if(this.state.filter.continuationToken) {
            this.setState({ isLoadingMore: true });
        }
        DocumentServiceClient.getPagedDocumentsDownloadHistory(
            this.state.filter,
            r => {
                this.setState(
                    (prevState) => {
                        let rawDocuments = prevState.rawDocuments;
                        let filter = prevState.filter;
                        rawDocuments.documentDownloadHistoryMetadata = rawDocuments.documentDownloadHistoryMetadata.concat(r.data.downloadDetails);
                        rawDocuments.continuationToken = r.data.continuationToken;
                        filter.continuationToken = r.data.continuationToken;
                        return { isLoading: false, isLoadingMore: false, rawDocuments: rawDocuments, filter: filter };
                    },
                    () => {
                        if(this.state.filter.continuationToken) {
                            this.setState({ isLoadingMore: false });
                        }
                        this.setState({ isLoading: false });
                        if (r.data.continuationToken && this.shouldLoadMoreDoc()) {
                            this.getDocumentDownloadHistory();
                        }
                    }
                );
            },
            (error: ErrorResponse) => {
                this.setState({ isLoading: false });
            });
    }

    onScroll() {
        if (this.shouldLoadMoreDoc()) {
            this.getDocumentDownloadHistory();
        }
    }

    shouldLoadMoreDoc(): boolean {
        var bottom = $('#ListBottom').offset();
        var windowHeight = $(window).height();
        var scrollTop = $(window).scrollTop();
        if (bottom === undefined || windowHeight === undefined || scrollTop === undefined) {
            return false;
        }
        if ((windowHeight * 2 + scrollTop > bottom.top) && this.state.rawDocuments.continuationToken && !this.state.isLoadingMore) {
            return true;
        } else {
            return false;
        }
    }

    private exportDownloadHistory(): void {
        this.setState({disableExportButton: true})
        DocumentServiceClient.exportDownloadHistory(
            r => {
                var blob = new Blob([r.data], { type: 'application/octet-stream;charset=utf-8' });
                FileSaver.saveAs(blob, "downloadHistory.csv");
                this.setState({ disableExportButton: false });
            },
            (error: ErrorResponse) => {
                this.setState({ disableExportButton: false });
            });
    }
}

export default DocumentDownloadHistoryPage;
