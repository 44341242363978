import * as React from 'react';
import { Unsubscribe } from 'redux';
import { store } from '../../Store';
import { StatusAlertMessage } from '../Reducers/StatusMessageReducer';
import StatusAlert from './StatusAlert';
import * as StatusMessageReducer from '../../App/Reducers/StatusMessageReducer';

interface StatusMessageProps {
    id: string;
}

export class StatusMessage extends React.Component<StatusMessageProps, StatusAlertMessage> {
    private storeUnsubscribe: Unsubscribe;   
    constructor(props: StatusMessageProps) {
        super(props);
        this.state = {
            status: 'ClientSide',
            title: '',
        };
        this.onCloseClick = this.onCloseClick.bind(this);
    }

    componentDidMount() {
        this.storeUnsubscribe = store.subscribe(() => {
            const statusAlerts = store.getState().statusMessageManagement.StatusAlerts;
            if (!statusAlerts.isExceptionDialogStyle) {
                return;
            }
            
            if (statusAlerts.hasOwnProperty(this.props.id)) {
                this.setState(store.getState().statusMessageManagement.StatusAlerts[this.props.id]);
            } 
            if (Object.keys(statusAlerts).length === 0) {
                this.setState({
                    status: 'ClientSide',
                    title: '',
                });
            }
        });
    }
    
    componentWillUnmount() {
        this.storeUnsubscribe();
    }

    onCloseClick() {
        store.dispatch(StatusMessageReducer.getClearBannerAction());
        store.dispatch(StatusMessageReducer.getClearAction(this.props.id));
    }

    public render() {
        const type = this.state.status === 'ClientSide' ? 'error' : 'warning';
        return <StatusAlert title={this.state.title} type={type} onCloseClick={this.onCloseClick} />;
    }
}
export default StatusMessage;