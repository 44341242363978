import { AxiosResponse } from 'axios';
import { ADUserInfo } from '../Models/ADTenantManagement/ADUserInfo';
import { ADGroupInfo } from '../Models/ADTenantManagement/ADGroupInfo';
import { ADTenantInfo } from '../Models/ADTenantManagement/ADTenantInfo';
import { ErrorResponse } from './Models/ErrorResponse';
import { ServiceTrustClient } from '../../Common/Utilities/ServiceTrustClient';

export class ADUserManagementService {

    getTenants(
        onRequestSuccess: (response: ADTenantInfo[]) => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        let adTenants: ADTenantInfo[] = [];
        var api: string = '/api/v2/ADTenantManagement/tenants/';
        ServiceTrustClient.doRequest_New(
            'get', api, {}, () => null,
            (response: AxiosResponse) => {
                adTenants = response.data as ADTenantInfo[];
                onRequestSuccess(adTenants);
            },
            onRequestFailed
        );
    }

    getTenantDisplayName(
        tenantId: string,
        onRequestSuccess: (response: string) => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        let displayName: string = '';
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId;
        ServiceTrustClient.doRequest_New(
            'get', api, {}, () => null,
            (response: AxiosResponse) => {
                displayName = response.data as string;
                onRequestSuccess(displayName);
            },
            onRequestFailed
        );
    }

    getTenantGroups(
        tenantId: string,
        onRequestSuccess: (response: ADGroupInfo[]) => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        let adGroups: ADGroupInfo[] = [];
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId + '/groups';
        ServiceTrustClient.doRequest_New(
            'get', api, {}, () => null,
            (response: AxiosResponse) => {
                adGroups = response.data as ADGroupInfo[];
                onRequestSuccess(adGroups);
            },
            onRequestFailed
        );
    }
    
    getAllTenantUsers(
        tenantId: string, 
        onRequestSuccess: (response: ADUserInfo[]) => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        let adUsers: ADUserInfo[] = [];
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId + '/users';
        ServiceTrustClient.doRequest_New(
            'get', api, {}, () => null,
            (response: AxiosResponse) => {
                adUsers = response.data as ADUserInfo[];
                onRequestSuccess(adUsers);
            },
            onRequestFailed
        );
    }

    getTenantUser(
        tenantId: string, userId: string,
        onRequestSuccess: (response: ADUserInfo) => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        let user: ADUserInfo;
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId + '/users/' + userId;
        ServiceTrustClient.doRequest_New(
            'get', api, {}, () => null,
            (response: AxiosResponse) => {
                user = response.data as ADUserInfo;
                onRequestSuccess(user);
            },
            onRequestFailed
        );
    }

    inviteGuestUserToTenant(
        tenantId: string, user: ADUserInfo,
        onRequestSuccess: () => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId + '/invitations';
        ServiceTrustClient.doRequest_New(
            'post', api, user, () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }

    addNewTenantUser(
        tenantId: string, user: ADUserInfo,
        onRequestSuccess: () => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId + '/users';
        ServiceTrustClient.doRequest_New(
            'post', api, user, () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }

    updateTenantUserGroups(
        tenantId: string, user: ADUserInfo,
        onRequestSuccess: () => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId + '/users/';
        ServiceTrustClient.doRequest_New(
            'put', api, user, () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }
    
    deleteTenantUser(
        tenantId: string, userId: string,
        onRequestSuccess: () => void,
        onRequestFailed: (response: ErrorResponse) => void = () => {}
    ): void {
        var api: string = '/api/v2/ADTenantManagement/tenants/' + tenantId + '/users/' + userId;
        ServiceTrustClient.doRequest_New(
            'delete', api, {}, () => null,
            onRequestSuccess,
            onRequestFailed
        );
    }
}