import { ActionItem } from '../../App/Models/ComplianceManagerV3/ActionItem';
import { CustomerActionType, ImplementationStatus, UserActionStatus } from '../../App/Models/ComplianceManagerV3';
import { EmptyUserPersona } from '../../App/Models/ComplianceManagerV2';
import { IKeyValuePair } from '../../App/Models/IKeyValuePair';
import * as moment from 'moment';
import { mockResponsiblepartieslookup } from './MockResponsiblePartiesLookupService';

export const mockActionItem: ActionItem[] = [
    {
        id: '9876b597-43e4-4200-baae-ed96763e5ca3',
        title: 'Acquisition Process',
        // tslint:disable-next-line:max-line-length
        description: 'The organization includes the following requirements, descriptions, and criteria, explicitly or by reference, in the acquisition contract for the information system, system component, or information system service in accordance with applicable federal laws, Executive Orders, directives, policies, regulations, standards, guidelines, and organizational mission/business needs: Security functional requirements;',
        actionType: CustomerActionType.Procedural,
        maxComplianceScore: 10,
        owner: mockResponsiblepartieslookup[0],
        whenTested: new Date().toLocaleDateString(),
        testResultStatus: UserActionStatus.FailedLowRisk,
        implementationStatus: ImplementationStatus.Implemented,
        implementationNotes: 'Implementation Notes for customer Action',
        managementResponse: 'Management response for customer Action',
        testPlanDetails: 'Test plan for customer Action',
        whenImplemented: new Date().toLocaleDateString(),
        whoImpemented: '',
        whoTested: '',
        whoAssignedTo: EmptyUserPersona,
        whenAssigned: new Date().toLocaleDateString(),
        whoAssigned: '',
        whoImplemented: '',
        documentInfos: [],
        assignedToCurrentUser: false
    },
    {
        id: '3426b597-43e4-4200-baae-ed96763e5ca3',
        title: 'Access to network and network service',
        description: 'Customer action description 2',
        actionType: CustomerActionType.Technical,
        maxComplianceScore: 100,
        owner: mockResponsiblepartieslookup[1],
        whenTested: moment('2018-01-14').format('MM/DD/YYYY').toLocaleString(),
        testResultStatus: UserActionStatus.FailedHighRisk,
        whenImplemented: new Date().toLocaleDateString(),
        implementationStatus: ImplementationStatus.Planned,
        implementationNotes: 'Implementation Notes for customer Action network services',
        managementResponse: 'Management response for customer Action network services',
        testPlanDetails: 'Test plan for customer Action network services',
        whoImpemented: '',
        whoTested: '',
        whoAssignedTo: EmptyUserPersona,
        whenAssigned: new Date().toLocaleDateString(),
        whoAssigned: '',
        whoImplemented: '',
        documentInfos: [],
        assignedToCurrentUser: false
    },
    {
        id: '3426b597-43e4-4212-baae-ed96763e5ca3',
        title: 'Automated Unauthorized Component Detection"',
        // tslint:disable-next-line:max-line-length
        description: 'The organization:\n(b) Takes the following actions when unauthorized components are detected: [Selection (one or more): disables network access by such components; isolates the components; notifies [Assignment: organization-defined personnel or roles]]',
        actionType: CustomerActionType.Technical,
        maxComplianceScore: 100,
        owner: 'Rod Tucker',
        whenTested: moment('2018-06-22').format('MM/DD/YYYY').toLocaleString(),
        testResultStatus: UserActionStatus.FailedHighRisk,
        whenImplemented: new Date().toLocaleDateString(),
        implementationStatus: ImplementationStatus.Planned,
        implementationNotes: 'Implementation Notes for customer Action network services',
        managementResponse: 'Management response for customer Action network services',
        testPlanDetails: 'Test plan for customer Action network services',
        whoImpemented: '',
        whoTested: '',
        whoAssignedTo: EmptyUserPersona,
        whenAssigned: new Date().toLocaleDateString(),
        whoAssigned: '',
        whoImplemented: '',
        documentInfos: [],
        assignedToCurrentUser: false
    },
    {
        id: '3426b597-43e4-4200-baae-ed96763e5ca3',
        title: 'Unclassified Non-National Security System Connections',
        description: 'Customer action description 2',
        actionType: CustomerActionType.Technical,
        maxComplianceScore: 100,
        owner: 'Steve Smith',
        whenTested: moment('2018-06-28').format('MM/DD/YYYY').toLocaleString(),
        testResultStatus: UserActionStatus.FailedHighRisk,
        whenImplemented: moment('2018-07-01').format('MM/DD/YYYY').toLocaleString(),
        implementationStatus: ImplementationStatus.Planned,
        implementationNotes: 'Implementation Notes for customer Action network services',
        managementResponse: 'Management response for customer Action network services',
        testPlanDetails: 'Test plan for customer Action network services',
        whoImpemented: '',
        whoTested: '',
        whoAssignedTo: EmptyUserPersona,
        whenAssigned: new Date().toLocaleDateString(),
        whoAssigned: '',
        whoImplemented: '',
        documentInfos: [],
        assignedToCurrentUser: false
    },
    {
        id: '3426b597-43e4-4200-baae-ed96763e5ca3',
        title: 'Security Categorization',
        description: 'The organization: Categorizes information and the information system in accordance with applicable federal laws, Executive Orders, directives, policies, regulations, standards, and guidance;',
        actionType: CustomerActionType.Technical,
        maxComplianceScore: 100,
        owner: 'Steve Smith',
        whenTested: moment('2018-07-30').format('MM/DD/YYYY').toLocaleString(),
        testResultStatus: UserActionStatus.FailedHighRisk,
        whenImplemented: moment('2018-08-24').format('MM/DD/YYYY').toLocaleString(),
        implementationStatus: ImplementationStatus.Planned,
        implementationNotes: 'Implementation Notes for customer Action network services',
        managementResponse: 'Management response for customer Action network services',
        testPlanDetails: 'Test plan for customer Action network services',
        whoImpemented: '',
        whoTested: '',
        whoAssignedTo: EmptyUserPersona,
        whenAssigned: new Date().toLocaleDateString(),
        whoAssigned: '',
        whoImplemented: '',
        documentInfos: [],
        assignedToCurrentUser: false
    }
];
export class MockActionItemService {

    getActionItems(): ActionItem[] {
        return mockActionItem;
    }

    PostActionItems(params: IKeyValuePair[], data: ActionItem): ActionItem {
        return data;
    }

    getMicrosoftActionItem(): ActionItem {
        return mockActionItem[0];
    }
}

export let MockClientActionItemService = new MockActionItemService();