import { Reducer } from 'redux';
import { ApplicationContext, CloudInstance } from '../../Common/Utilities/ApplicationContext';
import { ApplicationUserInfo } from '../../Common/Utilities/ApplicationUserInfo';
import { TopNavigationSettings } from '../Models/TopNavigationSettings';
import { Status } from '../Reducers/Status';
import { AvailableCulture } from '../Models/Settings/AvailableCulture';
import { SecureScoreIntegrationLevel } from '../../App/Models/ComplianceManagerV3/SecureScoreIntegrationLevel'
import { ComplianceManagerConstants } from '../../Common/Models/ComplianceManagerConstants';
// STATE - This defines the type of data maintained in the Redux store.

export interface ApplicationContextState {
    // Values that come from the web API
    applicationClientId: string;
    applicationLoginUrl: string;
    applicationGraphUrl: string;
    applicationScopes: string;
    cultureName: string;
    userInfo: ApplicationUserInfo;
    topNavigationSettings: TopNavigationSettings;
    availableCultures: AvailableCulture[];
    assemblyVersion: string;
    appInsightsId: string;
    azureResourcesCloudInstance: CloudInstance;
    maxFileUploadSizeInBytes: number;
    maxEvidenceFileUploadSizeInBytes: number;
    maxTemplateUploadSizeInBytes: number;
    // Other values.
    status: Status;
    complianceManagerInfoMessage: string;
    complianceManagerInfoMessageV3: string;
    secureScoreIntegrationLevel:SecureScoreIntegrationLevel;
    isCMV4Enabled: boolean;
    isItarEnabled: boolean;
    redirectUrl: string;
    redirectPath: {};
    pageRedirectMapping: { [key: string]: string; }
}

export function getDefaultApplicationContext(): ApplicationContextState {
    return {
        applicationClientId: '',
        applicationLoginUrl: '',
        applicationGraphUrl: '',
        applicationScopes: '',
        cultureName: '',
        userInfo: {
            id: '',
            principalName: '',
            anonymousId: '',
            displayName: 'Anonymous',
            scopedAuthorizationRights: [],
            flights: [],
            hasAgreedToAnyPreviousLicenseAgreement: false,
            hasAgreedToCurrentLicenseAgreement: false,
            hasExplicitCmV3AdminRbac: true,
            isTenantAdmin: false,
            tenantHasAllExplicitCmV3Rbac: false,
        },
        topNavigationSettings: {} as TopNavigationSettings,
        availableCultures: [] as AvailableCulture[],
        status: 'Init',
        assemblyVersion: '',
        appInsightsId: '',
        azureResourcesCloudInstance: CloudInstance.Public,
        maxFileUploadSizeInBytes: 512000,
        maxEvidenceFileUploadSizeInBytes: 52428800,
        maxTemplateUploadSizeInBytes : ComplianceManagerConstants.MaxTemplateUploadSizeInBytes,
        complianceManagerInfoMessage: '',
        complianceManagerInfoMessageV3:'',
        secureScoreIntegrationLevel:SecureScoreIntegrationLevel.EnableForAllActions,
        isCMV4Enabled : false,
        isItarEnabled : false,
        redirectUrl: '',
        redirectPath: {},
        pageRedirectMapping: {}
    } as ApplicationContextState;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface ApplicationContextChanged {
    type: 'APPLICATION_CONTEXT_CHANGED';
    applicationContext: ApplicationContext;
}

interface ApplicationContextLoading {
    type: 'APPLICATION_CONTEXT_LOADING';
}

interface ApplicationContextError {
    type: 'APPLICATION_CONTEXT_ERROR';
}

export type ApplicationContextActions = ApplicationContextChanged | ApplicationContextLoading | ApplicationContextError;

export function getApplicationContextChangedAction(applicationContext: ApplicationContext): ApplicationContextActions {
    return {
        type: 'APPLICATION_CONTEXT_CHANGED',
        applicationContext: applicationContext
    };
}

export function getApplicationContextLoadingAction(): ApplicationContextActions {
    return {
        type: 'APPLICATION_CONTEXT_LOADING'
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ApplicationContextState> = (state: ApplicationContextState, action: ApplicationContextActions) => {
    let newState: ApplicationContextState;
    switch (action.type) {
        case 'APPLICATION_CONTEXT_CHANGED':
            newState = {
                ...state,
                applicationClientId: action.applicationContext.applicationClientId,
                applicationLoginUrl: action.applicationContext.applicationLoginUrl,
                applicationGraphUrl: action.applicationContext.applicationGraphUrl,
                applicationScopes: action.applicationContext.applicationScopes,
                cultureName: action.applicationContext.cultureName,
                userInfo: action.applicationContext.userInfo,
                topNavigationSettings: action.applicationContext.topNavigationSettings,
                availableCultures: action.applicationContext.availableCultures,
                status: 'Loaded',
                assemblyVersion: action.applicationContext.assemblyVersion,
                appInsightsId: action.applicationContext.appInsightsId,
                azureResourcesCloudInstance: action.applicationContext.azureResourcesCloudInstance,
                maxFileUploadSizeInBytes: action.applicationContext.maxFileUploadSizeInBytes,
                complianceManagerInfoMessage: action.applicationContext.complianceManagerInfoMessage,
                complianceManagerInfoMessageV3: action.applicationContext.complianceManagerInfoMessageV3,
                maxEvidenceFileUploadSizeInBytes: action.applicationContext.maxEvidenceFileUploadSizeInBytes,
                secureScoreIntegrationLevel: action.applicationContext.secureScoreIntegrationLevel,
                isCMV4Enabled: action.applicationContext.isCMV4Enabled,
                isItarEnabled: action.applicationContext.isItarEnabled,
                redirectUrl: action.applicationContext.redirectUrl,
                redirectPath: action.applicationContext.redirectPath,
                pageRedirectMapping: action.applicationContext.pageRedirectMapping,
                maxTemplateUploadSizeInBytes: action.applicationContext.maxTemplateUploadSizeInBytes
             };
            break;
        case 'APPLICATION_CONTEXT_LOADING':
            newState = {
                ...state,
                status: 'Loading',
             };
            break;
        case 'APPLICATION_CONTEXT_ERROR':
        default:
            newState = (state === undefined) 
            ? getDefaultApplicationContext()
            : state;
    }

    return newState;
};