import { Reducer } from 'redux';
import { DocumentInfo } from '../Models/ComplianceDashboard/DocumentInfo';
import {
    FrameworkControlViewModel,
    FrameworkFamilyViewModel,
    FrameworkUserActions,
    ReviewDashboardTile,
    PersonaDetails,
    FrameworkDetailsFilter,
    ImplementationStatus,
    FrameworkControlTestResultStatus
} from '../Models/ComplianceDashboard';

const UPDATE_CERTIFICATION_CONTROL = 'UPDATE_CERTIFICATION_CONTROL';
const UPDATE_CERTIFICATION_CONTROL_COMPLETE = 'UPDATE_CERTIFICATION_CONTROL_COMPLETE';
const RECEIVE_ALLFAMILIES = 'RECEIVE_ALLFAMILIES';
const REQUEST_ALLFAMILIES = 'REQUEST_ALLFAMILIES';
const RECEIVE_CONTROL_FAMILY = 'RECEIVE_CONTROL_FAMILY';
const RECEIVE_FRAMEWORK_TILE = 'RECEIVE_FRAMEWORK_TILE';
const PUBLISH_VNEXT = 'PUBLISH_VNEXT';
const PUBLISH_VNEXT_COMPLETE = 'PUBLISH_VNEXT_COMPLETE';
const SAVE_VNEXT = 'SAVE_VNEXT';
const SAVE_VNEXT_COMPLETE = 'SAVE_VNEXT_COMPLETE';
const CREATE_VNEXT = 'CREATE_VNEXT';
const CANCEL_EDIT = 'CANCEL_EDIT';
export const ASSESS_CONTROL = 'ASSESS_CONTROL';
const RECEIVE_INSCOPE_SERVICES = 'RECEIVE_INSCOPE_SERVICES';
const UPDATE_COLLABORATORS = 'UPDATE_COLLABORATORS';
export const UPDATE_UPLOAD = 'UPDATE_UPLOAD';
export const UPDATE_DELETE = 'UPDATE_DELETE';
const UPDATE_TILESTATUS = 'UPDATE_TILESTATUS';
export const FRAMEWORKDETAILSFILTER_TILE = 'FRAMEWORKDETAILSFILTER_TILE';
export const UPDATE_ASSIGMENT = 'UPDATE_ASSIGMENT';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_RESULT = 'UPDATE_RESULT';
export const UPDATE_ISEXPANDED = 'UPDATE_ISEXPANDED';
export const RECEIVE_TILE = 'RECEIVE_TILE';

export interface FrameworkDetailState {
    isLoading: boolean;
    framework: string;
    certificationId: string;
    controlFamilyData: FrameworkFamilyViewModel[] | null;
    reviewDashboardTile: ReviewDashboardTile | null;
    inScopeServiceToFrameworkMapping: string[] | null;
    filter: FrameworkDetailsFilter;
}

interface ReceiveAllFamilies {
    type: typeof RECEIVE_ALLFAMILIES;
    data: FrameworkFamilyViewModel[];
}

interface RequestAllFamilies {
    type: typeof REQUEST_ALLFAMILIES;
}

interface SaveCertificationControl {
    type: typeof UPDATE_CERTIFICATION_CONTROL;
}

interface SaveCertificationControlComplete {
    type: typeof UPDATE_CERTIFICATION_CONTROL_COMPLETE;
    data: FrameworkUserActions;
}

interface ReceiveControlFamily {
    type: typeof RECEIVE_CONTROL_FAMILY;
    data: FrameworkControlViewModel[];
    controlFamilyId: string;
}

interface ReceiveFrameworkTile {
    type: typeof RECEIVE_FRAMEWORK_TILE;
    data: ReviewDashboardTile;
}

interface SavevNext {
    type: typeof SAVE_VNEXT;
}

interface SavevNextComplete {
    type: typeof SAVE_VNEXT_COMPLETE;
    newData: FrameworkControlViewModel;
    familyId: string;
}

interface PublishvNext {
    type: typeof PUBLISH_VNEXT;
}

interface PublishvNextComplete {
    type: typeof PUBLISH_VNEXT_COMPLETE;
    data: boolean;
    familyId: string;
    microsoftControlId: string;
    publish: boolean;
}

interface CreatevNext {
    type: typeof CREATE_VNEXT;
    control: FrameworkControlViewModel;
    controlFamily: FrameworkFamilyViewModel;
}

interface CancelEdit {
    type: typeof CANCEL_EDIT;
    controlFamily: FrameworkFamilyViewModel;
    control: FrameworkControlViewModel;
}

export interface AssessControl {
    type: typeof ASSESS_CONTROL;
    data: FrameworkControlViewModel;
    assessed: boolean;
}

interface ReceiveInScopeServices {
    type: typeof RECEIVE_INSCOPE_SERVICES;
    data: string[];
}

interface UpdateCollaborators {
    type: typeof UPDATE_COLLABORATORS;
    data: Map<string, PersonaDetails>;
}

export interface UpdateUploadState {
    type: typeof UPDATE_UPLOAD;
    document: DocumentInfo;
    controlId: string;
    familyId: string;
}

export interface UpdateDeleteState {
    type: typeof UPDATE_DELETE;
    document: DocumentInfo;
    controlId: string;
    familyId: string;
}

export interface FilterControlTile {
    type: typeof FRAMEWORKDETAILSFILTER_TILE;
    filterData: FrameworkDetailsFilter;
}

export interface UpdateAssigment {
    type: typeof UPDATE_ASSIGMENT;
    data: FrameworkControlViewModel;
}

export interface UpdateStatus {
    type: typeof UPDATE_STATUS;
    data: FrameworkControlViewModel;
}

export interface UpdateResult {
    type: typeof UPDATE_RESULT;
    data: FrameworkControlViewModel;
}

export interface UpdateTileStatus {
    type: typeof UPDATE_TILESTATUS;
    userActions: FrameworkUserActions;
}

interface UpdateIsExpanded {
    type: typeof UPDATE_ISEXPANDED;
    data: FrameworkFamilyViewModel;
    isExpanded: boolean;
}

export interface ReceiveTile {
    type: typeof RECEIVE_TILE;
    data: ReviewDashboardTile;
}

export type KnownAction =
    SaveCertificationControl |
    ReceiveAllFamilies |
    RequestAllFamilies |
    ReceiveControlFamily |
    SaveCertificationControlComplete |
    ReceiveFrameworkTile |
    SavevNext |
    SavevNextComplete |
    PublishvNext |
    PublishvNextComplete |
    CreatevNext |
    CancelEdit |
    ReceiveInScopeServices |
    AssessControl |
    UpdateCollaborators |
    UpdateUploadState |
    UpdateDeleteState |
    FilterControlTile |
    UpdateAssigment |
    UpdateStatus |
    UpdateResult |
    UpdateTileStatus |
    UpdateIsExpanded |
    ReceiveTile;

export function receiveAllFramiliesAction(data: FrameworkFamilyViewModel[]): KnownAction {
    return {
        type: RECEIVE_ALLFAMILIES,
        data: data
    };
}

export function requestAllFramiliesAction(): KnownAction {
    return {
        type: REQUEST_ALLFAMILIES,
    };
}

export function receiveDashboardTile(data: ReviewDashboardTile): KnownAction {
    return {
        type: RECEIVE_FRAMEWORK_TILE,
        data: data
    };
}

export function receiveInScopeServices(data: string[]): KnownAction {
    return {
        type: RECEIVE_INSCOPE_SERVICES,
        data: data
    };
}

export function saveCertificationControlAction(data: FrameworkUserActions): KnownAction {
    return {
        type: UPDATE_CERTIFICATION_CONTROL_COMPLETE,
        data: data
    };
}

export function receiveControlFamilyAction(data: FrameworkControlViewModel[], familyId: string): KnownAction {
    return ({
        type: RECEIVE_CONTROL_FAMILY,
        data: data,
        controlFamilyId: familyId
    });
}

export function approvevNextAction(): KnownAction {
    return { type: PUBLISH_VNEXT };
}

export function savevNextAction(data: FrameworkControlViewModel, familyId: string): KnownAction {
    return {
        type: SAVE_VNEXT_COMPLETE,
        newData: data,
        familyId: familyId,
    };
}

export function createvNextAction(control: FrameworkControlViewModel, controlFamily: FrameworkFamilyViewModel): KnownAction {
    return {
        type: CREATE_VNEXT,
        control: control,
        controlFamily: controlFamily,
    };
}

export function cancelEditAction(control: FrameworkControlViewModel, controlFamily: FrameworkFamilyViewModel): KnownAction {
    return {
        type: CANCEL_EDIT,
        controlFamily: controlFamily,
        control: control,
    };
}

export function assessControl(data: FrameworkControlViewModel, assessed: boolean): KnownAction {
    return {
        type: ASSESS_CONTROL,
        data: data,
        assessed: assessed,
    };
}

export function updateCollaborators(data: Map<string, PersonaDetails>): KnownAction {
    return {
        type: UPDATE_COLLABORATORS,
        data: data
    };
}

export function updateUploadState(document: DocumentInfo, familyId: string, controlId: string): KnownAction {
    return {
        type: UPDATE_UPLOAD,
        document: document,
        controlId: controlId,
        familyId: familyId
    };
}

export function updateDeleteState(document: DocumentInfo, familyId: string, controlId: string): KnownAction {
    return {
        type: UPDATE_DELETE,
        document: document,
        controlId: controlId,
        familyId: familyId
    };
}

export function filterControlTiles(data: FrameworkDetailsFilter): KnownAction {
    return {
        type: FRAMEWORKDETAILSFILTER_TILE,
        filterData: data,
    };
}

export function updateAssigment(data: FrameworkControlViewModel): KnownAction {
    return {
        type: UPDATE_ASSIGMENT,
        data: data,
    };
}

export function updateStatus(data: FrameworkControlViewModel): KnownAction {
    return {
        type: UPDATE_STATUS,
        data: data,
    };
}

export function updateResult(data: FrameworkControlViewModel): KnownAction {
    return {
        type: UPDATE_RESULT,
        data: data,
    };
}

export function updateTileStatus(userActions: FrameworkUserActions): KnownAction {
    return {
        type: UPDATE_TILESTATUS,
        userActions: userActions,
    };
}

export function updateIsExpanded(data: FrameworkFamilyViewModel, isExpanded: boolean): KnownAction {
    return {
        type: UPDATE_ISEXPANDED,
        data: data,
        isExpanded: isExpanded
    };
}

export function receiveTile(data: ReviewDashboardTile): KnownAction {
    return {
        type: RECEIVE_TILE,
        data: data
    };
}
const unloadedState: FrameworkDetailState = {
    isLoading: false,
    framework: '',
    certificationId: '',
    controlFamilyData: [],
    reviewDashboardTile: {} as ReviewDashboardTile,
    inScopeServiceToFrameworkMapping: [],
    filter: {} as FrameworkDetailsFilter
};

export const reducer: Reducer<FrameworkDetailState> = (state: FrameworkDetailState, action: KnownAction) => {
    // tslint:disable-next-line:switch-default
    switch (action.type) {
        case RECEIVE_ALLFAMILIES:
            let newState = { ...state, isLoading: false, controlFamilyData: action.data };
            return newState;
        case REQUEST_ALLFAMILIES:
            return { ...state, isLoading: true, controlFamilyData: [] };
        case RECEIVE_FRAMEWORK_TILE:
            return {
                isLoading: true,
                framework: state.framework,
                certificationId: state.certificationId,
                reviewDashboardTile: action.data,
                controlFamilyData: null,
                inScopeServiceToFrameworkMapping: state.inScopeServiceToFrameworkMapping,
                filter: state.filter,
            };
        case FRAMEWORKDETAILSFILTER_TILE:
            return {
                ...state,
                controlFamilyData: state.controlFamilyData ? state.controlFamilyData.slice() : state.controlFamilyData,

                filter: { ...action.filterData }
            };
        case UPDATE_CERTIFICATION_CONTROL:
            let nextState = { ...state, isLoading: true };
            return nextState;
        case UPDATE_CERTIFICATION_CONTROL_COMPLETE:
            let newTile: ReviewDashboardTile = { ...state.reviewDashboardTile } as ReviewDashboardTile;
            newTile.whenLastUpdated = new Date();
            let sCopy = { ...state, isLoading: false, reviewDashboardTile: newTile };
            return sCopy;
        case RECEIVE_CONTROL_FAMILY:
            let stateCopy = { ...state, isLoading: false };
            if (action.data && stateCopy && stateCopy.controlFamilyData) {
                let index: number = stateCopy.controlFamilyData.findIndex(o => o.id === action.controlFamilyId);
                stateCopy.controlFamilyData[index].controlFamilyDetails = action.data;
            }
            return stateCopy;
        case RECEIVE_INSCOPE_SERVICES:
            return {
                isLoading: true,
                framework: state.framework,
                certificationId: state.certificationId,
                reviewDashboardTile: state.reviewDashboardTile,
                controlFamilyData: state.controlFamilyData,
                inScopeServiceToFrameworkMapping: action.data,
                filter: state.filter,
            };
        case SAVE_VNEXT:
            return {
                isLoading: true,
                framework: state.framework,
                certificationId: state.certificationId,
                reviewDashboardTile: state.reviewDashboardTile,
                controlFamilyData: state.controlFamilyData,
                inScopeServiceToFrameworkMapping: state.inScopeServiceToFrameworkMapping,
                filter: state.filter,
            };
        case SAVE_VNEXT_COMPLETE:
            let family = state.controlFamilyData ? state.controlFamilyData.find(o => o.id === action.familyId) : null;
            if (family) {
                let details = family.controlFamilyDetails.find(o => o.microsoftControl != null && o.microsoftControl.id === action.newData.microsoftControl.id && o.isVNextVersion);
                if (details) {
                    details.isNew = false;
                    details.microsoftControl = action.newData.microsoftControl;
                }
            }
            return {
                isLoading: false,
                framework: state.framework,
                certificationId: state.certificationId,
                reviewDashboardTile: state.reviewDashboardTile,
                controlFamilyData: state.controlFamilyData ? state.controlFamilyData.slice() : state.controlFamilyData,
                inScopeServiceToFrameworkMapping: state.inScopeServiceToFrameworkMapping,
                filter: state.filter,
            };
        case PUBLISH_VNEXT:
            return {
                isLoading: true,
                framework: state.framework,
                certificationId: state.certificationId,
                reviewDashboardTile: state.reviewDashboardTile,
                controlFamilyData: state.controlFamilyData,
                inScopeServiceToFrameworkMapping: state.inScopeServiceToFrameworkMapping,
                filter: state.filter,
            };
        case PUBLISH_VNEXT_COMPLETE:
            let currentFamily = state.controlFamilyData ? state.controlFamilyData.find(o => o.id === action.familyId) : null;
            if (currentFamily && action.data) {
                let index = -1;
                if (action.publish) {
                    index = currentFamily.controlFamilyDetails.findIndex(o => o.microsoftControl != null && o.microsoftControl.id === action.microsoftControlId && !o.isVNextVersion);
                    currentFamily.controlFamilyDetails[index + 1].isVNextVersion = false;
                } else {
                    index = currentFamily.controlFamilyDetails.findIndex(o => o.microsoftControl != null && o.microsoftControl.id === action.microsoftControlId && o.isVNextVersion);
                }
                if (index >= 0) {
                    currentFamily.controlFamilyDetails.splice(index, 1);
                }

                return {
                    isLoading: false,
                    framework: state.framework,
                    certificationId: state.certificationId,
                    reviewDashboardTile: state.reviewDashboardTile,
                    controlFamilyData: state.controlFamilyData,
                    inScopeServiceToFrameworkMapping: state.inScopeServiceToFrameworkMapping,
                    filter: state.filter,
                };
            }
            return state;
        case CREATE_VNEXT:
            let vNext = new FrameworkControlViewModel({ ...action.control.microsoftControl }, null);
            let updatedState = { ...state };
            let updatedFamily = { ...action.controlFamily };

            if (!updatedState.controlFamilyData) {
                return state;
            }

            updatedState.controlFamilyData = updatedState.controlFamilyData.slice();

            vNext.isVNextVersion = vNext.isNew = true;

            let ij = updatedFamily.controlFamilyDetails.findIndex(o => o.microsoftControl.id === action.control.microsoftControl.id);

            updatedFamily.controlFamilyDetails[ij] = { ...action.controlFamily.controlFamilyDetails[ij], hasVNextVersion: true };

            if (ij + 1 >= updatedFamily.controlFamilyDetails.length) {
                updatedFamily.controlFamilyDetails.push(vNext);
            } else {
                updatedFamily.controlFamilyDetails.splice(ij + 1, 0, vNext);
            }

            let ix: number = updatedState.controlFamilyData.findIndex(o => o.id === action.controlFamily.id);
            updatedState.controlFamilyData.splice(ix, 1, updatedFamily);
            return updatedState;
        case CANCEL_EDIT:
            let cancelState = { ...state };

            if (!cancelState.controlFamilyData) {
                return state;
            }

            let cancelEditFamily = { ...action.controlFamily };
            cancelState.controlFamilyData = cancelState.controlFamilyData.slice();

            let ic: number = cancelEditFamily.controlFamilyDetails.findIndex(o => o.microsoftControl.id === action.control.microsoftControl.id && !o.isVNextVersion);

            cancelEditFamily.controlFamilyDetails[ic] = { ...action.controlFamily.controlFamilyDetails[ic], hasVNextVersion: false };
            cancelEditFamily.controlFamilyDetails.splice(ic + 1, 1);

            let jx: number = cancelState.controlFamilyData.findIndex(o => o.id === action.controlFamily.id);
            cancelState.controlFamilyData.splice(jx, 1, cancelEditFamily);
            return cancelState;
        case ASSESS_CONTROL:
            if (!state.controlFamilyData) {
                return state;
            }
            let familyIndex = state.controlFamilyData.findIndex(f => f.id === action.data.controlFamilyId);
            let familyData = state.controlFamilyData[familyIndex];
            if (!familyData) {
                return state;
            }
            if (state.reviewDashboardTile) {
                let factor = action.assessed ? 1 : -1;
                state.reviewDashboardTile = { ...state.reviewDashboardTile, completedCustomerControls: state.reviewDashboardTile.completedCustomerControls + factor };
            }

            state.controlFamilyData[familyIndex] = { ...familyData };

            return { ...state, controlFamilyData: state.controlFamilyData ? state.controlFamilyData.slice() : null };

        case UPDATE_COLLABORATORS:
            let reviewDashboardTile = state.reviewDashboardTile === null ? new ReviewDashboardTile() : { ...state.reviewDashboardTile };
            reviewDashboardTile.collaborators = action.data;
            let updatedCollaboratorsState = { ...state, reviewDashboardTile: reviewDashboardTile };
            return updatedCollaboratorsState;

        case UPDATE_ASSIGMENT:
            if (!state.controlFamilyData) {
                return state;
            }
            let controlIndex = state.controlFamilyData.findIndex(f => f.id === action.data.controlFamilyId);
            let controlFamilyData = state.controlFamilyData[controlIndex];
            if (!controlFamilyData) {
                return state;
            }
            let assignedUsers = controlFamilyData.assignedUsers;
            if (!assignedUsers) {
                assignedUsers = [];
            }
            if (action.data.userActions && action.data.userActions.assignment.whoAssignedTo) {
                assignedUsers.push(action.data.userActions.assignment.whoAssignedTo.anonymousId);
            }
            controlFamilyData.assignedUsers = assignedUsers;
            state.controlFamilyData[controlIndex] = { ...controlFamilyData };
            return { ...state, controlFamilyData: state.controlFamilyData };

        case UPDATE_STATUS:
            if (!state.controlFamilyData) {
                return state;
            }
            let controlStatusIndex = state.controlFamilyData.findIndex(f => f.id === action.data.controlFamilyId);

            let controlFamilyDataStatus = state.controlFamilyData[controlStatusIndex];
            if (!controlFamilyDataStatus) {
                return state;
            }
            if (!action.data.userActions) {
                return state;
            }
            switch (action.data.userActions.assignment.implementationStatus) {
                case ImplementationStatus.AlternativeImplementation:
                    controlFamilyDataStatus.alternativeImplementationCount++;
                    break;
                case ImplementationStatus.Implemented:
                    controlFamilyDataStatus.implementedCount++;
                    break;
                case ImplementationStatus.NotImplemented:
                    controlFamilyDataStatus.notImplemented++;
                    break;
                case ImplementationStatus.NotInScope:
                    controlFamilyDataStatus.notInScopeCount++;
                    break;
                case ImplementationStatus.Planned:
                    controlFamilyDataStatus.plannedCount++;
                    break;
                default:
                    break;
            }

            switch (action.data.userActions.assignment.previousImplementationStatus) {
                case ImplementationStatus.AlternativeImplementation:
                    controlFamilyDataStatus.alternativeImplementationCount--;
                    break;
                case ImplementationStatus.Implemented:
                    controlFamilyDataStatus.implementedCount--;
                    break;
                case ImplementationStatus.NotImplemented:
                    controlFamilyDataStatus.notImplemented--;
                    break;
                case ImplementationStatus.NotInScope:
                    controlFamilyDataStatus.notInScopeCount--;
                    break;
                case ImplementationStatus.Planned:
                    controlFamilyDataStatus.plannedCount--;
                    break;
                default:
                    break;
            }
            state.controlFamilyData[controlStatusIndex] = { ...controlFamilyDataStatus };
            return { ...state, controlFamilyData: state.controlFamilyData };

        case UPDATE_ISEXPANDED:
            if (!state.controlFamilyData) {
                return state;
            }
            let expandedIndex = state.controlFamilyData.findIndex(f => f.id === action.data.id);

            let expandedDataResult = state.controlFamilyData[expandedIndex];
            if (!expandedDataResult) {
                return state;
            }
            if (!action.data) {
                return state;
            }
            expandedDataResult.isExpanded = action.isExpanded;
            state.controlFamilyData[expandedIndex] = { ...expandedDataResult };
            return { ...state, controlFamilyData: state.controlFamilyData };
        case UPDATE_RESULT:
            if (!state.controlFamilyData) {
                return state;
            }
            let controlResultIndex = state.controlFamilyData.findIndex(f => f.id === action.data.controlFamilyId);

            let controlFamilyDataResult = state.controlFamilyData[controlResultIndex];
            if (!controlFamilyDataResult) {
                return state;
            }
            if (!action.data.userActions) {
                return state;
            }
            switch (action.data.userActions.testResult.status) {
                case FrameworkControlTestResultStatus.FailedHighRisk:
                    controlFamilyDataResult.failedHighRiskCount++;
                    break;
                case FrameworkControlTestResultStatus.FailedLowRisk:
                    controlFamilyDataResult.failedLowRiskCount++;
                    break;
                case FrameworkControlTestResultStatus.FailedMediumRisk:
                    controlFamilyDataResult.failedMediumRiskCount++;
                    break;
                case FrameworkControlTestResultStatus.Passed:
                    controlFamilyDataResult.passedCount++;
                    break;
                case FrameworkControlTestResultStatus.NotAssessed:
                    controlFamilyDataResult.notAssessedCount++;
                    break;
                default:
                    break;
            }

            switch (action.data.userActions.testResult.previousStatus) {
                case FrameworkControlTestResultStatus.FailedHighRisk:
                    controlFamilyDataResult.failedHighRiskCount--;
                    break;
                case FrameworkControlTestResultStatus.FailedLowRisk:
                    controlFamilyDataResult.failedLowRiskCount--;
                    break;
                case FrameworkControlTestResultStatus.FailedMediumRisk:
                    controlFamilyDataResult.failedMediumRiskCount--;
                    break;
                case FrameworkControlTestResultStatus.Passed:
                    controlFamilyDataResult.passedCount--;
                    break;
                case FrameworkControlTestResultStatus.NotAssessed:
                    controlFamilyDataResult.notAssessedCount--;
                    break;
                default:
                    break;
            }

            state.controlFamilyData[controlResultIndex] = { ...controlFamilyDataResult };
            return { ...state, controlFamilyData: state.controlFamilyData };

        case UPDATE_UPLOAD:
            let newUploadState = {
                ...state,
                controlFamilyData: state.controlFamilyData ? state.controlFamilyData.slice() : []
            };

            let fIndex = newUploadState.controlFamilyData.findIndex(f => f.id === action.familyId);
            if (fIndex < 0) {
                return state;
            }
            let fData = { ...newUploadState.controlFamilyData[fIndex] };
            let cIndex = fData.controlFamilyDetails && fData.controlFamilyDetails.length > 0 ? fData.controlFamilyDetails.findIndex(o => o.microsoftControl.id === action.controlId) : -1;
            if (cIndex < 0) {
                return state;
            }

            newUploadState.controlFamilyData.splice(fIndex, 1, fData);

            let control = { ...fData.controlFamilyDetails[cIndex] };
            if (!control.userActions) {
                return state;
            }

            if (!control.userActions.documentInfos) {
                control.userActions.documentInfos = [];
            } else {
                control.userActions.documentInfos = control.userActions.documentInfos.slice();
            }
            control.userActions.documentInfos.push(action.document);
            fData.controlFamilyDetails.splice(cIndex, 1, control);

            return newUploadState;
        case UPDATE_DELETE:
            let newDeleteState = {
                ...state,
                controlFamilyData: state.controlFamilyData ? state.controlFamilyData.slice() : []
            };

            let tfIndex = newDeleteState.controlFamilyData.findIndex(f => f.id === action.familyId);
            if (tfIndex < 0) {
                return state;
            }

            let fdData = { ...newDeleteState.controlFamilyData[tfIndex] };
            let cdIndex = fdData.controlFamilyDetails.findIndex(o => o.microsoftControl.id === action.controlId);
            if (cdIndex < 0) {
                return state;
            }

            newDeleteState.controlFamilyData.splice(tfIndex, 1, fdData);

            let dcontrol = { ...fdData.controlFamilyDetails[cdIndex] } as FrameworkControlViewModel;
            if (!dcontrol.userActions) {
                return state;
            }

            var deleteIndex = dcontrol.userActions.documentInfos.findIndex(o => o.fileId === action.document.fileId);
            if (deleteIndex < 0) {
                return state;
            }

            dcontrol.userActions.documentInfos.splice(deleteIndex, 1);
            dcontrol.userActions.documentInfos = dcontrol.userActions.documentInfos.slice();
            fdData.controlFamilyDetails = fdData.controlFamilyDetails.slice();
            fdData.controlFamilyDetails.splice(cdIndex, 1, dcontrol);
            return newDeleteState;

        case UPDATE_TILESTATUS:
            let newDashboardTile = state.reviewDashboardTile === null ? new ReviewDashboardTile() : { ...state.reviewDashboardTile };
            newDashboardTile.status = action.userActions.tileStatus;
            let updatedStatusState = { ...state, reviewDashboardTile: newDashboardTile };
            return updatedStatusState;
        case RECEIVE_TILE:
            let updatedTileState = { ...state, reviewDashboardTile: action.data };
            return updatedTileState;
    }
    return state || unloadedState;
};
